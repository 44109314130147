import { withModal, Button } from '@thryvlabs/maverick'
// import ResetPasswordModal from './reset-password-modal'
import ResetPasswordEmailModal from './reset-password-modal/ResetPasswordEmailModal'
import './ResetPasswordButtonWithModal.css'
const ResetPasswordButton = ({ showModal }) => {
  return (
    <Button variant="primary" buttonType="outlined" onClick={showModal}>
      Reset Password
    </Button>
  )
}

export const ResetPasswordButtonWithModal = () => {
  const ButtonWithModal = withModal(ResetPasswordButton, ResetPasswordEmailModal)
  return <ButtonWithModal />
}
