import styled, { css } from 'styled-components'

export const RadioContainer = styled.div`
  font-family: 'Montserrat';
  border-radius: 5px;
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding: 10px;
    font-size: 14px;
  }

  ${(props) => {
    switch (props.variant) {
      case 'default':
        return css`
          border: 2px solid #057affe6;
        `
      case 'expired':
        return css`
          border: 2px solid #df2a2a;
        `
      case 'expiring-soom':
        return css`
          border: 2px solid #fac02b;
        `
      default:
        return css`
          border: 2px solid #f8f9fb;
        `
    }
  }}
`
