import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose
} from '@fortawesome/pro-regular-svg-icons'
import {
faCircleExclamation
} from '@fortawesome/pro-solid-svg-icons'

const ErrorAlert = ({message, setShowErrorAlert}) => {
  const handleClose = function ()  {
    setShowErrorAlert(false)
  }  
  return (
    <div
    className='p-3 sm:p-[10px] sm:w-[95%] sm:m-[auto] flex justify-between items-center w-full  border-[1px] border-[#EF9595] bg-[#FCEAEA] mb-[1rem]'
    >
    <span className='sm:w-full w-[90%] sm:ml-[0px] ml-[1rem] open-sans text-[14px]'>    
    <span > <FontAwesomeIcon  
                      className=' text-[16px] text-[#DF2A2A] mr-[0.5rem]'
                      icon={faCircleExclamation}
                    /></span>
                    {message}</span>
    <span>
    <FontAwesomeIcon  
                      className='mr-[1rem] text-[14px]'
                      icon={faClose}
                      onClick={handleClose}
                    />
    </span>  
    </div>
  )
}

export default ErrorAlert