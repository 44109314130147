import { Icon, ParagraphText } from '@thryvlabs/maverick'

export const SearchCardWithIcon = ({ className, onClick, icon, children }) => {
  const hoverAnimClass = 'hover:bg-thryv-gray-light-200 duration-300 transition-all'

  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex items-center px-6 py-0.5 w-full cursor-pointer hover:bg-thryv-gray-light-200 ${hoverAnimClass} ${className} md:h-[52px]`}
    >
      <div>
        <Icon
          type={icon.type}
          variant={icon.variant}
          width="24"
          color="#808080"
          className="md:w-6 w-[14px]"
        />
      </div>
      <ParagraphText variant="reg" color="thryv-black" className="pl-2 truncate">
        {children}
      </ParagraphText>
    </button>
  )
}
