import { useRef } from 'react'
import { SubheaderTag } from '../../subheader-tag'
import { PinDropdown } from './pin-dropdown'
import { useClickOutside } from '../../../../../../hooks/use-click-outside'

export const PinTagWithDropdown = ({
  children,
  messages,
  refetchPins,
  messageContentRef,
  refetchMessages,
  showDropdown,
  setShowDropdown,
  ...rest
}) => {
  const pinTagRef = useRef(null)
  const pinDropdownRef = useRef(null)

  useClickOutside((e) => {
    const pinModal = document.getElementById('headlessui-portal-root')

    const pinButtonClicked = e.composedPath().includes(pinTagRef.current)
    const pinModalClicked = e.composedPath().includes(pinModal)

    if (pinButtonClicked || pinModalClicked) return
    else setShowDropdown(false)
  }, pinDropdownRef)

  return (
    <div>
      <SubheaderTag
        iconVariant="altThumbtack"
        iconType="regular"
        onClick={() => setShowDropdown(!showDropdown)}
        {...rest}
        ref={pinTagRef}
      >
        {children}
      </SubheaderTag>
      <PinDropdown
        setShowDropdown={setShowDropdown}
        ref={pinDropdownRef}
        messages={messages}
        refetchPins={refetchPins}
        messageContentRef={messageContentRef}
        refetchMessages={refetchMessages}
        show={showDropdown}
      />
    </div>
  )
}
