import ActivationView from './ActivationView'
const ActivationScreen = ({ openConnectPhoneModal }) => {
  return (
    <div>
      {/* <CallsNavigation /> */}

      <ActivationView openConnectPhoneModal={openConnectPhoneModal} />

      {/* <div id="contact-sidesheet" className="relative">
                <ContactSideSheet />
            </div> */}
    </div>
  )
}

export default ActivationScreen
