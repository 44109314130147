export const postalCodeValidator = (countryISO, inputValue) => {
  inputValue = inputValue.replace(/[- ]/, '')
  if (countryISO === 'US') {
    if (!isNaN(Number(inputValue)) && inputValue.length === 5) return 'valid'
    else return 'Number & 5 digits required'
  } else if (countryISO === 'CA') {
    if (inputValue.length === 6) return 'valid'
    else return '6 digits required'
  } else if (countryISO === 'AU') {
    if (!isNaN(Number(inputValue)) && inputValue.length === 4) return 'valid'
    else return 'Number & 4 digits required'
  } else if (countryISO === 'NZ') {
    if (!isNaN(Number(inputValue)) && inputValue.length === 4) return 'valid'
    else return 'Number & 4 digits required'
  }
}
