import { useAuth0 } from '@auth0/auth0-react'
import './thryvpay.css'

const Transactions = () => {
  const THRYVPAY_URL =
    import.meta.env.VITE_ENV === 'prod'
      ? 'https://thryvpayfullstack-prod.thryv.com'
      : 'https://dev-thryvpay-beta.sandbox.thryv.com'
  const { user } = useAuth0()

  return (
    <div className="iframe-parent">
      <iframe
        data-testid="tp-iframe"
        width="100%"
        height="100%"
        src={`${THRYVPAY_URL}/thryvpaydashboard?command_center?${user.businessId}?${user.staffUID}`}
      />
    </div>
  )
}

export default Transactions
