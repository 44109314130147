import useRedux from '../../../../hooks/use-redux'
import { selectContact } from '../../../../redux-toolkit/slices/calls/contacts/contacts-slice'
import { useState, useEffect } from 'react'
import SearchContact from '../search-contact'
import { Icon } from '@thryvlabs/maverick'
import { ArrowUpArrowDownIcon } from '../../../command-center-icon/icons'

const chevronLeftIcon = (
  <Icon
    type="regular"
    variant="chevronLeft"
    width="8.7"
    height="16.84"
    color="#4D4D4D"
  />
)
const AddContactPanel = ({ setContactInfoView }) => {
  const [dispatch] = useRedux()
  // eslint-disable-next-line no-unused-vars
  const [unknownRecentCall, setUnknownRecentCall] = useState({})
  const [
    {
      selectedContact,
      // contacts: { contactsList },
    },
  ] = useRedux('contacts')

  useEffect(() => {
    setUnknownRecentCall(selectedContact)
  }, [selectedContact])

  const handleClickOnBack = () => {
    setContactInfoView('default')
    dispatch(selectContact({ recentCall: selectedContact }))
  }

  return (
    <div className="flex flex-col w-[350px] h-full bg-white absolute border-l border-[#F1F1F5]">
      {/* back button */}
      <div className="w-[58px] h-5 flex flex-row mt-6 ml-[27px] mb-[35px]">
        {chevronLeftIcon}
        <button
          onClick={() => handleClickOnBack()}
          className="ml-3 text-sm font-montserrat font-semibold"
        >
          Back
        </button>
      </div>
      <SearchContact callAllowed={false} />
      {/* sort section */}
      <div className="w-[294px] flex flex-row justify-between ml-6 mt-6">
        <span className="font-montserrat font-semibold text-sm text-[#231F20]">
          Contacts
        </span>
        <button>
          <ArrowUpArrowDownIcon width="24" height="24" />
        </button>
      </div>
    </div>
  )
}

export default AddContactPanel
