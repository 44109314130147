import { useEffect, useState } from 'react'
import { FilterItem, SelectionButtonWithMenu } from '../../common'
import { CheckboxGroup } from '../../../../../../../common/checkbox-group'

const CHANNEL_TYPES = {
  Gmail: 'gmail',
  Outlook: 'outlook',
  'Yahoo mail': 'yahoo',
  Instagram: 'instagram',
  Facebook: 'facebook',
  Hotmail: 'hotmail',
  Webchat: 'webchat',
  'Microsoft 365': 'microsoft365',
  Phone: 'phone',
  Video: 'video',
  IMAP: 'imap',
}

const CHANNEL_TYPES_INVERTED = {
  gmail: 'Gmail',
  outlook: 'Outlook',
  yahoo: 'Yahoo mail',
  instagram: 'Instagram',
  facebook: 'Facebook',
  hotmail: 'Hotmail',
  webchat: 'Webchat',
  microsoft365: 'Microsoft 365',
  phone: 'Phone',
  video: 'Video',
  icloud: 'iCloud',
  imap: 'IMAP',
}

export const ChannelOption = ({
  defaultChannels,
  defaultSelected,
  onSelectionChange,
}) => {
  const [selectedChannelsStr, setSelectedChannelsStr] = useState('Any')
  const [selectedChannels, setSelectedChannels] = useState([])
  const [channels, setChannels] = useState([
    // { value: 'Gmail', checked: defaultChannels.includes('gmail') }
  ])

  const getCheckedChannels = (channels) =>
    [...channels].flatMap((channel) => (channel.checked ? channel.value : []))

  const updateSelectedChannels = (items) => {
    const checkedArr = getCheckedChannels(items)
    const formattedCheckedArr = checkedArr.map(
      (checkedItem) => CHANNEL_TYPES[checkedItem],
    )

    onSelectionChange && onSelectionChange(formattedCheckedArr)

    if (checkedArr.length) {
      setSelectedChannelsStr(checkedArr.join(', '))
      setSelectedChannels(formattedCheckedArr)
    } else {
      setSelectedChannelsStr('Any')
      setSelectedChannels([])
    }
  }

  useEffect(() => {
    const formattedChannelsStr = defaultSelected
      ?.map((channel) => CHANNEL_TYPES_INVERTED[channel])
      .join(', ')
    setSelectedChannelsStr(
      formattedChannelsStr.length ? formattedChannelsStr : 'Any',
    )

    const newChannels = defaultChannels.map((channel) => {
      return {
        value: CHANNEL_TYPES_INVERTED[channel],
        checked: defaultSelected.includes(channel),
      }
    })

    const removeDuplicates = (channels) => {
      const uniqueChannels = []

      channels.forEach((obj) => {
        if (!uniqueChannels.some((channel) => channel.value === obj.value)) {
          uniqueChannels.push(obj)
        }
      })

      return uniqueChannels
    }

    const filteredChannels = removeDuplicates(newChannels)

    setChannels(filteredChannels)

    setSelectedChannels(
      defaultSelected.map((channel) => CHANNEL_TYPES_INVERTED[channel]),
    )
  }, [])

  return (
    <FilterItem title="Channel">
      <input
        name="channel"
        value={JSON.stringify(selectedChannels)}
        className="hidden"
        readOnly
      />
      <SelectionButtonWithMenu
        dataTestID="channel-filter"
        className="!w-full"
        onStateChange={(state) =>
          state === 'dropdown closed' &&
          updateSelectedChannels(document.getElementsByName('channels'))
        }
        content={
          <div className="py-4 px-2">
            <CheckboxGroup
              className="channels-checkbox-group md:gap-6"
              data={channels}
              groupName="channels"
              column
            />
          </div>
        }
      >
        {selectedChannelsStr}
      </SelectionButtonWithMenu>
    </FilterItem>
  )
}
