import { useRef } from 'react'
import { useClickOutside } from '../../../../../../hooks'
import { useLazyQuery } from '@apollo/client'
import { TransitionContainer } from '../../../../../common/transition-container'
import { ParagraphText } from '@thryvlabs/maverick'
import { setInboxFilter } from '../../../../slices/inboxThreadsSlice'
import { useDispatch } from 'react-redux'
import { FETCH_THREADS } from '../../../../../../graphql'
import { useInboxMenuConversations } from '../../../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'

const NavItemButton = ({ children, onClick, name }) => {
  return (
    <li>
      <button
        className="px-2 py-[9px] w-full text-start rounded duration-300 hover:bg-[#F8F9FB]"
        onClick={() => onClick(name)}
      >
        <ParagraphText
          variant="reg"
          className="font-montserrat text-[#808080] font-semibold text-base"
        >
          {children}
        </ParagraphText>
      </button>
    </li>
  )
}

export const NavMenu = ({ show, setShow }) => {
  const menuRef = useRef(null)
  const dispatch = useDispatch()
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const handleClick = (item) => {
    dispatch(setInboxFilter(item))
    getThreads()
    setShow(false)
  }

  useClickOutside((ev) => {
    const buttonElem = menuRef.current.previousSibling
    const buttonClicked = ev.composedPath().includes(buttonElem)
    if (!buttonClicked) setShow(false)
  }, menuRef)

  const filtersArray = [
    {
      name: 'Inbox',
    },
    {
      name: 'Unread',
    },
    {
      name: 'Completed',
    },
    {
      name: 'Spam',
    },
    {
      name: 'Trash',
    },
  ]

  return (
    <TransitionContainer
      show={show}
      as="ul"
      className="flex flex-col top-[calc(100%+14px)] p-2"
      width="164px"
      unmount={false}
      ref={menuRef}
    >
      {filtersArray.map((item, i) => (
        <NavItemButton key={i} onClick={handleClick} name={item.name}>
          {item.name}
        </NavItemButton>
      ))}
    </TransitionContainer>
  )
}
