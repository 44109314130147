import styled from 'styled-components'

export const ModalPlansSection = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 500px) {
    height: 450px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    /* removing scrollbar in IE, Edge or Firefox */
    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`

export const PlansSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  height: 360px;

  & > :last-child {
    margin-right: 0px;
  }

  @media (max-width: 500px) {
    width: 790px;
  }

  @media (min-width: 768px) and (max-width: 820px) {
    height: 550px;
  }
`
