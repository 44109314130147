import RoundedActionButton from '../../../../../common/rounded-action-button'

export const RemoveButton = ({ disabled, onClick }) => {
  return (
    <RoundedActionButton
      dataTestId="remove-btn"
      icon={{
        type: 'regular',
        variant: 'altTrash',
      }}
      tooltipTitle="Remove"
      disable={disabled}
      onClick={onClick}
      isMavIcon
    />
  )
}
