import { ParagraphText } from '@thryvlabs/maverick'
import { ConversationAvatar } from '../../../../../conversation-avatar'
import RoundedActionButton from '../../../../../../common/rounded-action-button'
import formatContactData from '../../../../../../hooks/formatContactData'
import ChannelsIcon from '../../../../../../../channels-icon/channels-icon'

export const MessageCardTemplate = ({
  className,
  imageUrl,
  name,
  subject,
  content,
  timestamp,
  focus,
  onClick,
  showFileImportButton,
  showPinnedButton,
  message_channel,
}) => {
  const hoverAnimClass = 'hover:border-[#FF5000] duration-300 transition-all'
  const focusClass = 'border-[#FF5000] duration-300 transition-all'

  return (
    <article
      className={`
      border border-solid border-thryv-orange-500 rounded-xl p-2.5
      ${hoverAnimClass}
      ${focus && focusClass}
      ${className && className} 
      `}
      onClick={onClick}
    >
      <div className="flex justify-between mb-2">
        <div className="flex gap-1.5 items-center">
          <ConversationAvatar variant="image" imgUrl={imageUrl} customSize={20} />
          <ParagraphText variant="sm" color="thryv-black-500">
            {formatContactData(name)}
          </ParagraphText>
        </div>

        <div>
          <ChannelsIcon variant={message_channel} width={20} height={20} />
        </div>
      </div>

      <div className="mb-2 min-h-[20px] max-w-md">
        <ParagraphText
          className="font-semibold mb-1 line-clamp-1 max-w-sm"
          variant="sm"
          color="thryv-night"
        >
          {subject}
        </ParagraphText>
        <ParagraphText className="line-clamp-3" variant="sm" color="thryv-night">
          {content}
        </ParagraphText>
      </div>

      <div className="flex justify-between items-center">
        <ParagraphText variant="sm" color="thryv-gray-medium-500">
          {timestamp}
        </ParagraphText>
        <div className="flex">
          {showFileImportButton && (
            <RoundedActionButton
              className="w-[28px] h-[28px]"
              icon={{
                type: 'regular',
                variant: 'fileImport',
                width: '16',
                height: '16',
                color: '#808080',
                hoverColor: '#057AFF',
              }}
              tooltipTitle="Go to message"
              isMavIcon
            />
          )}
          {showPinnedButton && (
            <RoundedActionButton
              className="!w-[28px] !h-[28px]"
              icon={{
                variant: 'altThumbtack',
                type: 'solid',
                width: '12',
                height: '16',
                color: '#5378FC',
                hoverColor: '#5378FC',
              }}
              isCCIcon
              tooltipTitle="Unpin"
            />
          )}
        </div>
      </div>
    </article>
  )
}
