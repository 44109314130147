import { useState, useRef, useEffect } from 'react'
import { Header, ParagraphText, Icon, Button, Avatar } from '@thryvlabs/maverick'
import { useSelector, useDispatch, batch } from 'react-redux'

import {
  setSelectedCardData,
  setModalContent,
  setShowModal,
} from '../slices/connectChannelsSlice'
import axios from 'axios'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { useFacebookAuth } from '../../../../hooks/use-facebook-auth'
import { LoadingSpinner } from '../../loading-spinner'
const SelectPageModal = ({ handleClose, setSuccess }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [cancelConnection, setCancelConnection] = useState(false)
  const divRef = useRef(null)
  const containerRef = useRef(null)
  const buttonRef = useRef(null)
  const [selectedCard, setSelectedCard] = useState({})
  const [loading, setLoading] = useState(false)
  const { userEmail, userAccessToken, pages } = useSelector(
    (state) => state.connectChannels.metaUserData,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { user, getAccessTokenSilently } = useAuth0()

  const { VITE_SUNSHINE_CONNECT_URI } = import.meta.env
  const { access_token, name } = useSelector(
    (state) => state.connectChannels.selectedCardData,
  )

  const handleClick = (data) => {
    setIsChecked(true)
    setSelectedCard({ id: data.id, name: data.name })
    dispatch(
      setSelectedCardData({
        access_token: data.access_token,
        name: data.name,
        email: userEmail,
        selectedPageUserAccessToken: userAccessToken,
      }),
    )
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current === event.target ||
        buttonRef.current === event.target
      ) {
        setIsChecked(false)
      }
    }

    // Bind the event listener directly to the containerRef
    containerRef.current.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      containerRef.current.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef, isChecked])

  const handleConnect = async () => {
    setLoading(true)

    const token = await getAccessTokenSilently()

    const code = searchParams.get('code')

    await axios({
      url: VITE_SUNSHINE_CONNECT_URI,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        code: code,
        businessId: user.businessId,
        channelType: localStorage.getItem('sunshineapp'),
        ccid: user?.cc_id,
        direcotryCode: user?.dirCode,
        agentId: user?.staffUID,
        authType: 'desktop',
        selectedPageAccessToken: access_token,
        pageName: name,
        selectedPageUserEmail: userEmail,
        selectedPageUserAccessToken: userAccessToken,
      },
    })
      .then(() => {
        batch(() => {
          dispatch(setShowModal(false))
          dispatch(setModalContent(1))
        })
        navigate('/inbox')
        setLoading(false)
        setSuccess(true)
      })
      .catch(() => {
        dispatch(setModalContent(12))
      })
  }

  const PageCard = ({ data }) => {
    return (
      <div
        ref={divRef}
        onClick={() => handleClick(data)}
        className={`flex items-center p-4 w-[420px] sm:w-[240px]  x-sm:w-[220px] max-w-[436px] bg-[#F8F9FB] text-center gap-4 sm:gap-3 x-sm:gap-1 rounded drop-shadow-lg cursor-pointer ${
          isChecked && selectedCard.id === data.id
            ? 'border-[#5378FC] border-2'
            : 'border-[#E3E6E8] border'
        }   `}
      >
        <Avatar
          name={{ firstName: data.name.charAt(0) }}
          variant="name"
          size="default"
        />{' '}
        <Header
          className=" max-w-[270px] sm:max-w-[170px] x-sm:max-w-[135px] truncate"
          fontWeight="semibold"
          variant="h6"
        >
          {data.name}
        </Header>
        <div className="flex justify-end absolute right-10 sm:right-5 x-sm:right-4">
          {isChecked && selectedCard.id === data.id ? (
            <Icon
              type="solid"
              variant="circleCheck"
              color="#5378FC"
              className="justify-end"
            />
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div
      ref={containerRef}
      className="w-[500px] sm:w-[300px] x-sm:w-[280px] max-w-[500px] p-[32px]"
    >
      <div className="flex justify-between mb-[24px] sm:w-[240px] x-sm:w-[220px]">
        <Header className="" fontWeight="semibold" variant="h4">
          {cancelConnection ? 'Quit channel connection?' : 'Select Page'}
        </Header>
        <Icon
          type="regular"
          variant="x"
          height="18"
          fill="#A3A5A7"
          className="cursor-pointer"
          onClick={() => {
            cancelConnection ? handleClose() : setCancelConnection(true)
          }}
        />
      </div>
      {cancelConnection ? (
        <div className="mb-[24px] text-left">
          <Header className="" fontWeight="semibold" variant="h7">
            Are you sure you want to leave this page?
          </Header>
        </div>
      ) : null}
      <div className="mb-[25px] text-left">
        <ParagraphText variant="reg" className="sm:!text-xs">
          {cancelConnection
            ? `If you leave this page, no changes will be saved. You will lose your progress towards connecting this channel.`
            : `Choose the page you want to connect. Command Center currently only supports connecting one business page at a time.`}{' '}
          {!cancelConnection && (
            <a
              href="https://learn.thryv.com/hc/en-us/articles/17231876482573-Connect-Facebook-Messenger-to-your-Command-Center-Inbox"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-[#057aff] cursor-pointer">Learn more</span>
            </a>
          )}
        </ParagraphText>
      </div>

      {!cancelConnection && (
        <div className="flex flex-col gap-4 mb-[25px] max-h-[280px] hover:overflow-y-scroll overflow-hidden">
          {/* map over returned data then render page card */}
          {pages?.map((card, idx) => {
            return <PageCard key={idx} data={card} idx={idx} />
          })}
        </div>
      )}

      <div
        ref={buttonRef}
        className="flex gap-4 justify-end x-sm:w-[220px] sm:w-[240px]"
      >
        <Button
          variant="text"
          level={2}
          onClick={() => {
            cancelConnection
              ? setCancelConnection(false)
              : window.location.replace(useFacebookAuth('facebook'))
          }}
          className="cursor-pointer !text-[#808080]"
        >
          {cancelConnection ? 'CANCEL' : 'BACK'}
        </Button>
        <Button
          type="submit"
          className={`${!isChecked ? 'hover:!bg-[#CCCCCC]' : 'hover:!bg-inherit'}`}
          disabled={cancelConnection ? false : !isChecked}
          variant="primary"
          onClick={() => {
            cancelConnection ? handleClose() : handleConnect()
          }}
        >
          {loading ? (
            <LoadingSpinner widthAndHeight={14} />
          ) : cancelConnection ? (
            'Quit'
          ) : (
            'Connect'
          )}
        </Button>
      </div>
    </div>
  )
}

export default SelectPageModal
