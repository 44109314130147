const ListingsManagement = () => {
  const listingsManagementUrl =
    import.meta.env.VITE_ENV === 'prod'
      ? 'https://main.d2zce76p5tom1y.amplifyapp.com/yext-listings'
      : 'https://mc-staging.d2zce76p5tom1y.amplifyapp.com/yext-listings'

  return (
    <div>
      <iframe
        style={{ height: 'calc(100vh - 66.18px' }}
        width="100%"
        src={listingsManagementUrl}
      />
    </div>
  )
}

export default ListingsManagement
