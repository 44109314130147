import { Button } from '@thryvlabs/maverick'
import styled from 'styled-components'

export const InfoText = styled.span`
  color: #000;
  font-size: 14px;
  margin-left: 8px;
`

export const BannerClosed = styled(Button)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`
