import { NavLink } from 'react-router-dom'

export const NavTab = ({ path, title, active, activeStyle }) => {
  const navlinkStyle = `flex cursor-pointer w-max text-center items-end`
  const inactiveStyle = 'px-6 border-none hover:text-black'
  const defaultStyle =
    'py-2 font-semibold relative w-fit margin-auto align-middle min-h-[42px]'
  return (
    <NavLink to={path} className={navlinkStyle}>
      <h4 className={`${defaultStyle} ${active ? activeStyle : inactiveStyle}`}>
        {title}
      </h4>
    </NavLink>
  )
}
