import ChannelsIcon from '../../../../../../../../../../components/channels-icon/channels-icon'

const CircleChannelIcon = ({
  className,
  variant,
  backgroundColor,
  translateX,
  zIndex,
  height,
  width,
}) => (
  <span
    className={`${className} rounded-full flex justify-center items-center border-[.5px] border-[#808080] border-opacity-40	`}
    style={{
      backgroundColor: backgroundColor,
      transform: translateX ? `translateX(${translateX}px)` : '',
      zIndex: zIndex,
      height: height || '20px',
      width: width || '20px',
    }}
  >
    <ChannelsIcon variant={variant} width="10" height="10" />
  </span>
)

export const ChannelIconsStackedHorizontally = ({ channels }) => {
  const iconHeight = 20
  const iconWidth = 20
  const halfIconHeight = iconHeight / 2

  return (
    <div className="relative" style={{ height: iconHeight }}>
      <div
        className="relative"
        style={{ left: (channels?.length - 1) * halfIconHeight }}
      >
        {channels?.map((channel, i) => {
          return (
            <CircleChannelIcon
              key={i}
              className="absolute"
              variant={channel}
              backgroundColor={'white'}
              translateX={i * -halfIconHeight}
              zIndex={halfIconHeight - i + 20} // Fixes bug where 12th+ channels don't appear
              height={iconHeight}
              width={iconWidth}
            />
          )
        })}
      </div>
    </div>
  )
}
