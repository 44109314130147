import { useState, useRef } from 'react'
import moment from 'moment/moment'
import './custom-date-modal.css'

import { Icon, Header, DatePicker, ParagraphText } from '@thryvlabs/maverick'
import { useClickOutside } from '../../../../../../../../../hooks'

export const CustomDateModal = ({ close, onClose, newFrom, newTo }) => {
  const modalRef = useRef(null)
  const [from, setFrom] = useState(newFrom || moment())
  const [to, setTo] = useState(newTo || moment())

  const closeAndSendDates = () => {
    close()
    setTimeout(() => onClose([from, to]), 300)
  }

  useClickOutside(() => {
    closeAndSendDates()
  }, modalRef)

  return (
    <div className="inbox-custom-date-modal" ref={modalRef}>
      <div className="flex justify-end mb-2">
        <button type="button" onClick={closeAndSendDates}>
          <Icon variant="x" type="regular" color="#A3A5A7" width="18" height="18" />
        </button>
      </div>
      <Header fontWeight="semibold" variant="h6" className="mb-4 normal-case">
        Select date range
      </Header>
      <div className="relative px-[22px] pb-[22px]">
        <ParagraphText variant="sm" className="text-start -mb-6" color="thryv-steel">
          Select Date Range
        </ParagraphText>
        <DatePicker
          className="inbox-menu-filter-date-picker"
          relative
          variant="singleRange"
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
        />
      </div>
    </div>
  )
}
