import { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../command-center-icon'
import { QuickActionContext } from './context/quick-action-context'
import { returnBcPath } from '../../../../../../utils/returnBCPath'

const QuickActionButton = ({ btn, displayCenter }) => {
  const { setIsActionModal, setPickedModal } = useContext(QuickActionContext)

  const handleOpen = () => {
    setIsActionModal(true)
    setPickedModal(btn.iconName)
  }

  return (
    <>
      {!displayCenter && btn.btnText !== 'Charge card' && (
        <QuickActionBody handleOpen={handleOpen} btn={btn} />
      )}

      {displayCenter && <QuickActionBody handleOpen={handleOpen} btn={btn} />}
    </>
  )
}

const QuickActionBody = ({ handleOpen, btn }) => {
  const { user } = useAuth0()
  const genBcUrl = (action, dirCode) => {
    const url = returnBcPath(action, dirCode)
    return url
  }

  if (btn.btnText === 'Charge card') {
    return (
      <div className="cursor-pointer hover:bg-[#f2f4f6] w-full p-[8px] mx-[-8px] box-content rounded">
        <div className="flex gap-x-2 items-center" onClick={handleOpen}>
          <CommandCenterIcon variant={btn.iconName} type="regular" />
          <ParagraphText variant="reg">{btn.btnText} </ParagraphText>
        </div>
      </div>
    )
  }

  return (
    <div className="cursor-pointer hover:bg-[#f2f4f6] w-full p-[8px] mx-[-8px] box-content rounded">
      <a
        className="flex gap-x-2 items-center"
        target="_blank"
        href={`${genBcUrl(btn.btnText, user?.dirCode)}`}
        rel="noreferrer"
      >
        <CommandCenterIcon variant={btn.iconName} type="regular" />
        <ParagraphText variant="reg">{btn.btnText} </ParagraphText>
      </a>
    </div>
  )
}

export default QuickActionButton
