import { useState } from 'react'
import { LoadingSpinner } from '../../../../common/loading-spinner'
import phoneFormatter from '../../../../../utils/phoneFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faCirclePause } from '@fortawesome/pro-light-svg-icons'
import {
  CirclePhoneIcon,
  EmptyPhotoIcon,
} from '../../../../command-center-icon/icons/icons'
import moment from 'moment'
import { Icon } from '@thryvlabs/maverick'
import useCheckVoicemailExistence from '../../../../inbox/hooks/useCheckVoicemailExistence.js'

const VoicemailDropdownMobile = ({
  selectedCall,
  setShowInfoCardFromVoicemail,
  setDisplayInfoCard,
}) => {
  const { isVoicemailExist, audioBlob, transcript, isLoadingAudio } =
    useCheckVoicemailExistence(selectedCall)
  return (
    <div className="px-[24px]" data-testid="voicemail-info-dropdown">
      <div className="pt-10">
        <button onClick={() => setDisplayInfoCard(false)}>
          <Icon
            variant="x"
            type="regular"
            width={'18'}
            height={'18'}
            color="#A3A5A7"
          />
        </button>
      </div>
      <div className="flex border-b-[1px] border-barely-gray pb-[12px] pt-[10px]">
        <div className="flex min-w-md:pr-[8.5px] pl-[5px]">
          <EmptyPhotoIcon />
        </div>
        <div className={`flex flex-col grow justify-center pl-[17px]`}>
          <div
            onClick={() => {
              alert('Open New Contact Sheet')
            }}
            className={`${
              selectedCall.played ? 'font-normal' : 'font-bold'
            } flex text-[16px] hover:cursor-pointer pb-[5px]`}
          >
            {selectedCall?.contact || phoneFormatter(selectedCall?.number_from)}
          </div>
          <div className="text-thryv-steel font-normal text-[14px] pt-[4px]">
            {moment(selectedCall.ts).local().format('MMMM Do YYYY')}
            {' at ' + moment(selectedCall.ts).local().format('h:mm A')}
          </div>
        </div>
        <div className="flex flex-col text-thryv-steel">
          <span className="flex pb-[2px]">
            {moment(selectedCall.ts).local().format('l')}
          </span>
        </div>
      </div>
      <div className="flex center-items justify-center gap-[30px] py-[20px] h-[70px]">
        <div className="flex w-12 justify-center">
          <AudioPlayer
            isVoicemailExist={isVoicemailExist}
            audioBlob={audioBlob}
            isLoadingAudio={isLoadingAudio}
          />
        </div>
        <div
          onClick={() => {
            setShowInfoCardFromVoicemail(true)
          }}
        >
          <CirclePhoneIcon
            className="flex"
            height="30px"
            width="30px"
            fill="#A3A5A7"
          />
        </div>
      </div>
      <div>
        {transcript?.length > 0 && (
          <p className="bg-[#f8f9fb] text-thryv-night rounded-[4px] p-[16px]">
            {transcript}
          </p>
        )}
      </div>
    </div>
  )
}

const AudioPlayer = ({ isVoicemailExist, audioBlob, isLoadingAudio }) => {
  const [audio, setAudio] = useState()
  const [playingAudio, setPlayingAudio] = useState(false)

  const playAudio = () => {
    const audioURL = URL.createObjectURL(audioBlob)
    const audio = new Audio(audioURL)
    setAudio(audio)
    audio.play()
    audio.addEventListener('play', () => setPlayingAudio(true))
    audio.addEventListener('ended', () => setPlayingAudio(false))
  }
  return (
    <div className="flex items-center">
      {isLoadingAudio ? (
        <div className="pt-[10px] items-center">
          <LoadingSpinner widthAndHeight={40} />
        </div>
      ) : !playingAudio ? (
        <button
          disabled={!isVoicemailExist}
          onClick={() => playAudio()}
          className={`${
            !isVoicemailExist ? 'opacity-40 pointer-events-none' : 'opacity-100'
          }`}
        >
          <FontAwesomeIcon
            style={{ color: 'blue' }}
            className="color-blue"
            size="2xl"
            icon={faPlay}
          />
        </button>
      ) : (
        <button
          onClick={() => {
            audio.pause()
            setPlayingAudio(false)
          }}
        >
          <FontAwesomeIcon
            style={{ color: 'blue' }}
            className="color-blue"
            size="2xl"
            icon={faCirclePause}
          />
        </button>
      )}
    </div>
  )
}

export default VoicemailDropdownMobile
