import { createSlice } from '@reduxjs/toolkit'
import sidebarConfig from '../../../sidebarConfig'

export const userPreferenceSlice = createSlice({
  name: 'userPreferences',
  initialState: {
    navigationPreferences: sidebarConfig.navigationPreferences,
    notificationPreferences: sidebarConfig.notificationPreferences,
    navigationCollapsed: sidebarConfig.navigationCollapsed,
    letAnimate: false,
    hasMarketingCenter: undefined,
    hasListingsManagement: undefined,
    hasBusinessCenter: undefined,
    hasSignatures: undefined,
    hasWebsiteBuilder: undefined,
    hasThryvLeads: undefined,
    hasReportingCenter: undefined,
  },
  reducers: {
    setUserPreferences(state, action) {
      state.navigationPreferences = action.payload.navigationPreferences
      state.navigationCollapsed = action.payload.navigationCollapsed
      state.notificationPreferences = action.payload.notificationPreferences
    },
    setNavigationPreferences(state, action) {
      state.navigationPreferences = action.payload
    },
    setLeftNavigationCollapsed(state, action) {
      state.navigationCollapsed = action.payload
    },
    setNotificationPreferences(state, action) {
      state.notificationPreferences = action.payload
    },
    setDoNotDisturbPreferences(state, action) {
      state.notificationPreferences.doNotDisturb = action.payload
      const newValue = !action.payload
      state.notificationPreferences?.desktop?.forEach(
        (pref) => (pref.value = newValue),
      )
      state.notificationPreferences?.mobile?.forEach(
        (pref) => (pref.value = newValue),
      )
    },

    setCenterAccess(state, action) {
      state.hasMarketingCenter = action.payload.hasMarketingCenter
      state.hasListingsManagement = action.payload.hasListingsManagement
      state.hasBusinessCenter = action.payload.hasBusinessCenter
      state.hasSignatures = action.payload.hasSignatures
      state.hasWebsiteBuilder = action.payload.hasWebsiteBuilder
      state.hasThryvLeads = action.payload.hasThryvLeads
      state.hasReportingCenter = action.payload.hasReportingCenter
    },
    setDesktopNotificationPreferences(state, action) {
      state.notificationPreferences.desktop = action.payload
    },
    setMobileNotificationPreferences(state, action) {
      state.notificationPreferences.mobile = action.payload
    },
    removeCustomLink(state, action) {
      const updatedState = {
        ...state.navigationPreferences,
        data: [...(state.navigationPreferences?.data || '')].map((group) => {
          return {
            draggable: group?.draggable,
            editable: group?.editable,
            id: group?.id,
            items: group?.items?.filter((item) => {
              if (item.id !== action.payload) {
                return item
              }
            }),
            order: group?.order?.splice(1, 1),
            title: group?.title,
            visible: group?.visible,
          }
        }),
      }
      state.navigationPreferences = updatedState
    },
  },
})

export const {
  setNavigationPreferences,
  setLeftNavigationCollapsed,
  setNotificationPreferences,
  setUserPreferences,
  setCenterAccess,
  setDesktopNotificationPreferences,
  setMobileNotificationPreferences,
  setDoNotDisturbPreferences,
  removeCustomLink,
} = userPreferenceSlice.actions

export default userPreferenceSlice.reducer
