import { useEffect, useRef } from 'react'
import { TransitionContainer } from '../../common/transition-container'
import { ParagraphText } from '@thryvlabs/maverick'
import { useSelector, useDispatch } from 'react-redux'
import {
  setShowMarkAsMenu,
  setBulkEditBtnClicked,
  setBulkSelectedThreadIds,
  setBulkSelectedThreadSk1s,
} from '../slices/inboxThreadsSlice'
import { useMutation } from '@apollo/client'
import { UPDATE_THREAD_FIELD, REMOVE_THREAD_FIELD } from '../../../graphql'

const MarkAsMenu = ({ className, markAsRef, getNotifications, getThreads }) => {
  const dispatch = useDispatch()
  const dropdownRef = useRef(null)
  const {
    showMarkAsMenu,
    unreadThreadIds,
    bulkSelectedThreadIds,
    bulkSelectedThreadSk1s,
    inboxFilter,
  } = useSelector((state) => state.inboxThreads)

  const threadSk1s = bulkSelectedThreadSk1s.map((x) => x.sk1)

  const checkReadStatus = () => {
    const hasUnread = bulkSelectedThreadIds.some((id) =>
      unreadThreadIds.includes(id),
    )
    const onlyUnread = bulkSelectedThreadIds.every((id) =>
      unreadThreadIds.includes(id),
    )

    if (!bulkSelectedThreadIds.length || (!hasUnread && !onlyUnread)) {
      return 'Unread'
    } else if (hasUnread && !onlyUnread) {
      return 'Read'
    } else if (onlyUnread) {
      return 'Read'
    }
  }

  useEffect(() => {
    checkReadStatus()
  }, [bulkSelectedThreadIds, unreadThreadIds])

  const getDropdownOptions = () => {
    if (inboxFilter === 'Inbox' || inboxFilter === 'Unread') {
      return [checkReadStatus(), 'Completed', 'Spam']
    } else if (inboxFilter === 'Spam') {
      return ['Not Spam']
    } else if (inboxFilter === 'Completed') {
      return [checkReadStatus(), 'Incomplete', 'Spam']
    } else if (inboxFilter === 'Trash') {
      return ['Not Trash']
    } else {
      return [checkReadStatus(), 'Completed', 'Spam']
    }
  }

  const handleOnCompleted = () => {
    getThreads()
    dispatch(setBulkEditBtnClicked(false))
    dispatch(setBulkSelectedThreadIds([]))
    dispatch(setBulkSelectedThreadSk1s([]))
    dispatch(setShowMarkAsMenu(false))
  }

  const [addThreadToSpam] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'spamsk',
    },
    onCompleted: () => {
      handleOnCompleted()
    },
  })

  const [removeThreadFromSpam] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'spamsk',
    },
    onCompleted: () => {
      handleOnCompleted()
    },
  })

  const [addThreadToCompleted] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'completedsk',
    },
    onCompleted: () => {
      handleOnCompleted()
    },
  })

  const [removeThreadFromCompleted] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'completedsk',
    },
    onCompleted: () => {
      handleOnCompleted()
    },
  })

  const [updateToUnread] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'read',
    },
    onCompleted: () => {
      getNotifications()
      handleOnCompleted()
    },
  })

  const [updateToRead] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'read',
    },
    onCompleted: () => {
      getNotifications()
      handleOnCompleted()
    },
  })

  const [removeThreadFromTrash] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: threadSk1s,
      field: 'trashsk',
    },
    onCompleted: () => {
      handleOnCompleted()
    },
  })

  const handleMarkAs = (text) => {
    switch (text) {
      case 'Read':
        updateToRead()
        break
      case 'Unread':
        updateToUnread()
        break
      case 'Spam':
        addThreadToSpam()
        break
      case 'Not Spam':
        removeThreadFromSpam()
        break
      case 'Completed':
        addThreadToCompleted()
        break
      case 'Incomplete':
        removeThreadFromCompleted()
        break
      case 'Not Trash':
        removeThreadFromTrash()
        break
      default:
        break
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !markAsRef.current.contains(event.target)
      ) {
        dispatch(setShowMarkAsMenu(showMarkAsMenu))
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const MenuButton = ({ onClick, children }) => (
    <button
      data-testid={`mark-as-${children}`}
      type="button"
      className="whitespace-nowrap text-left rounded hover:bg-thryv-gray-light-200 transition-all duration-300 h-7 w-[180px] px-3 py-1 "
      onClick={onClick}
    >
      <ParagraphText variant="sm">{children}</ParagraphText>
    </button>
  )

  return (
    <TransitionContainer
      show={showMarkAsMenu}
      as="ul"
      className={className}
      width="fit-content"
      ref={dropdownRef}
    >
      {getDropdownOptions().map((text, i) => {
        return (
          <li className="list-none" key={i}>
            <MenuButton onClick={() => handleMarkAs(text)}>{text}</MenuButton>
          </li>
        )
      })}
    </TransitionContainer>
  )
}

export default MarkAsMenu
