import { gql } from '@apollo/client'

export const GET_BUSINESS_INFO_BY_LOCATION = gql`
  query get_business_info_by_location($location_id: String!) {
    queryBusinessInfoByLocation(LocationID: $location_id) {
      Name
      BusinessCategory
      Address
      City
      CountryCode
      Phone
      State
      Zip
      Email
      Website
    }
  }
`

export const GET_BUSINESS_NAME_BY_LOCATION = gql`
  query get_business_name_by_location($location_id: String!) {
    queryBusinessInfoByLocation(LocationID: $location_id) {
      Name
    }
  }
`

export const GET_BUSINESS_ICON_UPLOAD_URL = gql`
  query get_business_icon_upload_url($content_type: String!) {
    getBusinessIconUploadURL(content_type: $content_type)
  }
`
