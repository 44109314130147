export const getFirstAndLastName = (user) => {
  if (!user) return ['Unknown', ' ']

  if (
    user?.family_name?.length &&
    user?.family_name !== 'null' &&
    user?.given_name?.length &&
    user?.given_name !== 'null'
  ) {
    const firstname = user.given_name
    const lastname = user.family_name

    return [firstname, lastname]
  }

  if (user.name.includes('.')) {
    return user.name.split('.')
  }

  const fullName = user.name.split(' ')
  if (fullName.length === 1) {
    return [...fullName, '']
  }

  return fullName
}
