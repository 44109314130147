import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CardTitle, Modal, Input, ParagraphText } from '@thryvlabs/maverick'
import { LabelIcon } from '../../../components/command-center-icon/icons'
import Label from './Label'
import { useMutation } from '@apollo/client'
import { CREATE_LABEL_OPTIONS_BY_LOCATION } from '../../../graphql/mutations/label-options-mutations'
import { GET_LABEL_OPTIONS_BY_LOCATION } from '../../../graphql/queries'
import { useAuth0 } from '@auth0/auth0-react'
import { RoundActionButton } from '../SecurityStaff/sections/staff-section/components'

export default function Labels() {
  const { user } = useAuth0()
  const [newLabel, setNewLabel] = useState('')
  const [isHovered, setIsHovered] = useState(false)

  const labels = useSelector((state) => state.messageContent.labels)

  const [createLabel] = useMutation(CREATE_LABEL_OPTIONS_BY_LOCATION, {
    variables: {
      location_id: user?.businessId || '',
      label_name: newLabel,
      status: '1',
    },
    refetchQueries: [GET_LABEL_OPTIONS_BY_LOCATION],
    onError: () => {},
  })

  const handleAddNewLabel = (e) => {
    e.preventDefault()
    createLabel()
  }

  return (
    <div>
      <div className="mb-6">
        <CardTitle variant="primary">Labels</CardTitle>
      </div>

      <div
        style={{
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '2rem',
        }}
      >
        <div className="flex flex-col space-y-3">
          <Modal
            variant="default"
            id="hyperlink-modal"
            title="New label"
            action
            actionClose
            footer
            btnActionDisabled={
              // newLabel.length <= 64
              // newLabel cannot be comprised entirely of whitespace chars
              !/^(?!^\s+$).{1,64}$/.test(newLabel)
            }
            btnActionText="Add Label"
            btnAction={handleAddNewLabel}
            onClose={() => {
              setNewLabel('')
            }}
            footerCancel
            altBtn={
              <div
                className="flex justify-between items-center py-2 px-4 max-w-[600px] border border-dashed border-[#CCCCCC] rounded cursor-pointer sm:border-none sm:relative"
                style={{
                  border: '1px dashed #CCCCCC',
                  borderColor: '#CCCCCC',
                  height: '56px',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div className="flex items-center gap-2 sm:hidden">
                  <LabelIcon height={16} width={16} fill={'#92929D'} />
                  <ParagraphText variant="sm">CREATE LABEL</ParagraphText>
                </div>
                <RoundActionButton
                  dataTestId="create-new-label-btn"
                  className={`transition-all duration-300 ${
                    isHovered && '!bg-[#FF5000]'
                  } sm:!bg-[#FF5000] sm:absolute sm:-top-[50px] right-[5px]`}
                />
              </div>
            }
          >
            <div className="create-new-label-modal py-5">
              <div className="w-full h-[26px]">
                <Input
                  className="w-full"
                  type="text"
                  placeholder="label name"
                  name="newlabel"
                  withLabel
                  labelType="static"
                  variant="default"
                  onChange={(e) => setNewLabel(e.target.value)}
                />
                {newLabel.length > 64 && (
                  <span className="text-notification-red text-xs ">
                    Exceeded max length of 64 characters{' '}
                  </span>
                )}
              </div>
            </div>
          </Modal>

          {labels.map((label, key) => (
            <Label key={key} label={label} />
          ))}
        </div>
      </div>
    </div>
  )
}
