import { CommandCenterIcon } from '../command-center-icon'
import { Icon } from '@thryvlabs/maverick'

const RightPanelButtons = ({
  showFiles,
  showProfile,
  setShowFiles,
  setShowPanel,
  setShowProfile,
}) => {
  return (
    <div className={`flex flex-col bg-transparent relative`}>
      {showFiles ? (
        <button
          id="cc-file-selected"
          className={`flex flex-row self-end mb-1 -mr-1 py-[11px] px-[13px] rounded-l-full bg-white border-l-[2px] border-[#ECEEF1] shadow-[-1px_1px_0px_0px_#0000000A]`}
          onClick={() => {
            setShowFiles(!showFiles)
            setShowPanel(false)
            setShowProfile(false)
          }}
        >
          <CommandCenterIcon
            type="regular"
            variant="paperClip"
            height="20px"
            width="15px"
            fill="#000000"
          />
        </button>
      ) : (
        <button
          id="cc-file-unselected"
          className={`flex flex-row self-end mb-1 py-[11px] px-[11px] rounded-l-full bg-[#231F20] border-l-[2px] border-[#ECEEF1] hover:bg-[#4D4D4D]`}
          onClick={() => {
            setShowFiles(!showFiles)
            setShowPanel(true)
            setShowProfile(false)
          }}
        >
          <CommandCenterIcon
            type="regular"
            variant="paperClip"
            height="20px"
            width="15px"
            fill="#ffffff"
          />
        </button>
      )}
      {showProfile ? (
        <button
          id="cc-profile-selected"
          className={`flex flex-row self-end mb-1 -mr-1 py-[11px] px-[13px] rounded-l-full bg-white border-l-[2px] border-[#ECEEF1] shadow-[-1px_1px_0px_0px_#0000000A]`}
          onClick={() => {
            setShowProfile(!showProfile)
            setShowPanel(false)
            setShowFiles(false)
          }}
        >
          <Icon
            variant="user"
            type="regular"
            height="20px"
            width="15px"
            fill="#000000"
          />
        </button>
      ) : (
        <button
          id="cc-profile-unselected"
          className={`flex flex-row self-end mb-1 py-[11px] px-[11px] rounded-l-full bg-[#231F20] border-l-[2px] border-[#ECEEF1] hover:bg-[#4D4D4D]`}
          onClick={() => {
            setShowProfile(!showProfile)
            setShowPanel(true)
            setShowFiles(false)
          }}
        >
          <Icon
            variant="user"
            type="regular"
            height="20px"
            width="15px"
            fill="#ffffff"
          />
        </button>
      )}
    </div>
  )
}

export default RightPanelButtons
