import { useRef, useEffect, useState } from 'react'
import { StyledAccordion } from '../LeftNavBar.styles'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCommentQuestion,
  faGear,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'
import AdminItem from './AdminItem'
import { useDispatch } from 'react-redux'
import { setMobileNavOpen } from '../slices/left-nav-slice'

const SupportCenterAndSettings = ({
  collapsed,
  sectionIsEditing,
  handleAccordionOpenClose,
  mobileSectionsOpen,
  accordionRef,
}) => {
  const [isLoaded, setisLoaded] = useState(false)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const collapseRef = useRef(false)
  const dispatch = useDispatch()

  const supportAndSettings = [
    {
      title: 'Admin',
      link: 'https://learn.thryv.com',
      icon: faCommentQuestion,
    },
    {
      title: 'Settings',
      link: '/settings/my-account/personal-information',
      icon: faGear,
    },
  ]

  useEffect(() => {
    setisLoaded(true)
  }, [])

  useEffect(() => {
    if (isLoaded) {
      setShouldAnimate(true)
    }
  }, [collapsed])

  return (
    <>
      <div
        onClick={handleAccordionOpenClose}
        className="flex md:w-[280px] min-w-md:hidden md:border-b-[2px] border-[#F0F0F0]"
      >
        <StyledAccordion title="Admin" className={'z-20'}>
          <div
            className={`text-light-gray flex gap-[8px] ${
              collapsed
                ? `${
                    !collapseRef.current && sectionIsEditing.length === 0
                      ? 'animate-slideup'
                      : ''
                  } w-[36px]`
                : `${
                    collapseRef.current && sectionIsEditing.length === 0
                      ? 'animate-slidedown'
                      : ''
                  }  w-[228px]`
            } ml-[3px] py-[4px]`}
          >
            <ul>
              {/* Don't show Admin in mobile for now */}
              {/* <NavLink
                to="https://learn.thryv.com"
                onClick={() => {
                  dispatch(setMobileNavOpen(false))
                }}
                target='_blank'
                rel='noreferrer noopener'
              >
                {({ isActive }) => (
                  <div
                    style={isActive ? { backgroundColor: '#ECEEF1' } : {}}
                    className={`flex ${
                      collapsed
                        ? 'w-[36px] h-[36px]'
                        : 'w-[231px] md:w-[275px] pl-[12px]'
                    } align-center gap-[8px] py-[6px] rounded-[4px] hover:bg-[#ECEEF1] hover:cursor-pointer text-thryv-steel hover:text-dark-gray active:bg-[#231F201A]`}
                  >
                    <FontAwesomeIcon
                      icon={faCommentQuestion}
                      size={'lg'}
                      className={`${
                        collapsed ? 'pl-[8px] pt-[1.5px]' : 'pl-0 h-[16px] w-[17px]'
                      }  text-[#808080]`}
                    />
                    {!collapsed ? (
                      <li className="text-[12px] leading-[18px] font-semibold font-primary">
                        Support Center
                      </li>
                    ) : null}
                  </div>
                )}
              </NavLink> */}
              <NavLink
                to="/settings/my-account/personal-information"
                onClick={() => {
                  dispatch(setMobileNavOpen(false))
                }}
              >
                {({ isActive }) => (
                  <div
                    style={isActive ? { backgroundColor: '#ECEEF1' } : {}}
                    className={`flex ${
                      collapsed
                        ? 'w-[36px] h-[36px]'
                        : 'w-[231px] md:w-[275px] pl-[12px]'
                    } gap-[8px] py-[6px] rounded-[4px] hover:bg-[#ECEEF1]  text-thryv-steel hover:text-dark-gray hover:cursor-pointer active:bg-[#231F201A]`}
                  >
                    <FontAwesomeIcon
                      icon={faGear}
                      size={'lg'}
                      className={`${
                        collapsed ? 'pl-[8px] pt-[1.5px]' : 'pl-0 h-[16px] w-[17px]'
                      } text-[#808080]`}
                    />
                    {!collapsed ? (
                      <li className="text-[12px] leading-[18px] font-semibold font-primary">
                        Settings
                      </li>
                    ) : null}
                  </div>
                )}
              </NavLink>
            </ul>
          </div>
        </StyledAccordion>
        <FontAwesomeIcon
          className={`
            ${
              mobileSectionsOpen.includes('Admin')
                ? 'animate-iconRotateOpen'
                : `${accordionRef === 'Admin' && 'animate-iconRotateClosed'}`
            }
              absolute left-[240px] min-w-md:hidden text-center p-[15px] text-black`}
          size="sm"
          icon={faChevronDown}
          onAnimationEnd={() => {}}
        />
      </div>
      <div
        className={`md:hidden text-light-gray flex gap-[8px] ${
          collapsed
            ? `${shouldAnimate ? 'animate-slideup' : ''} w-[36px]`
            : `${shouldAnimate ? 'animate-slidedown' : ''} w-[231px]`
        } ml-[7px] pt-[4px] pb-[8px]`}
      >
        <ul>
          {' '}
          {supportAndSettings.map((options, i) => {
            // Don't show Admin in Desktop for now
            return (
              options.title === 'Settings' && (
                <AdminItem key={i} options={options} collapsed={collapsed} />
              )
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default SupportCenterAndSettings
