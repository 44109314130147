import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { setEditorView } from '../../../slices/messageComposeSlice'

export const Dropzone = ({ getRootProps }) => {
  const { editorView } = useSelector((s) => s.messageCompose)
  const dispatch = useDispatch()
  return (
    <div
      className={` bg-[#f9fafb] opacity-80 absolute bottom-0 top-0 right-0 left-0  rounded min-h-[72px] z-10 flex flex-col justify-center items-stretch`}
    >
      <div
        {...getRootProps({ className: 'dropzone flex flex-1  ' })}
        onDrop={() => editorView === 'closed' && dispatch(setEditorView('open'))}
      >
        <div className="pointer-events-none flex-1 flex flex-col justify-center items-center pt-2">
          <CommandCenterIcon
            variant="cloudUploadIcon"
            type="regular"
            color={'#cccccc'}
            width={editorView !== 'closed' ? 80 : 40}
            height={editorView !== 'closed' ? 56 : 28}
          />
          <p className="font-semibold pt-2">Drag files here</p>
        </div>
      </div>
    </div>
  )
}
