import ReactDOM from 'react-dom'

const CustomStyledTooltip = ({ options, coords }) => {
  return ReactDOM.createPortal(
    <div
      data-testid="custom-styled-tooltip"
      style={{ top: coords.y - 3, left: 60 }}
      className={`absolute z-100 top-[${coords.y - 3}px] left-[60px]`}
    >
      <div className="flex">
        <span
          className="absolute w-0 h-0 border-[8px] border-transparent"
          style={{
            top: 'min(30%, 36px)',
            borderRightColor: 'rgb(35, 31, 32)',
            left: 0,
            borderLeft: 0,
            marginLeft: -8,
            marginTop: 0,
          }}
        ></span>
        <div className="bg-[#231F20] text-white w-auto h-[40px] rounded-[10px] px-[10px] p-[5px]">
          <span className="text-thryv-white-200 text-h9 font-montserrat font-semibold min-w-[67px]">
            {options.title}
          </span>
        </div>
      </div>
    </div>,
    document.getElementById('portal'),
  )
}

export default CustomStyledTooltip
