import { useAuth0 } from '@auth0/auth0-react'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { AuthLoader } from '@authentication'
import { useState, useEffect } from 'react'
import getApolloLink from '../../utils/apollo-link'

export function ApolloWrapper({ children }) {
  const { getAccessTokenSilently } = useAuth0()
  const [client, setClient] = useState()

  useEffect(() => {
    ;(async () => {
      const accessToken = await getAccessTokenSilently()
      const link = getApolloLink(accessToken)
      const cache = new InMemoryCache({})

      setClient(new ApolloClient({ link, cache }))
    })()
  }, [getAccessTokenSilently])

  if (!client) {
    return <AuthLoader />
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
