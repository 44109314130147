import { ModalWrapper, ParagraphText, Icon, Button } from '@thryvlabs/maverick'

export const CreateNewConversationModal = ({ show, setShow, value, onSubmit }) => {
  const closeModal = () => {
    setShow(false)
  }

  return (
    <ModalWrapper
      show={show}
      close={closeModal}
      data-testid="createNewConversationModal"
    >
      <div className="w-[500px] p-3 create-new-conversation-modal">
        <div className="flex justify-between mb-6">
          <ParagraphText
            variant="sm"
            className="!font-[Montserrat] text-[22px] font-semibold leading-[30px]"
          >
            Create New Conversation
          </ParagraphText>

          <button type="button" onClick={closeModal}>
            <Icon
              variant="x"
              type="regular"
              height="18"
              width="18"
              color="#A3A5A7"
            />
          </button>
        </div>

        <ParagraphText
          variant="sm"
          className="!font-[Montserrat] text-[16px] font-semibold leading-[22px] text-start mb-3"
        >
          Are you sure you want to create a new conversation?
        </ParagraphText>

        <ParagraphText
          variant="sm"
          className="text-[14px] leading-[20px] text-start mb-[64px]"
        >
          This will create a new conversation with{' '}
          <span className="font-semibold">{value}</span>.
        </ParagraphText>

        <div className="flex justify-end gap-4">
          <Button
            variant="text"
            level={2}
            onClick={closeModal}
            data-testid="createNewConversationModalCancel"
          >
            <span className="text-[#808080]">CANCEL</span>
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onSubmit && onSubmit()
              closeModal()
            }}
            data-testid="createNewConversationModalSubmit"
          >
            Continue
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}
