import { createSlice } from '@reduxjs/toolkit'

export const phoneRegistrationSlice = createSlice({
  name: 'phoneRegistration',
  initialState: {
    brand_id: null,
    brand: null,
    campaign_id: null,
    reference_id: null,
    success: null,
    loading: false,
    phone: null,
  },
  reducers: {
    setBrand(state, action) {
      state.brand_id =
        action.payload.brand_id ||
        action.payload?.queryBrandCampaignByChannelID?.at(0)?.brand_id

      if (action.payload.campaign_id) {
        state.campaign_id = action.payload.campaign_id
      }
      if (action.payload?.queryBrandCampaignByChannelID?.at(0)?.phone) {
        state.phone = action.payload?.queryBrandCampaignByChannelID?.at(0)?.phone
      }
      if (action.payload?.queryBrandCampaignByChannelID?.at(0)) {
        state.brand = action.payload?.queryBrandCampaignByChannelID?.at(0)
      }

      if (
        action.payload?.createBrand ||
        action.payload?.updateBrand ||
        action.payload?.updateCampaign
      ) {
        state.brand = Object.values(action.payload)[0]
      }
    },
    setPhone(state, action) {
      state.phone = action.payload
    },
    setReferenceId(state, action) {
      state.reference_id = action.payload
    },
    setSuccess(state, action) {
      state.success = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
})

export const { setBrand, setPhone, setReferenceId, setSuccess, setLoading } =
  phoneRegistrationSlice.actions

export default phoneRegistrationSlice.reducer
