import { Header } from '@thryvlabs/maverick'

export const SectionTitle = ({ children }) => {
  return (
    <Header
      fontWeight="semibold"
      variant="h8"
      className="!text-thryv-steel md:mb-3 mb-2 px-6 normal-case md:text-base leading-5 md:leading-[22px]"
    >
      {children}
    </Header>
  )
}
