import { gql } from '@apollo/client'

export const GET_CONNECTED_CHANNELS_BY_USER = gql`
  query get_connected_channels_by_user($loc_id: String!, $user_id: String!) {
    queryConnectedChannelsByUser(loc_id: $loc_id, user_id: $user_id) {
      Nickname
      AccessType
      ChannelID
      AccountUserValue
      CCID
      ExternalAccountID
      Provider
      Status
      VendorID
      syncState
      ChannelAccessLevel
    }
  }
`

export const GET_CONNECTED_CHANNELS_BY_LOCATION = gql`
  query get_connected_channels_by_location($loc_id: String!) {
    queryConnectedChannelsByLocation(loc_id: $loc_id) {
      ChannelID
      ExternalAccountID
      CCID
      Status
      Provider
      VendorID
      syncState
      AccountUserValue
      ChannelAccessLevel
    }
  }
`

export const GET_PACKAGE_INFO = gql`
  query get_package_info {
    queryPackageInfoByCCID {
      CCID
      PackageID
      PackageName
      InboxChannels
      TeamChatRetention
      CallMinutes
      SupportLevel
      FreeSeats
    }
  }
`
