import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-regular-svg-icons'
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { Header } from '@thryvlabs/maverick'
import { useAuth0 } from '@auth0/auth0-react'
import { GET_STAFF } from '../../../../graphql'
import { useQuery } from '@apollo/client'

export const BusinessCardHeader = ({
  isEdit,
  toggleEdit,
  isMobile,
  isAccordianOpen,
  toggleAccordian,
}) => {
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: staffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userRole = staffData?.staff[0]?.Role || user.cc_role

  return (
    <div className="flex justify-between items-center w-full">
      <Header fontWeight="semibold" variant="h6" className="mt-5 mb-5">
        Your Business
      </Header>
      <div className="flex min-w-[85px]">
        {!isEdit && (
          <div
            className="flex text-[#057AFF] mr-[10px] cursor-pointer"
            onClick={toggleEdit}
          >
            {userRole !== '2' && (
              <>
                <FontAwesomeIcon icon={faPen} size={'lg'} />{' '}
                <span className="ml-[6px]">Edit</span>
              </>
            )}
          </div>
        )}
        {isMobile && (
          <div className="w-[15px]">
            {isAccordianOpen && !isEdit ? (
              <FontAwesomeIcon
                className="text-[#231F20]"
                icon={faCaretUp}
                size={'lg'}
                onClick={toggleAccordian}
              />
            ) : !isAccordianOpen && !isEdit ? (
              <FontAwesomeIcon
                icon={faCaretDown}
                size={'lg'}
                onClick={toggleAccordian}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

// {!isEdit && (
//   <div
//     className="flex text-[#057AFF] mr-[10px] cursor-pointer"
//     onClick={toggleEdit}
//   >
//     <FontAwesomeIcon icon={faPen} size={'lg'} />{' '}
//     <span className="ml-[6px]">Edit</span>
//   </div>
// )}
// {isMobile && (
//   <div className="w-[15px]">
//     {isAccordianOpen && !isEdit ? (
//       <FontAwesomeIcon
//         className="text-[#231F20]"
//         icon={faCaretUp}
//         size={'lg'}
//         onClick={toggleAccordian}
//       />
//     ) : !isAccordianOpen && !isEdit ? (
//       <FontAwesomeIcon
//         icon={faCaretDown}
//         size={'lg'}
//         onClick={toggleAccordian}
//       />
//     ) : null}
//   </div>
// )}
