/* Source: https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/ */
const downloadCsv = function (data, fileName) {
  const blob = new Blob([data], { type: 'text/csv' })

  const url = window.URL.createObjectURL(blob)

  const a = document.createElement('a')

  a.setAttribute('href', url)

  a.setAttribute('download', `${fileName}.csv`)

  a.click()
}

const getItemsWithExcludedPropsRemoved = (items, excludedProps) => {
  let itemsCopy

  itemsCopy = items.map((item) => {
    const itemCopy = { ...item }

    excludedProps.forEach((keyToDelete) => {
      // eslint-disable-next-line no-prototype-builtins
      if (itemCopy.hasOwnProperty(keyToDelete)) delete itemCopy[keyToDelete]
    })

    return itemCopy
  })

  return itemsCopy
}

/* Source: https://tinyurl.com/mrwsahrf */
const createCsv = (items, excludedProperties) => {
  let itemsCopy = getItemsWithExcludedPropsRemoved(items, excludedProperties)
  let csv = ''

  itemsCopy.forEach((item, row) => {
    const generateHeadings = () => {
      for (let key in itemsCopy[0]) {
        // This is to not add a comma at the last cell
        csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
        keysCounter++
      }
    }

    /* Reason for having item[key] in quotes: https://tinyurl.com/yc5e6zcy */
    const addRowToCsv = () => {
      for (let key in item) {
        csv += `"${item[key]}"` + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
        keysCounter++
      }
    }

    let keysAmount = Object.keys(item).length
    let keysCounter = 0

    if (row === 0) generateHeadings()
    else addRowToCsv()
  })

  return csv
}

const csv = {
  create: createCsv,

  download: downloadCsv,
}

export default csv
