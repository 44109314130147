import { gql } from '@apollo/client'

export const UPDATE_THREAD_FIELD = gql`
  mutation update_thread_field($sk1s: [Long!], $field: String!) {
    updateThreadField(sk1s: $sk1s, field: $field)
  }
`

export const REMOVE_THREAD_FIELD = gql`
  mutation remove_thread_field($sk1s: [Long!], $field: String!) {
    removeThreadField(sk1s: $sk1s, field: $field)
  }
`
