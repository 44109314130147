import { useState, useEffect } from 'react'
import { Button, Input, Select } from '@thryvlabs/maverick'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { UPDATE_BUSINESS_INFO_BY_LOCATION } from '../../../graphql/mutations/business-info-mutations'
import styled from 'styled-components'

import { useMediaQuery } from 'react-responsive'
import { BusinessCardHeader } from './BusinessCardForms/BusinessCardHeader'
import { useSelector, useDispatch } from 'react-redux'
import UploadProfileImage from './BusinessCardForms/UploadProfileImage'
import { setBusinessLocation } from '../../../components/top-navigation-bar/slices/businessInfoSlice'
import { useAuth0 } from '@auth0/auth0-react'
import { MaskedInput } from '@thryvlabs/maverick'
import { GET_BUSINESS_INFO_BY_LOCATION } from '../../../graphql/queries/business-info-queries'
import {
  getCountryStates,
  getStateObj,
  getStateValue,
} from '../../../components/top-navigation-bar/StateProvinceSelectDropdown'

import { convertAlphaToNumeric } from './BusinessCardForms/my-account-helpers'
import { convertAlphaToInputValue } from './BusinessCardForms/my-account-helpers'
import { yupResolver } from '@hookform/resolvers/yup'
import businessInfoFormSchema from './data/businessInfoFormSchema'
import { AnimatedNotificationContainer } from '../../../components/inbox/message-content/inbox-notifications/animated-notification-container/animated-notification-container'
import { Notification } from '../../../components/common/notification'

const StyledSelect = styled(Select)`
  span {
    color: #a3a5a7;
  }
  .disabled {
    color: #231f20;
  }
  button > span {
    color: #231f20;
  }
  li span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const BusinessForm = () => {
  /* Variables Used */
  const [isEdit, setIsEdit] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const [isAccordianOpen, setIsAccordianOpen] = useState(true)
  const { user } = useAuth0()
  const [showNotification, setShowNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')

  const dispatch = useDispatch()
  const businessLocation = useSelector((state) => state.businessInfo.location)
  const [maskPattern, setMaskPattern] = useState('(###) ###-####')
  // const API_KEY = import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY

  // const setAddressFromAutocomplete = (selected) => {
  //   setValue(
  //     'address',
  //     `${selected.address_components[0]?.long_name} ${selected.address_components[1]?.long_name}`,
  //   )
  //   setValue('city', selected.address_components[3]?.long_name)
  //   setValue('postalCode', selected.address_components[7]?.long_name)
  //   setValue('state', selected.address_components[5]?.short_name)
  // }

  const formatNumber = (phoneNumber) => {
    if (businessLocation.countryCode === 'NZ') {
      phoneNumber = phoneNumber.replace(/\D/g, '')

      if (phoneNumber.length === 10) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      } else if (phoneNumber.length === 9) {
        return phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '($1) $2-$3')
      } else {
        return phoneNumber
      }
    } else {
      return phoneNumber
    }
  }

  const {
    control,
    getValues,
    setValue,
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      businessName: businessLocation.name,
      address: businessLocation.address,
      city: businessLocation.city,
      state: businessLocation.state,
      postalCode: businessLocation.zip,
      businessCategory: businessLocation.category,
      email: businessLocation.email,
      countryCode: businessLocation.countryCode,
      businessPhone: businessLocation.phone.replace(/\D/g, ''),
      website: businessLocation.website,
    },
    resolver: yupResolver(businessInfoFormSchema(businessLocation.countryCode)),
  })

  useEffect(() => {
    switch (businessLocation.countryCode) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          getValues('businessPhone')[1] === '4' ||
          businessLocation.phone[1] === '4' ||
          getValues('businessPhone')[1] === '5' ||
          businessLocation.phone[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }
    }
    return '(###) ###-####'
  }, [businessLocation])

  useEffect(() => {
    if (!isMobile) {
      setIsAccordianOpen(true)
    }
  }, [isMobile])

  useEffect(() => {
    resetValues()
  }, [businessLocation])

  // Functions
  const toggleEdit = () => {
    setIsEdit(true)
    setIsAccordianOpen(true)
    document.querySelector('[data-testid="select-option"]').classList.add('disabled')
  }

  const toggleAccordian = function () {
    setIsAccordianOpen(!isAccordianOpen)
  }

  const resetValues = () => {
    reset({
      businessName: businessLocation.name,
      address: businessLocation.address,
      city: businessLocation.city,
      state: businessLocation.state,
      postalCode: businessLocation.zip,
      businessCategory: businessLocation.category,
      email: businessLocation.email,
      countryCode: businessLocation.countryCode,
      businessPhone: formatNumber(businessLocation.phone),
      website: businessLocation.website,
    })
  }

  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_INFO_BY_LOCATION, {
    variables: {
      name: getValues('businessName').trim() || '',
      category: getValues('businessCategory').trim() || '',
      address: getValues('address').trim() || '',
      city: getValues('city').trim() || '',
      zip: getValues('postalCode') || '',
      state: getStateValue(getValues('state')) || '',
      country: businessLocation.countryCode || '',
      phone:
        `${convertAlphaToNumeric(businessLocation.countryCode)}${getValues(
          'businessPhone',
        ).replace(/\D/g, '')}` || '',
      email: getValues('email') || '',
      website: getValues('website') || '',
      directoryCode: user.dirCode,
      locationId: user.businessId,
    },
    onCompleted: () => {
      setNotificationVariant('success')
      setNotificationMessage('Business Information Updated Successfully!')
      setShowNotification(true)
      setTimeout(() => {
        setShowNotification(false)
      }, 2000)
      if (isMobile) {
        setTimeout(() => {
          setIsAccordianOpen(false)
        }, 3000)
      }
    },
    refetchQueries: [GET_BUSINESS_INFO_BY_LOCATION],
    onError: () => {
      setNotificationVariant('error')
      setNotificationMessage('Error Updating the Business Information')
      setShowNotification(true)
      setTimeout(() => {
        setShowNotification(false)
      }, 2000)
      if (isMobile) {
        setTimeout(() => {
          setIsAccordianOpen(false)
        }, 3000)
      }
    },
  })

  const removeDisabled = () => {
    document
      .querySelector('[data-testid="select-option"]')
      .classList.remove('disabled')
  }

  const handleFormSubmit = () => {
    const name = getValues('businessName').trim() || ''
    const category = getValues('businessCategory').trim() || ''
    const address = getValues('address').trim() || ''
    const city = getValues('city').trim() || ''
    const zip = getValues('postalCode') || ''
    const state = getStateValue(getValues('state')) || ''
    const phone = getStateValue(getValues('businessPhone')) || ''
    const email = getValues('email') || ''
    const website = getValues('website') || ''

    if (
      businessLocation.countryCode === 'NZ' &&
      (phone.replace(/\D/g, '').length > 10 || phone.replace(/\D/g, '').length < 9)
    ) {
      setError('businessPhone', {
        type: 'custom',
        message: 'Please enter a valid phone number',
      })
      return
    }

    if (
      businessLocation.countryCode === 'AU' &&
      (phone.replace(/\D/g, '').length > 12 || phone.replace(/\D/g, '').length < 9)
    ) {
      setError('businessPhone', {
        type: 'custom',
        message: 'Please enter a valid phone number',
      })
      return
    }

    if (
      (businessLocation.countryCode === 'US' ||
        businessLocation.countryCode === 'CA') &&
      (phone.replace(/\D/g, '').length > 10 || phone.replace(/\D/g, '').length <= 9)
    ) {
      setError('businessPhone', {
        type: 'custom',
        message: 'Please enter a valid phone number',
      })
      return
    }

    const updatedValues = {
      name,
      category,
      address,
      city,
      zip,
      state,
      country: businessLocation.country,
      countryCode: businessLocation.countryCode,
      phone: phone.replace(/\D/g, ''),
      email,
      website,
    }
    removeDisabled()
    dispatch(setBusinessLocation(updatedValues))
    setTimeout(updateBusinessInfo, 1000)
    setIsEdit(false)
  }

  return (
    <>
      <div className="bg-[white] relative rounded sm:p-6 p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] sm:w-[95%] sm:m-auto">
        <BusinessCardHeader
          isEdit={isEdit}
          toggleEdit={toggleEdit}
          isMobile={isMobile}
          isAccordianOpen={isAccordianOpen}
          toggleAccordian={toggleAccordian}
        />

        {isAccordianOpen && (
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="flex h-[100px] sm:h-[70px] align-bottom pt-5">
              <div className="flex w-[50px] sm:w-[20px] h-[48px] rounded-[4px]">
                <UploadProfileImage isEdit={isEdit} />
              </div>
              <div className="relative sm:w-[100%] mt-[10px] pl-[10px] sm:pl-0">
                <Controller
                  control={control}
                  name="businessName"
                  render={({ field }) => (
                    <Input
                      id="businessName"
                      disabled={!isEdit}
                      className="flex w-[400px] bg-white"
                      type="text"
                      customPlaceholder="e.g Thryv"
                      {...field}
                      withLabel
                      labelType="static"
                      variant="default"
                    />
                  )}
                />
                <label
                  htmlFor="businessName"
                  className="pl-[10px] sm:pl-0 pointer-events-none absolute left-0 -top-[16px] capitalize
                  font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                  peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white text-[#898989]"
                >
                  Business Name
                </label>
                <p className="text-notification-red text-xs ">
                  {errors.businessName?.message}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-5 gap-[24px] sm:items-end min-w-md:h-[100px]">
              <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                {
                  <div className="relative">
                    <Controller
                      control={control}
                      name="address"
                      render={({ field }) => (
                        <Input
                          id="address"
                          disabled={!isEdit}
                          className="w-[100%] bg-white peer apperance-none px-0 border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-gray-medium-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                          type="text"
                          placeholder="Street, Office, Suite"
                          {...field}
                          variant="default"
                          // apiKey={API_KEY}
                          // onPlaceSelected={(selected) =>
                          //   setAddressFromAutocomplete(selected)
                          // }
                          // options={{
                          //   types: ['geocode', 'establishment'],
                          // }}
                        />
                      )}
                    />
                    <label
                      htmlFor="address"
                      className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                    >
                      Address
                    </label>
                    <p className="text-notification-red text-xs ">
                      {errors.address?.message}
                    </p>
                  </div>
                }
              </div>
              <div className="sm:col-span-6 col-span-1 mt-5 mb-5">
                {
                  <div className="relative">
                    <Controller
                      control={control}
                      name="city"
                      render={({ field }) => (
                        <Input
                          id="city"
                          disabled={!isEdit}
                          className="w-[100%] bg-white peer apperance-none px-0 border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-gray-medium-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                          type="text"
                          placeholder="City"
                          {...field}
                          variant="default"
                          // apiKey={API_KEY}
                          // onPlaceSelected={(selected) =>
                          //   setAddressFromAutocomplete(selected)
                          // }
                          // options={{
                          //   types: ['geocode', 'establishment'],
                          // }}
                        />
                      )}
                    />
                    <label
                      htmlFor="city"
                      className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                    >
                      City
                    </label>
                    <p className="text-notification-red text-xs ">
                      {errors.city?.message}
                    </p>
                  </div>
                }
              </div>
              <div className={`sm:col-span-6 col-span-1 mb-5 min-w-md:pt-[13px]`}>
                {
                  <div className="relative w-full">
                    <Controller
                      control={control}
                      className="test"
                      name="state"
                      render={({ field: { ...rest } }) => (
                        <StyledSelect
                          disabled={!isEdit}
                          options={getCountryStates(businessLocation.countryCode)}
                          className="w-[100%]"
                          selectLabel="State/Province"
                          labelType="static"
                          withLabel
                          selectedOption={getStateObj(
                            getValues('state'),
                            getCountryStates(businessLocation.countryCode),
                          )}
                          setSelectedOption={(e) =>
                            setValue('state', e.target?.value)
                          }
                          {...rest}
                        />
                      )}
                    />
                    <p className="text-notification-red text-xs ">
                      {errors.state?.message}
                    </p>
                  </div>
                }
              </div>

              <div className="sm:col-span-6 col-span-1 mt-5 mb-5">
                {
                  <div className="relative">
                    <Controller
                      control={control}
                      name="postalCode"
                      render={({ field }) => (
                        <Input
                          id="postalCode"
                          disabled={!isEdit}
                          className="w-[50%] bg-white sm:w-[100%] peer apperance-none px-0 border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-gray-medium-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                          type="tel"
                          placeholder="Postal Code"
                          {...field}
                          name="postalCode"
                          variant="default"
                          // apiKey={API_KEY}
                          // onPlaceSelected={(selected) =>
                          //   setAddressFromAutocomplete(selected)
                          // }
                          // options={{
                          //   types: ['geocode', 'establishment'],
                          // }}
                          register={register}
                          errors={errors}
                        />
                      )}
                    />
                    <label
                      htmlFor="postalCode"
                      className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                    >
                      Postal Code
                    </label>
                    {/* <p className="text-notification-red text-xs ">
                    {errors.postalCode?.message}
                  </p> */}
                  </div>
                }
              </div>
            </div>

            <div className="grid grid-cols-6 gap-[24px] sm:pt-[30px] sm:items-end min-w-md:h-[100px]">
              <div className={`sm:col-span-6 col-span-1 mb-5 min-w-md:pt-[20px]`}>
                <div className="relative">
                  <Input
                    id="countryCode"
                    className="w-[100%] sm:w-[100%]"
                    type="tel"
                    customPlaceholder={convertAlphaToInputValue(
                      businessLocation.countryCode,
                    )}
                    name="countryCode"
                    withLabel
                    labelType="static"
                    variant="default"
                    disabled={true}
                  />
                  <label
                    htmlFor="countryCode"
                    className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                  >
                    Country Code
                  </label>
                </div>
              </div>

              <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                {
                  <div className="relative">
                    {businessLocation.countryCode === 'NZ' ? (
                      <Controller
                        control={control}
                        name="businessPhone"
                        render={({ field: { ...rest }, fieldState: { error } }) => (
                          <Input
                            id="businessPhone"
                            disabled={!isEdit}
                            value={businessLocation.phone || ''}
                            className="w-[100%] bg-white peer apperance-none px-0 focus:border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-black-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                            type="tel"
                            customPlaceholder="Business Phone"
                            {...rest}
                            withLabel
                            labelType="static"
                            variant="default"
                            error={error}
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        control={control}
                        name="businessPhone"
                        // rules={{
                        //   minLength: phoneErrorMessage(),
                        //   maxLength: phoneErrorMessage(),
                        // }}
                        render={({ field: { ...rest }, fieldState: { error } }) => (
                          <MaskedInput
                            id="businessPhone"
                            disabled={!isEdit}
                            value={businessLocation.phone || ''}
                            className="w-[100%] bg-white peer apperance-none px-0 focus:border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-black-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                            type="tel"
                            mask={maskPattern}
                            customPlaceholder="Business Phone"
                            {...rest}
                            withLabel
                            labelType="static"
                            variant="default"
                            error={error}
                          />
                        )}
                      />
                    )}

                    <label
                      htmlFor="businessPhone"
                      className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                    >
                      Business Phone
                    </label>
                    <p className="text-notification-red text-xs ">
                      {errors.businessPhone?.message}
                    </p>
                  </div>
                }
              </div>
              <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
                <div className="relative">
                  <Controller
                    control={control}
                    name="website"
                    render={({ field }) => (
                      <Input
                        id="website"
                        disabled={!isEdit}
                        className="w-[81%] bg-white sm:w-[100%] peer apperance-none px-0 focus:border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-black-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                        type="text"
                        customPlaceholder="eg.www.example.com"
                        {...field}
                        withLabel
                        labelType="static"
                        variant="default"
                      />
                    )}
                  />
                  <label
                    htmlFor="website"
                    className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                  >
                    Website
                  </label>
                  <p className="text-notification-red text-xs ">
                    {errors.website?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-6 gap-[24px] sm:items-end  min-w-md:h-[100px]">
              <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                {
                  <div className="relative">
                    <Controller
                      control={control}
                      name="businessCategory"
                      render={({ field }) => (
                        <Input
                          id="businessCategory"
                          disabled={!isEdit}
                          className="w-[100%] bg-white peer apperance-none px-0 focus:border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-black-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                          type="text"
                          customPlaceholder="Business Category"
                          {...field}
                          withLabel
                          labelType="static"
                          variant="default"
                        />
                      )}
                    />
                    <label
                      htmlFor="businessCategory"
                      className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                    >
                      Business Category
                    </label>
                    <p className="text-notification-red text-xs ">
                      {errors.businessCategory?.message}
                    </p>
                  </div>
                }
              </div>

              <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
                {
                  <div className="relative">
                    <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <Input
                          id="email"
                          disabled={!isEdit}
                          className="w-[100%] bg-white sm:w-[100%] peer apperance-none px-0 focus:border-b border-thryv-gray-light-500 text-thryv-black-500 font-open-sans text-form-text font-normal focus:border-thryv-black-500 focus:outline-none focus:ring-0 transition duration-300 ease-in-out focus:placeholder-transparent disabled:placeholder-thryv-gray-medium-500 disabled:border-b disabled:border-thryv-gray-light-500 disabled:text-thryv-black-500 mt-1 pb-1 valid:border-b invalid:border-b bg-none false"
                          type="text"
                          customPlaceholder="Email"
                          {...field}
                          withLabel
                          labelType="static"
                          variant="default"
                        />
                      )}
                    />
                    <label
                      htmlFor="email"
                      className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
                    >
                      Email
                    </label>
                    <p className="text-notification-red text-xs ">
                      {errors.email?.message}
                    </p>
                  </div>
                }
              </div>
            </div>

            <div className="flex h-[75px] w-full mt-5 mb-5 justify-end items-center gap-4 sm:w-[286px] sm:justify-center sm:mt-[50px] sm:mb-[20px]">
              {isEdit && (
                <>
                  <Button
                    onClick={() => {
                      if (isMobile) {
                        setIsAccordianOpen(false)
                      }
                      resetValues()
                      setIsEdit(false)
                      removeDisabled()
                    }}
                    variant="text"
                    level={2}
                    textVariant="light"
                  >
                    Cancel
                  </Button>

                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </>
              )}
            </div>
          </form>
        )}

        <div className="absolute sm:left-2 bottom-3 w-[97%]">
          <AnimatedNotificationContainer show={showNotification}>
            <Notification
              className="relative bg-blue-500 p-2 rounded-lg"
              iconType={'solid'}
              variant={notificationVariant}
              textToRender={notificationMessage}
              close={() => {
                setShowNotification(false)
              }}
            />
          </AnimatedNotificationContainer>
        </div>
      </div>
    </>
  )
}

export default BusinessForm
