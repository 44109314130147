/* eslint-disable no-case-declarations */
export const ascSort = (fieldType, threadsToSort) => {
  switch (fieldType) {
    case 'thread_name':
      const numbersThreads = threadsToSort?.filter((thread) => {
        if (thread?.thread_name === null || parseInt(thread?.thread_name)) {
          return thread
        }
      })
      const wordsThreads = threadsToSort?.filter((thread) => {
        if (thread?.thread_name !== null && !parseInt(thread?.thread_name)) {
          return thread
        }
      })

      const sortedNumbers = numbersThreads.sort((a, b) => {
        const aText = parseInt(a?.thread_name)
          ? a?.thread_name
          : a?.sk1.toString() || ''
        const bText = parseInt(b?.thread_name)
          ? b?.thread_name
          : b?.sk1.toString() || ''
        return aText?.localeCompare(bText)
      })

      const sortedWords = wordsThreads.sort((a, b) => {
        const aText = a?.thread_name ? a?.thread_name : ''
        const bText = b?.thread_name ? b?.thread_name : ''
        return aText?.localeCompare(bText)
      })

      return sortedNumbers.concat(sortedWords)
    case 'message_time':
      return threadsToSort?.sort((a, b) =>
        a?.compareTime?.toString()?.localeCompare(b?.compareTime?.toString()),
      )
  }
}
