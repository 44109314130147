import styled from 'styled-components'
import { Toggle } from '@thryvlabs/maverick'

export const StyledToggleOn = styled(Toggle)`
  button {
    background-color: #16a085;
  }
`
export const StyledToggleOff = styled(Toggle)`
  button {
    background-color: #808080;
    border-style: none;
    span {
      border: 2px solid #808080;
    }
  }
`
