
const WebsiteBuilder = () => {
  const websiteBuilderUrl =
    import.meta.env.VITE_ENV === 'prod'
      ? 'https://website-builder.labs.thryv.com/'
      : 'https://dev-website-builder.sandbox.thryv.com'
  return (
    <div>
      <iframe style={{height: "calc(100vh - 66.18px"}} width="100%" src={websiteBuilderUrl} />
    </div>
  )
}

export default WebsiteBuilder
