import { useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useClickOutside } from '../../../hooks/use-click-outside'
import ActionButtonDropDown from './ActionButtonDropDownWeb'

export const CustomButtonGroup = ({

  setSelectedItem,
  subscription
}) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const dropDownRef = useRef(null)

  const items =
    subscription === 'Thryv Marketplace' || subscription === 'TeamChat'
      ? ['Upgrade']
      : ['Upgrade', 'Cancel']

  useClickOutside(() => {
    setShowDropDown(false)
  }, dropDownRef)

  return (
    <ActionButtonDropDown
      ref={dropDownRef}
      isMobile={isMobile}
      items={items}
      show={showDropDown}
      setShow={setShowDropDown}
      setSelectedItem={setSelectedItem}
    />
  )
}
