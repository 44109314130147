import { Button, RegularX } from '@thryvlabs/maverick'
import thryvIconCheckMark from '../../../../assets/ThryvIconCheckmark.png'

const SuccessModal = ({
  setOpenSuccessModal,
  modalHeaderMessage,
  modalBodyMessage,
}) => {
  const handleClose = function () {
    setOpenSuccessModal(false)
  }

  return (
    <div>
      <Button
        className="fixed right-[1%] top-[3%] mr-[0.5rem]"
        variant="text"
        level={2}
      >
        <RegularX
          type="regular"
          variant="x"
          height="18"
          fill="#A3A5A7"
          onClick={() => setOpenSuccessModal(false)}
        />
      </Button>

      <img
        className="w-[100px] h-[100px] m-[auto] mt-[1]"
        src={thryvIconCheckMark}
      />
      <h4 className="py-2 font-semibold w-fit m-[auto] text-black px-6 mt-[1rem] mb-[2rem]">
        {modalHeaderMessage}
      </h4>
      <div className="text-center sm:w-[99%] w-[382px] m-[auto] text-[14px] open-sans">
        {modalBodyMessage}
      </div>

      <div className="mt-12 text-sm flex justify-center">
        <Button className="ml-2" variant="primary" onClick={handleClose}>
          Done
        </Button>
      </div>
    </div>
  )
}

export default SuccessModal
