import { gql } from '@apollo/client'

export const FETCH_RECENT_CALLS = gql`
  query fetchRecentCalls($filter: RecentCallFilter) {
    allRecentCalls(filter: $filter) {
      id
      time
      agent_id
      date
      location
      username
      email
      phone
      type
      callDeleted
      voicemail
      voicemailDeleted
      voiceMailPlayed
      transcript
    }
  }
`

export const GET_CALLS_MINUTES = gql`
  query getCallsMinutes {
    queryCallsMinutesByCCID {
      BaseSeconds
      SecondsUsed
      SecondsRemaining
      AddOnQuantity
    }
  }
`

export const CLOUD_SEARCH_RECENT_CALLS = gql`
  query cloudSearchRecentCalls($chan_pk: String!) {
    queryCloudsearchCalls(input: { limit: 5, search: $chan_pk }) {
      sk1
      pk1
      body
      number_from
      chan_type
      number_to
      agent_id
      item_type
      timestamp
      threadpk
      is_read
    }
  }
`

export const CLOUD_SEARCH_RECENT_VOICEMAILS = gql`
  query cloudSearchRecentVoicemails($chan_pk: String!) {
    queryCloudsearchVoicemails(input: { limit: 5, search: $chan_pk }) {
      body
      sk1
      pk1
      chan_type
      agent_id
      number_from
      number_to
      item_type
      timestamp
      threadpk
      is_read
    }
  }
`
export const GET_CC_PLANS_INFO = gql`
  query getCcPlansInfo($CCID: String!, $currency: String) {
    queryRecurlyCommandCenterPlans(CCID: $CCID, currency: $currency) {
      code
      name
      price
    }
  }
`

export const GET_MESSAGES_BY_PHONE_CHANNEL = gql`
  query getRecentCallMessages($chanpk: String!, $item_type: String) {
    queryMessagesByPhoneChannel(chanpk: $chanpk, item_type: $item_type) {
      sk1
      pk1
      body
      number_from
      chan_type
      number_to
      agent_id
      item_type
      threadpk
      ts
      is_read
      chanpk
      msgpk
    }
  }
`

export const GET_VOICEMAIL_GREETING = gql`
  query queryVoicemailGreeting($input: VoicemailGreetingInput!) {
    queryVoicemailGreeting(input: $input) {
      url
    }
  }
`

export const GET_VOICEMAIL_AUDIO = gql`
  query queryVoicemailGreetingMetaData($phone_number: String!) {
    queryVoicemailGreetingMetaData(phone_number: $phone_number) {
      length
      name
    }
  }
`
