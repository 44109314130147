import { gql } from '@apollo/client'

export const CREATE_BRAND_CALL = gql`
  mutation CreateBrand(
    $channel_id: String!
    $brand_id: String
    $campaign_id: String
    $business_name: String!
    $entity_type: String!
    $dba: String!
    $vertical: String!
    $stock_symbol: String
    $stock_exchange: String
    $owner_first_name: String
    $owner_last_name: String
    $phone: String!
    $email: String!
    $street: String!
    $state: String!
    $city: String!
    $postal_code: String!
    $ein: String
    $privacy_policy_url: String
    $tc_url: String
    $support_contact: String
    $support_type: String
    $consent_method: String!
    $consent_details: String!
    $cta_image_url: String!
  ) {
    createBrand(
      input: {
        channel_id: $channel_id
        brand_id: $brand_id
        campaign_id: $campaign_id
        business_name: $business_name
        entity_type: $entity_type
        dba: $dba
        vertical: $vertical
        stock_symbol: $stock_symbol
        stock_exchange: $stock_exchange
        owner_first_name: $owner_first_name
        owner_last_name: $owner_last_name
        phone: $phone
        email: $email
        street: $street
        state: $state
        city: $city
        postal_code: $postal_code
        ein: $ein
        privacy_policy_url: $privacy_policy_url
        tc_url: $tc_url
        support_contact: $support_contact
        support_type: $support_type
        consent_method: $consent_method
        consent_details: $consent_details
        cta_image_url: $cta_image_url
      }
    ) {
      brand_id
    }
  }
`

export const CREATE_CAMPAIGN_CALL = gql`
  mutation CreateCampaign($brand_id: String!) {
    createCampaign(brand_id: $brand_id) {
      campaign_id
    }
  }
`

export const CREATE_OTP_CALL = gql`
  mutation CreateOTP($brand_id: String!) {
    createOTP(brand_id: $brand_id) {
      brand_id
      reference_id
    }
  }
`

export const CONFIRM_OTP_CALL = gql`
  mutation ConfirmOTP($otp: String!, $brand_id: String!, $reference_id: String!) {
    confirmOTP(
      input: { otp: $otp, brand_id: $brand_id, reference_id: $reference_id }
    )
  }
`

export const UPDATE_BRAND_CALL = gql`
  mutation UpdateBrand(
    $channel_id: String!
    $brand_id: String
    $campaign_id: String
    $business_name: String!
    $entity_type: String!
    $dba: String!
    $vertical: String!
    $stock_symbol: String
    $stock_exchange: String
    $owner_first_name: String
    $owner_last_name: String
    $phone: String!
    $email: String!
    $street: String!
    $state: String!
    $city: String!
    $postal_code: String!
    $ein: String
    $privacy_policy_url: String
    $tc_url: String
    $support_contact: String
    $support_type: String
    $consent_method: String!
    $consent_details: String!
    $cta_image_url: String!
  ) {
    updateBrand(
      input: {
        channel_id: $channel_id
        brand_id: $brand_id
        campaign_id: $campaign_id
        business_name: $business_name
        entity_type: $entity_type
        dba: $dba
        vertical: $vertical
        stock_symbol: $stock_symbol
        stock_exchange: $stock_exchange
        owner_first_name: $owner_first_name
        owner_last_name: $owner_last_name
        phone: $phone
        email: $email
        street: $street
        state: $state
        city: $city
        postal_code: $postal_code
        ein: $ein
        privacy_policy_url: $privacy_policy_url
        tc_url: $tc_url
        support_contact: $support_contact
        support_type: $support_type
        consent_method: $consent_method
        consent_details: $consent_details
        cta_image_url: $cta_image_url
      }
    ) {
      brand_id
      campaign_id
      channel_id
      business_name
      entity_type
      dba
      vertical
      stock_symbol
      stock_exchange
      owner_first_name
      owner_last_name
      phone
      email
      street
      state
      city
      postal_code
      ein
      privacy_policy_url
      tc_url
      created_at
      last_updated
      brand_status
      campaign_status
      brand_errors {
        name
        description
        fields
      }
      campaign_error_message
      support_contact
      support_type
      consent_method
      consent_details
      cta_image_url
    }
  }
`

export const UPDATE_CAMPAIGN_CALL = gql`
  mutation UpdateCampaign(
    $channel_id: String!
    $brand_id: String
    $campaign_id: String
    $business_name: String!
    $entity_type: String!
    $dba: String!
    $vertical: String!
    $stock_symbol: String
    $stock_exchange: String
    $owner_first_name: String
    $owner_last_name: String
    $phone: String!
    $email: String!
    $street: String!
    $state: String!
    $city: String!
    $postal_code: String!
    $ein: String
    $privacy_policy_url: String
    $tc_url: String
    $support_contact: String
    $support_type: String
    $consent_method: String!
    $consent_details: String!
    $cta_image_url: String!
  ) {
    updateCampaign(
      input: {
        channel_id: $channel_id
        brand_id: $brand_id
        campaign_id: $campaign_id
        business_name: $business_name
        entity_type: $entity_type
        dba: $dba
        vertical: $vertical
        stock_symbol: $stock_symbol
        stock_exchange: $stock_exchange
        owner_first_name: $owner_first_name
        owner_last_name: $owner_last_name
        phone: $phone
        email: $email
        street: $street
        state: $state
        city: $city
        postal_code: $postal_code
        ein: $ein
        privacy_policy_url: $privacy_policy_url
        tc_url: $tc_url
        support_contact: $support_contact
        support_type: $support_type
        consent_method: $consent_method
        consent_details: $consent_details
        cta_image_url: $cta_image_url
      }
    ) {
      brand_id
      campaign_id
      channel_id
      business_name
      entity_type
      dba
      vertical
      stock_symbol
      stock_exchange
      owner_first_name
      owner_last_name
      phone
      email
      street
      state
      city
      postal_code
      ein
      privacy_policy_url
      tc_url
      created_at
      last_updated
      brand_status
      campaign_status
      brand_errors {
        name
        description
        fields
      }
      campaign_error_message
      support_contact
      support_type
      consent_method
      consent_details
      cta_image_url
    }
  }
`

export const UPDATE_CHANNEL_READINESS_CALL = gql`
  mutation UpdateChannelReadiness(
    $channel_id: String!
    $access_level: Int!
    $sync_state: Int!
  ) {
    updateChannelReadiness(
      channel_id: $channel_id
      access_level: $access_level
      sync_state: $sync_state
    ) {
      CC_UserID
      AccessType
      ChannelID
      ExternalAccountID
      CCID
      AccountUserValue
      Status
      Provider
      VendorID
      syncState
      Nickname
      CountryCode
      ChannelAccessLevel
    }
  }
`

export const LINK_PHONE_NUMBER_CALL = gql`
  mutation LinkPhoneNumber($channel_id: String!, $phoneNumber: String!) {
    linkPhoneNumber(channel_id: $channel_id, phoneNumber: $phoneNumber)
  }
`
