import './add-staff-modal.css'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { HAS_AVAILABLE_SEATS_FOR_STAFF } from '../../../graphql'
import { AddStaffForm } from './add-staff-form/add-staff-form'
import { StaffConfirmationModal } from './staff-confirmation-modal/staff-confirmation-modal'
import UpgradePlanModal from '../modals/upgrade-plan-modal/UpgradePlanModal'
import { LoadingSpinner } from '../loading-spinner'

export const AddStaffModal = ({ close }) => {
  const { data, loading } = useQuery(HAS_AVAILABLE_SEATS_FOR_STAFF, {
    fetchPolicy: 'no-cache',
  })
  const [newStaff, setNewStaff] = useState('')
  const hasAvailableSeats = data?.seats.hasAvailableSeats === 'true' ? true : false

  if (loading) {
    return <LoadingSpinner />
  }
  // if no available seats, display upgrade plan modal
  if (!hasAvailableSeats && !newStaff) {
    return <UpgradePlanModal close={close} />
  }

  return newStaff ? (
    <StaffConfirmationModal close={close} staff={newStaff} />
  ) : (
    <AddStaffForm close={close} setNewStaff={setNewStaff} />
  )
}
