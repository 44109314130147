import { useState, useEffect } from 'react'
import { NavigationDesktop } from './components/navigation-desktop'
import { NavigationMobile } from './components/navigation-mobile'

export const CommandCenterNavigation = ({ linksArray }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth)
  const handleResize = () => {
    setScreenSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (screenSize > 768) {
    return <NavigationDesktop linksArray={linksArray} />
  } else {
    return <NavigationMobile linksArray={linksArray} />
  }
}
