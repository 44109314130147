import Cookies from 'js-cookie'

export const getRecentSearchesFromCookies = () => {
  return JSON.parse(Cookies.get('recent_inbox_searches'))
}

const wouldContainDuplicate = (arr, potentialDuplicateText) => {
  if (arr.includes(potentialDuplicateText)) return true
  return false
}

export const addRecentSearchToCookies = (recentSearchText) => {
  let recentSearches = getRecentSearchesFromCookies()

  if (recentSearches[0] === recentSearchText) return

  if (wouldContainDuplicate(recentSearches, recentSearchText)) {
    recentSearches = recentSearches.filter(
      (recentSearch) => recentSearch !== recentSearchText,
    )
  }

  if (recentSearches.length === 3) recentSearches.pop()

  recentSearches.unshift(recentSearchText)

  Cookies.set('recent_inbox_searches', JSON.stringify(recentSearches), {
    expires: 7,
  })
}
