import { createSlice } from '@reduxjs/toolkit'

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: {
      // * VITE_CALLS_CONNECTION is a string instead of boolean
      // by default it's false, please use get a number in calls page to set it to true
      connection: false,
      contactsList: [],
      filteredContacts: [],
      phoneNumber: '',
      phoneChannelID: '',
    },
    selectedContact: {
      id: '',
      username: '',
      phone: '',
      email: '',
      job: '',
      location: '',
    },
    searchValue: '',
    voicemailMetaData: {
      name: '',
      length: 0,
    },
  },
  reducers: {
    selectContact(state, action) {
      // this condition will be used for solving recentCall can't be shared with contact slice from call slice
      // since recentCall and contact are correlated with each other by "phone"
      if (action.payload.recentCall) {
        const { recentCall } = action.payload
        return {
          ...state,
          selectedContact: state.contacts.contactsList.length
            ? recentCall.username
              ? state.contacts.contactsList.find(
                  (contact) => contact.phone === recentCall.phone,
                )
              : // if select an unkown contact, just uesd unkown contact's info
                recentCall
            : state.selectedContact,
        }
      } else {
        return {
          ...state,
          selectedContact: state.contacts.contactsList.length
            ? state.contacts.contactsList.find(
                (contact) => contact.id === action.payload,
              )
            : state.selectedContact,
        }
      }
    },
    loadAppoloContactsState: (state, action) => {
      state.contacts = { ...state.contacts, ...action.payload }
    },
    selectContactOnPageLoad: (state) => {
      state.selectedContact = state.contacts.contactsList[0]
    },
    searchContactByNameOrEmail: (state, action) => {
      state.searchValue = action.payload
      state.contacts.filteredContacts = [
        ...state.contacts.contactsList.filter((contact) => {
          return (
            contact?.email?.toLowerCase()?.includes(action.payload.toLowerCase()) ||
            contact?.username?.toLowerCase()?.includes(action.payload.toLowerCase())
          )
        }),
      ]
    },
    searchContactByPhone: (state, action) => {
      state.searchValue = action.payload
      state.contacts.filteredContacts = [
        ...state.contacts.contactsList.filter((contact) =>
          contact?.phone?.includes(action.payload),
        ),
      ]
    },
    resetSearch: (state) => {
      state.searchValue = ''
      state.contacts.filteredContacts = state.contacts.contactsList
    },
    setUserPhoneNumber: (state, action) => {
      state.contacts = {
        ...state.contacts,
        phoneNumber: action.payload,
        connection: true,
      }
    },
    setUserphoneChannelID: (state, action) => {
      state.contacts = {
        ...state.contacts,
        phoneChannelID: action.payload,
      }
    },
    setVoicemailMetaData(state, action) {
      state.voicemailMetaData = action.payload
    },
  },
})

export const {
  searchContactByNameOrEmail,
  searchContactByPhone,
  selectContact,
  selectContactOnPageLoad,
  loadAppoloContactsState,
  setUserPhoneNumber,
  resetSearch,
  setUserphoneChannelID,
  setVoicemailMetaData,
} = contactsSlice.actions
export default contactsSlice.reducer
