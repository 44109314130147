import { Header, Icon, Button } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'

const PermissionRequest = ({ connectToWebchat }) => {
  return (
    <div className="bg-white px-8">
      <Header
        fontWeight="bold"
        variant="h7"
        className="px-[40.5px] pt-[50px] pb-[30px] sm:pt-[15px] sm:pb-[20px]"
      >
        What will this application do?
      </Header>
      <div className="text-left px-[1rem]">
        <p
          className="py-2 gap-1 flex items-center font-montserrat text-sm"
          color="#000000"
        >
          <span className="">
            <Icon type="solid" variant="check" color="#5378FC" height="12" />
          </span>
          Link your WebChat account to your Thryv account.
        </p>
        <p
          className="py-2 gap-1 items-center flex font-montserrat text-sm"
          color="#000000"
        >
          <span className="">
            <Icon type="solid" variant="check" color="#5378FC" height="12" />
          </span>
          You can receive and respond to messages received from your Thryv Command
          Center Inbox.
        </p>
        <p
          className="py-2 gap-1 flex items-center font-montserrat text-sm"
          color="#000000"
        >
          <span className="">
            <Icon type="solid" variant="check" color="#5378FC" height="12" />
          </span>
          Receive message updates regarding the status of messages sent and received.
        </p>
      </div>

      <Button
        onClick={connectToWebchat}
        variant="primary"
        className="h-[32px] mt-[1rem] mr-[2%] mb-[2rem]"
      >
        <FontAwesomeIcon className="text-[16px] text-[#fff]" icon={faLink} /> Connect
        To WebChat
      </Button>
    </div>
  )
}

export default PermissionRequest
