import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
  mutation ($id: ID!, $phoneNumber: String!, $connection: Boolean!) {
    updateUser(id: $id, phoneNumber: $phoneNumber, connection: $connection) {
      id
      phoneNumber
      connection
    }
  }
`
