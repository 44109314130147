import { useRegisterPhoneModal } from './RegisterPhoneModal'
import { useSelector, useDispatch } from 'react-redux'
import usePhoneChannel from '../../../../../hooks/use-phone-channel'
import { updateInboxNotif } from '../../../../inbox/slices/inbox-notifications/inbox-notifications-slice'

// Components
import { Button } from '@thryvlabs/maverick'
import { Notification } from '../../../notification'

const RegisterPhoneNotifications = ({ className }) => {
  const dispatch = useDispatch()

  const [openRegisterModal, , RegisterPhoneModal, openOTPFlow] =
    useRegisterPhoneModal()
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )

  const { importedRegisterPhoneNotif } = useSelector(
    (state) => state.inboxNotifications,
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const linkStatus = useSelector(
    (state) => state.phoneRegistration.brand?.link_status,
  )
  const needsOTPConfirmation = useSelector(
    (state) => state.phoneRegistration.brand?.needs_confirmation,
  )
  const loading = useSelector((state) => state.phoneRegistration.loading)
  const [phoneChannel] = usePhoneChannel()
  const brandID = useSelector((state) => state.phoneRegistration.brand_id)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const registerPhoneNotif = () => {
    const notifColors = {
      yellowPre: {
        variant: 'warning',
        textToRender: (
          <span>
            Due to{' '}
            <a
              href="https://learn.thryv.com/hc/en-us/articles/17089148474253-Why-Do-I-Need-To-Register-My-Number-With-TCR-"
              target="_blank"
              className="text-[#057AFF]"
              rel="noreferrer"
            >
              mobile carrier regulation
            </a>
            , you need to register your number. Until you do, you will not be able to
            text.{' '}
            <Button variant="text" level={1} onClick={openRegisterModal}>
              Click here to register your number!
            </Button>
          </span>
        ),
      },
      yellow: {
        variant: 'warning',
        textToRender:
          'Due to recent regulation, mobile carriers have a backlog causing delays in registration. Most registrations should complete within 4 weeks. We expect carrier response times to improve in the coming weeks.',
      },
      yellowOTP: {
        variant: 'warning',
        textToRender: (
          <span>
            You have not completed your SMS registration.{' '}
            <Button variant="text" level={1} onClick={openOTPFlow}>
              Click here to finish your registration.
            </Button>
          </span>
        ),
      },
      green: {
        variant: 'success',
        textToRender:
          'Great news! Your SMS registration has been approved. You can now text your contacts!',
      },
      red: {
        variant: 'error',
        iconVariant: 'circleExclamation',
        textToRender: (
          <span>
            The mobile carriers have declined your SMS registration. Think this was
            in error?{' '}
            <Button variant="text" level={1} onClick={openRegisterModal}>
              Resubmit now!
            </Button>
          </span>
        ),
      },
    }

    if (countryISO === 'US' && brandStatus === 'UNVERIFIED') {
      return notifColors['red']
    }

    if (needsOTPConfirmation) {
      return notifColors['yellowOTP']
    }

    if (
      countryISO === 'US' &&
      (brandStatus === 'PENDING' || brandStatus === 'ACTIVE')
    ) {
      return notifColors['yellow']
    }

    if (countryISO === 'US' && brandStatus === 'VERIFIED') {
      if (campaignStatus === 'PENDING_REVIEW') {
        return notifColors['yellow']
      } else if (
        campaignStatus === 'UPDATES_REQUIRED' ||
        campaignStatus === 'TERMINATED'
      ) {
        return notifColors['red']
      } else if (
        campaignStatus === 'ACTIVE' &&
        (linkStatus === 'APPROVED' || linkStatus === 'LINKED')
      ) {
        return notifColors['green']
      }
    }

    if (
      countryISO === 'US' &&
      phoneChannel?.ChannelAccessLevel === '1' &&
      brandStatus === undefined &&
      brandID === null
    ) {
      return notifColors['yellowPre']
    }

    return {}
  }

  const getNotificationProps = registerPhoneNotif()

  const handleClose = () => {
    dispatch(
      updateInboxNotif({
        show: false,
        name: 'importedRegisterPhoneNotif',
      }),
    )
  }

  return (
    <>
      {getNotificationProps?.variant &&
        importedRegisterPhoneNotif.show &&
        !loading && (
          <div className={`${className} rounded-[10px]`}>
            <Notification
              className="rounded-lg"
              iconType="solid"
              close={handleClose}
              {...getNotificationProps}
            />
          </div>
        )}
      {RegisterPhoneModal}
    </>
  )
}

export default RegisterPhoneNotifications
