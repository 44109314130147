import { AnimatedNotificationContainer } from '../../../../../components/inbox/message-content/inbox-notifications/animated-notification-container/animated-notification-container'
import { Notification } from '../../../../../components/common/notification'

export const PersonalInfoNotification = ({
  className,
  show,
  variant,
  close,
  message,
}) => {
  return (
    <div className={className}>
      <AnimatedNotificationContainer show={show}>
        <Notification
          className="relative bg-blue-500 p-2 rounded-lg"
          iconType={'solid'}
          variant={variant}
          textToRender={message}
          close={close}
        />
      </AnimatedNotificationContainer>
    </div>
  )
}
