import { gql } from '@apollo/client'

export const CREATE_STAFF = gql`
  mutation create_staff_mutation(
    $CC_UserID: ID
    $FirstName: String
    $LastName: String
    $Email: String
    $Phone: String
    $Status: String
    $Role: String
    $Type: String
    $CountryCode: String
    $ChannelAccess: [StaffChannelAccessInput]
  ) {
    createStaff(
      input: {
        CC_UserID: $CC_UserID
        FirstName: $FirstName
        LastName: $LastName
        Email: $Email
        Phone: $Phone
        Status: $Status
        Role: $Role
        Type: $Type
        CountryCode: $CountryCode
        ChannelAccess: $ChannelAccess
      }
    ) {
      CC_UserID
      CCID
      FirstName
      LastName
      Email
      Phone
      Status
      Role
      Type
      CountryCode
      CreatedAt
      UpdatedAt
    }
  }
`

export const DELETE_STAFF = gql`
  mutation delete_staff_mutation($UserId: ID!) {
    deleteStaff(CC_UserID: $UserId)
  }
`

/* the `Email` field is commented out in the BE */
export const UPDATE_STAFF = gql`
  mutation update_staff_mutation(
    $CC_UserID: ID!
    $LocationID: String!
    $FirstName: String
    $LastName: String
    $Phone: String
    $Email: String
    $Status: String
    $Role: String
    $Type: String
    $CountryCode: String
  ) {
    updateStaff(
      input: {
        CC_UserID: $CC_UserID
        FirstName: $FirstName
        LastName: $LastName
        Phone: $Phone
        Email: $Email
        LocationID: $LocationID
        Status: $Status
        Role: $Role
        Type: $Type
        CountryCode: $CountryCode
      }
    ) {
      CCID
      CC_UserID
      CountryCode
      CreatedAt
      Email
      FirstName
      LastName
      Phone
      Role
      Status
      Type
      UpdatedAt
    }
  }
`

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation send_reset_password_email_mutation(
    $client_id: String!
    $email: String!
    $connection: String!
  ) {
    sendResetPasswordEmail(
      input: { client_id: $client_id, email: $email, connection: $connection }
    )
  }
`

export const UPDATE_STAFF_CHANNEL_PERMISSIONS = gql`
  mutation update_staff_channel_permissions_mutation(
    $UserID: String!
    $ChannelID: String!
    $AccessType: Long!
  ) {
    updatedPermissions: addUserChannelPermissions(
      UserID: $UserID
      ChannelID: $ChannelID
      AccessType: $AccessType
    ) {
      AccessType
      ChannelID
      UserID
    }
  }
`

export const ADD_USER_CHANNEL_PERMISSIONS_BULK = gql`
  mutation add_user_channel_permissions_bulk($input: [PermissionUpdateObject!]!) {
    addUserChannelPermissionsBulk(input: $input)
  }
`
