import { useEffect, useState } from 'react'

export const MenuBackdrop = ({ className, show }) => {
  const [showBackdrop, setShowBackdrop] = useState(show)

  useEffect(() => {
    if (show === false) setTimeout(() => setShowBackdrop(false), 200)
    else setShowBackdrop(true)
  }, [show])

  return (
    <div
      className={`absolute h-screen w-screen bg-[#231F2066] 
      ${className}
      ${showBackdrop && '!block'} hidden`}
    ></div>
  )
}
