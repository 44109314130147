import { Header } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { toggleAttachmentsModal } from '../../../inbox/message-content/message-header/header-menu/buttons/tri-dot-button-with-menu/tri-dot-menu/modalSlice'
import useSharedMediaProps from '../shared-photos/useSharedMediaProps'
import { AllSharedFiles } from './AllSharedFiles'

const SharedFilesPreviewAll = ({ setShowAllFiles, sortedAttachmentData }) => {
  const { allSharedFilesProps } = useSharedMediaProps()

  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 835 })

  return (
    <div className="grid grid-flow-row min-h-[100px] md:h-full md:w-[390px] sm:w-[290px]">
      {sortedAttachmentData?.length > 0 && (
        <div className="grid grid-cols-2 gap-4 w-[310px]">
          <Header fontWeight="semibold" variant="h7">
            Shared Files
          </Header>
          <button
            className="ml-[85px] self-start"
            onClick={() => {
              if (isMobile) {
                dispatch(toggleAttachmentsModal(false))
              } else {
                setShowAllFiles(false)
              }
            }}
          >
            {!isMobile && (
              <p className="text-[#057AFF] text-sm" fontWeight="semibold">
                Go Back
              </p>
            )}
            {isMobile && (
              <p className="text-[#057AFF] text-sm" fontWeight="semibold">
                Close
              </p>
            )}
          </button>
        </div>
      )}
      <AllSharedFiles {...allSharedFilesProps} />
      {sortedAttachmentData?.length === 0 && (
        <p className="text-thryv-gray-medium-500 self-start mt-5">
          Your sent and received attachments will appear here.
        </p>
      )}
    </div>
  )
}

export default SharedFilesPreviewAll
