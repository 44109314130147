import { ParagraphText, Icon } from '@thryvlabs/maverick'

export const TableHead = ({ children }) => {
  return (
    <button className="flex items-center justify-between capitalize py-8 px-2 gap-3 mx-4 ">
      <ParagraphText variant="lg" className="font-semibold " color="thryv-steel">
        {children}
      </ParagraphText>
      <Icon variant="chevronDown" type="solid" width="11" fill="#808080" />
    </button>
  )
}
