import { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  setShowCustomNotification,
  setShowReminderNotification,
} from '../slices/remindMeSlice'
import {
  setRecipient,
  setChannelId,
  setConversationLimit,
} from '../slices/inboxSlice'
import { useReactToPrint } from 'react-to-print'
import Html2Pdf from 'js-html2pdf'
import csvMethods from '../../../utils/csv-methods'
import {
  FETCH_MESSAGES_BY_THREAD_ID,
  GET_ALL_THUMBNAIL,
  GET_PINNED_MESSAGES,
} from '../../../graphql/queries'
import { useQuery, useLazyQuery } from '@apollo/client'
import moment from 'moment'
import {
  IncomingMessageContext,
  OutgoingMessageContext,
} from '../../../context/message-context'
import { useAuth0 } from '@auth0/auth0-react'
import { CommandCenterInboxIcon } from '../../command-center-icon/icons'
import '../message-content/message-components/outgoing-message/outgoing-message.css'

import { setError } from '../slices/inboxSlice'
import { setOpenVideoCallLinkModal } from '../../calls/slices/callSlice'

// Components
import VideoCallLinkModal from '../../calls/video-call-link-modal/VideoCallLinkModal'
import {
  IncomingMessage,
  OutgoingMessage,
  PlaceholderMessage,
} from './message-components'
import { DaySeparator } from './day-separator'
import { MessageCompose } from './message-compose'
import { MessageHeader } from './message-header'
import { MessageSubheader } from './message-subheader'
import RightPanelButtons from '../../right-panel/RightPanelButtons'

import { Header, ParagraphText } from '@thryvlabs/maverick'
import CustomModal from '../../common/CustomModal/Modal'
import { ChannelsModalComponent } from '../../common/modals/channels-modal-component/ChannelsModalComponent'
import { LoadingSpinner } from '../../common/loading-spinner/loading-spinner'
import { FETCH_CONTACT_BY_THREAD } from '../../../graphql/queries'
import { setIncomingContactName } from '../slices/inboxSlice'
import { useMediaQuery } from 'react-responsive'
import { FirstChannelAddedModal } from '../../common/modals/first-channel-added-modal/FirstChannelAddedModal'
import {
  setShowFirstChannelModal,
  setShowModal,
} from '../../common/modals/slices/connectChannelsSlice'
import { InboxNotifications } from './inbox-notifications'
import { LatestMessagesButton } from './common/latest-messages-button/latest-messages-button'

import { LabelButtonWithModal } from './labels-menu/create-new-label-button/create-new-label-button'
import { setIsViewingWelcomeMessage } from '../slices/welcomeSlice'
import { setThreadData } from '../slices/inboxSlice'
import { updateAttachmentWithThumbnail } from './utils/updateAttachmentWithThumbnail'
import { getAttachmentS3Paths } from './utils/getAttachmentS3Path'
import { handleGetAllAttachments } from './utils/handleGetAllAttachments'

export const MessageContent = forwardRef(
  (
    {
      showFiles,
      setShowFiles,
      showProfile,
      setShowProfile,
      setShowPanel,
      showPanel,
    },
    ref,
  ) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const isMobile = useMediaQuery({ maxWidth: 640 })
    const messageContentRef = useRef(null)
    const isMounted = useRef(false)
    const oldestCurrentMessageRef = useRef(null)
    const messages = useSelector((state) => state.messageContent.messages)
    const showCustomNotification = useSelector(
      (state) => state.remindMe.showCustomNotification,
    )
    const showReminderNotification = useSelector(
      (state) => state.remindMe.showReminderNotification,
    )

    const { isLoading, conversationLimit } = useSelector((state) => state.inbox)

    const { showModal, showFirstChannelModal } = useSelector(
      (state) => state.connectChannels,
    )
    const {
      editorText,
      editorHTML,
      selectedChannel,
      subjectText,
      unsendButtonClicked,
    } = useSelector((s) => s.messageCompose)

    const { inboxAdvancedFilters, inboxPinnedThreadIds } = useSelector(
      (state) => state.inboxThreads,
    )

    const { user: authUser } = useAuth0()

    const conversationHeaderRef = useRef(null)

    const [messageLabels, setMessageLabels] = useState(
      useSelector((state) => state.messageContent.labels),
    )
    const [labelsBoxesChecked, setLabelsBoxesChecked] = useState([])
    const [newThread, setNewThread] = useState(true)
    const [labelsClear, setLabelsClear] = useState(false)
    const [labelsSelected, setLabelsSelected] = useState(false)
    const [limitAttachmentError, setLimitAttachmentError] = useState(false)
    const { threadData } = useSelector((state) => state.inbox)
    const [lastMessage, setLastMessage] = useState(threadData[threadData.length - 1])
    const [hasNextToken, setHasNextToken] = useState(true)
    const [pinnedMessages, setPinnedMessages] = useState([])
    const [scrollPosition, setScrollPosition] = useState(1776)

    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )

    const { channelInfo } = useSelector((state) => state.connectChannels)
    const { labels, showCreateLabelModal } = useSelector(
      (state) => state.messageContent,
    )
    const [draftMessageAttachments, setDraftMessageAttachments] = useState([])
    const [contactDetails, setContactDetails] = useState({
      body: null,
      contactsk2: '',
      contactsk3: '',
    })

    const [contactName, setContactName] = useState('')
    const openVideoCallLinkModal = useSelector(
      (state) => state.calls.openVideoCallLinkModal,
    )
    const selectedThreadName = useSelector(
      (state) => state.inboxThreads.selectedThread.name,
    )
    const { sendButtonClicked, attachmentFilesToDelete } = useSelector(
      (state) => state.messageCompose,
    )
    const ccEmailRecipient = useSelector(
      (state) => state.messageCompose.message.emailRecipients.cc,
    )

    const bccEmailRecipient = useSelector(
      (state) => state.messageCompose.message.emailRecipients.bcc,
    )

    const createMessageError = useSelector((state) => state.inbox.error.status)

    const [draftMessageData, setDraftMessageData] = useState()
    const getMessages = useQuery(FETCH_MESSAGES_BY_THREAD_ID, {
      variables: {
        threadid: selectedThreadId,
        first: conversationLimit,
      },
      skip: !selectedThreadId || selectedThreadId === '',
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.queryMessagesByThread?.draft) {
          setDraftMessageData(data?.queryMessagesByThread?.draft)
        } else {
          setDraftMessageData(null)
        }
        if (!newThread) {
          setScrollPosition(messageContentRef.current.scrollHeight)
        } else {
          setScrollPosition(0)
          if (messageContentRef.current) {
            setTimeout(() => {
              messageContentRef.current.scrollTop =
                messageContentRef.current.scrollHeight
            }, 10)
          }
        }
        if (!data?.queryMessagesByThread?.nextToken) {
          setHasNextToken(false)
        } else {
          setHasNextToken(true)
        }
        setNewThread(false)
        dispatch(setThreadData([])) //setting it to empty to prevent old data being on any other messages/threads
        getIncomingContactName(data.queryMessagesByThread)
        const messages = data.queryMessagesByThread.items.map((message) => {
          return {
            ...message,
            visible: true,
          }
        })
        dispatch(setThreadData(messages?.reverse()))
        setLastMessage(messages[messages.length - 1])
        setDraftMessageAttachments(
          data?.queryMessagesByThread.draft.attachments.items,
        )
        const channelId = data.queryMessagesByThread.items[0]?.chanpk
        dispatch(setChannelId(channelId))
      },
    })
    const messagesLoading = getMessages.loading
    const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
      variables: {
        threadid: selectedThreadId,
      },
      skip: !selectedThreadId || selectedThreadId === '',
      onCompleted: () => {
        const newRecipient = JSON.parse(
          contact?.queryContactsByThread?.items[0]?.body,
        )
        dispatch(
          setRecipient({
            email: newRecipient?.emails[0]?.email,
            id: newRecipient.id,
            phoneNumber: newRecipient?.phone_numbers[0]?.number,
            name: newRecipient?.emails[0]?.email
              ? newRecipient?.emails[0]?.email
              : newRecipient?.phone_numbers[0]?.number
                ? newRecipient?.phone_numbers[0]?.number
                : '',
          }),
        )
      },
    })

    const formatContactName = (name = '') => {
      let formattedName

      if (name?.length && name?.includes('.')) {
        formattedName = `${name?.split('.')[0]} ${name?.split('.')[1]}` || name
        formattedName =
          formattedName?.length && formattedName?.includes('@')
            ? formattedName?.split('@')[0]
            : formattedName
      }
      if (name?.length && name?.includes('.')) {
        formattedName = `${name?.split('.')[0]} ${name?.split('.')[1]}` || name
        formattedName =
          formattedName?.length && formattedName?.includes('@')
            ? formattedName?.split('@')[0]
            : formattedName
      }

      if (
        name?.length &&
        name?.includes('@') &&
        name?.length &&
        !name?.includes('.')
      ) {
        if (name?.split('@')[0]?.toLowerCase() === 'no-reply') {
          formattedName = name?.split('@')[0]
        } else {
          formattedName = `${name?.split('@')[0]} ${name?.split('@')[1]}` || name
        }
      }
      if (
        name?.length &&
        name?.includes('@') &&
        name?.length &&
        !name?.includes('.')
      ) {
        if (name?.split('@')[0]?.toLowerCase() === 'no-reply') {
          formattedName = name?.split('@')[0]
        } else {
          formattedName = `${name?.split('@')[0]} ${name?.split('@')[1]}` || name
        }
      }

      return `${formattedName
        ?.split(' ')[0]
        ?.charAt(0)
        ?.toUpperCase()}${formattedName?.split(' ')[0]?.slice(1)} ${formattedName
        ?.split(' ')[1]
        ?.charAt(0)
        ?.toUpperCase()}${formattedName?.split(' ')[1]?.slice(1)}`
    }

    useEffect(() => {
      if (contact?.queryContactsByThread?.items?.length) {
        setContactDetails({
          body: JSON.parse(contact?.queryContactsByThread?.items[0]?.body) || {},
          contactsk2: contact?.queryContactsByThread?.items[0]?.contactsk2,
          contactsk3: contact?.queryContactsByThread?.items[0]?.contactsk3,
        })
      }
    }, [contact, setContactDetails])
    useEffect(() => {
      if (contact?.queryContactsByThread?.items?.length) {
        setContactDetails({
          body: JSON.parse(contact?.queryContactsByThread?.items[0]?.body) || {},
          contactsk2: contact?.queryContactsByThread?.items[0]?.contactsk2,
          contactsk3: contact?.queryContactsByThread?.items[0]?.contactsk3,
        })
      }
    }, [contact, setContactDetails])

    useEffect(() => {
      if (contactDetails.body?.given_name?.length) {
        const {
          given_name = '',
          surname = '',
          middle_name = '',
          emails,
        } = contactDetails.body

        if (/\d{10}/.test(given_name)) {
          setContactName(given_name)
        } else {
          const formattedGivenName =
            typeof given_name === 'string' && given_name.length > 0
              ? `${given_name.charAt(0).toUpperCase()}${given_name.slice(1)} `
              : ''

          const formattedMiddleName =
            typeof middle_name === 'string' && middle_name.length > 0
              ? `${middle_name.charAt(0).toUpperCase()}${middle_name.slice(1)} `
              : ''
          const formattedSurname =
            typeof surname === 'string' && surname.length > 0
              ? `${surname.charAt(0).toUpperCase()}${surname.slice(1)}`
              : ''

          const parsedName =
            formattedGivenName.length > 0 && !formattedGivenName.includes('Unknown')
              ? middle_name === given_name || middle_name === surname
                ? `${formattedGivenName} ${formattedSurname}`
                : `${formattedGivenName} ${formattedMiddleName} ${formattedSurname}`
              : !formatContactName(emails[0]?.email).includes('undefined')
                ? formatContactName(emails[0]?.email)
                : emails[0]?.email

          setContactName(parsedName)
        }
      }

      if (
        !contactDetails.body?.given_name?.length &&
        contactDetails.contactsk2?.length
      ) {
        setContactName(contactDetails?.contactsk2)
      }

      if (
        !contactDetails.body?.given_name?.length &&
        !contactDetails.contactsk2?.length &&
        contactDetails.contactsk3?.length
      ) {
        setContactName(contactDetails?.contactsk3)
      }
    }, [contactDetails, setContactName])

    const getIncomingContactName = (data) => {
      if (data.items.length === 0) {
        dispatch(setIncomingContactName(selectedThreadName))
        return
      } else {
        data.items.forEach((message) => {
          if (message?.email_from?.email === selectedThreadName) {
            dispatch(setIncomingContactName(message.email_from.name))
            return
          } else if (message?.dm_from) {
            dispatch(setIncomingContactName(message.dm_from))
            return
          } else if (message?.number_from) {
            dispatch(setIncomingContactName(message.number_from))
            return
          } else {
            dispatch(setIncomingContactName(selectedThreadName))
            return
          }
        })
      }
    }

    const { data: pinnedData, refetch: refetchPins } = useQuery(
      GET_PINNED_MESSAGES,
      {
        variables: {
          threadid: selectedThreadId,
        },
        skip: !selectedThreadId || selectedThreadId === '',
        onCompleted: () => {
          setPinnedMessages(pinnedData.queryMessagesByPinned)
        },
      },
    )

    useEffect(() => {
      if (
        isMounted.current &&
        messageContentRef.current &&
        conversationLimit <= 10
      ) {
        messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight
      } else {
        isMounted.current = true
      }
    }, [threadData])

    useEffect(() => {
      setMessageLabels(labels)
    }, [labels])

    const messageHandlers = {
      print: {
        messages: useReactToPrint({
          content: () => messageContentRef.current,
        }),
      },

      download: {
        pdf: useReactToPrint({
          onPrintError: () => {},
          content: () => messageContentRef.current,
          print: async (printIframe) => {
            {
              /* https://github.com/gregnb/react-to-print/issues/484#issuecomment-1474454265 */
            }

            const document = printIframe.contentDocument

            if (document) {
              const elemToDownload = document.getElementById('message-container')
              const exporter = new Html2Pdf(elemToDownload, {
                filename: 'thryv-conversations.pdf',
              })
              await exporter.getPdf(true)
            }
          },
        }),

        csv: () => {
          const csv = csvMethods.create(messages, [
            'showSendReceipt',
            'variant',
            'labels',
            'attachments',
            'isPinned',
            'hideHeader',
            'disableButtons',
            'imgUrl',
            'footerContent',
          ])

          csvMethods.download(csv, 'thryv-conversations')
        },
      },
    }

    const messageLabelsHandler = {
      modify: {
        labelChecked: (data) => {
          setLabelsClear(false)
          const { LabelName, checked } = data.toggledCheckbox
          if (checked) {
            let filteredThreadData = threadData.map((message) => {
              const messsageHasLabel = containsLabel(message.labels, LabelName)
              if (messsageHasLabel) {
                return { ...message, visible: true }
              }
              return { ...message, visible: false }
            })
            dispatch(setThreadData(filteredThreadData))
            const newCheckedMessageLabels = messageLabels.map((messageLabel) => {
              if (messageLabel.LabelName === LabelName) {
                return { ...messageLabel, checked: true }
              }
              return messageLabel
            })
            setMessageLabels(newCheckedMessageLabels)
          }
          if (!checked) {
            let filteredThreadData = threadData.map((message) => {
              const messageHasAnyAtLeastOneLabel = messageHasAnyCheckedLabels(
                message.labels,
                data.checkboxes,
              )
              if (messageHasAnyAtLeastOneLabel) {
                return { ...message, visible: true }
              }
              return { ...message, visible: false }
            })

            let labelsBoxesCheckedCopy = [...labelsBoxesChecked]
            labelsBoxesCheckedCopy.splice(
              labelsBoxesCheckedCopy.indexOf(LabelName),
              1,
            )
            setLabelsBoxesChecked(labelsBoxesCheckedCopy)
            dispatch(setThreadData(filteredThreadData))

            const newUncheckedMessageLabels = messageLabels.map((messageLabel) => {
              if (messageLabel.LabelName === LabelName) {
                return { ...messageLabel, checked: false }
              }
              return messageLabel
            })
            setMessageLabels(newUncheckedMessageLabels)
          }
        },
        clearLabels: () => {
          setLabelsClear(true)
          setLabelsBoxesChecked([])
          const clearedMessages = threadData.map((message) => {
            return { ...message, visible: true }
          })
          dispatch(setThreadData(clearedMessages))
          //also set all messages to checked false
          const clearedMessageLabels = messageLabels.map((label) => {
            return { ...label, checked: false }
          })
          setMessageLabels(clearedMessageLabels)
        },
      },
    }
    const containsLabel = (labels, LabelName) => {
      const currentSelection = labels.some((label) => {
        return label.body === LabelName
      })
      const msgLabelNames = labels.map((msgLabel) => {
        return msgLabel.body
      })
      const previousSelection = labelsBoxesChecked.every((label) => {
        return msgLabelNames.indexOf(label) > -1
      })
      setLabelsBoxesChecked([...labelsBoxesChecked, LabelName])
      return currentSelection && previousSelection
    }

    const messageHasAnyCheckedLabels = (msgLabels, labelOptions) => {
      let messageHasAtLeastOneCheckedLabel = true
      labelOptions.forEach((option) => {
        if (option.checked) {
          messageHasAtLeastOneCheckedLabel = msgLabels.some((msgLabel) => {
            return msgLabel.body === option.LabelName
          })
        }
      })
      return messageHasAtLeastOneCheckedLabel
    }

    useEffect(() => {
      if (location.pathname === '/inbox/add-channels') {
        dispatch(setShowModal(true))
      }
    }, [])

    useEffect(() => {
      setNewThread(true)
      setScrollPosition(0)
      sessionStorage.removeItem('scrollPosition')
    }, [selectedThreadId])

    const recordScrollPosition = () => {
      if (messageContentRef?.current) {
        const scrollHeight = messageContentRef.current.scrollHeight
        sessionStorage.setItem('scrollPosition', scrollHeight - scrollPosition)
      }
    }

    const handleScrollPosition = () => {
      const scrollPosition = sessionStorage.getItem('scrollPosition')
      if (scrollPosition && messageContentRef?.current) {
        messageContentRef.current.scrollTop = parseInt(scrollPosition)
      }
    }

    const handleScroll = async () => {
      if (
        messageContentRef?.current &&
        threadData.length > conversationLimit &&
        messageContentRef.current.scrollHeight > 1000
      ) {
        messageContentRef.current.scrollTop =
          messageContentRef.current.scrollHeight - scrollPosition
      }
      if (
        messageContentRef?.current?.scrollTop === 0 &&
        threadData?.length >= conversationLimit &&
        !newThread &&
        hasNextToken
      ) {
        recordScrollPosition()
        dispatch(setConversationLimit(conversationLimit + 10))
        await getMessages.refetch()
        if (oldestCurrentMessageRef?.current) {
          oldestCurrentMessageRef.current.scrollIntoView(true)
        }
      }
    }

    useEffect(() => {
      if (!isLoading) setScrollPosition(0)
    }, [isLoading])

    useEffect(() => {
      handleScrollPosition()
    }, [threadData, conversationLimit])

    useEffect(() => {
      if (!messagesLoading) {
        messageContentRef.current?.addEventListener('scroll', handleScroll)
        return () => {
          messageContentRef.current?.removeEventListener('scroll', handleScroll)
        }
      }
    }, [messageContentRef, threadData, scrollPosition, messagesLoading])

    useEffect(() => {
      if (showCustomNotification) {
        setTimeout(() => {
          dispatch(setShowCustomNotification(false))
        }, 5000)
      }
    }, [showCustomNotification])

    useEffect(() => {
      if (showReminderNotification) {
        setTimeout(() => {
          dispatch(setShowReminderNotification(false))
        }, 5000)
      }
    }, [showReminderNotification])

    useEffect(() => {
      dispatch(setError({ status: false, message: '' }))
    }, [selectedThreadId])

    useEffect(() => {
      if (labelsBoxesChecked.length > 0) {
        setLabelsSelected(true)
      } else if (labelsBoxesChecked.length === 0) {
        setLabelsSelected(false)
      }
    }, [labelsBoxesChecked])

    useEffect(() => {
      setLabelsSelected(false)
      messageLabelsHandler.modify.clearLabels()
    }, [selectedThreadId])

    useEffect(() => {
      if (threadData && threadData.length > 0) {
        const mostRecentMessage = threadData[threadData.length - 1]
        if (
          mostRecentMessage?.email_from?.email?.toLowerCase() ===
            'support@thryv.com' &&
          mostRecentMessage?.email_subject === 'Welcome to Command Center Inbox!'
        ) {
          dispatch(setIsViewingWelcomeMessage(true))
        } else {
          dispatch(setIsViewingWelcomeMessage(false))
        }
      } else {
        dispatch(setIsViewingWelcomeMessage(false))
      }
    }, [threadData])

    const handleChanType = () => {
      if (selectedChannel?.Provider === 'phone') {
        return 'SMS'
      } else {
        return 'MSG'
      }
    }
    useEffect(() => {
      handleChanType()
    }, [selectedChannel])

    const displayPlaceholderMessage =
      !unsendButtonClicked &&
      (sendButtonClicked || isLoading || createMessageError) ? (
        <PlaceholderMessage
          body={editorText}
          draftMessageData={draftMessageData}
          outgoingId={1}
          className="ml-auto mr-0 outgoing-msg"
          selectedThreadId={selectedThreadId}
          confirmAttachment={attachmentFilesToDelete}
          refetchPins={refetchPins}
          refetchMessages={() => {
            getMessages.refetch()
          }}
          message={{
            title: subjectText,
            text: editorHTML,
            type: selectedChannel?.Provider,
            variant: handleChanType(),
            labels: null,
            threadpk: selectedThreadId,
            showSendReceipt: '',
            isPinned: false,
            attachments: attachmentFilesToDelete,
            emailRecipients: {
              cc: ccEmailRecipient,
              bcc: bccEmailRecipient,
            },
          }}
          user={{
            fullName: '',
            channelAddress: selectedChannel?.AccountUserValue,
          }}
          actionButtons={{
            clickHandlers: {
              onRemoveClick: () => {},
              onPinClick: () => {},
            },
          }}
          messages={editorHTML}
          messageContentRef={messageContentRef}
        />
      ) : null

    const isSunshineChannelInactive = (chan_type) => {
      const last24hoursTimestamp = Date.now() - 60 * 60 * 24 * 1000 //(60*60*24*1000);
      const sunshineChannel = chan_type === 'facebook' || chan_type === 'instagram'
      if (sunshineChannel) {
        const messages = getMessages.data?.queryMessagesByThread.items
        const incomingMessages = messages.filter(
          (message) => message.agent_id === null,
        )
        const sortedIncomingMessages = incomingMessages.sort((a, b) => a.sk1 - b.sk1)
        const lastIncomingMessage =
          sortedIncomingMessages[sortedIncomingMessages.length - 1]

        const value = lastIncomingMessage?.sk1 < last24hoursTimestamp

        return { sk1: lastIncomingMessage?.sk1, isInActive: value }
      } else {
        return { sk1: null, isInActive: false }
      }
    }

    const capitalizeFirstLetter = (str) => {
      return str?.charAt(0).toUpperCase() + str?.slice(1)
    }

    const messageContainsLabel = (labels, labelOptions) => {
      let messageHasAtLeastOneCheckedLabel = true
      labelOptions.forEach((option) => {
        messageHasAtLeastOneCheckedLabel = labels.some((msgLabel) => {
          return msgLabel.body === option
        })
      })
      return messageHasAtLeastOneCheckedLabel
    }

    const [attachmentData, setAttachmentData] = useState([]) //used to store the attachments data with thumbnail url

    const [getAllThumbnails] = useLazyQuery(GET_ALL_THUMBNAIL, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    })

    const useGetAllThumbnails = async () => {
      const messages = threadData
      const allAttachments = handleGetAllAttachments(messages)
      const attachmentsPaths = getAttachmentS3Paths(
        messages,
        selectedThreadId,
        allAttachments,
      )
      //this helps to set the attachments so the ui can render them even before getting the thumbnails
      const updatedFiles = await updateAttachmentWithThumbnail(messages, [])
      setAttachmentData([...updatedFiles])

      if (attachmentsPaths?.length !== 0) {
        getAllThumbnails({
          variables: {
            files: attachmentsPaths,
          },
        }).then(async (res) => {
          const allAttachmentsThumbnails = res.data.queryAttachments
          const updatedFiles = await updateAttachmentWithThumbnail(
            messages,
            allAttachmentsThumbnails,
          )
          setAttachmentData([...updatedFiles])
        })
      }
    }

    useEffect(() => {
      if (getMessages?.data?.queryMessagesByThread?.items?.length > 0) {
        useGetAllThumbnails()
      }
    }, [threadData])
    let messageCount = 0
    return (
      <div id="message-content" className="flex flex-col h-0 min-h-full relative">
        <div
          className="border-b border-[#F1F1F5] border-solid shadow-[0px_4px_4px_0px_#0000000A] z-10"
          ref={conversationHeaderRef}
        >
          <MessageHeader messageHandlers={messageHandlers} />
          <MessageSubheader
            labelsClear={labelsClear}
            pinnedMessages={pinnedMessages}
            refetchPins={refetchPins}
            refetchMessages={() => {
              getMessages.refetch()
            }}
            messageContentRef={messageContentRef}
            labels={messageLabels}
            onLabelChange={(data) => messageLabelsHandler.modify.labelChecked(data)}
            onClearClick={(data) => messageLabelsHandler.modify.clearLabels(data)}
            labelsSelected={labelsSelected}
          />
        </div>
        {selectedThreadId ? (
          <div
            className="grow pt-[18px] bg-[#fcfcff]  overflow-y-scroll relative"
            id="message-container"
            ref={messageContentRef}
          >
            <div className="flex flex-col gap-4 px-6">
              <div className="absolute left-[calc(50%-25px)]">
                {getMessages.loading ? <LoadingSpinner /> : null}
              </div>
              {!getMessages.loading && !hasNextToken ? (
                <DaySeparator
                  title={'Beginning of the conversation'}
                  date={threadData[0]?.sk1}
                />
              ) : null}

              {/* 
              {/* 
              ######################
              ##     MESSAGES     ##
              ######################
              */}
              {!getMessages.loading &&
                threadData &&
                threadData
                  .reduce((acc, message) => {
                    if (!message.visible) return acc

                    const messageDate = moment(message.sk1).format('YYYY-MM-DD')
                    const lastGroup = acc[acc.length - 1]

                    if (!lastGroup || lastGroup.date !== messageDate) {
                      acc.push({ date: messageDate, messages: [message] })
                    } else {
                      lastGroup.messages.push(message)
                    }

                    return acc
                  }, [])
                  .map((group, groupIdx) => (
                    <div key={groupIdx}>
                      <DaySeparator
                        className="sticky top-0 z-[2]"
                        subTitle={moment(group.date).format('dddd, MMM D, YYYY')}
                        messageContainer={messageContentRef}
                        hideSeparator={!hasNextToken && groupIdx === 0}
                      />
                      {group.messages.map((message, idx) => {
                        const isLastMessage = messages.length - 1 == idx
                        const positionLabelMenuAbove = isLastMessage && idx !== 0
                        if (!message.visible) {
                          return
                        }
                        if (
                          labelsSelected &&
                          !messageContainsLabel(message.labels, labelsBoxesChecked)
                        ) {
                          return
                        }

                        const isOutgoing =
                          message.agent_id !== null ||
                          channelInfo
                            ?.map((item) => item.AccountUserValue)
                            .includes(message?.email_from?.email)

                        const UtcMillisecondsToLocalTime = moment
                          .utc(message.sk1)
                          .local()
                          .format('h:mm A')

                        const sunshineChannel = capitalizeFirstLetter(
                          message?.chan_type,
                        )

                        /* ----- OUTGOING MESSAGE ----- */

                        const InboxOutgoingMessage = () => {
                          return (
                            <OutgoingMessageContext.Provider value={message}>
                              <OutgoingMessage
                                positionLabelMenuAbove={positionLabelMenuAbove}
                                body={message.body}
                                emailSnippet={message.email_snippet}
                                outgoingId={message.sk1}
                                className="ml-auto mr-0 outgoing-msg"
                                selectedThreadId={selectedThreadId}
                                refetchPins={refetchPins}
                                refetchMessages={() => {
                                  getMessages.refetch()
                                }}
                                message={{
                                  title: message?.email_subject,
                                  text: message?.body,
                                  timestamp: UtcMillisecondsToLocalTime,
                                  type: message.chan_type
                                    ? message.chan_type
                                    : 'imap',
                                  variant: message.item_type
                                    ? message.item_type
                                    : 'MSG', //defaulting type to MSG just in case if item_type is not there for it doesnt brake the front end completly
                                  labels: message.labels,
                                  threadpk: message.threadpk,
                                  sk1: message.sk1,
                                  pk1: message.pk1,
                                  msgpk: message.msgpk,
                                  chanpk: message.chanpk,
                                  showSendReceipt: '',
                                  isPinned: message.pinpk ? true : false,
                                  attachments: attachmentData,
                                  emailRecipients: {
                                    cc: message?.email_cc,
                                    bcc: message?.email_bcc,
                                  },
                                }}
                                user={{
                                  fullName: message.email_from?.name
                                    ? message.email_from?.name
                                    : message.number_from,
                                  channelAddress: message.email_from?.email
                                    ? message.email_from?.email
                                    : message.number_from,
                                }}
                                actionButtons={{
                                  allDisabled: message.is_suppressed,
                                  clickHandlers: {
                                    onRemoveClick: () => {},
                                    onPinClick: () => {},
                                  },
                                }}
                                messages={messages}
                                lastMessage={lastMessage}
                                messageContentRef={messageContentRef}
                              />
                            </OutgoingMessageContext.Provider>
                          )
                        }

                        /* ----- INCOMING MESSAGE ----- */

                        const InboxIncomingMessage = () => {
                          return (
                            <div>
                              <IncomingMessageContext.Provider value={message}>
                                <IncomingMessage
                                  positionLabelMenuAbove={positionLabelMenuAbove}
                                  className={'incoming-msg'}
                                  incomingId={message.sk1}
                                  emailSnippet={message.email_snippet}
                                  body={message.body}
                                  from={
                                    message?.email_from?.email
                                      ? message?.email_from?.email
                                      : message.number_from
                                        ? message.number_from
                                        : message.dm_from
                                          ? message.dm_from
                                          : ''
                                  }
                                  labels={message.labels}
                                  selectedThreadId={selectedThreadId}
                                  refetchPins={refetchPins}
                                  refetchMessages={() => {
                                    getMessages.refetch()
                                  }}
                                  hideHeader={message.hideHeader}
                                  message={{
                                    title: message?.email_subject,
                                    text:
                                      message?.body?.charAt(0) !== '<'
                                        ? message?.body
                                        : message?.email_snippet,
                                    timestamp: UtcMillisecondsToLocalTime,
                                    type: message.chan_type
                                      ? message.chan_type
                                      : 'imap',
                                    variant: message.item_type
                                      ? message.item_type
                                      : 'MSG', //defaulting type to MSG just in case if item_type is not there for it doesnt brake the front end completly
                                    labels: message.labels,
                                    threadpk: message.threadpk,
                                    sk1: message.sk1,
                                    pk1: message.pk1,
                                    chanpk: message.chanpk,
                                    msgpk: message.msgpk,
                                    isPinned: message.pinpk ? true : false,
                                    attachments: attachmentData,
                                    emailRecipients: {
                                      cc: message?.email_cc,
                                      bcc: message?.email_bcc,
                                    },
                                  }}
                                  user={{
                                    fullName: message.dm_from
                                      ? message.dm_from
                                      : contactName
                                        ? contactName
                                        : message.email_from?.name
                                          ? message.email_from?.name
                                          : message.email_from?.email,
                                    channelAddress: '',
                                    imageUrl: '',
                                  }}
                                  actionButtons={{
                                    allDisabled: message.is_suppressed,
                                    clickHandlers: {
                                      onRemoveClick: () => {},
                                    },
                                  }}
                                  footer={{
                                    variant:
                                      isSunshineChannelInactive(message.chan_type)
                                        .isInActive &&
                                      isSunshineChannelInactive(message.chan_type)
                                        .sk1 === message.sk1
                                        ? 'warning'
                                        : 'default',
                                    text:
                                      isSunshineChannelInactive(message.chan_type)
                                        .isInActive &&
                                      isSunshineChannelInactive(message.chan_type)
                                        .sk1 === message.sk1
                                        ? `${sunshineChannel} expired your session. You are no longer able to respond via ${sunshineChannel}`
                                        : '',
                                  }}
                                  messages={messages}
                                  messageContentRef={messageContentRef}
                                />
                              </IncomingMessageContext.Provider>
                            </div>
                          )
                        }

                        const passesChannelsFilters = () => {
                          return (
                            inboxAdvancedFilters.channels.length === 0 ||
                            inboxAdvancedFilters.channels.includes(
                              message.chan_type,
                            ) ||
                            inboxPinnedThreadIds.includes(
                              threadData?.[0]?.threadpk?.split('#')?.[0],
                            )
                          )
                        }

                        const passesLabelsFilters = () => {
                          const currMessagesLabels = {}
                          message.labels.forEach(
                            (label) => (currMessagesLabels[label.body] = true),
                          )
                          return (
                            !inboxAdvancedFilters.labels.length ||
                            inboxAdvancedFilters.labels.every(
                              (label) => label.checked === false,
                            ) ||
                            inboxAdvancedFilters.labels.some((label) => {
                              return currMessagesLabels[label.value] && label.checked
                            })
                          )
                        }

                        const isRenderableMessage =
                          passesChannelsFilters() && passesLabelsFilters()

                        if (isRenderableMessage) {
                          //renders inbox messages
                          const ref =
                            messageCount === 10 ? oldestCurrentMessageRef : null
                          messageCount++
                          return (
                            <div key={idx} ref={ref}>
                              {isOutgoing ? <InboxOutgoingMessage /> : null}

                              {!isOutgoing ? <InboxIncomingMessage /> : null}
                            </div>
                          )
                        }
                      })}
                    </div>
                  ))}
            </div>

            {displayPlaceholderMessage}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-screen">
            <CommandCenterInboxIcon
              svgClassName="sm:w-[243px] sm:h-[280px]"
              imgClassName="sm:w-[1043px]"
            />
            <div className=" flex flex-col gap-[30px] sm:gap-[20px]">
              <div className="mx-auto">
                <Header fontWeight="semibold" variant="h4">
                  Command Center{' '}
                  <span className="text-[#FF5000] font-primary font-semibold">
                    Inbox
                  </span>
                </Header>
              </div>
              <div className="mx-auto">
                <ParagraphText variant="lg">
                  Select a conversation to get started!
                </ParagraphText>
              </div>
            </div>
          </div>
        )}
        {showModal && <ChannelsModalComponent />}
        {showCreateLabelModal && <LabelButtonWithModal />}
        <FirstChannelAddedModal
          isOpen={showFirstChannelModal}
          setIsOpen={() => {
            dispatch(setShowFirstChannelModal(false))
          }}
        />
        <CustomModal
          isModalForm={true}
          isOpen={openVideoCallLinkModal}
          setIsOpen={setOpenVideoCallLinkModal}
          handleClose={() => {
            dispatch(setOpenVideoCallLinkModal(false))
          }}
          width={isMobile ? '325px' : '500px'}
        >
          <VideoCallLinkModal
            contactData={contact}
            selectedThreadId={selectedThreadId}
          />
        </CustomModal>
        {selectedThreadId !== null && selectedThreadId !== '' && (
          <div className="bg-[#fcfcff]">
            <div className="absolute right-0 top-[10rem] pt-1 z-40 sm:hidden md:hidden">
              <div ref={ref}>
                <RightPanelButtons
                  showProfile={showProfile}
                  setShowProfile={setShowProfile}
                  showFiles={showFiles}
                  setShowFiles={setShowFiles}
                  setShowPanel={setShowPanel}
                  showPanel={showPanel}
                />
              </div>
            </div>
            <div className="relative">
              <LatestMessagesButton
                className="bottom-[calc(100%+14px)] left-2/4 -translate-x-2/4"
                ref={messageContentRef}
                lastMessage={lastMessage}
              />
              <InboxNotifications
                className="absolute bottom-[calc(100%+8px)]"
                authUser={authUser}
                limitAttachmentErrorIsVisible={limitAttachmentError}
                setLimitAttachmentError={setLimitAttachmentError}
              />

              {selectedThreadId ? (
                <MessageCompose
                  ref={messageContentRef}
                  draftMessageData={draftMessageData}
                  setDraftMessageData={setDraftMessageData}
                  messages={threadData}
                  draftMessageAttachments={draftMessageAttachments}
                  limitAttachmentError={limitAttachmentError}
                  setLimitAttachmentError={setLimitAttachmentError}
                  className="sticky z-10 mb-2 mx-2 justify-center"
                  refetchMessages={() => {
                    getMessages.refetch()
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    )
  },
)
