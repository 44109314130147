import { Button, Icon } from '@thryvlabs/maverick'
import RightPanelSharedFiles from './RightPanelSharedFiles'
import { getFormattedFileSize } from './utils'

export const AllSharedFiles = ({
  attachmentFiles,
  groupedFileAttachments,
  handleDownloadAllAttachments,
  handleFileAttachmentDownload,
  isMobile,
}) => {
  return (
    <div className="grid grid-flow-row pt-2">
      {attachmentFiles?.length > 0 ? (
        Object.entries(groupedFileAttachments).map((attachments, index) => {
          return (
            <div key={index} className="text-sm open-sans font-normal py-2">
              <div className="flex justify-between">
                {attachments[0]}
                {!isMobile && (
                  <Button
                    variant="text"
                    level={1}
                    textVariant="light"
                    onClick={() => handleDownloadAllAttachments(index)}
                  >
                    Download All
                  </Button>
                )}
                {isMobile && (
                  <Button
                    variant="text"
                    level={1}
                    onClick={() => handleDownloadAllAttachments(index)}
                  >
                    <Icon
                      className="mr-2 inline-block"
                      variant="download"
                      type="regular"
                      height="15px"
                      width="20px"
                      color="#057AFF"
                    />
                    Download All
                  </Button>
                )}
              </div>
              {Object.values(groupedFileAttachments)
                [index].filter((attachment) => {
                  const parsedData = JSON.parse(attachment.body)
                  if (!isMobile) {
                    return !parsedData.contentType.startsWith('image/')
                  }
                  return true
                })
                .map((attachment, index) => {
                  const parsedData = JSON.parse(attachment.body)
                  return (
                    <div key={index} className="attachment-container">
                      <RightPanelSharedFiles
                        fileName={parsedData.filename}
                        fileSize={getFormattedFileSize(parsedData.size)}
                        type={parsedData.filename.slice(
                          parsedData.filename?.lastIndexOf('.') + 1,
                        )}
                        fileSk={attachment.filesk}
                        handleFileAttachmentDownload={() =>
                          handleFileAttachmentDownload(attachment)
                        }
                        isMobile={isMobile}
                      />
                    </div>
                  )
                })}
            </div>
          )
        })
      ) : (
        <p
          className={`self-start font-open-sans font-normal text-sm text-[#808080] ${
            isMobile ? 'my-4' : '-mt-7'
          }`}
        >
          Your shared files will appear here.
        </p>
      )}
    </div>
  )
}
