const navigationBarValidator = {
  validateVariant: (variant) => {
    const availableVariants = ['menu', 'tabs']

    if (!availableVariants.includes(variant)) {
      throw new Error(
        `The variant used: '${variant}' is not valid. Use one of the following: ${availableVariants.join(
          ', ',
        )}`,
      )
    }
  },
}

export default navigationBarValidator
