import { useRef } from 'react'
import { ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../../command-center-icon'

const VoicemailCallInformation = ({ icon, text }) => {
  const { variant, type } = icon

  return (
    <div className="flex gap-[9px] items-center">
      <CommandCenterIcon
        variant={variant}
        type={type}
        height="14"
        width="14"
        color="#A3A5A7"
      />
      <ParagraphText className="font-semibold font-[Montserrat]" variant="reg">
        {text}
      </ParagraphText>
    </div>
  )
}

const HorizontalLine = ({ className }) => {
  return (
    <div className={`${className} w-full`}>
      <div className={`h-px w-full bg-thryv-gray-light-400 rounded`}></div>
    </div>
  )
}

export const VoicemailTranscript = ({
  className,
  show,
  phoneNumber,
  dateReceived,
  transcript,
}) => {
  const transcriptRef = useRef(null)
  const transcriptMaxHeight = transcriptRef.current?.offsetHeight

  return (
    <div
      className={`${className} overflow-hidden transition-all duration-300`}
      style={{ height: show ? transcriptMaxHeight : '0' }}
    >
      <div ref={transcriptRef}>
        <HorizontalLine className="py-4" />

        <div className="flex gap-6">
          <VoicemailCallInformation
            icon={{
              variant: 'altPhone',
              type: 'regular',
            }}
            text={phoneNumber}
          />
          <VoicemailCallInformation
            icon={{
              variant: 'calendar',
              type: 'solid',
            }}
            text={dateReceived}
          />
        </div>

        <div className="w-0 min-w-full mt-2">
          <ParagraphText variant="sm">{transcript}</ParagraphText>
        </div>
      </div>
    </div>
  )
}
