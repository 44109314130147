// userSlice.js

import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    fname: '',
    lname: '',
    email: '',
    role: '',
  },
  reducers: {
    setUserInfo: (state, action) => {
      const { fname, lname, email, role } = action.payload
      state.fname = fname
      state.lname = lname
      state.email = email
      state.role = role
    },
  },
})

export const { setUserInfo } = userSlice.actions
export const selectUser = (state) => state.user

export default userSlice.reducer
