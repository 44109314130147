import CallTypeIcon from '../../recent-and-voicemail-components/CallTypeIcon'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import {
  CirclePhoneIcon,
  InformationIcon,
} from '../../../../command-center-icon/icons'
import { useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import formatContactData from '../../../../inbox/hooks/formatContactData'
import {
  setMobileKeypadOpen,
  setPrepopulatedNumber,
} from '../../../slices/callSlice'
import { useDispatch } from 'react-redux'

const RecentContentMobile = ({
  call,
  hasVoicemail,
  setDisplayInfoCard,
  setShowMobileContact,
}) => {
  const dispatch = useDispatch()
  const getCallType = () => {
    if (call?.agent_id === '123' && !call?.body.includes('Missed')) {
      return 'outgoing'
    } else if (call?.agent_id === '123' && call?.body.includes('Missed')) {
      return 'outgoing-missed'
    } else if (call?.agent_id !== '123' && call?.body.includes('Missed')) {
      return 'incoming-missed'
    } else if (call?.agent_id !== '123' && !call?.body.includes('Missed')) {
      return 'incoming'
    }
  }

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: call?.threadpk?.split('#')?.at(0) },
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''

  const fullName = firstName + ' ' + lastName

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  return (
    <>
      <div
        className="flex items-center pr-[10px]"
        data-testid="recent-mobile-container"
      >
        <div className="flex">
          <CallAvatar call={call} firstName={firstName} lastName={lastName} />
        </div>
      </div>
      <div className={`flex flex-col grow`}>
        <div
          className={`${
            getCallType() === 'outgoing-missed' ||
            getCallType() === 'incoming-missed'
              ? 'text-[#df2a2a]'
              : ''
          } block max-w-[175px] truncate font-semibold`}
        >
          {contact && fullName.length > 1 ? fullName : formattedPhoneNumber}
        </div>
        <div className="flex flex-row pt-[5px] items-center">
          <div className={`flex w-[27px]`}>
            <CallTypeIcon type={call.type} hasVoicemail={hasVoicemail} />
          </div>
          <span className="flex text-[14px] pr-[23px] pl-[3px]">{call.time}</span>
        </div>
      </div>
      <div className="flex justify-end">
        <div>
          <div className="flex justify-between gap-[16px] pr-[10px]">
            <button
              type="button"
              onClick={() => {
                dispatch(setMobileKeypadOpen(true))
                dispatch(setPrepopulatedNumber(formattedPhoneNumber))
              }}
            >
              <CirclePhoneIcon height="36px" width="36px" fill="#CCCCCC" />
            </button>
            <button
              type="button"
              data-testid="info-button"
              onClick={() => {
                setDisplayInfoCard(true)
                setShowMobileContact(true)
              }}
            >
              <InformationIcon height="36px" width="36px" fill="#CCCCCC" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default RecentContentMobile
