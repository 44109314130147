export const nzProvinces = [
  {
    name: 'Auckland',
    value: 'AUK',
  },
  {
    name: 'Bay of Plenty',
    value: 'BOP',
  },
  {
    name: 'Canterbury',
    value: 'CAN',
  },
  {
    name: 'Gisborne',
    value: 'GIS',
  },
  {
    name: "Hawke's Bay",
    value: 'HKB',
  },
  {
    name: 'Manawatū-Whanganui',
    value: 'MWT',
  },
  {
    name: 'Marlborough',
    value: 'MBH',
  },
  {
    name: 'Nelson',
    value: 'NSN',
  },
  {
    name: 'Northland',
    value: 'NTL',
  },
  {
    name: 'Otago',
    value: 'OTA',
  },
  {
    name: 'Southland',
    value: 'STL',
  },
  {
    name: 'Taranaki',
    value: 'TKI',
  },
  {
    name: 'Tasman',
    value: 'TAS',
  },
  {
    name: 'Waikato',
    value: 'WKO',
  },
  {
    name: 'Wellington',
    value: 'WGN',
  },
  {
    name: 'West Coast',
    value: 'WTC',
  },
]
