import moment from "moment";

export const calculateAddedTime = (duration) => {
    let timeToAdd
    if (duration === 'in 30 minutes') {
      timeToAdd = moment().add(30, 'minutes').format('h:mm A')
    } else if (duration === 'in 1 hour') {
      timeToAdd = moment().add(1, 'hour').format('h:mm A')
    } else if (duration === 'in 3 hours') {
      timeToAdd = moment().add(3, 'hour').format('h:mm A')
    } else if (duration === 'in 24 hours') {
      timeToAdd = moment().add(1, 'day').format('h:mm A')
    } else {
      throw new Error(`${duration} is not valid`)
    }
    return timeToAdd
  }