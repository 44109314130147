import { Input, Select } from '@thryvlabs/maverick'
import { Controller } from 'react-hook-form'
import { getTypeObj } from '../utils/getTypeObj'

export const formNames = [
  'businessType',
  'businessName',
  'firstName',
  'lastName',
  'dba',
  'verticalType',
]

export const getFormNames = (businessType) => {
  if (businessType === 'PUBLIC_PROFIT') {
    return [...formNames, 'stockSymbol', 'stockExchange']
  }

  return formNames
}

const businessTypes = [
  { name: 'Sole Proprietor', value: 'SOLE_PROPRIETOR' },
  { name: 'Private (LLC, S Corp, etc.)', value: 'PRIVATE_PROFIT' },
  { name: 'Publicly Traded', value: 'PUBLIC_PROFIT' },
  { name: 'Non-profit', value: 'NON_PROFIT' },
]

const verticalTypes = [
  { name: 'Real Estate', value: 'REAL_ESTATE' },
  { name: 'Healthcare', value: 'HEALTHCARE' },
  { name: 'Energy', value: 'ENERGY' },
  { name: 'Entertainment', value: 'ENTERTAINMENT' },
  { name: 'Retail', value: 'RETAIL' },
  { name: 'Agriculture', value: 'AGRICULTURE' },
  { name: 'Insurance', value: 'INSURANCE' },
  { name: 'Education', value: 'EDUCATION' },
  { name: 'Hospitality', value: 'HOSPITALITY' },
  { name: 'Financial', value: 'FINANCIAL' },
  { name: 'Gambling', value: 'GAMBLING' },
  { name: 'Construction', value: 'CONSTRUCTION' },
  { name: 'NGO', value: 'NGO' },
  { name: 'Manufacturing', value: 'MANUFACTURING' },
  { name: 'Government', value: 'GOVERNMENT' },
  { name: 'Technology', value: 'TECHNOLOGY' },
  { name: 'Communication', value: 'COMMUNICATION' },
]

const stockExchanges = [
  { name: 'None', value: 'NONE' },
  { name: 'AMEX', value: 'AMEX' },
  { name: 'AMX', value: 'AMX' },
  { name: 'ASX', value: 'ASX' },
  { name: 'B3', value: 'B3' },
  { name: 'BME', value: 'BME' },
  { name: 'BSE', value: 'BSE' },
  { name: 'FRA', value: 'FRA' },
  { name: 'ICEX', value: 'ICEX' },
  { name: 'JSE', value: 'JSE' },
  { name: 'KRX', value: 'KRX' },
  { name: 'LON', value: 'LON' },
  { name: 'NASDAQ', value: 'NASDAQ' },
  { name: 'NSE', value: 'NSE' },
  { name: 'NYSE', value: 'NYSE' },
  { name: 'OMX', value: 'OMX' },
  { name: 'SEHK', value: 'SEHK' },
  { name: 'SSE', value: 'SSE' },
  { name: 'STO', value: 'STO' },
  { name: 'SWX', value: 'SWX' },
  { name: 'SZSE', value: 'SZSE' },
  { name: 'TSX', value: 'TSX' },
  { name: 'TWSE', value: 'TWSE' },
  { name: 'VSE', value: 'VSE' },
]

const BusinessInfo = ({ register, errors, control, getValues, setValue }) => {
  const busType = getValues('businessType')

  return (
    <>
      <div className="flex flex-col">
        <div className="flex mt-[32px] sm:flex-col">
          <div className="mt-[-7px] pr-2 sm:mb-5 sm:pr-0">
            <Controller
              control={control}
              name="businessType"
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, onChange, ...rest } }) => (
                <Select
                  options={businessTypes}
                  selectLabel="Business Type"
                  withLabel
                  selectedOption={getTypeObj(
                    getValues('businessType'),
                    businessTypes,
                  )}
                  onChange={(option) => setValue('businessType', option.value)}
                  className="w-[220px] sm:w-full"
                  {...rest}
                />
              )}
            />
          </div>
          <div className="pl-2 sm:pl-0">
            <Input
              type="text"
              withLabel
              name="businessName"
              placeholder="Legal Business Name"
              labelType="floating"
              variant="default"
              register={register}
              className="w-[220px] sm:w-full"
              errors={errors}
            />
          </div>
        </div>
        <div className="flex mt-[32px] sm:flex-col sm:mt-5">
          <div className="pr-2 sm:mb-5 sm:pr-0">
            <Input
              type="text"
              withLabel
              name="firstName"
              placeholder="Owner First Name"
              labelType="floating"
              variant="default"
              register={register}
              className="w-[220px] sm:w-full"
              errors={errors}
            />
          </div>
          <div className="pl-2 sm:pl-0">
            <Input
              type="text"
              withLabel
              name="lastName"
              placeholder="Owner Last Name"
              labelType="floating"
              variant="default"
              register={register}
              className="w-[220px] sm:w-full"
              errors={errors}
            />
          </div>
        </div>
        <div className="flex mt-[32px] sm:flex-col sm:mt-5">
          <div className="pr-2 sm:mb-5 sm:pr-0">
            <Input
              type="text"
              withLabel
              name="dba"
              placeholder="Doing Business As (DBA)"
              labelType="floating"
              variant="default"
              register={register}
              className="w-[220px] sm:w-full"
              errors={errors}
            />
          </div>
          <div className="pl-2 mt-[-7px] sm:mt-0 sm:pl-0">
            <Controller
              control={control}
              name="verticalType"
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, onChange, ...rest } }) => (
                <Select
                  options={verticalTypes}
                  selectLabel="Vertical"
                  withLabel
                  selectedOption={getTypeObj(
                    getValues('verticalType'),
                    verticalTypes,
                  )}
                  onChange={(option) => setValue('verticalType', option.value)}
                  className="w-[220px] sm:w-full"
                  {...rest}
                />
              )}
            />
          </div>
        </div>
        {busType === 'PUBLIC_PROFIT' && (
          <div className="flex mt-[32px] sm:flex-col sm:mt-5">
            <div className="pr-2 sm:pr-0">
              <Input
                type="text"
                withLabel
                name="stockSymbol"
                placeholder="Stock Symbols"
                labelType="floating"
                variant="default"
                register={register}
                className="w-[220px] sm:w-full"
                errors={errors}
              />
            </div>
            <div className="pl-2 mt-[-7px] sm:pl-0 sm:mt-5">
              <Controller
                control={control}
                name="stockExchange"
                // eslint-disable-next-line no-unused-vars
                render={({ field: { ref, onChange, ...rest } }) => (
                  <Select
                    options={stockExchanges}
                    selectLabel="Stock Exchange"
                    withLabel
                    selectedOption={getTypeObj(
                      getValues('stockExchange'),
                      stockExchanges,
                    )}
                    onChange={(option) => setValue('stockExchange', option.value)}
                    className="w-[220px] sm:w-full"
                    {...rest}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BusinessInfo
