import { ParagraphText, Button } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../../command-center-icon'
import { VoicemailSection } from '../../voicemail-section'
// FIXME: Remove after using vonage api for voicemail

const CallSection = ({ message }) => {
  let call = {}

  const initCallObject = () => {
    if (message.text.includes('Missed call')) {
      call = {
        icon: {
          type: 'regular',
          variant: 'failedCall',
        },
        title: 'Missed Call',
      }
    } else {
      call = {
        icon: {
          type: 'solid',
          variant: 'incomingCall',
        },
        title: 'Incoming Call',
      }
    }
  }

  initCallObject()

  const onMissedCallClick = () => {
    // FIXME: Add the phone num to call below.
    // dispatch(setPrepopulatedNumber())
    // navigate('/calls')
  }

  return (
    <div className="flex gap-3 px-2">
      <div className="flex justify-center items-center">
        <CommandCenterIcon
          type={call.icon?.type}
          variant={call.icon?.variant}
          color="#16A085"
          width="16"
          height="16"
        />
      </div>
      <div className="flex flex-col">
        <ParagraphText variant="reg" className="font-['Montserrat'] !font-semibold">
          {message.title ? message.title : call.title}
        </ParagraphText>
        {message.variant === 'CALL' && (
          <ParagraphText variant="reg" color="thryv-steel">
            {message.text}
          </ParagraphText>
        )}
        {message.variant === 'missedCall' && (
          <Button
            variant="text"
            className="text-left"
            level={1}
            onClick={onMissedCallClick}
          >
            Call back
          </Button>
        )}
      </div>
    </div>
  )
}

const CallAndVoicemailSection = ({ message }) => {
  const showCallSection = message.variant === 'CALL'

  const showVoicemailSection = message.variant === 'VOICEMAIL'

  return (
    <div className="p-4">
      {/* <h4> CALLS</h4> */}
      {showCallSection && <CallSection message={message} />}
      {showVoicemailSection && <VoicemailSection voicemail={message} />}
    </div>
  )
}

export default CallAndVoicemailSection
