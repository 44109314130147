import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import {
  faCirclePause,
  faCirclePlay,
  faTrashCan,
  faCircleStop,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input } from '@thryvlabs/maverick'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { RecordVoiceMailCircleIcon } from '../../../../../command-center-icon/icons'
import './recordVoice.css'
import { UPLOAD_VOICEMAIL_GREETING_MUTATION } from '../../../../../../graphql/mutations/calls-mutations'
import { RenderWaveSurfer } from '../../../../../inbox/message-content/message-components/incoming-message/voicemail-section/render-waveForm'
import audioBufferToWav from 'audiobuffer-to-wav'
import {
  blobToAudioBuffer,
  getAudioDuration,
  secondsToTime,
} from '../../../../../../utils/voicemail'
import { GET_VOICEMAIL_AUDIO } from '../../../../../../graphql'
import { useDispatch } from 'react-redux'
import { setVoicemailMetaData } from '../../../../../../redux-toolkit/slices/calls'
import { LoadingSpinner } from '../../../../../common/loading-spinner'
const RecordVoiceModal = ({ setOpenRecordNew }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState()
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)
  const [recordedAudio, setRecordedAudio] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const waveformRef = useRef(null)
  const [replayAudio, setReplayAudio] = useState(false)
  const [audioSize, setAudioSize] = useState(0)
  const [recordingName, setRecordingName] = useState()

  const recorderControls = useAudioRecorder({
    noiseSuppression: true,
    echoCancellation: true,
  })

  const addAudioElement = async (blob) => {
    await //const audioLength = await getAudioDuration(blob)
    //setAudioSize(getAudioDuration(blob));
    blobToAudioBuffer(blob)
      .then((audioBuffer) => {
        // Do something with the AudioBuffer
        const wavBuffer = audioBufferToWav(audioBuffer)
        const wavblob = new Blob([wavBuffer], { type: 'audio/wav' })
        getAudioDuration(wavblob).then((audioLength) => {
          setAudioSize(audioLength)
        })
        setRecordedAudio(wavblob)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  }

  const uploadVoiceMailToS3 = async (uploadUrl, fileToUpload, contentType) => {
    let options = {
      headers: {
        'Content-Type': contentType,
      },
    }

    await axios
      .put(uploadUrl, fileToUpload, options)
      .then(() => {
        setOpenRecordNew(false)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error uploading file to s3', err)
      })
  }

  const [uploadVoicemailGreeting, { loading: uploadVoiceMailLoading }] = useMutation(
    UPLOAD_VOICEMAIL_GREETING_MUTATION,
    {
      onCompleted: (data) => {
        const { url } = data.uploadVoicemailGreeting
        refetch({ phone_number: phoneNumber })
        try {
          uploadVoiceMailToS3(url, recordedAudio, recordedAudio.type)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('Error uploading business logo to s3 bucket', err)
        }
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log('error', error)
      },
    },
  )

  const handleUpload = () => {
    const input = {
      phone_number: phoneNumber,
      meta_data: {
        length: audioSize,
        name: recordingName ? recordingName : 'Custom Greeting',
      },
    }
    uploadVoicemailGreeting({ variables: { input } })
  }

  const { data, refetch } = useQuery(GET_VOICEMAIL_AUDIO, {
    variables: { phone_number: phoneNumber },
    onCompleted: () => {
      const seconds = data?.queryVoicemailGreetingMetaData?.length
      const name = data?.queryVoicemailGreetingMetaData?.name
      const metaData = {
        length: secondsToTime(seconds),
        name: name,
      }
      dispatch(setVoicemailMetaData(metaData))
    },
  })

  return (
    <div className="mt-5 sm:w-[300px]">
      <Input
        className="w-full"
        type="text"
        customPlaceholder="Custom Greeting"
        name="recordingName"
        value={recordingName}
        onChange={(e) => setRecordingName(e.target.value)}
        withLabel
        labelType="static"
        variant="default"
      />

      <div className="mt-5 p-1">
        <p className="open-sans text-[14px] text-[#231F20]">
          {!recorderControls.isRecording && !recordedAudio && !isPlaying && (
            <>Select the record icon to initiate a new voicemail greeting.</>
          )}

          {recorderControls.isRecording && !isPlaying && <>Stop recording. </>}

          {isPlaying && <>Pause the recording. </>}

          {!isPlaying && recordedAudio && <>Click the play icon to play. </>}
        </p>

        <div className="flex  items-center mt-7 pb-[1rem] border-b-[2px] border-[#E3E6E8]">
          {(isPlaying || recordedAudio) && (
            <FontAwesomeIcon
              className={`${
                isPlaying ? 'text-[#E3E6E8]' : 'text-[#A3A5A7]'
              } text-[2.1rem] font-semibold`}
              icon={faTrashCan}
              onClick={() => {
                if (!isPlaying) setRecordedAudio(null)
              }}
            />
          )}

          {!isPlaying && !recordedAudio && !recorderControls.isRecording && (
            <button onClick={recorderControls.startRecording}>
              <RecordVoiceMailCircleIcon />
            </button>
          )}

          {!isPlaying && recorderControls.isRecording && (
            <button onClick={recorderControls.stopRecording}>
              <FontAwesomeIcon
                className="text-[#FF5000] text-[2.5rem] ml-1"
                icon={faCircleStop}
              />
            </button>
          )}

          {!isPlaying && (
            <FontAwesomeIcon
              onClick={() => {
                if (recordedAudio) {
                  setIsPlaying(!isPlaying)
                  setReplayAudio(!replayAudio)
                }
              }}
              className={`${
                recorderControls.isRecording || !recordedAudio
                  ? 'text-[#c9cfd9]'
                  : 'text-[#057AFF]'
              } text-[2.5rem] ml-5 mr-3`}
              icon={faCirclePlay}
            />
          )}

          {isPlaying && (
            <FontAwesomeIcon
              onClick={() => {
                if (recordedAudio) {
                  setReplayAudio(!replayAudio)
                  setIsPlaying(false)
                }
              }}
              className="text-[#057AFF] text-[2.5rem] ml-5 mr-3"
              icon={faCirclePause}
            />
          )}

          <div className="w-[450px] sm:w-[300px]">
            {!recordedAudio && !recorderControls.isRecording && (
              <div className="flex items-center flex-between w-full">
                <span className="mr-3">0:00</span>
                <hr className="w-[450px] sm:w-[100px]" />
                <span className="ml-3">0:00</span>
              </div>
            )}

            <AudioRecorder
              onRecordingComplete={(blob) => addAudioElement(blob)}
              recorderControls={recorderControls}
              showVisualizer={true}
              className="w-[450px] sm:w-[300px]"
              downloadFileExtension="wav"
            />

            {recordedAudio && (
              <div className="w-[450px] sm:w-[300px]">
                <RenderWaveSurfer
                  setIsPlaying={setIsPlaying}
                  audio={recordedAudio}
                  ref={waveformRef}
                  replayAudio={replayAudio}
                  audioLoading={loading}
                  setLoading={setLoading}
                  waveWidth="w-full"
                  className="flex"
                />
              </div>
            )}
          </div>
        </div>

        <div className="mt-[3em] flex justify-end">
          <Button
            variant="secondary"
            className="mr-3"
            onClick={() => setOpenRecordNew(false)}
          >
            Cancel
          </Button>
          <Button
            className="pl-5 pr-5 items-center flex justify-center"
            variant="primary"
            disabled={isPlaying || !recordedAudio}
            onClick={() => {
              if (!isPlaying && recordedAudio) handleUpload()
            }}
          >
            {uploadVoiceMailLoading ? (
              <LoadingSpinner widthAndHeight={18} />
            ) : (
              'Save Changes'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RecordVoiceModal
