import { Header, Icon } from '@thryvlabs/maverick'
import { useState } from 'react'
import { AllSharedFiles } from '../shared-media/shared-files/AllSharedFiles'
import { AllSharedPhotos } from '../shared-media/shared-photos/AllSharedPhotos'
import useSharedMediaProps from '../shared-media/shared-photos/useSharedMediaProps'

const MobileSharedMedia = ({ handleCloseModal }) => {
  const [activeTab, setActiveTab] = useState('media')
  const tabStyling = 'w-[93px] h-[44px] mx-5'
  const activeTabStyling = 'border-b-2 border-[#FF5000]'

  const handleMediaTabClick = () => {
    setActiveTab('media')
  }

  const handleFileTabClick = () => {
    setActiveTab('files')
  }

  const { allSharedPhotosProps, allSharedFilesProps } = useSharedMediaProps()

  return (
    <>
      <div className="h-[90vh]">
        <div className="w-full">
          <Icon
            variant="x"
            type="regular"
            width={'18'}
            height={'18'}
            color="#A3A5A7"
            className="block mr-0 ml-auto"
            onClick={() => handleCloseModal()}
          />
          <Header variant="h6" fontWeight="semibold" className="my-3">
            Attachments
          </Header>
          <div className="font-montserrat font-semibold text-sm ">
            <button
              className={`${tabStyling} ${
                activeTab === 'media' && activeTabStyling
              }`}
              onClick={handleMediaTabClick}
            >
              Media
            </button>
            <button
              className={`${tabStyling} ${
                activeTab === 'files' && activeTabStyling
              }`}
              onClick={handleFileTabClick}
            >
              Files
            </button>
          </div>
          <div className="relative flex items-center">
            <div className="-m-7 flex-grow border-t border-[#969696]"></div>
          </div>
          <div className="pt-3 max-h-[78vh] overflow-auto">
            {activeTab === 'files' && <AllSharedFiles {...allSharedFilesProps} />}
            {activeTab === 'media' && <AllSharedPhotos {...allSharedPhotosProps} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileSharedMedia
