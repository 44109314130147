import { getFileColors } from './getFileColors'
import { FileIconWithoutBorders } from './file-icon-without-borders/file-icon-without-borders'

const ColoredFileIcon = ({
  variant,
  className,
  fontSize,
  text,
  customColors, // { primary: '#ADADAD', secondary: '#F4F4F4' },
  width: width,
  height: height,
}) => {
  const colors = customColors || getFileColors(variant)
  return (
    <FileIconWithoutBorders
      className={className}
      colors={colors}
      fontSize={fontSize}
      text={text}
      width={width}
      height={height}
    />
  )
}

export default ColoredFileIcon
