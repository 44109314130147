import './search-modal.css'
import { useState, useRef, useEffect } from 'react'
import { useClickOutside } from '../../../../../../../hooks'
import { useSelector, useDispatch } from 'react-redux'
import { setSearch } from '../../../../../slices/inbox-search/inbox-search-slice'
import { getRecentSearchesFromCookies } from '../../../utils'
import { useLazyQuery } from '@apollo/client'
import { GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS } from '../../../../../../../graphql'
import { addRecentSearchToCookies } from '../../../utils'
import {
  createSearchParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import { setSelectedThread } from '../../../../../slices/inboxThreadsSlice'
import {
  setSelectedInboxMobilePage,
  handleStartNewMessage,
} from '../../../../../slices/inboxSlice'

// Components
import { Icon, Header, ModalWrapper, Button } from '@thryvlabs/maverick'
import { SearchBar } from '../../../common'
import {
  TopContactsSection,
  RecentSearchesSection,
} from '../../../sections/search-section'

const CloseButton = ({ onClick }) => (
  <button type="button" onClick={onClick}>
    <Icon variant="x" type="regular" color="#A3A5A7" width="18" height="18" />
  </button>
)

const HorizontalLine = ({ show }) => (
  <>{show ? <hr className="my-4 mx-4 text-thryv-gray-light-400" /> : null}</>
)

export const SearchModal = ({ show, close, onFilterButtonClick }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [getCloudsearchContacts] = useLazyQuery(GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS)
  const [cloudsearchContacts, setCloudsearchContacts] = useState([])
  // const [topSuggestion, setTopSuggestion] = useState('')

  const { search } = useSelector((state) => state.inboxSearch)
  // const { contacts } = useSelector((state) => state.inboxSearch)

  const searchInputRef = useRef(null)
  const searchInputContainerRef = useRef(null)

  const [focus, setFocus] = useState(false)

  const recentSearches = getRecentSearchesFromCookies()
  const placeholderText = ''

  const updateSearch = (newSearch) => {
    if (!newSearch.length && !search.length) return
    dispatch(setSearch(newSearch))
  }

  const updateCloudsearchContacts = async (newSearch) => {
    const { data: contactsData } = await getCloudsearchContacts({
      variables: {
        search: newSearch || search,
        ranks: {
          full_name: 6,
          // contactsk2: 5,
          // contactsk3: 4,
        },
        limit: 4,
      },
    })

    const contacts = contactsData.contactSuggestions

    setCloudsearchContacts(contacts)
    // updateTopSuggestion(contacts)
    return contacts
  }

  // const updateTopSuggestion = (contacts) => {
  //   const topContact = contacts[0]
  //   setTopSuggestion(topContact?.full_name || '')
  // }

  useClickOutside((e) => {
    const clearButton = document.querySelector('.clear-button')
    const clearButtonClicked = e.composedPath().includes(clearButton)
    if (clearButtonClicked) return
    setFocus(false)
  }, searchInputContainerRef)

  // useEffect(() => {
  //   const loadPreviouslyUsedSearchTerm = () => {
  //     const searchTermInUrl = searchParams.get('search')
  //     if (searchTermInUrl) setSearch(searchTermInUrl)
  //   }
  //   loadPreviouslyUsedSearchTerm()
  // }, [])

  useEffect(() => {
    const searchTermInUrl = searchParams.get('search')
    if (searchTermInUrl) updateSearch(searchTermInUrl)
  }, [])

  useEffect(() => {
    if (searchInputRef.current) {
      focusOnSearchInput()
    }
  }, [searchInputRef])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      updateCloudsearchContacts()
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const navigateToGlobalSearch = (inputSearch) => {
    let newSearch = inputSearch || search
    let pathToNavigateTo = '/inbox/global-search/messages'
    if (pathname.indexOf('/inbox/global-search') === 0) pathToNavigateTo = pathname

    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: newSearch }).toString(),
    })
  }

  const navigateToThread = (threadId, threadTitle) => {
    dispatch(
      setSelectedThread({
        id: threadId,
        name: threadTitle,
      }),
    )

    let pathToNavigateTo = '/inbox'

    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: threadTitle }).toString(),
    })
  }

  const handleSearchChange = async (e) => {
    let value
    if (typeof e === 'string') value = e
    else {
      value = e.target.value
      e.stopPropagation()
    }

    // onChange && onChange(e)

    updateSearch(value)
    updateCloudsearchContacts(value)
  }

  const clearAndFocusSearch = () => {
    focusOnSearchInput()
    updateSearch('')
  }

  const focusOnSearchInput = () => {
    setFocus(true)
    searchInputRef.current.focus()
  }

  const onSearchAllClick = () => {
    addRecentSearchToCookies(search)
    navigateToGlobalSearch()
  }

  const showRecentSearches = !search.length && recentSearches.length
  const showTopContacts = cloudsearchContacts.length

  return (
    <ModalWrapper show={show} disableBackdropClick={true}>
      <div>
        <div className="inbox-menu-search-modal">
          <div className="mx-4 mt-6 mb-4">
            <div className="flex justify-end mb-2">
              <CloseButton onClick={close} />
            </div>
            <Header fontWeight="semibold" variant="h6" className="mb-6 text-center">
              Search
            </Header>
            <div className="flex">
              <div
                ref={searchInputContainerRef}
                className="grow"
                onClick={focusOnSearchInput}
              >
                <SearchBar
                  search={search}
                  setSearch={updateSearch}
                  ref={searchInputRef}
                  className="grow cursor-text"
                  placeholderText={placeholderText}
                  isFocused={focus}
                  filterButton={{
                    onClick: () => onFilterButtonClick(),
                  }}
                  disableClearButton={true}
                  onChange={handleSearchChange}
                />
              </div>

              <button
                type="button"
                className={`clear-button ml-4 text-thryv-gray-light-500 font-semibold leading-5 transition-all duration-300
              ${search.length > 0 && '!text-[#057AFF]'}`}
                onClick={clearAndFocusSearch}
              >
                CANCEL
              </button>
            </div>
          </div>

          <TopContactsSection
            show={cloudsearchContacts.length}
            contacts={cloudsearchContacts}
            textToEmphasize={search}
            onContactRowClick={(contact) => {
              const { email, fullName, threadId } = contact
              const threadTitle = fullName || email
              dispatch(setSelectedInboxMobilePage('message-content'))
              handleSearchChange(threadTitle)
              navigateToThread(threadId, threadTitle)
            }}
          />

          <HorizontalLine show={showRecentSearches && showTopContacts} />

          <RecentSearchesSection
            show={recentSearches.length && !search.length}
            recentSearches={recentSearches}
            onRowClick={(newSearch) => {
              handleSearchChange(newSearch)
              navigateToGlobalSearch(newSearch)
              dispatch(setSelectedInboxMobilePage('message-content'))
              dispatch(handleStartNewMessage(false))
            }}
          />

          {/* <SuggestedSearchesSection
          show={search.length}
          suggestedSearches={suggestedSearches}
        /> */}
        </div>

        <HorizontalLine show={search.length && showTopContacts} />

        {search ? (
          <Button
            variant="text"
            level={1}
            className="px-6"
            onClick={onSearchAllClick}
          >
            Search All
          </Button>
        ) : null}
      </div>
    </ModalWrapper>
  )
}
