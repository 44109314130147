import { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomStyledTooltip from '../CustomStyledTooltip'

const AdminItem = ({ options, collapsed }) => {
  const [parentCoords, setParentCoords] = useState({ x: 0, y: 0 })
  const [isHovering, setIsHovering] = useState(false)
  const container = useRef(null)

  const handleMouseEnter = (e) => {
    if (collapsed) {
      setIsHovering(container.current.contains(e.target))
      if (parentCoords.x === 0) {
        const el = document.getElementById(
          `menu-item-${options.title.replace(/\s/g, '')}`,
        )
        const { x, y } = el.getBoundingClientRect()
        setParentCoords({ x, y })
      }
    }
  }

  const handleHover = ({ target }) => {
    if (container.current && container.current.contains(target)) {
      return
    }
    setParentCoords({ x: 0, y: 0 })
    return setIsHovering(false)
  }

  useEffect(() => {
    if (collapsed) {
      document.addEventListener('mouseover', handleHover)

      return () => {
        document.removeEventListener('mouseover', handleHover)
      }
    }
  }, [collapsed])

  return (
    <div
      ref={container}
      onMouseEnter={handleMouseEnter}
      id={`menu-item-${options.title.replace(/\s/g, '')}`}
      data-testid={`menu-item-${options.title.replace(/\s/g, '')}`}
    >
      <NavLink to={options.link}>
        {({ isActive }) => (
          <div
            style={isActive ? { backgroundColor: '#ECEEF1' } : {}}
            className={`flex ${
              collapsed ? 'w-[36px] h-[36px]' : 'w-[231px] md:w-[275px] pl-[12px]'
            } align-center gap-[8px] py-[6px] rounded-[4px] hover:bg-[#ECEEF1] hover:cursor-pointer text-thryv-steel hover:text-dark-gray active:bg-[#231F201A]`}
          >
            <FontAwesomeIcon
              icon={options.icon}
              size={'lg'}
              className={`${
                collapsed ? 'pl-[8px] pt-[1.5px]' : 'pl-0 h-[16px] w-[17px]'
              }  text-[#808080]`}
            />
            {!collapsed ? (
              <li className="text-[12px] leading-[18px] font-semibold font-primary">
                {options.title}
              </li>
            ) : null}
          </div>
        )}
      </NavLink>
      {isHovering && (
        <CustomStyledTooltip
          options={options}
          collapsed={collapsed}
          coords={parentCoords}
        />
      )}
    </div>
  )
}

export default AdminItem
