import { useState, useEffect } from 'react'
import WaveSurfer from 'wavesurfer.js'

/* ################################################ */
/* #               WARNING EXPLANATION            # */
/* ################################################ */
/*     This hook will create the following warning  
       in devtools.                                 
                                                  
       "The AudioContext was not allowed to start.  
       It must be resumed (or created) after a user 
       gesture on the page. https://goo.gl/7K7WLu"  
                                                  
       It occurs because when creating a wavesurfer
       object, there is no user gesture (like 
       click) that was passed into the object so it
       expects the waveform to have no functionality.
       Because we are using buttons to trigger play
       this warning can be ignored.                                           
                                                    */
/* ################################################ */

export const useWavesurfer = (waveformRef, waveform) => {
  const { audio, wavesurferOptions = {} } = waveform
  const {
    color = '#DADADA',
    progressColor = '#5378FC',
    height = 28,
  } = wavesurferOptions
  const [wavesurfer, setWavesurfer] = useState(null)
  let [wavesurferTime, setWavesurferTime] = useState({
    total: 0,
    current: 0,
    remaining: 0,
  })

  /* ----- Wavesurfer options documentation ----- */
  /* https://wavesurfer-js.org/docs/options.html */
  const createWavesurfer = () => {
    return WaveSurfer.create({
      container: waveformRef.current,
      waveColor: color,
      progressColor: progressColor,
      barRadius: 3,
      barWidth: 3,
      barHeight: 3,
      barGap: 3,
      height: height,
      cursorWidth: 0,
      ...wavesurferOptions,
    })
  }

  const initWavesurferTime = (newWavesurfer) => {
    const totalTime = newWavesurfer.getDuration()
    const currentTime = newWavesurfer.getCurrentTime()
    const remainingTime = totalTime - currentTime

    // Values in seconds
    setWavesurferTime({
      total: totalTime,
      current: currentTime,
      remaining: remainingTime,
    })
  }

  const initWavesurfer = () => {
    if (wavesurfer) wavesurfer.destroy()
    const newWavesurfer = createWavesurfer()

    newWavesurfer.loadBlob(audio)
    // if (typeof audio === 'string') newWavesurfer.load(audio) // must be a URL
    // else newWavesurfer.loadBlob(audio) //must be a Blob
    newWavesurfer.on('ready', () => initWavesurferTime(newWavesurfer))

    setWavesurfer(newWavesurfer)
  }

  useEffect(() => {
    if (waveformRef?.current) initWavesurfer()
  }, [audio])

  useEffect(() => {
    const updateTime = {
      duringPlay: () => {
        if (wavesurfer.isPlaying()) initWavesurferTime(wavesurfer)
      },

      onWaveClick: () => {
        if (wavesurfer) initWavesurferTime(wavesurfer)
      },
    }

    if (wavesurfer) {
      wavesurfer.on('audioprocess', updateTime.duringPlay)
      wavesurfer.on('seek', updateTime.onWaveClick)

      return () => {
        wavesurfer.un('audioprocess', updateTime.duringPlay)
        wavesurfer.un('seek', updateTime.onWaveClick)
      }
    }
  }, [wavesurfer, wavesurferTime])

  /* ----- Wavesurfer methods documentation ----- */
  /* http://wavesurfer-js.org/docs/methods.html */
  return [wavesurfer, wavesurferTime]
}
