import { useState } from 'react'
import VoicemailDropdownMobile from '../../recent-and-voicemail-content/voicemail-content/VoicemailDropdownMobile'
import { Icon } from '@thryvlabs/maverick'
import ContactInfoView from '../../../contacts/contact-info-view'
import { FETCH_CONTACT_WITH_PHONE } from '../../../../../graphql'
import { useQuery } from '@apollo/client'

const InfoCard = ({
  selectedCall,
  tabSelected,
  setDisplayInfoCard,
  contactInfoView,
  setContactInfoView,
  showMobileContact,
  setShowMobileContact,
}) => {
  const [showInfoCardFromVoicemail, setShowInfoCardFromVoicemail] = useState(false)
  const { refetch: refetchContacts } = useQuery(FETCH_CONTACT_WITH_PHONE)

  return (
    <>
      {' '}
      {(tabSelected === 'recent' || tabSelected === 'voicemail') &&
        showMobileContact && (
          <div
            onClick={() => {
              showInfoCardFromVoicemail
                ? setShowInfoCardFromVoicemail(false)
                : setDisplayInfoCard(false)
            }}
            className="absolute cursor-pointer top-[142px] left-[27px] flex flex-row items-center min-w-md:hidden z-20 bg-white"
          >
            <Icon
              type="solid"
              variant="chevronLeft"
              height="16.84"
              width="8.7"
              color="#4D4D4D"
            />
            <button
              className="ml-3 text-sm font-montserrat font-semibold"
              onClick={() => {
                showInfoCardFromVoicemail
                  ? setShowInfoCardFromVoicemail(false)
                  : setDisplayInfoCard(false)
              }}
            >
              Back
            </button>
          </div>
        )}
      {(tabSelected === 'recent' ||
        (tabSelected === 'voicemail' && showInfoCardFromVoicemail)) && (
        <ContactInfoView
          connection={true}
          setMobileContactSideSheetOpen={true}
          refetchContacts={refetchContacts}
          contactInfoView={contactInfoView}
          setContactInfoView={setContactInfoView}
          showMobileContact={showMobileContact}
          setShowMobileContact={setShowMobileContact}
        />
      )}
      {tabSelected === 'voicemail' && !showInfoCardFromVoicemail && (
        <VoicemailDropdownMobile
          selectedCall={selectedCall}
          setDisplayInfoCard={setDisplayInfoCard}
          setShowInfoCardFromVoicemail={setShowInfoCardFromVoicemail}
        />
      )}
      <div></div>
    </>
  )
}

export default InfoCard
