const { VITE_VCITA_HOSTS } = import.meta.env

const DIRS = VITE_VCITA_HOSTS.split(',')

export function redirectToBC(dirCode, staffUID, ssoToken) {
  const url = `${
    DIRS[dirCode - 1]
  }/v1/partners/sso/login?staff_uid=${staffUID}&sso_token=${ssoToken}`

  window.location.replace(url)

  return
}
