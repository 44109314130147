import { Header, ParagraphText, Button } from '@thryvlabs/maverick'
import checkmark from '../../../../../../../assets/checkmark.png'
import { CircleXGrayBackgroundIcon } from '../../../../../../../components/command-center-icon/icons'
import {
  UPDATE_STAFF,
  GET_STAFF,
  ADD_USER_CHANNEL_PERMISSIONS_BULK,
} from '../../../../../../../graphql'
import { useMutation } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingSpinner } from '../../../../../../../components/common/loading-spinner'
export const UpdateStaffConfirmationModal = ({
  setOpenUpdateStaffConfirmationModal,
  staffUpdatedName,
  updateStaffError,
  setUpdateStaffError,
  updatedStaffData,
  updatedStaffPermissionsData,
  setOpenEditStaffModal,
  setNewStaffPermissions,
}) => {
  const { user } = useAuth0()
  const commandCenterId = user.cc_id

  const [updateChannelPermissionsBulk, { loading: channelPermissionsLoading }] =
    useMutation(ADD_USER_CHANNEL_PERMISSIONS_BULK, {
      onCompleted: () => {
        setUpdateStaffError({ error: false, msg: '' })
      },
      refetchQueries: [GET_STAFF],
    })

  const [updateStaff, { loading }] = useMutation(UPDATE_STAFF, {
    onCompleted: () => {
      setOpenEditStaffModal(false)
      setOpenUpdateStaffConfirmationModal(true)
      setNewStaffPermissions([])
      setUpdateStaffError({ error: false, msg: '' })
    },
    onError: (error) => {
      setOpenEditStaffModal(false)
      setUpdateStaffError({ error: true, msg: error })
    },
    refetchQueries: [
      {
        query: GET_STAFF,
        variables: { comctrid: commandCenterId },
        fetchPolicy: 'network-only',
      },
    ],
  })

  const tryAgain = () => {
    updateStaff({
      variables: {
        CC_UserID: updatedStaffData.CC_UserID,
        LocationID: updatedStaffData.LocationID,
        FirstName: updatedStaffData.FirstName,
        LastName: updatedStaffData.LastName,
        Email: updatedStaffData.Email,
        Phone: updatedStaffData.Phone,
        Role: updatedStaffData.Role,
        Type: 'cinnamon roll',
        CountryCode: updatedStaffData.CountryCode,
      },
    })
    if (!updatedStaffPermissionsData.length) {
      return
    } else {
      updateChannelPermissionsBulk({
        variables: { input: updatedStaffPermissionsData },
      })
    }
  }
  return (
    <div className="w-[480px] max-h-[450px] h-[392px] md:w-[329px] md:h-[450px] bg-white z-10 flex flex-col items-center pr-6">
      <div className="self-end"></div>
      {updateStaffError.error ? (
        <CircleXGrayBackgroundIcon height={94} width={100} className="my-[18px]" />
      ) : (
        <img
          src={checkmark}
          alt="checkmark icon"
          className="h-[110px] w-[110px] mt-[10px] mb-[10px] mx-auto"
        />
      )}

      <div className="flex flex-col gap-[32px] w-[331px] md:w-[300px] md:text-center items-center text-center mb-[50px]">
        <Header fontWeight="semibold" variant="h1">
          {updateStaffError.error ? 'Network Error' : 'Changes Saved Successfully!'}
        </Header>
        <ParagraphText variant="reg" color="thryv-steel">
          {updateStaffError.error
            ? "We're having trouble connecting to the network, and couldn't save your changes. Please check your internet connection or try again in a few minutes."
            : `Your changes to ${staffUpdatedName.firstName} ${staffUpdatedName.lastName}'s information have been successfully saved.`}
          <br></br>
          {updateStaffError.error ? null : "Click 'Done' to continue."}
        </ParagraphText>
      </div>
      {updateStaffError.error ? (
        <div className="flex gap-6">
          <Button
            variant="text"
            level={1}
            textVariant="light"
            onClick={() => {
              setOpenUpdateStaffConfirmationModal(false)
              setTimeout(() => {
                setUpdateStaffError({ error: false, msg: '' })
              }, 1000)
            }}
          >
            CLOSE WITHOUT SAVING
          </Button>
          {loading || channelPermissionsLoading ? (
            <LoadingSpinner widthAndHeight={32} />
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                tryAgain()
              }}
            >
              Try Again
            </Button>
          )}
        </div>
      ) : (
        <Button
          variant="primary"
          onClick={() => {
            setOpenUpdateStaffConfirmationModal(false)
          }}
        >
          Done
        </Button>
      )}
    </div>
  )
}
