import { SectionTitle, SearchCardWithIcon } from '../common'

export const RecentSearchesSection = ({ show, recentSearches, onRowClick }) => {
  return (
    <>
      {show ? (
        <section>
          <SectionTitle>Recent searches</SectionTitle>
          <ul>
            {recentSearches.map((recentSearchText, i) => (
              <li key={i}>
                <SearchCardWithIcon
                  icon={{ variant: 'history', type: 'regular' }}
                  onClick={() => onRowClick(recentSearchText)}
                >
                  {recentSearchText}
                </SearchCardWithIcon>
              </li>
            ))}
          </ul>
        </section>
      ) : null}
    </>
  )
}
