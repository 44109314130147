import { useState, useEffect } from 'react'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import { ChannelsPopup } from './channel-popup'
import { useSelector } from 'react-redux'
import ChannelsIcon from '../../../../channels-icon/channels-icon'
import formatContactData from '../../../hooks/formatContactData'
import { useDispatch } from 'react-redux'
import { setSelectedChannel } from '../../../slices/messageComposeSlice'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import { useQuery } from '@apollo/client'
import usePhoneChannel from '../../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
const Button = ({ active, ...rest }) => {
  const [phoneChannel] = usePhoneChannel()
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )

  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )

  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const { toggleTenDlc } = useFlags()

  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const { selectedChannel } = useSelector((s) => s.messageCompose)

  const showIcon = () => {
    if (
      (selectedChannel?.Provider && selectedChannel?.Provider !== 'phone') ||
      (selectedChannel?.Provider &&
        selectedChannel?.Provider === 'phone' &&
        isActive)
    ) {
      return selectedChannel?.Provider
    }

    return 'towerBroadcast'
  }

  if (active) {
    return (
      <button
        data-testid="channel-button"
        type="button"
        className={`
          p-2 
          border-[1.5px] border-solid rounded-full border-thryv-gray-medium-500 hover:border-[#FF5000]
          transition-border duration-300
          ${active && ' border-[#FF5000]'}`}
        {...rest}
      >
        <ChannelsIcon
          variant={showIcon()}
          color={showIcon() === 'towerBroadcast' ? '#057AFF' : null}
          width={24}
          height={24}
        />
      </button>
    )
  } else {
    return (
      <TooltipWithCenteredArrow
        variant="top"
        width="max-content"
        title={
          selectedChannel?.Provider
            ? selectedChannel?.Provider === 'phone'
              ? formatContactData(selectedChannel?.AccountUserValue)
              : selectedChannel?.AccountUserValue
            : 'Explore Channels'
        }
        className="!max-w-[500px] !min-w-0"
        positioning={{ offsetX: '0px', offsetY: '-8px' }}
        disableVisibleOnClick={true}
      >
        <button
          data-testid="channel-button"
          type="button"
          className={`
          p-2 
          border-[1.5px] border-solid rounded-full border-thryv-gray-medium-500 hover:border-[#FF5000]
          transition-border duration-300
          ${active && ' border-[#FF5000]'}`}
          {...rest}
        >
          <ChannelsIcon
            variant={showIcon()}
            color={showIcon() === 'towerBroadcast' ? '#057AFF' : null}
            width={24}
            height={24}
          />
        </button>
      </TooltipWithCenteredArrow>
    )
  }
}

export const ChannelsButton = ({ channelType, lastMessage, draftMessageData }) => {
  const [active, setActive] = useState(false)
  const { channelInfo } = useSelector((state) => state.connectChannels) //using data coming from BE
  const { editorView } = useSelector((state) => state.messageCompose)
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const dispatch = useDispatch()
  const [contactEmail, setContactEmail] = useState(null)
  const [contactPhone, setContactPhone] = useState(null)

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: {
      threadid: selectedThreadId,
      skip: !selectedThreadId || selectedThreadId === '',
    },
    skip: !selectedThreadId || selectedThreadId === '',
    onCompleted: () => {
      setContactEmail(contact.queryContactsByThread.items[0]?.contactsk2)
      setContactPhone(contact.queryContactsByThread.items[0]?.contactsk3)
    },
  })

  const getAvailableChannelsByThread = () => {
    const activeChannels = channelInfo?.filter(
      (channel) => channel.Status === '1' && channel.Provider !== 'video',
    )

    let channelsByThread = []
    let disabledChannelsByThread = []

    activeChannels.forEach((channel) => {
      if (
        (channel.VendorID === '1' &&
          contactEmail &&
          contactEmail !== 'Unallocated') ||
        contactEmail === 'Facebook Messenger' ||
        (channel.VendorID === '2' && contactPhone) ||
        (channel.VendorID === '3' && contactEmail === 'Facebook Messenger') ||
        (channel.VendorID === '3' && contactEmail === 'Unallocated')
      ) {
        channelsByThread.push(channel)
      } else {
        disabledChannelsByThread.push(channel)
      }
    })

    return { channelsByThread, disabledChannelsByThread }
  }

  const [channelsActive, setChannelsActive] = useState([])
  const [disabledChannels, setDisabledChannels] = useState([])

  const init = () => {
    if (channelInfo) {
      const activeChannels =
        getAvailableChannelsByThread(channelType).channelsByThread
      const inativeChannels =
        getAvailableChannelsByThread(channelType).disabledChannelsByThread
      setChannelsActive(activeChannels)
      setDisabledChannels(inativeChannels)
    }
  }

  useEffect(() => {
    init()
  }, [contactEmail, contactPhone])

  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )

  const { sendButtonClicked } = useSelector((state) => state.messageCompose)

  const { isLoading } = useSelector((state) => state.inbox)

  const createMessageError = useSelector((state) => state.inbox.error.status)

  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )

  const { countryIso2 } = useSelector((state) => state.countryCode)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryIso2 === 'AU' ||
    countryIso2 === 'CA' ||
    countryIso2 === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const activeWithoutPhone = !isActive
    ? channelsActive.filter(
        (x) => x.Provider !== 'phone' && x.ChannelAccessLevel === '2',
      )
    : channelsActive

  const disabledWithoutPhone = !isActive
    ? disabledChannels.filter(
        (x) => x.Provider !== 'phone' && x.ChannelAccessLevel === '2',
      )
    : disabledChannels

  const initSelectedChannel = () => {
    if (
      (channelInfo && editorView === 'closed') ||
      (channelInfo && draftMessageData)
    ) {
      // adding editorView to make sure the channel doesnt change as the user is typing and only does it when the editor is closed
      const lastChannel = channelInfo?.filter(
        (channel) => channel.ChannelID === lastMessage?.chanpk,
      )
      //this is in the case that is a new thread and doesnt have messages so we default to the first channel on the list
      if (lastChannel.length === 0) {
        dispatch(setSelectedChannel(activeWithoutPhone[0]))
      } else {
        dispatch(setSelectedChannel(lastChannel[0]))
      }
    }
  }

  useEffect(() => {
    init()
  }, [channelInfo, channelType])

  useEffect(() => {
    initSelectedChannel()
  }, [lastMessage, channelInfo])

  return (
    <div className="relative">
      <ChannelsPopup
        availableChannels={activeWithoutPhone}
        disabledChannels={disabledWithoutPhone}
        className="bottom-full mb-2"
        show={active}
        setActive={setActive}
      />
      <Button
        disabled={(isLoading || sendButtonClicked) && !createMessageError}
        active={active}
        onClick={() => setActive(!active)}
      />
    </div>
  )
}
