import moment from 'moment'

const createUpdatedListItem = (item, searchTerm, className) => {
  const FORMATTED_TIMESTAMP = moment(item.timestamp).format(
    'MMM Do[,] YYYY [at] h[:]mm A',
  )

  const listItem = {
    user: {
      id: item.id,
      name: item.user.name,
      social: item.social,
      imageUrl: item.user.image,
    },
    title: item.title,
    timestamp: FORMATTED_TIMESTAMP,
    searchTerm: searchTerm,
    text: item.text,
    className: className,
    onClick: {
      container: item.onClick.container,
      name: item.onClick.name,
    },
  }

  return listItem
}

export const createMessagesListForPagination = (list, searchTerm) => {
  const updatedList = list.map((item) => createUpdatedListItem(item, searchTerm))

  return updatedList
}
