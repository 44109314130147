import InfoTooltip from '../../../components/common/infoTooltip/InfoTooltip'

export const NotificationInfo = ({label, tooltipDes}) => {
  return (
     <p className='flex  text-[14px] text-[#808080] open-sans ml-[2rem]'>
        {label} 
        <span className='ml-2'>
            <InfoTooltip description={tooltipDes} positioning={{
                offsetY: '-25px'
                }}/>
        </span>
    </p>
  )
}
