import { createSlice } from '@reduxjs/toolkit'

const editorViewStates = ['closed', 'open', 'expanded']

export const messageComposeSlice = createSlice({
  name: 'messageCompose',
  initialState: {
    editorText: '',
    editorHTML: '',
    isEditorFocused: false,
    subjectText: '',
    ccInputValue: { email: '', name: '' },
    bccInput: { email: '', name: '' },
    message: {
      text: '', // this gets populated with the text of a message when using the reply all functionality
      title: '', // this gets populated with the title of a message when using the reply all functionality
      emailRecipients: {
        cc: [],
        bcc: [],
      },
      labels: [],
    },
    replying: false,
    editorView: 'closed',
    showAttachmentPreview: false,
    attachmentPreviewUrl: '',
    attachmentPreviewFileSK: '',
    attachmentPreviewFilename: '',
    fileTypeToRender: '',
    filenameToRender: '',
    showFormattingToolbar: false,
    showLabelsMenu: false,
    selectedChannel: {},
    sendButtonClicked: false,
    unsendButtonClicked: false,
    attachmentFilesToDelete: [],
    submitMessageFlag: false,
    displayHyperlinkTooltip: false,
    currHyperlinkInformation: {
      offSetTop: '',
      offSetLeft: '',
      text: '',
      url: '',
    },
  },
  reducers: {
    setEditorContents(state, action) {
      state.editorText = action.payload.text
      state.editorHTML = action.payload.html
    },
    setIsEditorFocused(state, action) {
      state.isEditorFocused = action.payload
    },
    setCcInputValue(state, action) {
      state.ccInputValue.email = action.payload
    },
    setMessage(state, action) {
      state.message = action.payload
    },
    setSubjectText(state, action) {
      state.subjectText = action.payload
    },
    setReplying(state, action) {
      const subject = action.payload.subject
      state.replying = action.payload.replying
      if (subject !== null) {
        state.subjectText = subject.startsWith('Re:') ? subject : 'Re: ' + subject
      }
    },
    setEditorView(state, action) {
      state.editorView = editorViewStates.includes(action.payload)
        ? action.payload
        : state.editorView
    },
    setCcEmailRecipients(state, action) {
      state.message.emailRecipients.cc = action.payload
    },
    setResetCcEmailRecipients(state) {
      state.message.emailRecipients.cc = []
    },
    setBccEmailRecipients(state, action) {
      state.message.emailRecipients.bcc = action.payload
    },
    setResetBccEmailRecipients(state) {
      state.message.emailRecipients.bcc = []
    },
    setShowFormattingToolbar(state, action) {
      state.showFormattingToolbar = action.payload
    },
    setShowLabelsMenu(state, action) {
      state.showLabelsMenu = action.payload
    },
    setSelectedChannel(state, action) {
      state.selectedChannel = action.payload
    },
    setDisplayHyperlinkTooltip(state, action) {
      state.displayHyperlinkTooltip = action.payload
    },
    setCurrHyperlinkInformation(state, action) {
      state.currHyperlinkInformation = action.payload
    },
    handleClearResponseBar(state) {
      state.subjectText = ''
      state.message.emailRecipients.cc = []
      state.message.emailRecipients.bcc = []
      state.showFormattingToolbar = false
      state.editorView = 'closed'
    },
    handleClickFormatText(state) {
      if (state.editorView === 'closed') {
        state.editorView = 'open'
        state.showFormattingToolbar = true
      } else {
        state.showFormattingToolbar = !state.showFormattingToolbar
      }
    },
    setFileType(state, action) {
      state.fileTypeToRender = action.payload
    },
    setFileName(state, action) {
      state.filenameToRender = action.payload
    },
    handleClickAttachment(state, action) {
      const { url, sk, filename } = action.payload
      state.showAttachmentPreview = true
      state.attachmentPreviewUrl = url ?? ''
      state.attachmentPreviewFileSK = sk ?? ''
      state.attachmentPreviewFilename = filename ?? ''
    },
    handleCloseLightbox(state, action) {
      state.showAttachmentPreview = action.payload
      state.attachmentPreviewUrl = ''
      state.fileTypeToRender = ''
      state.filenameToRender = ''
    },
    handleShowAttachmentPreview(state, action) {
      state.showAttachmentPreview = action.payload
    },
    setSendButtonClicked(state, action) {
      state.sendButtonClicked = action.payload
    },
    setSubmitMessageFlag(state, action) {
      state.submitMessageFlag = action.payload
    },
    setUnsendButtonClicked(state, action) {
      state.unsendButtonClicked = action.payload
    },
    setAttachmentFilesToDelete(state, action) {
      state.attachmentFilesToDelete = action.payload
    },
  },
})

export const {
  setEditorContents,
  setIsEditorFocused,
  setMessage,
  setCcInputValue,
  setSubjectText,
  handleClearResponseBar,
  setReplying,
  setEditorView,
  setCcEmailRecipients,
  setBccEmailRecipients,
  setShowFormattingToolbar,
  setShowLabelsMenu,
  setSelectedChannel,
  setFileType,
  setFileName,
  handleClickFormatText,
  handleClickAttachment,
  handleCloseLightbox,
  setResetCcEmailRecipients,
  setResetBccEmailRecipients,
  handleShowAttachmentPreview,
  setSendButtonClicked,
  setSubmitMessageFlag,
  setDisplayHyperlinkTooltip,
  setCurrHyperlinkInformation,
  setUnsendButtonClicked,
  setAttachmentFilesToDelete,
} = messageComposeSlice.actions

export default messageComposeSlice.reducer
