import { useEffect } from 'react'

/* ----- Resource: https://usehooks.com/useOnClickOutside/ ----- */

/* ################################################ */
/* #                 EXPLANATION                  # */
/* ################################################ */
/*     This hook is used simliarly to useEffect.    */
/*                                                  */
/*     The callback executes when a click outside.  */
/*     an element (ref.current) occurs.             */
/*                                                  */
/*     Props:                                       */
/*       callback: (function)                       */
/*       ref: (mutable ref object)                  */
/* ################################################ */

export const useClickOutside = (callback, ref) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      callback(event)
    }

    document.addEventListener('mousedown', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  }, [callback, ref])
}
