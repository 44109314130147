import pageNotFound from '../../assets/error_page.png'

const PageNotFound = () => {
  return (
    <div className="w-[1400px] flex items-center justify-center m-auto">
      <img src={pageNotFound} alt="Oops! Page not found" />
    </div>
  )
}

export default PageNotFound