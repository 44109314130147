import RoundedActionButton from '../../../../../common/rounded-action-button'

export const PinButton = ({ variant, onClick }) => {
  let buttonProps = {}

  const initStyles = () => {
    switch (variant) {
      case 'disabled':
        buttonProps = {
          type: 'regular',
          width: '18',
          height: '18',
        }
        break
      case 'active':
        buttonProps = {
          type: 'solid',
          width: '16',
          height: '16',
        }
        break
      case 'inactive':
        buttonProps = {
          type: 'regular',
          width: '18',
          height: '18',
        }
        break
      default:
    }
  }

  initStyles()

  return (
    <RoundedActionButton
      dataTestId="pin-btn"
      icon={{
        type: buttonProps.type,
        variant: 'altThumbtack',
        color: variant === 'active' && '#5378FC',
        width: buttonProps.width,
        height: buttonProps.height,
      }}
      tooltipTitle={
        (variant === 'active' && 'Unpin message') ||
        (variant === 'inactive' && 'Pin message')
      }
      disable={variant === 'disabled'}
      onClick={onClick}
      isCCIcon
    />
  )
}
