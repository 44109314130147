import LeftNavBar from '../left-navigation-bar/LeftNavBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { ThryvCommandCenterIcon } from '../command-center-icon/icons'
import ProfileAvatar from '../common/profile-avatar/ProfileAvatar'
import { BusinessInfo } from './BusinessInfo'
import { setMobileNavOpen } from '../left-navigation-bar/slices/left-nav-slice'
import { useSelector, useDispatch } from 'react-redux'
import { getFirstAndLastName } from '../top-navigation-bar/utils/get-first-and-last-name'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation, useNavigate } from 'react-router'
import {
  handleStartNewMessage,
  setSelectedInboxMobilePage,
} from '../inbox/slices/inboxSlice'

const MobileNav = () => {
  const { mobileNavOpen } = useSelector((state) => state.leftNavigation)
  const { selectedInboxMobilePage } = useSelector((state) => state.inbox)
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const [firstname, lastname] = getFirstAndLastName(user)
  const navigate = useNavigate()
  const location = useLocation()

  const handleClickLogo = () => {
    if (location.pathname !== '/inbox') {
      navigate('/inbox')
    } else if (
      location.pathname === '/inbox' &&
      selectedInboxMobilePage !== 'inbox-menu'
    ) {
      dispatch(handleStartNewMessage(false))
      dispatch(setSelectedInboxMobilePage('inbox-menu'))
    }
  }
  return (
    <>
      <div className="w-full z-[100] flex flex-row items-center border-b-2 border-[#F0F0F0] h-[60px] bg-white px-[16px]">
        <div className="flex flex-wrap w-[30px] h-[30px] justify-center content-center">
          <FontAwesomeIcon
            className="flex hover:cursor-pointer text-[#4D4D4D]"
            size="xl"
            icon={mobileNavOpen ? faXmark : faBars}
            onClick={() => {
              dispatch(setMobileNavOpen())
            }}
          />
        </div>
        <div className="flex flex-1 justify-center">
          <div className="cursor-pointer" onClick={handleClickLogo}>
            <ThryvCommandCenterIcon width={124} />
          </div>
        </div>
        <ProfileAvatar
          variant="name"
          size="small"
          firstName={firstname}
          lastName={lastname}
          collapsed={true}
        />
      </div>
      {mobileNavOpen && (
        <div className="h-screen absolute w-[290px] z-[100] border-[#F0F0F0] border-r-[2px]">
          <BusinessInfo />
          <LeftNavBar />
        </div>
      )}
    </>
  )
}

export default MobileNav
