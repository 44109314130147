import { Button, Header, ParagraphText } from '@thryvlabs/maverick'
import thryvguy_sparklers from '../../assets/thryvguy_sparklers.png'
import { useNavigate } from 'react-router-dom'

const AlmostDone = ({ userEmail }) => {
  const navigate = useNavigate()

  const navigateToLogin = () => {
    navigate('/')
  }

  return (
    <div className="w-[540px] sm:w-[300px] sm:max-h-full p-3 bg-white z-10 rounded border border-[#ECEEF1] drop-shadow-md text-center">
      <div className="flex justify-center">
        <img src={thryvguy_sparklers} width={211} height={232} />
      </div>
      <Header fontWeight="semibold" variant="h4" className="mt-[-40px] py-5">
        Almost Done!
      </Header>
      <ParagraphText variant="reg" className="px-[40px] sm:px-5">
        A validation email has been sent to <b>{userEmail}</b>. Use that link to
        automatically login to your new Command Center!
      </ParagraphText>
      <div className="py-7">
        <Button variant="primary" onClick={navigateToLogin}>
          Log In
        </Button>
      </div>
    </div>
  )
}

export default AlmostDone
