import { Icon } from '@thryvlabs/maverick'
import DOMPurify from 'dompurify'
import { ButtonPlanCard, PlanCardBox } from './PlanCard.styles'

const PlanCard = ({
  color,
  features,
  header,
  supportText,
  isCurrent,
  price,
  id,
  onClickGetItNow,
}) => {
  return (
    <PlanCardBox
      color={color}
      id={id}
      className={`${isCurrent ? `bg-[#F8F9FB]` : `bg-[#FFF]`}`}
    >
      <p
        className={`font-['Montserrat'] !font-semibold text-[#4761C3] text-[28px] pt-3`}
      >
        {header}
      </p>
      <div className="flex flex-col gap-4">
        <span className="font-montserrat font-semibold text-[24px]">
          $ <span className="text-[50px] sm:text-[40px]">{price}</span>/user
        </span>
        <span className="font-montserrat text-lg text-[#A3A5A7] font-medium leading-9 mt-[-25px] ">
          Per month
        </span>
      </div>
      <div>
        <ul className="font-montserrat text-sm text-left">
          {features.map((feature, index) => (
            <li key={index} className="leading-9">
              <Icon
                type="regular"
                variant={feature.added ? 'check' : 'x'}
                color="#4761C3"
                className="inline h-[10px] w-[14px] mr-[3px]"
              />
              <span
                className={`text-[${
                  feature.added ? '#000' : '#A3A5A7'
                }] md:text-[13px]`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(feature.name),
                }}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="border-solid border-[1px] border-[#E3E6E8] w-[70%] mt-[15px] sm:mt-1">
        {''}
      </div>
      <div className="flex flex-col gap-2 justify-center items-center">
        <p className="font-montserrat text-sm mt-[10px] text-center px-10 min-h-[60px] mb-[20px] sm:mb-0">
          <b>Support</b> <br />
          {supportText}
        </p>

        {isCurrent ? (
          <p className="font-montserrat text-lg text-[#4761C3] font-medium h-[44px]">
            Your current plan
          </p>
        ) : (
          <ButtonPlanCard
            variant="primary"
            buttonType="outlined"
            color={color}
            active={isCurrent.toString()}
            onClick={onClickGetItNow}
            disabled={isCurrent}
          >
            GET IT NOW
          </ButtonPlanCard>
        )}
      </div>
    </PlanCardBox>
  )
}

export default PlanCard
