import { createSelector } from 'reselect'

const selectInboxThreads = ({ inboxThreads }) => inboxThreads

export const inboxThreadsSelector = createSelector(
  selectInboxThreads,
  ({
    customThreadLimit,
    inboxAdvancedFilters,
    inboxFilter,
    inboxNotification,
    inboxSort,
    messageSent,
    selectedThread,
    selectedThreadCompleted,
    selectedThreadSpam,
    selectedThreadTrash,
    threadLimit,
    threadsDoneLoading,
    timeframe,
  }) => ({
    customThreadLimit,
    inboxAdvancedFilters,
    inboxFilter,
    inboxNotification,
    inboxSort,
    messageSent,
    selectedThread,
    selectedThreadCompleted,
    selectedThreadSpam,
    selectedThreadTrash,
    threadLimit,
    threadsDoneLoading,
    timeframe,
  }),
)

export const selectedThreadSelector = createSelector(
  selectInboxThreads,
  ({ selectedThread: { id, name } }) => ({ selectedThreadId: id, name }),
)
