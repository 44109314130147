import { createSlice } from '@reduxjs/toolkit'

export const leftNavigationSlice = createSlice({
  name: 'leftNavigation',
  initialState: {
    mobileNavOpen: false,
  },
  reducers: {
    setMobileNavOpen(state) {
      state.mobileNavOpen = !state.mobileNavOpen
    },
  },
})

export const { setMobileNavOpen } = leftNavigationSlice.actions

export default leftNavigationSlice.reducer
