import { SolidCircleCheck, RegularX } from '@thryvlabs/maverick'
import {
  SuccessBannerContainer,
  SuccessClose,
  SuccessText,
} from '../modals/connect-channels-modal/ModalComponent.styles'
import { useDispatch, useSelector } from 'react-redux'
import { setChannelIsConnected } from '../modals/slices/connectChannelsSlice'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

const SuccessBanner = () => {
  const dispatch = useDispatch()
  const { user } = useAuth0()

  const channelId = localStorage.getItem('channelClicked')
  const { cardData, connectedChannelInfo } = useSelector(
    (state) => state.connectChannels,
  )
  const [channelConnected] = useState(cardData[channelId])

  useEffect(() => {
    const sucessBannerTimeout = setTimeout(() => {
      dispatch(setChannelIsConnected(false))
    }, 15000)
    return () => clearTimeout(sucessBannerTimeout)
  }, [])

  return (
    <SuccessBannerContainer>
      <div className="mt-1">
        <SolidCircleCheck fill="#16A085" width="14" height="14" />
      </div>
      <SuccessText variant="reg" color="thryv-black-500">
        Congrats, {user.name}! Your {connectedChannelInfo.provider} account was
        successfully connected.
        {channelConnected?.channelProvider === 'nylas' && //this is to know what text to render for each provider
          ' We are importing your messages now. This process can take up to a few hours to complete.'}
        {channelConnected?.channelProvider === 'sunshine' && //this is to know what text to render for each provider
          `You may now respond to ${connectedChannelInfo?.provider} messages in your Inbox!`}
      </SuccessText>
      <SuccessClose
        variant="text"
        level={2}
        onClick={() => {
          dispatch(setChannelIsConnected(false))
        }}
      >
        <RegularX width="12" height="12" fill="#808080" />
      </SuccessClose>
    </SuccessBannerContainer>
  )
}

export default SuccessBanner
