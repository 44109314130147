import { useState, useEffect } from 'react'
import { GET_S3_DOWNLOAD_LINK } from '@graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
const { VITE_NYLAS_HOST } = import.meta.env

/**
 * Checks the existence of a voicemail and returns related data.
 *
 * @param {object} voicemail - The voicemail object to check.
 * @returns {object} {isVoicemailExist, audioBlob, transcript, isLoadingAudio} - An object containing:
 *   - {boolean} isVoicemailExist: Whether the voicemail exists.
 *   - {Blob} audioBlob: The audio data of the voicemail (or null if none).
 *   - {string} transcript: The transcript of the voicemail (or null if none).
 *   - {boolean} isLoadingAudio: Whether the audio is loading.
 */
const useCheckVoicemailExistence = (voicemail) => {
  const [isVoicemailExist, setIsVoicemailExist] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [transcript, setTranscript] = useState(null)
  const [isLoadingAudio, setIsLoadingAudio] = useState(true)
  const { user } = useAuth0()
  const filename = voicemail?.threadpk
    ? `${user.cc_id}/${voicemail.chanpk}/${voicemail.threadpk.split('#')[0]}/${
        voicemail.msgpk
      }.mp3`
    : null
  const { data, loading } = useQuery(GET_S3_DOWNLOAD_LINK, {
    variables: {
      filename,
    },
  })
  let isMounted = true

  const formatTranscription = () => {
    const voicemailText = voicemail?.text || voicemail?.body
    try {
      const parsedData = JSON.parse(voicemailText)
      setTranscript(parsedData.transcription)
    } catch (err) {
      const parsedData = voicemail.text.substring(
        voicemailText.indexOf('=') + 1,
        voicemailText.indexOf('link=') - 2,
      )
      setTranscript(parsedData)
    }
  }

  const getDownloadLink = () => {
    const voicemailText = voicemail?.text || voicemail?.body
    const nexmoLink = voicemailText.substring(
      voicemailText.indexOf('link=') + 5,
      voicemailText.length - 1,
    )
    const audioUUID = nexmoLink.substring(nexmoLink.indexOf('files/') + 6)
    // if get 404 from s3 query, try nexmo
    const downloadLink =
      data?.getSafeS3DownloadURL === '404'
        ? // nexmo
          `${VITE_NYLAS_HOST}/voicemail/${audioUUID}`
        : // s3
          data?.getSafeS3DownloadURL.substring(
            data?.getSafeS3DownloadURL.indexOf('=') + 1,
            data?.getSafeS3DownloadURL.length - 1,
          )
    return downloadLink
  }

  const checkVoicemailExistence = async () => {
    let downloadLink = getDownloadLink()
    const myHeaders = new Headers()
    myHeaders.append('Accept', 'audio/mpeg')
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    try {
      // will see a 404 err when both download links won't work, which is normal behavior
      const expectedResponse = await fetch(downloadLink, requestOptions)
      if (expectedResponse.ok && isMounted) {
        setIsVoicemailExist(true)
        setAudioBlob(await expectedResponse.blob())
      } else {
        throw new Error()
      }
    } catch (err) {
      if (isMounted) setIsVoicemailExist(false)
    } finally {
      if (isMounted) setIsLoadingAudio(false)
    }
  }
  useEffect(() => {
    if (
      voicemail &&
      !loading &&
      audioBlob === null &&
      (voicemail?.variant === 'VOICEMAIL' || voicemail?.item_type === 'VOICEMAIL')
    ) {
      formatTranscription()
      checkVoicemailExistence()
    }

    // todo: cleanup async task to avoid updating state
    return () => (isMounted = false)
  }, [voicemail?.text, loading])

  return { isVoicemailExist, audioBlob, transcript, isLoadingAudio }
}

export default useCheckVoicemailExistence
