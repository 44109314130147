import { Icon, ParagraphText, Select, TextArea, Tooltip } from '@thryvlabs/maverick'
import { Controller } from 'react-hook-form'
import { getTypeObj } from '../utils/getTypeObj'

export const formNames = ['campaignType', 'campaignDescription']

const campaignTypes = [
  { name: 'Text to Join', value: 'TEXT_TO_JOIN' },
  { name: 'Live Operator/Phone Call', value: 'LIVE_OPERATOR' },
  { name: 'Point of Sale (including paper forms)', value: 'POINT_OF_SALE' },
  { name: 'Online (Site, Mobile App, Browser)', value: 'ONLINE_FORM' },
  { name: 'Other', value: 'OTHER' },
]

const CampaignType = ({
  register,
  control,
  errors,
  getValues,
  setValue,
  isMobile,
}) => {
  const campType = getValues('campaignType')

  const toolTipMessages = () => {
    const message = {
      description: (
        <span>
          Specify how you gain opt-in consent and describe the communications you
          sent to customers.
          <ul className="list-disc pl-5">
            <li>
              <b>Text to join</b> supports automatic responses to the specified
              keyword command.
            </li>
            <li>
              Other opt-in types, which include Online, Verbal, and Paper do not
              support automatic responses. You must provide manual responses for
              these opt-in types.
            </li>
          </ul>
        </span>
      ),
    }
    return message
  }

  const method = (campType) => {
    switch (campType) {
      case 'TEXT_TO_JOIN':
        return 'Text to Join'
      case 'LIVE_OPERATOR':
        return 'Live Operator/Phone Call'
      case 'POINT_OF_SALE':
        return 'Point of Sale'
      case 'ONLINE_FORM':
        return 'Online'
      case 'OTHER':
        return 'Other'
    }

    return 'Please select an Opt-in Method above'
  }

  const methodDescription = (campType) => {
    switch (campType) {
      case 'TEXT_TO_JOIN':
        return (
          <>
            <ul className="list-disc pl-4">
              <li>Specify your opt-in keyword (such as JOIN, SUBSCRIBE).</li>
              <li>
                <div className="flex">
                  Describe how you request the customer to opt in.{' '}
                  <Tooltip
                    variant={isMobile ? 'left' : 'top'}
                    positioning={
                      isMobile
                        ? { offsetX: '15px', offsetY: '-8px' }
                        : { offsetY: '-12px' }
                    }
                    description={
                      <>
                        <b>Example:</b> Advertisement for services informs customers
                        that they can subscribe to receive SMS text about our
                        services.
                      </>
                    }
                  >
                    <button type="button">
                      <Icon
                        type="regular"
                        variant="infoCircle"
                        height="18"
                        fill="#057AFF"
                      />
                    </button>
                  </Tooltip>
                </div>
              </li>
              <li>
                <div className="[&>div:last-child]:inline-block">
                  Specify how you confirm the customer has opted in to receive text
                  messages. The message must contain the STOP and HELP keywords,
                  message frequency, and message rates.
                  <Tooltip
                    variant={isMobile ? 'left' : 'top'}
                    positioning={
                      isMobile
                        ? { offsetX: '15px', offsetY: '-8px' }
                        : { offsetY: '-12px' }
                    }
                    description={
                      <>
                        <b>Example:</b> You subscribed to receive recurring messages
                        from MenusRUs. Reply STOP to cancel, HELP for assistance.
                        Message frequency varies. Message and Data rates may apply.
                      </>
                    }
                  >
                    <button type="button">
                      <Icon
                        type="regular"
                        variant="infoCircle"
                        height="18"
                        fill="#057AFF"
                      />
                    </button>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </>
        )
      case 'LIVE_OPERATOR':
        return (
          <>
            <ul className="list-disc pl-4">
              <li>
                <div className="flex">
                  Explain the exchange used to obtain opt-in consent.{' '}
                  <Tooltip
                    variant={isMobile ? 'left' : 'top'}
                    positioning={
                      isMobile
                        ? { offsetX: '15px', offsetY: '-8px' }
                        : { offsetY: '-12px' }
                    }
                    description={
                      <>
                        <b>Example:</b> During the Sales process, the client is
                        verbally asked if they agree to receive SMS texts about
                        special offers and status updates on services rendered.
                      </>
                    }
                  >
                    <button type="button">
                      <Icon
                        type="regular"
                        variant="infoCircle"
                        height="18"
                        fill="#057AFF"
                      />
                    </button>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </>
        )
      case 'POINT_OF_SALE':
        return (
          <>
            <ul className="list-disc pl-4">
              <li>
                <div className="flex">
                  Describe how you request the customer to opt in on paper.{' '}
                  <Tooltip
                    variant={isMobile ? 'left' : 'top'}
                    positioning={
                      isMobile
                        ? { offsetX: '15px', offsetY: '-8px' }
                        : { offsetY: '-12px' }
                    }
                    description={
                      <>
                        <b>Example:</b> Customer must submit an application by paper,
                        where they are requested to mark the checkbox if they agree
                        to receive SMS texts about special offers and status updates
                        on services rendered.
                      </>
                    }
                  >
                    <button type="button">
                      <Icon
                        type="regular"
                        variant="infoCircle"
                        height="18"
                        fill="#057AFF"
                      />
                    </button>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </>
        )
      case 'ONLINE_FORM':
        return (
          <>
            <ul className="list-disc pl-4">
              <li>Specify the URL for your organization.</li>
              <li>
                <div className="flex">
                  Describe how consent is requested online.{' '}
                  <Tooltip
                    variant={isMobile ? 'left' : 'top'}
                    positioning={
                      isMobile
                        ? { offsetX: '15px', offsetY: '-8px' }
                        : { offsetY: '-12px' }
                    }
                    description={
                      <>
                        <b>Example:</b> During the signup process for our services,
                        our client must select the option to receive SMS texts about
                        special offers and status updates on services rendered.
                      </>
                    }
                  >
                    <button type="button">
                      <Icon
                        type="regular"
                        variant="infoCircle"
                        height="18"
                        fill="#057AFF"
                      />
                    </button>
                  </Tooltip>
                </div>
              </li>
            </ul>
          </>
        )
      case 'OTHER':
        return (
          <>
            <li>Explain and describe the method used to obtain opt-in consent.</li>
          </>
        )
    }

    return 'Description'
  }

  const toolTipProp = toolTipMessages()

  return (
    <>
      <div className="flex flex-col">
        <div className="flex sm:flex-col">
          <div className="flex w-full mt-[32px]">
            <div className="grow">
              <Controller
                control={control}
                name="campaignType"
                // eslint-disable-next-line no-unused-vars
                render={({ field: { ref, onChange, ...rest } }) => (
                  <Select
                    className="w-full"
                    options={campaignTypes}
                    selectLabel="Opt-in Methods"
                    withLabel
                    selectedOption={getTypeObj(
                      getValues('campaignType'),
                      campaignTypes,
                    )}
                    onChange={(option) => setValue('campaignType', option.value)}
                    {...rest}
                  />
                )}
              />
            </div>
            <div className="mt-3">
              <Tooltip
                variant={isMobile ? 'left' : 'top'}
                positioning={
                  isMobile
                    ? { offsetX: '15px', offsetY: '-8px' }
                    : { offsetY: '-12px' }
                }
                {...toolTipProp}
              >
                <button type="button">
                  <Icon
                    type="regular"
                    variant="infoCircle"
                    height="18"
                    fill="#057AFF"
                  />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        {campType && (
          <>
            <div className="flex mt-[8px]">
              <ParagraphText
                variant="reg"
                className="mt-6 text-sm font-semibold leading-[20px]"
              >
                Opt-in Method: {method(campType)}
              </ParagraphText>
            </div>
            <div className="my-2 pl-3 font-open-sans text-xs leading-[18px] text-left">
              {methodDescription(campType)}
            </div>
            <TextArea
              type="text"
              name="campaignDescription"
              limit={750}
              register={register}
              errors={errors}
            />
          </>
        )}
      </div>
    </>
  )
}

export default CampaignType
