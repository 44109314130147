import { useLocation } from 'react-router-dom'
import { NavTab } from './nav-tab'

export const NavigationDesktop = ({ linksArray }) => {
  const { pathname } = useLocation()
  const activeStyle = 'border-b-[4px] border-[#FF5001] text-black w-fit px-6'

  return (
    <div
      className={`flex items-end md:hidden text-thryv-steel w-full pl-[4px] min-h-[44px] semi-bold border-b-[1px] border-barely-gray`}
    >
      {linksArray.map((link) => {
        return (
          <NavTab
            key={link.title}
            path={link.path}
            title={link.title}
            active={
              pathname.includes(link.path) ||
              (link.title === 'My Account' &&
                pathname.includes(link.path.split('/').slice(0, 3).join('/')))
            } // highlight My Account when on other My Account subroutes
            activeStyle={activeStyle}
          />
        )
      })}
    </div>
  )
}
