import { createSlice } from '@reduxjs/toolkit'
import IncomingRing from '../../../assets/ringtone/Incoming-ring.mp3'

// const data = NODE_ENV === 'development' ? recentCalls : []
const data = []
const navigationLocationOnLoad = () => {
  if (location.pathname === '/calls') {
    return 'recent'
  } else if (location.pathname === '/calls/voicemail') {
    return 'voicemail'
  } else {
    return 'contacts'
  }
}

export const callSlice = createSlice({
  name: 'calls',
  initialState: {
    tier: 'pro',
    tabSelected: navigationLocationOnLoad(),
    calls: data,
    voicemails: data,
    prepopulatedNumber: '',
    dtmf: null,
    audioPermission: 'loading',
    mobileKeypadOpen: false,
    onOutgoingCall: false,
    onIncomingCall: false,
    openVideoCallLinkModal: false,
    copyInvitationClicked: false,
    incomingCallMobile: true,
    incomingCallWeb: true,
    callHandler: null,
    callMember: null,
    callStatus: null,
    callMuted: false,
    callOnHold: false,
    answerIsClicked: null,
    phoneValue: '',
    callDirection: null,
    callDuration: { mins: 0, secs: 0 },
    clientNumber: '',
    clientUid: '',
    userUid: '',
    audio: new Audio(IncomingRing),
    sessionID: '',
    prepopulatedNumberClicked: false,
    notConversationEvent: null,
    vonageClient: {
      app: null,
      isInit: false,
      session_id: null,
      jwt_sub: null,
      jwt_no_sub: null,
    },
  },
  reducers: {
    setCalls(state, action) {
      state.calls = action.payload
    },
    initVonageClient(state, action) {
      state.vonageClient = { ...state.vonageClient, ...action.payload }
    },
    clearVonageClient(state) {
      state.vonageClient = {
        app: null,
        isInit: false,
        session_id: null,
        jwt_sub: null,
        jwt_no_sub: null,
      }
    },
    setAudioPermission(state, action) {
      state.audioPermission = action.payload
    },
    setDtmf(state, action) {
      state.dtmf = action.payload
    },
    setVonageSessionID(state, action) {
      state.sessionID = action.payload
    },
    setVoicemails(state, action) {
      state.voicemails = action.payload
    },
    setTabSelected(state, action) {
      state.tabSelected = action.payload
    },
    setPrepopulatedNumber(state, action) {
      state.prepopulatedNumber = action.payload
    },
    setPrepopulatedNumberClicked(state, action) {
      state.prepopulatedNumberClicked = action.payload
    },
    loadAppoloCallsState: (state, action) => {
      state.calls = action.payload.calls
      state.voicemails = action.payload.calls
    },
    setMobileKeypadOpen: (state, action) => {
      state.mobileKeypadOpen = action.payload
    },
    setOnOutgoingCall: (state, action) => {
      state.onOutgoingCall = action.payload
    },
    setOnIncomingCall: (state, action) => {
      state.onIncomingCall = action.payload
    },
    setOpenVideoCallLinkModal(state, action) {
      state.openVideoCallLinkModal = action.payload
    },
    setCopyInvitationClicked(state, action) {
      state.copyInvitationClicked = action.payload
    },

    setIncomingCallMobile: (state, action) => {
      state.incomingCallMobile = action.payload
    },
    setIncomingCallWeb: (state, action) => {
      state.incomingCallWeb = action.payload
    },
    setPhoneValue: (state, action) => {
      state.phoneValue = action.payload
    },
    setCallHandler: (state, action) => {
      state.callHandler = action.payload
    },
    setCallStatus: (state, action) => {
      state.callStatus = action.payload
    },
    setCallMuted: (state, action) => {
      state.callMuted = action.payload
    },
    setCallOnHold: (state, action) => {
      state.callOnHold = action.payload
    },
    setCallDuration: (state, action) => {
      const { minutes, seconds } = action.payload
      state.callDuration = { mins: minutes, secs: seconds }
    },
    setCallDirection: (state, action) => {
      state.callDirection = action.payload
    },
    setClientNumber: (state, action) => {
      state.clientNumber = action.payload
    },
    setClientUid: (state, action) => {
      state.clientUid = action.payload
    },
    setUserUid: (state, action) => {
      state.userUid = action.payload
    },
    setCallMember: (state, action) => {
      state.callMember = action.payload
    },
    setAnswerIsClicked: (state, action) => {
      state.answerIsClicked = action.payload
    },
    setNotConversationEvent: (state, action) => {
      state.notConversationEvent = action.payload
    },
    clearCallEvent: (state, action) => {
      state.phoneValue = ''
      state.dtmf = null
      state.callHandler = null
      state.callStatus = null
      state.callMuted = false
      state.callOnHold = false
      state.callDuration = { mins: 0, secs: 0 }
      state.clientNumber = ''
      state.clientUid = ''
      state.userUid = ''
      state.callMember = null
      state.onOutgoingCall = false
      state.onIncomingCall = false
      state.callDirection = null
      state.mobileKeypadOpen = false
      state.notConversationEvent = null
      if (!action.payload) {
        state.answerIsClicked = null
      }
    },
  },
})

export const {
  setCalls,
  setDtmf,
  setAudioPermission,
  setVoicemails,
  setTabSelected,
  setPrepopulatedNumber,
  loadAppoloCallsState,
  setMobileKeypadOpen,
  setOnOutgoingCall,
  setOnIncomingCall,
  setOpenVideoCallLinkModal,
  setCopyInvitationClicked,
  setCallMuted,
  setCallOnHold,
  setIncomingCallMobile,
  setIncomingCallWeb,
  setCallHandler,
  setCallStatus,
  clearCallEvent,
  setPhoneValue,
  setCallDuration,
  setCallDirection,
  setClientNumber,
  setClientUid,
  setCallMember,
  setUserUid,
  setVonageSessionID,
  setPrepopulatedNumberClicked,
  setAnswerIsClicked,
  initVonageClient,
  setNotConversationEvent,
  clearVonageClient,
} = callSlice.actions

export default callSlice.reducer
