import { forwardRef, useState, useEffect } from 'react'

// const WaveformProgressBar = ({ className, isVisible, progress, style }) => {
//   return (
//     <div
//       className={`${className} voicemail-indeterminate-progress-bar opacity-100
//       ${!isVisible && '!opacity-0'}`}
//     >
//       <div className="voicemail-indeterminate-progress-bar__progress"></div>
//     </div>
//   )
// }

const Waveform = forwardRef(({ className, isVisible }, ref) => {
  return (
    <div
      className={`${className} transition-all duration-300 h-full w-full opacity-0 
        ${isVisible && 'opacity-100'}`}
    >
      <div
        ref={ref}
        className={`${className} relative w-full h-full  
        `}
      ></div>
    </div>
  )
})

const WaveformWithLoadBar = forwardRef(
  (
    { className, wavesurfer, onWaveformLoad, loadingState, waveWidth },
    waveformRef,
  ) => {
    const [, setLoadingProgress] = useState(0)
    const [, setWaveformLoaded] = useState(false)
    // const loadComplete = loadingProgress === 100 && waveformLoaded

    const handleWaveformReady = () => {
      setWaveformLoaded(true)
      onWaveformLoad()
    }
    useEffect(() => {
      if (wavesurfer) {
        wavesurfer.on('loading', (progress) => setLoadingProgress(progress))
        wavesurfer.on('ready', handleWaveformReady)
      }
    }, [wavesurfer])

    return (
      <div
        className={`${className} flex items-center ${
          waveWidth ? waveWidth : 'w-[30%]'
        } h-[28px]`}
      >
        <Waveform ref={waveformRef} isVisible={!loadingState} />
      </div>
    )
  },
)

export default WaveformWithLoadBar
