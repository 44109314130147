import { Quill } from 'react-quill'

const Delta = Quill.import('delta')

export const getEditorSelection = (quillEditorRef) => {
  if (quillEditorRef.current === null) return null
  const editor = quillEditorRef.current.getEditor()
  const text = editor.getText()
  const selection = editor.getSelection()
  const selectedText = selection
    ? text
        .split('')
        .slice(selection.index, selection.index + selection.length)
        .join('')
    : ''
  return selection ? { text: selectedText, index: selection.index } : null
}

export const getEditorText = (quillEditorRef) => {
  return quillEditorRef.current !== null
    ? quillEditorRef.current.getEditor().getText().replace(/\n/g, '')
    : null
}

// If multiple links have matching text or linktext, find the correct one
export const getLinkFromSelection = (quillEditorRef, selection, linkText) => {
  if (!linkText || !selection) return ''

  const html = quillEditorRef.current.getEditor().root.innerHTML
  const parser = new DOMParser()
  const htmlDoc = parser.parseFromString(html, 'text/html')
  const matchingAnchorTags = Array.from(htmlDoc.getElementsByTagName('a'))
    .filter((anchorTag) => {
      return anchorTag.getAttribute('href') === linkText
    })
    .map((tag) => {
      const tagText = tag.textContent
      const linkText = tag.getAttribute('href')
      const plainTextStartIndex = htmlDoc.body.textContent.indexOf(tagText)
      const endIndex = plainTextStartIndex + tagText.length
      return {
        startIndex: plainTextStartIndex,
        endIndex,
        length: tagText.length,
        linkText,
        tagText,
      }
    })

  if (matchingAnchorTags.length === 0) return null
  if (matchingAnchorTags.length === 1) return matchingAnchorTags[0]
  if (matchingAnchorTags.length > 1) {
    const selectedLink =
      matchingAnchorTags.find((link) => {
        // eslint-disable-next-line no-console
        console.log('Links to compare: ', link)
        return (
          link.startIndex <= selection.index &&
          link.endIndex >= selection.index + selection.text.length
        )
      }) ||
      matchingAnchorTags.reduce((prev, curr) => {
        const prevDiff = Math.abs(prev.startIndex - selection.index)
        const currDiff = Math.abs(curr.startIndex - selection.index)
        return currDiff < prevDiff ? curr : prev
      })
    return selectedLink
  }
}

export const insertEmoji = (quillEditorRef, emoji, selectionIndex) => {
  const editor = quillEditorRef.current.getEditor()
  editor.updateContents(new Delta().retain(selectionIndex).insert(emoji))
}

export const insertHyperlink = (
  quillEditorRef,
  displayText,
  originalTextLength,
  selectionIndex,
  link,
) => {
  const editor = quillEditorRef.current.getEditor()
  const currentIndex =
    selectionIndex === null ? editor.getLength() - 1 : selectionIndex
  editor.updateContents(
    new Delta()
      .retain(currentIndex)
      .delete(originalTextLength)
      .insert(displayText, { customlink: link }),
  )
}
