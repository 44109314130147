import { Button, RegularX } from '@thryvlabs/maverick'
import { ThryvExclamationIcon } from '../../../command-center-icon/icons'

export const DowngradePlanModal = ({ handleClose }) => {
  return (
    <div
      data-testid="downgrade-modal"
      className="flex flex-col rounded p-8 justify-center items-center h-[464px] md:w-[298px] "
    >
      <div className="flex gap-8 self-stretch">
        <Button
          data-testid="close-x-btn"
          className="fixed right-[5%] top-[8%] md:top-[5%] md:right-[3%] mr-[0.5rem]"
          variant="text"
          level={2}
          onClick={handleClose}
        >
          <RegularX type="regular" variant="x" height="18" fill="#A3A5A7" />
        </Button>
      </div>
      <div className="flex flex-col w-[380px] items-center gap-6 text-center">
        <div className="">
          <ThryvExclamationIcon />
        </div>
        <div className="flex flex-col w-[380px] gap-6 items-center">
          <div className="w-[380px] font-montserrat text-[22px] font-semibold leading-[30px]">
            Work in progress: <br /> Downgrade Plan
          </div>
          <div className="w-[342px] font-open-sans text-[14px] leading-5">
            <p>
              At this time, “Downgrade Plan” is a work in progress.
              <br />
              We'll notify you via email and send you a notification
              <br />
              as soon as it's ready. We apologize for any
              <br /> inconvenience and appreciate your patience.
            </p>
            <br />
            <p>
              In the meantime, please contact{' '}
              <span className="text-[#057AFF]">support@thryv.com </span> to downgrade
              your Command Center plan. Thank you for understanding!
            </p>
          </div>
        </div>
        <div className="flex items-end justify-center self-stretch">
          <div className="flex px-[24px] py-[9px] justify-center items-center gap-2 bg-[#FF5000] rounded">
            <Button
              data-testid="close-btn"
              variant="text"
              level={1}
              onClick={handleClose}
              className="font-open-sans text-[#FFF] font-semibold text-[13px]"
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
