import { NavLink } from 'react-router-dom'
import { VerifyEmailIcon } from '../command-center-icon/icons'

const EmailVerified = () => {
  return (
    <div className="min-w-screen w-screen min-h-screen h-screen bg-no-repeat bg-cover bg-[url('https://cdn.sandbox.thryv.com/login-background.svg')] flex justify-center items-center sm:p-3">
      <div className="w-[540px] h-[444px] bg-white rounded shadow-md p-8 flex flex-col justify-start items-center pt-[68px] sm:w-full sm:p-8">
        <VerifyEmailIcon />
        <h4 className="font-montserrat font-semibold text-[22px] leading-[30px] mt-6 mb-6">
          Email is Verified!
        </h4>
        <p className="w-[382px] font-open-sans text-sm leading-5 text-center sm:w-full">
          Your email has been successfully verified. You can now go to the login page
          to access the Command Center.
        </p>

        <NavLink
          to="/"
          className="bg-thryv-orange-500 font-open-sans text-white font-semibold text-sm mt-[64px] pt-[9px] pb-[9px] pl-6 pr-6 rounded hover:bg-thryv-orange-700"
        >
          Go to Command Center
        </NavLink>
      </div>
    </div>
  )
}

export default EmailVerified
