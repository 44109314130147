import { Button, Header, Icon, ModalWrapper } from '@thryvlabs/maverick'
import { ThryvExclamationIcon } from '../../../command-center-icon/icons'

const ChannelsPendingReauthModal = ({
  showPendingReauthModal,
  setShowPendingReauthModal,
  openManageModal,
}) => {
  const handleCloseModal = () => {
    setShowPendingReauthModal(false)
  }

  const handleReauthClick = () => {
    setShowPendingReauthModal(false)
    openManageModal()
  }

  return (
    <ModalWrapper
      show={showPendingReauthModal}
      close={handleCloseModal}
      disableBackdropClick={false}
    >
      <div className="w-[410px] p-2 sm:w-full">
        <div className="flex justify-end gap-8 w-full">
          <Button variant="text" level={2}>
            <Icon
              type="regular"
              variant="x"
              height="18"
              fill="#A3A5A7"
              onClick={handleCloseModal}
            />
          </Button>
        </div>
        <div className="flex justify-center pb-5">
          <ThryvExclamationIcon />
        </div>
        <Header variant="h4" fontWeight="semibold">
          Channels Pending Reauthentication
        </Header>
        <p className="font-open-sans text-sm text-thryv-black py-5">
          To apply the recent performance update for channels, please reauthenticate
          all your connected channel accounts. Without reauthentication, you will not
          be able to send or receive messages on these channels.
        </p>
        <div className="pt-3">
          <Button
            variant="text"
            level={2}
            textVariant="light"
            className="pr-3"
            onClick={handleCloseModal}
          >
            Do it later
          </Button>
          <Button variant="primary" onClick={handleReauthClick}>
            Reauthenticate now
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ChannelsPendingReauthModal
