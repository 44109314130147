import { Button, withModal } from '@thryvlabs/maverick'
import { AddStaffModal } from '../common/add-staff-modal'

const AddStaffButton = ({ showModal }) => {
  return (
    <Button
      className="w-full h-12 text-sm"
      variant="primary"
      buttonType="outlined"
      onClick={showModal}
    >
      Add Staff
    </Button>
  )
}

export const AddStaffMobileNav = () => {
  const AddStaffButtonWithModal = withModal(AddStaffButton, AddStaffModal)
  return <AddStaffButtonWithModal />
}
