import { CommandCenterIcon } from '../../../../command-center-icon'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'

export const HyperlinkModalOpenButton = ({
  disableButtons,
  handleClick,
  fromTooltip,
}) => {
  // if disableButtons is true, return disable version right away
  if (disableButtons) {
    return (
      <div
        className={`w-[30px] h-[30px] rounded-[50%] flex items-center justify-center  ${
          !disableButtons ? 'hover:bg-thryv-gray-light-200' : 'opacity-30'
        }`}
        onClick={handleClick}
      >
        <CommandCenterIcon
          variant="hyperlink"
          type="regular"
          width={20}
          height={20}
          fill="#808080"
        />
      </div>
    )
  }

  // if disableButtons is false, we check if we are currently on tooltip and render correspondingly
  return fromTooltip ? (
    <a
      className="text-btn-links font-semibold bg-none border-0 font-open-sans text-thryv-cornflower-500 normal-case disabled:text-thryv-steel visited:text-thryv-cornflower-500"
      onClick={handleClick}
    >
      Edit
    </a>
  ) : (
    <TooltipWithCenteredArrow
      variant="top"
      title="Insert link"
      className="!max-w-[500px] !min-w-0"
      positioning={{ offsetX: '0px', offsetY: '3px' }}
      disableVisibleOnClick={true}
      width={'max-content'}
    >
      <div
        className={`w-[30px] h-[30px] rounded-[50%] flex items-center justify-center  ${
          !disableButtons ? 'hover:bg-thryv-gray-light-200' : 'opacity-30'
        }`}
        onClick={handleClick}
      >
        <CommandCenterIcon
          variant="hyperlink"
          type="regular"
          width={20}
          height={20}
          fill="#808080"
        />
      </div>
    </TooltipWithCenteredArrow>
  )
}
