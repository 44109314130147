import { Button, Icon, Input, ParagraphText } from '@thryvlabs/maverick'

export const HyperlinkModalContent = ({
  displayText,
  setDisplayText,
  link,
  setLink,
  showPreviewLink,
  showLinkError,
}) => {
  let previewLink
  // Add a default protocol if none is present
  if (!/^https?:\/\//i.test(link)) {
    previewLink = 'http://' + link
  }
  return (
    <div className="flex flex-col items-start pt-8">
      <div className="flex-1 w-full pb-6">
        <ParagraphText
          variant="reg"
          color="thryv-steel"
          className="font-semibold pb-2"
        >
          Display text
        </ParagraphText>
        <Input
          variant="default"
          placeholder="text to display as link"
          className="w-full"
          value={displayText}
          onChange={(event) => setDisplayText(event.target.value)}
        />
      </div>

      <div className="flex-1 w-full pb-4">
        <ParagraphText
          variant="reg"
          color="thryv-steel"
          className="font-semibold pb-2"
        >
          URL (Link)
        </ParagraphText>
        <Input
          variant="default"
          placeholder="Link"
          className="w-full"
          value={link}
          onChange={(event) => setLink(event.target.value)}
          isValid={!showLinkError}
          errorMessage={
            link.includes('http')
              ? 'The URL is invalid.'
              : 'The URL is invalid. Make sure to include https:// in the URL.'
          }
        />
      </div>

      {showPreviewLink && (
        <div className="flex flex-row items-center justify-start gap-2">
          <Button variant="text" level={1} className="hover:underline">
            <a href={previewLink} target="_blank" rel="noreferrer">
              Preview Link
            </a>
          </Button>
          <a href={previewLink} target="_blank" rel="noreferrer">
            <Icon
              variant="externalLink"
              type="regular"
              width={14}
              height={14}
              fill="#057aff"
            />
          </a>
        </div>
      )}
    </div>
  )
}
