import { useState } from 'react'

// Resize logic source: https://stackoverflow.com/questions/62436814/react-drag-corner-of-element-to-resize-contents
export const useResize = (x) => {
  const [size, setSize] = useState({ x: x })

  const resizeHandler = (mouseDownEvent) => {
    const startSize = size
    const startPosition = { x: mouseDownEvent.pageX }

    function onMouseMove(mouseMoveEvent) {
      setSize(() => ({
        x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
      }))
    }

    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove)
    }

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  return [resizeHandler, size]
}
