import { createMessagesListForPagination } from '../utils/createMessagesListForPagination'

// Components
import { MessageRow } from './message-row'
import { NoResultsFoundSection } from '../../no-results-found-section'
import { PaginatedList } from '@thryvlabs/maverick'
import StyledPaginatedListWrapper from '../common/paginated-list-wrapper'
// An alternative to loading dots
import { LoadingSpinner } from '../../../../common/loading-spinner'

const PaginatedMessages = ({ list }) => (
  <StyledPaginatedListWrapper>
    <PaginatedList list={list}>
      <MessageRow />
    </PaginatedList>
  </StyledPaginatedListWrapper>
)

const LoadingDotsInContainer = () => (
  <div className="relative h-full w-full">
    <LoadingSpinner />
  </div>
)

const MessagesSection = ({ searchTerm, messagesList, messagesLoading }) => {
  const showNoResultsFound = !messagesList.length && !messagesLoading

  const listForPagination = createMessagesListForPagination(
    messagesList,
    searchTerm,
    'my-2.5',
  )

  return (
    <>
      {messagesList.length ? <PaginatedMessages list={listForPagination} /> : null}
      {messagesLoading ? <LoadingDotsInContainer /> : null}
      {showNoResultsFound ? <NoResultsFoundSection /> : null}
    </>
  )
}

export default MessagesSection
