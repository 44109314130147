import { useState, useEffect } from 'react'
import { Header, Button, Avatar, ParagraphText } from '@thryvlabs/maverick'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { setOpenVideoCallLinkModal } from '../slices/callSlice'
import { CREATE_VONAGE_MEETING_ROOM } from '../../../graphql/mutations/vonage-mutations'
import { GET_ACTIVE_VIDEO_ROOM } from '../../../graphql/queries/vonage-queries'
import { LoadingDots } from '../../common/loading-dots'
import {
  setRoomInfo,
  setRoomData,
} from '../../../redux-toolkit/slices/meetings/meetings-slice'
import { Buffer } from 'buffer'
import { Notification } from '../../common/notification'
import {
  setModalContent,
  setShowModal,
} from '../../common/modals/slices/connectChannelsSlice'
import avatarColorPicker from '../../../utils/avatarColorPicker'
import './style.css'
window.Buffer = Buffer

const VideoCallLinkModal = ({ contactData, selectedThreadId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAuth0()

  const [meetingRoomLink, setMeetingRoomLink] = useState({})
  const [, setRoomID] = useState('')
  const [showMinutesLimitError, setShowMinutesLimitError] = useState(false)
  const body = contactData?.queryContactsByThread?.items?.[0]?.body
  const [contact] = useState(body ? JSON.parse(body) : {})
  const [contactName, setContactName] = useState({ given_name: '', surname: '' })
  const [retryClicked, setRetryClicked] = useState(false)
  const { selectedThread } = useSelector((state) => state.inboxThreads)
  const [createVonageMeetingRoom, { loading, error }] = useMutation(
    CREATE_VONAGE_MEETING_ROOM,
    {
      variables: {
        CC_UserID: user.cc_uid,
        threadId: selectedThread.id,
      },
      onCompleted: (data) => {
        const roomData = data.createVonageMeetingRoom
        setMeetingRoomLink(data.createVonageMeetingRoom)
        dispatch(setRoomInfo(meetingRoomLink))
        dispatch(setRoomData({ roomData, threadId: selectedThreadId }))
        setRoomID(data.createVonageMeetingRoom.RoomID)
      },
      onError: (error) => {
        const errObj = { error }
        const errorMessage = errObj?.error?.message
        if (errorMessage.includes('has no vonage time.')) {
          setShowMinutesLimitError(true)
        }
      },
      refetchQueries: () => [
        {
          query: GET_ACTIVE_VIDEO_ROOM,
          variables: { threadId: selectedThread.id },
        },
      ],
    },
  )

  useEffect(() => {
    createVonageMeetingRoom()
  }, [retryClicked])

  useEffect(() => {
    if (loading) setRetryClicked(false)
  }, [loading])

  useEffect(() => {
    if (
      (contact?.given_name === '' && contact?.surname === '') ||
      /\d{10}/.test(contact?.given_name)
    ) {
      setContactName({ given_name: 'Unknown', surname: '' })
    } else {
      setContactName({
        given_name: contact?.given_name,
        surname: contact?.surname,
      })
    }
  }, [contact])

  const openUpgradeModal = () => {
    dispatch(setModalContent(4))
    dispatch(setShowModal(true))
    dispatch(setOpenVideoCallLinkModal(false))
  }

  return (
    <div
      data-testid="video-call-link-modal"
      className="flex flex-col items-start p-[32px] gap-[24px] sm:px-0"
    >
      <div className="w-full h-[30px] flex justify-center mx-auto">
        <Header
          fontWeight="semibold"
          variant="h4"
          className="sm:!text-[19px] x-sm:!text-[17px]"
        >
          Start Video Call
        </Header>
      </div>

      <div className="flex flex-col justify-center mx-auto items-center gap-5 text-center  sm:w-full">
        <Avatar
          variant="name"
          size="large"
          name={{
            firstName: contactName?.given_name?.toUpperCase(),
            lastName: contactName?.surname?.toUpperCase(),
          }}
          staticBackground
          backgroundColor={avatarColorPicker(contactName.surname?.at(0))}
        />

        {error ? (
          <ParagraphText variant="lg">
            <b>Hi {user.name.split(' ')[0]}</b>, sorry but there seems to be an
            <b className="!text-[#DF2A2A]"> error.</b> Please try again.
          </ParagraphText>
        ) : (
          <div className="flex flex-col mx-auto h-10 items-center">
            <ParagraphText variant="lg">
              <b>Hi {user.name.split(' ')[0]},</b> you've invited{' '}
              {contactName?.given_name} to a video call.{' '}
              {loading
                ? 'Your link is being created.'
                : 'Click to join the meeting.'}
            </ParagraphText>

            {loading && (
              <div className="mt-[10px] mb-[12px]">
                <LoadingDots />
              </div>
            )}
          </div>
        )}
      </div>

      {showMinutesLimitError ? (
        <div className="flex flex-col justify-center  items-center gap-5">
          <Notification
            variant="warning"
            iconType="solid"
            className={'w-full'}
            textToRender={
              'You have used all your minutes. Please upgrade to continue to make Video Calls'
            }
          />
          <Button variant="secondary" onClick={openUpgradeModal}>
            get more minutes
          </Button>
        </div>
      ) : null}

      {showMinutesLimitError ? null : (
        <div className="w-full h-[32px] flex justify-center gap-4 mt-5">
          <Button
            variant="text"
            level={1}
            textVariant="light"
            onClick={() => dispatch(setOpenVideoCallLinkModal(false))}
          >
            {error ? 'CLOSE' : 'DISMISS'}
          </Button>
          {!loading && !error && (
            <Button
              variant="primary"
              onClick={() => {
                dispatch(setOpenVideoCallLinkModal(false))
                navigate('/meetings')
              }}
            >
              Join Call
            </Button>
          )}
          {error && (
            <Button variant="primary" onClick={() => setRetryClicked(true)}>
              Retry
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default VideoCallLinkModal
