import { createSelector } from 'reselect'

const selectCalls = ({ calls }) => calls

export const callsSelector = createSelector(
  selectCalls,
  ({
    audio,
    callDirection,
    callDuration,
    callHandler,
    callMember,
    callMuted,
    callOnHold,
    callStatus,
    calls,
    clientNumber,
    clientUid,
    copyInvitationClicked,
    incomingCallMobile,
    incomingCallWeb,
    mobileKeypadOpen,
    onIncomingCall,
    onOutgoingCall,
    openVideoCallLinkModal,
    phoneValue,
    prepopulatedNumber,
    tabSelected,
    tier,
    userUid,
    voicemails,
    audioPermission,
    vonageClient,
  }) => ({
    audio,
    callDirection,
    callDuration,
    callHandler,
    callMember,
    callMuted,
    callOnHold,
    callStatus,
    calls,
    clientNumber,
    clientUid,
    copyInvitationClicked,
    incomingCallMobile,
    incomingCallWeb,
    mobileKeypadOpen,
    onIncomingCall,
    onOutgoingCall,
    openVideoCallLinkModal,
    phoneValue,
    prepopulatedNumber,
    tabSelected,
    tier,
    userUid,
    voicemails,
    audioPermission,
    vonageClient,
  }),
)
