import { ParagraphText } from '@thryvlabs/maverick'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import usePhoneChannel from '../../../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
const GetAreaCode = () => {
  const isXSmallMobile = useMediaQuery({ maxWidth: 375 })
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const {
    user: { employee },
  } = useAuth0()
  const isEmp = countryIso2 === 'US' && employee === 'thryv'
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  return (
    <>
      <ParagraphText
        variant="lg"
        className="mt-10 mb-5 text-[22px] font-semibold leading-[30px]"
      >
        {isEmp ? 'Hello, fellow Thryver!' : "Let's get your number"}
      </ParagraphText>
      <ParagraphText variant={isXSmallMobile ? 'sm' : 'reg'} className="mb-5">
        {isEmp &&
          'Since this is an employee account, you are only able to select from the following area codes: 833, 844, or 855.'}
      </ParagraphText>
      <ParagraphText variant={isXSmallMobile ? 'sm' : 'reg'} className="mb-5">
        {isEmp
          ? 'Please enter your preferred area code from the three listed.'
          : !isActive
            ? "Please enter the preferred area code for your business's calls and voicemails to get started."
            : "Please enter the preferred area code for your business's calls, voicemails, and SMS messages to get started."}
      </ParagraphText>
    </>
  )
}

export default GetAreaCode
