import { createSlice } from '@reduxjs/toolkit'

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState: {
    isViewingWelcomeMessage: false,
    noChannelsConnected: false,
    supportChannelId: '',
    supportChannelThreadId: '',
  },
  reducers: {
    setIsViewingWelcomeMessage(state, action) {
      state.isViewingWelcomeMessage = action.payload
    },
    setNoChannelsConnected(state, action) {
      state.noChannelsConnected = action.payload
    },
    setSupportChannelId(state, action) {
      state.supportChannelId = action.payload
    },
    setSupportChannelThreadId(state, action) {
      state.supportChannelThreadId = action.payload
    },
  },
})

export const {
  setIsViewingWelcomeMessage,
  setNoChannelsConnected,
  setSupportChannelId,
  setSupportChannelThreadId,
} = welcomeSlice.actions

export default welcomeSlice.reducer
