import { Input } from '@thryvlabs/maverick'
import { useCountryData } from '../../../../hooks/use-country-data'

export const CountryCodeInput = () => {
  const countryData = useCountryData()
  return (
    <div className="relative">
      <Input
        type="tel"
        customPlaceholder={countryData.emojiAndCountryCode}
        name="countryCode"
        withLabel
        labelType="static"
        variant="default"
        disabled={true}
      />
      <label
        htmlFor="countryCode"
        className='pointer-events-none absolute left-0 -top-[16px] capitalize 
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text 
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
      >
        Country Code
      </label>
    </div>
  )
}
