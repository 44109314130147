import { CommandCenterIcon } from '../../../../command-center-icon'
import getSocialIconProps from './getSocialIconProps'

export const MessageSocialIcon = ({ variant }) => {
  const socialIcon = getSocialIconProps(variant)

  return (
    <div
      className={`flex justify-center items-center`}
      style={{ width: socialIcon.width, height: socialIcon.height }}
    >
      <CommandCenterIcon
        type={socialIcon.type}
        variant={socialIcon.variant}
        width={socialIcon.width}
        height={socialIcon.height}
      />
    </div>
  )
}
