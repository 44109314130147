import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleInfo
  } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip } from '@thryvlabs/maverick'

const InfoTooltip = ({description}) => {
  return (
    <Tooltip variant="right" description={description} positioning={{
        offsetY: '-10px'
      }}>
    <FontAwesomeIcon
            className="text-[14px]"
            icon={faCircleInfo}
          /></Tooltip>
  )
}

export default InfoTooltip