import { createSelector } from 'reselect'

const selectUserPreferences = ({ userPreferences }) => userPreferences

export const userPreferencesSelector = createSelector(
  selectUserPreferences,
  ({
    hasBusinessCenter,
    hasMarketingCenter,
    hasListingsManagement,
    hasSignatures,
    hasWebsiteBuilder,
    hasThryvLeads,
    hasReportingCenter,
    letAnimate,
    navigationCollapsed,
  }) => ({
    hasBusinessCenter,
    hasMarketingCenter,
    hasListingsManagement,
    hasSignatures,
    hasWebsiteBuilder,
    hasThryvLeads,
    hasReportingCenter,
    letAnimate,
    navigationCollapsed,
  }),
)
