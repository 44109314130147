import { useState } from 'react'
import inboxFilters from '../../../../../data/filter-options.json'

// Components
import { FilterItem, SelectionButtonWithMenu } from '../../common'

const ItemButton = ({ children, onClick }) => {
  const hoverAnimClass =
    'hover:bg-thryv-gray-light-200 duration-300 transition-all active:bg-thryv-gray-light-200'

  return (
    <button
      type="button"
      className={`block w-full text-start hover:bg-thryv-gray-light-200 py-1 md:py-[9px] px-2 rounded font-['Open_Sans'] text-sm ${hoverAnimClass} text-thryv-black-500`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const SearchInOption = ({ defaultFilter }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(
    defaultFilter || inboxFilters[0],
  )

  const updateFilter = (filter) => {
    setSelectedFilter(filter)
    setIsOpen(false)
  }

  return (
    <FilterItem title="Search in">
      <input name="search in" value={selectedFilter} className="hidden" readOnly />
      <SelectionButtonWithMenu
        dataTestID="search-filter"
        className="!w-full"
        onClick={() => setIsOpen(false)}
        isOpen={isOpen}
        onStateChange={(state) => state === 'dropdown open' && setIsOpen(true)}
        content={
          <div className="p-2">
            {inboxFilters.map((filter, index) => (
              <ItemButton key={index} onClick={() => updateFilter(filter)}>
                {filter}
              </ItemButton>
            ))}
          </div>
        }
      >
        {selectedFilter}
      </SelectionButtonWithMenu>
    </FilterItem>
  )
}
