import { forwardRef } from 'react'
import { ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../command-center-icon'

export const SubheaderTag = forwardRef(
  ({ children, onClick, iconVariant, iconType, labelsSelected, ...rest }, ref) => {

    const labelButtonColors = () => {
      if (labelsSelected) {
        return "flex px-2 bg-[#3D5199] items-center rounded h-6"
      } else if (!labelsSelected) {
        return "flex px-2 bg-thryv-white-300 items-center rounded h-6"
      }
    }

    const labelTagIconColors = () => {
      if (labelsSelected) {
        return "#ECEEF1"
      } else if (!labelsSelected) {
        return "#92929D"
      }
    }

    const labelTextColors = () => {
      if (labelsSelected) {
        return "white"
      } else if (!labelsSelected) {
        return "thryv-cornflower-500"
      }
    }

    const labelButtonStyle = labelButtonColors()
    const labelTagColor = labelTagIconColors()
    const labelTextColor = labelTextColors()

    return (
      <button
        className={labelButtonStyle}
        onClick={onClick}
        {...rest}
        ref={ref}
      >
        <div className="flex gap-1.5">
          <div className="flex pt-[1px]">
            <CommandCenterIcon
              type={iconType}
              variant={iconVariant}
              width="12"
              height="14"
              color={labelTagColor}
            />
          </div>

          <ParagraphText
            variant="sm"
            color={labelTextColor}
            className="font-semibold leading-snug"
          >
            {children}
          </ParagraphText>
        </div>
      </button>
    )
  },
)
