import { gql } from '@apollo/client'

export const GET_BRAND_CAMPAIGN_BY_CHANNEL_ID = gql`
  query QueryBrandCampaignByChannelID($channel_id: String!) {
    queryBrandCampaignByChannelID(channel_id: $channel_id) {
      brand_id
      campaign_id
      channel_id
      business_name
      entity_type
      dba
      vertical
      stock_symbol
      stock_exchange
      owner_first_name
      owner_last_name
      phone
      email
      street
      state
      city
      postal_code
      ein
      created_at
      last_updated
      brand_status
      campaign_status
      brand_errors {
        name
        description
        fields
      }
      campaign_error_message
      link_status
      needs_confirmation
      support_contact
      support_type
      privacy_policy_url
      tc_url
      consent_method
      consent_details
      cta_image_url
    }
  }
`

export const GET_CONNECTED_CHANNEL_BY_ID = gql`
  query QueryConnectedChannelByID($channel_id: String!) {
    queryConnectedChannelByID(channel_id: $channel_id) {
      CC_UserID
      AccessType
      ChannelID
      ExternalAccountID
      CCID
      AccountUserValue
      Status
      Provider
      VendorID
      syncState
      Nickname
      CountryCode
      ChannelAccessLevel
    }
  }
`
