import {
  Button,
  Icon,
  ModalTitle,
  ModalWrapper,
  ParagraphText,
} from '@thryvlabs/maverick'
import { CheckIcon } from '../../../../../command-center-icon/icons'

export const CertPending = ({ handleCloseModal, handleContinuePendingModal }) => {
  return (
    <div className="w-[500px] h-[448px]">
      <div className="flex justify-between gap-8 w-full">
        <div className="flex gap-4 items-center"></div>
        <Button className="" variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={handleCloseModal}
          />
        </Button>
      </div>
      <div className="mt-5 px-16">
        <CheckIcon className="m-auto" />
        <div className="my-5">
          <ModalTitle variant="primary">Almost Done!</ModalTitle>
        </div>
        <ParagraphText variant="reg" className="text-sm leading-[20px]">
          We’ve submitted your number for registration. You’ll receive a text with a
          verification code shortly. Click ‘Continue’ to be taken to the verification
          page, where you will enter the code.
        </ParagraphText>
      </div>
      <Button variant="primary" className="mt-20" onClick={handleContinuePendingModal}>
        Continue
      </Button>
    </div>
  )
}

const CertPendingModal = ({ showModal, handleCloseModal }) => {
  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseModal}
      disableBackdropClick={false}
    >
      <CertPending />
    </ModalWrapper>
  )
}

export default CertPendingModal
