import { useEffect, useState } from 'react'
import DefaultScreen from '../../recent-and-voicemail-components/default-screens/DefaultScreenMain'
import Recent from './Recent'
import { useSelector } from 'react-redux'
import { PaginatedList } from '@thryvlabs/maverick'

const Recents = ({
  setDisplayInfoCard,
  isMobile,
  contactInfoView,
  setContactInfoView,
  setShowMobileContact,
}) => {
  const [callsReceivedTab, setCallsReceivedTab] = useState('all')
  const calls = useSelector((state) => state.calls.calls)
  const tabSelected = 'recent'

  useEffect(() => {
    setCallsReceivedTab('all')
  }, [tabSelected])

  useEffect(() => {
    setCallsReceivedTab('all')
  }, [isMobile])

  return (
    <>
      {calls?.length ? (
        <div className="h-full min-w-md:pt-[13px] p-[20px] md:p-[0px] md:bg-white">
          <PaginatedList list={calls}>
            <Recent
              contactInfoView={contactInfoView}
              setContactInfoView={setContactInfoView}
              setDisplayInfoCard={setDisplayInfoCard}
              isMobile={isMobile}
              callsReceivedTab={callsReceivedTab}
              setShowMobileContact={setShowMobileContact}
            />
          </PaginatedList>
        </div>
      ) : (
        <DefaultScreen tabSelected="recent" isMobile={isMobile} />
      )}
    </>
  )
}

export default Recents
