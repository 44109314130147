import { forwardRef } from 'react'
import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'
import { useSelector } from 'react-redux'
import capitalizeFirstLetter from '../../inbox/utils/capitalizeFirstLetter'
import { setModalContent, setShowModal } from '../modals/slices/connectChannelsSlice'
import { useDispatch } from 'react-redux'

const validateType = (variant) => {
  if (variant !== 'error' && variant !== 'warning') {
    //add error here
  } else return true
}

export const CredentialChangedNotification = forwardRef(
  (
    {
      iconType,
      variant,
      handleHideDisconnectedChannelsNotification,
      handleHideDisconnectingChannelNotification,
      className,
      styles,
      iconVariant,
      textSize,
      updateButton = false,
    },
    ref,
  ) => {
    // types available = 'error' | 'warning'

    validateType(variant)
    let iconProps = {
      variant: '',
      color: '',
    }
    let bannerStyles = ''
    switch (variant) {
      case 'error':
        iconProps.color = 'red'
        iconProps.variant = 'circleX'
        bannerStyles = 'bg-[#FCEAEA] border-[#EF9595]'
        break
      case 'warning':
        iconProps.color = '#FAC02B'
        iconProps.variant = 'triangleExclamation'
        bannerStyles = 'bg-[#FFF9EA] border-[#FDDF95]'
        break
      default:
        throw new Error(`Variant "${variant}" is not valid.`)
    }

    const wrapperStyles = ` ${className} flex flex-row justify-between items-start border rounded ${bannerStyles} p-[8px] text-[14px]`

    // gets the name of the channels disconnected to show on the text
    const {
      channelDisconnected,
      showChannelDisconnectedNotification,
      channelDisconnecting,
      showChannelDisconnectingNotification,
    } = useSelector((state) => state.connectChannels)
    const providerArray = []

    channelDisconnected?.forEach((element) => {
      if (!providerArray.includes(capitalizeFirstLetter(element.Provider))) {
        providerArray.push(capitalizeFirstLetter(element.Provider))
      }
    })

    const dispatch = useDispatch()

    const handleOpenModal = () => {
      dispatch(setShowModal(true))
      dispatch(setModalContent(2))
    }

    //This shows the value of the account disconnect if we need to
    // (${channelDisconnected[0]?.AccountUserValue}) account`}

    return (
      <>
        {providerArray.length > 0 && showChannelDisconnectedNotification ? (
          <div className={wrapperStyles} ref={ref} style={styles}>
            <div className="flex gap-2 text-center">
              <span className=" pt-[.16rem]">
                <Icon
                  variant={iconVariant ? iconVariant : iconProps.variant}
                  type={iconType}
                  color={iconProps.color}
                  height="16"
                />
              </span>
              {updateButton ? (
                <div className="text-left">
                  {providerArray.join(', ')}
                  {providerArray.length > 1 ? ' accounts ' : ` account `}
                  has been disconnected. Please update the account information to
                  continue to use the channel.
                  <Button
                    variant="text"
                    className="text-sm ml-1"
                    level={1}
                    onClick={() => handleOpenModal()}
                  >
                    Update now
                  </Button>
                </div>
              ) : (
                <ParagraphText
                  variant={textSize || 'reg'}
                  className="text-left sm:text-center"
                >
                  {providerArray.join(', ')}
                  {providerArray.length > 1 ? ` accounts ` : ' account '}
                  has been disconnected. Please update the account information to
                  continue to use the channel.
                </ParagraphText>
              )}
            </div>

            <span className="pt-[.3rem]">
              {handleHideDisconnectedChannelsNotification && (
                <Icon
                  aria-label="close-button"
                  variant="x"
                  type="regular"
                  height="10"
                  color="#808080"
                  onClick={handleHideDisconnectedChannelsNotification}
                  className="cursor-pointer"
                />
              )}
            </span>
          </div>
        ) : null}
        {channelDisconnecting !== '' && showChannelDisconnectingNotification ? (
          <div className={wrapperStyles} ref={ref} style={styles}>
            <div className="flex gap-2 text-center">
              <span className=" pt-[.16rem]">
                <Icon
                  variant={iconVariant ? iconVariant : iconProps.variant}
                  type={iconType}
                  color={iconProps.color}
                  height="16"
                />
              </span>
              <ParagraphText
                variant={textSize || 'reg'}
                className="text-left sm:text-center"
              >
                {channelDisconnecting?.Provider === 'video'
                  ? 'Your video chat channel has successfully been disconnected.'
                  : channelDisconnecting?.Provider === 'webchat' ||
                      channelDisconnecting?.Provider === 'phone'
                    ? `Your ${channelDisconnecting?.Provider} channel has successfully been disconnected.`
                    : `Your ${channelDisconnecting?.Provider} channel (${channelDisconnecting?.AccountUserValue}) has successfully been disconnected.`}
              </ParagraphText>
            </div>

            <span className="pt-[.3rem]">
              {handleHideDisconnectingChannelNotification && (
                <Icon
                  aria-label="close-button"
                  variant="x"
                  type="regular"
                  height="10"
                  color="#808080"
                  onClick={handleHideDisconnectingChannelNotification}
                  className="cursor-pointer"
                />
              )}
            </span>
          </div>
        ) : null}
      </>
    )
  },
)
