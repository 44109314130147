import { useState, forwardRef, useEffect, useContext } from 'react'
import moment from 'moment'
import { ParagraphText } from '@thryvlabs/maverick'
import { TransitionContainer } from '../../../../common/transition-container'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import './remind-me-menu.css'
import {
  CREATE_NOTIFICATION_MUTATION,
  DELETE_NOTIFICATION_MUTATION,
} from '../../../../../graphql/mutations/inbox-notification-mutations'
import { calculateDifferenceToMilliseconds } from './calculate-difference-to-milliseconds/calculateDifferenceToMilliseconds'
import {
  setNotifications,
  setRemoveNotifications,
  setReminderText,
  setShowReminderNotification,
} from '../../../slices/remindMeSlice'
import { calculateAddedTime } from './calculate-added-time/calculateAddedTime'
import { IncomingMessageContext } from '../../../../../context/message-context'
import { FETCH_MESSAGES_BY_THREAD_ID } from '@graphql'
const RemindMeButton = ({ children, onClick, className }) => {
  const hoverAnimClass = 'hover:bg-thryv-gray-light-200 duration-300 transition-all'

  return (
    <button
      type="button"
      className={`${className} ${hoverAnimClass} rounded text-left pl-4 py-2`}
      onClick={onClick}
    >
      <ParagraphText variant="reg" color="#231F20">
        {children}
      </ParagraphText>
    </button>
  )
}
// this could be removed bc its not being used anywhere
const RemindMeMenu = forwardRef(
  (
    {
      className,
      expanded,
      id,
      setOpenReminderModal,
      setIncomingMessageId,
      reminderTimeInMilliseconds,
      setReminderTimeInMilliseconds,
      setCustomReminderPk,
      setCustomReminderSk,
      selectedThreadId,
      ...rest
    },
    ref,
  ) => {
    const dispatch = useDispatch()
    const incomingMessage = useContext(IncomingMessageContext)
    const [incomingMessagePk, setIncomingMessagePk] = useState()
    const [incomingMessageSk, setIncomingMessageSk] = useState()
    const [isSameDatePersisted, setIsSameDatePersisted] = useState(null)
    const [isCustomDate, setIsCustomDate] = useState(null)
    const [reminderSet, setReminderSet] = useState(false)
    const [reminderTime, setReminderTime] = useState()
    const [customDate, setCustomDate] = useState()
    const handleCustomReminderOnClick = () => {
      setCustomReminderPk(incomingMessage.pk1)
      setCustomReminderSk(incomingMessage.sk1)
    }

    const [createNotificationReminder, { loading }] = useMutation(
      CREATE_NOTIFICATION_MUTATION,
    )

    useEffect(() => {
      if (reminderTimeInMilliseconds !== 0) {
        createNotificationReminder({
          variables: {
            PK1: incomingMessagePk,
            SK1: incomingMessageSk,
            agent_id: '',
            REMINDSK: reminderTimeInMilliseconds,
          },
          refetchQueries: [
            {
              query: FETCH_MESSAGES_BY_THREAD_ID,
              variables: { threadid: selectedThreadId },
              fetchPolicy: 'network-only',
            },
          ],
          onCompleted: () => {
            dispatch(setShowReminderNotification(true))
          },
        })
      }
    }, [reminderTimeInMilliseconds, incomingMessagePk, FETCH_MESSAGES_BY_THREAD_ID])

    const [deleteNotificationReminder] = useMutation(DELETE_NOTIFICATION_MUTATION, {
      variables: {
        PK1: incomingMessage.pk1,
        SK1: incomingMessage.sk1,
      },
      refetchQueries: [
        {
          query: FETCH_MESSAGES_BY_THREAD_ID,
          variables: { threadid: selectedThreadId },
          fetchPolicy: 'network-only',
        },
      ],
    })

    const handleRemoveNotification = () => {
      if (incomingMessage.msgpk === id) {
        deleteNotificationReminder()
        dispatch(setRemoveNotifications(id))
        setReminderSet(false)
      }
    }

    useEffect(() => {
      const persistReminderTime = moment
        .utc(incomingMessage.remindsk)
        .local()
        .format('h:mm A')
      setReminderTime(persistReminderTime)

      const persistCustomDate = moment
        .utc(incomingMessage.remindsk)
        .local()
        .format('ddd MMM D')
      setCustomDate(persistCustomDate)
    }, [reminderTime, customDate, incomingMessage.remindsk])

    useEffect(() => {
      const reminderSkToDate = moment.utc(incomingMessage.remindsk).local()
      if (reminderSkToDate !== 'Invalid date') {
        const isToday = moment()
        const futureDate = moment().add(2, 'days')
        setIsSameDatePersisted(reminderSkToDate.isSame(isToday, 'day'))
        setIsCustomDate(reminderSkToDate.isSameOrAfter(futureDate, 'day'))
      }
    }, [isSameDatePersisted, isCustomDate, incomingMessage])

    const handleClick = (duration) => {
      const difference = calculateDifferenceToMilliseconds(duration)
      setReminderTimeInMilliseconds(difference)
      const timeToAdd = calculateAddedTime(duration)
      const isToday = duration === 'in 24 hours' ? false : true
      dispatch(setNotifications({ id, text: duration, time: timeToAdd, isToday }))
      setIncomingMessagePk(incomingMessage.pk1)
      setIncomingMessageSk(incomingMessage.sk1)
    }

    useEffect(() => {
      if (incomingMessage.remindsk === null || incomingMessage.msgpk !== id) {
        return
      }
      const currentTime = Date.now()
      setReminderSet(currentTime < incomingMessage.remindsk)
      const timeRemaining = incomingMessage.remindsk - currentTime
      const removeReminder = setTimeout(() => {
        setReminderSet(false)
      }, timeRemaining)
      return () => clearTimeout(removeReminder)
    }, [incomingMessage.remindsk, incomingMessage.msgpk])

    return (
      <TransitionContainer
        show={expanded}
        className={`${className} flex flex-col p-2 mt-1`}
        width={200}
        positioning="absolute"
        ref={ref}
        {...rest}
      >
        {incomingMessage.remindsk !== null &&
        id === incomingMessage.msgpk &&
        reminderSet ? (
          <>
            <RemindMeButton onClick={() => handleRemoveNotification()}>
              Clear Reminder
            </RemindMeButton>
            <hr className="my-1 text-thryv-gray-light-400" />
            <RemindMeButton className="cursor-default hover:bg-white">
              <span className="flex gap-2 items-center">
                <CommandCenterIcon type="regular" variant="alarmClock" />
                {loading || reminderTime === 'Invalid date' ? (
                  <span className="loader"></span>
                ) : id === incomingMessage.msgpk &&
                  isSameDatePersisted &&
                  !isCustomDate ? (
                  `Today at ${reminderTime}`
                ) : id === incomingMessage.msgpk &&
                  !isSameDatePersisted &&
                  !isCustomDate ? (
                  `Tomorrow at ${reminderTime}`
                ) : id === incomingMessage.msgpk && isCustomDate ? (
                  `${customDate} at ${reminderTime}`
                ) : (
                  <></>
                )}
              </span>
            </RemindMeButton>
          </>
        ) : (
          <>
            <RemindMeButton
              onClick={() => {
                handleClick('in 30 minutes')
                createNotificationReminder()
                dispatch(setReminderText('in 30 minutes'))
              }}
            >
              In 30 minutes
            </RemindMeButton>
            <RemindMeButton
              onClick={() => {
                handleClick('in 1 hour')
                createNotificationReminder()
                dispatch(setReminderText('in 1 hour'))
              }}
            >
              In 1 hour
            </RemindMeButton>
            <RemindMeButton
              onClick={() => {
                handleClick('in 3 hours')
                createNotificationReminder()
                dispatch(setReminderText('in 3 hours'))
              }}
            >
              In 3 hours
            </RemindMeButton>
            <RemindMeButton
              onClick={() => {
                handleClick('in 24 hours')
                createNotificationReminder()
                dispatch(setReminderText('in 24 hours'))
              }}
            >
              In 24 hours
            </RemindMeButton>
            <hr className="my-1 text-thryv-gray-light-400" />
            <RemindMeButton
              onClick={() => {
                setOpenReminderModal(true)
                handleCustomReminderOnClick()
                setIncomingMessageId(id)
              }}
            >
              Custom Reminder
            </RemindMeButton>
          </>
        )}
      </TransitionContainer>
    )
  },
)

export default RemindMeMenu
