import { useState } from 'react'
import PlanCard from './PlanCard'
import { ModalPlansSection, PlansSection } from './PlanSelection.style'

function PlanSelection({ windowWidth, onSelectPlan, plansInfo }) {
  const [scrollBarPosition, setScrollBarPosition] = useState(0)

  const onSrollPlans = (e) => {
    let position = 0
    const left = e.currentTarget.scrollLeft
    const width = e.currentTarget.scrollWidth
    const percentaje = (left / width) * 100

    if (percentaje > 20 && percentaje < 50) {
      position = 1
    } else if (percentaje > 42) {
      position = 2
    }

    if (position !== scrollBarPosition) {
      setScrollBarPosition(position)
    }
  }

  const scrollToTier = (id) => {
    const element = document.getElementById(id)
    if (!element) return
    element.scrollIntoView(false, { behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <p className="text-base text-left text-[16px] font-[500] ml-[10px]">
        Choose the plan that best meets your needs and helps grow your business.
      </p>
      <ModalPlansSection onScroll={onSrollPlans}>
        <PlansSection id="plan-sections">
          {plansInfo &&
            plansInfo?.map((plan, index) => (
              <PlanCard
                key={index}
                {...plan}
                id={`tier-${plan.name}`}
                onClickGetItNow={() => onSelectPlan(plan.code)}
              />
            ))}
        </PlansSection>
      </ModalPlansSection>
      {windowWidth <= '500' ? (
        <div className="dotstyle dotstyle-fillup">
          <ul>
            <li>
              <a
                className={scrollBarPosition === 0 ? 'current' : ''}
                href="#"
                onClick={() => scrollToTier('tier-free')}
              >
                free
              </a>
            </li>
            <li>
              <a
                className={scrollBarPosition === 1 ? 'current' : ''}
                href="#"
                onClick={() => scrollToTier('tier-plus')}
              >
                plus
              </a>
            </li>
            <li>
              <a
                className={scrollBarPosition === 2 ? 'current' : ''}
                href="#"
                onClick={() => scrollToTier('tier-pro')}
              >
                pro
              </a>
            </li>
          </ul>
        </div>
      ) : null}
    </>
  )
}

export default PlanSelection
