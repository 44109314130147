import styled from 'styled-components'

const grayscaleBlack = '#231f20'
export const thryvOrange = '#FF5000'
export const openSans = "'Open Sans', 'sans-serif'"

export const Controls = styled.div`
  display: flex;
  gap: 20px;
`
export const CardFieldContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const CardField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border: 2px solid #f8f9fb;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400px;
  font-family: 'Open Sans', sans-serif;
  color: #231f20;
  padding: 20px;
`

export const PaymetnInfoContainer = styled.div`
  font-weight: 400;
  width: 655px;

 @media (max-width: 380px) {
  width: 300px;
 }
`

export const Titles = styled.p`
  padding-bottom: 5px;
`

export const Form = styled.form`
  @media (max-width: 640px) {
    margin-top: 0px;
  }
`
export const Headers = styled.div`
  font-family: ${openSans};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${grayscaleBlack};
  padding-top: 10px;
`

export const FormContainer = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
  @media (max-width: 640px) {
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
  }
`

export const ItemField = styled.div`
  width: 50%;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const SelectField = styled.div`
  position: relative;
  bottom: 3px;
  @media (max-width: 640px) {
    width: 100%;
  }
`
