import { Checkbox, Icon, Input, Tooltip } from '@thryvlabs/maverick'
import { useEffect } from 'react'
import '../styles/ein.css'

export const formNames = ['ein']

export const getFormNames = (businessType, noEin) => {
  if (businessType === 'SOLE_PROPRIETOR' && noEin) {
    return []
  }
  return formNames
}

const TaxIdNumber = ({
  register,
  errors,
  businessType,
  noEin,
  setValue,
  isMobile,
}) => {
  useEffect(() => {
    if (noEin) {
      setValue('ein', undefined)
    }
  }, [noEin])

  return (
    <>
      <div className="flex flex-col">
        {!noEin ? (
          <div className="flex mt-[32px]">
            <div className="flex w-full relative">
              {/* EIN must be a text type input so that users can include '-' in the input on mobile */}
              {/* EIN input format Vonage is expecting: ##-####### */}
              <div className="sm:grow">
                <Input
                  type="text"
                  withLabel
                  name="ein"
                  placeholder="EIN"
                  labelType="floating"
                  variant="default"
                  register={register}
                  className="w-[220px] sm:w-full"
                  errors={errors}
                />
              </div>
              <div className="mt-1">
                <Tooltip
                  variant={isMobile ? 'left' : 'top'}
                  positioning={isMobile ? { offsetX: '15px' } : { offsetY: '-12px' }}
                  description="If you do not provide an EIN and it is found in the IRS database, your submission may be rejected. Not providing an EIN will also need an additional phone verification step."
                >
                  <button type="button">
                    <Icon
                      type="regular"
                      variant="infoCircle"
                      height="18"
                      fill="#057AFF"
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        ) : null}
        {businessType === 'SOLE_PROPRIETOR' && (
          <div
            className={`mt-[32px] ${
              businessType !== 'SOLE_PROPRIETOR' ? 'has-ein' : ''
            }`}
          >
            <Checkbox
              hasLabel
              name="noEin"
              register={register}
              label="I don't have an EIN"
              disabled={businessType !== 'SOLE_PROPRIETOR'}
            />
            <p className="mt-3 text-thryv-steel text-xs italic text-left">
              If you have an EIN and do not disclose it in this step, the carriers
              will reject your registration.
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default TaxIdNumber
