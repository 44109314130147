const { VITE_VCITA_HOSTS } = import.meta.env
const DIRS = VITE_VCITA_HOSTS.split(',')

export const returnBcPath = (action, dirCode) => {
  let url = `${DIRS[dirCode - 1]}/app/dashboard`

  switch (action) {
    case 'New appointment':
      url = `${url}?action=appointment`
      break
    case 'Register to event':
      url = `${url}?action=event`
      break
    case 'New invoice':
      url = `${url}?action=invoice`
      break

    case 'Invite to client portal':
      url = `${url}?action=client`
      break
    case 'Invite to schedule':
      url = `${url}?action=schedule`
      break
  }

  return url
}
