import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { GET_PERSONAL_INFO } from '../../../../../graphql/queries/personal-info-queries'
import { setUserInfo } from '../../../../../redux-toolkit/slices/user-info/user-info.slice'

const VoicemailGreetings = ({ setIsOpen }) => {
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const { fname } = useSelector((state) => state.user)

  useQuery(GET_PERSONAL_INFO, {
    variables: {
      CC_UserID: user.cc_uid,
    },
    onCompleted: (data) => {
      const { FirstName, LastName, Email } = data.staff[0] //Country code comes in as Alpha2Code
      dispatch(setUserInfo({ fname: FirstName, lname: LastName, email: Email }))
    },
    onError: () => {},
  })

  return (
    <div className="max-w-[332px] w-[100%] h-[134px] bg-white text-[#4D4D4D] text-[14px] p-1 m-auto !mt-[4rem]">
      <p className="font-semibold pb-1">Hi {fname},</p>
      <p className="flex flex-row open-sans items-center pb-5">
        <span className="w-[298px] text-[14px]">
          All your voicemails will appear here. You can also customize the voicemail
          greeting for your business.
        </span>
      </p>
      <Button
        variant="secondary"
        className="flex px-2 py-3px disabled:bg-thryv-gray-light-500"
        onClick={() => setIsOpen(true)}
      >
        <span className="my-auto font-open-sans text-[#4D4D4D] font-semibold text-[13px] leading-[14px]">
          Record a new greeting
        </span>
      </Button>
    </div>
  )
}

export default VoicemailGreetings
