import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'

const TC_BACKEND_DEV_API_URL = 'https://teamchatbackend-staging.thryv.com/'
const TC_BACKEND_PROD_API_URL = 'https://teamchatproduction.thryv.com/'

const TEAMCHAT_API_URL =
  import.meta.env.VITE_ENV === 'prod'
    ? TC_BACKEND_PROD_API_URL
    : TC_BACKEND_DEV_API_URL

const SocketContext = createContext()
const OnlineStatusContext = createContext()

export const useSocket = () => useContext(SocketContext)

export const useOnlineStatus = () => useContext(OnlineStatusContext)

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null)
  const [isOnline, setIsOnline] = useState(true)
  const { user } = useAuth0()
  const thryv_staff_id = user?.staffUID

  const getInitialStatusFromTC = async () => {
    await axios
      .get(`${TEAMCHAT_API_URL}api/teamchat/getstatus/${thryv_staff_id}`)
      .then((res) => {
        const away_status_db = res?.data?.convertedBufferObject?.away_status
        if (
          away_status_db != 'undefined' &&
          away_status_db != '' &&
          away_status_db != null &&
          away_status_db != 0
        ) {
          setIsOnline(false)
        }
      })
      .catch(() => {})
  }

  useEffect(async () => {
    await getInitialStatusFromTC()

    const newSocket = io(`${TEAMCHAT_API_URL}`, {
      transports: ['websocket'],
    })
    setSocket(newSocket)

    return () => {
      newSocket.close()
    }
  }, [])

  return (
    <SocketContext.Provider value={socket}>
      <OnlineStatusContext.Provider value={isOnline}>
        {children}
      </OnlineStatusContext.Provider>
    </SocketContext.Provider>
  )
}
