import { ParagraphText } from '@thryvlabs/maverick'
import { forwardRef, useEffect, useState } from 'react'
import { useWavesurfer } from '../../../../../../hooks'
import { formatSecondsToMinutes } from './voicemail-section'
import WaveformWithLoadBar from './waveform-with-load-bar'

export const RenderWaveSurfer = forwardRef(
  (
    {
      audio,
      setIsPlaying,
      replayAudio,
      audioLoading,
      setLoading,
      waveWidth,
      className,
    },
    waveformRef,
  ) => {
    const [wavesurfer, wavesurferTime] = useWavesurfer(waveformRef, {
      audio: audio,
    })

    const [, setWaveformLoaded] = useState(false)

    useEffect(() => {
      const setIsPlayingFalse = () => setIsPlaying(false)

      if (wavesurfer) {
        wavesurfer.on('finish', setIsPlayingFalse)
      }

      return () => {
        wavesurfer && wavesurfer.un('finish', setIsPlayingFalse)
      }
    }, [wavesurfer])

    useEffect(() => {
      if (wavesurfer) {
        wavesurfer.playPause()
        wavesurfer.isPlaying() ? setIsPlaying(true) : setIsPlaying(false)
      }
    }, [replayAudio, audio])

    return (
      <div className={className}>
        <ParagraphText variant="reg" color="#1A2028" className="mr-2">
          {wavesurferTime && formatSecondsToMinutes(wavesurferTime.current)}
        </ParagraphText>
        <WaveformWithLoadBar
          className="mr-1"
          waveWidth={waveWidth}
          ref={waveformRef}
          wavesurfer={wavesurfer}
          loadingState={audioLoading}
          onWaveformLoad={() => {
            setLoading(false)
            setWaveformLoaded(true)
            wavesurfer.playPause()
            wavesurfer.isPlaying() ? setIsPlaying(true) : setIsPlaying(false)
          }}
        />
        <ParagraphText variant="reg" color="#1A2028" className="mr-2 ml-3">
          {wavesurferTime && '-' + formatSecondsToMinutes(wavesurferTime.remaining)}
        </ParagraphText>
      </div>
    )
  },
)
