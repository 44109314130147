import { Button, Header, ParagraphText } from '@thryvlabs/maverick'

const { VITE_WEBCHAT_REDIRECT_URL } = import.meta.env

const AlmostDone = ({ setModalType }) => {
  return (
    <div className="w-[500px] p-[0.75rem] text-left">
      <Header variant="h4" fontWeight="semibold">
        Almost Done
      </Header>
      <ParagraphText className="mb-[0.5rem]" variant="reg" color="#231F20">
        Once you confirm below, we’ll get started installing WebChat to your Thryv
        site. Every chat will feed right to your Command Center inbox. After you
        press confirm below, your WebChat will be live within 24 hours, nothing else
        you have to do.
      </ParagraphText>

      <div className="float-right flex justify-center items-center">
        <span
          className="text-[#808080] text-[13px]  mr-[0.5rem] cursor-pointer"
          onClick={() => setModalType('Web Chat')}
        >
          BACK
        </span>

        <Button
          onClick={() =>
            window.location.replace(`${VITE_WEBCHAT_REDIRECT_URL}=internal`)
          }
          variant="primary"
          className="h-[32px]  mr-[2%] "
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default AlmostDone
