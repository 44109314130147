import { useState, useRef } from 'react'

// Components
import { SearchBar, SortButton } from '../common'
import { FilterModal, SearchModal } from './modals'
import { SortMenu } from '../desktop-inbox-menu-search-bar/menus'
import RoundedActionButton from '../../../common/rounded-action-button'
import { useDispatch } from 'react-redux'
import {
  setBulkEditBtnClicked,
  setBulkSelectAllBtnChecked,
  setBulkSelectedThreadIds,
  setBulkSelectedThreadSk1s,
  setShowMarkAsMenu,
} from '../../../slices/inboxThreadsSlice'
import { useSelector } from 'react-redux'
import { Button, Checkbox, Header } from '@thryvlabs/maverick'
export const MobileInboxMenuSearchBar = ({ className }) => {
  const dispatch = useDispatch()
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showSortMenu, setShowSortMenu] = useState(false)
  const {
    bulkSelectedThreadIds,
    bulkEditBtnClicked,
    bulkSelectAllBtnChecked,
    inboxThreadIds,
    inboxPinnedThreadIds,
    inboxThreadSk1s,
    inboxPinnedThreadSk1s,
    bulkSelectedThreadCount,
  } = useSelector((state) => state.inboxThreads)
  const searchContainerRef = useRef(null)
  const sortButtonRef = useRef(null)

  const closeAllMenusAndModals = () => {
    setShowFilterModal(false)
    setShowSearchModal(false)
    setShowSortMenu(false)
  }

  const openFilterModal = () => {
    closeAllMenusAndModals()
    setShowFilterModal(true)
  }

  const openSearchModal = () => {
    closeAllMenusAndModals()
    setShowSearchModal(true)
  }

  const openSortMenu = () => {
    closeAllMenusAndModals()
    setShowSortMenu(true)
  }

  const handleCloseSortMenu = (e) => {
    if (e.composedPath().includes(sortButtonRef.current)) return
    setShowSortMenu(false)
  }

  const handleSelectAll = () => {
    if (bulkSelectedThreadCount > 0) {
      dispatch(setBulkSelectAllBtnChecked(!bulkSelectAllBtnChecked))
      dispatch(
        setBulkSelectedThreadIds([...inboxThreadIds, ...inboxPinnedThreadIds]),
      )
      dispatch(
        setBulkSelectedThreadSk1s([...inboxThreadSk1s, ...inboxPinnedThreadSk1s]),
      )
    } else {
      dispatch(setBulkSelectAllBtnChecked(!bulkSelectAllBtnChecked))

      dispatch(
        setBulkSelectedThreadIds([...inboxThreadIds, ...inboxPinnedThreadIds]),
      )
      dispatch(
        setBulkSelectedThreadSk1s([...inboxThreadSk1s, ...inboxPinnedThreadSk1s]),
      )
    }
  }

  const handleCancel = () => {
    dispatch(setBulkEditBtnClicked(false))
    dispatch(setBulkSelectAllBtnChecked(false))
    dispatch(setBulkSelectedThreadIds([]))
    dispatch(setBulkSelectedThreadSk1s([]))
    dispatch(setShowMarkAsMenu(false))
  }

  const BulkEditSelectedCount = () => {
    if (bulkEditBtnClicked) {
      return (
        <div className="flex gap-2.5 items-center mb-[2px]">
          <Header fontWeight="semibold" variant="h5">
            {bulkSelectedThreadIds.length} Selected
          </Header>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div
      className={`${className} ${bulkEditBtnClicked ? 'flex-col' : 'flex'}`}
      ref={searchContainerRef}
    >
      <FilterModal show={showFilterModal} close={() => setShowFilterModal(false)} />

      <SearchModal
        show={showSearchModal}
        close={() => setShowSearchModal(false)}
        onFilterButtonClick={openFilterModal}
      />
      <div className="relative grow">
        {bulkEditBtnClicked ? (
          BulkEditSelectedCount()
        ) : (
          <>
            {' '}
            <SearchBar
              className="!cursor-pointer"
              disableSearch
              filterButton={{
                onClick: openFilterModal,
              }}
              searchArea={{
                onClick: openSearchModal,
              }}
            />
            <SortMenu
              className="mt-px"
              show={showSortMenu}
              onSelectionClick={closeAllMenusAndModals}
              onOutsideClick={handleCloseSortMenu}
            />
          </>
        )}
      </div>

      <div
        className={`${
          bulkEditBtnClicked
            ? 'flex w-full justify-between mt-[12px] mb-[15px]'
            : 'ml-2 grow-0 flex justify-center items-center'
        }`}
      >
        {bulkEditBtnClicked ? (
          <>
            <div className="flex justify-center items-center gap-2">
              {' '}
              <Checkbox
                hasLabel
                name="Select All"
                isChecked={bulkSelectAllBtnChecked}
                onChange={handleSelectAll}
              />
              <span
                className="font-semibold font-open-sans text-utility/text-link-info text-[14px] cursor-pointer"
                onClick={handleSelectAll}
              >
                {bulkSelectAllBtnChecked ? 'Unselect All' : 'Select All'}
              </span>{' '}
            </div>

            <Button variant="text" level={1} onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <SortButton
              ref={sortButtonRef}
              onClick={openSortMenu}
              active={showSortMenu}
            />
            <RoundedActionButton
              dataTestID="bulk-edit-btn"
              icon={{
                variant: 'pen',
                type: 'regular',
                width: '18',
                height: '18',
                color: '#808080',
                hoverColor: '#4D4D4D',
              }}
              isMavIcon
              onClick={() => dispatch(setBulkEditBtnClicked(true))}
            />
          </>
        )}
      </div>
    </div>
  )
}
