const YouTubeEmbed = ({ videoId }) => (
  <div className="video-embed-container mb-5">
    <iframe
      className="w-full sm:w-full sm:h-full md:w-full md:h-full"
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube video player"
    ></iframe>
  </div>
)

export default YouTubeEmbed
