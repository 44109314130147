import { gql } from '@apollo/client'

export const UPDATE_PERSONAL_INFO = gql`
  mutation update_personal_info_mutation(
    $CC_UserID: ID!
    $LocationID: String!
    $FirstName: String
    $LastName: String
    $Phone: String
    $Role: String
  ) {
    updateStaff(
      input: {
        CC_UserID: $CC_UserID
        LocationID: $LocationID
        FirstName: $FirstName
        LastName: $LastName
        Phone: $Phone
        Role: $Role
      }
    ) {
      CC_UserID
      CreatedAt
      Email
      FirstName
      LastName
      Phone
      Role
      UpdatedAt
    }
  }
`
