import { Button, Icon, ModalWrapper, ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../command-center-icon'
import { ThryvCommandCenterIcon } from '../../../../../command-center-icon/icons'
import { OTPInput } from '../../../../otp-input/OTPInput'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setReferenceId, setSuccess } from '../../../slices/phoneRegistrationSlice'
import phoneFormatter from '../../../../../../utils/phoneFormatter'
import { CONFIRM_OTP_CALL, CREATE_OTP_CALL } from '../../../../../../graphql'
import { useMutation } from '@apollo/client'
import { Notification } from '../../../../notification'

export const OTP = ({ handleShowSuccessModal, setIsOTPScreen }) => {
  const [otp, setOtp] = useState('')
  const onChange = (value) => setOtp(value)
  const dispatch = useDispatch()
  const brandId = useSelector((state) => state.phoneRegistration.brand_id)
  const referenceId = useSelector((state) => state.phoneRegistration.reference_id)
  const success = useSelector((state) => state.phoneRegistration.success)
  const phone = useSelector((state) => state.phoneRegistration.phone)
  const valueLength = 6
  const [showCreateError, setShowCreateError] = useState(false)
  const [showConfirmError, setShowConfirmError] = useState(false)
  const [showBrandIDError, setShowBrandIDError] = useState(false)

  const [createOTP, { loading: createOTPLoading }] = useMutation(CREATE_OTP_CALL, {
    onCompleted: (data) => {
      dispatch(setReferenceId(data.createOTP.reference_id))
    },
    onError: () => {
      setShowCreateError(true)
    },
  })

  const [confirmOTP, { loading: confirmOTPLoading }] = useMutation(
    CONFIRM_OTP_CALL,
    {
      onCompleted: (data) => {
        dispatch(setSuccess(data.confirmOTP))
      },
      onError: () => {
        setShowConfirmError(true)
      },
    },
  )

  useEffect(() => {
    if (brandId) {
      createOTP({ variables: { brand_id: brandId } })
    } else {
      setShowBrandIDError(true)
    }
  }, [brandId])

  useEffect(() => {
    if (otp && otp.length === valueLength) {
      confirmOTP({
        variables: { otp: otp, brand_id: brandId, reference_id: referenceId },
      })
    }
  }, [otp, valueLength])

  useEffect(() => {
    if (success) {
      setIsOTPScreen(false)
      handleShowSuccessModal()
    }
  }, [success])

  const handleResendClick = () => {
    if (brandId) {
      createOTP({ variables: { brand_id: brandId } })
      setShowCreateError(false)
      setShowConfirmError(false)
    } else {
      setShowBrandIDError(true)
    }
  }

  return (
    <>
      <div className="fixed inset-0 bg-white">
        <div className="absolute top-[-50%] left-[-10vw] w-[120%] h-full bg-gradient-to-r from-thryv-cornflower to-thryv-sapphire-500 rotate-[-7deg]"></div>
      </div>
      <div className="fixed w-[656px] rounded translate-x-[-50%] translate-y-[-50%] top-2/4 left-2/4 bg-white shadow-md">
        <div className="flex justify-between items-center gap-8 w-full">
          <div className="flex gap-4 items-center mx-auto mt-10">
            <ThryvCommandCenterIcon />
          </div>
        </div>
        <div className="flex flex-col items-center w-[496px] h-[553px] border-[2px] rounded border-thryv-gray-light-400 mx-auto mt-[20px] mb-[75px] px-[20px]">
          {createOTPLoading && (
            <div className="pr-5">
              <span className="w-[38px] h-[38px] border-4 border-[#E3E6E8] border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/3 top-5" />
            </div>
          )}

          {!createOTPLoading && (
            <>
              <div className="flex w-[492px] min-h-[116px] bg-[#F8F9FB] rounded place-items-center justify-center">
                <CommandCenterIcon
                  type="solid"
                  variant="thryvCCLogoOrange"
                  width="52px"
                  height="52px"
                />
                <Icon
                  type="regular"
                  variant="exchange"
                  width="20px"
                  height="20px"
                  className="ml-[40px] mr-[40px]"
                />
                <Icon
                  type="solid"
                  variant="circlePhone"
                  fill="#3D5199"
                  width="52px"
                  height="52px"
                />
              </div>
              <p className="mt-10 mb-5 text-[22px] font-montserrat font-semibold leading-[30px]">
                Verifying your identity
              </p>
              <ParagraphText
                variant="reg"
                className="mt-2 mb-8 text-sm leading-[20px]  px-5"
              >
                We will not be sending marketing messages. A text message will be
                sent to this number only to verify your identity. Please enter
                6-digit code sent to <b>{phoneFormatter(phone)}</b> to continue.
              </ParagraphText>
              <OTPInput value={otp} valueLength={valueLength} onChange={onChange} />
              <ParagraphText
                variant="reg"
                className="mb-5 text-xs mt-8 leading-[20px]"
              >
                Didn't receive the code?{' '}
                <Button variant="text" level={1} onClick={handleResendClick}>
                  Click to resend
                </Button>
              </ParagraphText>
              {showCreateError && (
                <Notification
                  className="mt-5 text-left"
                  iconType={'solid'}
                  iconVariant="circleExclamation"
                  variant={'error'}
                  textToRender={
                    'There was an error creating your one time passcode.'
                  }
                />
              )}
              {showConfirmError && (
                <Notification
                  className="mt-2 text-left"
                  iconType={'solid'}
                  iconVariant="circleExclamation"
                  variant={'error'}
                  textToRender={
                    'There was an error confirming your one time passcode.'
                  }
                />
              )}
              {showBrandIDError && (
                <Notification
                  className="mt-2 text-left"
                  iconType={'solid'}
                  iconVariant="circleExclamation"
                  variant={'error'}
                  textToRender={
                    'There was an issue creating your brand ID. Please refresh and resubmit the form.'
                  }
                />
              )}
              {createOTPLoading && (
                <div className="pr-5">
                  <span className="w-[38px] h-[38px] border-4 border-[#E3E6E8] border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/3 top-5" />
                </div>
              )}

              {confirmOTPLoading && (
                <div className="pr-5">
                  <span className="w-[38px] h-[38px] border-4 border-[#E3E6E8] border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/3 top-5" />
                </div>
              )}
              <p className="font-montserrat font-semibold">
                {success && 'Verification successful'}
                {success === false && 'Verification failed, please submit again'}
                {success === null && null}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

const OTPModal = ({ showModal, handleCloseModal }) => {
  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseModal}
      className="transform-none"
    >
      <OTP />
    </ModalWrapper>
  )
}

export default OTPModal
