import { Select } from '@thryvlabs/maverick'

export const SelectField = ({options, selectedOption, setSelectedOption, className}) => {
  return (
    <div>
        <Select 
            className={className}
            options={options} 
            selectLabel="Type" 
            width="full" 
            selectedOption={selectedOption} 
            setSelectedOption={setSelectedOption} 
            withLabel />
    </div>
  )
}
