import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRedux } from '@hooks'
import { fetchVonageSessionData } from '@redux-toolkit/slices'
import { useSubscription } from '@apollo/client'
import { SUBSCRIPTION_FOR_MEETING_END } from '../../graphql/mutations/vonage-mutations'
import { Buffer } from 'buffer'
window.Buffer = Buffer

const MeetingEnd = () => {
  const navigate = useNavigate()

  const [{ room }] = useRedux('meetings')
  const { room_data } = room

  const onMeetingEnd = useSubscription(SUBSCRIPTION_FOR_MEETING_END, {
    variables: {
      RoomID: room_data?.RoomID,
    },
    onData: () => {
      navigate('/calls')
    },
    onError: () => {},
  })
  const onMeetingEndClick = () => {
    onMeetingEnd
  }
  if (room_data) {
    onMeetingEndClick()
  }

  return <></>
}

const Meetings = () => {
  const navigate = useNavigate()
  const [{ room, meetingEnded }, dispatch] = useRedux('meetings')
  const { room_data, loading } = room

  useEffect(() => {
    if (!room_data && !loading) navigate('/calls')
  }, [room_data, loading])

  useEffect(() => {
    let intervalId
    if (!meetingEnded) {
      intervalId = setInterval(() => {
        dispatch(fetchVonageSessionData({ roomId: room_data.RoomID }))
      }, 10000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [meetingEnded])

  // useEffect(() => {
  //   if (
  //     session?.session_data &&
  //     session?.session_data['Event'] === 'session:ended' &&
  //     session?.session_data?.EndTime?.length
  //   ) {
  //     dispatch(clearVonageSession())
  //   }
  // }, [session])

  return (
    <>
      <MeetingEnd />
      <iframe
        src={room_data?.host_url}
        allow="camera;microphone"
        title="Embedded Meeting"
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </>
  )
}

export default Meetings
