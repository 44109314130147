import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthLoader } from './auth-loader'
import { redirectToBC } from '../../utils/redirect-to-bc'

export const BusinessCenter = () => {
  const { user } = useAuth0()

  useEffect(() => {
    redirectToBC(user?.dirCode, user?.staffUID, user?.ssoToken)
  }, [user])

  return <AuthLoader />
}
