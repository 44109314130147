import { useSelector } from 'react-redux'

export const useChannelsWithReauthStatus = () => {
  const { channelInfo } = useSelector((state) => state.connectChannels)

  const reauthChannels = channelInfo?.filter(
    (channel) => channel.Status == '98' && channel.VendorID == '1',
  )

  return reauthChannels
}
