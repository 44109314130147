import { forwardRef } from 'react'
import { Icon } from '@thryvlabs/maverick'

export const SortButton = forwardRef(
  ({ className, onClick, active }, sortButtonRef) => {
    return (
      <button
        ref={sortButtonRef}
        type="button"
        className={`p-1.5 h-[30px] w-[30px] rounded-full 
      ${active && 'bg-[#F8F9FB]'} 
      ${className}
      `}
        data-testid="inbox-sort"
        onClick={onClick}
      >
        <Icon
          type="regular"
          variant="sortDown"
          color="#808080"
          width="20"
          height="16"
        />
      </button>
    )
  },
)
