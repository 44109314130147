import { MenuChevron } from '@thryvlabs/maverick'

export const StaffTableHead = ({
  children,
  className,
  width,
  onClick,
  removeChevron,
}) => {
  return (
    <button
      type="button"
      className={`${className} flex justify-start items-center pl-[24px] py-[26px]`}
      style={{
        width: width, // Recommended to be a percentage value. Ex: 35%
      }}
      onClick={onClick}
    >
      <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
        {children}
      </h6>
      <MenuChevron
        className={`transition-all duration-300 
        ${removeChevron ? 'opacity-0' : 'opacity-100'}`}
        style={{
          marginLeft: '16px',
        }}
      />
    </button>
  )
}
