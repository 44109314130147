import { gql } from '@apollo/client'

export const UPDATE_LOCATION_NAME_CALL = gql`
  mutation UpdateLocationName($location_id: String!, $business_name: String!) {
    updateLocationName(location_id: $location_id, business_name: $business_name) {
      LocationID
      CCID
      Name
      Email
      Phone
      Address
      City
      Zip
      State
      CountryCode
      Timezone
      AddressHidden
      Website
      CreditCardOnFile
      ACHOnFile
      Status
      AddressType
      ExtDirectoryCode
      VToken
      CreatedAt
      UpdatedAt
      BusinessCenterLegacy
      BusinessCenter
      MarketingCenter
      Signatures
      WebsiteBuilder
    }
  }
`
