import { useSubscription } from '@apollo/client'
import { Header } from '@thryvlabs/maverick'
import { useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import { SUBSCRIPTION_FOR_MESSAGE_CREATED } from '../../../../graphql'
import { useSharedPhotos } from './useSharedPhotos'

import { Buffer } from 'buffer'
import SharedPhotoButtons from './SharedPhotoButtons'
import useHandleImageClick from './useHandleImageClick'
import { useLoadImageState } from './useSharedMediaProps'
window.Buffer = Buffer

const SharedPhotosPreview = ({ setShowAllPhotos, attachmentData }) => {
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  // Re-rendering the component when this pings true //
  const { data: subscriptionAlert } = useSubscription(
    SUBSCRIPTION_FOR_MESSAGE_CREATED,
  )
  const { imagePreviewPhotos, imageAttachmentData, refetchAttachments, loading } =
    useSharedPhotos(selectedThreadId)
  const recentImages = Array.from(imagePreviewPhotos, ([, value]) => value).slice(
    0,
    4,
  )
  const recentAttachments = imageAttachmentData.slice(0, 4)
  const { useImageLoader, imageLoaded, imageProcessing, setImageProcessing } =
    useLoadImageState({ images: recentImages })

  const handleSeeAllClick = () => setShowAllPhotos(true)

  useImageLoader()

  const { handleClickImage, handlePhotoAttachmentDownload } = useHandleImageClick({
    imageProcessing,
    setImageProcessing,
  })

  useEffect(() => {
    if (subscriptionAlert) {
      refetchAttachments, loading()
    }
  }, [subscriptionAlert, refetchAttachments, loading])

  // Render only if there are recent attachments
  return (
    <>
      {attachmentData.length > 0 && (
        <div className="grid grid-cols-2 gap-4 w-[310px]">
          {/* Header */}
          <Header fontWeight="semibold" variant="h7">
            Shared Photos
          </Header>
          <button
            className="ml-[85px] self-start"
            onClick={() => setShowAllPhotos(true)}
          >
            <p className="text-[#057AFF] text-sm" fontWeight="semibold">
              See All
            </p>
          </button>
        </div>
      )}
      {recentAttachments.length > 0 && (
        <>
          {/* Image Grid */}
          <div className="grid grid-cols-2 gap-4 mb-[10px] mt-[10px] px-[-10px] ml-[5px]">
            {recentAttachments.map((attachment, index) => {
              const url = imagePreviewPhotos.get(attachment.body)
              return (
                <div
                  key={`${attachment.pk1}-${index}`}
                  className="group relative flex justify-center items-center bg-thryv-gray-light-200 border border-thryv-gray-light-300 rounded-lg"
                  style={{ width: '136px', height: '136px', padding: '5px' }}
                >
                  <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 transition-opacity duration-200 z-10"></div>

                  {index === 3 ? (
                    <div
                      className="absolute inset-0 flex justify-center items-center p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-30"
                      onClick={handleSeeAllClick}
                    >
                      <span className="text-white">
                        {/* Show how many additional photos there are */}+
                        {imageAttachmentData.length - 4}
                      </span>
                    </div>
                  ) : (
                    <div className="absolute inset-0 flex justify-center items-center p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-30">
                      {imageProcessing[index] ? (
                        <Oval
                          height={25}
                          width={25}
                          color="#FF5000"
                          visible={true}
                          ariaLabel="oval-loading"
                        />
                      ) : (
                        <SharedPhotoButtons
                          handlePhotoAttachmentDownload={
                            handlePhotoAttachmentDownload
                          }
                          handleClickImage={handleClickImage}
                          imageProcessing={imageProcessing}
                          setImageProcessing={setImageProcessing}
                          attachment={attachment}
                          index={index}
                        />
                      )}
                    </div>
                  )}

                  <div className="relative max-w-full max-h-full overflow-hidden z-0">
                    {imageLoaded[index] ? (
                      <img
                        src={url}
                        alt={`Image ${index + 1}`}
                        className="object-contain w-full h-full"
                        onClick={() => handleClickImage(attachment, index)}
                      />
                    ) : (
                      <Oval
                        height={25}
                        width={25}
                        color="#FF5000"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#E9E9E9"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      {attachmentData.length > 0 && recentAttachments.length === 0 && (
        <p className="self-start font-open-sans font-normal text-sm text-[#808080] my-5">
          Your shared photos will appear here.
        </p>
      )}
    </>
  )
}

export default SharedPhotosPreview
