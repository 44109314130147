import {
  CallsOutgoingCallIcon,
  CallsIncomingCallIcon,
  MissedCallIcon,
  VoicemailIcon,
} from '../../../command-center-icon/icons/icons'
const CallTypeIcon = ({ type, hasVoicemail }) => {
  if (hasVoicemail) {
    return <VoicemailIcon fill={'#DF2A2A'} />
  } else if (type === 'incoming') {
    return <CallsIncomingCallIcon />
  } else if (type === 'outgoing') {
    return <CallsOutgoingCallIcon />
  } else if (type === 'missed') {
    return <MissedCallIcon fill={'#DF2A2A'} width={'22px'} height={'22px'} />
  }
  return <></>
}

export default CallTypeIcon
