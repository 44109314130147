export const updateAttachmentWithThumbnail = async (messages, thumbnailUrls) => {
  const matchedData = []
  messages.forEach((message) => {
    const messageID = message.sk1
    const attachmentsArray = message.attachments.items
    for (const file of attachmentsArray) {
      const thumbnailUrl = thumbnailUrls.find((url) => url.includes(file.sk1)) //matches thumbnail per file
      if (thumbnailUrl) {
        updateAttachment(file, messageID, thumbnailUrl, matchedData)
      } else {
        const initialThumbnail = 'loading'
        updateAttachment(file, messageID, initialThumbnail, matchedData)
      }
    }
  })
  return matchedData
}

const updateAttachment = (file, messageID, thumbnailUrl, matchedData) => {
  const newObj = { ...file, messageID, thumbnailUrl }
  const index = matchedData?.findIndex(
    (item) => item.sk1 === file.sk1 && item.messageID === messageID,
  )
  if (index === -1) {
    matchedData.push(newObj)
  } else {
    matchedData[index] = newObj
  }
}
