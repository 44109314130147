import {
  Button,
  Header,
  Icon,
  ModalWrapper,
  ParagraphText,
} from '@thryvlabs/maverick'
import { useState, useMemo, useEffect } from 'react'
import SmsStepsHeader from './SmsStepsHeader'
import RegisterInitial from './steps/RegisterInitial'
import { PenPaperIcon } from '../../../../command-center-icon/icons'
import { Notification } from '../../../notification'
import { Success } from './steps/SuccessModal'
import { CertPending } from './steps/CertificationPendingModal'
import { OTP } from './steps/OTPModal'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

const Screens = Object.freeze({
  REGISTER: 'REGISTER',
  PENDING: 'PENDING',
  OTP: 'OTP',
  SUCCESS: 'SUCCESS',
})

const RegisterPhone = ({
  handleCloseModal,
  handleShowPendingModal,
  handleShowSuccessModal,
}) => {
  const [step, setStep] = useState(0)
  const [showInfoNotif, setShowInfoNotif] = useState(true)
  const [showErrorNotif, setShowErrorNotif] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState()
  const [showSubmitErrorNotif, setShowSubmitErrorNotif] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const isXSmallMobile = useMediaQuery({ maxWidth: 375 })
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandError = useSelector(
    (state) => state.phoneRegistration.brand?.brand_errors?.[0],
  )
  const errorFields = brandError?.fields
  const errorDescription = brandError?.description
  // const campaignError = useSelector(
  //   (state) => state.phoneRegistration.brand?.campaign_error_message,
  // )

  const getFieldName = (field) => {
    switch (field) {
      case 'entityType':
        return 'Business Type'
      case 'companyName':
        return 'Legal Business Name'
      case 'ein':
        return 'EIN'
    }
    return field
  }

  const errorFieldDisplay = (errorFields) => {
    if (errorFields.length > 1) {
      let responseString = ''
      let fieldName = ''
      errorFields.forEach((field, index) => {
        fieldName = getFieldName(field)
        index !== errorFields.length - 1
          ? (responseString += fieldName + ', ')
          : (responseString += fieldName)
      })
      return responseString
    }

    return errorFields
  }

  const rejectedRegistrationMessage = () => {
    if (brandError) {
      return (
        <span>
          The mobile carriers have declined your SMS registration. Incorrect
          information may have been the cause. Please re-enter accurate information
          and resubmit.{' '}
          <div>
            <b>Issue:</b> {errorDescription}
          </div>{' '}
          <div>
            <b>Fields:</b> {errorFieldDisplay(errorFields)}
          </div>
        </span>
      )
    }

    // if (campaignError) {
    //   return (
    //     <span>
    //       The mobile carriers have declined your SMS registration. Incorrect
    //       information may have been the cause. Please re-enter accurate information
    //       and resubmit.{' '}
    //       <div>
    //         <b>Issue:</b> {campaignError}
    //       </div>
    //     </span>
    //   )
    // }

    return 'The mobile carriers have declined your SMS registration. Incorrect information may have been the cause. Please re-enter accurate information and resubmit.'
  }

  const handleCloseInfoNotif = () => {
    setShowInfoNotif(false)
  }

  const handleCloseRegisterPhoneModal = () => {
    setStep(0)
    handleCloseModal()
  }

  const handleCloseSubmitErrorNotif = () => {
    setShowSubmitErrorNotif(false)
  }

  useEffect(() => {
    if (
      brandStatus === 'UNVERIFIED' ||
      campaignStatus === 'UPDATES_REQUIRED' ||
      campaignStatus === 'TERMINATED'
    ) {
      setShowErrorNotif(true)
    }
  }, [brandStatus, campaignStatus])

  return (
    <div className="w-[656px] sm:w-full px-4 sm:px-1 xx-sm:px-0">
      <div className="flex justify-between items-center gap-8 w-full">
        <div className="flex gap-4 items-center">
          <Header
            fontWeight="semibold"
            variant={isXSmallMobile ? 'h6' : isMobile ? 'h4' : 'h2'}
          >
            Register Your Phone Number
          </Header>
        </div>
        <Button className="" variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={handleCloseModal}
          />
        </Button>
      </div>
      {step !== 0 && showErrorNotif && (
        <Notification
          className="mt-2 text-left"
          iconType={'solid'}
          iconVariant="circleExclamation"
          variant={'error'}
          textToRender={rejectedRegistrationMessage()}
        />
      )}
      {(step === 2 || step === 6) && showInfoNotif && (
        <Notification
          className="mt-2"
          iconType={'solid'}
          variant={'reminder'}
          textToRender={
            'We will not share this address with anyone, it is only used for verification purposes.'
          }
          close={handleCloseInfoNotif}
        />
      )}
      {step === 6 && showSubmitErrorNotif && (
        <Notification
          className="mt-2 text-left"
          close={handleCloseSubmitErrorNotif}
          iconType={'solid'}
          iconVariant="circleExclamation"
          variant={'error'}
          textToRender={submitErrorMessage}
        />
      )}
      <ParagraphText
        variant={isMobile ? 'sm' : 'lg'}
        className="my-5 text-left font-semibold leading-[22px]"
      >
        To ensure accuracy, please use the same information you use when filing your
        taxes.
      </ParagraphText>
      <div className="flex flex-col items-center sm:w-full sm:h-full w-[496px] h-[582px] border-[2px] rounded border-thryv-gray-light-400 mx-auto mt-[20px] mb-[50px] px-[20px]">
        <div className="flex sm:w-full w-[492px] mt-12 place-items-center justify-center">
          <div className="absolute z-0 h-[100px] w-[100px] bg-[#EBEDF0] rounded-full" />
          <PenPaperIcon className="z-10 ml-3" />
        </div>
        {step === 0 ? (
          <RegisterInitial setStep={setStep} isMobile={isMobile} />
        ) : (
          <SmsStepsHeader
            step={step}
            setStep={setStep}
            handleCloseModal={handleCloseRegisterPhoneModal}
            handleShowPendingModal={handleShowPendingModal}
            handleShowSuccessModal={handleShowSuccessModal}
            setSubmitErrorMessage={setSubmitErrorMessage}
            setShowSubmitErrorNotif={setShowSubmitErrorNotif}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  )
}

export const RegisterPhoneWrapper = ({
  handleCloseModal,
  setIsOTPScreen,
  isOTPFlow,
}) => {
  const [currentScreen, setCurrentScreen] = useState(
    isOTPFlow ? Screens.OTP : Screens.REGISTER,
  )

  const handleShowPendingModal = () => {
    setCurrentScreen(Screens.PENDING)
  }

  const handleContinuePendingModal = () => {
    setIsOTPScreen(true)
    setCurrentScreen(Screens.OTP)
  }

  const handleShowSuccessModal = () => {
    setCurrentScreen(Screens.SUCCESS)
  }

  const screen = useMemo(
    () => ({
      [Screens.REGISTER]: (
        <RegisterPhone
          handleCloseModal={handleCloseModal}
          handleShowPendingModal={handleShowPendingModal}
          handleShowSuccessModal={handleShowSuccessModal}
        />
      ),
      [Screens.PENDING]: (
        <CertPending
          handleCloseModal={handleCloseModal}
          handleContinuePendingModal={handleContinuePendingModal}
        />
      ),
      [Screens.OTP]: (
        <OTP
          handleShowSuccessModal={handleShowSuccessModal}
          setIsOTPScreen={setIsOTPScreen}
        />
      ),
      [Screens.SUCCESS]: <Success handleCloseModal={handleCloseModal} />,
    }),
    [
      handleCloseModal,
      handleShowPendingModal,
      handleShowSuccessModal,
      handleContinuePendingModal,
    ],
  )

  return screen[currentScreen]
}

export const RegisterPhoneModal = ({ showModal, setShowModal, isOTPFlow }) => {
  const handleCloseModal = () => setShowModal(false)
  const [isOTPScreen, setIsOTPScreen] = useState(false)

  useEffect(() => {
    if (isOTPFlow) setIsOTPScreen(true)
  }, [isOTPFlow])

  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseModal}
      disableBackdropClick={false}
      {...(isOTPScreen && { className: 'transform-none' })}
    >
      <RegisterPhoneWrapper
        handleCloseModal={handleCloseModal}
        setIsOTPScreen={setIsOTPScreen}
        isOTPFlow={isOTPFlow}
      />
    </ModalWrapper>
  )
}

export const useRegisterPhoneModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [isOTPFlow, setIsOTPFlow] = useState(false)
  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)
  const openOTPFlow = () => {
    setShowModal(true)
    setIsOTPFlow(true)
  }

  const Modal = useMemo(
    () => (
      <RegisterPhoneModal
        showModal={showModal}
        setShowModal={setShowModal}
        isOTPFlow={isOTPFlow}
      />
    ),
    [showModal, setShowModal],
  )

  return [openModal, closeModal, Modal, openOTPFlow]
}
