export const DATE_THREADS_FILTERS = {
  All: 'all',

  Inbox: 'inbox',

  Unread: 'unread',

  Completed: 'completed',

  Spam: 'spam',

  Trash: 'trash',
}
