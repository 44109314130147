import axios from 'axios'

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID } = import.meta.env

export const sendResetPasswordEmail = async (email) => {
  try {
    await axios.post(`https://${VITE_AUTH0_DOMAIN}/dbconnections/change_password`, {
      client_id: VITE_AUTH0_CLIENT_ID,
      email: email,
      connection: 'Username-Password-Authentication',
    })
  } catch (err) {
    //
  }
}
