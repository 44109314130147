import { useState, useEffect } from 'react'
import thryvcclogo from '../../assets/thryvcclogomedium.png'
import axios from 'axios'
import { Input, MaskedInput, Checkbox, Button, Icon } from '@thryvlabs/maverick'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import useDetectCountryCode from '../../hooks/use-detect-country-code'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
const { VITE_SIGN_UP_SERVICE_URL } = import.meta.env
import { LoadingSpinner } from '../common/loading-spinner'

const CreateProfile = ({ setUserData, setStep, setUserEmail }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [countryAbbreviation, setCountryAbbreviation] = useState('US')
  const [searchParams] = useSearchParams()
  const country = useSelector((state) => state.countryCode.country)
  const countryCodeState = useSelector((state) => state.countryCode.countryCode)
  const detectedCountry = useDetectCountryCode()
  const handleOnChange = () => setIsChecked(!isChecked)
  const [landlineError, setLandlineError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [maskPattern, setMaskPattern] = useState('')

  useEffect(() => {
    let maskPattern = '(###) ###-####'
    if (country === 'United States' || country === 'US') {
      setCountryAbbreviation('US')
    }
    if (country === 'Australia' || country === 'AU') {
      setCountryAbbreviation('AU')
      maskPattern = '#### ### ###'
    }
    if (country === 'Canada' || country === 'CA') {
      setCountryAbbreviation('CA')
    }
    if (country === 'New Zealand' || country === 'NZ') {
      setCountryAbbreviation('NZ')
    }
    setMaskPattern(maskPattern)
  }, [country])

  const formatNumber = (phoneNumber) => {
    if (country === 'NZ') {
      phoneNumber = phoneNumber.replace(/\D/g, '')

      if (phoneNumber.length === 10) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      } else if (phoneNumber.length === 9) {
        return phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '($1) $2-$3')
      } else {
        return phoneNumber
      }
    } else {
      return phoneNumber
    }
  }

  // React Hook Form imports & default values
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: searchParams.get('first_name') || '',
      lastName: searchParams.get('last_name') || '',
      email: searchParams.get('email') || '',
      callingCode: countryCodeState,
      phoneNumber: searchParams.get('phone') || '',
    },
  })

  const verifyInvite = async () => {
    if (searchParams.get('invite_id')) {
      const inviteRespose = await axios.post(
        `${VITE_SIGN_UP_SERVICE_URL}/signup/verify-invite`,
        {
          invite_id: searchParams.get('invite_id'),
        },
      )
      setValue('firstName', inviteRespose.firstName)
      setValue('lastName', inviteRespose.lastName)
      setValue('email', inviteRespose.email)
      setValue('phoneNumber', inviteRespose.formatNumber(phoneNumber))
    }
  }

  useEffect(() => {
    verifyInvite()
  }, [])

  // Watchers from React Hook Form assigned to input value
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const email = watch('email')
  const phoneNumber = watch('phoneNumber')
  const timezone = moment.tz.guess(true)

  const requestNumber = `${countryCodeState}${phoneNumber}`

  const unsuccessfulPayload = {
    email,
    first_name: firstName,
    last_name: lastName,
    country: countryAbbreviation,
    time_zone: timezone,
    phone: formatNumber(phoneNumber.replace(/[^A-Za-z0-9]/g), ''),
    source: 'cc_signup',
  }

  const unsuccessfulSignUp = async () => {
    if (searchParams.get('invite_id')) {
      unsuccessfulPayload.invite_id = searchParams.get('invite_id')
    }

    await axios.post(
      `${VITE_SIGN_UP_SERVICE_URL}/signup/unsuccessful`,
      unsuccessfulPayload,
    )
  }

  const validateCountry = (data) => {
    const validCountries = [
      'US',
      'CA',
      'NZ',
      'AU',
      'United States',
      'Canada',
      'New Zealand',
      'Australia',
    ]
    if (validCountries.includes(country)) {
      setIsOpen(false)
      axios
        .post(`${VITE_SIGN_UP_SERVICE_URL}/signup/get-code`, {
          phone: requestNumber,
        })
        .then(() => {
          setUserData({
            countryCode: countryCodeState,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: formatNumber(data.phoneNumber.replace(/[^A-Za-z0-9]/g), ''),
            country: countryAbbreviation,
          })
          setStep('2')
          unsuccessfulSignUp()
          if (window.heap && !Array.isArray(window.heap)) {
            window.heap.addUserProperties({
              email: data.email,
              first_name: data.firstName,
              last_name: data.lastName,
              phone_number: formatNumber(
                data.phoneNumber.replace(/[^A-Za-z0-9]/g),
                '',
              ),
            })
          }
        })
        .catch(() => {
          setLoading(false)
          setLandlineError(true)
        })
    } else {
      setIsOpen(true)
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col items-center md:w-[329px] overflow-hidden">
      <div className="h-[80px] md:h-0 md:mb-[50px] gap-[20px] flex flex-col items-center">
        <img
          src={thryvcclogo}
          alt="Thryv Command Center"
          className="w-[198px] h-[40px] md:hidden"
        />
        <p className='font-["Open_Sans"] font-normal text-[14px] leading-[14px] md:hidden'>
          Welcome! Let's start by setting up your profile.
        </p>
      </div>
      <div className="hidden md:flex flex-col gap-[8px] text-center mb-[40px]">
        <h1 className="font-primary text-[18px] leading-[24px] font-semibold text-[#4D4D4D]">
          Let's get started!
        </h1>
        <p className='font-["Open_Sans"] font-normal text-[14px] leading-[20px] text-[#4D4D4D]'>
          Let's start by setting up your profile.
        </p>
      </div>

      {isOpen ? (
        <div className="w-[420px] relative flex justify-center rounded-[4px] py-[8px] px-[12px] bg-[#FCEAEA] mt-[16px] border-[1px] border-[#EF9595] md:w-[309px] md:mt-[-30px] md:mb-[20px] ">
          <Icon
            type="regular"
            variant="x"
            color="#808080"
            height="12"
            width="12"
            className="absolute right-[12px] top-[16px] cursor-pointer"
            onClick={() => setIsOpen(false)}
          />
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="w-[16px] h-[16px] text-[#DF2A2A] absolute left-[8px] top-[14px]"
          />
          <div className="w-[352px] md:w-[242px]">
            <span className='text-[14px] font-normal leading-[20px] font-["Open_Sans"] text-[#231F20]'>
              Command Center is not yet available in your country.
            </span>
          </div>
        </div>
      ) : null}

      <form
        className="md:max-h-[420px]"
        id="submit_user_info"
        onSubmit={handleSubmit((data) => {
          const updatedData = {
            ...data,
            email: data.email.trim(),
          }

          setLoading(true)
          validateCountry(updatedData)
          setUserEmail(data.email.trim())
        })}
      >
        <div className="w-[420px] md:w-[281px] md:my-0 md:mx-auto h-[206px] max-h-[206px] flex flex-col gap-[10px] mt-[32px]">
          <div className="w-[420px] max-w-[420px] md:w-[281px]  h-[62px] flex gap-[16px]">
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z' ]*$/,
                  message: 'Special characters not allowed',
                },
              }}
              name="firstName"
              render={({ field }) => (
                <Input
                  tabindex="0"
                  id="firstName"
                  disabled={searchParams.get('invite_id')}
                  className="w-[202px] md:w-[132.5px]"
                  onChange={(e) => setValue('firstName', e.target.value)}
                  type="text"
                  placeholder="First Name"
                  withLabel
                  labelType="floating"
                  variant="default"
                  {...field}
                />
              )}
            />
            <div className=" w-[294px] md:w-[150px] md:wrap flex justify-start md:h-[25px] absolute bottom-[54.5%] md:right-[47%] md:bottom-[55%]">
              <p className="text-notification-red text-xs ">
                {errors.firstName?.message}
              </p>
            </div>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Za-z]+[A-Za-z' ]*$/,
                  message: 'Special characters not allowed',
                },
              }}
              name="lastName"
              render={({ field }) => (
                <Input
                  tabindex="0"
                  id="lastName"
                  disabled={searchParams.get('invite_id')}
                  className="w-[202px]  md:w-[132.5px]"
                  onChange={(e) => setValue('lastName', e.target.value)}
                  type="text"
                  placeholder="Last Name"
                  withLabel
                  labelType="floating"
                  variant="default"
                  {...field}
                />
              )}
            />
          </div>
          <div className=" w-[294px] md:w-[150px] md:wrap flex justify-start md:h-[25px] absolute bottom-[54.5%] right-[49.5%] md:right-[1%] md:bottom-[55%]">
            <p className="text-notification-red text-xs ">
              {errors.lastName?.message}
            </p>
          </div>
          <div className="md:w-[281px] md:ml-0">
            <Controller
              control={control}
              rules={{
                required: 'Please enter a valid email',
                pattern: {
                  value:
                    /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                  message: 'Please enter a valid email',
                },
              }}
              name="email"
              render={({ field }) => (
                <Input
                  tabindex="0"
                  id="email"
                  disabled={searchParams.get('invite_id')}
                  className="w-full"
                  onChange={(e) => setValue('email', e.target.value)}
                  type="text"
                  placeholder="Email"
                  withLabel
                  labelType="floating"
                  variant="default"
                  {...field}
                />
              )}
            />
          </div>
          <div className=" w-[294px] flex justify-start md:h-[25px] absolute bottom-[44.5%] md:bottom-[42%]">
            <p className="text-notification-red text-xs ">{errors.email?.message}</p>
          </div>
          <div className="w-[420px] max-w-[420px] h-[62px] flex gap-[16px] items-end md:w-[281px] md:ml-0">
            <div>
              <span className="text-[12px] leading-[15px] text-thryv-steel font-['Open_Sans']">
                Country Code
              </span>
              {detectedCountry?.length > 0 ? (
                <Input
                  value={detectedCountry}
                  tabindex="-1"
                  disabled
                  className="w-full"
                  type="tel"
                  placeholder="Country Code"
                  withLabel
                  labelType="floating"
                  variant="default"
                />
              ) : (
                <div className="h-[26px] w-[124px]">
                  <LoadingSpinner widthAndHeight={24} />
                </div>
              )}
            </div>
            {country === 'NZ' ? (
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^\d{8,10}$|^\(?\d{1,3}\)?[-\s]\d{3,4}[-\s]\d{4}$/,
                    message: 'Please enter a valid phone number',
                  },
                  minLength: {
                    value: 8,
                    message: 'Must be between 8 and 10 digits',
                  },
                  maxLength: {
                    value: 14,
                    message: 'Must be between 8 and 10 digits',
                  },
                }}
                name="phoneNumber"
                render={({ field }) => (
                  <Input
                    id="phoneNumber"
                    tabindex="0"
                    disabled={searchParams.get('invite_id')}
                    className="w-[280px] md:w-[145px]"
                    onInput={() => setLandlineError(false)}
                    onChange={(e) => {
                      setValue('phoneNumber', formatNumber(e.target.value))
                    }}
                    type="tel"
                    placeholder="Mobile Number"
                    withLabel
                    labelType="floating"
                    variant="default"
                    {...field}
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^\d{8,10}$|^\(?\d{1,3}\)?[-\s]\d{3,4}[-\s]\d{4}$/,
                    message: 'Please enter a valid phone number',
                  },
                  minLength: {
                    value: 8,
                    message: 'Must be between 8 and 10 digits',
                  },
                  maxLength: {
                    value: 14,
                    message: 'Must be between 8 and 10 digits',
                  },
                }}
                name="phoneNumber"
                render={({ field }) => (
                  <MaskedInput
                    id="phoneNumber"
                    tabindex="0"
                    disabled={searchParams.get('invite_id')}
                    className="w-[280px] md:w-[145px]"
                    onInput={() => setLandlineError(false)}
                    onChange={(e) => {
                      setValue('phoneNumber', formatNumber(e.target.value))
                    }}
                    type="tel"
                    placeholder="Mobile Number"
                    withLabel
                    labelType="floating"
                    variant="default"
                    mask={maskPattern}
                    {...field}
                  />
                )}
              />
            )}

            {landlineError && !errors.phoneNumber?.message ? (
              <div className=" w-[294px] flex justify-start md:h-[25px] md:w-[250px] absolute bottom-[33%] left-[19.5%] md:bottom-[29.5%] md:left-[10%] md:text-center">
                <p className="text-notification-red text-xs ">
                  Enter a valid mobile number that can receive SMS messages
                </p>
              </div>
            ) : (
              <div className=" w-[294px] flex justify-start md:h-[25px] md:w-[110px] absolute bottom-[35%] left-[22%] md:bottom-[29.5%] md:left-[55%] md:text-center">
                <p className="text-notification-red text-xs ">
                  {errors.phoneNumber?.message}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-[8px] w-[420px] md:w-[282px] md:my-0 md:mx-auto md:mb-[32px] mb-[40px]">
          <Checkbox
            tabindex="0"
            className="mt-[3px]"
            name="terms"
            isChecked={isChecked}
            onChange={handleOnChange}
          />
          <span
            className='font-["Open_Sans"] text-[#808080] text-sm font-normal leading-5'
            onClick={handleOnChange}
          >
            By clicking this box, you agree to our{' '}
            <a
              target="_blank"
              href="https://corporate.thryv.com/thryv-terms/?_gl=1*14a5rio*_ga*MTQ4NTI3NzY3NS4xNjc0NzU3OTc1*_ga_Q0P8XE9ZR3*MTY4MzEzNjA5MC45LjAuMTY4MzEzNjA5Mi4wLjAuMA"
              className="font-semibold text-[#057AFF] underline"
              rel="noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="https://corporate.thryv.com/privacy/?_gl=1*qrsy9a*_ga*MTQ4NTI3NzY3NS4xNjc0NzU3OTc1*_ga_Q0P8XE9ZR3*MTY4MzEzNjA5MC45LjAuMTY4MzEzNjA5Mi4wLjAuMA"
              className="font-semibold text-[#057AFF] underline"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </span>
        </div>
        {loading ? (
          <div className="w-[420px] md:w-[329px] flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="w-[420px] md:w-[329px] flex justify-center">
            <Button
              tabindex={0}
              variant="primary"
              type="submit"
              disabled={
                firstName === '' ||
                lastName === '' ||
                email === '' ||
                phoneNumber === '' ||
                !isChecked
              }
            >
              Continue
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}

export default CreateProfile
