import styleSearchTermInString from '../../../../../../utils/styleSearchTermInString'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPrepopulatedNumber } from '../../../../../calls/slices/callSlice'
import DOMPurify from 'dompurify'

// Components
import { ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterAvatar } from '../../../../../common/command-center-avatar'

export const ContactRow = ({ user, textToEmphasize, onCardClick }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { name, email, iconUrl, phoneNumber } = user

  const firstName = name.length ? name.split(' ')[0] : ''
  const lastName = name.length ? name.split(' ')[1] : ''

  const title = (name && name) || (email && email) || (phoneNumber && phoneNumber)

  const markupWithBoldedWords = (str, substr) => {
    return {
      __html: DOMPurify.sanitize(
        styleSearchTermInString.allOccurences(
          str,
          substr,
          'font-bold text-thryv-black-500',
        ),
      ),
    }
  }

  const handlePhoneNumberClick = () => {
    dispatch(setPrepopulatedNumber(phoneNumber))
    navigate('/calls')
  }

  return (
    <div
      className="flex bg-thryv-white-50 p-4 rounded border border-thryv-gray-light-400 cursor-pointer"
      onClick={onCardClick}
    >
      <CommandCenterAvatar
        className="mr-2.5"
        imageUrl={iconUrl}
        firstName={firstName}
        lastName={lastName}
        widthAndHeight={48}
        avatarProps={{ size: 'default' }}
      />

      <div className="flex flex-col justify-center">
        {/* ----- TITLE ----- */}
        <ParagraphText variant="reg" color="thryv-black-500">
          <span
            dangerouslySetInnerHTML={markupWithBoldedWords(title, textToEmphasize)}
          />
        </ParagraphText>

        {/* ----- SUBTITLE ----- */}
        <ParagraphText variant="reg" color="thryv-steel" className="flex gap-0.5">
          {/* Email */}
          {name && email ? (
            <span
              dangerouslySetInnerHTML={markupWithBoldedWords(email, textToEmphasize)}
            />
          ) : null}

          {email && phoneNumber ? <span>|</span> : null}

          {/* Phone */}
          {phoneNumber ? (
            <button
              type="button"
              onClick={handlePhoneNumberClick}
              className="hover:text-[#057aff] [&>span]:hover:text-[#057aff]"
              dangerouslySetInnerHTML={markupWithBoldedWords(
                phoneNumber,
                textToEmphasize,
              )}
            />
          ) : null}
        </ParagraphText>
      </div>
    </div>
  )
}
