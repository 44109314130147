import { forwardRef } from 'react'

export const UserSearchBar = forwardRef(
  (
    {
      className,
      search,
      setSearch,
      onChange,
      onFocus,
      onBlur,
      onSubmit,
      onKeyDown,
      showError,
    },
    searchRef,
  ) => {
    return (
      <div className={`${className} flex items-center h-[32px] w-full `}>
        <form className="w-full" onSubmit={onSubmit}>
          <input
            ref={searchRef}
            type="text"
            value={search}
            className={`contact-compose-searchbar w-full focus:outline-none font-open-sans text-sm text-thryv-black-500 ${
              showError && '!text-[#DF2A2A]'
            }`}
            placeholder="name or account@example.com"
            onChange={(e) => {
              onChange(e)
              setSearch(e.target.value)
            }}
            autoFocus
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            autoComplete="off"
          />
        </form>
      </div>
    )
  },
)
