import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_STAFF } from '../../../../graphql/mutations'
import {
  GET_STAFF,
  HAS_AVAILABLE_SEATS_FOR_STAFF,
  GET_CONNECTED_CHANNELS_BY_USER,
} from '../../../../graphql/queries'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import formSchema from '../../../../modules/settings/SecurityStaff/sections/staff-section/components/edit-staff-details-modal/formSchema'
import { getRoleIntegerEquivalent } from '../../../../utils/get-role-or-status-integer-equivalent'
import { STAFF_STATUS } from '../../../../modules/settings/SecurityStaff/sections/staff-section/data/staff-status'
import { useCountryData } from '../../../../hooks/use-country-data'

// Components
import { ParagraphText, Button } from '@thryvlabs/maverick'
import { LoadingSpinner } from '../../loading-spinner'
import { ConnectedChannelInputs } from '../inputs'
import {
  PhoneInput,
  EmailInput,
  FirstNameInput,
  LastNameInput,
  RoleInput,
} from '../inputs'
import { CountryCodeInput } from '../inputs/CountryCodeInput'
import { CloseModalButton } from '../close-modal-button'

export const AddStaffForm = ({ close, setNewStaff }) => {
  const { user } = useAuth0()
  const [error, setError] = useState('')
  const { countryISO, countryCode } = useCountryData()

  const { data: connectedChannelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: user.businessId, user_id: user.cc_uid },
    fetchPolicy: 'no-cache',
  })

  const initChannels = (channelData) => {
    const tempAccessibleChannels = []

    channelData?.forEach((channel) => {
      if (channel.Provider === 'CC') return
      if (channel.Status === '1' || channel.Status === '2') {
        tempAccessibleChannels.push(channel)
      }
    })
    return tempAccessibleChannels
  }

  const accessibleChannels = initChannels(
    connectedChannelsData?.queryConnectedChannelsByUser,
  )

  const [addStaff, { loading }] = useMutation(CREATE_STAFF, {
    refetchQueries: [HAS_AVAILABLE_SEATS_FOR_STAFF],
  })

  const { data: staffData } = useQuery(GET_STAFF)

  const staff = staffData?.staff

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema(countryISO)),
    defaultValues: {
      firstName: '',
      lastName: '',
      countryCode: countryISO,
      phone: '',
      email: '',
      role: '',
    },
  })

  const firstNameWatcher = watch('firstName')
  const lastNameWatcher = watch('lastName')
  const phoneNumberWatcher = watch('phone')
  const emailWatcher = watch('email')
  const roleWatcher = watch('role')

  const [selectedRole, setSelectedRole] = useState('Staff')

  const handleAddStaffError = (err) => {
    if (
      err.message ===
      'Staff member already exists, please enter a new staff member email.'
    ) {
      setError(
        'Uh-oh! Looks like this email address is already used to log in to another Thryv account. Please try again with a different email.',
      )
    } else if (err.message === 'Please upgrade to add more staff.') {
      setError('Please upgrade to add more staff.')
    } else if (
      err.message.includes('Account is not subscribed to a plan with seat addons') ||
      err.message === 'Please upgrade to add more staff.'
    ) {
      setError('Please upgrade to add more staff.')
    } else if (err.message === 'Request failed with status code 422') {
      setError('Credit card expiration date is expired or does not match.')
    } else if (err.message === 'Error in doesEmailExistInAuth0') {
      setError('Please enter a valid email.')
    } else {
      setError('An error occurred while trying to add this staff member.')
    }
  }

  const addStaffToDatabase = async (formData) => {
    const parsedConnectedChannels = formData?.connectedChannels?.length
      ? JSON.parse(formData.connectedChannels)
      : []

    const isDuplicateStaff = staff.some(
      (staff) =>
        staff?.Status !== '4' &&
        staff?.Email?.toLowerCase() === formData?.email?.toLowerCase(),
    )

    if (isDuplicateStaff) {
      setError(`Staff member already exists, please enter a new staff member email.`)
      return
    } else {
      setError('')
    }

    try {
      await addStaff({
        variables: {
          CC_UserID: user?.cc_uid,
          FirstName: firstNameWatcher,
          LastName: lastNameWatcher,
          Email: emailWatcher,
          Phone: countryCode + phoneNumberWatcher,
          Status: STAFF_STATUS.active,
          Role: getRoleIntegerEquivalent(selectedRole),
          Type: 'cinnamon roll', // FIXME: Figure out what Type refers to here. A placeholder is needed for this to work.
          CountryCode: countryISO,
          ChannelAccess: parsedConnectedChannels,
        },
      })
      setNewStaff(`${firstNameWatcher} ${lastNameWatcher}`)
    } catch (err) {
      handleAddStaffError(err)
    }
  }
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') close()
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className="add-staff-modal md:max-h-[690px] md:w-[780px] sm:max-w-[300px]">
      <div className="flex justify-between items-center mb-5 sm:max-w-[280px]">
        <h4 className="font-semibold font-[Montserrat] text-[22px] leading-[30px] text-thryv-black-500">
          Add Staff
        </h4>
        <CloseModalButton onClick={close} />
      </div>

      <form onSubmit={handleSubmit(addStaffToDatabase)}>
        {/* ----- FORM ----- */}
        <div className="pr-[20px] mb-[42px]">
          <div className="flex gap-[53px] mb-[48px] sm:flex-col sm:gap-8 sm:mb-[32px]">
            <FirstNameInput
              className="w-[302px]"
              register={register}
              errors={errors}
            />
            <LastNameInput className="grow" register={register} errors={errors} />
          </div>

          <div className="flex mb-[42px] gap-[52px] sm:flex-col sm:gap-8 sm:mb-[32px]">
            <CountryCodeInput className="sm:w-[280px]" />
            <PhoneInput className="grow" control={control} errors={errors} />
          </div>

          <div className="flex gap-[22px] mb-[42px] sm:flex-col sm:gap-8 sm:mb-[32px]">
            <EmailInput
              className="w-[333px] sm:w-[280px]"
              register={register}
              errors={errors}
            />

            <RoleInput
              className="grow"
              control={control}
              errors={errors}
              onChange={setSelectedRole}
            />
          </div>
        </div>

        {/* ----- MANAGE CONNECTED CHANNELS ----- */}
        <div className="text-start mb-[30px] sm:max-w-[280px]">
          <h5 className="font-semibold text-base leading-[22px] font-[Montserrat] mb-2">
            Manage connected channels
          </h5>
          <ParagraphText variant="reg" className="!leading-[20px] mb-6">
            {accessibleChannels?.length
              ? 'Grant access to your channels here. You can change it later in Settings.'
              : 'You do not yet have any channels connected.'}
          </ParagraphText>
          {accessibleChannels?.length > 0 && (
            <>
              {/* ----- MANAGE CONNECTED CHANNELS CONTAINER ----- */}
              <div className="md:max-h-[120px] max-h-[210px] md:max-w-[720px] overflow-y-auto bg-barely-white rounded-md p-4 z-0 relative">
                <ConnectedChannelInputs
                  currentRole={selectedRole}
                  connectedChannels={accessibleChannels}
                  control={control}
                  errors={errors}
                />
              </div>
            </>
          )}
        </div>

        {/* ----- SUBMISSION BUTTONS ----- */}
        <div className="flex justify-end items-center gap-4 h-[33.69px] sm:pb-8 sm:max-w-[280px]">
          {loading ? (
            <LoadingSpinner widthAndHeight={36} />
          ) : (
            <>
              <ParagraphText variant="sm" color="[#DF2A2A]">
                {error}
              </ParagraphText>
              <Button
                data-testid="canel-btn"
                type="button"
                variant="text"
                level={2}
                textVariant="light"
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                data-testid="create-btn"
                variant="primary"
                type="submit"
                disabled={
                  !firstNameWatcher?.trim().length ||
                  !lastNameWatcher?.trim().length ||
                  !emailWatcher?.trim().length ||
                  roleWatcher?.name === '' ||
                  phoneNumberWatcher === undefined ||
                  (phoneNumberWatcher !== undefined &&
                    !phoneNumberWatcher?.trim().length)
                }
              >
                Create
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}
