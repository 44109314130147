import axios from 'axios'
const { VITE_VONAGE_API } = import.meta.env

/**
 * Generate jwt token for accessing vonage sdk
 * @param {String} ccid The cc_id from auth0 (is "" by default)
 * @return {String} The jwt token
 */
export const generateJwtToken = async (countrycode, subject, token) => {
  const params = subject ? { countrycode, subject } : { countrycode }
  const response = await axios.get(`${VITE_VONAGE_API}/get_vonage_token`, {
    headers: { Authorization: `Bearer ${token}` },
    params,
  })
  return response.data.jwt
}

export const getLegsApi = async (conversation_id, jwt2) => {
  try {
    const { data } = await axios.get('https://api.nexmo.com/v0.3/legs', {
      headers: {
        Authorization: `Bearer ${jwt2}`,
      },
      params: { conversation_id },
    })
    return data._embedded.legs
  } catch (error) {
    return
  }
}

export const connectVonageChannel = async (
  token,
  ccid,
  locid,
  countrycode,
  areacode,
  type,
) => {
  return await axios.post(
    `${VITE_VONAGE_API}/connect_vonage_channel`,
    {
      ccid,
      locid,
      countrycode,
      number: areacode,
      type,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  )
}

export const transferToVoiceMail = async ({
  token,
  clientUid,
  countryIso2,
  clientNumber,
}) => {
  return axios.post(
    `${VITE_VONAGE_API}/transfer_to_voicemail`,
    {
      uuid: clientUid,
      countrycode: countryIso2,
      cc_number: clientNumber,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}
