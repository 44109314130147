import { gql } from '@apollo/client'

export const UPDATE_USER_PREFERENCES = gql`
  mutation update_user_preferences_mutation(
    $userId: String!
    $preferences: String!
  ) {
    updateUserPreferences(userId: $userId, preferences: $preferences) {
      email
      appSettings {
        Values
        Platform
      }
    }
  }
`

export const SUBSCRIPTION_FOR_USER_PREFERENCES_UPDATE = gql`
  subscription onUpdateUserPreferences($email: String!) {
    onUpdateUserPreferences(email: $email) {
      email
      appSettings {
        Values
        Platform
      }
    }
  }
`
