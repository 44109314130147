const { VITE_ENV } = import.meta.env
const MARKETING_CENTER_BASE_URL =
  VITE_ENV === 'prod'
    ? 'https://marketingcenter.labs.thryv.com'
    : 'https://mc-dev.sandbox.thryv.com'

let initialData = {
  timeStamp: 1684331934329,
  navigationCollapsed: false,
  notificationPreferences: {
    doNotDisturb: false,
    desktop: [
      // { label: 'New Conversations', value: true },
      // { label: 'New TeamChat', value: true },
      { label: 'Inbox', value: true },
      { label: 'TeamChat', value: true },
      { label: 'Calls', value: true },
    ],
    mobile: [
      // { label: 'New Conversations', value: true },
      // { label: 'New TeamChat', value: true },
      { label: 'Inbox', value: true },
      { label: 'TeamChat', value: true },
      { label: 'Calls', value: true },
    ],
    selectedConversationDesktop: { name: '', value: '' },
    selectedTeamChatDesktop: { name: '', value: '' },
    selectedConversationMobile: { name: '', value: '' },
    selectedTeamChatMobile: { name: '', value: '' },
  },
  navigationPreferences: {
    // order: ["0", "1", "2", "3", "4", "5", "6", "7"],
    order: ['0', '1', '2', '3', '4', '6', '5'],

    data: [
      {
        title: 'Command Center',
        id: '0',
        order: ['section-1', 'section-2', 'section-3', 'section-4'],
        items: [
          {
            id: 'section-1',
            title: 'Inbox',
            visible: true,
            center: 'Command Center',
          },
          {
            id: 'section-2',
            title: 'TeamChat',
            visible: true,
            center: 'Command Center',
          },
          {
            id: 'section-3',
            title: 'Calls',
            visible: true,
            center: 'Command Center',
          },
          {
            id: 'section-4',
            title: 'Voicemail',
            visible: true,
            center: 'Command Center',
          },
        ],
      },
      {
        title: 'ThryvPay',
        id: '1',
        order: ['section-5', 'section-6'],
        items: [
          {
            id: 'section-5',
            title: 'Transactions',
            visible: true,
            center: 'ThryvPay',
          },
          {
            id: 'section-6',
            title: 'Scheduled Payments',
            visible: true,
            center: 'ThryvPay',
          },
        ],
      },
      {
        title: 'Business Center',
        id: '2',
        order: [
          'section-8',
          'section-9',
          'section-10',
          'section-11',
          'section-12',
          'section-13',
          'section-14',
          'section-15',
        ],
        items: [
          {
            id: 'section-8',
            title: 'Dashboard',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-9',
            title: 'Calendar',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-10',
            title: 'Clients',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-11',
            title: 'Sales',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-12',
            title: 'Documents',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-13',
            title: 'Marketing',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-14',
            title: 'Online Presence',
            visible: true,
            center: 'Business Center',
          },
          {
            id: 'section-15',
            title: 'Social Content',
            visible: true,
            center: 'Business Center',
          },
        ],
        link: 'https://go.thryv.com/app/dashboard',
      },
      {
        title: 'Marketing Center',
        id: '3',
        order: [
          'section-16',
          'section-17',
          'section-18',
          'section-19',
          'section-20',
          'section-21',
          'section-22',
          'section-23',
        ],
        items: [
          {
            id: 'section-16',
            title: 'Overview',
            visible: true,
            center: 'Marketing Center',
          },
          // {
          //   id: 'section-17',
          //   title: 'Recommendations',
          //   visible: true,
          //   center: 'Marketing Center',
          // },
          {
            id: 'section-18',
            title: 'Analytics',
            visible: true,
            center: 'Marketing Center',
          },
          // {
          //   id: 'section-19',
          //   title: 'Landing Pages',
          //   visible: true,
          //   center: 'Marketing Center',
          // },
          {
            id: 'section-19',
            title: 'Campaigns',
            visible: true,
            center: 'Marketing Center',
          },
          {
            id: 'section-20',
            title: 'Social',
            visible: true,
            center: 'Marketing Center',
          },
          {
            id: 'section-21',
            title: 'Tools',
            visible: true,
            center: 'Marketing Center',
          },
          {
            id: 'section-22',
            title: 'Trackable Phone Number',
            visible: true,
            center: 'Marketing Center',
          },
          {
            id: 'section-23',
            title: 'Listings Management',
            visible: true,
            center: 'Marketing Center',
          },
        ],
        link: `${MARKETING_CENTER_BASE_URL}/overview`,
      },
      // {
      //   title: "Workforce Center",
      //   id: "4",
      //   order: ["section-23"],
      //   items: [
      //     {
      //       id: "section-23",
      //       title: "MyPay",
      //       visible: true,
      //       center: "Payroll Center"
      //     }
      //   ]
      // },
      // {
      //   title: "Reporting Center",
      //   id: "5",
      //   order: ["section-24"],
      //   items: [
      //     {
      //       id: "section-24",
      //       title: "Sales Dashboard",
      //       visible: true,
      //       center: "Reporting Center"
      //     }
      //   ]
      // },
      {
        title: 'Installed Apps',
        id: '4',
        order: ['section-25', 'section-26', 'section-27', 'section-28'],
        items: [
          {
            id: 'section-25',
            title: 'Signatures',
            visible: true,
            center: 'Installed Apps',
          },
          {
            id: 'section-26',
            title: 'Website Builder',
            visible: true,
            center: 'Installed Apps',
          },
          {
            id: 'section-27',
            title: 'Leads',
            visible: true,
            center: 'Installed Apps',
          },
          {
            id: 'section-28',
            title: 'Listing Management',
            visible: true,
            center: 'Installed Apps',
          },
        ],
      },
      {
        title: 'Custom Links',
        id: '5',
        order: ['section-29', 'section-30'],
        items: [
          // {
          //   id: "section-29",
          //   title: "QuickBooks",
          //   icon: "https://quickbooks.intuit.com/etc/designs/quickbooks/images/favicon.ico",
          //   link: "https://accounts.intuit.com/app/sign-in",
          //   visible: true,
          //   center: "Custom Links"
          // },
          {
            id: 'section-30',
            title: 'Thryv',
            icon: 'https://www.thryv.com/favicon.ico',
            link: 'https://www.thryv.com',
            visible: true,
            center: 'Custom Links',
          },
        ],
      },
      {
        title: 'Reporting Center',
        id: '6',
        order: [
          'section-31',
          // 'section-32', 'section-33', 'section-34'
        ],
        items: [
          {
            id: 'section-31',
            title: 'Reports',
            visible: true,
            center: 'Reporting Center',
          },
          // {
          //   id: 'section-32',
          //   title: 'Favorites',
          //   visible: true,
          //   center: 'Reporting Center',
          // },
          // {
          //   id: 'section-33',
          //   title: 'ThryvAI',
          //   visible: true,
          //   center: 'Reporting Center',
          // },
          // {
          //   id: 'section-34',
          //   title: 'Bin',
          //   visible: true,
          //   center: 'Reporting Center',
          // },
        ],
      },
    ],
  },
}

export default initialData
