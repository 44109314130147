import { forwardRef } from 'react'
import { TransitionContainer } from '../../../../../../common/transition-container'
import { MessageCard } from './message-card'

const removeTagsRegexPattern = /<[^>]*>/g

export const PinDropdown = forwardRef(
  (
    {
      show,
      messages,
      setShowDropdown,
      refetchPins,
      refetchMessages,
      messageContentRef,
    },
    ref,
  ) => {
    return (
      <TransitionContainer
        show={show}
        width={300}
        className="mt-[3px] p-4 border border-solid border-thryv-gray-light-400 max-h-[498px] overflow-hidden overflow-y-scroll no-scrollbar"
        ref={ref}
      >
        <ul className="flex flex-col gap-2.5">
          {messages.map((message, i) => {
            const isVoicemail = message?.item_type === 'VOICEMAIL'
            const isSMS = message?.item_type === 'SMS'
            const content = isVoicemail
              ? message.body
              : message.email_snippet
                ? message.email_snippet
                : message.body.replace(removeTagsRegexPattern, '')

            return (
              <li key={i}>
                <MessageCard
                  isVoicemail={isVoicemail}
                  isSMS={isSMS}
                  refetchPins={refetchPins}
                  messageContentRef={messageContentRef}
                  refetchMessages={refetchMessages}
                  message={message}
                  setShowDropdown={setShowDropdown}
                  imageUrl={message?.imageUrl}
                  content={content}
                />
              </li>
            )
          })}
        </ul>
      </TransitionContainer>
    )
  },
)
