import styled from 'styled-components'
import { ParagraphText } from '@thryvlabs/maverick'

export const labelsMenuBtnStyles = `
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  transition: background-color 300ms;

  &:hover {
    background-color: #f2f4f6;
  }
`

const LabelsMenuBtn = ({ className, children, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <ParagraphText variant="reg" color="thryv-black-500">
        {children}
      </ParagraphText>
    </button>
  )
}

export const StyledLabelsMenuBtn = styled(LabelsMenuBtn)`
  ${labelsMenuBtnStyles}
`
