import './sort-menu.css'
import { useEffect, useRef } from 'react'
import { transformToSortData } from '../../../sections/sort-section/utils/transform-to-sort-data'
import { setInboxSort } from '../../../../../slices/inboxThreadsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { inboxSorts } from '../../../../data/sort-options'

// Components
import { RadioGroup } from '@thryvlabs/maverick'
import { TransitionContainer } from '../../../../../../common/transition-container'
import { useClickOutside } from '../../../../../../../hooks'

export const SortMenu = ({ className, show, onSelectionClick, onOutsideClick }) => {
  const sortMenuRef = useRef(null)
  const dispatch = useDispatch()
  const { inboxSort } = useSelector((state) => state.inboxThreads)

  useEffect(() => {
    onSelectionClick(inboxSort)
  }, [inboxSort])

  useClickOutside((e) => {
    onOutsideClick && onOutsideClick(e)
  }, sortMenuRef)

  return (
    <TransitionContainer
      ref={sortMenuRef}
      show={show}
      className={`${className} border border-thryv-gray-light-400`}
      transformOrigin={'top center'}
    >
      <div>
        {
          <RadioGroup
            data={transformToSortData(inboxSorts)}
            value={{
              name: inboxSort,
              value: inboxSort,
            }}
            column
            onChange={(newSort) => dispatch(setInboxSort(newSort.name))}
          />
        }
      </div>
    </TransitionContainer>
  )
}
