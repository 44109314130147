import { useMemo, useState } from 'react'

export const useSortableData = (items, initialSortConfig = null) => {
  const [sortConfig, setSortConfig] = useState(initialSortConfig)

  const sortedItems = useMemo(() => {
    let sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const sortFn = (aItem, bItem) => {
          if (aItem < bItem) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (aItem > bItem) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        }

        if (typeof sortConfig.accessor === 'function') {
          const aItem = sortConfig.accessor(a)
          const bItem = sortConfig.accessor(b)
          return sortFn(aItem, bItem)
        } else {
          const aItem = a[sortConfig.key]
          const bItem = b[sortConfig.key]
          return sortFn(aItem, bItem)
        }
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = (key, accessor = null) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction, accessor })
  }
  return { items: sortedItems, requestSort, sortConfig }
}
