import { forwardRef } from 'react'
import { handleStartNewMessage } from '../../../../slices/inboxSlice'
import { useDispatch, useSelector } from 'react-redux'

export const ConversationRowWrapper = forwardRef(
  ({ children, variant, className, onClick, ...rest }, ref) => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state.inbox)
    let backgroundColor

    const initBackgroundColor = () => {
      if (variant === 'pinned') backgroundColor = '#ff50000d'
      if (variant === 'selected') backgroundColor = '#EEEEEE'
    }

    initBackgroundColor()

    return (
      <tr
        className={`${
          isLoading ? 'cursor-default' : 'cursor-pointer'
        } flex p-2  rounded h-[66px] md:h-[56px] hover:bg-[#00000008]
        ${className || ''}`}
        style={{ backgroundColor: backgroundColor }}
        tabIndex="-1"
        ref={ref}
        onClick={() => {
          onClick()
          dispatch(handleStartNewMessage(false))
        }}
        {...rest}
      >
        {children}
      </tr>
    )
  },
)
