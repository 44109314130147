export const scrollToMsgInThread = (fileSk) => {
  setTimeout(function () {
    let targetElement

    targetElement = document.getElementById(fileSk)

    if (!targetElement) {
      document
        .getElementById('message-container')
        .scroll({ top: 0, behavior: 'smooth' })
    } else {
      targetElement.classList.add('message-selected')
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, 500)
}
