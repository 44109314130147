import { useEffect, useState, useRef } from 'react'
import './expandable-text-box.css'
import { ParagraphText } from '@thryvlabs/maverick'
import { TextButton } from '../text-button'

/* -----  Helper Functions Start ----- */

const addLineClampStyle = (elem) => {
  elem.style.display = '-webkit-box'
  elem.style.webkitBoxOrient = 'vertical'
  elem.style.webkitLineClamp = '2'
}

const removeLineClampStyle = (elem) => {
  elem.style.display = '-webkit-box'
  elem.style.webkitBoxOrient = 'vertical'
  elem.style.webkitLineClamp = 'initial'
}

const getMaxHeight = (elem) => {
  const elemHeight = elem.clientHeight
  if (elemHeight > 36) return elemHeight
  else return 36
}

const resizeTextbox = (textbox, isExpanded, maxHeight) => {
  const growTextbox = () => {
    removeLineClampStyle(textbox)
    textbox.style.maxHeight = maxHeight + 'px'
  }

  const shrinkTextbox = () => (textbox.style.maxHeight = '36px')

  isExpanded ? growTextbox() : shrinkTextbox()
}

/* -----  Helper Functions End ----- */

export const ExpandableTextbox = ({ className, children, isOpen, setIsOpen }) => {
  const textboxContainerRef = useRef(null)
  const [height, setHeight] = useState({ max: 36, min: null })

  const init = () => {
    const textbox = textboxContainerRef.current.firstChild
    const newHeight = { max: getMaxHeight(textbox), min: null }

    setHeight(newHeight)

    resizeTextbox(textbox, isOpen, newHeight.max)
    if (!isOpen) addLineClampStyle(textbox)
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const textbox = textboxContainerRef.current.firstChild
    if (height.max > 36) resizeTextbox(textbox, isOpen, height.max)
  }, [isOpen])

  const handleTransitionEnd = () => {
    const textbox = textboxContainerRef.current.firstChild
    isOpen ? removeLineClampStyle(textbox) : addLineClampStyle(textbox)
  }

  return (
    <div onTransitionEnd={handleTransitionEnd} ref={textboxContainerRef}>
      <ParagraphText
        variant="sm"
        color="thryv-night"
        className={`main-text transition-all ease-in-out duration-300 overflow-hidden ${className}`}
      >
        {children}{' '}
        <TextButton
          className={`text-expand-button 
          ${!isOpen ? 'inline-block' : 'hidden'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          More
        </TextButton>
        <TextButton
          className={`${!isOpen && 'invisible pointer-events-none'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          Less
        </TextButton>
      </ParagraphText>
    </div>
  )
}
