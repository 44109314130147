import { TransitionContainer } from '../../../../common/transition-container'
import { UnderlineBorder } from '../compose-header/compose-header'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { ParagraphText } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { setSelectedChannel } from '../../../slices/messageComposeSlice'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { useRef } from 'react'
import {
  setModalContent,
  setShowModal,
} from '../../../../common/modals/slices/connectChannelsSlice'
import { useSelector } from 'react-redux'
import ChannelsIcon from '../../../../channels-icon/channels-icon'
import formatContactData from '../../../hooks/formatContactData'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'

const ChannelItem = ({
  className,
  textColor,
  channel,
  setActive,
  disabled = false,
}) => {
  const dispatch = useDispatch()
  const selectedChannel = useSelector(
    (state) => state.messageCompose.selectedChannel,
  )

  const handleSelectChannel = () => {
    dispatch(setSelectedChannel(channel))
    localStorage.setItem('selectedChannel', JSON.stringify(channel))
    setActive(false)
  }
  const checkIfSame = selectedChannel?.AccountUserValue === channel?.AccountUserValue

  return (
    <>
      <li
        className={`rounded ${
          checkIfSame && 'bg-thryv-gray-medium-100/100'
        } h-[36px] pb-1 relative mb-2`}
      >
        <button
          data-testid={`channel-change-${channel.Provider}`}
          onClick={() => handleSelectChannel()}
          className={`${className} flex p-2 gap-2 rounded  w-full ${
            channel.Status === '99'
              ? 'hover:border border-[#EF9595] bg-[#FCEAEA]'
              : 'enabled:hover:bg-thryv-gray-light-200'
          } `}
          disabled={channel.Status === '99' || disabled}
        >
          <ChannelsIcon
            variant={channel.Provider}
            width={20}
            height={20}
            className={channel.Status === 99 ? 'opacity-30' : null}
          />
          <ParagraphText
            variant="reg"
            color={textColor || '#231F20'}
            className={`${
              channel.Status === 99 || disabled ? 'opacity-30' : null
            } truncate`}
          >
            {channel?.Nickname
              ? channel.Nickname.replace(/''/g, "'")
              : channel.Provider === 'video'
                ? 'Video Channel'
                : formatContactData(channel.AccountUserValue)}
          </ParagraphText>
        </button>
      </li>
    </>
  )
}

const ExploreChannels = ({ className }) => {
  const dispatch = useDispatch()

  return (
    <button
      data-testid="explore-channel"
      onClick={() => {
        dispatch(setShowModal(true))
        dispatch(setModalContent(1))
      }}
      className={`rounded hover:bg-thryv-gray-light-200 w-full h-[36px] pb-1 ${className}`}
    >
      <li className={`flex p-2 gap-2 w-full`}>
        <CommandCenterIcon
          variant="towerBroadcast"
          type="solid"
          width={20}
          height={20}
          color={'#057AFF'}
        />
        <ParagraphText variant="reg" className="text-[#057AFF]">
          Explore Channels
        </ParagraphText>
      </li>
    </button>
  )
}

export const ChannelsPopup = ({
  show,
  setActive,
  className,
  availableChannels,
  disabledChannels,
}) => {
  const dropDownRef = useRef(null)

  useClickOutside(() => {
    setActive(false)
  }, dropDownRef)

  const { channelInfo } = useSelector((state) => state.connectChannels) //using data coming from BE
  /* 
  This should be divided it into 3 lists of channels: 
    1. the channels that are selectable and can send the message
    2. the channels that are disabled because of the thread: ig/fb/webchat cant send on threads that have email, email cant send on sunshine threads
    3. the disconnected channels that need to be reauthenticated.

  for list of channels #2 we need to show the tooltip that says "No active session." and have the disabled styles applied to this array. 
  */
  const disconnectedChannels = channelInfo?.filter(
    (channel) => channel.Status === '99',
  )

  return (
    <TransitionContainer
      show={show}
      className={`${className} p-2 pt-0 max-h-[200px] ${
        availableChannels.length > 1 || disabledChannels.length > 1
          ? 'overflow-y-scroll'
          : ''
      } `}
      width={availableChannels.length > 0 || disabledChannels.length > 0 ? 250 : 200}
      ref={dropDownRef}
    >
      <ExploreChannels className={'z-10 sticky inset-0  bg-white'} />
      <ul className={`flex flex-col`}>
        <UnderlineBorder className="mb-1" />
        {availableChannels?.map((channel, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <ChannelItem setActive={setActive} channel={channel} />
            </div>
          )
        })}
        {disabledChannels?.map((channel, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <TooltipWithCenteredArrow
                variant="top"
                width={
                  channel.VendorID === '1' || channel.VendorID === '2'
                    ? '200px'
                    : 'max-content'
                }
                title={
                  channel.VendorID === '1'
                    ? 'No email address associated with this contact'
                    : channel.VendorID === '2'
                      ? 'No phone number associated with this contact'
                      : 'No active sessions'
                }
                // className="!max-w-[500px] !min-w-0"
                positioning={{ offsetX: '0px', offsetY: '-16px' }}
                disableVisibleOnClick={true}
              >
                <ChannelItem
                  disabled={true}
                  setActive={setActive}
                  channel={channel}
                />
              </TooltipWithCenteredArrow>
            </div>
          )
        })}
        {disconnectedChannels?.length > 0 && <UnderlineBorder className="mb-2" />}
        {disconnectedChannels?.map((channel, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <ChannelItem
                setActive={setActive}
                channel={channel}
                // className={'bg-thryv-gray-medium-200'}
              />
            </div>
          )
        })}
      </ul>
    </TransitionContainer>
  )
}
