import { Icon } from '@thryvlabs/maverick'

export const RoundActionButton = ({ dataTestId, className, onClick }) => (
  <button
    data-testid={dataTestId}
    type="button"
    className={`flex justify-center items-center h-[30px] w-[30px] bg-[#CCCCCC] rounded-full ${className}`}
    onClick={onClick}
  >
    <Icon type="solid" variant="plus" color="white" width="13" height="13" />
  </button>
)
