import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faEnvelope, faUserUnlock, faTowerBroadcast } from '@fortawesome/pro-regular-svg-icons'
import { Header, ParagraphText } from '@thryvlabs/maverick'

const ConnectionConfirmation = () => {

    return (
        <div className="w-[400px] p-[0.75rem] text-left">
            <Header variant="h4" fontWeight="semibold">Your WebChat account will be connected within 24 hours!</Header>
            <ParagraphText className='mt-[0.5rem] mb-[0.5rem]' variant="reg" color="#231F20">
                You can now start using Command Center inbox. You can also control who has access to your channel or connect more channels.
            </ParagraphText>

            <div className="flex justify-between items-center p-[4px] bg-[#F8F9FB] border-[1px] border-[#E3E6E8] rounded-[4px] ">
                <span className='flex items-center ml-[4px]'>
                    <FontAwesomeIcon className="text-[14px] text-[#5378FC]" icon={faEnvelope} />

                    <ParagraphText className='mt-[0.5rem] mb-[0.5rem] ml-[4px]' variant="reg" color="#231F20">
                        Start using Command Center inbox
                    </ParagraphText>
                </span>

                <FontAwesomeIcon className="text-[14px] text-[#E3E6E8] mr-[4px]" icon={faAngleRight} />
            </div>

            <div className="flex justify-between items-center p-[4px] mt-[0.5rem] bg-[#F8F9FB] border-[1px] border-[#E3E6E8] rounded-[4px] ">
                <span className='flex items-center ml-[4px]'>
                    <FontAwesomeIcon className="text-[14px] text-[#5378FC]" icon={faUserUnlock} />

                    <ParagraphText className='mt-[0.5rem] mb-[0.5rem]  ml-[4px]' variant="reg" color="#231F20">
                        Grant access to your channel
                    </ParagraphText>
                </span>

                <FontAwesomeIcon className="text-[14px] text-[#E3E6E8] mr-[4px]" icon={faAngleRight} />
            </div>

            <div className="flex justify-between items-center p-[4px] mt-[0.5rem] bg-[#F8F9FB] border-[1px] border-[#E3E6E8] rounded-[4px] ">
                <span className='flex items-center ml-[4px]'>
                    <FontAwesomeIcon className="text-[16px] text-[#5378FC]" icon={faTowerBroadcast} />

                    <ParagraphText className='mt-[0.5rem] mb-[0.5rem]  ml-[4px]' variant="reg" color="#231F20">
                        Connect more channels
                    </ParagraphText>

                </span>


                <FontAwesomeIcon className="text-[14px] text-[#E3E6E8] mr-[4px]" icon={faAngleRight} />
            </div>

        </div>
    )
}

export default ConnectionConfirmation