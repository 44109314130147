import { forwardRef } from 'react'
import styled from 'styled-components'

// Components
import { TransitionContainer } from '../../../../../../common/transition-container'
import { ParagraphText, Button } from '@thryvlabs/maverick'
import { CheckboxGroup } from '../../../../../../common/checkbox-group'

const StyledCheckboxGroup = styled(CheckboxGroup)`
  /* ----- Checkbox Input Container ----- */
  & > li {
    border-radius: 4px;
    padding: 8px;
    transition: background-color 300ms;
  }

  & > li,
  & label {
    cursor: pointer;
  }

  & > li:hover {
    background-color: #f2f4f6;
  }
`
export const LabelTagDropdown = forwardRef(
  ({ show, data, onCheckboxChange, onClearClick, labelsClear }, ref) => {
    const checkedLabels = data.filter(
      (label) => typeof label.checked === 'boolean' && label.checked,
    )
    return (
      <TransitionContainer
        as="div"
        show={show}
        transformOrigin="top left"
        className="top-[calc(100%+3px)] px-2 py-4 border-solid border border-thryv-gray-light-400 !z-[9999]"
        width={230}
        ref={ref}
      >
        <div className="flex justify-between mb-2 mx-2">
          <ParagraphText
            variant="reg"
            color="thryv-steel"
            className="!font-[Montserrat] font-semibold"
          >
            Filter by labels
          </ParagraphText>

          <Button
            variant="text"
            level={2}
            onClick={onClearClick}
            disabled={checkedLabels.length ? false : true}
          >
            Clear all
          </Button>
        </div>
        <StyledCheckboxGroup
          className="!gap-0"
          groupName="message-content-labels"
          data={data}
          column
          onChange={onCheckboxChange}
          labelsClear={labelsClear}
        />
      </TransitionContainer>
    )
  },
)
