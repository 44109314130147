export const StaffTableCell = ({ className, children, width, ...rest }) => {
  return (
    <div
      className={`flex items-center pl-[24px] h-full ${className}`}
      style={{ width: width }}
      {...rest}
    >
      {children}
    </div>
  )
}
