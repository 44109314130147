import { Button, Icon } from '@thryvlabs/maverick'

export const ComposeButton = ({ children, onClick, ...rest }) => (
  <Button
    className="flex items-center gap-1 !px-2.5 py-1"
    variant="primary"
    onClick={onClick}
    {...rest}
  >
    <Icon type="regular" variant="edit" color="white" width="18" />
    {children}
  </Button>
)
