import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 500px;
  height: 380px;
`
export const ModalHeader = styled.div`
  width: 382px;
  height: 30px;

  /* Headers/Desktop&Web/H4(SemiBold)

H4
*/
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  /* identical to box height, or 136% */
  text-align: center;
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 15px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .last-btn {
    margin-top: auto;
  }
`
