import styled, { keyframes } from 'styled-components'

const animate = (num, letAnimate) => {
  if (letAnimate) {
    return keyframes`
        from { 
          transform: translateY(${num}); 
        }
        to { transform: translateY(0px); }
     `
  }
}

export const AnimatedDiv = styled.div`
  animation: ${(props) => animate(props.animationCalculation, props.letAnimate)} .3s;
`
