import { CheckIcon } from '@thryvlabs/maverick'
import { useState, useEffect } from 'react'

const SignUpTimeline = ({ step }) => {
  const [events, setEvents] = useState([])
  // CREATE PROFILE
  const stepOneEvents = [
    { eventTitle: 'Create Profile', theme: 'primary', icon: 1 },
    { eventTitle: 'Verify Phone', theme: 'secondary', icon: 2 },
    { eventTitle: 'Create Password', theme: 'tertiary', icon: 3 },
  ]

  // CREATE PASSWORD
  const stepTwoEvents = [
    { eventTitle: 'Create Profile', theme: 'completed', icon: <CheckIcon /> },
    { eventTitle: 'Verify Phone', theme: 'primary', icon: 2 },
    { eventTitle: 'Create Password', theme: 'secondary', icon: 3 },
  ]

  // VERIFY
  const stepThreeEvents = [
    { eventTitle: 'Create Profile', theme: 'completed', icon: <CheckIcon /> },
    { eventTitle: 'Verify Phone', theme: 'completed', icon: <CheckIcon /> },
    { eventTitle: 'Create Password', theme: 'primary', icon: 3 },
  ]

  useEffect(() => {
    if (step === '1') {
      setEvents(stepOneEvents)
    }
    if (step === '2') {
      setEvents(stepTwoEvents)
    }
    if (step === '3') {
      setEvents(stepThreeEvents)
    }
  }, [step])

  return (
    <div className="md:h-[42px] md:my-[15px] md:mr-9">
      <div className="h-[44px] w-[480px] md:w-[277px] md:h-[42px] my-[70px] md:my-[20px]">
        <div className="grid grid-flow-col justify-between md:left-[10px] md:top-[25px] z-0">
          {events.map((event, index) => (
            <div
              key={Math.random()}
              className="flex flex-col items-center gap-[10px] min-w-[101px] relative h-[83px]"
            >
              {index !== 0 && (
                <div
                  className={`w-[190px] md:w-[100px] border-t-[2px] border-solid absolute z-5 ${
                    event.theme !== 'tertiary'
                      ? 'border-thryv-orange-300 top-[12px] left-[-140px] md:left-[-43px]'
                      : 'border-[#E3E6E8] top-[12px] left-[-140px] md:left-[-63px]'
                  } `}
                ></div>
              )}
              <div
                className={`box-content	 ${
                  event.theme === 'primary'
                    ? 'bg-thryv-orange-500 border-2 border-thryv-orange-500'
                    : event.theme === 'completed'
                      ? 'bg-thryv-orange-500 border-2 border-thryv-orange-500'
                      : 'bg-white border-2 border-thryv-orange-500'
                } min-w-[22px] w-[22px] min-h-[22px] h-[22px] rounded-full flex justify-center items-center z-10`}
              >
                <span
                  className={`w-[15px] h-[15px] flex justify-center items-center ${
                    event.theme !== 'primary' ? 'text-[#FF5000]' : 'text-white'
                  }`}
                >
                  {event.icon}
                </span>
              </div>
              <div className="flex flex-col gap-[3px]">
                <span className="font-montserrat text-[14px] md:text-[12px] md:font-extralight font-medium">
                  {event.eventTitle}
                </span>
                <p
                  className="font-open-sans font-normal text-[10px]"
                  style={{ color: 'rgba(41, 42, 46, 0.5)' }}
                >
                  {event.eventSubtitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SignUpTimeline
