import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLazyQuery, useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../graphql/queries/contacts-queries'
import { Header, Icon, Button } from '@thryvlabs/maverick'
import RightPanelUserProfile from './user-profile/RightPanelUserProfile'
import { useDispatch, batch } from 'react-redux'
import { setOpenVideoCallLinkModal } from '../../calls/slices/callSlice'
import { UserProfileAndActivityOnLoading } from './skeleton/UserProfileAndActivity.skeleton'
import useAvatarComponent from '../../../hooks/useAvatarComponent'
import { ContactPhoneButtonIcon, ContactFaceTimeButtonIcon } from '@icons'
import { selectedThreadSelector } from '../../inbox/slices/inboxThreads.selectors'
import { connectChannelsSelector } from '../../common/modals/slices/connectedChannels.selectors'
import { Link, useNavigate } from 'react-router-dom'
import { setPrepopulatedNumber } from '../../calls/slices/callSlice'
import { CommandCenterIcon } from '../../command-center-icon/command-center-icon'
import EditContactPanel from '../../calls/contacts/edit-contact-panel/EditContactPanel'
import {
  FETCH_MESSAGES_BY_THREAD_ID,
  GET_CONNECTED_CHANNELS_BY_USER,
  FETCH_THREADS,
} from '../../../graphql'
import formatContactData from '../../inbox/hooks/formatContactData'
import { ThryvGuyIcon } from '../../command-center-icon/icons'
import { useInboxMenuConversations } from '../../inbox/inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { TooltipWithCenteredArrow } from '../../common/tooltip-with-centered-arrow'
import { GET_ACTIVE_VIDEO_ROOM } from '../../../graphql/queries/vonage-queries'
import { useAuth0 } from '@auth0/auth0-react'
import { setRoomData } from '../../../redux-toolkit'
import {
  setShowModal,
  setModalContent,
} from '../../common/modals/slices/connectChannelsSlice'
const UserProfileAndActivity = ({ isMobile = false, openConnectPhoneModal }) => {
  const { user } = useAuth0()
  const THRYV_SUPPORT_EMAIL = 'support@thryv.com'
  const [contact, setContact] = useState({
    username: '',
    phone: '',
    email: '',
  })
  const [disableVideoButton, setDisableVideoButton] = useState(true)
  const [disablePhoneButton, setDisablePhoneButton] = useState(true)
  const [openEditContact, setOpenEditContact] = useState(false)
  const [enablePhoneInput, setEnablePhoneInput] = useState(false)
  const [enableEmailInput, setEnableEmailInput] = useState(false)
  const [contactDetails, setContactDetails] = useState({
    body: {
      given_name: '',
      surname: '',
      middle_name: '',
      emails: [],
    },
    contactsk2: '',
    contactsk3: '',
  })

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()
  const [isThryvSupport, setIsThryvSupport] = useState(false)
  const [, setContactHasMessages] = useState(false)
  const [, setWarningModal] = useState(false)
  const [clearPhone, setClearPhone] = useState(false)
  const [clearEmail, setClearEmail] = useState(false)
  const [contactName1, setContactName] = useState('')
  const [videoCallActive, setVideoCallActive] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { channelInfo, maxChannelsAllowed } = useSelector(connectChannelsSelector)
  const { selectedThreadId } = useSelector(selectedThreadSelector)

  const [isPhoneActive, setIsPhoneActive] = useState(false)
  const [isVideoActive, setIsVideoActive] = useState(false)

  const { data: videoRoomData } = useQuery(GET_ACTIVE_VIDEO_ROOM, {
    fetchPolicy: 'cache-and-network',
    variables: { threadId: selectedThreadId },
  })

  useEffect(() => {
    const activeVideoRoomData = videoRoomData?.getActiveVideoRoomByThreadId
    if (activeVideoRoomData) {
      setVideoCallActive(true)
      dispatch(
        setRoomData({ roomData: activeVideoRoomData, threadId: selectedThreadId }),
      )
    }
  }, [videoRoomData])

  const { data: channelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    onCompleted: () => {
      const phoneChannels = channelsData.queryConnectedChannelsByUser.filter(
        (x) => x.Status !== '99' && x.Status !== '0' && x.Provider === 'phone',
      )
      const videoChannels = channelsData.queryConnectedChannelsByUser.filter(
        (x) => x.Status !== '99' && x.Status !== '0' && x.Provider === 'video',
      )

      if (videoChannels.length) {
        setIsVideoActive(true)
      }
      if (phoneChannels.length) {
        setIsPhoneActive(true)
      }
    },
  })

  const handleEditPhone = () => {
    setEnablePhoneInput(true)
    setOpenEditContact(true)
  }

  const handleEditEmail = () => {
    setEnableEmailInput(true)
    setOpenEditContact(true)
  }

  const handleClick = () => {
    if (videoCallActive) {
      navigate('/meetings')
    } else {
      navigate({ pathname: '/inbox' })
      dispatch(setOpenVideoCallLinkModal(true))
    }
  }

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    fetchPolicy: 'no-cache',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const contactCard = useSelector((state) => state.inbox.incomingContactName)

  const formatContactName = (name) => {
    let formattedName

    if (name?.length && name?.includes('.')) {
      formattedName = `${name?.split('.')[0]} ${name?.split('.')[1]}` || name
      formattedName =
        formattedName?.length && formattedName?.includes('@')
          ? formattedName?.split('@')[0]
          : formattedName
    }

    if (
      name?.length &&
      name?.includes('@') &&
      name?.length &&
      !name?.includes('.')
    ) {
      if (name?.split('@')[0]?.toLowerCase() === 'no-reply') {
        formattedName = name?.split('@')[0]
      } else {
        formattedName = `${name?.split('@')[0]} ${name?.split('@')[1]}` || name
      }
    }

    return `${formattedName?.split(' ')[0]}${formattedName
      ?.split(' ')[0]
      ?.slice(1)} ${formattedName?.split(' ')[1]}${formattedName
      ?.split(' ')[1]
      ?.slice(1)}
      `
  }

  const [getContactByThread, { data, error, refetch, loading }] = useLazyQuery(
    FETCH_CONTACT_BY_THREAD,
    {
      onCompleted: () => {
        // setOpenEditContact(false)
        setClearEmail(false)
        setClearPhone(false)
        setEnablePhoneInput(false)
        setEnableEmailInput(false)
      },
    },
  )

  const { data: contactMessages } = useQuery(FETCH_MESSAGES_BY_THREAD_ID, {
    variables: {
      threadid: data?.queryContactsByThread.items[0]?.contactsk1,
    },
  })

  useEffect(
    () => {
      if (contactMessages?.queryMessagesByThread?.items?.length) {
        setContactHasMessages(true)
      }
    },
    [contactMessages],
    setContactHasMessages,
  )

  useEffect(() => {
    if (data?.queryContactsByThread?.items?.length) {
      const body = data?.queryContactsByThread?.items[0]?.body
      const parsedBody =
        typeof body === 'string'
          ? JSON.parse(data?.queryContactsByThread?.items[0]?.body)
          : '' || {}

      setContactDetails({
        body: parsedBody,
        contactsk2: data?.queryContactsByThread?.items[0]?.contactsk2,
        contactsk3: data?.queryContactsByThread?.items[0]?.contactsk3,
      })
      if (parsedBody?.emails?.[0]?.email?.toLowerCase() === THRYV_SUPPORT_EMAIL) {
        setIsThryvSupport(true)
      }
    }
  }, [data, setContactDetails, setIsThryvSupport])

  useEffect(() => {
    if (contactDetails.body?.given_name?.length) {
      const {
        given_name = '',
        surname = '',
        middle_name = '',
        emails,
      } = contactDetails.body

      if (/\d{10}/.test(given_name)) {
        setContactName(formatContactData('Unknown'))
      } else {
        let formattedGivenName =
          typeof given_name === 'string' && given_name?.length > 0
            ? `${given_name}`
            : ''

        const formattedMiddleName =
          typeof middle_name === 'string' && middle_name?.length > 0
            ? `${middle_name}`
            : ''

        const formattedSurname =
          typeof surname === 'string' && surname?.length > 0 ? `${surname}` : ''

        formattedGivenName = !formattedGivenName?.includes(formattedSurname)
          ? formattedGivenName
          : formattedGivenName.replace(formattedSurname, '')

        const parsedName =
          formattedGivenName?.length > 0 ||
          (formattedSurname?.length > 0 && !formattedSurname?.includes('Unknown'))
            ? middle_name === given_name || middle_name === surname
              ? `${formattedGivenName} ${formattedSurname}`
              : `${formattedGivenName} ${formattedMiddleName} ${formattedSurname}`
            : !formatContactName(emails[0]?.email)?.includes('undefined')
              ? formatContactName(emails[0]?.email)
              : emails?.[0]?.email

        setContactName(parsedName)
      }
    }
  }, [contactDetails, setContactName, setContact])

  const body =
    typeof data?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(data?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(data?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(data?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''
  const fullName = /\d{10}/.test(firstName)
    ? 'Unknown'
    : firstName?.length > 0 && lastName?.length === 0
      ? firstName
      : firstName?.length > 0 && lastName?.length > 0
        ? firstName + ' ' + lastName
        : 'Unknown'

  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'default',
    picture_url: contactDetails?.picture_url,
    contactName: fullName.toUpperCase(),
  })

  useEffect(() => {
    if (data) {
      if (data.queryContactsByThread?.items?.length) {
        const parsedContact = JSON.parse(data?.queryContactsByThread?.items[0]?.body)
        if (parsedContact) {
          const {
            emails,
            given_name = '',
            phone_numbers,
            physical_addresses,
            surname = '',
          } = parsedContact
          // Always pull from the body - if the incorrect data is coming through it is a bug
          const email = emails?.length ? emails[0].email : ''
          const phone = phone_numbers?.length ? phone_numbers[0].number : ''
          const location = physical_addresses?.length ? physical_addresses[0] : ''
          const username =
            surname?.length || given_name?.length
              ? `${given_name} ${surname}`
              : formatContactName(emails?.[0]?.email)
          setContact({ email, phone, username, location })
        }
      } else {
        setContact({ username: contactCard })
      }
    }
  }, [data, setContact])

  // update contact info in inboxRightPanel each time user select a thread
  useEffect(() => {
    const threadId = selectedThreadId?.split('#')[0]
    getContactByThread({
      variables: {
        threadid: threadId,
      },
    })
  }, [selectedThreadId, getContactByThread])

  let userHasVideoChannel
  let userHasPhoneChannel

  useEffect(() => {
    userHasVideoChannel = channelInfo.filter(
      (channel) => channel.Provider === 'video' && channel.Status !== '99',
    )
    const userHasVideoChannelPermissions =
      userHasVideoChannel?.[0]?.AccessType === '2'

    if (userHasVideoChannel?.length >= 1 && userHasVideoChannelPermissions) {
      setDisableVideoButton(false)
    } else {
      setDisableVideoButton(true)
    }

    userHasPhoneChannel = channelInfo.filter(
      (channel) => channel.Provider === 'phone' && channel.Status !== '99',
    )
    const userHasPhoneChannelPermissions =
      userHasPhoneChannel?.[0]?.AccessType === '2'
    if (
      userHasPhoneChannel?.length >= 1 &&
      userHasPhoneChannelPermissions &&
      contact?.phone?.length > 0
    ) {
      setDisablePhoneButton(false)
    } else {
      setDisablePhoneButton(true)
    }
  }, [channelInfo, contact])

  // Render loading state
  if (loading) {
    return <UserProfileAndActivityOnLoading />
  }

  // Render error state
  if (error && !loading) {
    return <UserProfileAndActivityOnLoading error={error} />
  }

  const setOnCall = () => {
    dispatch(setPrepopulatedNumber(`+${contact?.phone}` || ''))
  }

  // Render successfull state
  return (
    <>
      {openEditContact ? (
        <EditContactPanel
          handleEditPhone={handleEditPhone}
          handleEditEmail={handleEditEmail}
          contactName={
            fullName.length > 1
              ? fullName
              : contactName1?.replace(' ', '')?.trim() || ''
          }
          enableEmailInput={enableEmailInput}
          enablePhoneInput={enablePhoneInput}
          setIsEditPanelOpen={setOpenEditContact}
          setEnableEmailInput={setEnableEmailInput}
          setEnablePhoneInput={setEnablePhoneInput}
          setWarningModal={setWarningModal}
          contact={data}
          setContactInfoView={() => setOpenEditContact(false)}
          refetch={refetch}
          isInbox={true}
          setClearEmail={setClearEmail}
          setClearPhone={setClearPhone}
          isMobile={isMobile}
          getThreads={getThreads}
        />
      ) : (
        <div
          className={`w-full h-full flex flex-col justify-start md:h-full gap-y-5 ${
            isMobile && 'p-5'
          }`}
        >
          <div className="w-full flex flex-row justify-between items-start">
            <span className="flex gap-4">
              {isMobile && (
                <button
                  type="button"
                  onClick={() => navigate({ pathname: '/inbox' })}
                >
                  <Icon
                    type="regular"
                    variant="chevronLeft"
                    height="20"
                    width="12"
                    color="#4D4D4D"
                    className={isMobile ? 'block' : 'hidden'}
                  />
                </button>
              )}
              <Header
                className={!isMobile && 'md:hidden'}
                fontWeight="semibold"
                variant="h7"
              >
                Contact Card
              </Header>
            </span>
            {contact?.email === 'support@thryv.com' ||
            contact?.email === 'support' ? (
              <></>
            ) : (
              <CommandCenterIcon
                variant="circlePen"
                testid="edit-contact-btn"
                type="solid"
                color="#A3A5A7"
                height="24"
                width="24"
                onClick={() => {
                  setClearPhone(!clearPhone)
                  setClearEmail(!clearEmail)
                  setOpenEditContact(true)
                  handleEditEmail()
                  handleEditPhone()
                }}
                className="cursor-pointer"
              />
            )}
          </div>
          {!isMobile && (
            <div className="min-w-md:hidden grid grid-flow-col justify-between">
              <button>
                <Header
                  className="text-[#0554fffd] ml-[-20px]"
                  fontWeight="semibold"
                  variant="h8"
                >
                  Cancel
                </Header>
              </button>
              <Header className="pr-[70px]" fontWeight="semibold" variant="h7">
                Profile & Activity
              </Header>
            </div>
          )}
          <div className="w-full flex justify-center items-start mx-auto">
            {contactName1?.trim()?.toLowerCase() === 'support@thryv.com' ||
            contactName1?.trim()?.toLowerCase() === 'support' ||
            isThryvSupport ? (
              <ThryvGuyIcon width={'72'} height={'72'} />
            ) : (
              <RenderUserProfile />
            )}
          </div>

          <div className="text-center justify-center border-b-[1px] border-[#E3E6E8] pb-6">
            <Header fontWeight="semibold" variant="h7" className="normal-case">
              {contactName1?.trim()?.toLowerCase() === 'support@thryv.com' ||
              contactName1?.trim()?.toLowerCase() === 'support' ||
              isThryvSupport
                ? 'Thryv Support Center'
                : contactName1 || 'Unknown'}
            </Header>
            <div className=" flex flex-col items-center w-full pt-[24px] justify-center gap-y-[20px]">
              <div className="w-full flex justify-center gap-8">
                {/* CALL BUTTON */}

                {isPhoneActive && (
                  <Link onClick={setOnCall} to="/calls">
                    <button disabled={disablePhoneButton}>
                      <div className="grid h-[38px] w-[38px] content-center justify-center rounded-full bg-[#E3E3E3]">
                        <ContactPhoneButtonIcon enabled={!disablePhoneButton} />
                      </div>
                      <p
                        className={`font-["Open_Sans"] font-normal text-xs mt-1 ${
                          disablePhoneButton && 'opacity-[0.3]'
                        }`}
                      >
                        Call
                      </p>
                    </button>
                  </Link>
                )}

                {/* VIDEO BUTTON */}
                {isVideoActive && (
                  <button
                    onClick={handleClick}
                    disabled={disableVideoButton || isThryvSupport}
                  >
                    <div
                      className={`bg-[#16A085] grid h-[38px] w-[38px] content-center justify-center rounded-full  `}
                    >
                      <TooltipWithCenteredArrow
                        variant="bottom"
                        title="Click the video icon to join the on-going call."
                        disableVisibleOnClick={true}
                        visible={videoCallActive}
                        width="175px"
                      >
                        <ContactFaceTimeButtonIcon
                          enabled={!disableVideoButton}
                          circleFill={
                            videoCallActive
                              ? '#16A085'
                              : isThryvSupport
                                ? '#E8E8EA'
                                : '#F3F5F9'
                          }
                          pathFill={
                            videoCallActive
                              ? '#FFFFFF'
                              : isThryvSupport
                                ? '#B9B9B9'
                                : '#4D4D4D'
                          }
                        />
                      </TooltipWithCenteredArrow>
                    </div>
                    <p
                      className={
                        !disableVideoButton && !isThryvSupport
                          ? 'font-["Open_Sans"] font-normal text-xs mt-1'
                          : 'font-["Open_Sans"] font-normal text-xs mt-1 opacity-[0.3]'
                      }
                    >
                      Video
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>

          <RightPanelUserProfile
            handleEditPhone={handleEditPhone}
            handleEditEmail={handleEditEmail}
            clearEmail={clearEmail}
            clearPhone={clearPhone}
            contact={{ ...contact, threadpk: selectedThreadId.split('#')[0] }}
          />
          <div className="flex flex-col gap-4 justify-end h-full">
            {!isPhoneActive && (
              <Button onClick={openConnectPhoneModal} variant="secondary">
                Add Calls
              </Button>
            )}
            {!isVideoActive && (
              <Button
                onClick={() => {
                  batch(() => {
                    dispatch(setShowModal(true))
                    if (channelInfo?.length >= parseInt(maxChannelsAllowed)) {
                      dispatch(setModalContent(4))
                    } else {
                      dispatch(setModalContent(8))
                    }
                  })
                }}
                variant="secondary"
              >
                Add Video
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

UserProfileAndActivity.displayName = 'UserProfileAndActivity'

export default UserProfileAndActivity
