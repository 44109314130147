import { useSelector, useDispatch } from 'react-redux'

const useRedux = (initalState = '') => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state[`${initalState}`])

  return initalState.length ? [state, dispatch] : [dispatch, useSelector]
}

export default useRedux
