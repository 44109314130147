import { TableFormAlert } from '@thryvlabs/maverick'

const loadingStyle = `before:absolute before:inset-0 
before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/30 before:animate-[shimmer_1.2s_infinite]`

export const UserProfileAndActivityOnLoading = ({ error }) => {
  const renderError = error ? (
    <TableFormAlert variant="danger" alertText={error?.message} />
  ) : null

  return (
    <div>
      {renderError}
      <div
        className={`relative w-full flex flex-col gap-6 border-b-[1px] border-[#E3E6E8] pb-[26px] ${loadingStyle}`}
      >
        <div className="w-2/6 bg-thryv-gray-medium-200 h-4 rounded-md" />
        <div className="flex flex-col w-full justify-center items-center gap-4">
          <div className="w-[80px] h-[80px] bg-thryv-gray-medium-200 rounded-full" />
          <div className="w-full flex flex-col gap-3 justify-center items-center">
            <div className="w-[145px] h-5 bg-thryv-gray-medium-200 rounded-md" />
            <div className="w-[100px] h-4 bg-thryv-gray-medium-200 rounded-md" />
            <div className="w-[191px] h-[30px] bg-thryv-gray-medium-200 rounded-md mt-[44px]" />
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col gap-6 w-full border-b-[1px] border-[#E3E6E8] pb-[26px] mt-[26px] ${loadingStyle}`}
      >
        <div className="flex flex-col gap-4 w-full">
          <div className="w-3/6 bg-thryv-gray-medium-200 h-5 rounded-md" />
          <div className="w-4/6 bg-thryv-gray-medium-200 h-4 rounded-md" />
        </div>
        <div className="flex flex-col gap-4 w-full mt-[20px]">
          <div className="w-3/6 bg-thryv-gray-medium-200 h-5 rounded-md" />
          <div className="w-4/6 bg-thryv-gray-medium-200 h-4 rounded-md" />
        </div>
        <div className="flex flex-col gap-4 w-full mt-[20px]">
          <div className="w-3/6 bg-thryv-gray-medium-200 h-5 rounded-md" />
          <div className="w-4/6 bg-thryv-gray-medium-200 h-4 rounded-md" />
        </div>
      </div>
    </div>
  )
}
