import { gql } from '@apollo/client'

export const UPDATE_RECENT_CALL = gql`
  mutation (
    $id: ID!
    $time: String
    $date: String
    $location: String
    $username: String
    $email: String
    $phone: String
    $type: String
    $callDeleted: Boolean
    $voicemail: String
    $voicemailDeleted: Boolean
    $voiceMailPlayed: Boolean
    $transcript: String
  ) {
    updateRecentCall(
      id: $id
      time: $time
      date: $date
      location: $location
      username: $username
      email: $email
      phone: $phone
      type: $type
      callDeleted: $callDeleted
      voicemail: $voicemail
      voicemailDeleted: $voicemailDeleted
      voiceMailPlayed: $voiceMailPlayed
      transcript: $transcript
    ) {
      id
      time
      date
      location
      username
      email
      phone
      type
      callDeleted
      voicemail
      voicemailDeleted
      voiceMailPlayed
      transcript
    }
  }
`

export const MARK_RECENT_CALL_OR_VOICEMAIL_AS_READ = gql`
  mutation ($pk1: ID!, $sk1: Long!, $is_read: Boolean) {
    updateVonageIsRead(pk1: $pk1, sk1: $sk1, is_read: $is_read) {
      pk1
      sk1
    }
  }
`

export const UPLOAD_VOICEMAIL_GREETING_MUTATION = gql`
  mutation UploadVoicemailGreeting($input: VoicemailGreetingInput!) {
    uploadVoicemailGreeting(input: $input) {
      url
    }
  }
`

export const DELETE_VOICEMAIL_GREETING_MUTATION = gql`
  mutation deleteVoicemailGreeting($number: String!) {
    deleteVoicemailGreeting(number: $number)
  }
`
