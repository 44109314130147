import navigationBarValidator from './navigationBarValidator'
import { DesktopNavigation } from './desktop-navigation'
import { MobileNavigation } from './mobile-navigation'

/*
#######################
##     IMPORTANT     ##
#######################

Make sure to nest all sections of the nav bar
within the component.

Documentation: https://tinyurl.com/2p8tba4f

*/

export const CommandCenterNavigation = ({
  variant, // menu & tabs
  desktopNavClassName,
  mobileNavClassName,
  wrapperClassName,
  linksArray,
  selected,
  setSelectedOption,
  children,
}) => {
  navigationBarValidator.validateVariant(variant)

  return (
    <>
      <DesktopNavigation
        className={`${desktopNavClassName} 
        ${variant === 'menu' ? 'md:hidden' : ''}
        ${variant == 'tabs' ? 'md:justify-between' : ''}`}
        linksArray={linksArray}
        selected={selected}
        setSelectedOption={setSelectedOption}
      />
      <div className={`${wrapperClassName} relative flex flex-col`}>
        {variant === 'menu' ? (
          <MobileNavigation
            className={`${mobileNavClassName} min-w-md:hidden`}
            linksArray={linksArray}
            selected={selected}
            setSelectedOption={setSelectedOption}
          />
        ) : null}

        {children}
      </div>
    </>
  )
}
