import { useSelector } from 'react-redux'
import usePhoneChannel from '../../../hooks/use-phone-channel'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_BRAND_CAMPAIGN_BY_CHANNEL_ID,
  GET_CONNECTED_CHANNEL_BY_ID,
  LINK_PHONE_NUMBER_CALL,
  UPDATE_CHANNEL_READINESS_CALL,
} from '../../../graphql'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setCardDataConnectError,
  setCardDataConnectWarning,
  setChanelInfo,
} from '../../common/modals/slices/connectChannelsSlice'
import {
  setBrand,
  setLoading,
} from '../../common/modals/slices/phoneRegistrationSlice'
import useCardData from './useCardData'
import { cloneDeep } from '@apollo/client/utilities'
import { useFlags } from 'launchdarkly-react-client-sdk'
const usePhoneRegistrationCalls = () => {
  const [phoneChannel, phoneChannelIndex] = usePhoneChannel()
  const dispatch = useDispatch()
  const brandID = useSelector((state) => state.phoneRegistration.brand_id)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const businessName = useSelector((state) => state.businessInfo.location.name)
  const channelInfo = useSelector((state) => state.connectChannels.channelInfo)
  const { getPhoneCard } = useCardData()
  const [, phoneCardIndex] = getPhoneCard()
  const [linkPhoneNumber] = useMutation(LINK_PHONE_NUMBER_CALL)
  const [updateChannelReadiness] = useMutation(UPDATE_CHANNEL_READINESS_CALL, {
    onCompleted: (data) => {
      const newPhoneChannelData = data?.updateChannelReadiness
      const updatedChannelInfo = cloneDeep(channelInfo)

      if (!Array.isArray(updatedChannelInfo) || phoneChannelIndex === -1) return

      updatedChannelInfo[phoneChannelIndex] = newPhoneChannelData
      dispatch(setChanelInfo(updatedChannelInfo))

      if (newPhoneChannelData?.ChannelAccessLevel === '2') {
        dispatch(
          setCardDataConnectError({
            channelClickedID: phoneCardIndex,
            connectError: false,
          }),
        )
        dispatch(
          setCardDataConnectWarning({
            channelClickedID: phoneCardIndex,
            connectWarning: false,
          }),
        )
      }
    },
  })
  const [getBrandCampaign] = useLazyQuery(GET_BRAND_CAMPAIGN_BY_CHANNEL_ID)
  const [getConnectedChannel] = useLazyQuery(GET_CONNECTED_CHANNEL_BY_ID)

  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  useEffect(() => {
    const requiredConditionals =
      phoneChannel?.ChannelAccessLevel === '1' && phoneChannel?.ChannelID

    if (countryISO === 'US') {
      if (requiredConditionals) {
        dispatch(setLoading(true))
        getBrandCampaign({
          variables: { channel_id: phoneChannel.ChannelID },
          onError: () => {
            dispatch(setLoading(false))
          },
          onCompleted: (data) => {
            dispatch(setLoading(false))

            const brandStatus =
              data?.queryBrandCampaignByChannelID?.at(0)?.brand_status
            const campaignStatus =
              data?.queryBrandCampaignByChannelID?.at(0)?.campaign_status
            const linkStatus =
              data?.queryBrandCampaignByChannelID?.at(0)?.link_status

            if (brandStatus) {
              dispatch(setBrand(data))

              dispatch(
                setCardDataConnectError({
                  channelClickedID: phoneCardIndex,
                  connectError:
                    brandStatus === 'UNVERIFIED' ||
                    campaignStatus === 'UPDATES_REQUIRED' ||
                    campaignStatus === 'TERMINATED',
                }),
              )

              dispatch(
                setCardDataConnectWarning({
                  channelClickedID: phoneCardIndex,
                  connectWarning: isActive
                    ? brandStatus === 'PENDING' ||
                      brandStatus === 'ACTIVE' ||
                      campaignStatus === 'PENDING_REVIEW'
                    : false,
                }),
              )
            }

            if (campaignStatus === 'ACTIVE') {
              getConnectedChannel({
                variables: { channel_id: phoneChannel.ChannelID },
                onCompleted: (data) => {
                  const phoneNumber =
                    data?.queryConnectedChannelByID?.AccountUserValue

                  linkPhoneNumber({
                    variables: {
                      channel_id: phoneChannel.ChannelID,
                      phoneNumber: phoneNumber,
                    },
                  })
                },
              })
            }

            if (linkStatus === 'APPROVED' || linkStatus === 'LINKED') {
              updateChannelReadiness({
                variables: {
                  channel_id: phoneChannel.ChannelID,
                  access_level: 2,
                  sync_state: 0,
                },
              })
            }

            if (brandID === null) {
              dispatch(
                setCardDataConnectWarning({
                  channelClickedID: phoneCardIndex,
                  connectWarning: isActive
                    ? brandStatus === 'PENDING' ||
                      brandStatus === 'ACTIVE' ||
                      campaignStatus === 'PENDING_REVIEW'
                    : false,
                }),
              )
            }
          },
        })
      }
    } else if (countryISO === 'AU') {
      if (requiredConditionals && !businessName) {
        dispatch(
          setCardDataConnectError({
            channelClickedID: phoneCardIndex,
            connectError: true,
          }),
        )
      } else if (requiredConditionals && businessName) {
        updateChannelReadiness({
          variables: {
            channel_id: phoneChannel.ChannelID,
            access_level: 2,
            sync_state: 0,
          },
        })
      }
    }
  }, [phoneChannel?.ChannelAccessLevel, phoneChannel?.ChannelID, businessName])
}

export default usePhoneRegistrationCalls
