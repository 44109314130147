export const setMask = (countryCode, phoneNumber) => {
  switch (countryCode) {
    case 'US':
      return '(###) ###-####'
    case 'CA':
      return '(###) ###-####'
    case 'AU':
      if (
        phoneNumber[1] === '4' ||
        phoneNumber[1] === '5'
        // checks if mobile numbber and formats accordingly
      ) {
        return '#### ### ###'
      } else {
        return '(##) #### ####'
      }

    case 'NZ':
      return '(##) ###-####'
  }
  return '(###) ###-####'
}
