const getChannelIconProps = (variant) => {
  switch (variant) {
    case 'gmail':
      return { variant: 'gmail', type: 'solid' }
    case 'outlook':
      return { variant: 'outlook', type: 'solid' }
    case 'outlook365':
        return { variant: 'outlook', type: 'solid' }
    case 'instagram':
      return { variant: 'instagram', type: 'solid' }
    case 'yahoo':
      return { variant: 'yahoo', type: 'solid' }
    case 'facebook':
      return { variant: 'facebookMessenger', type: 'solid' }
    case 'hotmail':
      return { variant: 'hotmail', type: 'solid' }
    case 'phone':
      return { variant: 'calls', type: 'regular' }
    case 'imap':
      return { variant: 'imap', type: 'solid' }
    case 'ttl':
      return { variant: 'ttl', type: 'regular' }
    case 'webchat':
      return { variant: 'webchat', type: 'solid' }
    case 'icloud':
      return { variant: 'icloud', type: 'solid' }
    default:
      return { variant, type: 'solid' }
    case 'incomingCall':
      return { variant: 'calls', type: 'regular' }
    case 'missedCall':
      return { variant: 'calls', type: 'regular' }
    case 'outgoingCall':
      return { variant: 'calls', type: 'regular' }
    case 'voicemail':
      return { variant: 'calls', type: 'regular' }
  }
}

export default getChannelIconProps
