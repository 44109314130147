import { Button } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../components/command-center-icon/command-center-icon'

const SavedPayment = ({ method, onEdit, onDelete }) => {
  return (
    <div className="flex sm:flex-col sm:p-[1rem] my-[1rem] justify-between items-center w-full sm:h-[100px] h-[62px] border-[1px] border-[#EFEFEF] ">
      <div className="flex sm:justify-between sm:w-full items-center sm:ml-[0rem] ml-[1rem]">
        <CommandCenterIcon
          width={40}
          height={30}
          type="solid"
          variant={`${method.CC_TYPE == 'Visa' ? 'visa' : 'masterCard'}`}
        />
        <span className="text-[12px]">
          {`${method.CC_TYPE} ending in ${method.CC_LAST_4}`}{' '}
        </span>
        {method.PRIMARY_PAYMENT_METHOD && (
          <span className="bg-[#5378FC] font-semibold pl-[1rem] pr-[1rem] pt-[0.3rem] pb-[0.3rem] ml-[10px] rounded-[1rem] text-[12px] text-[#fff]">
            DEFAULT
          </span>
        )}
      </div>
      <div className="flex sm:justify-between sm:w-full items-center sm:ml-[0rem] ml-[1rem]">
        <span className="bg-[#18a085] font-semibold pl-[1rem] pr-[1rem] pt-[0.3rem] pb-[0.3rem] mr-[10px] rounded-[1rem] text-[12px] text-[#fff]">
          ACTIVE
        </span>
        <span className="overflow-hidden whitespace-nowrap text-sm text-[#057AFF] mr-[1rem]">
          <Button variant="text" onClick={onEdit} level={1}>
            Edit
          </Button>{' '}
          |{' '}
          <Button variant="text" onClick={onDelete} level={1}>
            Delete
          </Button>
        </span>
      </div>
    </div>
  )
}

export default SavedPayment
