import { useMediaQuery } from 'react-responsive'
import Cookies from 'js-cookie'

// Components
import { MobileInboxMenuSearchBar } from './mobile-inbox-menu-search-bar'
import { DesktopInboxMenuSearchBar } from './desktop-inbox-menu-search-bar'

if (!Cookies.get('recent_inbox_searches')) {
  Cookies.set('recent_inbox_searches', JSON.stringify([]))
}

export const InboxMenuSearchBar = () => {
  const isMdScreen = useMediaQuery({
    query: '(max-width: 834px)',
  })

  return (
    <>
      {isMdScreen && <MobileInboxMenuSearchBar />}
      {!isMdScreen && <DesktopInboxMenuSearchBar />}
    </>
  )
}
