import { useState } from 'react'
import { Avatar, ParagraphText, Button, Icon } from '@thryvlabs/maverick'

// Used in both components
const PermissionDropdown = ({
  setShowDropDown,
  setSelectedPermission,
  className,
}) => {
  const buttons = ['Read/write', 'Read only', 'No access']

  const handleClick = (btnText) => {
    setShowDropDown(false)
    setSelectedPermission(btnText)
  }

  return (
    <div
      className={`${className} flex flex-col mt-1 shadow-md rounded-sm max-w-[100px]`}
    >
      {buttons.map((btn, idx) => {
        return (
          <button
            key={idx}
            className="w-[100px] flex bg-white text-center rounded-sm items-center py-2 px-5 hover:bg-thryv-white-300"
            onClick={() => handleClick(btn)}
          >
            <ParagraphText variant="sm">{btn}</ParagraphText>
          </button>
        )
      })}
    </div>
  )
}

const PermissionUserMobile = ({ user }) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState('Read/write')

  return (
    <div className="flex flex-col w-full border-b-[2px] border-thryv-white-300 p-2">
      <div className="flex items-center justify-between w-full bg-[#F8F9FB] border-b border-thryv-white-300">
        <div className="flex items-center gap-2">
          <Avatar variant="name" size="small" className="p-4" name={user} />
          <ParagraphText variant="reg" className="capitalize">
            {user.firstName} {user.lastName}
          </ParagraphText>
        </div>
        <div className="relative">
          <Button
            variant="secondary"
            className="w-[100px] rounded-md flex items-center justify-evenly gap-1" // Adjust width as needed
            onClick={() => setShowDropDown(!showDropDown)}
          >
            <ParagraphText variant="reg">{selectedPermission}</ParagraphText>
            <Icon
              variant="caretDown"
              type="solid"
              width="11"
              className={`${
                showDropDown ? 'rotate-180' : null
              } transition duration-300`}
            />
          </Button>
          {showDropDown ? (
            <PermissionDropdown
              className="absolute right-[-10] top-[-10] z-[1000]"
              setSelectedPermission={setSelectedPermission}
              setShowDropDown={setShowDropDown}
            />
          ) : null}
        </div>
      </div>
      <div className="flex justify-between w-full pt-2">
        <ParagraphText variant="reg" color="thryv-steel">
          Email
        </ParagraphText>
        <ParagraphText variant="reg" color="thryv-steel">
          {user.email}
        </ParagraphText>
      </div>
    </div>
  )
}

export const UserPermissionsMobileTable = ({ staffMembers }) => {
  return (
    <>
      <div
        id="table-header"
        className="flex items-center justify-between w-full border-b-[2px] border-thryv-white-300"
      ></div>

      {staffMembers.map((member, idx) => {
        const emailTruncated =
          member.Email.length > 30 ? member.Email.slice(0, 30) : member.Email
        const user = {
          firstName: member.FirstName,
          lastName: member.LastName,
          email: emailTruncated,
        }
        return <PermissionUserMobile key={idx} user={user} />
      })}
    </>
  )
}
