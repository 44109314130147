import { Header, ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../command-center-icon'

export const NoResultsFoundSection = () => {
  return (
    <div className="!h-0 min-h-full bg-[#F8F9FB]">
      <div className="relative text-center top-[25%]">
        <CommandCenterIcon
          type="solid"
          variant="noSearchResultsIcon"
          className="mx-auto mb-8"
        />

        <Header
          fontWeight="semibold"
          variant="h4"
          className="normal-case leading-[30px] mb-4"
        >
          No matching search results
        </Header>

        <ParagraphText variant="reg" color="thryv-black">
          Try adjusting your search or using filter to find what you&apos;re looking
          for.
        </ParagraphText>
      </div>

      <div className="h-1/2"></div>
    </div>
  )
}
