import { createSlice } from '@reduxjs/toolkit'

export const remindMeSlice = createSlice({
  name: 'remindMe',
  initialState: {
    notifications: [],
    customReminder: [],
    showReminderNotification: false,
    showCustomNotification: false,
    reminderText: '',
    reminderTimeInMilliseconds: 0,
    customReminderInMilliseconds: 0,
    notificationSet: false,
    updatedReminderTime: '',
    onCompletedCustomReminderSk: '',
    isSameDate: null,

  },
  reducers: {
    setNotifications(state, action) {
      const { id, text, customDate, customTime, isToday } = action.payload
      const index = state.notifications.findIndex((item) => item.id === id)
      if (index >= 0) {
        let updatedItem = { ...state.notifications[index] }
        if (text !== undefined) {
          updatedItem = { ...updatedItem, text }
          delete updatedItem.customDate
          delete updatedItem.customTime
        } else if (customDate !== undefined && customTime !== undefined) {
          updatedItem = { ...updatedItem, customDate, customTime }
          delete updatedItem.time
        } else if (isToday !== null) {
          updatedItem = { ...updatedItem, isToday}
        }
        const newNotifications = [
          ...state.notifications.slice(0, index),
          updatedItem,
          ...state.notifications.slice(index + 1),
        ]
        return { ...state, notifications: newNotifications }
      } else {
        const newNotifications = [...state.notifications, action.payload]
        return { ...state, notifications: newNotifications }
      }
    },
    setRemoveNotifications(state, action) {
      const idToDelete = action.payload;
      const updatedNotifications = state.notifications.filter((item) => item.id !== idToDelete);
      return { ...state, notifications: updatedNotifications };
   },
    setCustomReminderNotification(state, action) {
      const { id, customDate, customTime } = action.payload
      state.customReminder = { id, customDate, customTime }
    },
    setShowReminderNotification(state, action) {
      state.showReminderNotification = action.payload
    },
    setShowCustomNotification(state, action) {
      state.showCustomNotification = action.payload
    },
    removeNotification(state, action) {
      const { id } = action.payload
      state.notifications = state.notifications.filter((n) => n.id !== id)
    },
    setCustomReminderInMilliseconds(state, action) {
      state.customReminderInMilliseconds = action.payload
    },
    setReminderTimeInMilliseconds(state, action) {
      state.reminderTimeInMilliseconds = action.payload
    },
    setNotificationSet(state, action) {
      state.notificationSet = action.payload
    },
    setOnCompletedCustomReminderSk(state, action) {
      state.onCompletedCustomReminderSk = action.payload
    },
    setIsSameDate(state, action) {
      state.isSameDate = action.payload
    },
    setReminderText(state, action) {
      state.reminderText = action.payload
    }

  },
})

export const {
  setNotifications,
  setCustomReminder,
  setCustomReminderNotification,
  setShowCustomNotification,
  removeNotification,
  setCustomReminderInMilliseconds,
  setReminderTimeInMilliseconds,
  setNotificationSet,
  setRemoveNotifications,
  setOnCompletedCustomReminderSk,
  setIsSameDate,
  setIsSameDatePersisted,
  setShowReminderNotification,
  setReminderText
} = remindMeSlice.actions

export default remindMeSlice.reducer
