import { Header } from '@thryvlabs/maverick'
import { AllSharedPhotos } from './AllSharedPhotos'
import useSharedMediaProps from './useSharedMediaProps'

const SeeAllSharedPhotos = ({ setShowAllPhotos, attachmentData }) => {
  const { allSharedPhotosProps } = useSharedMediaProps()

  // Render only if there are recent attachments
  return (
    <>
      {attachmentData.length > 0 && (
        <div className="grid grid-cols-2 gap-4 w-[310px]">
          {/* Header */}
          <Header fontWeight="semibold" variant="h7">
            Shared Photos
          </Header>
          <button className="ml-[85px]" onClick={() => setShowAllPhotos(false)}>
            <p className="text-[#057AFF] text-sm" fontWeight="semibold">
              Go Back
            </p>
          </button>
        </div>
      )}
      <AllSharedPhotos {...allSharedPhotosProps} />
    </>
  )
}

export default SeeAllSharedPhotos
