import { useDispatch } from 'react-redux'
import { setShowModal } from '../common/modals/slices/connectChannelsSlice'
import {
  LogoContainer,
  ParagraphTextStyledSmaller,
  ParagraphTextStyled,
  BottomBtn,
  CommandCenterIconResponsive,
} from './WelcomeScreen.styles'

const BottomModalButton = ({ IconName, ButtonText, IconType }) => {
  const dispatch = useDispatch()

  return (
    <BottomBtn onClick={() => dispatch(setShowModal(true))} variant="text" level={2}>
      <LogoContainer className='cursor-pointer'>
        <CommandCenterIconResponsive type={IconType} variant={IconName} />
      </LogoContainer>
      {IconName === 'ttl' || IconName === 'googleMessenger' ? (
        <ParagraphTextStyled className='cursor-pointer' variant="reg"> {ButtonText} </ParagraphTextStyled>
      ) : (
        <ParagraphTextStyledSmaller variant="reg"className='cursor-pointer' >
          {ButtonText}{' '}
        </ParagraphTextStyledSmaller>
      )}
    </BottomBtn>
  )
}

export default BottomModalButton
