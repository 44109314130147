import { gql } from '@apollo/client'

export const GET_ACTIVE_VIDEO_ROOM = gql`
  query get_active_video_room($threadId: ID!) {
    getActiveVideoRoomByThreadId(threadId: $threadId) {
      CC_UserID
      RoomID
      ThreadID
      active
      created_at
      display_name
      expired_at
      guest_url
      host_url
      meeting_code
      type
    }
  }
`
