export const ausStates = [
  {
    name: 'Australian Capital Territory',
    value: 'AC',
  },
  {
    name: 'New South Wales',
    value: 'NS',
  },
  {
    name: 'Northern Territory',
    value: 'NT',
  },
  {
    name: 'Queensland',
    value: 'QL',
  },
  {
    name: 'South Australia',
    value: 'SA',
  },
  {
    name: 'Tasmania',
    value: 'TA',
  },
  {
    name: 'Victoria',
    value: 'VI',
  },
  {
    name: 'Western Australia',
    value: 'WA',
  },
]
