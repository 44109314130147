import * as yup from 'yup'

const phoneRegExp = /^\d{10}$/
const einRegExp = /\d{2}-\d{7}/g
const httpRegEx = /^(http:|https:)\/\/.*$/m

const formSchema = yup.object().shape({
  businessType: yup.string().required('Please select a Business Type'),

  businessName: yup
    .string()
    .min(3, 'Must be at least 3 characters')
    .required('Business Name is a required field'),

  firstName: yup
    .string()
    .min(2, 'First Name must be at least 2 characters')
    .max(128, 'First Name cannot be longer than 128 characters')
    .required(),

  lastName: yup
    .string()
    .min(2, 'Last Name must be at least 2 characters')
    .max(128, 'Last Name cannot be longer than 128 characters')
    .required(),

  dba: yup
    .string()
    .max(27, 'DBA must be 27 characters or less')
    .required('DBA is a required field'),

  ein: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .string()
        .matches(einRegExp, 'EIN must be 9 numbers and follow format XX-XXXXXXX')
        .required()
    }

    return yup.mixed().notRequired()
  }),

  verticalType: yup.string().required('Please select a Vertical Type'),

  stockSymbol: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Stock Symbols is a required field')
    }

    return yup.mixed().notRequired()
  }),

  stockExchange: yup.lazy((value) => {
    if (value !== undefined) {
      return yup.string().required('Please select a Stock Exchange')
    }

    return yup.mixed().notRequired()
  }),

  address: yup.string().required('Address is a required field'),

  city: yup.string().required('City is a required field'),

  state: yup.string().required('Please select a State'),

  postal: yup
    .string()
    .matches(/^\d+$/, 'Postal Code must be only numbers')
    .required('Postal Code is a required field'),

  businessEmail: yup
    .string()
    .email('Business Email must be a valid email address')
    .required('Business Email is a required field'),

  countryCode: yup.string().required(),

  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone Number is invalid')
    .required(),

  supportType: yup.string().required('Please select a Support Type'),

  supportContact: yup
    .string()
    .max(250, 'Support Contact cannot be longer than 250 characters')
    .required('Support Contact is a required field'),

  businessPrivacyPolicy: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .string()
        .max(250, 'Privacy Policy URL cannot be longer than 250 characters')
        .required('Privacy Policy URL is a required field')
        .test(
          'checkUrlPrefix',
          'Please include "http://" or "https://" in your Privacy Policy URL',
          () => httpRegEx.test(value),
        )
    }

    return yup.mixed().notRequired()
  }),

  businessTC: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .string()
        .max(250, 'Terms & Conditions URL cannot be longer than 250 characters')
        .required('Terms & Conditions URL is a required field')
        .test(
          'checkUrlPrefix',
          'Please include "http://" or "https://" in your Terms & Conditions URL',
          () => httpRegEx.test(value),
        )
    }

    return yup.mixed().notRequired()
  }),

  campaignType: yup.string().required('Please select an Opt-in Method'),

  campaignDescription: yup
    .string()
    .required('Please enter a description of your Opt-in Method'),

  ctaImage: yup.lazy((value) =>
    yup
      .string()
      .nullable()
      .when('campaignType', {
        is: (val) => val === 'TEXT_TO_JOIN',
        then: (schema) =>
          schema
            .nullable()
            .notRequired()
            .test(
              'checkUrlPrefix',
              'Please include "http://" or "https://" in your CTA Image URL',
              () => {
                if (value === '') {
                  return true
                }

                return httpRegEx.test(value)
              },
            ),
        otherwise: (schema) =>
          schema
            .nullable()
            .required(
              'An image URL is required. Please provide a link to an image showing where your clients opt in to receive SMS messages.',
            )
            .test(
              'checkUrlPrefix',
              'Please include "http://" or "https://" in your CTA Image URL',
              () => httpRegEx.test(value),
            ),
      }),
  ),
})

export default formSchema
