import Key from './key'
import { keys } from './data.json'

const Keypad = ({
  renderKeys,
  onChange,
  setShowKeypad,
  sourceIsWidget,
  setEndCall,
  callAllowed,
}) => {
  const generateKeyPad = keys.map((key) => {
    const { chars, id, number, symbol, type } = key
    if (number.length || chars.length) {
      return (
        <Key
          key={id}
          number={number}
          chars={chars}
          type={type}
          onChange={onChange}
          setShowKeypad={setShowKeypad}
          sourceIsWidget={sourceIsWidget}
          setEndCall={setEndCall}
          callAllowed={callAllowed}
        />
      )
    } else if (symbol.length) {
      return (
        <Key
          key={id}
          symbol={symbol}
          type={type}
          onChange={onChange}
          setShowKeypad={setShowKeypad}
          sourceIsWidget={sourceIsWidget}
          setEndCall={setEndCall}
          callAllowed={callAllowed}
        />
      )
    } else {
      return (
        <Key
          key={id}
          type={type}
          onChange={onChange}
          setShowKeypad={setShowKeypad}
          sourceIsWidget={sourceIsWidget}
          setEndCall={setEndCall}
          callAllowed={callAllowed}
        />
      )
    }
  })

  return (
    <div
      className={`${renderKeys} w-full grid grid-cols-3 grid-rows-5 gap-x-[30px] gap-y-4 pb-[11px] place-items-center`}
    >
      {generateKeyPad}
    </div>
  )
}

export default Keypad
