import { Button, ModalWrapper, RegularX } from '@thryvlabs/maverick'
import {
  HeaderContainer,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from './PaymentFailModal.style'
import { CommandCenterIcon } from '../../../command-center-icon/command-center-icon'

export default function PaymentFailModal({ showModal, setShowModal }) {
  return (
    <ModalWrapper
      show={showModal}
      disableBackdropClick={false}
      close={() => setShowModal(false)}
    >
      <ModalContainer>
        <HeaderContainer>
          <Button variant="text" level={2}>
            {' '}
            <RegularX
              type="regular"
              variant="x"
              height="18"
              fill="#A3A5A7"
              onClick={() => setShowModal(false)}
            />
          </Button>
        </HeaderContainer>
        <ModalContent className="content h-[90%]">
          <CommandCenterIcon
            type="solid"
            variant={'CCPaymentFail'}
            width={121}
            height={120}
          />

          <div className="flex flex-col pt-[25px] items-center">
            <ModalHeader>Payment didn’t go through</ModalHeader>
            <p>Your transaction has failed due to some technical error.</p>
            <p>Please try again.</p>
          </div>

          <div className="flex justify-center last-btn">
            <Button variant="primary" className=" w-[108px]" onClick={() => close()}>
              Try Again
            </Button>
          </div>
        </ModalContent>
      </ModalContainer>
    </ModalWrapper>
  )
}
