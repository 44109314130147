/* eslint-disable no-unused-vars */
import { Button, Header, Icon } from '@thryvlabs/maverick'
import { ParagraphText as Text } from '@thryvlabs/maverick'
import { useEffect, useState, useRef } from 'react'
import Toggle from '../../../components/common/toggle/Toggle'
import { useDispatch, useSelector } from 'react-redux'

import { GET_CALLS_MINUTES, GET_RECURLY_PLAN_TYPE } from '../../../graphql'
import { useQuery, useMutation } from '@apollo/client'
import { secondsToMinutesConverter } from '../../../components/calls/add-minutes/SecondsToMinutesConverter'
import {
  CC_RECURLY_PLAN_CODES,
  CC_RECURLY_PLAN_TYPE,
} from '../../../utils/recurly-constants'
import { UPDATE_USER_PREFERENCES } from '../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import {
  setDesktopNotificationPreferences,
  setMobileNotificationPreferences,
  setDoNotDisturbPreferences,
} from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { setUserPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { VoicemailSettings } from './VoicemailSettings'

export const GeneralCallsSettings = ({ setOpenAddMoreMinutes, isMobile }) => {
  const [minutes, setMinutes] = useState([])
  const [activePlan, setActivePlan] = useState()
  const { user } = useAuth0()
  const { role } = useSelector((state) => state.user)
  const userRole = role || user?.cc_role
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  const { doNotDisturb } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )
  const { desktop: desktopNotifications, mobile: mobileNotifications } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )
  const preferenceData = useSelector((state) => state.userPreferences)
  const targetLabel = 'Calls'
  const incomingCallNotificationDesktopTargetIndex = desktopNotifications?.findIndex(
    (item) => item.label === targetLabel,
  )
  const incomingCallNotificationDesktop =
    desktopNotifications?.[incomingCallNotificationDesktopTargetIndex]?.value
  const incomingCallNotificationMobileTargetIndex = mobileNotifications?.findIndex(
    (item) => item.label === targetLabel,
  )
  const incomingCallNotificationMobile =
    mobileNotifications?.[incomingCallNotificationMobileTargetIndex]?.value
  const { data: callsMinutes } = useQuery(GET_CALLS_MINUTES, {
    onCompleted: () => {
      setMinutes(callsMinutes.queryCallsMinutesByCCID)
    },
  })
  const upgradeLine = [
    <b key={Math.random()} className="text-thryv-night">
      Upgrade
    </b>,
    ' your plan to add more minutes.',
  ]

  const { data: planType } = useQuery(GET_RECURLY_PLAN_TYPE, {
    variables: { CCID: localStorage.getItem('cc_id') },
    onCompleted: () => {
      let selectedPlanCode = planType.queryRecurlyAccountInfo.activePlanCode

      const selectedPlanType = Object.keys(CC_RECURLY_PLAN_CODES).reduce(
        (acc, key) => {
          if (CC_RECURLY_PLAN_CODES[key] === selectedPlanCode) {
            return key
          }
          return acc
        },
        null,
      )

      setActivePlan(CC_RECURLY_PLAN_TYPE[selectedPlanType])
    },
  })

  const [selectedConversationDesktop, setSelectedConversationDesktop] = useState(
    preferenceData.notificationPreferences.selectedConversationDesktop,
  )
  const [selectedTeamChatDesktop, setSelectedTeamChatDesktop] = useState(
    preferenceData.notificationPreferences.selectedTeamChatDesktop,
  )
  const [selectedConversationMobile, setSelectedConversationMobile] = useState(
    preferenceData.notificationPreferences.selectedConversationMobile,
  )
  const [selectedTeamChatMobile, setSelectedTeamChatMobile] = useState(
    preferenceData.notificationPreferences.selectedTeamChatMobile,
  )

  const [updateUserPreferences, { data, loading, error }] = useMutation(
    UPDATE_USER_PREFERENCES,
  )
  const updatePreferences = async (updatedPreferences) => {
    try {
      await updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedPreferences,
          }),
        },
      })
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      const updatedConfig = {
        navigationPreferences: preferenceData.navigationPreferences,
        navigationCollapsed: preferenceData.navigationCollapsed,
        notificationPreferences: {
          doNotDisturb: doNotDisturb,
          desktop: desktopNotifications,
          mobile: mobileNotifications,
          selectedConversationMobile,
          selectedConversationDesktop,
          selectedTeamChatDesktop,
          selectedTeamChatMobile,
        },
      }
      updatePreferences(updatedConfig)
      dispatch(setUserPreferences(updatedConfig))
    }
    isMounted.current = true
  }, [
    doNotDisturb,
    desktopNotifications,
    mobileNotifications,
    selectedConversationDesktop,
    selectedTeamChatDesktop,
    selectedConversationMobile,
    selectedTeamChatMobile,
  ])

  const handleDndToggle = () => {
    let notificationsListDesktop = JSON.parse(JSON.stringify(desktopNotifications))
    let notificationsListMobile = JSON.parse(JSON.stringify(mobileNotifications))
    const desktopTargetIndex = notificationsListDesktop?.findIndex(
      (item) => item.label === targetLabel,
    )
    const mobileTargetIndex = notificationsListMobile?.findIndex(
      (item) => item.label === targetLabel,
    )

    if (desktopTargetIndex !== -1 && mobileTargetIndex !== -1) {
      // toggle from off to on
      if (!doNotDisturb) {
        // disable mobile and web calls when do not disturb are on
        notificationsListDesktop[desktopTargetIndex].value = false
        notificationsListMobile[mobileTargetIndex].value = false
      } else {
        // toggle dnd from on to off, only perform actions when both mobile and web calls are disabled
        if (
          !notificationsListDesktop[desktopTargetIndex].value &&
          !notificationsListMobile[mobileTargetIndex].value
        ) {
          // enable both mobile and web calls
          notificationsListDesktop[desktopTargetIndex].value = true
          notificationsListMobile[mobileTargetIndex].value = true
        }
      }
      dispatch(setDesktopNotificationPreferences([...notificationsListDesktop]))
      dispatch(setMobileNotificationPreferences([...notificationsListMobile]))
      dispatch(setDoNotDisturbPreferences(!doNotDisturb))
    } else {
      // eslint-disable-next-line no-console
      console.error("can't update user preferences")
    }
  }

  const handleWebToggleChange = () => {
    let notificationsListDesktop = JSON.parse(JSON.stringify(desktopNotifications))
    const desktopTargetIndex = notificationsListDesktop?.findIndex(
      (item) => item.label === targetLabel,
    )
    notificationsListDesktop[desktopTargetIndex].value =
      !notificationsListDesktop[desktopTargetIndex].value

    let notificationsListMobile = JSON.parse(JSON.stringify(mobileNotifications))
    const mobileTargetIndex = notificationsListMobile?.findIndex(
      (item) => item.label === targetLabel,
    )
    if (
      notificationsListDesktop[desktopTargetIndex].value ||
      notificationsListMobile[mobileTargetIndex].value
    ) {
      dispatch(setDoNotDisturbPreferences(false))
    }
    if (
      !notificationsListDesktop[desktopTargetIndex].value &&
      !notificationsListMobile[mobileTargetIndex].value
    ) {
      dispatch(setDoNotDisturbPreferences(true))
    }
    dispatch(setDesktopNotificationPreferences([...notificationsListDesktop]))
  }

  const handleMobileToggleChange = () => {
    let notificationsListMobile = JSON.parse(JSON.stringify(mobileNotifications))
    const mobileTargetIndex = notificationsListMobile?.findIndex(
      (item) => item.label === targetLabel,
    )
    notificationsListMobile[mobileTargetIndex].value =
      !notificationsListMobile[mobileTargetIndex].value
    let notificationsListDesktop = JSON.parse(JSON.stringify(desktopNotifications))
    const desktopTargetIndex = notificationsListDesktop?.findIndex(
      (item) => item.label === targetLabel,
    )
    if (
      notificationsListDesktop[desktopTargetIndex].value ||
      notificationsListMobile[mobileTargetIndex].value
    ) {
      dispatch(setDoNotDisturbPreferences(false))
    }
    if (
      !notificationsListDesktop[desktopTargetIndex].value &&
      !notificationsListMobile[mobileTargetIndex].value
    ) {
      dispatch(setDoNotDisturbPreferences(true))
    }
    dispatch(setMobileNotificationPreferences([...notificationsListMobile]))
  }

  // NOTE: Mobile is being rendered here, instead of having a separate
  // MobileGeneralCallsSettings.jsx file, for the DND/Web/Mobile toggle logic
  const renderMobile = () => {
    return (
      <div className="pt-[14px] md:w-auto sm:w-[370px]">
        <div className="h-[345px] w-full bg-white z-10 rounded-[3px] border border-[#ECEEF1] drop-shadow-md justify-center">
          <div className="pl-[24px] pt-[24px]">
            <Header fontWeight="semibold" variant="h6">
              General
            </Header>
            <div className="pt-[19px] h-[180px] w-full">
              <Header fontWeight="semibold" variant="h7">
                Outgoing Calls
              </Header>
              <Text variant="reg" color="thryv-steel" className="pt-[8px]">
                {upgradeLine}
              </Text>
              <div className="flex flex-row pt-[16px] justify-between">
                <div className="grid grid-row gap-y-[8px]">
                  <Text variant="reg">
                    Plan Type: <b>{activePlan} (Monthly)</b>
                  </Text>
                  <Text variant="reg">
                    Time allocated:{' '}
                    <b data-test-id="time-allocated">
                      {secondsToMinutesConverter(
                        minutes.AddOnQuantity + minutes.BaseSeconds,
                      )}{' '}
                      mins
                    </b>
                  </Text>
                  <Text variant="reg">
                    Currently used:{' '}
                    <b data-test-id="minutes-used">
                      {secondsToMinutesConverter(minutes.SecondsUsed)} mins
                    </b>{' '}
                  </Text>
                  <Text variant="reg">
                    Time remaining:{' '}
                    <b
                      className="text-utility-error-danger"
                      data-test-id="time-remaining"
                    >
                      {secondsToMinutesConverter(minutes.SecondsRemaining)} mins
                    </b>{' '}
                  </Text>
                  {userRole !== 2 && (
                    <div className="pt-[11px]">
                      <Button variant="primary" className="w-[103px] h-[32px]">
                        Upgrade
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-[16px]">
          <div className="h-[100px] pt-1 w-full bg-white z-10 rounded-[3px] border border-[#ECEEF1] drop-shadow-md justify-center">
            <div className="pl-[24px] pt-[16px] flex flex-row justify-between">
              <div>
                <Header fontWeight="semibold" variant="h7">
                  Do Not Disturb
                </Header>
                <Text variant="reg" color="thryv-steel" className="pt-[8px]">
                  Send calls to voicemail.
                </Text>
              </div>
              <div className="pt-[30px] pr-[16px]">
                <Toggle
                  toggleStatus={doNotDisturb}
                  setToggleStatus={handleDndToggle}
                  label={doNotDisturb ? 'on' : 'off'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-[16px]">
          <div className="h-[244px] w-full bg-white z-10 rounded-[3px] border border-[#ECEEF1] drop-shadow-md justify-center">
            <div className="pl-[24px] pt-[24px]">
              <Header fontWeight="semibold" variant="h7">
                Devices
              </Header>
              <Text variant="reg" color="thryv-steel" className="pt-[8px]">
                Enable calls on multiple devices.
              </Text>
              <div className="flex flex-row pt-[16px] justify-between">
                <div className="flex flex-row">
                  <div className="grid h-[57px] w-[57px] justify-center content-center rounded-full bg-[#F8F9FB]">
                    <Icon
                      type="regular"
                      variant={'desktop'}
                      color="#CCCCCC"
                      width="31px"
                      height="27px"
                    />
                  </div>
                  <div className="grid grid-row pl-[16px] pt-[15px]">
                    <Text variant="lg">
                      <b>Web</b>
                    </Text>
                  </div>
                </div>
                <div className="pt-[20px] pr-[16px]">
                  <Toggle
                    toggleStatus={incomingCallNotificationDesktop}
                    setToggleStatus={handleWebToggleChange}
                    label={incomingCallNotificationDesktop ? 'on' : 'off'}
                  />
                </div>
              </div>
              <div className="flex flex-row pt-[16px] justify-between">
                <div className="flex flex-row">
                  <div className="grid h-[57px] w-[57px] justify-center content-center rounded-full bg-[#F8F9FB]">
                    <Icon
                      type="regular"
                      variant={'android'}
                      color="#CCCCCC"
                      width="18px"
                      height="27px"
                    />
                  </div>
                  <div className="grid grid-row pl-[16px] pt-[15px]">
                    <Text variant="lg">
                      <b>Mobile</b>
                    </Text>
                  </div>
                </div>
                <div className="pt-[20px] pr-[16px]">
                  <Toggle
                    toggleStatus={incomingCallNotificationMobile}
                    setToggleStatus={handleMobileToggleChange}
                    label={incomingCallNotificationMobile ? 'on' : 'off'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-[16px]">
          <VoicemailSettings />
        </div>
      </div>
    )
  }

  if (isMobile) {
    return renderMobile()
  }

  return (
    <div className="pl-[2%] pr-[2%] bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] flex">
      <div className="pt-[24px]  w-full ">
        <Header fontWeight="semibold" variant="h6">
          General
        </Header>
        <div className="pt-[19px] pb-[19px] border-b-[1px] border-thryv-gray-light-400">
          <Header fontWeight="semibold" variant="h7">
            Outgoing Calls
          </Header>
          <Text variant="reg" color="thryv-steel" className="pt-[8px]">
            <b className="text-thryv-night">Upgrade</b> your plan to add more
            minutes.
          </Text>
          <div className="flex flex-row pt-[16px] justify-between">
            <div className="flex flex-row">
              {/* <Text variant="reg">
                <b>Monthly</b>
              </Text> */}
              <div className="grid grid-row">
                <Text variant="reg" className="py-[3px]">
                  <span className="mr-[100px]">Plan Type:</span>{' '}
                  <b>{activePlan} (Monthly)</b>
                </Text>
                <Text variant="reg" className="py-[3px]">
                  <span className="mr-[70px]">Time allocated:</span>{' '}
                  <b data-test-id="time-allocated">
                    {secondsToMinutesConverter(
                      minutes.AddOnQuantity + minutes.BaseSeconds,
                    )}{' '}
                    mins
                  </b>
                </Text>
                <Text variant="reg" className="py-[3px]">
                  <span className="mr-[68px]">Currently used:</span>{' '}
                  <b data-test-id="minutes-used">
                    {secondsToMinutesConverter(minutes.SecondsUsed)} mins
                  </b>
                </Text>
                <Text variant="reg" className="py-[3px]">
                  <span className="mr-[63px]">Time remaining:</span>{' '}
                  <b
                    className="text-utility-error-danger"
                    data-test-id="time-remaining"
                  >
                    {secondsToMinutesConverter(minutes.SecondsRemaining)} mins
                  </b>
                </Text>
              </div>
            </div>
            {userRole !== 2 && (
              <div className="pt-[33px]">
                <Button
                  onClick={() => setOpenAddMoreMinutes(true)}
                  variant="primary"
                  className="w-[103px] h-[32px] mr-[2%]"
                >
                  Upgrade
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="pt-[19px] pb-[19px] border-b-[1px] border-thryv-gray-light-400 flex justify-between">
          <div>
            <Header fontWeight="semibold" variant="h7">
              Do Not Disturb
            </Header>
            <Text variant="reg" color="thryv-steel" className="pt-[8px]">
              Send calls to voicemail.
            </Text>
          </div>
          <div className="pr-[20px] pt-[30px]">
            <Toggle
              toggleStatus={doNotDisturb}
              setToggleStatus={handleDndToggle}
              label={doNotDisturb ? 'on' : 'off'}
              testid="dnd-toggle"
              labelTestid="dndLbl"
            />
          </div>
        </div>
        <div className="pt-[19px] pb-[19px]">
          <Header fontWeight="semibold" variant="h7">
            Devices
          </Header>
          <Text variant="reg" color="thryv-steel" className="pt-[8px]">
            Enable calls on multiple devices.
          </Text>
          <div className="flex flex-row pt-[16px] justify-between">
            <div className="flex flex-row">
              <div className="grid h-[57px] w-[57px] justify-center content-center rounded-full bg-[#F8F9FB]">
                <Icon
                  type="regular"
                  variant={'desktop'}
                  color="#CCCCCC"
                  width="31px"
                  height="27px"
                />
              </div>
              <div className="grid grid-row pl-[16px] pt-[15px]">
                <Text variant="lg">
                  <b>Web</b>
                </Text>
              </div>
            </div>
            <div className="pt-[15px] pr-[20px]">
              <Toggle
                toggleStatus={incomingCallNotificationDesktop}
                setToggleStatus={handleWebToggleChange}
                label={incomingCallNotificationDesktop ? 'on' : 'off'}
                testid="web-toggle"
                labelTestid="web-label"
              />
            </div>
          </div>
          <div className="flex flex-row pt-[16px] justify-between">
            <div className="flex flex-row">
              <div className="grid h-[57px] w-[57px] justify-center content-center rounded-full bg-[#F8F9FB]">
                <Icon
                  type="regular"
                  variant={'android'}
                  color="#CCCCCC"
                  width="18px"
                  height="27px"
                />
              </div>
              <div className="grid grid-row pl-[16px] pt-[15px]">
                <Text variant="lg">
                  <b>Mobile</b>
                </Text>
              </div>
            </div>
            <div className="pt-[15px] pr-[20px]">
              <Toggle
                toggleStatus={incomingCallNotificationMobile}
                setToggleStatus={handleMobileToggleChange}
                label={incomingCallNotificationMobile ? 'on' : 'off'}
                testid="mobile-toggle"
                labelTestid="mobile-lbl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
