import { Icon } from '@thryvlabs/maverick'
import { setViewFullMessageOpen } from '../../../slices/inboxSlice'
import { useDispatch } from 'react-redux'
import DOMPurify from 'dompurify'
import './message-modal.css'

const MessageModal = ({ body }) => {
  /* https://making.close.com/posts/rendering-untrusted-html-email-safely */
  const email = `
    <html><head>
    <meta http-equiv="Content-Security-Policy" content="script-src 'none'">
    <base target="_blank">
    </head>
    <body>${body}</body>
    </html>
`
  const dispatch = useDispatch()
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    }
  })
  return (
    <>
      <div className="flex items-center justify-center p-4">
        <div className="sm:max-w-[300px] w-full max-h-[85vh] overflow-auto p-4 ">
          <div className="flex justify-end mb-2">
            <Icon
              className="cursor-pointer"
              type="regular"
              variant="x"
              height="18"
              fill="#A3A5A7"
              onClick={() => {
                dispatch(setViewFullMessageOpen(false))
              }}
            />
          </div>

          <div
            className="leading-8 message-modal-content whitespace-normal break-words"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(email, { ADD_ATTR: ['target'] }),
            }}
          />
        </div>
      </div>
    </>
  )
}

export default MessageModal
