import { gql } from '@apollo/client'

export const UPDATE_BUSINESS_INFO_BY_LOCATION = gql`
  mutation update_business_info_by_location(
    $name: String!
    $category: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $country: String!
    $phone: String!
    $email: String!
    $website: String!
    $directoryCode: String!
    $locationId: String!
  ) {
    updateBusinessInfoByLocation(
      name: $name
      category: $category
      address: $address
      city: $city
      state: $state
      zip: $zip
      country: $country
      phone: $phone
      email: $email
      website: $website
      directoryCode: $directoryCode
      locationId: $locationId
    )
  }
`

//   mutation ($id: ID!, $phoneNumber: String!, $connection: Boolean!) {
//     updateUser(id: $id, phoneNumber: $phoneNumber, connection: $connection) {
//       id
//       phoneNumber
//       connection
//     }
//   }
