import styled from 'styled-components'
import { useState, useEffect } from 'react'

import { Toggle } from '@thryvlabs/maverick'
import { StatusPill } from '../../../../../../../../../../components/common/status-pill/status-pill'
import { LoadingSpinner } from '../../../../../../../../../../components/common/loading-spinner/loading-spinner'

const StyledToggle = styled(Toggle)`
  & > button {
    ${({ toggled }) => !toggled && 'border-color: #808080;'}

    ${({ toggled }) =>
      toggled ? 'background-color: #16A085;' : 'background-color: #808080'}
  }

  & > button > span:last-child {
    ${({ toggled }) => !toggled && 'border-color: #808080;'}
  }
`

export const PillWithToggle = ({
  className,
  isActive,
  removeToggle,
  onToggleChange,
  loading,
  role,
  totalData,
}) => {
  // Used in case isActive change is delayed
  // Prevents bugs with toggle styling
  const [localIsActive, setLocalIsActive] = useState(isActive)

  const handleToggleChange = () => {
    onToggleChange && onToggleChange()
    setLocalIsActive(!localIsActive)
  }

  useEffect(() => {
    setLocalIsActive(isActive)
  }, [isActive])

  return (
    <div
      className={`
        ${className} flex items-center gap-3 
        ${loading && 'pointer-events-none'}`}
    >
      {!(totalData === 1 && role === 'owner') && (
        <StyledToggle
          className={`settings-staff-toggle
        ${removeToggle && 'pointer-events-none opacity-0'}`}
          toggleSize="small"
          onChange={handleToggleChange}
          toggled={localIsActive}
        />
      )}
      <StatusPill
        color={localIsActive ? '#16A085' : '#A3A5A7'}
        className="!w-[72px] !flex justify-center"
      >
        {loading ? (
          <LoadingSpinner widthAndHeight={'16px'} />
        ) : (
          <>{localIsActive ? 'ACTIVE' : 'INACTIVE'}</>
        )}
      </StatusPill>
    </div>
  )
}
