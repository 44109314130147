import { useState, useEffect } from 'react'
import axios from 'axios'

const useGeoLocation = () => {
  const [geoInfo, setGeoInfo] = useState(null)

  // Only run on mount and unmount
  useEffect(() => {
    const setValues = async () => {
      const res = await axios.get('https://ipapi.co/json').catch(() => {})
      setGeoInfo(res.data)
    }
    setValues()
  }, [])

  return geoInfo
}

export default useGeoLocation
