import { NavButtonWithMenu } from './common'
import { ChannelActivationButton } from './common'

export const InboxMobileNavBar = ({ className, disableMenu }) => {
  return (
    <div
      className={`${className} h-14 bg-thryv-white-300 w-full flex items-center justify-between px-4 z-20`}
    >
      <NavButtonWithMenu disableMenu={disableMenu} />
      <ChannelActivationButton />
    </div>
  )
}
