import { Button } from '@thryvlabs/maverick'
import { RegisterBusinessModal } from './RegisterBusinessModal'
import { useState } from 'react'
import { Notification } from '../../notification'
import usePhoneChannel from '../../../../hooks/use-phone-channel'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updateInboxNotif } from '../../../inbox/slices/inbox-notifications/inbox-notifications-slice'

const RegisterBusinessErrorNotif = ({ className }) => {
  const [showModal, setShowModal] = useState(false)
  const [phoneChannel] = usePhoneChannel()
  const businessName = useSelector((state) => state.businessInfo.location.name)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const dispatch = useDispatch()

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  if (
    countryISO !== 'AU' ||
    phoneChannel?.ChannelAccessLevel !== '1' ||
    businessName
  ) {
    return null
  }

  const handleClose = () => {
    dispatch(
      updateInboxNotif({
        show: false,
        name: 'importedRegisterBusinessErrorNotif',
      }),
    )
  }

  return (
    <>
      <div className={className}>
        <Notification
          iconType="solid"
          close={handleClose}
          variant={'error'}
          iconVariant={'circleExclamation'}
          textToRender={
            <span>
              In order to start using the SMS service included in the phone channel
              for your area, you need to{' '}
              <Button variant="text" level={1} onClick={handleShowModal}>
                register a business name
              </Button>{' '}
              to send with text as per{' '}
              <a
                href="https://www.acma.gov.au/avoid-sending-spam"
                target="_blank"
                className="text-[#057AFF]"
                rel="noreferrer"
              >
                policy
              </a>
              . You can easily update your business name later in the Business
              Information settings page.
            </span>
          }
        />
      </div>
      <RegisterBusinessModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

export default RegisterBusinessErrorNotif
