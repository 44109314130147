import CallTypeIcon from '../../recent-and-voicemail-components/CallTypeIcon'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import formatContactData from '../../../../inbox/hooks/formatContactData'
const RecentContent = ({ call, hasVoicemail }) => {
  const getCallType = () => {
    if (call?.agent_id === '123' && !call?.body.includes('Missed')) {
      return 'outgoing'
    } else if (call?.agent_id === '123' && call?.body.includes('Missed')) {
      return 'outgoing-missed'
    } else if (call?.agent_id !== '123' && call?.body.includes('Missed')) {
      return 'incoming-missed'
    } else if (call?.agent_id !== '123' && !call?.body.includes('Missed')) {
      return 'incoming'
    }
  }

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: call?.threadpk?.split('#')?.at(0) },
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''

  const fullName = firstName + ' ' + lastName

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  return (
    <>
      <>
        <div className="flex items-center">
          <div className={`flex w-[25px]`}>
            <CallTypeIcon
              type={
                getCallType() === 'outgoing-missed' ||
                getCallType() === 'incoming-missed'
                  ? 'missed'
                  : getCallType()
              }
              hasVoicemail={hasVoicemail}
            />
          </div>
          <div className="flex pr-[16px] pl-[13px]">
            <CallAvatar call={call} firstName={firstName} lastName={lastName} />
          </div>
        </div>
        <div className={`flex flex-col grow`}>
          <div
            className={`${
              getCallType() === 'outgoing-missed' ||
              getCallType() === 'incoming-missed'
                ? 'text-[#df2a2a]'
                : ''
            } block max-w-[1352px] truncate font-semibold`}
          >
            {contact &&
            fullName.length > 1 &&
            !fullName.includes('undefined') &&
            !/\d{10}/.test(fullName)
              ? fullName
              : formattedPhoneNumber}
          </div>
        </div>
        <div className="flex justify-end items-center text-thryv-steel text-[16px] pr-[20px]">
          <div className="flex pr-[10px] border-r-[1px] border-[#ccc]">
            <span className="flex pb-[2px]">
              {moment(call?.ts).local().format('ll')}
            </span>
          </div>
          <span className="flex pl-[10px] ">
            {moment(call?.ts).local().format('h:mm A')}
          </span>
        </div>
      </>
    </>
  )
}
export default RecentContent
