import { useState } from 'react'
import { Icon, RegularX } from '@thryvlabs/maverick'
import { BannerClosed, InfoText } from './InfoBanner.style'

const InfoBanner = ({ text, className }) => {
  const [showInfo, setShowInfo] = useState(true)
  const wrapper = `flex flex-row justify-between text-left border rounded mt-[15px] bg-[#E6F2FF] border-[#82BDFF] p-[8px] ${className} `

  return (
    <>
      {showInfo && (
        <div className={`${wrapper}`}>
          <Icon
            variant="circleExclamation"
            type="solid"
            color="#057AFF"
            height="16"
          />
          <InfoText>{text}</InfoText>
          <BannerClosed variant="text" level={2} onClick={() => setShowInfo(false)}>
            {' '}
            <RegularX width="10" height="10" fill="#808080" />
          </BannerClosed>
        </div>
      )}
    </>
  )
}

export default InfoBanner
