import { Header, ParagraphText, Button, Icon } from '@thryvlabs/maverick'
import {
  FETCH_THREADS,
  UPDATE_THREAD_FIELD,
  REMOVE_THREAD_FIELD,
} from '../../../../graphql'
import { useInboxMenuConversations } from '../hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import {
  setBulkEditBtnClicked,
  setBulkSelectedThreadIds,
  setBulkSelectedThreadSk1s,
} from '../../slices/inboxThreadsSlice'
const PinConversationModal = ({
  setOpenPinConversationModal,
  unpinConversation,
}) => {
  const { bulkSelectedThreadSk1s } = useSelector((state) => state.inboxThreads)

  const sk1s = bulkSelectedThreadSk1s.map((x) => x.sk1)
  const dispatch = useDispatch()
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const [createPinThread] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: sk1s,
      field: 'pinsk',
    },
    onCompleted: () => {
      getThreads()
      dispatch(setBulkEditBtnClicked(false))
      dispatch(setBulkSelectedThreadIds([]))
      dispatch(setBulkSelectedThreadSk1s([]))
    },
  })

  const [deletePinThread] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: sk1s,
      field: 'pinsk',
    },
    onCompleted: () => {
      getThreads()
      dispatch(setBulkEditBtnClicked(false))
      dispatch(setBulkSelectedThreadIds([]))
      dispatch(setBulkSelectedThreadSk1s([]))
    },
  })

  const handleClick = () => {
    if (unpinConversation) {
      deletePinThread()
      setOpenPinConversationModal(false)
    } else {
      createPinThread()
      setOpenPinConversationModal(false)
    }
  }
  return (
    <div className=" flex flex-col gap-[40px] sm:w-[300px] sm:gap-[10px] sm:py-0 ">
      <div className="flex w-full items-center">
        <div className="flex justify-start w-2/3 sm:w-full sm:items-center sm:mb-6">
          <Header variant="h4" fontWeight="semibold">
            {unpinConversation ? 'Unpin Conversation' : 'Pin Conversation'}
          </Header>
        </div>
        <div className="flex w-1/3 justify-end sm:absolute sm:top-[25px] sm:right-[20px]">
          <Icon
            className="cursor-pointer"
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => {
              setOpenPinConversationModal(false)
            }}
          />
        </div>
      </div>

      <div className="w-full sm:w-[300px]">
        <ParagraphText variant="reg" className="sm:text-[13px]">
          {unpinConversation
            ? 'Would you like to unpin these conversations?'
            : 'Would you like to pin these conversations?'}
        </ParagraphText>
      </div>
      <div className="flex w-full justify-end items-center gap-4 mt-[50px] sm:pr-4">
        <Button
          type="button"
          onClick={() => {
            setOpenPinConversationModal(false)
          }}
          variant="text"
          level={2}
          textVariant="light"
        >
          Cancel
        </Button>
        {unpinConversation ? (
          <Button variant="primary" type="submit" onClick={handleClick}>
            Unpin
          </Button>
        ) : (
          <Button variant="primary" type="submit" onClick={handleClick}>
            Pin
          </Button>
        )}
      </div>
    </div>
  )
}

export default PinConversationModal
