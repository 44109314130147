import { useRef, useState } from 'react'
import { Header, ParagraphText, Icon, Button, RegularX } from '@thryvlabs/maverick'
import { Circle, ThryvCCOrangeLogoIcon } from '../../../command-center-icon/icons'
import { CommandCenterIcon } from '../../../command-center-icon'
import { useDispatch, useSelector } from 'react-redux'
import { setModalContent } from '../slices/connectChannelsSlice'
import { useClickOutside } from '../../../../hooks/use-click-outside'
import { useMediaQuery } from 'react-responsive'
import PermissionRequest from './PermissionRequest'
import ConnectRequest from './ConnectRequest'
import AlmostDone from './AlmostDone'
import ConnectionConfirmation from './ConnectionConfirmation'
import AlmostDoneExternal from './AlmostDoneExternal'
import ConnectionConfirmationExternal from './ConnectionConfirmationExternal'

const WebChatModal = ({ clickedID }) => {
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const dispatch = useDispatch()
  const cardData = useSelector((state) => state.connectChannels.cardData)

  const imapRef = useRef(null)

  const modalTypesObj = {
    webChat: 'Web Chat',
    almostDone: 'Almost Done',
    connectionConfirmation: 'Connection Confirmation',
    almostDoneExternal: 'Almost Done External',
    connectionConfirmationExternal: 'Connection Confirmation External',
  }

  const [modalType, setModalType] = useState('Web Chat')
  const [step, setStep] = useState(1)

  const connectToWebchat = () => {
    setStep(2)
  }

  useClickOutside((e) => {
    const imapRefClicked = e.composedPath().includes(imapRef.current)
    if (imapRefClicked) return
    else {
      dispatch(setModalContent(1))
    }
  }, imapRef)

  return (
    <>
      {modalType === modalTypesObj.webChat && (
        <div className="m-5 mb-10">
          <div className="flex flex-col ml-[0.5rem] mb-[0.5rem]">
            <Header className="text-left mb-5" variant="h4" fontWeight="semibold">
              Connect WebChat
            </Header>
            <span className="font-montserrat font-semibold text-base text-left">
              Connect your WebChat to connect with Thryv account.
            </span>
          </div>

          <Button
            className="fixed right-[1%] top-[3%] mr-[0.5rem]"
            variant="text"
            level={2}
          >
            <RegularX
              type="regular"
              variant="x"
              height="18"
              fill="#A3A5A7"
              onClick={() => dispatch(setModalContent(1))}
            />
          </Button>

          <div
            className=" w-[608px] sm:w-[300px] x-sm:w-[280px] sm:mb-[40px] flex flex-col items-center"
            ref={imapRef}
          >
            <div className="w-[496px] sm:w-[300px] border border-[#E3E6E8] ">
              <div className="bg-white flex flex-col w-[100%] justify-center items-center py-8">
                <div className="flex w-[100%] justify-center items-center">
                  <Circle
                    width="28"
                    height="28"
                    fill={step === 1 ? '#FF5000' : '#fff'}
                    stroke="#FF5000"
                    textColor={step === 1 ? 'white' : '#FF5000'}
                    text="1"
                  />
                  <hr className="w-[233px] h-[2px] text-[#F19B6F]" />
                  <Circle
                    width="28"
                    height="28"
                    fill={step === 2 ? '#FF5000' : '#fff'}
                    stroke="#FF5000"
                    textColor={step === 2 ? 'white' : '#FF5000'}
                    text="2"
                  />
                </div>
                <div className="flex w-[100%] justify-around">
                  <ParagraphText variant="lg">Permission Request</ParagraphText>

                  <ParagraphText variant="lg">Connect Account</ParagraphText>
                </div>
              </div>

              <div className="flex bg-[#F8F9FB] py-[32px] sm:py-[22px] items-center gap-[32px] justify-center">
                <ThryvCCOrangeLogoIcon
                  width={isMobile ? 42 : 52}
                  height={isMobile ? 42 : 52}
                />
                <Icon type="regular" variant="exchange" />
                <CommandCenterIcon
                  variant={cardData[clickedID]?.iconName}
                  type={cardData[clickedID]?.iconType}
                  width={isMobile ? 42 : 52}
                  height={isMobile ? 42 : 52}
                />
              </div>

              {step === 1 && (
                <PermissionRequest connectToWebchat={connectToWebchat} />
              )}

              {step === 2 && (
                <ConnectRequest setStep={setStep} setModalType={setModalType} />
              )}
            </div>
          </div>
        </div>
      )}

      {modalType === modalTypesObj.almostDone && (
        <AlmostDone setModalType={setModalType} />
      )}
      {modalType === modalTypesObj.almostDoneExternal && (
        <AlmostDoneExternal setModalType={setModalType} />
      )}
      {modalType === modalTypesObj.connectionConfirmation && (
        <ConnectionConfirmation />
      )}
      {modalType === modalTypesObj.connectionConfirmationExternal && (
        <ConnectionConfirmationExternal />
      )}
    </>
  )
}

export default WebChatModal
