import { useState } from 'react'
import SignUpTimeline from './SignUpTimeline'
import CreateProfile from './CreateProfile'
import VerifyStep from './VerifyStep'
import CreatePassword from './CreatePassword'
import Carousel from './Carousel'
import thryvcclogowhite from '../../assets/thryv-cc-white.png'
import AlmostDone from './AlmostDone'

const SignUp = () => {
  const [userData, setUserData] = useState({})
  const [step, setStep] = useState('1')
  const [userEmail, setUserEmail] = useState('')

  return (
    <div className="bg-sign-up-bg bg-cover bg-center bg-no-repeat h-[100vh] w-[100vw] flex items-center justify-center">
      {step !== '4' && (
        <>
          <img
            src={thryvcclogowhite}
            alt="thryv logo white"
            className="hidden md:flex md:absolute md:top-[45px]"
          />

          <div className="w-[1188px] max-h-[752px] h-[752px] md:w-[329px] md:h-[588px] md:max-h-[588px] m bg-white z-10 rounded-[12px] border border-[#ECEEF1] drop-shadow-md flex">
            <div className="w-1/2 h-full flex flex-col items-center md:w-[329px]">
              <SignUpTimeline step={step} />
              {step === '1' ? (
                <CreateProfile
                  userData={userData}
                  setUserData={setUserData}
                  setStep={setStep}
                  setUserEmail={setUserEmail}
                />
              ) : step === '2' ? (
                <VerifyStep userData={userData} setStep={setStep} />
              ) : step === '3' ? (
                <CreatePassword setStep={setStep} userData={userData} />
              ) : (
                <></>
              )}
            </div>
            <div className="h-full w-[1px] bg-[#ECEEF1] md:hidden " />
            <div className="w-1/2 h-full  md:hidden">
              <Carousel step={step} />
            </div>
          </div>
        </>
      )}
      {step === '4' && <AlmostDone userEmail={userEmail} />}
    </div>
  )
}

export default SignUp
