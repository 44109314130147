import { useMutation } from '@apollo/client'
import { UPDATE_STAFF_CHANNEL_PERMISSIONS } from '../../../../../../../graphql'
import { STAFF_PERMISSIONS, STAFF_PERMISSIONS_INT } from '../utils/staff-permissions'
import { getAccessTypeInt } from '../utils/get-access-type-int'
import { GET_STAFF } from '../../../../../../../graphql'

// Components
import { UserPermissionsRow } from './user-permissions-row'
import { TableHead } from './table-head/table-head'
import { useStaffUser } from '../../../../../../../hooks/use-staff-user'

const INTEGER_TO_ROLE = {
  1: 'admin',
  2: 'staff',
  3: 'owner',
}

export const UserPermissionsDesktopTable = ({ staffMembers, channel }) => {
  const { role } = useStaffUser()

  const [updateChannelPermissions] = useMutation(UPDATE_STAFF_CHANNEL_PERMISSIONS, {
    refetchQueries: [GET_STAFF],
  })

  const updateChannelPermissionsInDb = async (newPermission, member) => {
    const NEW_ACCESS_TYPE = STAFF_PERMISSIONS[newPermission]
    const OLD_ACCESS_TYPE = getAccessTypeInt(member.permittedChannels, channel.id)

    if (NEW_ACCESS_TYPE === OLD_ACCESS_TYPE) return

    try {
      await updateChannelPermissions({
        variables: {
          UserID: member.CC_UserID,
          ChannelID: channel.id,
          AccessType: NEW_ACCESS_TYPE,
        },
      })
    } catch (err) {
      return
    }
  }

  return (
    <>
      <div
        id="table-header"
        className="flex items-center justify-between w-full border-b border-thryv-white-300"
      >
        <TableHead>name</TableHead>
        <TableHead>email</TableHead>
        <TableHead>permission</TableHead>
      </div>
      {staffMembers.map((member, idx) => {
        const user = {
          firstName: member.FirstName,
          lastName: member.LastName,
          email: member.Email,
          role: INTEGER_TO_ROLE[member?.Role || user.cc_role],
        }

        const isReadOnlyRow =
          role === 'staff' || user.role === 'admin' || user.role === 'owner'

        return (
          <UserPermissionsRow
            key={idx}
            user={user}
            onPermissionChange={(newPermission) =>
              updateChannelPermissionsInDb(newPermission, member)
            }
            initialAccessType={
              STAFF_PERMISSIONS_INT[
                getAccessTypeInt(member.permittedChannels, channel.id)
              ]
            }
            readonly={isReadOnlyRow}
          />
        )
      })}
    </>
  )
}
