/* eslint-disable no-console */
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  CREATE_MESSAGE,
  FETCH_CONTACT_BY_NAME,
  FETCH_MESSAGES_BY_THREAD_ID,
  FETCH_THREADS,
} from '../../../graphql'
import { useEffect, useState } from 'react'
import { useInboxMenuConversations } from '../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { setError } from '../slices/inboxSlice'

export const useWelcomeMessage = () => {
  const { supportChannelId, noChannelsConnected } = useSelector(
    (state) => state.welcome,
  )
  const [supportThreadId, setSupportThreadId] = useState(null)
  const [sendWelcomeMessage, setSendWelcomeMessage] = useState(false)
  const { fname, lname, email } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const { data: contactData } = useQuery(FETCH_CONTACT_BY_NAME, {
    variables: { contactsk2: { eq: 'support@thryv.com' }, first: 20, after: null },
    skip: !supportChannelId || !noChannelsConnected,
    onCompleted: () => {
      const contacts = contactData?.queryContactsByName?.items
      const supportThreadId = contacts
        ? contacts.filter(
            (contact) => contact?.contactsk2 === 'support@thryv.com',
          )[0]?.contactsk1
        : ''
      setSupportThreadId(supportThreadId)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const { data: messagesData } = useQuery(FETCH_MESSAGES_BY_THREAD_ID, {
    variables: {
      threadid: supportThreadId,
    },
    skip: !supportThreadId || !noChannelsConnected,
    onCompleted: () => {
      const messageCount = messagesData?.queryMessagesByThread?.items?.length
      setSendWelcomeMessage(messageCount === 0)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const renderMessageError = (error) => {
    if (error) {
      dispatch(setError({ status: true, message: error.message }))
      console.error('createWelcomeMessage error:', error)
    }
  }

  const [createWelcomeMessage, { error }] = useMutation(CREATE_MESSAGE, {
    variables: {
      body: `Hi ${fname}! Welcome to your new Command Center account! We've provided a video below to help you get started. For a more detailed overview, check out the support center resources available though the Help button in the navigation.`,
      email_subject: 'Welcome to Command Center Inbox!',
      thread_id: supportThreadId || '',
      email_to: { name: `${fname} ${lname}`, email: email },
      email_cc: [],
      email_bcc: [],
      channel_id: supportChannelId || '',
      email_from: {
        name: 'Thryv Support Center',
        email: 'support@thryv.com',
      },
    },
    refetchQueries: [
      {
        query: FETCH_MESSAGES_BY_THREAD_ID,
        variables: { threadid: supportThreadId || '' },
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted: (data) => {
      console.log('Support message created!', data)
      setSendWelcomeMessage(false)
      getThreads()
    },
    onError: () => {
      renderMessageError(error)
    },
  })

  useEffect(() => {
    if (sendWelcomeMessage && supportThreadId && supportChannelId) {
      createWelcomeMessage({
        variables: { thread_id: supportThreadId, channel_id: supportChannelId },
      })
    }
  }, [sendWelcomeMessage, supportThreadId, supportChannelId])
}
