import styled from 'styled-components'
import { Accordion } from '@thryvlabs/maverick'

export const StyledAccordion = styled(Accordion)`
    border: none;
    padding-bottom: 5px;
    padding-top: 5px;
    width: 280px;
    h6 {
        color: #4D4D4D;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    div {
        margin-top: 0px;
    }
    button div {
        padding-left: 10px;
    }
    button div svg {
        display: none; 
    }
    `