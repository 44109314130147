import { createSelector } from 'reselect'

const selectContacts = ({ contacts }) => contacts

export const selectContactsData = createSelector(
  selectContacts,
  ({ contacts, loading, error }) => ({
    contacts,
    loading,
    error,
  }),
)
