import { gql } from '@apollo/client'

export const UPDATE_CONTACT = gql`
  mutation update_contact(
    $sk1: Long!
    $body: String
    $contactsk2: String
    $contactsk3: String
  ) {
    update_contact: updateContact(
      sk1: $sk1
      body: $body
      contactsk2: $contactsk2
      contactsk3: $contactsk3
    ) {
      mergeContact {
        body
        contactsk1
        contactsk2
        contactsk3
        pk1
        sk1
      }
      body
      contactsk1
      contactsk2
      contactsk3
    }
  }
`

export const CREATE_CONTACT = gql`
  mutation create_contact($body: String) {
    contact: createContact(input: { body: $body }) {
      pk1
      sk1
      body
      contactsk1
      contactsk2
      contactsk3
    }
  }
`
