import {
  PaginatedList,
  Button,
  Header,
  MenuChevron,
  TableContent,
  TableRow,
  TableMenu,
} from '@thryvlabs/maverick'
import { useEffect } from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { CREATE_INVOICE } from '../../../../graphql/mutations/settings-mutations'
import { LoadingSpinner } from '../../../../components/common/loading-spinner'
import { useSortableData } from '../../../../hooks/use-sortable-data'
import { getCountryCurrency } from '../../../../utils/get-country-currency'
import { useSelector } from 'react-redux'

export const InvoiceTableHead = ({
  children,
  className,
  width,
  onClick,
  sortDirection,
}) => {
  return (
    <button
      type="button"
      className={`${className} flex justify-start items-center ${
        !sortDirection && 'cursor-default'
      }`}
      style={{
        width: width, // Recommended to be a percentage value. Ex: 35%
      }}
      onClick={onClick}
    >
      <h6 className="text-thryv-steel font-semibold font-montserrat text-[14px] capitalize">
        {children}
      </h6>
      {sortDirection ? (
        <MenuChevron
          style={{
            marginLeft: '16px',
            ...(sortDirection === 'ascending' && { transform: 'rotate(180deg)' }),
          }}
        />
      ) : null}
    </button>
  )
}

const renderBilledDate = (props) => {
  return moment(props.BILLED_DATE?.split(' ')[0]).toDate()
}

const renderDueOn = (props) => {
  return moment(props.DUE_ON?.split(' ')[0]).toDate()
}

const renderClosedAt = (props) => {
  return moment(props.CLOSED_AT?.split(' ')[0]).toDate()
}

const InvoiceTableRow = (props) => {
  const { INVOICE_NUMBER, BILLED_DATE, DUE_ON, CLOSED_AT, INVOICE_TOTAL } = props
  const [generateInvoice, { data, loading: urlLoading }] = useMutation(
    CREATE_INVOICE,
    {
      variables: {
        invoice_number: INVOICE_NUMBER,
      },
    },
  )
  const countryCode = useSelector((state) => state.countryCode.countryIso2)

  useEffect(() => {
    generateInvoice()
  }, [])

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  return (
    <TableRow variant="static" compact>
      {/* EXAMPLE CONTENT IN TABLE ROW */}
      <div
        className="flex justify-start items-center gap-[14px]"
        style={{
          width: '25%',
          //   '@media (max-width: 768px)': {
          //     width: '30%',
          //   },
        }}
      >
        <div>
          {INVOICE_NUMBER && (
            <span className="text-btn-links font-semibold font-montserrat text-thryv-black-500">
              {INVOICE_NUMBER}{' '}
            </span>
          )}
        </div>
      </div>

      <div
        className="flex justify-start items-center"
        style={{
          width: '25%',
          //   '@media (max-width: 768px)': {
          //     width: '35%',
          //   },
        }}
      >
        {BILLED_DATE && (
          <span className="text-btn-links font-montserrat text-[#231F20]">
            {moment(BILLED_DATE.split(' ')[0]).format('MMM Do, YYYY')}
          </span>
        )}
      </div>

      <div
        className="flex justify-start items-center"
        style={{
          width: '25%',
          //   '@media (max-width: 768px)': {
          //     width: '35%',
          //   },
        }}
      >
        {DUE_ON && (
          <span className="text-btn-links font-montserrat text-[#231F20]">
            {moment(DUE_ON.split(' ')[0]).format('MMM Do, YYYY')}
          </span>
        )}
      </div>

      <div
        className="flex justify-start items-center"
        style={{
          width: '25%',
        }}
      >
        {CLOSED_AT && (
          <span className="text-btn-links font-montserrat text-[#231F20]">
            {moment(CLOSED_AT.split(' ')[0]).format('MMM Do, YYYY')}
          </span>
        )}
      </div>

      <div
        className="flex justify-start items-center"
        style={{
          width: '25%',
        }}
      >
        <span className="text-btn-links font-montserrat text-[#231F20]">
          {`$${INVOICE_TOTAL} ${getCountryCurrency(countryCode)}`}
        </span>
      </div>

      <div
        className="flex justify-start items-center"
        style={{
          width: '25%',
        }}
      >
        {urlLoading ? (
          <LoadingSpinner widthAndHeight={32} />
        ) : (
          <Button
            variant="secondary"
            onClick={() => openInNewTab(data?.generateInvoice?.data?.url)}
          >
            Go To Page
          </Button>
        )}
      </div>
    </TableRow>
  )
}

export const InvoiceCouponsWeb = ({ tableData, loading }) => {
  const { items, requestSort, sortConfig } = useSortableData(tableData)
  const getSortDirectionForKey = (key) => {
    return sortConfig?.key === key && sortConfig?.direction
  }
  return (
    <div className="p-6 border border-[#EFEFEF] rounded shadow-[0px_4px_4px_0px_#0000000A]">
      <div className="flex items-center justify-between mb-[30px]">
        <Header fontWeight="semibold" variant="h6">
          Invoices
        </Header>
      </div>

      <div className="border-t border-thryv-gray-light-400">
        <TableMenu>
          <div className="flex justify-start items-center w-full">
            <InvoiceTableHead
              width="25%"
              onClick={() => requestSort('INVOICE_NUMBER')}
              sortDirection={getSortDirectionForKey('INVOICE_NUMBER')}
            >
              Invoice
            </InvoiceTableHead>
            <InvoiceTableHead
              width="25%"
              onClick={() => requestSort('billDate', renderBilledDate)}
              sortDirection={getSortDirectionForKey('billDate')}
            >
              Created on
            </InvoiceTableHead>
            <InvoiceTableHead
              width="25%"
              onClick={() => requestSort('dueOn', renderDueOn)}
              sortDirection={getSortDirectionForKey('dueOn')}
            >
              Due on
            </InvoiceTableHead>
            <InvoiceTableHead
              width="25%"
              onClick={() => requestSort('closedAt', renderClosedAt)}
              sortDirection={getSortDirectionForKey('closedAt')}
            >
              Paid on
            </InvoiceTableHead>
            <InvoiceTableHead
              width="25%"
              onClick={() => requestSort('INVOICE_TOTAL')}
              sortDirection={getSortDirectionForKey('INVOICE_TOTAL')}
            >
              Amount
            </InvoiceTableHead>
            <InvoiceTableHead width="25%">Action</InvoiceTableHead>
          </div>
        </TableMenu>

        <TableContent>
          {loading ? (
            <div className="flex justify-center mt-2">
              <LoadingSpinner widthAndHeight={38} className="top-3" />
            </div>
          ) : (
            <>
              {items.length > 0 ? (
                <PaginatedList list={items}>
                  <InvoiceTableRow />
                </PaginatedList>
              ) : (
                <div className="flex justify-center pt-5 text-thryv-steel font-semibold font-montserrat text-[14px]">
                  You do not have any payment history to show.
                </div>
              )}
            </>
          )}
        </TableContent>
      </div>
    </div>
  )
}
