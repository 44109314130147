import { Icon } from '@thryvlabs/maverick'
import { Step, StepsContainerStyled } from './StepsContainer.styles'

export default function StepsContainer({ step }) {
  return (
    <StepsContainerStyled>
      <Step first={true} current={step === 'select'}>
        Select a Plan
      </Step>
      <Icon type="regular" variant="chevronRight" width="6" height="20" />
      <Step
        current={step === 'payment'}
      >
        Payment
      </Step>
      <Icon type="regular" variant="chevronRight" width="6" height="20" />
      <Step
        current={step === 'review'}
      >
        Review
      </Step>
    </StepsContainerStyled>
  )
}
