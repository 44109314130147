import styled from 'styled-components'

const StyledSquare = styled.div`
  height: ${({ size }) => (size ? size : '12')}px;
  width: ${({ size }) => (size ? size : '12')}px;
  background-color: yellow;
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    ${({ color }) => (color ? color : 'black')} 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  border-bottom: 1px solid ${({ color }) => (color ? color : 'black')};
  border-left: 1px solid ${({ color }) => (color ? color : 'black')};
`
const TopLeftLine = ({ color, height }) => {
  return (
    <div
      className="relative rounded-tl rounded-tr border border-r-0 border-b-0 border-t-0"
      style={{ borderColor: color, height }}
    ></div>
  )
}

const BottomLeftLine = ({ color }) => (
  <div
    className="relative grow w-full rounded-bl rounded- border border-r-0 border-t-0 border-b-0"
    style={{ borderColor: color }}
  ></div>
)

const Square = ({ color, size }) => (
  <div
    className="absolute flex flex-col w-full h-full rounded border border-l-0 border-b-0 border-r-0 border-t-0"
    style={{ borderColor: color }}
  >
    <div className="flex">
      <div
        className="grow rounded-tl border-b-0 border border-r-0"
        style={{ borderColor: color }}
      ></div>
      <StyledSquare color={color} size={size} />
    </div>
    <div
      className="w-full grow rounded-br border border-l-0 border-b-0 border-t-0"
      style={{ borderColor: color }}
    ></div>
  </div>
)

const TextContent = ({
  text,
  width,
  height,
  fontSize,
  backgroundColor,
  textColor,
  style,
}) => (
  <div
    className="relative flex items-center justify-center -left-[1px] border-transparent my-0.5"
    style={{ backgroundColor: backgroundColor, width, height, ...style }}
  >
    <p className="text-[8px] truncate" style={{ color: textColor, fontSize }}>
      {text}
    </p>
  </div>
)

export const FileIconWithoutBorders = ({
  className,
  colors,
  fontSize,
  text,
  width = 40, // number (will be pixels)
  height = 48, // number (will be pixels)
}) => {
  const { primary, secondary } = colors

  return (
    <div
      className={`${className} relative flex flex-col border border-l-0 border-t-0 border-r-0 rounded box-border`}
      data-testid="coloredFile"
      style={{ borderColor: primary, width, height }}
    >
      <Square color={primary} size={height / 3.7} />
      <TopLeftLine color={primary} height={'43%'} />
      <TextContent
        text={text}
        width={'95%'}
        height={'30%'}
        fontSize={fontSize}
        backgroundColor={secondary}
        textColor={primary}
      />
      <BottomLeftLine color={primary} />
    </div>
  )
}
