import { createSelector } from 'reselect'

const selectInbox = ({ inbox }) => inbox

export const selectedInboxMobilePageSelector = createSelector(
  selectInbox,
  ({
    agentId,
    ccRecurlyPlan,
    channelId,
    draftPk1,
    error,
    hasBusinessCenter,
    incomingContactName,
    isLoading,
    locationId,
    moderationError,
    recipient,
    reloadMessage,
    selectedInboxMobilePage,
    startNewMessage,
    user,
    viewFullMessageOpen,
  }) => ({
    agentId,
    ccRecurlyPlan,
    channelId,
    draftPk1,
    error,
    hasBusinessCenter,
    incomingContactName,
    isLoading,
    locationId,
    moderationError,
    recipient,
    reloadMessage,
    selectedInboxMobilePage,
    startNewMessage,
    user,
    viewFullMessageOpen,
  }),
)
