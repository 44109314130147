import styled from 'styled-components'
import { Button } from '@thryvlabs/maverick'

const StyledDropdownButton = styled(Button)`
  & > div > div:last-child {
    width: 100%;s
    margin-top: 4px;
    z-index: 10;
  }
`

export const StyledStaffDropdownButton = ({ ...rest }) => (
  <div className="relative">
    <StyledDropdownButton {...rest} />
  </div>
)
