import { gql } from '@apollo/client'

export const FETCH_CONTACTS = gql`
  query fetch_contacts {
    allContacts {
      id
      username
      phone
      email
      job
      location
    }
  }
`

export const FETCH_CONTACTS_BY_ID = gql`
  query fetch_contact($id: ID!) {
    Contact(id: $id) {
      id
      username
      phone
      email
      job
      location
    }
  }
`

export const FETCH_CONTACT_BY_THREAD = gql`
  query fetch_contact_by_thread_id($threadid: String!) {
    queryContactsByThread(thread_id: $threadid) {
      nextToken
      items {
        pk1
        sk1
        body
        contactsk1
        contactsk2
        contactsk3
      }
    }
  }
`

export const FETCH_CONTACT_BY_NAME = gql`
  query fetch_contact_by_name($contactsk2: TableStringSortInput, $first: Int, $after: String) {
    queryContactsByName(contactsk2: $contactsk2, first: $first, after: $after) {
      items {
        pk1
        sk1
        body
        contactsk1
        contactsk2
      }
      nextToken
    }
  }
`

export const FETCH_CONTACT_BY_CCID = gql`
  query fetch_contact_by_cc_id($first: Int) {
    queryContactsByCCID(first: $first) {
      nextToken
      items {
        pk1
        sk1
        body
        contactsk1
        contactsk2
        contactsk3
      }
    }
  }
`

export const FETCH_CONTACT_WITH_PHONE = gql`
  query fetch_contacts_with_phones {
    queryContactsWithPhoneNumber {
      pk1
      sk1
      body
      contactsk1
      contactsk2
      contactsk3
    }
  }
`
