import { forwardRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHideThreadsMenu } from '../../../../slices/inboxSlice'

// Components
import { MagnifyGlassIcon } from '../magnify-glass-icon'
import { ClearButton, FilterButton } from './buttons'

export const SearchBar = forwardRef(
  (
    {
      search = '',
      setSearch,
      className,
      onChange,
      filterButton,
      onClearClick,
      searchArea,
      isFocused,
      placeholderText,
      disableSearch,
      disableClearButton,
      onKeyDown,
    },
    searchRef,
  ) => {
    const [placeholderVisible, setPlaceholderVisible] = useState(true)

    const dispatch = useDispatch()

    const handleSearchChange = (e) => {
      const { value } = e.target
      if (value === '') {
        onClearClick && onClearClick()
        dispatch(setHideThreadsMenu(false))
        const urlWithoutParams = window.location.origin + '/inbox'
        history.replaceState({}, document.title, urlWithoutParams)
      }
      e.stopPropagation()
      onChange && onChange(e)
      setSearch(value)
    }

    const onClearButtonClick = (e) => {
      e.target.value = ''
      onClearClick && onClearClick()
      handleSearchChange(e)
      dispatch(setHideThreadsMenu(false))
    }

    const onFilterButtonClick = (e) => {
      e.stopPropagation()
      filterButton?.onClick(e)
    }

    const handleKeyDown = (e) => {
      onKeyDown && onKeyDown(e)
    }

    return (
      <div
        onClick={searchArea?.onClick}
        className={`
          inbox-search-bar-container
          relative flex items-center px-2 py-2 border rounded transition-all bg-transparent cursor-text
          ${className}
          ${
            !isFocused
              ? 'border-[#E7E7E7]'
              : 'border-thryv-black-500 ring-1 ring-thryv-black-500'
          }
        `}
      >
        <MagnifyGlassIcon className="absolute pointer-events-none ml-1.5" />

        <div className="ml-9 font-[Montserrat] text-xs font-medium grow relative overflow-hidden">
          <label
            htmlFor="inbox-search-bar-input"
            className="absolute text-thryv-steel pointer-events-none"
            style={{
              display: placeholderVisible ? 'block' : 'none',
            }}
          >
            {!search.length ? 'Search' : placeholderText || ''}
          </label>
          <input
            id="inbox-search-bar-input"
            data-testid="inbox-search-input"
            type="text"
            className={`peer appearance-none text-thryv-black-500 border-none w-full ring-0 focus:outline-none py-0 bg-transparent 
              ${disableSearch && 'pointer-events-none'}`}
            onChange={handleSearchChange}
            value={search}
            onKeyDown={(e) => {
              const isEllipsisActive = (e) =>
                e.target.offsetWidth < e.target.scrollWidth

              if (isEllipsisActive(e)) setPlaceholderVisible(false)
              else setPlaceholderVisible(true)

              handleKeyDown(e)
            }}
            autoComplete="off"
            ref={searchRef}
          />
        </div>

        <div className="flex">
          {disableClearButton ? null : (
            <ClearButton
              className="ml-1.5"
              isVisible={search.length > 0}
              onClick={onClearButtonClick}
            />
          )}

          <FilterButton
            className="ml-0.5"
            onClick={onFilterButtonClick}
            active={filterButton?.isActive}
          />
        </div>
      </div>
    )
  },
)
