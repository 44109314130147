import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const { VITE_VONAGE_ROOM_SESSION_URL } = import.meta.env

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
}
//export const createVonageRoom = createAsyncThunk(
// 'meetings/createVonageRoom',
// async ({ ccid, display_name = 'New Meeting Room' }) => {
//   const payload = { ccid, display_name }
//   return await axios.post(VITE_VONAGE_ROOM_SESSION_URL, payload, config)
// },
//)

//might be replaced with subscription
export const fetchVonageSessionData = createAsyncThunk(
  'meetings/fetchVonageSessionData',
  async ({ roomId }) => {
    const { data } = await axios.get(
      `${VITE_VONAGE_ROOM_SESSION_URL}?RoomID=${roomId}`,
      config,
    )
    return data.sessions
  },
)

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: {
    room: {
      room_data: null,
      loading: false,
      error: null,
    },
    session: {
      session_data: null,
      loading: false,
      error: null,
    },
    minutesRemaining: null,
    meetingEnded: false,
    vonage_room_info: {},
    threadId: null,
  },
  reducers: {
    clearVonageSession: (state) => {
      state.room = { room_data: null, loading: false, error: null }
      state.session = { session_data: null, loading: false, error: null }
      state.meetingEnded = true
    },
    setRoomInfo(state, action) {
      state.vonage_room_info = action.payload
    },
    setRoomData: (state, action) => {
      state.room.room_data = action.payload.roomData
      state.threadId = action.payload.threadId // Update the state with the new key
    },
    setMinutesRemaining(state, action) {
      state.minutesRemaining = action.payload
    },
    // createVonageRoom: (state, action) => {
    //   createVonageMeetingRoom(action.data)
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(createVonageRoom.pending, (state) => {
    //   state.room.loading = true
    //   state.meetingEnded = false
    // })
    // builder.addCase(createVonageRoom.fulfilled, (state, action) => {
    //   state.room.room_data = action.payload
    //   state.room.loading = false
    //   state.session.session_data = {
    //     session_data: null,
    //     loading: false,
    //     error: null,
    //   }
    //   state.meetingEnded = false
    // })
    // builder.addCase(createVonageRoom.rejected, (state, action) => {
    //   state.room.error = action.payload || action.error
    //   state.room.loading = false
    //   state.session.session_data = null
    //   state.meetingEnded = false
    // })
    builder.addCase(fetchVonageSessionData.pending, (state) => {
      state.session.loading = true
      state.session = { session_data: null, loading: false, error: null }
    })
    builder.addCase(fetchVonageSessionData.fulfilled, (state, action) => {
      state.session.loading = false
      state.session.session_data = action.payload[0]
    })
    builder.addCase(fetchVonageSessionData.rejected, (state, action) => {
      state.session.loading = false
      state.session.error = action.payload
    })
  },
})

export const { clearVonageSession, setRoomInfo, setRoomData, setMinutesRemaining } =
  meetingsSlice.actions
export default meetingsSlice.reducer
