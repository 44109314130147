import defaultLogo from '../../../../../assets/recent-voicemail-default.png'

const VoicemailDefaultScreen = () => {
  return (
    <>
      <div className="flex flex-col items-center px-[20px] md:bg-white bg-[#fbfcfd] relative w-full ">
        <img
          className="w-[360px] pt-[10px] md:pt-[50px] mr-6/12 px-[10px]"
          src={defaultLogo}
          alt="Default Calls Screen"
        />
      </div>
    </>
  )
}

export default VoicemailDefaultScreen
