import { useEffect, useRef, useState } from 'react'
import ModalHeader from './ModalHeader'
import { ModalBody, ModalContainer } from './UpgradePlanModal.styles'
import './UpgradePlan.css'
import PlanSelection from './plan-selection/PlanSelection'
import PaymentForm from './PaymentForm'
import OrderReview from './review/OrderReview'
import { UPGRADE_FLOW_STEPS } from './constants'
import { useQuery } from '@apollo/client'
import { GET_RECURLY_ACCOUNT_INFO, GET_STAFF } from '../../../../graphql'
import { useSelector, useDispatch } from 'react-redux'
import { setModalContent, setShowModal } from '../slices/connectChannelsSlice'

const UPGRADE_STEPS = [PlanSelection, OrderReview, PaymentForm]

const UpgradePlanModal = ({ close }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [accountInfo, setAccountInfo] = useState({})
  const [plansInfo, setPlansInfo] = useState(() => [])
  const [isNewPaymentMethod, setIsNewPaymentMethod] = useState(false)
  const [step, setStep] = useState(0)
  const prevStepRef = useRef()
  const cc_id = localStorage.getItem('cc_id')
  const [seats, setSeats] = useState()
  const planData = useSelector((state) => state.inbox.ccRecurlyPlan)
  const [selectedPlanCode, setSelectedPlanCode] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => setPlansInfo(planData), [planData])
  const { data, refetch: refetchAccountInfo } = useQuery(GET_RECURLY_ACCOUNT_INFO, {
    variables: {
      CCID: cc_id,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (data) {
        setAccountInfo(data.queryRecurlyAccountInfo)
      }
    },
  })

  const { data: allStaff } = useQuery(GET_STAFF, {
    variables: {
      CC_UserID: {
        eq: cc_id,
      },
    },
    onCompleted: () => {
      if (allStaff) {
        setSeats(allStaff?.staff?.filter((staff) => staff.Status === '1').length)
      }
    },
  })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  useEffect(() => {
    prevStepRef.current = step
  }, [step])

  const ModalContent = UPGRADE_STEPS[step]

  const moveToPreviousStep = () => {
    if (step !== UPGRADE_FLOW_STEPS.PLAN_SELECTION) {
      setStep((step) => step - 1)
    }
    return
  }

  const moveToNextStep = () => {
    if (step !== UPGRADE_FLOW_STEPS.ORDER_REVIEW) {
      setStep((step) => step + 1)
    } else if (step === UPGRADE_FLOW_STEPS.PAYMENT_LIST) {
      setStep(UPGRADE_FLOW_STEPS.ORDER_REVIEW)
    } else {
      // eslint-disable-next-line no-undef
      SubmitPayment()
    }
    return
  }

  const onSelectPlanHandler = async (newPlanCode) => {
    if (newPlanCode.includes('basic')) {
      dispatch(setModalContent(23))
      dispatch(setShowModal(true))
    } else {
      setSelectedPlanCode(newPlanCode)
      moveToNextStep()
    }
  }
  const currentPlan = plansInfo && plansInfo?.find((plan) => plan.isCurrent === true)
  const selectedPlan =
    plansInfo && plansInfo?.find((plan) => plan.code === selectedPlanCode)

  return (
    <ModalContainer>
      <ModalHeader close={close} />
      <ModalBody>
        <>
          <ModalContent
            windowWidth={windowWidth}
            currentPlan={currentPlan}
            selectedPlan={selectedPlan}
            previousStep={prevStepRef.current}
            setStep={setStep}
            currentStep={step}
            isNewPayment={isNewPaymentMethod}
            setIsNewPaymentMethod={setIsNewPaymentMethod}
            moveToPreviousStep={moveToPreviousStep}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
            onSelectPlan={(code) => onSelectPlanHandler(code)}
            plansInfo={plansInfo}
            refetchAccountInfo={refetchAccountInfo}
            seats={seats}
          />
        </>
      </ModalBody>
    </ModalContainer>
  )
}

export default UpgradePlanModal
