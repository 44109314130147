import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  Marketplace,
  Calls,
  PageNotFound,
  InboxPageWrapper,
  WelcomePageWrapper,
  Transactions,
  ScheduledPayments,
  Signatures,
  ListingsManagement,
  WebsiteBuilder,
  ThryvLeads,
  Teamchat,
  ReportingCenter,
  Meetings,
  Oauth,
} from '@pages'
import { Layout } from '@layout'
import { SocketProvider } from '../../context/socket-context'
import { Settings } from '../../modules/settings/Settings'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { setCCRecurlyPlan } from '../inbox/slices/inboxSlice'
import { setUserPhoneNumber } from '../../redux-toolkit'
import { syncCCPlanPrices } from '../common/modals/upgrade-plan-modal/helpers'
import ResetPassword from './reset-password'
import { BusinessCenter } from './business-center'
import { useGeoLocation } from '../../hooks'
import { useProvisionNumber } from '../../vonage/useProvisionNumber'
import { GET_INITIAL_QUERY } from '../../graphql/queries/initial-query'
import { FETCH_THREADS } from '../../graphql'
import { GET_MESSAGES_BY_PHONE_CHANNEL } from '../../graphql'
import {
  setMaxChannelsAllowed,
  setMaxChannelsAllowedLoading,
} from '../common/modals/slices/connectChannelsSlice'
import { setMinutesRemaining } from '../../redux-toolkit'
import { secondsToMinutesConverter } from '../calls/add-minutes/SecondsToMinutesConverter'
import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../redux-toolkit/slices/notifications/notifications-slice'
import { setUserInfo } from '../../redux-toolkit/slices/user-info/user-info.slice'
import {
  getLocalPhone,
  convertAlphaToLong,
} from '../../modules/settings/MyAccount/BusinessCardForms/my-account-helpers'
import { setBusinessLocation } from '../top-navigation-bar/slices/businessInfoSlice'
import { sha1 } from 'js-sha1'
import { useFlags } from 'launchdarkly-react-client-sdk'

const { VITE_AUTH0_REDIRECT_URI } = import.meta.env

const URL_MATCH = {
  [`${VITE_AUTH0_REDIRECT_URI}/voicemail`]: '/calls/voicemail',
  [`${VITE_AUTH0_REDIRECT_URI}/settings`]:
    '/settings/my-account/personal-information',
}
const WRONG_URLS = [
  `${VITE_AUTH0_REDIRECT_URI}/voicemail`,
  `${VITE_AUTH0_REDIRECT_URI}/settings`,
]

export function AuthenticatedApp() {
  const [number] = useProvisionNumber()
  const navigate = useNavigate()
  const geoInfo = useGeoLocation()
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const { toggleStaffSeatUpdates } = useFlags()

  useEffect(() => {
    if (number) {
      dispatch(setUserPhoneNumber(number))
    }
  }, [number])

  useEffect(() => {
    if (WRONG_URLS.includes(window.location.href)) {
      navigate(URL_MATCH[window.location.href])
    }
  }, [URL_MATCH, window.location.href, WRONG_URLS])

  const [recurlyScriptLoaded, setRecurlyScriptLoaded] = useState(false)

  useEffect(() => {
    if (user) {
      if (window.location.href.includes('/signup')) {
        navigate('/')
      }
      //setting the user on localStorage bc if not when the app reloads and tries to run the req below it will fail since the user hasnt been loaded
      localStorage.setItem('cc_id', user.cc_id)
      localStorage.setItem('business_id', user.businessId)
      localStorage.setItem('dir_code', user.dirCode)
      localStorage.setItem('agent_id', user.staffUID)
    }
  }, [navigate, user])

  useEffect(() => {
    const recurlyStyles = document.createElement('link')
    const script = document.createElement('script')

    recurlyStyles.href = 'https://js.recurly.com/v4/recurly.css'
    recurlyStyles.rel = 'stylesheet'
    document.body.appendChild(recurlyStyles)

    script.src = 'https://js.recurly.com/v4/recurly.js'
    script.async = true
    script.id = 'recurlyScript'

    document.body.appendChild(script)
    document.getElementById('recurlyScript').addEventListener('load', () => {
      setRecurlyScriptLoaded(true)
    })
    return () => {
      document.body.removeChild(script)
      document.body.removeChild(recurlyStyles)
    }
  }, [])

  const unreadThreadsData = useQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
    variables: {
      folder: 'unread',
    },
    onCompleted: () => {
      const threads = unreadThreadsData?.data?.queryThreads?.items?.filter(
        (x) => x?.messages?.items?.length > 0,
      )
      const pinned = unreadThreadsData?.data?.pinned?.items?.filter(
        (x) => x?.messages?.items?.length > 0 && x?.read === false,
      )
      dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
    },
  })

  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,

    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  const fetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  useEffect(() => {
    if (phoneChannelID) {
      fetchVoicemailsAndCalls()
    }
  }, [phoneChannelID])

  const { data } = useQuery(GET_INITIAL_QUERY, {
    variables: {
      CCID: user?.cc_id,
      loc_id: user?.businessId,
    },
    onCompleted: () => {
      dispatch(
        setMinutesRemaining(
          secondsToMinutesConverter(data?.queryCallsMinutesByCCID?.SecondsRemaining),
        ),
      )
      dispatch(setMaxChannelsAllowedLoading(false))
      dispatch(setMaxChannelsAllowed(data?.queryPackageInfoByCCID?.InboxChannels))

      const newPlanData = syncCCPlanPrices(
        data.queryRecurlyCommandCenterPlans,
        data.queryRecurlyAccountInfo.activePlanCode,
        toggleStaffSeatUpdates,
      )
      dispatch(setCCRecurlyPlan(newPlanData))

      const { FirstName, LastName, Email, Role } = data.staff[0]
      dispatch(
        setUserInfo({
          fname: user.given_name ? user.given_name : FirstName,
          lname: user.family_name ? user.family_name : LastName,
          email: user.email ? user.email : Email,
          role: user.cc_role ? user.cc_role : Role,
        }),
      )

      const {
        Name,
        BusinessCategory,
        Address,
        City,
        Zip,
        State,
        Phone,
        CountryCode,
        Email: businessEmail,
        Website,
      } = data.queryBusinessInfoByLocation[0]

      const country = convertAlphaToLong(CountryCode)
      const validCountryCode = CountryCode
      const localPhone = getLocalPhone(CountryCode, Phone)
      dispatch(
        setBusinessLocation({
          name: Name || '',
          category: BusinessCategory || '',
          address: Address || '',
          city: City || '',
          zip: Zip || '',
          state: State || '',
          country,
          countryCode: validCountryCode || '',
          phone: localPhone,
          email: businessEmail || '',
          website: Website || '',
        }),
      )
    },
  })

  const ROLES = { 1: 'admin', 2: 'staff', 3: 'owner' }
  const { name, email, staffUID, cc_id, businessId, cc_channels, cc_role, bc_role } =
    user
  let firstName = ''
  let lastName = ''
  if (name.split(' ').length === 2) {
    ;[firstName, lastName] = name.split(' ')
  } else {
    ;[firstName, lastName] = name.split('+')[0].split('.')
  }

  // Tealium code
  const visitor = {
    visitor_id: staffUID,
    user_id: businessId,
    email: email,
    first_name: firstName,
    full_name: firstName + ' ' + lastName,
    last_name: lastName,
    language: 'en',
    cc_role: ROLES[cc_role],
    bc_role: bc_role,
    has_password: true,
    account_id: businessId,
  }

  const account = {
    account_id: businessId,
    business_identities: businessId,
    channels: cc_channels?.length || 0,
    users: cc_channels?.length || 0,
    command_center_id: cc_id,
    country: geoInfo?.country_name,
    state: geoInfo?.region,
    postal_code: geoInfo?.postal,
    business_name: null,
    mc_package: null,
    package: null,
    creation_date: null,
    create_date_time: null,
    matter_term: null,
    phone_number: null,
    mobile_number: null,
    tags: null,
    thryvpay_connected: null,
    staffs: null,
  }
  let tealium_data = {
    cc_account_prop: account,
    cc_user_prop: visitor,
    cc_user_email: visitor.email,
  }

  for (let key in tealium_data) {
    // eslint-disable-next-line no-undef
    utag_data[key] = tealium_data[key]
  }

  // eslint-disable-next-line no-undef
  const { cc_user_email } = utag_data

  useEffect(() => {
    if (cc_user_email !== '') {
      // eslint-disable-next-line no-undef
      utag.view(utag_data)
    }
  }, [cc_user_email])

  // Impact(platform similar to tealium) Identify function
  if (window.ire && !Array.isArray(window.ire) && email && cc_id) {
    // when email and cc_id is ready, call identify function
    window.ire('identify', {
      customerId: cc_id,
      customerEmail: sha1(email),
    })
  }

  if (!recurlyScriptLoaded) {
    return null
  }

  return (
    <SocketProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<WelcomePageWrapper />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/inbox/*" element={<InboxPageWrapper />} />
          <Route path="/inbox/add-channels" element={<InboxPageWrapper />} />
          <Route path="/teamchat/*" element={<Teamchat />} />
          <Route path="/reporting-center/*" element={<ReportingCenter />} />
          <Route path="/calls/*" element={<Calls />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/scheduled-payments" element={<ScheduledPayments />} />
          <Route path="/signatures" element={<Signatures />} />
          <Route path="/listings-management" element={<ListingsManagement />} />
          <Route path="/website-builder" element={<WebsiteBuilder />} />
          <Route path="/leads" element={<ThryvLeads />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/meetings" element={<Meetings />} />
          <Route path="/oauth/*" element={<Oauth />} />
          <Route path="/oauth=meta?" element={<Oauth />} />
          <Route path="/oauth=office?" element={<Oauth />} />
          <Route path="/oauth=webchat?" element={<Oauth />} />
          <Route path="/business-center" element={<BusinessCenter />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </SocketProvider>
  )
}
