import { useLazyQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuth0 } from '@auth0/auth0-react'
import { FETCH_ACTIVE_SUBSCRIPTIONS } from '../../../../graphql/queries/settings-queries'
import { useEffect, useState } from 'react'
import { CC_RECURLY_PLAN_CODES_LIST_EXCLUDE } from '../../../../utils/recurly-constants'
import StaffSeatChangeModal from './StaffSeatChangeModal'

const useStaffSeatChangeModals = () => {
  const { toggleStaffSeatUpdates } = useFlags()
  const { user } = useAuth0()
  const [ccSubscription, setCcSubscription] = useState([])
  const [getActiveSubscriptions, { loading }] = useLazyQuery(
    FETCH_ACTIVE_SUBSCRIPTIONS,
  )
  const planName = ccSubscription?.[0]?.subscription?.SUBSCRIPTION_PLAN_NAME

  useEffect(() => {
    if (!toggleStaffSeatUpdates) {
      return
    }

    getActiveSubscriptions({
      variables: {
        loc_id: user.businessId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { data: subscriptions } = data.queryActiveSubscriptionsByLocation
        // Just showing CC subscriptions and others with addOns
        const ccSubscriptions = subscriptions
          .filter(
            (subscription) =>
              !CC_RECURLY_PLAN_CODES_LIST_EXCLUDE.includes(
                subscription.subscription.SUBSCRIPTION_PLAN_CODE,
              ) || subscription.addons.length > 0,
          )
          .filter((subscription) => {
            return subscription?.subscription?.SUBSCRIPTION_PLAN_NAME?.includes(
              'Command Center',
            )
          })
        setCcSubscription(ccSubscriptions)
      },
    })
  }, [])

  const staffSeatChangeModal = (
    <StaffSeatChangeModal planName={planName} loading={loading} />
  )

  return { staffSeatChangeModal }
}

export default useStaffSeatChangeModals
