import { createSlice } from '@reduxjs/toolkit'

export const planInfoSlice = createSlice({
  name: 'planInfo',
  initialState: {
    recentlyUpgraded: false,
    currentPlan: {},
  },
  reducers: {
    changeCurrentPlan: (state, action) => {
      const { payload } = action
      if (payload) {
        const newPlan = {
          ...payload,
          isCurrent: true,
        }
        state.currentPlan = newPlan
      }
    },
    setPaymentProcessed: (state, action) => {
      const { payload } = action
      if (typeof payload === "boolean") {
        state.recentlyUpgraded = payload
      }
    },
  },
})

export const { changeCurrentPlan, setPaymentProcessed } = planInfoSlice.actions

export default planInfoSlice.reducer
