
export const StatusPill = ({ className, children, color }) => {
  return (
    <div
      className={`${className} rounded-full w-max px-2 py-1 bg-[${color}] text-xs text-white`}
    >
      {children}
    </div>
  )
}
