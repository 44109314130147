import * as yup from 'yup'

export const getUpdateContactSchema = (countryCode) => {
  let max = 0
  const usernameErrorMessage = 'No Special Characters Allowed'

  if (countryCode === 'US' || countryCode === 'CA') max = 11
  if (countryCode === 'AU') max = 12
  if (countryCode === 'NZ') max = 10

  return yup.object({
    firstName: yup
      .string()
      .required('First Name is required')
      .max(15, 'First Name must be at most 15 characters')
      .trim()
      .typeError(usernameErrorMessage),
    lastName: yup
      .string()
      .max(20, 'Last Name must be at most 15 characters')
      .typeError(usernameErrorMessage)
      .trim(),
    email: yup.string().email(),
    phone: yup
      .string()
      .max(max)
      .matches(/^[\d\s]*$/, 'No special characters allowed')
      .typeError(
        `Please enter a valid ${countryCode} phone number without any special characters`,
      ),
  })
}
