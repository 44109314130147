import { Header, ParagraphText } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindow, faHandshake} from '@fortawesome/pro-regular-svg-icons'

const ManageTypeCard = ({managedBy, description, icon, selectedCard, setSelectedCard}) => {
    return (
        <div onClick={()=>setSelectedCard(managedBy)} className={`flex w-[370px] justify-center items-center cursor-pointer m-[auto] mt-[1rem] p-[12px] border-[2px] ${selectedCard === managedBy?'border-[#5378FC]': 'border-[#ECEEF1]'} rounded-[2px] bg-[#F8F9FB]`}>
            <div className="w-[30%] h-[72px] bg-red">
                {icon === "faHandshake" && <FontAwesomeIcon className="text-[2rem] mt-[1rem] text-[#5378FC]" icon={faHandshake} />}
                {icon === "faWindow" && <FontAwesomeIcon className="text-[2rem] mt-[1rem] text-[#5378FC]" icon={faWindow} />}
            </div>
            <div className="w-[70%] text-left">
                <Header
                    className="text-[14px]"
                    fontWeight="semibold"
                    variant="h5"
                >
                    {managedBy}
                </Header>
                <ParagraphText variant="sm" color="#231F20">
                    {description}
                </ParagraphText>
            </div>
        </div>
    )
}

export default ManageTypeCard