import { useSelector } from 'react-redux'
import { useRedux } from '../../../hooks'

export default function useContactName() {
  const { clientNumber } = useSelector((state) => state.calls)
  const [
    {
      contacts: { contactsList },
    },
  ] = useRedux('contacts')

  const contactIndex = contactsList.findIndex(
    (contact) => contact.phone === clientNumber,
  )
  const contactName = contactsList[contactIndex]?.username

  return contactName
}
