import { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_STAFF } from '../../../graphql'
import { useLocation } from 'react-router-dom'
import { getNavMenuHeaderTitle } from '../settings-utils'

export const MobileNavigation = ({
  settingsLinksArray,
  myAccountSubTab,
  staffMyAccountSubTab,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [subTab, setSubTab] = useState('')
  const mobileMenuRef = useRef(null)
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: staffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userRole = staffData?.staff[0]?.Role || user.cc_role
  const [userNav, setUserNav] = useState()

  useEffect(() => {
    if (userRole === '2') {
      setUserNav(staffMyAccountSubTab)
    } else {
      setUserNav(myAccountSubTab)
    }
  }, [userRole])
  const location = useLocation()

  {
    /* ⬇ Commented out for now, only need one piece of state. Saving in case we want breadcrums again ⬇ */
  }
  //   const [mainTab, setMainTab] = useState('My Account')

  {
    /* ⬇ Commented out to only show the current tab the user is on instead of breadcrums ⬇ */
  }
  //   const setMainTabFun = function (val) {
  //     setOpenMobileMenu(false)
  //     if(val === 'My Account') {
  //         setMainTab('My Account');
  //         setSubTab('Personal Information')
  //     }else{
  //         setMainTab(val);
  //         setSubTab('')
  //     }
  // }

  useEffect(() => {
    const navHeaderTitle = getNavMenuHeaderTitle(location.pathname)
    setSubTab(navHeaderTitle)
  }, [])

  const mobileMenuToggle = function (event) {
    event.stopPropagation()
    setOpenMobileMenu((prevState) => !prevState) // Toggle the openMobileMenu state
  }

  const setSubTabFun = function (val) {
    setOpenMobileMenu(false)
    setSubTab(val)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setOpenMobileMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [mobileMenuRef])

  return (
    <>
      <div
        onClick={mobileMenuToggle}
        className="flex  items-center p-[0.5rem] text-[18px] font-[600] text-[#4D4D4D]"
      >
        {/* ⬇ Commented out to only show the current tab the user is on ⬇ */}

        {/* <span>Settings</span>
                <FontAwesomeIcon
                    icon={faChevronRight}
                    size={'lg'}
                    className={'pl-0 text-[12px] ml-[6px] mr-[6px] mb-[2px]'}
                />
                <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{mainTab}</span> */}

        {subTab && (
          <>
            {/* <FontAwesomeIcon
                        icon={faChevronRight}
                        size={'lg'}
                        className={'pl-0 text-[12px] ml-[6px] mr-[6px] mb-[2px]'}
                    /> */}

            {/* ⬆ Commented out to only show the current tab the user is on ⬆ */}

            <span className=" whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer">
              {subTab}
            </span>
            <FontAwesomeIcon
              icon={faCaretDown}
              size={'lg'}
              className={
                'pl-0 text-[12px] ml-[6px] mr-[6px] mb-[2px] cursor-pointer'
              }
            />
          </>
        )}
      </div>

      {openMobileMenu && (
        <div
          // ref={mobileMenuRef}
          className="absolute p-[1rem] top-[13%] w-[218px] h-[472px] bg-[#FFFFFF] border-[1px] border-[#E3E6E8] rounded-[4px] shadow-[0_4px_4px_rgba(0,0,0,0.04)] z-[1000]"
        >
          <p className="font-bold text-[16px] cursor-default">Settings</p>
          <div className="open-sans">
            {settingsLinksArray.map((navigator, index) => {
              const setNavTab =
                navigator.title === 'My Account'
                  ? 'Personal Information'
                  : navigator.title
              return (
                <div key={index}>
                  <NavLink to={navigator.path}>
                    <p
                      onClick={() => setSubTabFun(setNavTab)}
                      className="font-bold hover:text-[#057AFF] text-sm  min-h-[40px] pt-[14px] px-[6px]"
                      /* ⬇ Commented out for now since we only have one menu item showing ⬇ */
                      // className={`${
                      //   navigator.title === mainTab ? 'font-bold' : 'font-light'
                      // } hover:text-[#057AFF] text-sm  min-h-[40px] pt-[14px] px-[6px]`}
                    >
                      {navigator.title}
                    </p>
                  </NavLink>

                  {index === 0 &&
                    userNav.map((tab, idx) => (
                      <NavLink key={idx} to={tab.path}>
                        <p
                          onClick={() => setSubTabFun(tab.label)}
                          className={`${
                            tab.label == subTab ? 'semi-bold' : 'font-light'
                          } hover:text-[#057AFF] text-sm  min-h-[30px] pt-[7px] px-[16px]`}
                        >
                          {tab.label}
                        </p>
                      </NavLink>
                    ))}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
