import styled from 'styled-components'

const StyledPaginatedListWrapper = styled.div`
  & > div > .table-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  overflow-y: scroll;
  height: 0;
  min-height: 100%;
  background-color: #f8f9fb;
  width: 100%;
  padding: 24px;

  // Pagination buttons container
  & > div > div:first-child + div {
    margin-bottom: 16px;
    margin-top: 24px;
  }
`

export default StyledPaginatedListWrapper
