import { createSlice } from '@reduxjs/toolkit'

const inboxSearchSlice = createSlice({
  name: 'search',
  initialState: {
    search: '',
    contacts: [],
    suggestedSearches: [],
    recentSearches: [],
  },
  reducers: {
    setSearch(state, action) {
      state.search = action.payload
    },
    decrement(state) {
      state.value--
    },
    incrementByAmount(state, action) {
      state.value += action.payload
    },
  },
})

export const { setSearch } = inboxSearchSlice.actions
export default inboxSearchSlice.reducer
