import { Input, Select } from '@thryvlabs/maverick'
import { Controller } from 'react-hook-form'
import { usStates } from '../../../../../../utils/us-states'
import { getStateObj } from '../../../../../top-navigation-bar/StateProvinceSelectDropdown'

export const formNames = ['address', 'city', 'state', 'postal']

const BusinessDetails = ({ register, errors, control, getValues, setValue }) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="mt-[32px] sm:mt-5">
          <Input
            type="text"
            withLabel
            name="address"
            placeholder="Street Address"
            labelType="floating"
            variant="default"
            register={register}
            className="w-full"
            errors={errors}
          />
        </div>
        <div className="w-full mt-[32px] sm:mt-5">
          <Input
            type="text"
            withLabel
            name="optionalAddress"
            placeholder="Apt, suite, etc (optional)"
            labelType="floating"
            variant="default"
            register={register}
            className="w-full"
            errors={errors}
          />
        </div>
        <div className="flex mt-[32px] mb-[10px] sm:flex-col sm:mt-5">
          <div className="w-1/3 pr-[12px] sm:w-full sm:pr-0">
            <Input
              type="text"
              withLabel
              name="city"
              placeholder="City"
              labelType="floating"
              variant="default"
              register={register}
              className="sm:w-full"
              errors={errors}
            />
          </div>
          <div className="w-1/3 mt-[-7px] px-[12px] sm:w-full sm:mt-5 sm:px-0">
            <Controller
              control={control}
              name="state"
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, onChange, ...rest } }) => (
                <div
                  onKeyDown={(e) => {
                    if (e.code === `Key${e.key.toUpperCase()}`) {
                      const selectedState = usStates.find((s) =>
                        s.name.toLowerCase().startsWith(e.key),
                      )
                      setValue('state', selectedState.value)
                    }
                  }}
                >
                  <Select
                    options={usStates}
                    selectLabel="State"
                    withLabel
                    selectedOption={getStateObj(getValues('state'), usStates)}
                    onChange={(option) => setValue('state', option.value)}
                    className="w-full"
                    {...rest}
                  />
                </div>
              )}
            />
          </div>
          <div className="w-1/3 pl-[12px] sm:w-full sm:pl-0 sm:mt-5">
            <Input
              type="tel"
              withLabel
              name="postal"
              placeholder="Postal Code"
              labelType="floating"
              variant="default"
              register={register}
              className="w-full"
              errors={errors}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default BusinessDetails
