import { ActivateKeypadIcon } from '../../../../command-center-icon/icons/icons'
import defaultLogo from '../../../../../assets/recent-voicemail-default.png'
const RecentsDefaultScreen = ({ username, isMobile }) => {
  return (
    <>
      <div className="flex flex-col items-center pt-[55px] md:pt-[50px] px-[20px] md:bg-white bg-[#fbfcfd] relative w-full h-full">
        <div className="flex text-[14px] items-center h-[104px] max-w-[336px] bg-white text-thryv-night rounded-[10px]">
          <div className="flex flex-col pl-[20px] pr-[16px] py-[12px] justify-items-center">
            <span className="flex font-semibold capitalize">Hi {username},</span>
            <span className="flex w-[251px]">
              {`You can access your contacts through the ${
                isMobile ? 'main navigation' : 'left panel'
              } or use the dialpad
              icon to make a call.`}
            </span>
          </div>
          <ActivateKeypadIcon fill={'#E3E6E8'} />
        </div>
        <img
          className="w-[360px] pt-[83px] md:pt-[50px] mr-6/12 px-[10px]"
          src={defaultLogo}
          alt="Default Calls Screen"
        />
      </div>
    </>
  )
}

export default RecentsDefaultScreen
