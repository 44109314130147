export const transformToSortData = (list) => {
  const newData = list.map((item) => {
    return {
      name: item,
      value: item,
    }
  })

  return newData
}
