import * as yup from 'yup'

const personalInfoFormSchema = (countryISO) => {
  let max = 0
  let min = 0

  if (countryISO === 'US' || countryISO === 'CA') {
    min = 10
    max = 11
  }
  if (countryISO === 'AU') {
    min = 9
    max = 12
  }
  if (countryISO === 'NZ') {
    min = 8
    max = 10
  }

  return yup.object({
    fname: yup
      .string()
      .min(1, 'First Name is required')
      .max(128, 'First Name cannot be longer than 128 characters')
      .matches(/^(?!^\s+$)/, 'First Name cannot consist entirely of whitespace')
      .required(),

    lname: yup
      .string()
      .min(1, 'Last Name is required')
      .max(128, 'Last Name cannot be longer than 128 characters')
      .matches(/^(?!^\s+$)/, 'Last Name cannot consist entirely of whitespace')
      .required(),

    email: yup.string().email(),

    phoneNumber: yup
      .string()
      .max(max)
      .min(min, `Please enter a valid ${countryISO} phone number`)
      .matches(/^[\d\s]*$/, 'No special characters allowed')
      .typeError(
        `Please enter a valid ${countryISO} phone number without any special characters`,
      ),
  })
}

export default personalInfoFormSchema
