import { Button, Header, Icon, ModalWrapper } from '@thryvlabs/maverick'
import ChannelsPendingReauthModal from './ChannelsPendingReauthModal'
import { useDispatch } from 'react-redux'
import { setModalContent, setShowModal } from '../slices/connectChannelsSlice'

const UpcomingImprovementsModal = ({
  showUpcomingImprovModal,
  setShowUpcomingImprovModal,
  showPendingReauthModal,
  setShowPendingReauthModal,
}) => {
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    setShowUpcomingImprovModal(false)
    setShowPendingReauthModal(true)
  }

  const openManageModal = () => {
    dispatch(setModalContent(2))
    dispatch(setShowModal(true))
  }

  const handleStartClick = () => {
    setShowUpcomingImprovModal(false)
    openManageModal()
  }

  return (
    <>
      <ModalWrapper
        show={showUpcomingImprovModal}
        close={handleCloseModal}
        disableBackdropClick={false}
      >
        <div className="w-[436px] p-2 text-left sm:w-full">
          <div className="flex justify-between items-center gap-8 w-full">
            <Header variant="h4" fontWeight="semibold">
              Upcoming Improvements to Your Command Center
            </Header>
            <Button variant="text" level={2}>
              <Icon
                type="regular"
                variant="x"
                height="18"
                fill="#A3A5A7"
                onClick={handleCloseModal}
              />
            </Button>
          </div>
          <div className="font-open-sans text-sm text-thryv-black">
            <p className="pb-4 pt-5">
              We’re excited to announce updates aimed at enhancing the performance
              and reliability of your channels in Command Center.
            </p>
            <p className="pb-4">
              Here is what to expect:
              <ul>
                <li className="pl-3">
                  • Speed at scale, your inbox is now 3.5x faster and 10x more
                  reliable
                </li>
                <li className="pl-3">
                  • Secure and reliable, industry standard encryption keeps your data
                  private
                </li>
              </ul>
            </p>
            <p className="pb-4">
              To enjoy these improvements, please reauthenticate your connected email
              channels in Command Center before November 20th. Any channels that are
              not reauthenticated by November 20th will be automatically disconnected
              and will require manual reauthentication.
            </p>
            <p className="pb-4">
              Click the button below to reauthenticate your channels today and
              experience a more smooth and efficient business communication
              experience.
            </p>
          </div>
        </div>
        <div className="pb-3">
          <Button
            className="h-[44px] w-[142px] rounded-[22px] border-2 text-sm"
            variant="primary"
            onClick={handleStartClick}
          >
            LET'S START
          </Button>
        </div>
      </ModalWrapper>
      <ChannelsPendingReauthModal
        showPendingReauthModal={showPendingReauthModal}
        setShowPendingReauthModal={setShowPendingReauthModal}
        openManageModal={openManageModal}
      />
    </>
  )
}

export default UpcomingImprovementsModal
