import { useEffect, useRef, useState } from 'react'
import { useClickOutside } from '../../../../../../../hooks/use-click-outside'
import { TransitionContainer } from '../../../../../../common/transition-container'
import { ParagraphText } from '@thryvlabs/maverick'
import RoundedActionButton from '../../../../../common/rounded-action-button'

const MenuButton = ({ onClick, children }) => (
  <button
    type="button"
    className="whitespace-nowrap text-left rounded hover:bg-thryv-gray-light-200 transition-all duration-300 h-7 px-2 py-1 w-full"
    onClick={onClick}
  >
    <ParagraphText variant="sm">{children}</ParagraphText>
  </button>
)

export const MoveFolderButtonWithMenu = ({
  onMenuToggle,
  data,
  hideHoverButtons,
}) => {
  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [buttonClickable, setButtonClickable] = useState(true)

  const toggleMenu = (show) => {
    if (!buttonClickable) return

    setButtonClickable(false)

    typeof show === 'boolean' ? setShowMenu(show) : setShowMenu(!showMenu)

    setTimeout(() => {
      setButtonClickable(true)
    }, 300)
  }

  const hideHoverButtonsByDelay = () => {
    setTimeout(() => {
      hideHoverButtons()
    }, 10000)
  }

  useEffect(() => {
    return () => toggleMenu(false)
  }, [])

  useEffect(() => {
    onMenuToggle(showMenu)
  }, [showMenu])

  useClickOutside((e) => {
    const ConversationRowClicked = e
      .composedPath()
      .includes(buttonRef.current.closest('tr'))

    const buttonClicked = e.composedPath().includes(buttonRef.current)
    if (!buttonClicked) toggleMenu(false)
    if (!ConversationRowClicked) hideHoverButtonsByDelay()
  }, dropdownRef)

  return (
    <div className="relative">
      <RoundedActionButton
        dataTestId="conversation-move"
        className={`w-[30px] h-[30px] 
        ${buttonClickable && 'pointer-events-auto'} pointer-events-none`}
        icon={{
          variant: 'uploadFolder',
          type: 'regular',
          width: '16',
          height: '16',
        }}
        isCCIcon
        tooltipTitle="Mark As"
        onClick={(e) => {
          e.stopPropagation()
          toggleMenu()
        }}
        ref={buttonRef}
      />

      <TransitionContainer
        show={showMenu}
        as="ul"
        className={`right-0 p-2`}
        width="fit-content"
        transformOrigin="top right"
        ref={dropdownRef}
      >
        {data.map(({ text, onClick }, i) => {
          const onMenuButtonClick = (e) => {
            e.stopPropagation()
            onClick()
            toggleMenu(false)
            hideHoverButtonsByDelay()
          }

          return (
            <li className="list-none" key={i}>
              <MenuButton onClick={onMenuButtonClick}>{text}</MenuButton>
            </li>
          )
        })}
      </TransitionContainer>
    </div>
  )
}
