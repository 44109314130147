import React, { createContext, useState } from 'react'

export const QuickActionContext = createContext({})

export const QuickActionContextProvider = ({ children }) => {
  const [isActionModal, setIsActionModal] = useState(false)
  const [pickedModal, setPickedModal] = useState('')

  return (
    <div className="flex flex-col items-start justify-start py-2 px-4 overflow-y-auto">
      <QuickActionContext.Provider
        value={{ isActionModal, setIsActionModal, pickedModal, setPickedModal }}
      >
        {children}
      </QuickActionContext.Provider>
    </div>
  )
}
