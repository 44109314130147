import * as yup from 'yup'

const businessInfoFormSchema = (countryCode) => {
  const businessName = yup
    .string()
    .required('Business name is required')
    .min(3, 'Must be at least 3 characters')

  const state = yup.mixed().nullable().required('Please select a state / province')

  if (countryCode === 'US') {
    return yup.object().shape({
      postalCode: yup
        .string()
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Please enter a valid zip code')
        .min(5, 'Please enter a valid zip code')
        .max(10, 'Please enter a valid zip code'),
      businessName,
      state,
    })
  }
  if (countryCode === 'CA') {
    return yup.object().shape({
      postalCode: yup
        .string()
        .matches(
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
          'Please enter a valid zip code',
        )
        .min(3, 'Please enter a valid zip code')
        .max(7, 'Please enter a valid zip code'),
      businessName,
      state,
    })
  }
  if (countryCode === 'NZ' || countryCode === 'AU') {
    return yup.object().shape({
      postalCode: yup
        .string()
        .matches(/^[0-9]+$/, 'Please enter a valid zip code')
        .min(4, 'Please enter a valid zip code')
        .max(4, 'Please enter a valid zip code'),
      businessName,
      state,
    })
  }
}

export default businessInfoFormSchema
