import { createSlice } from '@reduxjs/toolkit'

const participantsSlice = createSlice({
  name: 'participants',

  initialState: {
    participants: [],

    selectedParticpant: null,
  },

  reducers: {},
})

export default participantsSlice.reducer

// const particpantExample = {
//   id: crypto.randomUUID(),
//   name: 'Jakob Workman',
//   iconUrl: '',
//   phone: { number: '707-111-3333' },
//   email: 'jakok_workman@gmail.com',
//   conversations: {
//     id: crypto.randomUUID(),
//     isRead: true || false,
//     isPinned: true || false,
//     isSpam: true || false,
//     isCompleted: true || false,
//     isTrash: true || false,
//     draft: {},
//     newestConversation: {
//       id: crypto.randomUUID(),
//       text: '',
//       isPinned: true || false,
//       channel: '',
//       variant: '',
//       attachments: [],
//       timestamp: '',
//       labels: [],
//       recipients: {
//         bcc: [],
//         cc: [],
//       },
//     },
//   },
// }
