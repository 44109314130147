import { useEffect, useState } from 'react'

export const EditContactErrorToast = ({ message, setContactInfoView }) => {
  const [mounted, setMounted] = useState(false)
  const [startFade, setStartFade] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      const timer1 = () =>
        setTimeout(() => {
          setStartFade(true)
        }, 5000)
      const timer2 = () =>
        setTimeout(() => {
          setMounted(false)
        }, 8000)

      const timer1Id = timer1()
      const timer2Id = timer2()

      return () => {
        clearTimeout(timer1Id)
        clearTimeout(timer2Id)
      }
    }
    return () => {}
  }, [mounted])

  const renderToast = mounted ? (
    <div
      className={`flex items-center rounded text-white font-semibold transition-all duration-[3000ms] ${
        startFade ? 'bg-transparent' : 'bg-utility-error-danger'
      } mt-4 p-4 ${setContactInfoView ? 'w-[310px]' : 'w-[275px] mx-auto'}`}
    >
      <span className="flex gap-2 text-[14px] leading-5 normal-case">{message}</span>
    </div>
  ) : null

  return <>{renderToast}</>
}
