import { useEffect, useState } from 'react'

const ReportingCenter = () => {
  const { VITE_ENV } = import.meta.env
  const REPORTING_CENTER_BASE_URL =
    VITE_ENV === 'prod'
      ? 'https://reporting-center.labs.thryv.com'
      : VITE_ENV === 'development'
        ? 'https://dev-reporting-center.sandbox.thryv.com'
        : VITE_ENV === 'localhost'
          ? 'http://localhost:3001'
          : 'https://qa-reporting-center.staging.thryv.com'

  const [firstLoadRoute, setFirstLoadRoute] = useState('reports')

  const currentRoute = getCurrentRoute(window.location.href)

  // When selecting Reporting Center for the first time, change the iframe URL
  // For all future route changes, post a message to the iframe rather than
  // reloading the iframe with a new URL
  useEffect(() => {
    setFirstLoadRoute(getCurrentRoute(window.location.href))
  }, [])

  useEffect(() => {
    const iframe = document.getElementById('reporting-center-iframe')
    iframe.contentWindow.postMessage(
      { type: 'NAVIGATE', route: `/${currentRoute}` },
      '*',
    )
  }, [currentRoute])

  return (
    <div>
      <iframe
        allowFullScreen
        id="reporting-center-iframe"
        style={{
          width: '100%',
          height: 'calc(100vh - 66.18px)',
          border: 'none',
          overflow: 'hidden',
        }}
        src={`${REPORTING_CENTER_BASE_URL}/${firstLoadRoute}?env=commandcenter`}
      ></iframe>
    </div>
  )
}

export default ReportingCenter

const getCurrentRoute = (url) => {
  const trimmedUrl = url.endsWith('/') ? url.slice(0, -1) : url
  const segments = trimmedUrl.split('/')
  return segments[segments.length - 1]
}
