import styleSearchTermInString from '../../../../../../utils/styleSearchTermInString'
import formatContactData from '../../../../hooks/formatContactData'
import DOMPurify from 'dompurify'

// Components
import { Avatar, ParagraphText } from '@thryvlabs/maverick'
import { ThryvGuyIcon } from '../../../../../command-center-icon/icons'
import { ConversationAvatar } from '../../../conversation-avatar'
import { CommandCenterIcon } from '../../../../../../components/command-center-icon'

const UserCardAvatar = ({ className, isThryvSupport, imageUrl }) => {
  if (isThryvSupport) {
    return (
      <div className={`${className} w-6 h-6`}>
        <Avatar variant="icon" className="!w-6 !h-6">
          <ThryvGuyIcon fill="white" stroke="#ECEEF1" />
        </Avatar>
      </div>
    )
  }

  return <ConversationAvatar customSize={24} imgUrl={imageUrl} />
}

const markupWithBoldWord = (str, searchTerm) => {
  return {
    __html: DOMPurify.sanitize(
      styleSearchTermInString.allOccurences(str, searchTerm),
    ),
  }
}

const NameSpan = ({ show, name, searchTerm }) => (
  <>
    {show ? (
      <span dangerouslySetInnerHTML={markupWithBoldWord(name, searchTerm)} />
    ) : (
      <span>Unknown Contact</span>
    )}
  </>
)

const EmailSpan = ({ show, email, searchTerm }) => (
  <>
    {show ? (
      <span
        className="text-sm text-thryv-gray-medium-400 truncate"
        dangerouslySetInnerHTML={markupWithBoldWord(email, searchTerm)}
      />
    ) : null}
  </>
)

const PhoneSpan = ({ show, phoneNumber, searchTerm }) => (
  <>
    {show ? (
      <span
        className="text-sm text-thryv-gray-medium-400 truncate"
        dangerouslySetInnerHTML={markupWithBoldWord(phoneNumber, searchTerm)}
      />
    ) : null}
  </>
)

const VerticalLine = ({ show }) => (
  <>
    {show ? (
      <CommandCenterIcon
        variant="verticalLine"
        type="regular"
        height={14}
        width={2}
        color="red"
      />
    ) : null}
  </>
)

export const UserCard = ({
  imageUrl,
  name,
  email,
  phoneNumber,
  onClick,
  isThryvSupport,
  searchTerm,
}) => {
  const hoverAnimClass = 'hover:bg-thryv-white-300 duration-300 transition-all'

  return (
    <button
      className={`${hoverAnimClass} rounded flex items-center gap-2 p-2 hover:bg-thryv-white-300 cursor-pointer w-full`}
      onClick={onClick}
    >
      <UserCardAvatar imgUrl={imageUrl} isThryvSupport={isThryvSupport} />

      <ParagraphText
        variant="reg"
        color="thryv-black-500"
        className="flex items-center truncate gap-2 max-w-full"
      >
        <NameSpan
          show={(name || isThryvSupport) && true}
          name={isThryvSupport ? 'Thryv Support Center' : name}
          searchTerm={searchTerm}
        />

        <EmailSpan show={email && true} email={email} searchTerm={searchTerm} />

        <VerticalLine show={email && phoneNumber} />

        <PhoneSpan
          show={phoneNumber && true}
          phoneNumber={formatContactData(phoneNumber)}
          searchTerm={searchTerm}
        />
      </ParagraphText>
    </button>
  )
}
