import { forwardRef, useRef, useEffect } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Header, Icon, ParagraphText, Button } from '@thryvlabs/maverick'
import {
  setDisplayHyperlinkTooltip,
  setCurrHyperlinkInformation,
} from '../../../slices/messageComposeSlice'
import { HyperlinkModal } from './hyperlink-modal'

export const HyperlinkCustomTooltip = forwardRef((props, ref) => {
  const tooltipRef = useRef(null)
  const dispatch = useDispatch()
  const { offSetTop, offSetLeft, text, url } = useSelector(
    (state) => state.messageCompose.currHyperlinkInformation,
  )

  useEffect(() => {
    function handleClickOutside(event) {
      const hyperlinkModal = document.querySelector('#hyperlink-modal')
      if (
        !hyperlinkModal &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        !event.target.classList.contains('inline-hyperlink')
      ) {
        // we close the tooltip only when below two condition are true
        // 1. hyperlinkModal is currently close
        // 2. user click somewhere other then the hyperlinks and the tooltip
        batch(() => {
          dispatch(setDisplayHyperlinkTooltip(false))
          dispatch(
            setCurrHyperlinkInformation({
              offSetTop: '',
              offSetLeft: '',
              text: '',
              url: '',
            }),
          )
        })
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [tooltipRef])

  function removeTooltip() {
    batch(() => {
      dispatch(setDisplayHyperlinkTooltip(false))
      dispatch(
        setCurrHyperlinkInformation({
          offSetTop: '',
          offSetLeft: '',
          text: '',
          url: '',
        }),
      )
    })
  }

  return (
    <div
      ref={tooltipRef}
      className={`absolute bg-[#FFFFFF] rounded-[4px] drop-shadow p-[16px] w-[300px] h-[152px] z-30 flex flex-col justify-between`}
      style={{ top: `${offSetTop - 160}px`, left: `${offSetLeft}px` }}
    >
      <div className=" flex flex-col gap-[141px] sm:w-[300px] sm:gap-[10px] sm:py-0 ">
        <div className="flex w-full items-center">
          <div className="flex justify-start w-2/3 sm:w-full sm:items-center sm:mb-6">
            <Header variant="h7" fontWeight="semibold">
              {text}
            </Header>
          </div>
          <div className="flex w-1/3 justify-end sm:absolute sm:top-[25px] sm:right-[20px]">
            <Icon
              className="cursor-pointer"
              type="regular"
              variant="x"
              height="12"
              fill="#A3A5A7"
              onClick={removeTooltip}
            />
          </div>
        </div>
      </div>
      <ParagraphText variant="reg" className="break-all">
        {url}
      </ParagraphText>
      <div className="flex w-full justify-end items-center gap-4">
        <HyperlinkModal
          quillEditorRef={ref}
          disableButtons={false}
          currHyperlinkInformation={{ offSetTop, offSetLeft, text, url }}
        />
        <Button variant="primary" type="button">
          <a href={url} target="_blank" rel="noreferrer">
            Preview
          </a>
        </Button>
      </div>
    </div>
  )
})
