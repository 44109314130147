import { gql } from '@apollo/client'

export const CREATE_VONAGE_MEETING_ROOM = gql`
  mutation create_vonage_meeting_room_mutation($CC_UserID: ID!, $threadId: ID!) {
    createVonageMeetingRoom(CC_UserID: $CC_UserID, threadId: $threadId) {
      RoomID
      CC_UserID
      display_name
      type
      meeting_code
      host_url
      guest_url
      ThreadID
      created_at
      expired_at
      active
    }
  }
`
export const SUBSCRIPTION_FOR_MEETING_END = gql`
  subscription onSessionEnded($RoomID: String!) {
    onSessionEnded(RoomID: $RoomID) {
      SessionID
      RoomID
      started_at
      ended_at
      session_duration
      total_billed_duration
    }
  }
`

export const SUBSCRIPTION_FOR_MESSAGE_CREATED = gql`
  subscription onMessageCreated {
    onMessageCreated {
      sk1
      pk1
    }
  }
`

export const SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE = gql`
  subscription onGenericUpdate {
    onGenericUpdate {
      body
    }
  }
`
