import { Icon, Header } from '@thryvlabs/maverick'

export const NavButton = ({ className, children, onClick, active }) => {
  return (
    <button className={`${className} flex items-center`} onClick={() => onClick()}>
      <Header variant="h6" fontWeight="semibold" className="!text-thryv-night">
        {children}
      </Header>
      <div className="flex items-center justify-center w-6">
        <Icon
          variant="caretDown"
          type="solid"
          width="8"
          color="#4D4D4D"
          className={`transition-rotate duration-300 ${active && '-rotate-180'}`}
        />
      </div>
    </button>
  )
}
