import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useClickOutside } from '../../../../hooks/use-click-outside'
import { NavButton } from './nav-button'
import { NavMenu } from './nav-menu'
import { MenuBackdrop } from './nav-menu/menu-backdrop'

const getSelectedOption = (linksArray, selected) => {
  const { pathname } = useLocation()
  let selectedOption

  if (selected !== undefined) {
    selectedOption = linksArray[selected]
  } else {
    selectedOption = linksArray.find((link) => link.url === pathname)
  }

  return selectedOption
}

export const MobileNavigation = ({
  className,
  linksArray,
  selected,
  setSelectedOption,
}) => {
  const menuRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const title = getSelectedOption(linksArray, selected).title

  const toggleMenu = (show) => {
    show !== undefined ? setShowMenu(show) : setShowMenu(!showMenu)
  }

  useClickOutside((e) => {
    const navButton = document.getElementsByClassName(
      'command-center-nav-toggle-button',
    )[0]
    const navButtonClicked = e.composedPath().includes(navButton)
    if (!navButtonClicked) {
      toggleMenu(false)
    }
  }, menuRef)

  return (
    <>
      <MenuBackdrop show={showMenu} className="z-[99]" />

      <nav className={`${className} grow-0 z-100`}>
        <div className="relative bg-thryv-white-300 border-b border-[#E3E6E8] px-4 py-[15px]">
          <NavButton
            className="command-center-nav-toggle-button"
            onClick={toggleMenu}
            active={showMenu}
          >
            {title}
          </NavButton>
        </div>
        <NavMenu
          className="mt-1 ml-1"
          ref={menuRef}
          show={showMenu}
          linksArray={linksArray}
          unmount={false}
          toggleMenu={toggleMenu}
          setSelectedOption={setSelectedOption}
        />
      </nav>
    </>
  )
}
