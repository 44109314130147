import { useAuth0 } from '@auth0/auth0-react'
import { Button, Header, Icon } from '@thryvlabs/maverick'
import axios from 'axios'
import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CommandCenterIcon } from '../../../command-center-icon'
import { ThryvCCOrangeLogoIcon } from '../../../command-center-icon/icons'
import { useClickOutside } from '../../../../hooks'
import {
  setChannelIsConnected,
  setModalContent,
  setShowModal,
} from '../slices/connectChannelsSlice'
import getApolloLink from '../../../../utils/apollo-link'
import { useApolloClient } from '@apollo/client'
import { sha1 } from 'js-sha1'
const { VITE_NYLAS_HOST } = import.meta.env

export const VideoChannelModal = ({ refetchChannelData }) => {
  const isMobile = useMediaQuery({ maxWidth: 640 })
  // const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [, setErrorOnCheckbox] = useState(false)
  const videoRef = useRef(null)
  // const handleOnChange = () => {
  //   setIsChecked(!isChecked)
  //   setErrorOnCheckbox(false)
  // }

  const dispatch = useDispatch()
  const { user, getAccessTokenSilently } = useAuth0()
  const userCountry = user.country.length > 0 ? user.country : 'US'
  const client = useApolloClient()

  const hardReloadAuthToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: 'off' })
      const link = getApolloLink(accessToken)
      client.setLink(link)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error calling hardReloadAuthToken function:', error)
    }
  }

  const handleConnectVideo = async () => {
    setErrorOnCheckbox(false)
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    try {
      await axios.post(
        `${VITE_NYLAS_HOST}/connect_video_channel`,
        {
          ccid: user.cc_id,
          countryCode: userCountry,
          locationId: user.businessId,
        },
        options,
      )
      refetchChannelData()
      setIsLoading(false)
      dispatch(setModalContent(1))

      // invoke impact conversion function when channel is connected
      if (window.ire && !Array.isArray(window.ire)) {
        const { cc_id, email } = user
        if (email && cc_id) {
          window.ire(
            'trackConversion',
            43455,
            {
              orderId: 'IR_AN_64', // passing this value will create a UUID in impact
              customerId: cc_id,
              customerEmail: sha1(email),
            },
            {
              verifySiteDefinitionMatch: true,
            },
          )
        }
      }

      dispatch(setChannelIsConnected(true))
      await hardReloadAuthToken()
    } catch (error) {
      setIsLoading(false)
    }
  }

  useClickOutside((e) => {
    const videoRefClicked = e.composedPath().includes(videoRef.current)
    if (videoRefClicked) return
    else {
      dispatch(setModalContent(1))
      dispatch(setShowModal(false))
    }
  }, videoRef)

  return (
    <div
      ref={videoRef}
      className=" h-[643px] w-full p-5 mb-5 sm:h-[500px] sm:w-full sm:mb-[40px] flex flex-col items-center"
    >
      <ModalHeader />
      <div className="w-[496px] sm:w-full border border-[#E3E6E8] ">
        <div className="flex bg-[#F8F9FB] py-[32px] sm:py-[18px] items-center gap-[32px] justify-center">
          <ThryvCCOrangeLogoIcon
            width={isMobile ? 38 : 52}
            height={isMobile ? 38 : 52}
          />
          <Icon type="regular" variant="exchange" />
          <CommandCenterIcon
            variant={'video'}
            type={'solid'}
            width={isMobile ? 38 : 52}
            height={isMobile ? 38 : 52}
          />
        </div>
        <div className="bg-white sm:mt-2 mt-6">
          <p className="font-montserrat font-semibold sm:text-base text-lg text-center">
            Get started with Video Calls!
          </p>
          <div className="flex flex-col justify-center items-start sm:ml-4 xx-sm:ml-2 ml-12 mt-4 sm:mt-3 w-[378px] sm:w-[250px]">
            <ul className="list-none mb-[1rem] flex flex-col justify-start sm:gap-1 gap-3">
              <FeatureListItem
                text={'Instantly create video rooms.'}
                className={'sm:-ml-3 x-sm:-ml-6 x-sm:text-xs'}
              />
              <FeatureListItem
                className={'sm:mt-4 x-sm:-ml-3 x-sm:text-xs'}
                text={' Chat live over video with anyone, anywhere.'}
              />
              <FeatureListItem
                className={'sm:mt-4 x-sm:-ml-3 x-sm:text-xs'}
                text={'No download required for you or your clients'}
              />
            </ul>
          </div>
          <div className="flex gap-[16px] ml-[170px] pb-[60px] sm:ml-[0] sm:w-full sm:px-0 sm:flex sm:justify-center sm:pb-[30px]">
            {!isLoading ? (
              <Button
                variant="primary"
                className="sm:mt-4 mt-[4rem] sm:gap-x-0 gap-x-2 py-1.5 flex justify-evenly items-center"
                onClick={() => handleConnectVideo()}
              >
                <Icon
                  variant="link"
                  type="solid"
                  fill="white"
                  width="16"
                  height="14"
                />
                Connect to Video
              </Button>
            ) : (
              <div className="py-1.5 mt-8 sm:mt-5">
                <div className="dot-flashing ml-20 sm:ml-0 "></div>{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ModalHeader = () => {
  const dispatch = useDispatch()

  return (
    <div className="flex justify-center items-start flex-col gap-[16px] sm:mb-2 mb-4 sm:w-full">
      <div className="flex justify-between items-center w-full">
        <Header fontWeight="semibold" variant="h6" className="sm:text-[18px]">
          Connect Video
        </Header>
        <Button className="" variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => dispatch(setModalContent(1))}
          />
        </Button>
      </div>
      <p className="font-montserrat font-semibold text-base text-left sm:text-sm sm:w-full sm:text-center w-[568px]">
        Build strong relationships with real-time, direct, and personal
        communication.
      </p>
    </div>
  )
}

const FeatureListItem = ({ className, text }) => {
  return (
    <li className="text-sm flex gap-x-2 xx-sm:gap-x-3.5 items-center font-montserrat">
      <Icon type="solid" variant="check" width="14" height="12" fill="#5378FC" />
      <p className={`sm:w-[250px] xx-sm:w-[200px] sm:text-sm ${className} `}>
        {text}
      </p>
    </li>
  )
}
