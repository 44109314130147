import { useEffect, useState } from 'react'

export const useNotification = () => {
  // The following are in regards to the Notification
  const [show, setShow] = useState(false)
  const [variant, setVariant] = useState('')
  const [message, setMessage] = useState('')
  const [timeoutId, setTimeoutId] = useState({})

  const toggle = {
    changeState: function () {
      setShow(!show)
    },

    open: function () {
      setShow(true)
    },

    close: function () {
      clearTimeout(timeoutId)
      setShow(false)
    },

    success: function (message) {
      this.open()
      setVariant('success')
      setMessage(message)
    },

    error: function (message) {
      this.open()
      setVariant('error')
      setMessage(message)
    },
  }

  const timeoutNotification = () => {
    const newTimeoutId = setTimeout(() => {
      setShow(false)
    }, 5000)

    setTimeoutId(newTimeoutId)
  }

  useEffect(() => {
    if (show) timeoutNotification()
  }, [show])

  return {
    toggle,
    variant,
    message,
    show,
  }
}
