import styled from 'styled-components'

const grayscaleBlack = '#4D4D4D'
const thryvOrange = '#FF5000'

export const StepsContainerStyled = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 600;
  color: ${grayscaleBlack};
`

export const Step = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;

  padding-right: 5px;
  padding-left: ${(props) => (props.first ? '0px' : '5px')};
  color: ${(props) => (props.current ? thryvOrange : grayscaleBlack)};

  @media (max-width: 380px) {
    font-size: 14px;
  }
`
