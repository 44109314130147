import { Button, ParagraphText } from '@thryvlabs/maverick'
import { ReserveNumberIcon } from '../../../../../command-center-icon/icons'
import { useSelector } from 'react-redux'
import phoneFormatter from '../../../../../../utils/phoneFormatter'
// import { useAuth0 } from '@auth0/auth0-react'

const AssignedPhone = ({
  // handleShowRegisterModal,
  handleCloseConnectPhoneModal,
}) => {
  const provisionedNumber = useSelector(
    (state) => state.contacts.contacts.phoneNumber,
  )
  // const { user } = useAuth0()
  // const employee = user.employee
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const RenderCongratsTextBasedOnCountry = () => {
    // Checks if the user is in the United States
    if (countryISO === 'US') {
      return 'Your new phone number has been assigned successfully! Enjoy calls and voicemail right away.'
    }
    // Checks if the user is in New Zealand or Canada
    if (
      countryISO === 'NZ' ||
      (countryISO === 'CA' && provisionedNumber.startsWith('1'))
    ) {
      return 'Your new phone number has been assigned successfully! Enjoy calls and voicemail right away.'
    }
    // Checks if the user is in Australia
    if (countryISO === 'AU') {
      return 'Your new phone number has been assigned successfully! Enjoy calls and voicemail right away. Add your business name to send SMS.'
    }
    // If countryISO doesnt match above countries default to US text
    return 'Your new phone number has been assigned successfully! Enjoy calls and voicemail right away.'
  }
  return (
    <div>
      <div className="w-[436px] sm:w-full">
        <div className="flex mt-6 place-items-center justify-center">
          <ReserveNumberIcon className="z-10 ml-3" />
        </div>
        <p className="mt-5 mb-5 px-8 font-montserrat text-[22px] font-semibold leading-[30px]">
          Congratulations!
        </p>
        <div className="sm:w-full sm:mx-auto">
          <ParagraphText
            variant="reg"
            className="mb-5 text-sm font-montserrat leading-[20px]"
          >
            <RenderCongratsTextBasedOnCountry />
          </ParagraphText>
        </div>
        <div className="mb-10">
          <div className="font-montserrat text-base font-semibold">
            {phoneFormatter(provisionedNumber)}
          </div>
        </div>
        {/* {countryISO === 'US' && employee !== 'thryv' && (
          <div className="sm:w-[280px] sm:mx-auto">
            <ParagraphText
              variant="reg"
              className="mb-5 text-sm text-thryv-steel leading-[20px]"
            >
              *In compliance with mobile carrier regulation, new numbers must be{' '}
              <Button
                variant="text"
                level={1}
                className="underline"
                onClick={handleShowRegisterModal}
              >
                registered
              </Button>{' '}
              to send SMS messages.{' '}
              <Button
                variant="text"
                level={1}
                className="underline"
                onClick={handleShowRegisterModal}
              >
                Register now!
              </Button>
            </ParagraphText>
          </div>
        )} */}
      </div>
      <Button
        variant="primary"
        className="mt-16 sm:mt-6"
        onClick={handleCloseConnectPhoneModal}
      >
        Use This Number
      </Button>
    </div>
  )
}

export default AssignedPhone
