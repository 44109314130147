import { LoadingSpinner } from '../../../../common/loading-spinner'

export const SeeMoreConversationsButton = ({
  className,
  onClick,
  seeMoreClicked,
  loading,
}) => {
  return (
    <>
      {!seeMoreClicked && !loading ? (
        <button
          type="button"
          onClick={onClick}
          className={`${className} flex mx-auto px-4 mb-2 text-[14px] font-primary font-semibold leading-[18px] text-[#057AFF] cursor-pointer py-2 rounded hover:bg-[#00000008]`}
        >
          See More
        </button>
      ) : (
        <div className="flex justify-center py-3">
          <LoadingSpinner widthAndHeight={18} />
        </div>
      )}
    </>
  )
}
