const Teamchat = () => {
  const teamchatUrl = import.meta.env.VITE_TEAMCHAT_IFRAME_URL

  return (
    <div>
      <iframe
        allowFullScreen
        id="teamchat-iframe"
        style={{
          width: '100%',
          height: 'calc(100vh - 66.18px)',
          border: 'none',
          overflow: 'hidden',
        }}
        src={`${teamchatUrl}?env=commandcenter`}
      ></iframe>
    </div>
  )
}

export default Teamchat
