import { QuickSetupModal, Button, Header, RegularX } from '@thryvlabs/maverick'

const CustomModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    hasCrossIcon = false,
    close,
    heading,
    subHeading,
    children,
    width,
    showTitle,
    disableBackdropClick,
  } = props

  return (
    <QuickSetupModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      width={width}
      hideLeftSide={true}
      disableBackdropClick={disableBackdropClick}
    >
      {showTitle && (
        <div className="flex justify-between">
          <div className="flex flex-col">
            <Header variant="h4" fontWeight="semibold">
              {heading}
            </Header>
            <span className="text-[12px] text-thryv-steel pt-[10px]">
              {subHeading}
            </span>
          </div>
        </div>
      )}

      {hasCrossIcon && (
        <Button
          className="fixed right-[1%] top-[5%] mr-[0.5rem]"
          variant="text"
          level={2}
        >
          <RegularX
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => close()}
          />
        </Button>
      )}

      <div className="">{children}</div>
    </QuickSetupModal>
  )
}

export default CustomModal
