import styled from 'styled-components'
import { labelsMenuBtnStyles } from '../styled-labels-menu-btn'
import {
  ParagraphText,
  Input,
  ModalWrapper,
  ModalTitle,
  Icon,
  Button,
} from '@thryvlabs/maverick'
import { useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
import {
  setShowCreateLabelModal,
  setSelectedMessageForNewLabel,
} from '../../../slices/messageContentSlice'
import {
  CREATE_LABEL_ADD_TO_MESSAGE,
  FETCH_MESSAGES_BY_THREAD_ID,
  GET_LABEL_OPTIONS_BY_LOCATION,
} from '../../../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@apollo/client'
import { LoadingSpinner } from '../../../../common/loading-spinner'

const StyledButtonWrapper = styled.div`
  & > button {
    ${labelsMenuBtnStyles}
  }
`
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const CreateNewLabelButton = ({ pk1, threadpk, sk1 }) => {
  const dispatch = useDispatch()
  const setMessageForNewLabelAndOpenModal = () => {
    const channelID = pk1.split('#')[1]
    const threadID = pk1.split('#')[2]
    batch(() => {
      dispatch(
        setSelectedMessageForNewLabel({
          channel_id: channelID,
          thread_id: threadID,
          threadpk: threadpk.split('#')[0],
          message_sk1: sk1,
        }),
      )
      dispatch(setShowCreateLabelModal(true))
    })
  }

  return (
    <StyledButtonWrapper>
      <button onClick={setMessageForNewLabelAndOpenModal}>
        <ParagraphText variant="reg" color="thryv-black-500">
          Create new
        </ParagraphText>
      </button>
    </StyledButtonWrapper>
  )
}

const Modal = () => {
  const { showCreateLabelModal, selectedMessageForNewLabel } = useSelector(
    (state) => state.messageContent,
  )
  const [labelValue, setLabelValue] = useState('')
  const { user } = useAuth0()
  const dispatch = useDispatch()

  const closeModal = () => {
    batch(() => {
      dispatch(setSelectedMessageForNewLabel({}))
      dispatch(setShowCreateLabelModal(false))
    })
  }

  const [createLabelAndAddToMessage, { loading }] = useMutation(
    CREATE_LABEL_ADD_TO_MESSAGE,
    {
      variables: {
        location_id: user?.businessId || '',
        label_name: labelValue,
        status: '1',
        channel_id: selectedMessageForNewLabel.channel_id,
        thread_id: selectedMessageForNewLabel.thread_id,
        threadpk: selectedMessageForNewLabel.threadpk,
        message_sk1: selectedMessageForNewLabel.message_sk1,
      },
      refetchQueries: [GET_LABEL_OPTIONS_BY_LOCATION, FETCH_MESSAGES_BY_THREAD_ID],
      onCompleted: () => {
        closeModal()
      },
      onError: () => {
        //make error handling for this?
      },
    },
  )

  const addNewLabel = (e) => {
    e.preventDefault()
    createLabelAndAddToMessage()
  }

  const handleChange = (e) => {
    setLabelValue(e.target.value)
  }

  return (
    <ModalWrapper
      show={showCreateLabelModal}
      close={() => closeModal()}
      className="inline-block transform text-center overflow-hidden align-middle bg-white p-[32px] w-[575px] shadow-xl rounded md:p-[22px] md:m-auto md:w-[70%] md:my-[10px] "
    >
      <ModalHeader>
        <ModalTitle variant="primary"> New Label </ModalTitle>
        <Button variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="17"
            width="28"
            fill="#A3A5A7"
            onClick={() => closeModal()}
          />
        </Button>
      </ModalHeader>

      <div className="create-new-label-modal py-5">
        <div className="w-full h-[26px]">
          <Input
            className="w-full"
            type="text"
            placeholder="label name"
            name="newlabel"
            withLabel
            labelType="static"
            variant="default"
            onChange={handleChange}
          />
          {labelValue?.length > 64 && (
            <span className="text-notification-red text-xs ">
              Exceeded max length of 64 characters{' '}
            </span>
          )}
        </div>
      </div>

      <div className="py-4 flex float-right gap-4">
        <Button
          variant="text"
          level={2}
          textVariant="light"
          onClick={() => closeModal()}
        >
          {' '}
          Cancel
        </Button>
        {loading ? (
          <LoadingSpinner widthAndHeight={36} className={'mt-1'} />
        ) : (
          <Button
            variant="primary"
            disabled={!labelValue.length || labelValue.length > 64 ? true : false}
            onClick={(e) => addNewLabel(e)}
          >
            {' '}
            Add Label
          </Button>
        )}
      </div>
    </ModalWrapper>
  )
}

export const LabelButtonWithModal = ({ pk1, threadpk, sk1 }) => {
  return (
    <>
      <CreateNewLabelButton pk1={pk1} threadpk={threadpk} sk1={sk1} />

      <Modal />
    </>
  )
}
