import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { NavLink } from 'react-router-dom'
import checkmark from '../../../../assets/checkmark.png'
import { GET_STAFF } from '../../../../graphql/queries'
import { CloseModalButton } from '../close-modal-button'
import { Button, Header, ParagraphText } from '@thryvlabs/maverick'

export const StaffConfirmationModal = ({ close, staff }) => {
  const [currentUrl] = useState(window.location.href)
  const client = useApolloClient()

  const closeModalHandler = () => {
    client.refetchQueries({
      include: [GET_STAFF],
    })
    close()
  }
  const NavigateLink = ({ currentUrl }) => {
    return currentUrl.includes('settings/security-staff') ? (
      <span
        onClick={closeModalHandler}
        className="font-semibold text-utility-link-info underline cursor-pointer"
      >
        Security & Staff settings
      </span>
    ) : (
      <NavLink
        to="settings/security-staff"
        className="font-semibold text-utility-link-info underline"
        onClick={closeModalHandler}
      >
        Security & Staff settings
      </NavLink>
    )
  }

  return (
    <div className="w-[480px] max-h-[450px] h-[392px] md:w-[329px] md:h-[450px] bg-white z-10 flex flex-col items-center">
      <div className="self-end">
        <CloseModalButton onClick={closeModalHandler} />
      </div>
      <img
        src={checkmark}
        alt="checkmark icon"
        className="h-[110px] w-[110px] mt-[10px] mb-[10px] mx-auto"
      />
      <div className="flex flex-col gap-[32px] w-[331px] md:w-[300px] md:text-center items-center mb-[50px]">
        <Header fontWeight="semibold" variant="h1">
          New Staff Added!
        </Header>
        <ParagraphText variant="reg" color="thryv-steel">
          {staff} has been successfully added as a new staff member. You can review
          staff information in the <NavigateLink currentUrl={currentUrl} /> page.
        </ParagraphText>
      </div>
      <Button variant="primary" onClick={closeModalHandler}>
        Done
      </Button>
    </div>
  )
}
