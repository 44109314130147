import { useSelector } from 'react-redux'

const useCardData = () => {
  const cardData = useSelector((state) => state.connectChannels.cardData)

  const getCard = (provider) => {
    if (Array.isArray(cardData) && cardData.length) {
      const cardIndex = cardData.findIndex((card) => card.iconName === provider)
      return [cardIndex > -1 ? cardData[cardIndex] : null, cardIndex]
    }

    return [null, -1]
  }

  const getPhoneCard = () => {
    if (Array.isArray(cardData) && cardData.length) {
      const phoneCardIndex = cardData.findIndex((card) => card.iconName === 'calls')
      return [phoneCardIndex > -1 ? cardData[phoneCardIndex] : null, phoneCardIndex]
    }

    return [null, -1]
  }

  return { getCard, getPhoneCard, cardData }
}

export default useCardData
