import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { AppProviders } from './context'
import App from './App'
import './index.css'

const { VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID } = import.meta.env

;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  })

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <AppProviders>
          <App />
        </AppProviders>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()
