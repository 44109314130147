import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  ParagraphText as Text,
  Header,
  ModalWrapper,
  Button,
} from '@thryvlabs/maverick'

export const useAudioPermissionModal = (isMobile) => {
  const [isOpen, setIsOpen] = useState(true)
  const isXSmallMobile = useMediaQuery({ maxWidth: 375 })

  const Modal = (
    <ModalWrapper show={isOpen} close={setIsOpen} disableBackdropClick={true}>
      <div className="w-[500px] px-4 sm:px-1 sm:w-full xx-sm:px-0">
        <div className="flex justify-between items-center gap-8 w-full">
          <div className="flex gap-4 items-center">
            <Header
              fontWeight="semibold"
              variant={isXSmallMobile ? 'h6' : isMobile ? 'h4' : 'h2'}
            >
              Microphone Access Required
            </Header>
          </div>
        </div>
        <Text
          variant={isMobile ? 'sm' : 'lg'}
          className="my-5 text-left font-semibold leading-[22px]"
        >
          Command Center is unable to place or receive calls without access to your
          microphone. Please adjust your browser or app settings to allow access to
          your microphone to continue.
        </Text>
        <div className="flex flex-row justify-end">
          <Button variant="primary" onClick={() => setIsOpen(false)}>
            Okay
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
  return [isOpen, setIsOpen, Modal]
}
