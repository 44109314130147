import QuickActionType from "./quick-action-type"
import { QuickActionContextProvider } from "./context/quick-action-context"
import quickActionsOptions from "../../../../../../json/quick-action-options.json"
import useDisplayCenter from "../../../../../../hooks/use-display-center";

import QuickActionButton from "./quick-action-button";

export const QuickActionModal = () => {
  const { displayCenter } = useDisplayCenter()

  return (
    <div>
      <QuickActionContextProvider>
        {quickActionsOptions.map((btn, idx) => {
          return <QuickActionButton btn={btn} key={`quick-action-btn-${idx}`} displayCenter={displayCenter} /> 
        })}
        <QuickActionType />
      </QuickActionContextProvider>
    </div>
  )
}