import { useState } from 'react'
import { Button } from '@thryvlabs/maverick'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getCountryCurrency } from '../../../../utils/get-country-currency'

export const InvoiceCouponsMobile = ({ tableData, loading }) => {
  const [items] = useState(['Preview', 'Download'])
  const countryCode = useSelector((state) => state.countryCode.countryIso2)

  return (
    <div>
      {loading ? (
        <div className="flex justify-center">
          <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] box-border animate-spin relative top-3"></span>
        </div>
      ) : (
        <>
          {tableData.length > 0 ? (
            <>
              {tableData.map((data, index) => (
                <div key={index}>
                  <div className="p-[0.5rem] w-full flex justify-between items-center h-[48px] bg-[#F8F9FB] mt-[1rem] mb-[1rem]">
                    <span className="text-[12px] text-[#4D4D4D] font-semibold open-sans">
                      {moment(data.BILLED_DATE.split(' ')[0]).format('MMM Do, YYYY')}{' '}
                      |{' '}
                      <span className="text-[] font-semibold">
                        {' '}
                        {`$${data.INVOICE_TOTAL} ${getCountryCurrency(
                          countryCode,
                        )}`}{' '}
                      </span>
                    </span>

                    <Button variant="secondary" buttonType="dropdown" items={items}>
                      Actions
                    </Button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex justify-center pt-5 text-thryv-steel font-semibold font-montserrat text-[14px]">
              You do not have any payment history to show.
            </div>
          )}
        </>
      )}
    </div>
  )
}
