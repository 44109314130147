import { ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../command-center-icon'

export const SearchView = () => {
  return (
    <div className="flex h-full w-full justify-center flex-col items-center">
      <CommandCenterIcon variant="thryvMagnify" color={'black'} type="solid" />
      <div className="mt-8 flex-col p-2">
        <ParagraphText
          variant="lg"
          className="font-semibold w-full text-center font-montserrat text-thryv-black-600 "
        >
          Find what you are looking for?
        </ParagraphText>
        <div className="w-full flex justify-center items-center mt-2 p-1">
          <ParagraphText variant="sm" className="text-center mr-2">
            Try advanced search by clicking
          </ParagraphText>
          <CommandCenterIcon
            type="solid"
            variant="filter"
            fill="#808080"
            width="14"
            className="mt-0.5"
          />
        </div>
      </div>
    </div>
  )
}
