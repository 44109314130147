import { useState, useEffect, useRef } from 'react'

// In this function I mention text. I am referring to the greyed text within each FilterOption.
// https://i.imgur.com/PVFd1H5.png
export const FilterItemGroup = ({ children, className }) => {
  const filterGroupRef = useRef()
  const [width, setWidth] = useState(0)

  const getLargestTextWidth = (filterGroup) => {
    const filterGroupChildren = [...filterGroup.children]

    let largestWidth = 0
    filterGroupChildren.forEach((filterOption) => {
      const textWidth = filterOption.firstChild.offsetWidth
      if (textWidth > largestWidth) {
        largestWidth = textWidth
      }
    })

    return largestWidth
  }

  const updateAllTextWidths = (filterGroup) => {
    const filterGroupChildren = [...filterGroup.children]

    if (width > 0) {
      filterGroupChildren.forEach((filterOption) => {
        const paragraphElem = filterOption.firstChild
        paragraphElem.style.width = `${width}px`
        paragraphElem.style.minWidth = `${width}px`
      })
    }
  }

  useEffect(() => {
    const filterGroup = filterGroupRef.current
    const largestWidth = getLargestTextWidth(filterGroup)
    // Adding 1 prevents potential text wrapping
    setWidth(largestWidth + 1)
  }, [filterGroupRef])

  useEffect(() => {
    const filterGroup = filterGroupRef.current
    updateAllTextWidths(filterGroup)
  }, [filterGroupRef, width])

  return (
    <div
      className={`flex flex-col gap-4 ${className}`}
      role="filtergroup"
      ref={filterGroupRef}
    >
      {children}
    </div>
  )
}
