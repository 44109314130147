import { CommanCenterLoading } from '@icons'

export function AuthLoader({ width = 'screen', height = 'screen' }) {
  return (
    <div
      role="redirect-status"
      className={`relative h-${height} w-${width} flex flex-col items-center justify-center bg-thryv-gray-light-200`}
    >
      <CommanCenterLoading />
      <div className="w-[12.5rem] bg-barely-gray rounded-md h-1 dark:bg-gray-700 mt-4">
        <div className="bg-[#818181] h-1 rounded-md animate-authLoading" />
      </div>
    </div>
  )
}
