import { useRef, useState, useEffect } from 'react'
import { useClickOutside } from '../../../../../../../../hooks/use-click-outside'

// Components
import { ParagraphText, Avatar } from '@thryvlabs/maverick'
import { PermissionDropdown } from '../../components/permissions-dropdown'

export const UserPermissionsRow = ({
  user,
  onPermissionChange,
  initialAccessType,
  readonly,
}) => {
  const dropdownButtonRef = useRef(null)

  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState(
    initialAccessType || 'Read / Write',
  )

  const handlePermissionSelection = (newPermission) => {
    setSelectedPermission(newPermission)
    onPermissionChange && onPermissionChange(newPermission)
  }

  useClickOutside(() => {
    setShowDropDown(false)
  }, dropdownButtonRef)

  useEffect(() => {
    setSelectedPermission(initialAccessType)
  }, [initialAccessType])

  return (
    <div className="flex items-center justify-between w-full border-b border-thryv-white-300 p-2">
      <div className="flex items-center gap-2 w-[145px]">
        <Avatar variant="name" size="small" className="p-4" name={user} />
        <ParagraphText
          data-testid="user-permissions-channel"
          variant="reg"
          className="capitalize truncate"
        >
          {user.firstName} {user.lastName}
        </ParagraphText>
      </div>
      <div className="w-[200px] flex justify-start">
        <ParagraphText variant="reg" color="thryv-steel" className="truncate">
          {user.email}
        </ParagraphText>
      </div>
      <PermissionDropdown
        ref={dropdownButtonRef}
        setSelectedPermission={handlePermissionSelection}
        selectedPermission={selectedPermission}
        setShow={setShowDropDown}
        show={showDropDown}
        readonly={readonly}
      />
    </div>
  )
}
