import styled from 'styled-components'
import { Button } from '@thryvlabs/maverick'

export const StyledInboxDropdownButton = styled(Button)`
  & > div > button {
    background-color: white;
    border-color: white;
    padding: 0px 0 0px 0;
    min-width: 0px;
  }

  & > div > button:hover {
    background-color: white;
    border-color: white;
  }

  & > div > button > svg {
    display: none;
  }

  /* ----- List Container ----- */
  & > div > div {
    padding: 8px;
    z-index: 10;
    margin-top: 6px;
  }

  /* ----- List Item ----- */
  & > div > div > div {
    border-radius: 4px !important;
  }

  /* ----- List Item text / Button ----- */
  & > div > div > div > button {
    font-size: 14px !important;
    padding: 8px 16px;
  }

  & > div > div > div > button[id*='headlessui-menu-item'] {
    border-radius: 4px;
  }

  & > div > div > div > button[id*='headlessui-menu-item']:hover {
    background-color: #f2f4f6;
  }
`
