import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'
import { forwardRef } from 'react'
import { TransitionContainer } from '../transition-container'

const DropdownList = ({ items, show, setShow, select, isMobile }) => {
  const handleClick = (btnText) => {
    setShow(!show)
    select(btnText)
  }

  return (
    <div className="absolute">
      <TransitionContainer
        show={show}
        className="flex flex-col mt-2 bg-white shadow-md rounded"
        positioning="relative"
        transformOrigin="top"
      >
        {items.map((item, idx) => {
          return (
            <button
              data-testid={`${item}-btn`}
              key={idx}
              className={`${
                isMobile ? `w-[80px]` : `w-[110px]`
              }  flex justify-start rounded-sm items-center py-2 px-5 hover:bg-thryv-white-300 bg-white`}
              onClick={() => handleClick(item)}
            >
              <ParagraphText variant="sm">{item}</ParagraphText>
            </button>
          )
        })}
      </TransitionContainer>
    </div>
  )
}

const ActionButtonDropDown = forwardRef(
  ({ items, setSelectedItem, show, setShow, isMobile }, actionButtonRef) => {
    return (
      <div ref={actionButtonRef}>
        <Button
          data-testid="action-btn"
          variant="secondary"
          className={`${
            isMobile ? `w-[80px]` : `w-[110px]`
          } rounded-md flex items-center justify-evenly gap-1`}
          onClick={() => setShow(!show)}
        >
          <ParagraphText variant="sm">Action</ParagraphText>
          <Icon
            variant="caretDown"
            type="solid"
            width="11"
            className={`${show ? 'rotate-180' : null} transition duration-300`}
          />
        </Button>

        <DropdownList
          show={show}
          setShow={setShow}
          items={items}
          isMobile={isMobile}
          select={setSelectedItem}
        />
      </div>
    )
  },
)

export default ActionButtonDropDown
