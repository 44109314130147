import { createSlice } from '@reduxjs/toolkit'

const notificationsMap = {
  inbox: 'Inbox',
  teamchat: 'TeamChat',
  calls: 'Calls',
  voicemails: 'Voicemail',
  transactions: 'Transactions',
  scheduledPayments: 'Scheduled Payments',
  invoices: 'Invoices',
  documents: 'Documents',
  overview: 'Overview',
  recommendations: 'Recommendations',
  analytics: 'Analytics',
  landingPages: 'Landing Pages',
  campaigns: 'Campaigns',
  tools: 'Tools',
  myPay: 'MyPay',
  salesDashboard: 'Sales Dashboard',
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    inbox: 0,
    teamchat: 0,
    calls: 0,
    voicemails: 0,
    transactions: 0,
    scheduledPayments: 0,
    invoices: 0,
    documents: 0,
    overview: 0,
    recommendations: 0,
    analytics: 0,
    landingPages: 0,
    campaigns: 0,
    tools: 0,
    myPay: 0,
    salesDashboard: 0,
  },
  reducers: {
    setInboxNotifications(state, action) {
      state.inbox = action.payload
    },
    setCallsNotifications(state, action) {
      if (action.payload.voicemails) {
        state.voicemails = action.payload.voicemails
      }

      if (action.payload.calls) {
        state.calls = action.payload.calls
      }
    },
    setRecentCallsNotifications(state) {
      state.calls = 0
    },
    setVoicemailsNotifications(state) {
      state.voicemails = 0
    },
    setTeamchatNotifications(state, action) {
      state.teamchat = action.payload
    },
  },
})

export const {
  setInboxNotifications,
  setCallsNotifications,
  setDoNotDisturb,
  setRecentCallsNotifications,
  setVoicemailsNotifications,
  setTeamchatNotifications,
} = notificationsSlice.actions

export const selectNotificationsCount = (state) => {
  const notifications = {}
  Object.keys(state.notifications).forEach((key) => {
    notifications[notificationsMap[key]] = state.notifications[key]
  })
  return notifications
}

export default notificationsSlice.reducer
