import { Icon } from '@thryvlabs/maverick'

export const CloseModalButton = ({ onClick, height, width }) => (
  <button onClick={onClick}>
    <Icon
      variant="x"
      type="regular"
      width={width || '18'}
      height={height || '18'}
      color="#A3A5A7"
    />
  </button>
)
