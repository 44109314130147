import { ParagraphText, Icon, Button } from '@thryvlabs/maverick'
import { forwardRef } from 'react'
import { TransitionContainer } from '../../../../../../transition-container'

export const Dropdown = ({ show, setShowDropDown, setSelectedPermission }) => {
  const buttons = ['Read / Write', 'Read Only', 'No Access']

  const handleClick = (btnText, e) => {
    e.preventDefault()
    setShowDropDown(false)
    setSelectedPermission(btnText)
  }

  return (
    <div className="relative">
      <TransitionContainer
        show={show}
        className="flex flex-col mt-1 absolute bg-white shadow-md rounded"
        transformOrigin="top"
      >
        {buttons.map((btn, idx) => {
          return (
            <button
              key={idx}
              data-testid="channel-permissions-btn"
              className="w-[138px] flex justify-start rounded-sm items-center py-2 px-5 hover:bg-thryv-white-300"
              onClick={(e) => handleClick(btn, e)}
            >
              <ParagraphText variant="reg">{btn}</ParagraphText>
            </button>
          )
        })}
      </TransitionContainer>
    </div>
  )
}

export const PermissionDropdown = forwardRef(
  (
    { disabled, show, setShow, setSelectedPermission, selectedPermission, readonly },
    dropdownButtonRef,
  ) => (
    <div ref={dropdownButtonRef}>
      <Button
        disabled={disabled}
        variant="secondary"
        className={`${
          disabled
            ? 'opacity-50 pointer-events-none'
            : 'w-[138px] rounded-md flex items-center justify-evenly gap-1 '
        } 
          ${readonly && 'pointer-events-none'}
        `}
        onClick={() => setShow(!show)}
      >
        <ParagraphText variant="reg">{selectedPermission} </ParagraphText>
        {!readonly ? (
          <Icon
            variant="caretDown"
            type="solid"
            width="11"
            className={`${show ? 'rotate-180' : null} transition duration-300`}
          />
        ) : null}
      </Button>

      <Dropdown
        show={show}
        setSelectedPermission={setSelectedPermission}
        setShowDropDown={setShow}
      />
    </div>
  ),
)
