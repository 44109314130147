import { useState } from 'react'
import {
  CirclePauseIcon,
  PlayButtonIcon,
} from '../../../../command-center-icon/icons'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import formatContactData from '../../../../inbox/hooks/formatContactData'
import { LoadingSpinner } from '../../../../common/loading-spinner'

const VoicemailDate = ({ voicemail }) => {
  const voicemailDate = moment(voicemail?.ts).local().format('ll')

  return (
    <div className="flex items-center pr-[16px] pl-[10px] ">
      <span className="flex pb-[2px]">{voicemailDate}</span>
    </div>
  )
}
const VoicemailContent = ({
  voicemail,
  voiceMailOpen,
  setVoicemailOpen,
  isVoicemailExist,
  audioBlob,
  isLoadingAudio,
}) => {
  const [voicemailPlayed] = useState(false)

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: voicemail?.threadpk?.split('#')?.at(0) },
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''

  const fullName = firstName + ' ' + lastName

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  return (
    <div className="flex w-full pr-[20px]">
      <div className="flex pr-[8.5px] pl-[20px] items-center">
        <CallAvatar call={voicemail} firstName={firstName} lastName={lastName} />
      </div>
      <div className={`flex ml-[10px] pr-[20px] items-center `}>
        <div
          className={`${
            !voicemailPlayed ? '' : ''
          } block w-[150px] max-w-[1352px] truncate font-semibold`}
        >
          {contact && fullName.length > 1 && !/^\d/.test(firstName)
            ? fullName
            : formattedPhoneNumber}
        </div>
      </div>
      <div className="flex items-center w-full justify-end ">
        <div className="flex text-thryv-steel text-[14px] py-1">
          <div className="flex items-center">
            {voicemail?.ts ? (
              <span className="flex pr-[16px]">
                {isVoicemailExist ? `Available` : `Expired`}
              </span>
            ) : null}
          </div>
          <div className="border-r-[1px] border-[#ccc]" />
          <VoicemailDate voicemail={voicemail} />
          <div className="flex flex-row items-center">
            <AudioPlayer
              isVoicemailExist={isVoicemailExist}
              audioBlob={audioBlob}
              isLoadingAudio={isLoadingAudio}
            />
            <button
              onClick={() => {
                setVoicemailOpen(!voiceMailOpen)
              }}
            >
              <FontAwesomeIcon
                className={`${
                  voiceMailOpen
                    ? 'transform rotate-180 duration-300'
                    : 'transform duration-300'
                } flex hover:cursor-pointer`}
                size="lg"
                icon={faChevronDown}
                data-testid="voicemail-chevron"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const AudioPlayer = ({ isVoicemailExist, audioBlob, isLoadingAudio }) => {
  const [audio, setAudio] = useState()
  const [playingAudio, setPlayingAudio] = useState(false)

  const playAudio = () => {
    const audioURL = URL.createObjectURL(audioBlob)
    const audio = new Audio(audioURL)
    setAudio(audio)
    audio.play()
    audio.addEventListener('play', () => setPlayingAudio(true))
    audio.addEventListener('ended', () => setPlayingAudio(false))
  }

  return (
    <div className="flex pr-[16px]">
      {isLoadingAudio ? (
        <LoadingSpinner widthAndHeight={'24px'} />
      ) : !playingAudio ? ( //play btn
        <button
          disabled={!isVoicemailExist}
          onClick={() => playAudio()}
          className={`${
            !isVoicemailExist ? 'opacity-40 pointer-events-none' : 'opacity-100'
          }`}
        >
          <PlayButtonIcon />
        </button>
      ) : (
        //pause btn
        <button
          onClick={() => {
            audio.pause()
            setPlayingAudio(false)
          }}
        >
          <CirclePauseIcon width={24} height={24} active={true} />
        </button>
      )}
    </div>
  )
}

export default VoicemailContent
