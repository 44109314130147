import placeholderImg from '../../../../assets/placeholder-profile-image.svg'

const initStyles = (size) => {
  const validSizes = ['sm', 'reg']

  switch (size) {
    case 'sm':
      return 'h-6 w-6'
    case 'reg':
      return 'h-12 w-12'
    default:
      throw new Error(
        `Please use one of the following sizes: ${validSizes.join(', ')}`,
      )
  }
}

const initCustomSize = (size) => {
  if (typeof size !== 'number') {
    throw new Error('Please use a number in order to use customSize')
  } else if (size <= 0) {
    throw new Error('Please use a number that is greater then 0 to use customSize')
  }

  return { height: size, width: size }
}

export const ConversationAvatar = ({
  className,
  imgUrl,
  alt,
  variant = '',
  size,
  customSize,
}) => {
  if (size !== undefined && customSize !== undefined) {
    throw new Error('Please only use the "size" or "customSize" prop')
  }

  const avatarSizeStyles = customSize === undefined ? initStyles(size) : ''
  const avatarCustomSize = customSize !== undefined ? initCustomSize(customSize) : {}

  const Border = ({ isHidden }) => (
    <div
      className={`absolute h-12 w-12 rounded-full border-thryv-orange-500 border-[1.8px] border-solid ${
        isHidden && 'hidden'
      }`}
    ></div>
  )

  const RoundedImage = () => {
    return (
      <div
        className={`${avatarSizeStyles} rounded-full overflow-hidden`}
        style={avatarCustomSize}
      >
        <img
          className="w-full h-full object-cover"
          src={imgUrl || placeholderImg}
          alt={alt || ''}
        />
      </div>
    )
  }

  return (
    <div className={`relative flex justify-center items-center ${className || ''}`}>
      <Border isHidden={variant !== 'active' && true} />
      <RoundedImage />
    </div>
  )
}
