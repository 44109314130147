import { ParagraphText } from '@thryvlabs/maverick'
import { useEffect, useState, useRef } from 'react'
import ChannelsIcon from '../../../channels-icon/channels-icon'
import formatContactData from '../../../inbox/hooks/formatContactData'
import { StyledCommandCenterDropdownButton } from '../../command-center-dropdown-button'
import { PermissionDropdown } from '../../modals/manage-channels-modal/channel-container/user-permissions-table/components/permissions-dropdown'
import { useAuth0 } from '@auth0/auth0-react'
import { useClickOutside } from '../../../../hooks'
import {
  STAFF_PERMISSIONS,
  STAFF_PERMISSIONS_INT,
} from '../../modals/manage-channels-modal/channel-container/user-permissions-table/utils/staff-permissions'

export const ConnectedChannelRow = ({
  variant,
  channel,
  onChange,
  className,
  currentRole,
  isEdit,
  staffAccessType,
  setNewStaffPermissions,
}) => {
  const { nickname, credential, channelId } = channel
  const { user } = useAuth0()
  const [items] = useState(['Read / Write', 'Read only', 'No Access'])
  const [selectedItem, setSelectedItem] = useState('Read only')
  const [disabled, setDisabled] = useState(false)
  const [dropdownElement, setDropdownElement] = useState(null)
  const [dropdownTop, setDropdownTop] = useState(null)
  const [dropdownWidth, setDropdownWidth] = useState(null)
  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState(
    STAFF_PERMISSIONS_INT[staffAccessType] || 'Read / Write',
  )
  const dropdownButtonRef = useRef(null)
  const handlePermissionSelection = (newPermission) => {
    setSelectedPermission(newPermission)

    if (isEdit) {
      setNewStaffPermissions((prevPermissions) => {
        const existingPermission = prevPermissions.find(
          (permission) => permission.ChannelID === channelId,
        )

        if (existingPermission) {
          return prevPermissions.map((permission) =>
            permission.ChannelID === channelId
              ? { ...permission, AccessType: STAFF_PERMISSIONS[newPermission] }
              : permission,
          )
        } else {
          return [
            ...prevPermissions,
            { AccessType: STAFF_PERMISSIONS[newPermission], ChannelID: channelId },
          ]
        }
      })
    } else {
      return
    }
  }

  useClickOutside(() => {
    setShowDropDown(false)
  }, dropdownButtonRef)

  useEffect(() => {
    if (currentRole === 'admin') {
      setSelectedPermission('Read / Write')
      setNewStaffPermissions((prevPermissions) => {
        const existingPermission = prevPermissions.find(
          (permission) => permission.ChannelID === channelId,
        )
        const newState = [
          ...prevPermissions,
          { AccessType: STAFF_PERMISSIONS['Read / Write'], ChannelID: channelId },
        ]
        if (existingPermission) {
          return prevPermissions.map((permission) =>
            permission.ChannelID === channelId
              ? { ...permission, AccessType: STAFF_PERMISSIONS['Read / Write'] }
              : permission,
          )
        } else {
          return newState
        }
      })
    }
  }, [currentRole])

  useEffect(() => {
    onChange && onChange(selectedItem)
  }, [selectedItem])

  useEffect(() => {
    const dropdownButton = dropdownElement?.firstChild?.firstChild?.firstChild
    if (!dropdownButton) return () => null

    const setDropdownDimensions = (e) => {
      const staffModalElementOffsetTop =
        document.querySelector('.add-staff-modal')?.parentElement?.offsetTop ?? 0
      const boundingClientRect = e?.target?.getBoundingClientRect()
      const popoverTop = boundingClientRect?.top ?? 0
      const popoverHeight = boundingClientRect?.height ?? 0

      setDropdownTop(popoverTop - staffModalElementOffsetTop + popoverHeight)
      setDropdownWidth(boundingClientRect?.width)
    }

    dropdownButton?.addEventListener('click', setDropdownDimensions)

    return () => {
      dropdownButton?.removeEventListener('click', setDropdownDimensions)
    }
  }, [dropdownElement])

  const initSelectedItem = () => {
    switch (currentRole) {
      case 'Admin':
        setSelectedItem('Read / Write')
        setDisabled(true)
        break
      case '':
        setDisabled(true)
        setSelectedItem('Read / Write')
        break
      default:
        setDisabled(false)
    }

    switch (variant) {
      case 'video':
        setDisabled(true)
        setSelectedItem('Read / Write')
        break
    }
  }

  useEffect(() => {
    initSelectedItem()
  }, [currentRole])

  return (
    <div className={` ${className} flex justify-between`}>
      <div className="flex items-center gap-[10px]">
        <div className="w-[34px] h-[34px]">
          <ChannelsIcon variant={variant} className="w-full h-full" />
        </div>
        <ParagraphText variant="reg" className="sm:max-w-[175px] sm:truncate">
          {nickname ? `${nickname} (${credential})` : ''}
          {!nickname
            ? Number(credential)
              ? formatContactData(credential)
              : credential
            : null}
        </ParagraphText>
      </div>

      {isEdit ? (
        <PermissionDropdown
          disabled={
            currentRole === 'owner' ||
            (user.cc_role !== 3 && currentRole === 'admin') ||
            (user.cc_role !== 2 && currentRole === 'admin')
          }
          ref={dropdownButtonRef}
          setSelectedPermission={handlePermissionSelection}
          selectedPermission={selectedPermission}
          setShow={setShowDropDown}
          show={showDropDown}
          readonly={currentRole === 'admin' || currentRole === 'owner'}
        />
      ) : (
        <StyledCommandCenterDropdownButton
          ref={setDropdownElement}
          className={`${disabled && 'opacity-50 pointer-events-none'}`}
          items={items}
          setSelectedItem={setSelectedItem}
          data-testid="connected-channel-btn"
          $top={dropdownTop}
          $width={dropdownWidth}
        >
          {!selectedItem ? 'Read only' : selectedItem}
        </StyledCommandCenterDropdownButton>
      )}
    </div>
  )
}
