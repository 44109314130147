import { useContext, useEffect } from "react"
import { useAuth0 } from '@auth0/auth0-react'
import { QuickActionContext } from "../context/quick-action-context"

const ChargeCard = () => {
    // https://dev-thryvpay-beta.sandbox.thryv.com
    const THRYVPAY_URL = import.meta.env.VITE_ENV === 'prod' ? "https://thryvpayfullstack-prod.thryv.com" : "https://thryvpay-staging.thryv.com"
    
    const { setIsActionModal, setPickedModal } = useContext(QuickActionContext)
    const { user } = useAuth0()

    useEffect(() => {
        closeModalListener()
    }, [])

    const closeModalListener = () => {
        const listeningToThryvPay = window.addEventListener(
            "message",
            (event) => {
                if (event.origin === THRYVPAY_URL) {
                    if (event.data?.closeModal) {
                        setIsActionModal(false)
                        setPickedModal("")
                    }
                }
            },
        )
        window.removeEventListener("message", listeningToThryvPay)
    }

    return (
        <div>  
            <iframe
                width='100%'
                height='600px'
                src={`${THRYVPAY_URL}/command-center-charge?${user.businessId}`}
            /> 
        </div>
    )
}

export default ChargeCard