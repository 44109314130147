import { ParagraphText } from '@thryvlabs/maverick'

export const FilterItem = ({ className, title, children }) => {
  return (
    <div className={`flex ${className}`}>
      <ParagraphText variant="reg" color="thryv-steel" className="md:text-base">
        {title}
      </ParagraphText>
      <div className="flex-grow ml-4">{children}</div>
    </div>
  )
}
