import './delete-modal.css'
import { useMutation } from '@apollo/client'
import { DELETE_STAFF } from '../../../../../../../../../../../graphql/mutations'
import {
  GET_STAFF,
  HAS_AVAILABLE_SEATS_FOR_STAFF,
} from '../../../../../../../../../../../graphql/queries'
import { LoadingSpinner } from '../../../../../../../../../../../components/common/loading-spinner'
import { useMediaQuery } from 'react-responsive'

// Components
import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'

const CloseModalButton = ({ onClick, height, width }) => (
  <button onClick={onClick}>
    <Icon
      variant="x"
      type="regular"
      width={width || '18'}
      height={height || '18'}
      color="#A3A5A7"
    />
  </button>
)

export const DeleteModal = ({ close, userToDelete, onDelete }) => {
  const [deleteStaff, { loading }] = useMutation(DELETE_STAFF, {
    refetchQueries: [GET_STAFF, HAS_AVAILABLE_SEATS_FOR_STAFF],
  })
  const isMobile = useMediaQuery({ maxWidth: 640 })

  const { id: userId } = userToDelete

  const handleCloseAndStaffDeletion = async () => {
    await deleteStaff({
      variables: {
        UserId: userId,
      },
      onCompleted: () => {
        close()
        if (onDelete) onDelete()
      },
      onError: () => {},
    })
  }

  return (
    <div
      className={`${
        isMobile ? '' : 'delete-staff-modal'
      } text-start sm:max-w-[300px]`}
    >
      {/* ----- HEADER ----- */}
      <div className="flex justify-between items-center mb-[26px] sm:max-w-[300px]">
        <h4 className="font-semibold font-[Montserrat] text-[22px] leading-[30px] text-thryv-black-500">
          Delete Staff
        </h4>
        <CloseModalButton onClick={close} />
      </div>

      {/* ----- TEXT ----- */}
      <div className="mb-[118px]">
        <ParagraphText variant="reg">
          Are you sure you want to delete this staff member?
        </ParagraphText>
        <ParagraphText variant="reg">This step cannot be undone.</ParagraphText>
      </div>

      {/* ----- SUBMISSION BUTTONS ----- */}
      <div className="flex justify-end items-center gap-4 h-[33.69px]">
        {loading ? (
          <LoadingSpinner widthAndHeight={36} />
        ) : (
          <>
            <Button variant="text" level={2} textVariant="light" onClick={close}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleCloseAndStaffDeletion}
            >
              Delete
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
