export const getAttachmentS3Paths = (messages, selectedThreadId, allAttachments) => {
  const channelID = messages[0]?.chanpk
  const channelCCID = messages[0]?.pk1.split('#')[0]
  const attachmentsS3Path = []

  allAttachments.forEach((file) => {
    const s3_path = `${channelCCID}/${channelID}/${selectedThreadId}/${file.sk1}`
    attachmentsS3Path.push(s3_path)
  })

  return attachmentsS3Path
}
