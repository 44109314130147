const checkIsRoundedSquareIcon = (variant) => {
  switch (variant) {
    case 'gmail':
      return true
    case 'phone':
      return true
    case 'yahoo':
      return true
    case 'icloud':
      return true
    case 'video':
      return true
    case 'ttl':
      return true
    case 'aol':
      return true
    case 'hotmail':
      return true
    case 'webchat':
      return true
    case 'imap':
      return true
    case 'incomingCall':
      return true
    case 'missedCall':
      return true
    case 'outgoingCall':
      return true
    case 'voicemail':
      return true
    case 'microsoft365':
      return true
    default:
      return false
  }
}

export default checkIsRoundedSquareIcon
