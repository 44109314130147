import { Input } from '@thryvlabs/maverick'

export const formNames = ['ctaImage']

export const getFormNames = (campaignType) => {
  if (campaignType === 'TEXT_TO_JOIN') {
    return []
  }
  return formNames
}

const CTAImage = ({ register, errors }) => {
  return (
    <>
      <div className="my-2 pl-3 font-open-sans text-xs leading-[18px] text-left">
        <ul className="list-disc pl-4">
          <li>Provide a link to the CTA image.</li>
          <li>
            The image should either be a real example of the CTA in use or a
            representative mockup.
          </li>
          <li>
            Regardless of how many places the message is displayed, please provide
            only one image.
          </li>
        </ul>
      </div>
      <div className="flex w-full mt-[32px]">
        <div className="grow">
          <Input
            type="text"
            withLabel
            name="ctaImage"
            placeholder="Image URL"
            labelType="floating"
            variant="default"
            register={register}
            className="w-full"
            errors={errors}
          />
        </div>
      </div>
    </>
  )
}

export default CTAImage
