import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { setSelectedInboxMobilePage } from '../../slices/inboxSlice'
import { useQuery } from '@apollo/client'
import formatContactData from '../../hooks/formatContactData'
import { handleStartNewMessage } from '../../slices/inboxSlice'
import { FETCH_CONTACT_BY_THREAD } from '../../../../graphql'

// Components
import { HeaderMenu } from './header-menu/header-menu'
import { Avatar, Header, Icon } from '@thryvlabs/maverick'
import { MattersPill } from './matters-pill'
import { ConversationAvatar } from '../conversation-avatar'
import { ThryvGuyIcon } from '../../../command-center-icon/icons'
import avatarColorPicker from '../../../../utils/avatarColorPicker'

const HeaderAvatar = ({ show, thryvGuyVariant, imgUrl, firstName, lastName }) => {
  if (!show) return <></>

  if (thryvGuyVariant) {
    return (
      <Avatar variant="icon" style={{ height: 40, width: 40 }}>
        <ThryvGuyIcon fill="white" stroke="#ECEEF1" />
      </Avatar>
    )
  }

  if (!imgUrl && firstName && isNaN(firstName)) {
    return (
      <Avatar
        variant="name"
        size="default"
        name={{ firstName, lastName }}
        staticBackground
        backgroundColor={avatarColorPicker(lastName?.at(0))}
        className="place-items-start"
      />
    )
  }

  return <ConversationAvatar imgUrl={imgUrl} customSize={40} />
}

export const MessageHeader = ({ messageHandlers }) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 835 })

  const startNewMessage = useSelector((state) => state.inbox.startNewMessage)
  const recipient = useSelector((state) => state.inbox.recipient)
  const selectedThread = useSelector((state) => state.inboxThreads.selectedThread)

  const isThryvSupport =
    selectedThread?.name?.trim()?.toLowerCase() === 'support@thryv.com' ||
    selectedThread?.name?.trim()?.toLowerCase() === 'support'
  const formattedThreadName = formatContactData(selectedThread.name)

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: selectedThread?.id },
    skip: !selectedThread?.id || selectedThread?.id === '',
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  let firstName = body ? body?.given_name?.toUpperCase() : ''
  let lastName = body ? body?.surname?.toUpperCase() : ''
  if (!firstName && lastName) {
    firstName = lastName
    lastName = ''
  }

  const onMobileBackButtonClick = () => {
    dispatch(handleStartNewMessage(false))
    dispatch(setSelectedInboxMobilePage('inbox-menu'))
  }

  return (
    <div className="flex px-[30px] py-6 justify-between h-[88px] bg-[white] sm:px-[20px]">
      <div className="flex gap-2.5 items-center w-full">
        <Icon
          type="regular"
          variant="chevronLeft"
          height="20"
          width="12"
          color="#4D4D4D"
          className={isMobile ? 'block' : 'hidden'}
          onClick={onMobileBackButtonClick}
        />

        <HeaderAvatar
          show={!startNewMessage && formattedThreadName}
          thryvGuyVariant={isThryvSupport}
          imgUrl={recipient.imgUrl}
          firstName={firstName}
          lastName={lastName}
        />

        <Header
          fontWeight="semibold"
          variant="h6"
          className="!normal-case truncate w-[0px] grow"
        >
          {startNewMessage && 'New Conversation'}

          {!startNewMessage && isThryvSupport && 'Thryv Support Center'}

          {!startNewMessage && !isThryvSupport && formattedThreadName}
        </Header>

        {formattedThreadName && <MattersPill />}
      </div>

      {contact && !startNewMessage ? (
        <HeaderMenu
          recipient={recipient}
          messageHandlers={messageHandlers}
          contact={contact}
        />
      ) : null}
    </div>
  )
}
