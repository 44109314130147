import { gql } from '@apollo/client'

export const DISCONNECT_CHANNEL = gql`
  mutation disconnect_channel(
    $channel_id: String!
    $locationID: String!
    $provider: String!
    $status: String!
    $ccid: String!
  ) {
    deleteChannel(
      channel_id: $channel_id
      locationID: $locationID
      provider: $provider
      status: $status
      ccid: $ccid
    )
  }
`
