import { useState } from 'react'
import { ThryvMetaAuthorizationIcon } from '../../../command-center-icon/icons'
import { Header, ParagraphText, Icon, Button } from '@thryvlabs/maverick'
import { useFacebookAuth } from '../../../../hooks/use-facebook-auth'
import { useSelector } from 'react-redux'
const ThryvMetaAuthorizationModal = ({ handleClose }) => {
  const [cancelConnection, setCancelConnection] = useState(false)
  const { reauth, pageName } = useSelector(
    (state) => state.connectChannels.metaUserData,
  )
  return (
    <div className={`max-w-[500px] ${cancelConnection ? 'p-8' : 'py-4'}`}>
      <div
        className={`flex ${
          cancelConnection ? 'justify-between' : 'justify-end pr-6'
        }`}
      >
        {cancelConnection ? (
          <Header className="mb-5" fontWeight="semibold" variant="h4">
            Quit channel connection?
          </Header>
        ) : null}
        <Icon
          type="regular"
          variant="x"
          height="18"
          fill="#A3A5A7"
          className="cursor-pointer"
          onClick={() => {
            cancelConnection ? handleClose() : setCancelConnection(true)
          }}
        />
      </div>
      {!cancelConnection && (
        <div className="flex justify-center mb-10">
          <ThryvMetaAuthorizationIcon />
        </div>
      )}

      <div
        className={`flex flex-col gap-[24px] ${
          cancelConnection ? 'mb-[65px]' : 'mb-10'
        }`}
      >
        <Header
          className={cancelConnection ? 'text-left' : 'text-center'}
          fontWeight="semibold"
          variant={cancelConnection ? 'h7' : 'h4'}
        >
          {cancelConnection
            ? 'Are you sure you want to leave this page?'
            : 'Additional authorization needed'}
        </Header>

        <ParagraphText
          variant="reg"
          className={cancelConnection ? 'text-left' : 'text-center'}
        >
          {cancelConnection
            ? 'If you leave this page, no changes will be saved. You will lose your progress towards connecting this channel.'
            : `It looks like your Page ${pageName} uses Meta Business Manager. Meta requires additional authorization before we can connect your account.`}
        </ParagraphText>
      </div>
      {cancelConnection ? (
        <div className="flex gap-4 justify-end">
          <Button
            variant="text"
            level={2}
            onClick={() => setCancelConnection(false)}
            className="cursor-pointer !text-[#808080]"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            variant="primary"
            style={{ fontSize: '14px' }}
            onClick={handleClose}
          >
            Quit
          </Button>
        </div>
      ) : (
        <div className="justify-center flex">
          <Button
            type="submit"
            variant="primary"
            style={{ fontSize: '14px' }}
            onClick={
              () => window.location.replace(useFacebookAuth('facebook', reauth)) // inject business_managemet scope into facebook auth
            }
          >
            Authorize
          </Button>
        </div>
      )}
    </div>
  )
}
export default ThryvMetaAuthorizationModal
