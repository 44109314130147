import { CardTitle, Header } from '@thryvlabs/maverick'
import { useEffect, useState, useRef } from 'react'
import Toggle from '../../../components/common/toggle/Toggle'
import { NotificationInfo } from './NotificationInfo'
import { SelectField } from './SelectField'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { UPDATE_USER_PREFERENCES } from '../../../graphql/mutations/user-preferences-mutation'
import {
  setDesktopNotificationPreferences,
  setMobileNotificationPreferences,
} from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { setUserPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { GET_USER_PREFERENCES } from '../../../graphql'
import { SUBSCRIPTION_FOR_USER_PREFERENCES_UPDATE } from '../../../graphql/mutations/user-preferences-mutation'
import { setDoNotDisturbPreferences } from '../../../redux-toolkit/slices/user-preferences/user-preferences'
import { Buffer } from 'buffer'
window.Buffer = Buffer

export const Notifications = () => {
  const preferenceData = useSelector((state) => state.userPreferences)
  const [inboxNotificationData, setInboxNotificationData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [teamchatNotificationData, setTeamchatNotificationData] = useState([])
  const [callsNotificationData, setCallsNotificationData] = useState([])
  const [desktopNotifications, setDirectDesktopNotificationData] = useState(
    preferenceData.notificationPreferences.desktop,
  )
  const [mobileNotifications, setDirectMobileNotificationData] = useState(
    preferenceData.notificationPreferences.mobile,
  )
  const isFirstRender = useRef(true)
  const { user } = useAuth0()

  const { data: subscriptionAlert } = useSubscription(
    SUBSCRIPTION_FOR_USER_PREFERENCES_UPDATE,
    {
      variables: { email: user?.email },
    },
  )

  const { doNotDisturb } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )

  const dispatch = useDispatch()

  const { data: userPreferences, refetch } = useQuery(GET_USER_PREFERENCES, {
    fetchPolicy: 'no-cache',
    variables: { userId: user?.email },
    onCompleted: () => {
      dispatch(
        setDoNotDisturbPreferences(
          JSON.parse(subscriptionAlert?.onUpdateUserPreferences.appSettings.Values)
            .data.notificationPreferences.doNotDisturb,
        ),
      )
      setDirectDesktopNotificationData(
        JSON.parse(userPreferences?.queryUserPreferences?.[0]?.Values).data
          .notificationPreferences.desktop,
      )
      setDirectMobileNotificationData(
        JSON.parse(userPreferences?.queryUserPreferences?.[0]?.Values).data
          .notificationPreferences.mobile,
      )
      const updatedConfig = {
        navigationPreferences,
        navigationCollapsed,
        notificationPreferences: {
          doNotDisturb: JSON.parse(
            subscriptionAlert?.onUpdateUserPreferences.appSettings.Values,
          ).data.notificationPreferences.doNotDisturb,
          desktop: JSON.parse(userPreferences?.queryUserPreferences?.[0]?.Values)
            .data.notificationPreferences.desktop,
          mobile: JSON.parse(userPreferences?.queryUserPreferences?.[0]?.Values).data
            .notificationPreferences.mobile,
          selectedConversationMobile,
          selectedConversationDesktop,
          selectedTeamChatDesktop,
          selectedTeamChatMobile,
        },
      }
      updatePreferences(updatedConfig)
      dispatch(setUserPreferences(updatedConfig))
    },
  })

  useEffect(() => {
    if (subscriptionAlert) {
      const isDesktopUpdated =
        JSON.stringify(
          JSON.parse(subscriptionAlert?.onUpdateUserPreferences.appSettings.Values)
            .data.notificationPreferences.desktop,
        ) !== JSON.stringify(preferenceData.notificationPreferences.desktop)

      const isMobileUpdated =
        JSON.stringify(
          JSON.parse(subscriptionAlert?.onUpdateUserPreferences.appSettings.Values)
            .data.notificationPreferences.mobile,
        ) !== JSON.stringify(preferenceData.notificationPreferences.mobile)

      if (isDesktopUpdated || isMobileUpdated) {
        refetch()
      }
    }
  }, [subscriptionAlert])

  const newTeamChatTypeList = [
    { name: 'All', value: 'All' },
    { name: 'Tagged Only', value: 'Tagged Only' },
  ]

  const changeDesktopToggle = (index) => {
    let notificationsList = JSON.parse(JSON.stringify(desktopNotifications))

    notificationsList[index].value = !notificationsList[index].value
    setDirectDesktopNotificationData([...notificationsList])
    dispatch(setDesktopNotificationPreferences([...notificationsList]))

    const updatedConfig = {
      navigationPreferences,
      navigationCollapsed,
      notificationPreferences: {
        doNotDisturb: doNotDisturb,
        desktop: [...notificationsList],
        mobile: mobileNotifications,
        selectedConversationMobile,
        selectedConversationDesktop,
        selectedTeamChatDesktop,
        selectedTeamChatMobile,
      },
    }
    updatePreferences(updatedConfig)
    dispatch(setUserPreferences(updatedConfig))
  }

  const changeMobileToggle = (index) => {
    let notificationsList = JSON.parse(JSON.stringify(mobileNotifications))
    notificationsList[index].value = !notificationsList[index].value
    setDirectMobileNotificationData([...notificationsList])
    dispatch(setMobileNotificationPreferences([...notificationsList]))

    const updatedConfig = {
      navigationPreferences,
      navigationCollapsed,
      notificationPreferences: {
        doNotDisturb: doNotDisturb,
        desktop: desktopNotifications,
        mobile: [...notificationsList],
        selectedConversationMobile,
        selectedConversationDesktop,
        selectedTeamChatDesktop,
        selectedTeamChatMobile,
      },
    }
    updatePreferences(updatedConfig)
    dispatch(setUserPreferences(updatedConfig))
  }

  const changeDNDToggle = () => {
    if (doNotDisturb) {
      setDirectDesktopNotificationData([
        { label: 'Inbox', value: false },
        { label: 'TeamChat', value: false },
        { label: 'Calls', value: false },
      ])
      setDirectMobileNotificationData([
        { label: 'Inbox', value: false },
        { label: 'TeamChat', value: false },
        { label: 'Calls', value: false },
      ])

      const updatedConfig = {
        navigationPreferences,
        navigationCollapsed,
        notificationPreferences: {
          doNotDisturb: doNotDisturb,
          desktop: [
            { label: 'Inbox', value: false },
            { label: 'TeamChat', value: false },
            { label: 'Calls', value: false },
          ],
          mobile: [
            { label: 'Inbox', value: false },
            { label: 'TeamChat', value: false },
            { label: 'Calls', value: false },
          ],
          selectedConversationMobile,
          selectedConversationDesktop,
          selectedTeamChatDesktop,
          selectedTeamChatMobile,
        },
      }
      updatePreferences(updatedConfig)
      dispatch(setUserPreferences(updatedConfig))
    } else {
      setDirectDesktopNotificationData([
        { label: 'Inbox', value: true },
        { label: 'TeamChat', value: true },
        { label: 'Calls', value: true },
      ])
      setDirectMobileNotificationData([
        { label: 'Inbox', value: true },
        { label: 'TeamChat', value: true },
        { label: 'Calls', value: true },
      ])

      const updatedConfig = {
        navigationPreferences,
        navigationCollapsed,
        notificationPreferences: {
          doNotDisturb: doNotDisturb,
          desktop: [
            { label: 'Inbox', value: true },
            { label: 'TeamChat', value: true },
            { label: 'Calls', value: true },
          ],
          mobile: [
            { label: 'Inbox', value: true },
            { label: 'TeamChat', value: true },
            { label: 'Calls', value: true },
          ],
          selectedConversationMobile,
          selectedConversationDesktop,
          selectedTeamChatDesktop,
          selectedTeamChatMobile,
        },
      }
      updatePreferences(updatedConfig)
      dispatch(setUserPreferences(updatedConfig))
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      changeDNDToggle()
    }
  }, [doNotDisturb])

  const [selectedConversationDesktop] = useState(
    preferenceData.notificationPreferences.selectedConversationDesktop,
  )

  const [navigationPreferences] = useState(preferenceData.navigationPreferences)

  const [navigationCollapsed] = useState(preferenceData.navigationCollapsed)

  const [selectedTeamChatDesktop, setSelectedTeamChatDesktop] = useState(
    preferenceData.notificationPreferences.selectedTeamChatDesktop,
  )
  const [selectedConversationMobile] = useState(
    preferenceData.notificationPreferences.selectedConversationMobile,
  )
  // eslint-disable-next-line no-unused-vars
  const [selectedTeamChatMobile, setSelectedTeamChatMobile] = useState(
    preferenceData.notificationPreferences.selectedTeamChatMobile,
  )

  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES)
  const updatePreferences = async (updatedPreferences) => {
    try {
      await updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedPreferences,
          }),
        },
      })
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    const processNotification = (label, container) => {
      const desktopNotification = desktopNotifications?.find(
        (x) => x.label === label,
      )
      const mobileNotification = mobileNotifications?.find((x) => x.label === label)

      if (desktopNotification) {
        container.push({
          device: 'Desktop',
          label: desktopNotification.label,
          value: desktopNotification.value,
        })
      }

      if (mobileNotification) {
        container.push({
          device: 'Mobile',
          label: mobileNotification.label,
          value: mobileNotification.value,
        })
      }
    }

    const inbox = []
    processNotification('Inbox', inbox)

    const teamchat = []
    processNotification('TeamChat', teamchat)

    const calls = []
    processNotification('Calls', calls)

    setInboxNotificationData(inbox)
    setTeamchatNotificationData(teamchat)
    setCallsNotificationData(calls)
  }, [preferenceData])

  return (
    <div className="bg-[white] rounded p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A]">
      <div className="mt-1 mb-6">
        <Header fontWeight="semibold" variant="h6">
          Notifications
        </Header>
      </div>

      <Toggle label="Do Not Disturb" setToggleStatus={changeDNDToggle} />

      <hr className="border-[#E3E6E8] border-[1px] mt-[1rem]" />

      <div className="mt-3 mb-6">
        <CardTitle size="md" variant="subtitle" className="text-sm">
          Inbox
        </CardTitle>
      </div>

      {inboxNotificationData.map((notification, index) => (
        <div
          key={index}
          className="grid grid-cols-2 gap-3 sm:flex-col mt-5 mb-5 w-full"
        >
          <div>
            <Toggle
              key={index}
              index={0}
              isToggleList={true}
              toggleStatus={notification.value}
              setToggleStatus={
                notification.device === 'Desktop'
                  ? changeDesktopToggle
                  : changeMobileToggle
              }
              label={notification.device}
            />

            {notification.value === true &&
              notification.label === 'New TeamChat' && (
                <NotificationInfo
                  label="Please select conversation type"
                  tooltipDes="Get notifications for all incoming TeamChat messages, or just the ones you're tagged in."
                />
              )}
          </div>

          {notification.value === true && notification.label === 'New TeamChat' && (
            <div className="w-[22%] sm:w-[130px]  mt-[20px]">
              {notification.label === 'New TeamChat' && (
                <SelectField
                  className="[&>div>div:last-child>ul]:h-auto"
                  options={newTeamChatTypeList}
                  selectedOption={selectedTeamChatDesktop}
                  setSelectedOption={setSelectedTeamChatDesktop}
                  withLabel
                />
              )}
            </div>
          )}
        </div>
      ))}

      <hr className="border-[#E3E6E8] border-[1px] mt-[1rem]" />
      <div className="mt-3 mb-6">
        <CardTitle size="md" variant="subtitle" className="text-sm">
          Calls
        </CardTitle>
      </div>
      {callsNotificationData.map((notification, index) => (
        <div
          key={index}
          className="grid grid-cols-2 gap-3 sm:flex-col mt-5 mb-5 w-full"
        >
          <div>
            <Toggle
              key={index}
              index={2}
              isToggleList={true}
              toggleStatus={notification.value}
              setToggleStatus={
                notification.device === 'Desktop'
                  ? changeDesktopToggle
                  : changeMobileToggle
              }
              label={notification.device}
            />

            {notification.value === true &&
              notification.label === 'New TeamChat' && (
                <NotificationInfo
                  label="Please select conversation type"
                  tooltipDes="Get notifications for all incoming TeamChat messages, or just the ones you're tagged in."
                />
              )}
          </div>

          {notification.value === true && notification.label === 'New TeamChat' && (
            <div className="w-[22%] sm:w-[130px]  mt-[20px]">
              {notification.label === 'New TeamChat' && (
                <SelectField
                  className="[&>div>div:last-child>ul]:h-auto"
                  options={newTeamChatTypeList}
                  selectedOption={selectedTeamChatDesktop}
                  setSelectedOption={setSelectedTeamChatDesktop}
                  withLabel
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
