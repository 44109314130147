import { useState, useEffect } from 'react'
import axios from 'axios'
import thryvcclogo from '../../assets/thryvcclogomedium.png'
import { Header, TableFormAlert } from '@thryvlabs/maverick'
const { VITE_SIGN_UP_SERVICE_URL } = import.meta.env
import formatContactData from '../inbox/hooks/formatContactData'
import './SignUpStyles.css'

const VerifyStep = ({ setStep, userData }) => {
  const [code, setCode] = useState(Array(6).fill(''))
  const [showWarning, setShowWarning] = useState(false)
  const [showResentMessage, setShowResentMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  const displayPhoneNumber = `${userData.countryCode}${userData.phoneNumber}`
  const formatedDisplayPhoneNumber = formatContactData(displayPhoneNumber)

  const re = /^[0-9\b]+$/

  const requestCode = () => {
    axios.post(`${VITE_SIGN_UP_SERVICE_URL}/signup/get-code`, {
      phone: displayPhoneNumber,
    })
  }

  const compareCodes = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${VITE_SIGN_UP_SERVICE_URL}/signup/verify-code`,
        {
          phone: displayPhoneNumber,
          code: code.join(''),
        },
      )
      if (response.data.status === 'approved') {
        window.utag.link({
          tealium_event: 'cc-signup-track',
          event_name: 'cc-verified-lead',
          event_type: 'Lead',
        })
        setShowWarning(false)
        setStep('3')
      } else {
        setShowWarning(true)
        setLoading(false)
      }
    } catch (error) {
      setShowWarning(true)
      setLoading(false)
    }
  }

  const selectNextInput = (e) => {
    if (re.test(e.target.value) === true) {
      const fieldName = e.target.id.split('-')[1]
      const nextSibiling = document.getElementById(
        `input-${parseInt(fieldName) + 1}`,
      )
      if (nextSibiling !== null) {
        nextSibiling.focus()
      }
    }
  }

  const selectPreviousInput = (e) => {
    const fieldName = e.target.id.split('-')[1]
    const previousSibiling = document.getElementById(
      `input-${parseInt(fieldName) - 1}`,
    )
    if (previousSibiling !== null) {
      previousSibiling.focus()
    }
  }

  useEffect(() => {
    const codeString = code.join('')
    if (codeString.length === 6) {
      compareCodes()
    }
  }, [code])

  const handleInput = (e) => {
    const index = e.target.id.split('-')[1]
    const input = e.target.value.replace(/\D/g, '')
    setCode((prev) => {
      const newCode = [...prev]
      newCode[index] = input
      return newCode
    })
    if (input.length === 1) selectNextInput(e)
  }

  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData('Text')
    if (pastedCode.length === 6) {
      setCode(pastedCode.split(''))
      document.getElementById(`input-5`).focus()
      e.preventDefault()
    }
  }

  const handleFocus = (e) => {
    e.target.select()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      selectPreviousInput(e)
    }
  }

  useEffect(() => {
    document.getElementById('input-0').focus()
  }, [])

  return (
    <>
      <div className="mb-[30px] md:mb-[0px] md:mt-[50px]">
        <img
          src={thryvcclogo}
          alt="Thryv Command Center"
          className="w-[198px] h-[40px] md:hidden"
        />
      </div>

      <div className="h-[80px] md:mt-[35px] gap-[20px] flex flex-col items-center ">
        <Header fontWeight="semibold" variant="h4" className="min-w-md:hidden">
          <p>Verifying Your Identity</p>
        </Header>
        <p className='font-["Open_Sans"] font-normal w-[408px] text-sm md:w-[303px] md:h-[588px]'>
          We will not be sending marketing messages. A text message will be sent to
          this number only to verify your identity. Please enter 6-digit code sent to{' '}
          <b>{formatedDisplayPhoneNumber}</b> to continue.
        </p>
        <div
          className="grid grid-flow-col pt-[30px] gap-x-[20px] md:w-[303px] md:gap-x-[10px] z-40"
          onPaste={handlePaste}
        >
          {[0, 1, 2, 3, 4, 5].map((i) => {
            return (
              <input
                key={i}
                id={`input-${i}`}
                className="h-[44px] w-[50px] md:w-[40px] flex text-center rounded border border-thryv-gray-light-500 focus:outline-none focus:border-thryv-orange"
                type="tel"
                placeholder="0"
                onInput={handleInput}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                value={code[i]}
                autoComplete="one-time-code"
                maxLength={1}
              />
            )
          })}
        </div>
      </div>
      <div className="w-[230px] h-[150px] flex flex-col gap-[10px] mt-[32px]" />
      <div className="flex gap-[8px] w-[300px] mb-[40px] md:pt-[60px]">
        <span className='font-["Open_Sans"] text-[#808080] md:mt-[-50px]'>
          Didn't receive the code?{' '}
          <a
            href="#"
            onClick={() => {
              requestCode()
              setShowResentMessage(true)
              setTimeout(() => {
                setShowResentMessage(false)
              }, 6000)
            }}
            className="font-medium text-[#057AFF] dark:text-[#057AFF] hover:underline"
          >
            Click to resend
          </a>{' '}
        </span>
      </div>
      {loading ? (
        <div>
          <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin"></span>
        </div>
      ) : (
        <button onClick={() => setStep('1')}>
          <p
            className={`font-["Open_Sans"] font-[600] leading-[34px] text-[#808080] text-[13px] pr-[16px] md:mt-[-40px] mt-[-20px]`}
          >
            BACK
          </p>
        </button>
      )}
      {showResentMessage ? (
        <div className="mb-[25px] md:mb-4">
          <TableFormAlert
            variant="success"
            alertText="Another SMS code has been sent to your phone."
            allowClose
            onClick={() => setShowResentMessage(false)}
          />
        </div>
      ) : (
        <></>
      )}
      {showWarning ? (
        <div className="mb-[25px] md:mb-4">
          <TableFormAlert
            variant="danger"
            alertText="Invalid verification code. Please try again."
            allowClose
            onClick={() => setShowWarning(false)}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default VerifyStep
