import { useState, useRef, useEffect } from 'react'
import { useInboxMenuConversations } from '../../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import {
  setSelectedThreadCompleted,
  setSelectedThread,
} from '../../../slices/inboxThreadsSlice'
import { setMobileKeypadOpen } from '../../../../calls/slices/callSlice'

import {
  UPDATE_THREAD_FIELD,
  REMOVE_THREAD_FIELD,
} from '../../../../../graphql/mutations/inbox-thread-mutations'
import {
  setOpenVideoCallLinkModal,
  setPrepopulatedNumber,
} from '../../../../calls/slices/callSlice'
import { FETCH_THREADS } from '../../../../../graphql'

// Components
import RoundedActionButton from '../../../common/rounded-action-button'
import { TriDotButtonWithMenu } from './buttons'
import { useMediaQuery } from 'react-responsive'

export const HeaderMenu = ({ messageHandlers, contact }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const menuRef = useRef()
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const [visibleMenu, setVisibleMenu] = useState('')
  const [disableVideoButton, setDisableVideoButton] = useState(true)
  const [disablePhoneButton, setDisablePhoneButton] = useState(true)
  const contactBody = contact?.queryContactsByThread?.items?.at(0)?.body
  const contactPhoneNumber = contactBody
    ? JSON.parse(contactBody)?.phone_numbers?.at(0)?.number
    : null
  const mostRecentThread = useSelector(
    (state) => state.inboxThreads.mostRecentThread,
  )
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const { selectedThreadCompleted } = useSelector((state) => state.inboxThreads)

  const [addThreadToCompleted] = useMutation(UPDATE_THREAD_FIELD, {})
  const [removeThreadFromCompleted] = useMutation(REMOVE_THREAD_FIELD, {})

  const { inboxThreadSk1s, inboxPinnedThreadSk1s } = useSelector(
    (state) => state.inboxThreads,
  )

  const threads = [...inboxThreadSk1s, ...inboxPinnedThreadSk1s]
  const sk1 = [threads.filter((x) => x.id === selectedThreadId)[0]?.sk1]
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const { channelInfo } = useSelector((state) => state.connectChannels)
  let userHasVideoChannel

  useEffect(() => {
    userHasVideoChannel = channelInfo?.filter(
      (channel) => channel.Provider === 'video',
    )
    if (userHasVideoChannel?.length >= 1) {
      setDisableVideoButton(false)
    } else {
      setDisableVideoButton(true)
    }
  }, [channelInfo])

  const triDotHandlers = {
    onNotificationsClick: () => {},

    onAttachmentClick: () => {},

    onPrintClick: () => {
      setVisibleMenu('')
      messageHandlers.print.messages()
    },

    onPdfClick: () => {
      setVisibleMenu('')
      messageHandlers.download.pdf()
    },

    onCsvClick: () => {
      setVisibleMenu('')
      messageHandlers.download.csv()
    },
  }

  const onCallClick = () => {
    if (contactPhoneNumber) {
      dispatch(setPrepopulatedNumber(`+${contactPhoneNumber}`))
      if (isMobile) {
        dispatch(setMobileKeypadOpen(true))
      }
      navigate('/calls')
    }
  }

  const onMarkCompleteClick = async () => {
    const THREAD_IS_COMPLETED = selectedThreadCompleted
    const MUTATION_OBJECT = {
      variables: {
        sk1s: sk1,
        field: 'completedsk',
      },
      onCompleted: () => {
        getThreads()
        dispatch(setSelectedThreadCompleted(!THREAD_IS_COMPLETED))

        if (!THREAD_IS_COMPLETED) {
          if (selectedThreadId === mostRecentThread[0].id) {
            dispatch(setSelectedThread(mostRecentThread[1]))
          } else {
            dispatch(setSelectedThread(mostRecentThread[0]))
          }
        }
      },
    }

    if (THREAD_IS_COMPLETED) removeThreadFromCompleted(MUTATION_OBJECT)
    else addThreadToCompleted(MUTATION_OBJECT)
  }

  let userHasPhoneChannel

  useEffect(() => {
    userHasPhoneChannel = channelInfo?.filter(
      (channel) => channel.Provider === 'phone' && channel.Status !== '99',
    )
    if (userHasPhoneChannel?.length >= 1 && contactPhoneNumber?.length > 0) {
      setDisablePhoneButton(false)
    } else {
      setDisablePhoneButton(true)
    }
  }, [channelInfo, contact])

  return (
    <div ref={menuRef} className="relative flex gap-4 sm:gap-1 items-center">
      {/* ----- Call button ----- */}
      <RoundedActionButton
        className="!w-[30px] !h-[30px]"
        type="regular"
        disable={disablePhoneButton}
        icon={{
          variant: 'altPhone',
          type: 'regular',
          width: '20',
          height: '20',
          color: '#92929D',
          hoverColor: '#92929D',
        }}
        onClick={onCallClick}
        isMavIcon
        tooltipTitle={'Call'}
      />

      {/* ----- Video call button ----- */}
      <RoundedActionButton
        className="!w-[30px] !h-[30px]"
        type="regular"
        disable={disableVideoButton}
        icon={{
          variant: 'video',
          type: 'regular',
          width: '20',
          height: '13',
          color: '#92929D',
          hoverColor: '#92929D',
        }}
        onClick={() => dispatch(setOpenVideoCallLinkModal(true))}
        isMavIcon
        tooltipTitle="Video chat"
      />

      {/* ----- Mark complete button ----- */}
      <RoundedActionButton
        className="!w-[30px] !h-[30px]"
        type="regular"
        icon={{
          variant: 'commentCheck',
          type: 'regular',
          width: '19',
          height: '17',
          color: selectedThreadCompleted ? '#FF5000' : '#92929D',
          hoverColor: selectedThreadCompleted ? '#FF5000' : '#92929D',
        }}
        onClick={onMarkCompleteClick}
        isCCIcon
        tooltipTitle={
          selectedThreadCompleted ? 'Mark as incomplete' : 'Mark as complete'
        }
      />

      <TriDotButtonWithMenu
        showMenu={visibleMenu === 'triDot'}
        setVisibleMenu={setVisibleMenu}
        buttonHandlers={triDotHandlers}
      />
    </div>
  )
}
