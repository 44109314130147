export const DATE_THREADS_SORTS = {
  'Date received: newest first': {
    sort_order: 'desc',
    field: 'message_time',
  },

  'Date received: oldest first': {
    sort_order: 'asc',
    field: 'message_time',
  },

  'Sender name: A to Z': {
    sort_order: 'asc',
    field: 'thread_name',
  },

  'Sender name: Z to A': {
    sort_order: 'desc',
    field: 'thread_name',
  },
}
