import { CommandCenterIcon } from '../../../../../../command-center-icon'

export const PinIcon = () => (
  <button
    type="button"
    className="flex justify-center items-center h-[30px] w-[30px] ml-2.5"
  >
    <CommandCenterIcon
      type="solid"
      variant="thumbtack"
      color="#FF5000"
      width="14"
      height="16"
    />
  </button>
)
