import { CallsSearchIcon } from '@icons'
import { useDispatch } from 'react-redux'
import { setOnOutgoingCall } from '../slices/callSlice'
import useRedux from '../../../hooks/use-redux'
const searchInputStyles = `w-[300px] font-montserrat font-semibold text-[#23120] text-base placeholder:text-xs 
placeholder:font-montserrat placeholder:text-[#A3A5A7] placeholder:font-medium outline-0`

const SearchCall = ({ callAllowed, onChange, value, setShowKeypad, onKeyDown }) => {
  const dispatch = useDispatch()
  const [
    {
      contacts: { connection },
    },
  ] = useRedux('contacts')

  const handleClick = () => {
    if (callAllowed) {
      setShowKeypad(false)
      dispatch(setOnOutgoingCall(true))
    }
  }
  return (
    <div
      className={`w-[318px] h-[42px] relative mx-4 mt-4 md:ml-auto md:mr-auto flex justify-between items-center ${
        !connection || 'border border-[#E7E7E7]'
      } 
    rounded-[4px] py-[9px] px-[12px]`}
    >
      {connection && (
        <>
          <input
            type="tel"
            className={searchInputStyles}
            placeholder="Search contacts"
            onChange={(e) => onChange({ e })}
            value={value}
            onKeyDown={onKeyDown}
          />
          <button
            data-testid="call-button"
            className={`absolute right-[12px] ${
              callAllowed ? 'cursor-pointer' : 'cursor-auto'
            } hover:${callAllowed ? 'cursor-pointer' : 'cursor-auto'}`}
            onClick={handleClick}
          >
            <CallsSearchIcon fill={callAllowed && '#16A085'} />
          </button>
        </>
      )}
    </div>
  )
}

export default SearchCall
