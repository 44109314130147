import { useAuth0 } from '@auth0/auth0-react'
import { convertRoleIntegerToText } from '../../../../../../../utils/convert-role-integer-to-text'
import { useStaffSort } from './hooks/use-staff-sort'
import { useActiveChannels } from '../../../../../../../hooks/use-active-channels'
import { createStaffListForPagination } from './utils/createStaffListForPagination'

// Components
import { TableMenu, PaginatedList } from '@thryvlabs/maverick'
import { StaffTableHead, StaffTableRow } from './components'

export const StaffMembersTable = ({
  className,
  auth0User,
  staffMembers,
  setOpenEditStaffModal,
  setStaffMemberData,
}) => {
  const commandCenterId = auth0User.cc_id
  const businessId = auth0User.businessId
  const { user } = useAuth0()

  const masterUser = {
    id: auth0User.staffUID,
    role: convertRoleIntegerToText(
      (staffMembers.length &&
        staffMembers.filter((member) => auth0User.cc_uid === member.CC_UserID)[0]
          ?.Role) ||
        user.cc_role,
    ),
  }

  const { staff, toggleSort, selectedSort } = useStaffSort(staffMembers)

  const connectedChannels = useActiveChannels()

  const staffListForPagination = createStaffListForPagination({
    commandCenterId,
    businessId,
    staffList: staff,
    masterUser,
    connectedChannels,
  })

  return (
    <div className={className}>
      <TableMenu className="w-full border-b border-thryv-gray-light-400 sm:overflow-x-auto">
        <div className="flex items-center w-full sm:min-w-fit">
          <StaffTableHead
            className={'w-full max-w-[15%]'}
            onClick={() => toggleSort('name')}
            removeChevron={selectedSort.name !== 'name'}
          >
            Name
          </StaffTableHead>
          <StaffTableHead
            className={'w-full max-w-[15%]'}
            onClick={() => toggleSort('channels')}
            removeChevron={selectedSort.name !== 'channels'}
          >
            Channels
          </StaffTableHead>
          <StaffTableHead
            className={'w-full max-w-[25%]'}
            onClick={() => toggleSort('email')}
            removeChevron={selectedSort.name !== 'email'}
          >
            Email
          </StaffTableHead>
          <StaffTableHead
            width="15%"
            onClick={() => toggleSort('phone')}
            removeChevron={selectedSort.name !== 'phone'}
          >
            Number
          </StaffTableHead>
          <StaffTableHead
            width="10%"
            onClick={() => toggleSort('role')}
            removeChevron={selectedSort.name !== 'role'}
          >
            Role
          </StaffTableHead>
          <StaffTableHead
            width="13%"
            onClick={() => toggleSort('status')}
            removeChevron={selectedSort.name !== 'status'}
          >
            Status
          </StaffTableHead>
          <StaffTableHead
            width="12%"
            removeChevron
            className="justify-end pointer-events-none"
          ></StaffTableHead>
        </div>
      </TableMenu>

      <PaginatedList list={staffListForPagination}>
        <StaffTableRow
          totalData={staffListForPagination.length}
          auth0User={auth0User}
          setOpenEditStaffModal={setOpenEditStaffModal}
          setStaffMemberData={setStaffMemberData}
        />
      </PaginatedList>
    </div>
  )
}
