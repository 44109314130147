import { useRef } from 'react'
import { SubheaderTag } from '../../subheader-tag'
import { useClickOutside } from '../../../../../../hooks/use-click-outside'
import { LabelTagDropdown } from './label-tag-dropdown'

export const LabelTagWithDropdown = ({
  children,
  showDropdown,
  setShowDropdown,
  onLabelChange,
  onClearClick,
  labels,
  labelsClear,
  labelsSelected,
  ...rest
}) => {
  const labelTagRef = useRef(null)
  const labelDropdownRef = useRef(null)

  useClickOutside((e) => {
    const labelTagClicked = e.composedPath().includes(labelTagRef.current)
    !labelTagClicked && setShowDropdown(false)
  }, labelDropdownRef)

  return (
    <div className="relative">
      <SubheaderTag
        iconVariant="label"
        iconType="regular"
        onClick={() => setShowDropdown(!showDropdown)}
        ref={labelTagRef}
        labelsSelected={labelsSelected}
        {...rest}
      >
        {children}
      </SubheaderTag>
      <LabelTagDropdown
        show={showDropdown}
        data={labels}
        onCheckboxChange={onLabelChange}
        labelsClear={labelsClear}
        onClearClick={onClearClick}
        ref={labelDropdownRef}
      />
    </div>
  )
}
