import { ChannelsContainer, HeaderContainer } from './ModalComponent.styles'
import ConnectCard from './ConnectCard'
import { Icon, Header, Button, ModalTitle } from '@thryvlabs/maverick'
import { useEffect } from 'react'
import { ErrorBanner, SuccessBanner } from '../../helper-components'
import { Notification } from '../../notification/notification'
import { useSelector } from 'react-redux'
import {
  handleHideDisconnectedChannelsNotification,
  setModalContent,
  setShowFirstChannelModal,
  setShowModal,
} from '../slices/connectChannelsSlice'
import { useDispatch } from 'react-redux'
import { setPaymentProcessed } from '../upgrade-plan-modal/slices/planInfoSlice'
import { CredentialChangedNotification } from '../../notification'
import RegisterPhoneNotifications from '../phone-certification-modal/register-phone-modal/RegisterPhoneNotifications'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_STAFF } from '../../../../graphql'
import usePhoneChannel from '../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
function ConnectChannelsModalMobile({
  manageChannel,
  setChannelIsConnected,
  setClickedID,
  subscriptionTierLoading,
}) {
  const { cardData, channelDisconnected } = useSelector(
    (state) => state.connectChannels,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setShowFirstChannelModal(false))

    return () => dispatch(setPaymentProcessed(false))
  }, [])

  return (
    <div
      id="connect-channels-modal-container"
      className="p-5 sm:p-2 w-full mx-auto sm:h-[609px] sm:overflow-scroll sm:no-scrollbar"
    >
      <ConnectHeader
        setChannelIsConnected={setChannelIsConnected}
        subscriptionTierLoading={subscriptionTierLoading}
      />
      <ChannelsContainer>
        {cardData.map((card, idx) => {
          return (
            <ConnectCard
              key={card.iconName}
              iconName={card.iconName}
              iconType={card.iconType}
              ButtonText={card.ButtonText}
              clickedID={idx}
              isConnected={card.isConnected}
              manageChannel={manageChannel}
              connectError={
                card.connectError ||
                (card.iconName === channelDisconnected?.at(0)?.Provider
                  ? true
                  : false)
              }
              connectWarning={card.connectWarning}
              description={card.description}
              channelsConnected={card.channelsConnected}
              setClickedID={setClickedID}
            />
          )
        })}
      </ChannelsContainer>
    </div>
  )
}

const ConnectHeader = ({ subscriptionTierLoading }) => {
  const {
    channelInfo,
    maxChannelsReached,
    channelIsConnected,
    channelDisconnected,
    showChannelDisconnectedNotifcation,
  } = useSelector((state) => state.connectChannels)
  const dispatch = useDispatch()
  const { currentPlan, paymentProcessed } = useSelector((state) => state.planInfo)
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const userRole = singleStaffData?.staff[0]?.Role || user.cc_role
  const maxChannelsAllowed = useSelector(
    (state) => state.connectChannels.maxChannelsAllowed,
  )

  const UpgradeButton = () => (
    <Button
      className="rounded-[56px] border-2 focus:outline-none flex items-center justify-center"
      variant="primary"
      onClick={() => {
        dispatch(setModalContent(4))
      }}
    >
      UPGRADE
    </Button>
  )

  return (
    <HeaderContainer>
      <div className="flex justify-between items-center gap-8 w-full">
        <div className="flex gap-4 items-center">
          <Header
            fontWeight="semibold"
            variant="h4"
            className="sm:text-[18px] x-sm:text-[14px]"
          >
            {subscriptionTierLoading || maxChannelsAllowed === 'Unlimited'
              ? 'My Channels'
              : `My Channels (${
                  channelInfo ? channelInfo?.length : 0
                }/${maxChannelsAllowed})`}
          </Header>
          {userRole !== '2' && <UpgradeButton />}
        </div>
        <Button variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => dispatch(setShowModal(false))}
          />
        </Button>
      </div>
      <ModalTitle size="md" variant="subtitle">
        Connect your favorite channels for your business communication
      </ModalTitle>
      {isActive && (
        <RegisterPhoneNotifications className="w-[37.6rem] mb-[20px] text-left gap-x-3 gap-y-6 sm:w-full" />
      )}
      {channelIsConnected ? <SuccessBanner /> : null}
      {maxChannelsReached ? <ErrorBanner /> : null}
      {channelDisconnected?.length > 0 && showChannelDisconnectedNotifcation ? (
        <CredentialChangedNotification
          variant="error"
          iconType="solid"
          iconVariant="circleExclamation"
          className="w-[37.6rem] text-left mb-4 -mt-6 gap-x-3 gap-y-6 sm:w-full"
          close={() => dispatch(handleHideDisconnectedChannelsNotification(false))}
          updateButton={false}
        />
      ) : null}
      {paymentProcessed ? (
        <Notification
          variant="success"
          iconType="solid"
          iconVariant="circleExclamation"
          className="w-[37.6rem] text-left mb-4 -mt-4 gap-x-3 gap-y-6 sm:w-full sm:text-center"
          textToRender={`Thanks, ${
            user.name
          }! You have successfully upgraded your plan to ${
            currentPlan?.header
          } and now have ${maxChannelsAllowed
            ?.toString()
            .toLowerCase()} channels! You will receive a receipt via email shortly. It can take up to 60 seconds for your updated plan to be reflected in your active subscriptions`}
          close={() => setPaymentProcessed(false)}
        />
      ) : null}
    </HeaderContainer>
  )
}
export default ConnectChannelsModalMobile
