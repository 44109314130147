import { useState, useEffect, useRef } from 'react'

const getMaxHeight = (elem, minHeight) => {
  const elemHeight = elem.clientHeight
  if (elemHeight > minHeight) return elemHeight
  else return minHeight
}

const ExpandableBoxWrapper = ({
  children,
  className,
  maxHeight = 100000,
  minHeight = 0,
  expanded = false,
  ...rest
}) => {
  const expandableBoxWrapperRef = useRef(null)
  const contentBoxWrapperRef = useRef(null)
  const [height, setHeight] = useState({ max: maxHeight, min: minHeight })
  const [isExpanded, setIsExpanded] = useState(expanded)

  const expandBoxWrapper = () => setIsExpanded(true)
  const contractBoxWrapper = () => setIsExpanded(false)

  const init = () => {
    const contentBoxWrapper = contentBoxWrapperRef.current

    const newHeight = {
      max: getMaxHeight(contentBoxWrapper, minHeight),
      min: minHeight,
    }

    setHeight(newHeight)

    expanded ? expandBoxWrapper() : contractBoxWrapper()
  }

  useEffect(() => {
    if (expandableBoxWrapperRef.current.children.length) init()
  }, [children])

  return (
    <>
      {/* This height should increase or decrease dynamicallly */}
      <div
        className={`${
          isExpanded ? '' : 'overflow-hidden'
        } transition-all duration-300`}
        style={{
          maxHeight: isExpanded ? height.max : height.min,
        }}
        ref={expandableBoxWrapperRef}
      >
        {/* This height should always be consistent and what we base the expandable box height of off */}
        <div
          className={`${className} flex flex-col`}
          ref={contentBoxWrapperRef}
          {...rest}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default ExpandableBoxWrapper
