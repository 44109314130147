import { ParagraphText as Text, Button } from '@thryvlabs/maverick'
import { OutOfMinutesIcon } from '../../command-center-icon/icons'
const OutOfMinutes = ({ setOpenAddMoreMinutes, close }) => {
  const handleClose = () => {
    close()
    setOpenAddMoreMinutes(true)
  }
  return (
    <div className="flex flex-col items-center mt-1">
      <OutOfMinutesIcon />
      <span className="text-5 leading-[26px] font-montserrat font-semibold text-thryv-black-500 mt-[27px]">
        Out of Minutes
      </span>
      <Text variant="reg" color="#4D4D4D" className="w-[290px] text-center mt-5">
        You've used all your minutes for the month. To continue calling, you'll need
        to add more to your subscription.
      </Text>
      <Button variant="primary" className="mt-10 mb-5" onClick={handleClose}>
        Add More
      </Button>
    </div>
  )
}
export default OutOfMinutes
