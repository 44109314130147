import { Button, Input, NotificationBar } from '@thryvlabs/maverick'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { ThryvCommandCenterIcon } from '../../components/command-center-icon/icons'

const { VITE_SIGN_UP_SERVICE_URL } = import.meta.env

export const RequestDeletion = () => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isOnTimeout, setIsOnTimeout] = useState(false)
  const [timeRemaining, setTimeRemaining] = useState(10)

  useEffect(() => {
    if (isOnTimeout) {
      const timer = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            setIsOnTimeout(false)
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => {
        clearInterval(timer)
        setTimeRemaining(10)
        setIsError(false)
      }
    }
  }, [isOnTimeout])

  const submitEmail = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post(`${VITE_SIGN_UP_SERVICE_URL}/cancel`, {
        email: email,
      })
      if (response) {
        setIsSuccessful(true)
      }
    } catch (error) {
      setIsError(true)
      setIsOnTimeout(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <ThryvCommandCenterIcon height={100} width={100} />
      <div className="flex flex-col gap-4 text-center w-full max-w-md p-4 mt-2 mb-2">
        <h3 className="text-h3 font-montserrat text-left font-semibold text-thryv-black-500 capitalize">
          Command Center Account Deactivation Request
        </h3>
        <div className="flex flex-col gap-1">
          <p className="text-left font-open-sans text-sm text-thryv-black-600 mb-2 italic">
            Thank you for being a Command Center customer. Please submit this form to
            deactivate your account.
          </p>
          <p className="text-left font-open-sans text-sm text-thryv-black-600 mb-2 italic">
            For information on how we manage your data, please review our{' '}
            <a
              target="_blank"
              href="https://corporate.thryv.com/privacy/?_gl=1*qrsy9a*_ga*MTQ4NTI3NzY3NS4xNjc0NzU3OTc1*_ga_Q0P8XE9ZR3*MTY4MzEzNjA5MC45LjAuMTY4MzEzNjA5Mi4wLjAuMA"
              className="font-semibold text-[#057AFF] hover:underline"
              rel="noreferrer"
            >
              privacy policy
            </a>
            . If there is anything we can do to change your mind or provide further
            assistance, please don't hesitate to{' '}
            <a
              target="_blank"
              href="https://www.thryv.com/contact-us/"
              className="font-semibold text-[#057AFF] hover:underline"
              rel="noreferrer"
            >
              contact us
            </a>
            .
          </p>
          <p className="text-left font-open-sans text-sm text-thryv-black-600 mb-2 italic">
            To continue with account deactivation, please submit your email address
            below. Your email will be verified against your account.
          </p>
        </div>
        <div className="flex gap-4 flex-col justify-start">
          {!isSuccessful ? (
            <>
              <Input
                className="w-[500px]"
                type="text"
                placeholder="Email"
                name="email"
                variant="default"
                required
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              <div className="w-full flex justify-start">
                {isLoading ? (
                  <div className="mt-[-20px] ml-[-12px]">
                    <span className="w-[40px] h-[40px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/2 top-5"></span>
                  </div>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      disabled={
                        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) || isOnTimeout
                      }
                      onClick={() => submitEmail()}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </>
          ) : null}

          {isOnTimeout ? (
            <p>Please wait {timeRemaining} seconds before submitting</p>
          ) : null}

          {isError ? (
            <div className="pt-[15px] w-full">
              <NotificationBar
                variant="error"
                content={
                  'Your Email Entered is Wrong or Your Account is Already Marked for Deletion or Your Email Address is connected to Multiple Accounts. Please contact support.'
                }
              />
            </div>
          ) : null}

          {isSuccessful ? (
            <div className="pt-[15px] w-full">
              <NotificationBar
                variant="success"
                content={
                  'Your submission has been recieved and your request is being processed'
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
