import { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { TransitionContainer } from '../../../common/transition-container'
import { ParagraphText, Checkbox } from '@thryvlabs/maverick'
import { StyledLabelsMenuBtn } from './styled-labels-menu-btn'
import { LabelButtonWithModal } from './create-new-label-button/create-new-label-button'
import { useSelector } from 'react-redux'
import { FETCH_MESSAGES_BY_THREAD_ID } from '../../../../graphql/queries'
import {
  ADD_LABEL_TO_MESSAGE,
  DELETE_LABEL_FROM_MESSAGE,
} from '../../../../graphql/mutations'
import { Link } from 'react-router-dom'

const StyledCheckbox = styled(Checkbox)`
  :checked + span,
  &:not(:checked) + span {
    color: #808080;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
export const LabelsMenu = forwardRef(
  (
    { className, show, messageLabels, sk1, threadpk, pk1, positionAbove, ...rest },
    ref,
  ) => {
    const [msgLabels, setMsgLabels] = useState(messageLabels)
    const labelOptions = useSelector((state) => state.messageContent.labels)

    useEffect(() => {
      const labelOptionsChecked = labelOptions.map((labelOption) => {
        return {
          LabelName: labelOption.LabelName,
          id: labelOption.LabelID,
          checked: includedOnMsgLabel(labelOption.LabelName),
          labelPK1: setPK1(labelOption.LabelName),
          labelSK1: setSK1(labelOption.LabelName),
        }
      })
      setMsgLabels(labelOptionsChecked)
    }, [messageLabels, labelOptions])

    const includedOnMsgLabel = (optionName) => {
      return messageLabels?.some((msgLabel) => {
        return msgLabel?.body === optionName
      })
    }
    const setPK1 = (labelName) => {
      let pk1Found = ''
      messageLabels?.forEach((msgLabel) => {
        if (msgLabel.body === labelName) {
          pk1Found = msgLabel.pk1
        }
      })
      return pk1Found
    }

    const setSK1 = (labelName) => {
      let sk1Found = ''
      messageLabels?.forEach((msgLabel) => {
        if (msgLabel.body === labelName) {
          sk1Found = msgLabel.sk1
        }
      })
      return sk1Found
    }

    const handleCheckboxChange = (e, checkboxId) => {
      const updatedCheckedLabels = msgLabels.map((msgLabel) => {
        if (msgLabel.id === checkboxId) {
          if (msgLabel.checked) {
            deleteMessageFromLabel({
              variables: { PK1: msgLabel.labelPK1, SK1: msgLabel.labelSK1 },
            })
          } else {
            const pk1ThreadID = pk1.split('#')[2]
            const threadpkThreadID = threadpk.split('#')[0]
            const channelPK = pk1.split('#')[1]
            addMessageToLabel({
              variables: {
                channel_id: channelPK,
                thread_id: pk1ThreadID,
                threadpk: threadpkThreadID,
                body: msgLabel.LabelName,
                message_sk1: sk1,
              },
            })
          }
          return { ...msgLabel, checked: !msgLabel.checked }
        } else return msgLabel
      })
      setMsgLabels(updatedCheckedLabels)
    }

    const [addMessageToLabel] = useMutation(ADD_LABEL_TO_MESSAGE, {
      onError: () => {},
      refetchQueries: [FETCH_MESSAGES_BY_THREAD_ID],
    })

    const [deleteMessageFromLabel] = useMutation(DELETE_LABEL_FROM_MESSAGE, {
      onError: () => {},
      refetchQueries: [FETCH_MESSAGES_BY_THREAD_ID],
    })

    return (
      <TransitionContainer
        show={show}
        as="div"
        className={`${className} py-4 px-2 flex flex-col gap-2 max-h-56 overflow-auto ${
          positionAbove ? 'bottom-[40px]' : ''
        }`}
        width="200px"
        {...rest}
        ref={ref}
      >
        <ParagraphText
          variant="reg"
          className="pl-2 font-semibold font-montserrat"
          color="thryv-steel"
        >
          Label as
        </ParagraphText>

        <ul className="w-full flex flex-col">
          {msgLabels?.map((label, i) => (
            <li
              className="hover:bg-thryv-gray-light-200 transition-all duration-300 p-2 rounded"
              key={i}
            >
              <StyledCheckbox
                value={label.LabelName}
                hasLabel
                label={label.LabelName}
                onChange={(e) => handleCheckboxChange(e, label.id)}
                isChecked={label.checked}
              />
            </li>
          ))}
        </ul>

        <hr className="text-thryv-gray-light-400 w-full" />

        <div className="w-full">
          <div>
            <LabelButtonWithModal pk1={pk1} threadpk={threadpk} sk1={sk1} />
          </div>
          <Link to="/settings/inbox">
            <StyledLabelsMenuBtn>Manage labels</StyledLabelsMenuBtn>
          </Link>
        </div>
      </TransitionContainer>
    )
  },
)

export default LabelsMenu
