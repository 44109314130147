export const CC_RECURLY_PLAN_CODES = {
  free: 'cc_basic-5-thryv-m2m',
  freePlus: 'cc_basicpls-5-thryv-m2m',
  plus: 'cc_plus-5-thryv-m2m',
  pro: 'cc_pro-5-thryv-m2m',
}

export const CC_RECURLY_PLAN_CODES_LIST = [
  'cc_basic-5-thryv-m2m',
  'cc_basicpls-5-thryv-m2m',
  'cc_plus-5-thryv-m2m',
  'cc_pro-5-thryv-m2m',
]

export const CC_RECURLY_PLAN_CODES_LIST_EXCLUDE = []

export const CC_RECURLY_PLAN_TYPE = {
  free: 'Basic',
  freePlus: 'Basic+',
  plus: 'Plus',
  pro: 'Pro',
}
