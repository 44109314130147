import { useNavigate, createSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedThread } from '../../slices/inboxThreadsSlice'
import { setSelectedInboxMobilePage } from '../../slices/inboxSlice'
import { scrollToSelectedThread } from '../sections/utils/scrollToSelectedThread'
import { scrollToMsgInThread } from '../sections/utils/scrollToMsgInThread'

export const useThreadSelecting = (search) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [updateReadTime] = useMutation(UPDATE_THREAD_READ_TIME, {})

  const updateSelectedThread = (threadId, threadName, msgPk) => {
    dispatch(
      setSelectedThread({
        id: threadId,
        name: threadName,
      }),
    )

    dispatch(setSelectedInboxMobilePage('message-content'))

    let pathToNavigateTo = '/inbox'

    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: search }).toString(),
    })
    scrollToSelectedThread(threadId)
    scrollToMsgInThread(msgPk)

    // if (
    //   email.message_time > email.read_time ||
    //   (!email.message_time && !email.read_time)
    // ) {
    //   updateReadTime({
    //     variables: {
    //       thread_id: threadId,
    //     },
    //     refetchQueries: [
    //       {
    //         query: FETCH_THREADS_WITH_TIME_RANGE,
    //         variables: {
    //           time_filter: {
    //             between: [timeRangeStart, timeRangeEnd],
    //           },
    //         },
    //         fetchPolicy: 'network-only',
    //       },
    //     ],
    //   })
    // }
  }

  return updateSelectedThread
}
