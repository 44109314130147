import styled from 'styled-components'
import { Button } from '@thryvlabs/maverick'
import { forwardRef } from 'react'

const StyledDropdownButton = styled(Button)`
  & > div > div:last-child {
    position: fixed;
    z-index: 10;
    margin: 0;
    ${({ $top }) => `top: ${$top}px;`}
    ${({ $width }) => `width: ${$width}px;`}
  }
`

/* It's a dropdown button where the dropdown scales based on the button size */

export const StyledCommandCenterDropdownButton = forwardRef(
  ({ className, ...rest }, ref) => (
    <div className={`${className} relative inline-block`} ref={ref}>
      <StyledDropdownButton variant="secondary" buttonType="dropdown" {...rest} />
    </div>
  ),
)
