import { Header } from '@thryvlabs/maverick'
import { ParagraphText as Text } from '@thryvlabs/maverick'
import { Button } from '@thryvlabs/maverick'
import { Link } from 'react-router-dom'

const StartUsingCall = () => {
  return (
    <div className="pl-[2%] pr-[2%] mb-[1rem] bg-white z-10 rounded-[3px] border border-[#ECEEF1] drop-shadow-md  ">
      <Header className="pt-[24px]  w-full" fontWeight="semibold" variant="h4">
        Start Using Calls
      </Header>
      <Text variant="reg" color="thryv-steel" className="pt-[8px] text-[1rem]">
        Activate Calls now to get a phone number to make calls and send SMS messages!
      </Text>
      <div className="pt-[1rem] pb-[1.5rem]">
        <Link to="/calls">
          <Button
            variant="text"
            level={1}
            className="h-[44px] w-[167px] py-auto px-auto rounded-[22px] bg-thryv-orange-500 text-white leading-[14px] text-[15px] font-medium md:h-[32px] md:w-[138px] md:rounded-[4px]"
          >
            Get Your Number
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default StartUsingCall
