const allowedImgTypes = [
  '.png',
  '.jpg',
  '.gif',
  '.svg',
  '.webP',
  '.bmp',
  '.ico',
  '.tif',
  '.tiff',
]
const allowedVideoTypes = [
  '.mp3',
  '.mp4',
  '.midi',
  '.flac',
  '.avi',
  '.mpeg',
  '.mov',
  '.webM',
]
const allowedApplicationTypes = [
  '.csv',
  '.txt',
  '.text',
  '.pdf',
  '.psd',
  '.ai',
  '.id',
  '.eps',
  '.word',
  '.dot',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
]
const allowedTextTypes = ['.html', '.css', '.xml']

export {
  allowedImgTypes,
  allowedVideoTypes,
  allowedApplicationTypes,
  allowedTextTypes,
}
