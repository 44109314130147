/* eslint-disable no-undef */
import { classnames } from 'tailwindcss-classnames'

export const container = classnames(
  'w-full',
  'h-auto',
  'row-start-1',
  'row-span-3',
  'row-end-3',
)

export const header = classnames(
  'w-full',
  'h-full',
  'flex',
  'mb-[18px]',
  'justify-between',
  'items-center',
  'p-4',
  'md:hidden',
)

export const addNumber = classnames(
  'text-[#057AFF]',
  'font-montserrat',
  'font-semibold',
  'text-base',
  'underline',
)

export const phoneNumber = classnames(
  'font-montserrat',
  'font-semibold',
  'text-base',
)

export const keypadActive = classnames('bg-[#F8F9FB]')
export const keypadHidden = classnames('bg-none h-0')
export const keypad = classnames(
  'absolute',
  'bottom-0',
  'h-auto',
  'w-[334px]',
  'mx-2',
  'rounded-t-[15px]',
  'flex',
  'flex-col',
  'items-center',
  'justify-start',
  'z-10',
  'p-6',
  'transition-all',
  'duration-200',
  'ease-in-out',
)

export const keypadCTA = (showkeypad) =>
  classnames(styles.keypad, {
    [styles.hidden]: showkeypad,
    [styles.keypadActive]: !showkeypad,
  })
