const Signatures = () => {
  const signaturesUrl =
    import.meta.env.VITE_ENV === 'prod'
      ? 'https://signatures.labs.thryv.com/signatures'
      : 'https://dev-signatures.sandbox.thryv.com/signatures'

  return <iframe className="w-full h-full" src={signaturesUrl} />
}

export default Signatures
