import { useEffect, useState } from 'react'
import axios from 'axios'
import {
  setCountry,
  setCountryCode,
} from '../components/common/country-code-select/countryCodeSlice'
import { useDispatch } from 'react-redux'

const useDetectCountryCode = () => {
  // This hook detects the users country based on IP and displays the corresponding emoji flag and country code

  const { VITE_IPAPI_API_KEY } = import.meta.env
  const dispatch = useDispatch()

  const [selectedOption, setSelectedOption] = useState({
    name: '',
    value: '',
  })

  const countryFlags = {
    US: '🇺🇸   +1',
    CA: '🇨🇦   +1',
    AU: '🇦🇺   +61',
    NZ: '🇳🇿   +64',
  }

  const countryCallingCodes = {
    US: '+1',
    CA: '+1',
    AU: '+61',
    NZ: '+64',
  }

  // API call to get users IP address and assign country code based on location
  useEffect(() => {
    axios
      .get(`https://ipapi.co/country_code/?key=${VITE_IPAPI_API_KEY}`)
      .then((response) => {
        const data = response.data
        if (countryFlags[data]) {
          setSelectedOption({
            name: countryFlags[data],
            value: countryFlags[data],
          })
        } else {
          setSelectedOption({
            name: data,
            value: data,
          })
        }

        dispatch(setCountryCode(countryCallingCodes[data]))
        dispatch(setCountry(data))
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }, [])

  return selectedOption.name
}

export default useDetectCountryCode
