import { useAuth0 } from '@auth0/auth0-react'
import { QuickSetupModal, ModalTitle } from '@thryvlabs/maverick'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import FirstChannelAdded from './FirstChannelAdded'

export const FirstChannelAddedModal = ({ isOpen, setIsOpen }) => {
  const { user } = useAuth0()
  const { cardData, connectedChannelInfo } = useSelector(
    (state) => state.connectChannels,
  )

  const channelId = localStorage.getItem('channelClicked')
  const [channelConnected] = useState(cardData[channelId])
  const isMobile = useMediaQuery({ maxWidth: 900 })
  const handleCloseModal = () => setIsOpen()

  return (
    <>
      <QuickSetupModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hideLeftSide={true}
        width={isMobile ? '300px' : '550px'}
      >
        <ModalTitle variant="primary">
          <p
            className={`w-full font-montserrat  ${
              isMobile ? 'text-sm ' : 'text-2xl'
            }font-semibold`}
          >
            Your {connectedChannelInfo?.provider} account was successfully connected!
          </p>
        </ModalTitle>

        {/* FOR NEW META AUTH FLOW */}
        {connectedChannelInfo?.provider === 'facebook' ? (
          <p className={`${isMobile ? 'text-sm' : ''} pt-[8px] `}>
            Congrats, {user.name}! Your {connectedChannelInfo?.provider} Page{' '}
            {connectedChannelInfo?.pageName} was successfully connected. You may now
            respond to {connectedChannelInfo?.provider} messages in your Inbox!
          </p>
        ) : (
          <p className={`${isMobile ? 'text-sm' : ''} pt-[8px] `}>
            Congrats, {user.name}! Your {connectedChannelInfo?.provider} account was
            successfully connected.
            {channelConnected?.channelProvider === 'nylas' && //this is to know what text to render for each provider
              ' We are importing your messages now. This process can take up to a few hours to complete.'}
            {channelConnected?.channelProvider === 'sunshine' && //this is to know what text to render for each provider
              `You may now respond to ${connectedChannelInfo?.provider} messages in your Inbox!`}
          </p>
        )}
        <FirstChannelAdded handleCloseModal={handleCloseModal} />
      </QuickSetupModal>
    </>
  )
}
