import { gql } from '@apollo/client'

export const CREATE_INVOICE = gql`
  mutation create_invoice_mutation($invoice_number: String!) {
    generateInvoice(input: { invoice_number: $invoice_number }) {
      data {
        name
        url
        mime_type
        expires
      }
    }
  }
`
