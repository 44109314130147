import { gql } from '@apollo/client'

export const GET_LABEL_OPTIONS_BY_LOCATION = gql`
  query get_label_options_by_location($locationid: String!) {
    queryLabelOptionsByLocation(location_id: $locationid) {
      LabelName
      LabelID
    }
  }
`
