import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { NotificationBar } from '@thryvlabs/maverick'
import { AuthLoader } from '@authentication'

const { VITE_AWS_LAMBDA_RESET_PASSWORD_URL } = import.meta.env

const ResetPassword = () => {
  const { getIdTokenClaims, logout } = useAuth0()
  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      try {
        const idToken = await getIdTokenClaims()

        const config = {
          method: 'POST',
          body: JSON.stringify({ assertion: idToken.__raw }),
          headers: { 'Content-Type': 'application/json' },
        }

        const response = await window.fetch(
          `${VITE_AWS_LAMBDA_RESET_PASSWORD_URL}/tickets/password-change`,
          config,
        )
        const data = await response.json()

        logout({ logoutParams: { returnTo: data.ticket } })
      } catch (err) {
        setError(err)
      }
    })()
  }, [getIdTokenClaims, logout])

  if (error) {
    return <NotificationBar variant="error" content={<>{error.message}</>} />
  }

  return <AuthLoader />
}

export default ResetPassword
