const generateInputStyle = (name, setContactInfoView) => {
  const nameInputStyle = `${
    setContactInfoView ? 'w-[275px]' : 'w-[235px]'
  } focus:outline-none font-['Open_Sans'] text-sm text-thryv-steel`
  const mobileEmailInputStyle =
    "ml-4 focus:outline-none font-['Open_Sans'] text-sm text-thryv-steel w-[235px] truncate"
  switch (name) {
    case 'firstName':
      return `mt-[14px] ${nameInputStyle}`
    case 'lastName':
      return `mb-[14px] ${nameInputStyle}`
    case 'phone':
      return mobileEmailInputStyle
    case 'email':
      return mobileEmailInputStyle
  }
}
const Input = ({
  name,
  value,
  onChangeHandler,
  setContactInfoView,
  placeholder,
  disabled,
  title,
  register,
}) => {
  const style = generateInputStyle(name, setContactInfoView)
  return (
    <>
      <input
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChangeHandler}
        type={
          name === 'email' && value
            ? 'email'
            : name === 'phone' && value
              ? 'tel'
              : 'text'
        }
        name={name}
        className={style}
        autoComplete="off"
        title={title}
        {...(register && { ...register(name) })}
      />
    </>
  )
}

export default Input
