import { CommandCenterIcon } from '../../../../command-center-icon'
import { ParagraphText } from '@thryvlabs/maverick'
import { useSelector, useDispatch } from 'react-redux'
import {
  setModalContent,
  setShowModal,
} from '../../../../common/modals/slices/connectChannelsSlice'
import { ChannelsModalComponent } from '../../../../common/modals/channels-modal-component/ChannelsModalComponent'
import { FirstChannelAddedModal } from '../../../../common/modals/first-channel-added-modal/FirstChannelAddedModal'
import { GET_PACKAGE_INFO } from '../../../../../graphql'
import { setMaxChannelsAllowed } from '../../../../common/modals/slices/connectChannelsSlice'

import { useQuery } from '@apollo/client'
import { useMediaQuery } from 'react-responsive'

export const ChannelActivationButton = () => {
  const dispatch = useDispatch()
  const handleOpenModal = () => {
    dispatch(setShowModal(true))
    dispatch(setModalContent(isMobile ? 151 : 1))
  }
  const maxChannelsAllowed = useSelector(
    (state) => state.connectChannels.maxChannelsAllowed,
  )
  const { showFirstChannelModal, showModal } = useSelector(
    (state) => state.connectChannels,
  )
  const { channelInfo } = useSelector((state) => state.connectChannels)
  const totalActiveChannels = channelInfo ? channelInfo?.length : 0

  const isMobile = useMediaQuery({ maxWidth: 835 })

  const {
    data: packageInfoData,
    loading: packageInfoLoading,
    error: packageInfoError,
  } = useQuery(GET_PACKAGE_INFO, {
    onCompleted: () => {
      dispatch(
        setMaxChannelsAllowed(packageInfoData.queryPackageInfoByCCID.InboxChannels),
      )
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.log('PACKAGE INFO ERROR:', packageInfoError)
    },
  })

  return (
    <>
      <button
        onClick={() => handleOpenModal()}
        className="rounded-[15px] bg-thryv-night flex gap-2 px-3.5 py-2 items-center"
      >
        <CommandCenterIcon type="solid" variant="messages" />
        <ParagraphText
          variant="reg"
          className="font-semibold leading-[14px] tracking-[0.25px]"
          color="white"
        >
          {packageInfoLoading
            ? 'Channels'
            : maxChannelsAllowed === 'Unlimited'
              ? 'Channels'
              : `Channels (${totalActiveChannels}/${maxChannelsAllowed})`}
        </ParagraphText>
      </button>
      {showModal && <ChannelsModalComponent />}
      {showFirstChannelModal && (
        <FirstChannelAddedModal isOpen={true} setIsOpen={() => {}} />
      )}
    </>
  )
}
