import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from '@apollo/client'
import {
  UPDATE_STAFF,
  GET_STAFF,
  ADD_USER_CHANNEL_PERMISSIONS_BULK,
  GET_CONNECTED_CHANNELS_BY_USER,
} from '../../../../../../../graphql'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import formSchema from './formSchema'
import { Input, MaskedInput } from '@thryvlabs/maverick'
import { getRoleIntegerEquivalent } from '../../../../../../../utils/get-role-or-status-integer-equivalent'
import {
  updateStaffInDatabase,
  updateChannelPermissionsInDatabase,
  hasStaffDataChanged,
} from './utils/editStaffUtils'
import { setMask } from '../../../../../../../utils/phoneNumberMask'
// Components
import { ParagraphText, Button } from '@thryvlabs/maverick'
import { RoleInput } from '../../../../../../../components/common/add-staff-modal/inputs'

import { LoadingSpinner } from '../../../../../../../components/common/loading-spinner'
import { useCountryData } from '../../../../../../../hooks/use-country-data'
import { ConnectedChannelInputs } from '../../../../../../../components/common/add-staff-modal/inputs'

const formatPhone = (phoneNumber, countryISO) => {
  if (!phoneNumber) return ''

  if (countryISO === 'US') return phoneNumber.slice(1)
  else return phoneNumber.slice(2)
}

const EditStaffDetailsModal = ({
  setOpenEditStaffModal,
  staffMemberData,
  setStaffUpdatedName,
  setOpenUpdateStaffConfirmationModal,
  setUpdateStaffError,
  setUpdatedStaffData,
  setUpdatedStaffPermissionsData,
  newStaffPermissions,
  setNewStaffPermissions,
}) => {
  const { user } = useAuth0()
  const commandCenterId = user.cc_id
  const [isDuplicate, setIsDuplicate] = useState({ number: false, email: false })
  const { countryISO, countryCode, emojiAndCountryCode } = useCountryData()

  const [permittedChannels, setPermittedChannels] = useState([])
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: staffMemberData.cc_userId },
      },
    },

    onCompleted: () => {
      setPermittedChannels(singleStaffData?.staff[0].permittedChannels)
    },
  })

  const { data: connectedChannelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: user.businessId, user_id: user.cc_uid },
  })
  const initChannels = (channelData) => {
    const tempAccessibleChannels = []

    channelData?.forEach((channel) => {
      if (channel.Provider === 'CC') return
      if (channel.Status === '1' || channel.Status === '2') {
        tempAccessibleChannels.push(channel)
      }
    })
    return tempAccessibleChannels
  }

  const accessibleChannels = initChannels(
    connectedChannelsData?.queryConnectedChannelsByUser,
  )

  const { data: staffData } = useQuery(GET_STAFF)

  const formattedPhone = formatPhone(staffMemberData?.phone, countryISO)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema(countryISO)),
    defaultValues: {
      firstName: staffMemberData?.firstName,
      lastName: staffMemberData?.lastName,
      email: staffMemberData?.email,
      countryCode: countryCode,
      phone: formattedPhone,
      role: getRoleIntegerEquivalent(staffMemberData?.role),
    },
  })

  const firstNameWatcher = watch('firstName')
  const lastNameWatcher = watch('lastName')
  const emailWatcher = watch('email')
  const phoneNumberWatcher = watch('phone')
  const roleWatcher = watch('role')

  useEffect(() => {
    setMask(countryISO, phoneNumberWatcher)
  }, [countryISO])

  const hasStaffPermissionsChanged = () => {
    if (newStaffPermissions.length === 0) {
      return false
    }

    const accessMap = new Map()
    newStaffPermissions.forEach((item) => {
      accessMap.set(item.ChannelID, item.AccessType)
    })

    const permittedChannels = singleStaffData.staff[0]?.permittedChannels || []

    for (const channel of permittedChannels) {
      const storedAccessType = accessMap.get(channel.ChannelID)
      if (storedAccessType !== undefined && storedAccessType != channel.AccessType) {
        return true
      }
    }

    return false
  }

  const [updateStaff, { loading, error }] = useMutation(UPDATE_STAFF, {
    onCompleted: () => {
      setOpenEditStaffModal(false)
      setOpenUpdateStaffConfirmationModal(true)
      setNewStaffPermissions([])
      setIsDuplicate({ number: false, email: false })
    },
    onError: () => {
      setOpenEditStaffModal(false)
      setUpdateStaffError({ error: true, msg: error })
      setOpenUpdateStaffConfirmationModal(true)
    },
    refetchQueries: [
      {
        query: GET_STAFF,
        variables: { comctrid: commandCenterId },
        fetchPolicy: 'network-only',
      },
    ],
  })
  const [updateChannelPermissionsBulk, { loading: channelPermissionsLoading }] =
    useMutation(ADD_USER_CHANNEL_PERMISSIONS_BULK, {
      onCompleted: () => {
        setOpenEditStaffModal(false)
        setOpenUpdateStaffConfirmationModal(true)
        setNewStaffPermissions([])
        setIsDuplicate({ number: false, email: false })
      },
      refetchQueries: [GET_STAFF],
    })

  const currentStaffData = {
    firstName: firstNameWatcher,
    lastName: lastNameWatcher,
    phone: countryCode + phoneNumberWatcher,
    email: emailWatcher,
    role: String(getRoleIntegerEquivalent(roleWatcher?.value)),
    cc_userId: staffMemberData.cc_userId,
  }

  const handleFormSubmit = async (formData) => {
    try {
      const newStaffData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: countryCode + phoneNumberWatcher,
        email: formData.email,
        role: String(getRoleIntegerEquivalent(formData.role.value)),
        cc_userId: staffMemberData.cc_userId,
      }

      if (hasStaffDataChanged(newStaffData, staffMemberData)) {
        await updateStaffInDatabase(
          formData,
          staffData,
          singleStaffData,
          emailWatcher,
          countryISO,
          countryCode,
          staffMemberData,
          user,
          setStaffUpdatedName,
          setIsDuplicate,
          setUpdatedStaffData,
          updateStaff,
          getRoleIntegerEquivalent,
        )
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating staff:', error)
    } finally {
      try {
        await updateChannelPermissionsInDatabase(
          newStaffPermissions,
          singleStaffData,
          updateChannelPermissionsBulk,
          setUpdatedStaffPermissionsData,
          formData,
          setStaffUpdatedName,
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error updating channel permissions:', error)
      }
    }
  }
  return (
    <div className="edit-staff-modal md:max-w-[300px]">
      <div className="flex px-[22px] justify-between items-center md:max-w-[300px] mb-11">
        <h4 className="font-semibold font-[Montserrat] text-[22px] leading-[30px] text-thryv-black-500">
          Edit Staff
        </h4>
      </div>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {/* ----- FORM ----- */}
        <div className="pr-[20px] mb-[42px] ml-2">
          <div className="flex justify-center gap-[56px] mb-[56px] md:flex-col md:gap-8 md:mb-[32px]">
            <Controller
              control={control}
              rules={{ required: 'First Name is required' }}
              name="firstName"
              render={({ field }) => (
                <Input
                  onChange={(e) => setValue('firstName', e.target.value)}
                  className=" w-[302px] md:w-full md:max-w-[280px]  h-[27px]"
                  type="text"
                  {...field}
                  name="firstName"
                  placeholder="First Name"
                  variant="default"
                  withLabel
                  labelType="floating"
                  aria-label="First Name"
                />
              )}
            />
            <Controller
              control={control}
              rules={{ required: 'Last Name is required' }}
              name="lastName"
              render={({ field }) => (
                <Input
                  onChange={(e) => setValue('lastName', e.target.value)}
                  className=" w-[333px] md:w-full md:max-w-[280px] h-[27px] text-start"
                  type="text"
                  {...field}
                  name="lastName"
                  placeholder="Last Name"
                  variant="default"
                  withLabel
                  labelType="floating"
                  aria-label="Last Name"
                />
              )}
            />
          </div>

          <div className="flex pl-[20px] gap-[56px] mb-[48px] md:flex-col md:gap-8 md:mb-[32px] md:pl-0">
            <div className="relative">
              <Input
                value={emojiAndCountryCode}
                tabIndex="-1" // No need for tabIndex to be set, since it's already disabled
                disabled
                className="md:w-full w-[110px] bg-gray-200" // Optional: Add background color to indicate disabled state
                type="tel"
                placeholder="Country Code"
                variant="default"
                withLabel
                labelType="floating"
                aria-label="Country Code"
              />
              <label
                className='pointer-events-none absolute left-0 -top-4 capitalize 
                font-open-sans text-label-text peer-placeholder-shown:text-form-text peer-placeholder-shown:text-thryv-steel 
                peer-placeholder-shown:top-6 transition-all peer-focus:-top-0 peer-focus:text-label-text 
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989] undefined ">Country</label>'
              >
                Country Code
              </label>
            </div>

            <div className="flex flex-col h-[27px] text-start">
              <Controller
                control={control}
                rules={{ required: 'Phone Number is required' }}
                name="phone"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    errors={errors}
                    className="md:w-full w-[525px]"
                    type="tel"
                    placeholder="Phone Number"
                    name="phone"
                    variant="default"
                    onChange={(e) => setValue('phone', e.target.value)}
                    mask={setMask(countryISO, phoneNumberWatcher)}
                    withLabel
                    labelType="floating"
                    aria-label="Phone Number"
                  />
                )}
              />

              {!loading && isDuplicate.number && (
                <p className="text-[#DF2A2A] font-open-sans text-xs">
                  Phone number is already in use.
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between px-5 mb-[42px] md:flex-col md:gap-8 md:mb-[32px] md:px-0">
            <div className="flex flex-col">
              <Controller
                control={control}
                rules={{ required: 'Email is required' }}
                name="email"
                render={({ field }) => (
                  <Input
                    {...field}
                    className="w-[333px] md:w-full"
                    type="email"
                    placeholder="Email"
                    name="email"
                    variant="default"
                    onChange={(e) => setValue('email', e.target.value)}
                    withLabel
                    labelType="floating"
                    aria-label="Email"
                  />
                )}
              />
              {!loading && isDuplicate.email && (
                <p className="text-[#DF2A2A] font-open-sans text-xs">
                  Email is already in use.
                </p>
              )}
            </div>

            <RoleInput
              className="md:w-[280px] w-[333px]"
              control={control}
              errors={errors}
              defaultRole={
                (staffMemberData.role === '1' && 'admin') ||
                (staffMemberData.role === '2' && 'staff') ||
                (staffMemberData.role === '3' && 'owner')
              }
            />
          </div>
        </div>

        {/* MANAGE CONNECTED CHANNELS */}

        <div className="flex flex-col px-[22px] gap-3 md:max-w-[300px] mb-11">
          <h4 className="font-semibold font-[Montserrat] text-[16px] leading-[22px] text-thryv-black-500">
            Manage connected channels
          </h4>
          <ParagraphText variant="reg">
            Grant access to your channels here. You can change it later in Settings.
          </ParagraphText>

          {accessibleChannels?.length > 0 && (
            <>
              <div
                className={`md:max-h-[120px] max-h-[220px] md:max-w-[720px] rounded-md p-4 ${
                  accessibleChannels?.length <= 3
                    ? 'md:overflow-y-auto overflow-visible'
                    : 'overflow-y-auto'
                } `}
              >
                {permittedChannels.length ? (
                  <ConnectedChannelInputs
                    currentRole={roleWatcher?.value}
                    connectedChannels={accessibleChannels}
                    control={control}
                    errors={errors}
                    isEdit={true}
                    permittedChannels={permittedChannels}
                    newStaffPermissions={newStaffPermissions}
                    setNewStaffPermissions={setNewStaffPermissions}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
        {/* ----- SUBMISSION BUTTONS ----- */}
        <div className="flex justify-end items-center gap-4 h-[33.69px] md:max-w-[300px]">
          {loading || channelPermissionsLoading ? (
            <LoadingSpinner widthAndHeight={32} />
          ) : (
            <>
              <Button
                variant="text"
                level={2}
                textVariant="light"
                onClick={() => setOpenEditStaffModal(false)}
              >
                Cancel
              </Button>
              <Button
                data-testId="saveButton"
                variant="primary"
                type="submit"
                disabled={
                  (!hasStaffDataChanged(currentStaffData, staffMemberData) &&
                    !hasStaffPermissionsChanged()) ||
                  !firstNameWatcher?.trim().length ||
                  !lastNameWatcher?.trim().length ||
                  !emailWatcher?.trim().length ||
                  !formattedPhone ||
                  (phoneNumberWatcher !== undefined &&
                    !phoneNumberWatcher?.trim().length)
                }
              >
                Save
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default EditStaffDetailsModal
