import styleSearchTermInString from '../../../../../../../../utils/styleSearchTermInString'

// Components
import { ParagraphText } from '@thryvlabs/maverick'
import DOMPurify from 'dompurify'
import { CommandCenterAvatar } from '../../../../../../../common/command-center-avatar'

export const SearchContactCard = ({
  className,
  onClick,
  firstName,
  lastName,
  email,
  imageUrl,
  textToEmphasize,
}) => {
  const fullName = `${firstName}  ${lastName}`.trim()
  const hoverAnimClass = 'hover:bg-thryv-gray-light-200 duration-300 transition-all'

  const renderEmailText = () => {
    const createBoldedEmailMarkup = () => {
      return {
        __html: DOMPurify.sanitize(
          styleSearchTermInString.allOccurences(email, textToEmphasize.trim()),
        ),
      }
    }

    return <span dangerouslySetInnerHTML={createBoldedEmailMarkup()} />
  }

  const renderNameText = () => {
    const createBoldedNameMarkup = () => {
      return {
        __html: styleSearchTermInString.allOccurences(
          fullName.length ? fullName : email,
          textToEmphasize.trim(),
        ),
      }
    }
    return <span dangerouslySetInnerHTML={createBoldedNameMarkup()} />
  }

  return (
    <button
      type="submit"
      onClick={onClick}
      className={`flex items-center w-full max-w-full px-6 py-1 cursor-pointer ${hoverAnimClass} ${className} h-10`}
    >
      <CommandCenterAvatar
        imageUrl={imageUrl}
        firstName={firstName}
        lastName={lastName}
        avatarProps={{ size: 'small' }}
      />

      <div className="text-start ml-2 truncate">
        <ParagraphText
          variant="reg"
          color="thryv-black"
          className="w-full truncate text-xs md:text-sm"
        >
          {renderNameText()}
        </ParagraphText>

        {fullName ? (
          <ParagraphText
            variant="reg"
            color="thryv-gray-medium-500"
            className="w-full truncate text-xs md:text-sm"
          >
            {renderEmailText()}
          </ParagraphText>
        ) : null}
      </div>
    </button>
  )
}
