import { useEffect, useState } from 'react'
import { Button } from '@thryvlabs/maverick'
import { OrderRow, OrderTotal, SummaryBox, TotalLine } from './OrderSummaryBox.style'
import { GET_RECURLY_SUBSCRIPTION_TAX } from '../../../../../graphql'
import { useQuery } from '@apollo/client'
import { LoadingSpinner } from '../../../loading-spinner'

const PROMO_INITIAL_STATE = {
  value: '',
  price: 0,
  validated: false,
  error: false,
}

export default function OrderSummaryBox({
  selectedPlan,
  setCanSubmit,
  accountInfo,
  canApplyPromoCode = false,
  seats,
  setErrorMessage,
}) {
  const [applyPromoCode, setApplyPromoCode] = useState(false)
  const [promoCodeState, setPromoCodeState] = useState(PROMO_INITIAL_STATE)
  const [loadingTaxes, setLoadingTaxes] = useState(true)
  const [taxPrice, setTaxPrice] = useState(null)
  const { price } = selectedPlan || { price: 0 }
  const billingInfo = accountInfo?.billingInfo || null
  const billingInfoId = billingInfo?.id || null

  const { data, error } = useQuery(GET_RECURLY_SUBSCRIPTION_TAX, {
    variables: {
      CCID: localStorage.getItem('cc_id'),
      billingInfoId: billingInfoId,
      planCode: selectedPlan?.code,
      NumberofSeats: seats,
    },
    skip: !billingInfo,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (data) {
        const {
          queryRecurlySubscriptionTaxes: { amount },
        } = data
        if (amount !== null && amount !== undefined) {
          setTaxPrice(amount)
          setLoadingTaxes(false)
        }
      }
    },
    onError: () => {
      setTaxPrice(0)
      setLoadingTaxes(false)
      setCanSubmit(false)
    },
  })

  const taxError = error?.graphQLErrors
  useEffect(() => {
    if (taxError && taxError.length > 0) {
      const errorMsg = taxError[0].errorType
      setErrorMessage(errorMsg)
    }
  }, [taxError])

  useEffect(() => {
    if (!canApplyPromoCode && applyPromoCode) {
      setApplyPromoCode(false)
    }
    return () => {
      setPromoCodeState(PROMO_INITIAL_STATE)
    }
  }, [canApplyPromoCode])

  useEffect(() => {
    if (setCanSubmit && price !== 0 && taxPrice !== null) {
      setCanSubmit(true)
    } else if (setCanSubmit && selectedPlan?.code == 'cc_basic-5-thryv-m2m') {
      setCanSubmit(true)
    }
  }, [price, taxPrice, billingInfoId])

  const calculateOrderTotal = () => {
    const taxes =
      taxPrice === null || taxPrice === undefined ? 0 : parseFloat(taxPrice)
    let total = parseFloat(price) * seats + taxes

    if (promoCodeState.price !== 0) {
      total = total - promoCodeState.price
    }
    return Number(total).toFixed(2)
  }

  const calculatePlanPricing = () => {
    let total = parseFloat(price) * seats
    return Number(total).toFixed(2)
  }

  return (
    <SummaryBox>
      <div className="header">Order Summary</div>
      <div className="order-details">
        <OrderRow>
          <div className="title">
            {selectedPlan?.header}{' '}
            <span className="text-[#4D4D4D]">
              (
              {seats ? (
                `x ${seats} ${seats > 1 ? 'users' : 'user'}`
              ) : (
                <LoadingSpinner widthAndHeight={24} />
              )}
              )
            </span>
          </div>
          <div className="prices">
            {' '}
            {calculatePlanPricing() !== 'NaN' ? (
              <div className="prices">{`$${calculatePlanPricing()}`}</div>
            ) : (
              <LoadingSpinner widthAndHeight={24} />
            )}
          </div>
        </OrderRow>
        {billingInfo === null || price === '0' ? (
          <OrderRow>
            <div className="title">Estimated Taxes</div>
            <div className="prices">{`$${Number(taxPrice).toFixed(2)}`}</div>
          </OrderRow>
        ) : (
          <OrderRow>
            <div className="title">Estimated Taxes</div>
            {loadingTaxes ? (
              <LoadingSpinner widthAndHeight={24} />
            ) : (
              <div className="prices">{`$${Number(taxPrice).toFixed(2)}`}</div>
            )}
          </OrderRow>
        )}

        {promoCodeState.validated && promoCodeState.price !== 0 && (
          <OrderRow>
            <div className="title">
              Discount{' '}
              <Button
                className="ml-[08px]"
                variant="text"
                level={1}
                onClick={() => setPromoCodeState(PROMO_INITIAL_STATE)}
              >
                [Remove]
              </Button>
            </div>
            <div className="prices text-[#FF5000]">{`-$${promoCodeState.price}.00`}</div>
          </OrderRow>
        )}

        <TotalLine />
        {billingInfo == null || price == '0' ? (
          <OrderTotal>
            <div className="title">Total</div>
            <div className="prices">{`$${calculateOrderTotal()}/month`}</div>
          </OrderTotal>
        ) : (
          <OrderTotal>
            <div className="title">Total</div>
            {calculateOrderTotal() !== 'NaN' && taxPrice !== null ? (
              <div className="prices">{`$${calculateOrderTotal()}/month`}</div>
            ) : (
              <LoadingSpinner widthAndHeight={24} />
            )}
          </OrderTotal>
        )}
      </div>
    </SummaryBox>
  )
}
