import './checkbox-group.css'
import { useEffect, useState } from 'react'
import { Checkbox } from '@thryvlabs/maverick'
import styled from 'styled-components'

/* ################################################ */
/* #####          Guide & Props API           ##### */
/* ################################################ */
/* ##                                            ## */
/* ##        https://tinyurl.com/brv432sc        ## */
/* ##                                            ## */
/* ################################################ */

const createCheckboxes = (newCheckboxes) => {
  return newCheckboxes.map((checkbox) => {
    return {
      ...checkbox,
      checked: typeof checkbox.checked === 'boolean' ? checkbox.checked : false,
    }
  })
}
export const CheckboxGroup = ({
  className,
  data,
  column,
  row,
  onChange,
  groupName,
  labelsClear,
}) => {
  const [checkboxes, setCheckboxes] = useState(createCheckboxes(data))

  const toggleCheckboxAtIndex = (index) => {
    const checkboxesCopy = checkboxes.slice()
    const checkbox = checkboxesCopy[index]
    checkbox.checked = !checkbox.checked
    setCheckboxes(checkboxesCopy)

    return {
      toggledCheckbox: checkbox,
      checkboxes: checkboxesCopy,
    }
  }

  const handleCheckboxChange = (index) => {
    const checkboxesData = toggleCheckboxAtIndex(index)
    onChange && onChange(checkboxesData)
  }

  useEffect(() => {
    const updateCheckboxes = () => setCheckboxes(createCheckboxes(data))
    updateCheckboxes()
  }, [data])

  useEffect(() => {
    if (labelsClear) {
      const clearedCheckboxes = checkboxes.map((checkbox) => {
        return { ...checkbox, checked: false }
      })
      setCheckboxes(clearedCheckboxes)
    }
  }, [labelsClear])

  return (
    <ul
      className={`flex gap-2.5
    ${column && 'flex-col'} 
    ${row && 'flex-row'}
    ${className}
    `}
    >
      {checkboxes.map((value, i) => (
        <li key={i} onClick={() => handleCheckboxChange(i)}>
          <Checkbox
            className="cc-checkbox-group-checkbox"
            key={i}
            hasLabel
            name={value.name || groupName || ''}
            isChecked={checkboxes[i].checked === true}
            label={value.value || value.LabelName}
            value={value.value || value.LabelName}
            readOnly /* Added to prevent warning in console */
          />
        </li>
      ))}
    </ul>
  )
}

export const StyledCheckboxGroup = styled(CheckboxGroup)`
  /* ----- Checkbox Input Container ----- */
  & > li {
    border-radius: 4px;
    padding: 8px;
    transition: background-color 300ms;
  }

  & > li,
  & label {
    cursor: pointer;
  }

  & > li:hover {
    background-color: #f2f4f6;
  }
`
