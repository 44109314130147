import {
  DELETE_VOICEMAIL_GREETING_MUTATION,
  GET_VOICEMAIL_AUDIO,
} from '../../../../../graphql'
import { LoadingSpinner } from '../../../../common/loading-spinner'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { ParagraphText, Button } from '@thryvlabs/maverick'
import { CircleXGrayBackgroundIcon } from '../../../../command-center-icon/icons'
const DeleteVoicemailModal = ({ setDeleteVoicemailOpen, setVoicemailDeleted }) => {
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)

  const [deleteVoicemailGreeting, { loading, error }] = useMutation(
    DELETE_VOICEMAIL_GREETING_MUTATION,
    {
      onCompleted: () => {
        setDeleteVoicemailOpen(false)
        setVoicemailDeleted(true)
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log('error', error)
      },
      refetchQueries: () => [
        {
          query: GET_VOICEMAIL_AUDIO,
          variables: { phone_number: phoneNumber },
        },
      ],
    },
  )

  return (
    <div className="py-10">
      <div className="flex flex-col gap-4 items-center justify-center mb-5">
        <button onClick={() => setDeleteVoicemailOpen(false)}>
          <CircleXGrayBackgroundIcon />
        </button>
        <ParagraphText
          variant="lg"
          className="text-[#231F20] !font-['Montserrat'] font-semibold text-[22px]"
        >
          Delete Voicemail Greeting
        </ParagraphText>
      </div>
      <div className="w-full px-[30px] sm:px-0">
        <ParagraphText variant="lg" className="text-center w-full">
          You're about to delete this voicemail greeting. If you proceed, your
          voicemail will be set to the default greeting.
        </ParagraphText>

        {error && (
          <ParagraphText
            color="utility-error-danger"
            variant="lg"
            className="mt-5 text-center w-full"
          >
            There was an error deleting your voicemail. Please try again.
          </ParagraphText>
        )}
      </div>

      <div className="flex justify-center mt-10 gap-4">
        <Button onClick={() => setDeleteVoicemailOpen(false)} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteVoicemailGreeting({ variables: { number: phoneNumber } })
          }}
          variant="primary"
        >
          {loading ? <LoadingSpinner widthAndHeight={12} /> : 'Proceed'}
        </Button>
      </div>
    </div>
  )
}

export default DeleteVoicemailModal
