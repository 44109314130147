import { useState, useEffect } from 'react'
import { Button } from '@thryvlabs/maverick'
import LocationModal from '../top-navigation-bar/LocationModal'
import BusinessNameModal from '../top-navigation-bar/BusinessNameModal'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomModal from '../common/CustomModal/Modal'
import { useSelector } from 'react-redux'
import { GET_BUSINESS_INFO_BY_LOCATION } from '../../graphql/queries/business-info-queries'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../graphql'
import { useQuery } from '@apollo/client'
import { setBusinessLocation } from '../top-navigation-bar/slices/businessInfoSlice'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingDots } from '../common/loading-dots'
import { useDispatch } from 'react-redux'
import { ChannelsModalComponent } from '../common/modals/channels-modal-component/ChannelsModalComponent'
import { AddStaffMobileNav } from './AddStaffMobileNav'
import {
  setShowModal,
  setModalContent,
} from '../common/modals/slices/connectChannelsSlice'
import { getLocalPhone } from '../../modules/settings/MyAccount/BusinessCardForms/my-account-helpers'
import UploadImage from '../top-navigation-bar/UploadImage'
import { setMobileNavOpen } from '../left-navigation-bar/slices/left-nav-slice'
import { setChanelInfo } from '../common/modals/slices/connectChannelsSlice'

export const BusinessInfo = () => {
  const [openLocationModal, setOpenLocationModal] = useState(false)
  const [openBusinessModal, setOpenBusinessModal] = useState(false)
  const [location, setLocation] = useState(false)
  const [remote, setRemote] = useState(false)
  const [remoteToggled, setRemoteToggled] = useState(false)
  const [hasBusinessName, setHasBusinessName] = useState(false)
  const businessLocation = useSelector((state) => state.businessInfo.location)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const [isMobile, setIsMobile] = useState(false)
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const { showModal, channelInfo } = useSelector((state) => state.connectChannels)
  const handleAddChannelsClick = () => {
    dispatch(setModalContent(1))
    dispatch(setShowModal(true))
    dispatch(setMobileNavOpen())
  }

  addEventListener('resize', () => {
    if (window.innerWidth < 640) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  })

  useEffect(() => {
    if (window.innerWidth < 640) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const { loading: loadingBusinessInfo } = useQuery(GET_BUSINESS_INFO_BY_LOCATION, {
    variables: {
      location_id: user.businessId,
    },
    onCompleted: (data) => {
      const {
        Name,
        BusinessCategory,
        Address,
        City,
        Zip,
        State,
        Phone,
        CountryCode,
        Email,
        Website,
      } = data.queryBusinessInfoByLocation[0]

      const convertAlphaToLong = (alphaCode) => {
        switch (alphaCode) {
          case 'US':
            return 'United States'
          case 'CA':
            return 'Canada'
          case 'NZ':
            return 'New Zealand'
          case 'AU':
            return '+61'
        }
        return ''
      }

      const country = convertAlphaToLong(CountryCode)
      const validCountryCode = CountryCode || countryISO
      const localPhone = getLocalPhone(CountryCode, Phone)
      dispatch(
        setBusinessLocation({
          name: Name || '',
          category: BusinessCategory || '',
          address: Address || '',
          city: City || '',
          zip: Zip > 0 ? Zip : '',
          state: State || '',
          country: country || '',
          countryCode: validCountryCode || '',
          phone: localPhone || '',
          email: Email || '',
          website: Website || '',
        }),
      )
    },
    onError: () => {},
  })

  const { loading: loadingChannel } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    onCompleted: (data) => {
      const activeChannels = data?.queryConnectedChannelsByUser?.filter(
        (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
      )

      //  had to add the conditional because it would try to set it before the data comes back, which caused it to make multiple calls to set the redux slice
      if (activeChannels) {
        dispatch(setChanelInfo(activeChannels))
        // selecting the channel is being handled in the channels button now
        // dispatch(setSelectedChannel(activeChannels[0]))
      }
    },
    onError: () => {},
  })

  return loadingBusinessInfo || loadingChannel ? (
    <div className="flex w-[280px] h-[130px] bg-white p-1 items-center justify-center">
      <LoadingDots />
    </div>
  ) : (
    <>
      {showModal && <ChannelsModalComponent />}
      <div className="p-1 bg-white">
        {channelInfo.length ? (
          <AddStaffMobileNav />
        ) : (
          <Button
            className="w-full h-12 text-sm"
            variant="primary"
            buttonType="outlined"
            onClick={handleAddChannelsClick}
          >
            Add Channels
          </Button>
        )}

        <div className="h-[2px] bg-[#F0F0F0] mt-[12px]"></div>

        <div
          className={`flex gap-[10px] py-[10px] items-center ${
            hasBusinessName || location ? '' : 'h-[60px]'
          }`}
        >
          <div className="flex items-center pt-[8px] max-h-[60px] max-w-[60px] pl-1">
            <UploadImage />
          </div>

          <div className="max-w-[185px] w-[185px] flex flex-col">
            <CustomModal
              isModalForm={true}
              isOpen={openBusinessModal}
              setIsOpen={setOpenBusinessModal}
              handleClose={() => {
                setOpenBusinessModal(false)
              }}
              width={isMobile ? '300px' : '326px'}
            >
              <BusinessNameModal
                setHasBusinessName={setHasBusinessName}
                setOpenBusinessModal={setOpenBusinessModal}
              />
            </CustomModal>
            {businessLocation.name.length > 0 ? (
              <span
                className="flex break-words sm:max-w-[185px] sm:truncate sm:block text-[14px] font-primary font-semibold leading-[20px] text-[#231F20] cursor-pointer"
                onClick={() => setOpenBusinessModal(true)}
              >
                {businessLocation.name}
              </span>
            ) : (
              <span
                className="text-[14px] font-primary font-semibold leading-[20px] text-[#057AFF] cursor-pointer"
                onClick={() => setOpenBusinessModal(true)}
              >
                Add business name
              </span>
            )}
            {remote ? (
              <span
                onClick={() => setOpenLocationModal(true)}
                className="text-[12px] font-primary font-semibold leading-[18px]  text-[#808080] cursor-pointer"
              >
                Remote
              </span>
            ) : businessLocation.address?.length > 0 ? (
              <span
                onClick={() => setOpenLocationModal(true)}
                className="line-clamp-2 text-[12px] font-primary font-semibold leading-[18px] text-[#808080] cursor-pointer"
              >
                {businessLocation.address}
                {businessLocation.city.length > 0 && ', ' + businessLocation.city}
                {businessLocation.state.length > 0 && ', ' + businessLocation.state}
                {businessLocation.zip.length > 0 && ', ' + businessLocation.zip}
              </span>
            ) : (
              <span
                onClick={() => setOpenLocationModal(true)}
                className="text-[12px] font-primary font-semibold leading-[18px]  text-[#057AFF] cursor-pointer"
              >
                Add location
              </span>
            )}
            <CustomModal
              isModalForm={true}
              isOpen={openLocationModal}
              setIsOpen={setOpenLocationModal}
              handleClose={() => {
                setOpenLocationModal(false)
              }}
              width={isMobile ? '326px' : '500px'}
            >
              <LocationModal
                setLocation={setLocation}
                location={location}
                setOpenLocationModal={setOpenLocationModal}
                remote={remote}
                setRemote={setRemote}
                remoteToggled={remoteToggled}
                setRemoteToggled={setRemoteToggled}
              />
            </CustomModal>
          </div>
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </div>

        <div className="h-[2px] bg-[#F0F0F0] mb-[6px]"></div>
      </div>
    </>
  )
}
