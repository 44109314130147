import { Icon } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../../command-center-icon'

export const OutgoingCallButton = ({ className, onClick }) => (
  <button
    className={`${className} flex justify-center items-center w-[42px] h-[42px] border border-thryv-sapphire-500 rounded-full`}
    type="button"
    onClick={onClick}
    data-testid="voicemail-icon"
  >
    <CommandCenterIcon
      type="regular"
      variant="voicemail"
      color="#5378FC"
      height="14"
      width="24"
    />
  </button>
)

export const PlayButton = ({ className, onClick, showPauseButton, disabled }) => (
  <button
    className={`${className} transition-all duration-300 flex justify-center items-center w-[42px] h-[42px] rounded-full bg-thryv-sapphire-500 
    ${disabled ? 'opacity-50 pointer-events-none' : ' opacity-100'}`}
    type="button"
    onClick={onClick}
  >
    <CommandCenterIcon
      type="solid"
      variant={showPauseButton ? 'roundedPauseButton' : 'playButton'}
      color="white"
      height="15"
      width="13"
    />
  </button>
)

export const ExpandCollapseButton = ({
  className,
  onClick,
  active,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`${className}  flex justify-center items-center h-4 w-4 rounded-full transition-all duration-300 
        ${active && '-rotate-180'}`}
      onClick={onClick}
    >
      <Icon
        variant="caretDown"
        type="solid"
        width="10"
        height="5"
        color={disabled ? '#CCCCCC' : '#4D4D4D'}
      />
    </button>
  )
}
