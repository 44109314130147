import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'
import { useRef } from 'react'
import { useState } from 'react'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { ActionButton } from '../left-action-buttons/action-button'
import {
  getEditorSelection,
  insertEmoji,
} from '../quill-editor/quill-editor-helpers'
import { setEditorView } from '../../../slices/messageComposeSlice'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export const EmojiMenu = ({ quillEditorRef, disableButtons }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [selectionIndex, setSelectionIndex] = useState(0)
  const emojiPickerRef = useRef(null)
  const { editorView } = useSelector((s) => s.messageCompose)
  const dispatch = useDispatch()

  const handleOpenEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker)
    const selection = getEditorSelection(quillEditorRef)
    if (selection) setSelectionIndex(selection.index)
  }

  const handleClickEmoji = (emojiData, event, selectionIndex) => {
    insertEmoji(quillEditorRef, emojiData.emoji || '', selectionIndex)
    if (editorView === 'closed') {
      dispatch(setEditorView('open'))
    }
  }

  useClickOutside(() => {
    setShowEmojiPicker(false)
  }, emojiPickerRef)

  return (
    <div ref={emojiPickerRef} className="sm:relative">
      <ActionButton
        dataTestID="add-emoji"
        title="Emoji"
        onBtnClick={handleOpenEmojiPicker}
        isActive={showEmojiPicker}
        disabled={disableButtons}
      >
        <CommandCenterIcon
          variant="happyEmoji"
          type="regular"
          width={20}
          height={20}
          fill="#808080"
        />
      </ActionButton>
      {showEmojiPicker && (
        <div className="sm:absolute sm:left-[210px] x-sm:left-[230px]">
          <EmojiPicker
            onEmojiClick={(emojiData, event) => {
              handleClickEmoji(emojiData, event, selectionIndex)
              setSelectionIndex((index) => index + 2)
              setShowEmojiPicker(false)
            }}
            emojiStyle={EmojiStyle.NATIVE}
            lazyLoadEmojis={true}
            autoFocusSearch={true}
            skinTonePickerLocation={'PREVIEW'}
            width={280}
            height={320}
            categories={[
              'smileys_people',
              'animals_nature',
              'food_drink',
              'activities',
              'travel_places',
              'objects',
              'symbols',
              'flags',
            ]}
          />
        </div>
      )}
    </div>
  )
}
