import { forwardRef } from 'react'
import { TransitionContainer } from '../../../transition-container'
import { useNavigate } from 'react-router-dom'

const NavOption = ({ title, onClick }) => {
  return (
    <button
      className="hover:bg-thryv-white-200 active:bg-thryv-white-200 w-full text-left px-2 py-[9px] transition-bg duration-300 rounded"
      onClick={onClick}
    >
      <h4 className="font-semibold text-thryv-steel text-sm font-[montserrat]">
        {title}
      </h4>
    </button>
  )
}

export const NavMenu = forwardRef(
  ({ className, show, linksArray, toggleMenu, setSelectedOption }, menuRef) => {
    const navigate = useNavigate()

    {
      /* Must use absolute path for `url` in order for title change to work when using `url` */
    }
    const onOptionClick = (url, onClick, index) => {
      toggleMenu(false)
      if (setSelectedOption !== undefined) {
        setSelectedOption(index)
        onClick()
      } else {
        navigate(url)
      }
    }

    return (
      <TransitionContainer
        show={show}
        transformOrigin="top"
        className={`${className} p-2`}
        ref={menuRef}
        as="ul"
        style={{
          minWidth: 113,
          width: 'fit-content',
        }}
      >
        {linksArray.map((link, i) => {
          return (
            <li key={i}>
              <NavOption
                title={link.title}
                onClick={() => onOptionClick(link.url, link.onClick, i)}
              />
            </li>
          )
        })}
      </TransitionContainer>
    )
  },
)
