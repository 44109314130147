import { Button, Header, Icon } from '@thryvlabs/maverick'
import thryvguy_sparklers from '../../../../assets/thryvguy_sparklers.png'

const PaidStaffUpdateModal = ({ handleCloseStaffChangeModal }) => {
  return (
    <div className="w-[450px] p-2">
      <div className="flex justify-end gap-8 w-full">
        <Button variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={handleCloseStaffChangeModal}
          />
        </Button>
      </div>
      <div className="flex justify-center -mt-3 -mb-5">
        <img src={thryvguy_sparklers} width={211} height={232} />
      </div>
      <Header variant="h4" fontWeight="semibold">
        Great News for You!
      </Header>
      <p className="font-open-sans text-sm text-thryv-black py-5 px-2">
        With our updated the <b className="text-[#4761C3]">Free Forever plan</b>, you
        will have <b>3 FREE</b> user seats starting Wednesday, October 16th, 2024.
        Thank you for being a valued Command Center user. We are committed to
        providing you with the best service, and we welcome any questions you may
        have at{' '}
        <a href="support@thryv.com" className="text-[#057AFF] underline-offset-1">
          support@thryv.com
        </a>
        .
      </p>
      <div className="py-2">
        <Button variant="primary" onClick={handleCloseStaffChangeModal}>
          Got it
        </Button>
      </div>
    </div>
  )
}

export default PaidStaffUpdateModal
