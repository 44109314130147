export const getRecurlyErrorMessage = (recurlyError) => {
  if (recurlyError.code == 'validation') {
    const fields = recurlyError.details.map((detail) => detail.field)
    if (fields.length > 0) {
      const fieldsError = `Following fields are not valid: ${fields.join('/')}`
      return fieldsError
    }
  }
  return `${recurlyError?.message}`
}
