import { useEffect, useState } from 'react'
import { GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS } from '../../../../../../graphql'
import { useLazyQuery } from '@apollo/client'

export const useContactSuggestions = (search) => {
  const [contactSuggestions, setContactSuggestions] = useState([])
  const [fetchContactSuggestions, { loading }] = useLazyQuery(
    GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS,
  )

  const updateContactSuggestions = async (newSearch) => {
    const { data: contactsData } = await fetchContactSuggestions({
      variables: {
        search: newSearch,
        ranks: {
          full_name: 5,
          contactsk2: 5,
          contactsk3: 5,
        },
        limit: 4,
      },
    })

    const suggestions = contactsData.contactSuggestions
    setContactSuggestions(suggestions)
  }

  const clearSuggestions = () => {
    setContactSuggestions([])
  }

  useEffect(() => {
    updateContactSuggestions(search)
  }, [])

  return {
    contactSuggestions,
    updateContactSuggestions,
    clearSuggestions,
    loading,
  }
}
