import { Helmet } from 'react-helmet-async'

export const DynamicPageTitle = ({ pageTitle, link = { rel: '', href: '' } }) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <link rel={link.rel} href={link.href} />
    </Helmet>
  )
}
