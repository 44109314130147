import * as yup from 'yup'

const formSchema = yup.object().shape({
  currentPassword: yup.string().required('Please enter your current password'),

  newPassword: yup
    .string()
    .min(8, 'Min. 8 characters')
    .matches(/[A-Z]+/, 'Min. 1 upper-case letter')
    .matches(/[a-z]+/, 'Min. 1 lower-case letter')
    .matches(/\d+/, 'Min. 1 number')
    .matches(/[@$!%*#?&]+/, 'A special character')
    .required(),

  confirmPassword: yup
    .string()
    .min(1, 'Please confirm new password')
    .oneOf([yup.ref('newPassword')], 'Passwords do not match.')
    .required(),
})

export default formSchema
