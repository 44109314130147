import { useSelector } from 'react-redux'

export const useCountryData = () => {
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const businessCountryCode = useSelector(
    (state) => state.businessInfo.location.countryCode,
  )

  const isUS = () => {
    return countryISO === 'US' || businessCountryCode === 'US'
  }

  const isCA = () => {
    return countryISO === 'CA' || businessCountryCode === 'CA'
  }

  const isAU = () => {
    return countryISO === 'AU' || businessCountryCode === 'AU'
  }

  const isNZ = () => {
    return countryISO === 'NZ' || businessCountryCode === 'NZ'
  }

  if (isUS()) {
    return {
      emoji: '🇺🇸',
      countryCode: '1',
      countryISO: 'US',
      emojiAndCountryCode: '🇺🇸   +1',
    }
  } else if (isCA()) {
    return {
      emoji: '🇨🇦',
      countryCode: '1',
      countryISO: 'CA',
      emojiAndCountryCode: '🇨🇦   +1',
    }
  } else if (isAU()) {
    return {
      emoji: '🇦🇺',
      countryCode: '61',
      countryISO: 'AU',
      emojiAndCountryCode: '🇦🇺   +61',
    }
  } else if (isNZ()) {
    return {
      emoji: '🇳🇿',
      countryCode: '64',
      countryISO: 'NZ',
      emojiAndCountryCode: '🇳🇿   +64',
    }
  } else {
    return {
      emoji: '🇺🇸',
      countryCode: '1',
      countryISO: 'US',
      emojiAndCountryCode: '🇺🇸   +1',
    }
  }
}
