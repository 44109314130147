import { useLazyQuery } from '@apollo/client'
import { GET_S3_DOWNLOAD_LINK } from '../../../../../graphql'

function useUploadedFiles() {
  const [getDownloadLink] = useLazyQuery(GET_S3_DOWNLOAD_LINK, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })

  return { getDownloadLink }
}

export default useUploadedFiles
