import { useContext } from 'react'
import { Modal } from '@thryvlabs/maverick'
import ChargeCard from './actions-modals/charge-card'
import { QuickActionContext } from './context/quick-action-context'

const QuickActionType = () => {
  const { pickedModal, isActionModal } = useContext(QuickActionContext)

  if (pickedModal === 'chargeCardAction') {
    return (
      <Modal disableBackdropClick={true} hideX openOnLoad={isActionModal}>
        <ChargeCard />
      </Modal>
    )
  }

  return <></>
}

export default QuickActionType
