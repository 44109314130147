import { useState } from 'react'
// Components
import { RoundActionButton } from '../round-action-button'
import { AddStaffModal } from '../../../../../../../components/common/add-staff-modal'
import { ParagraphText, Avatar, withModal } from '@thryvlabs/maverick'

const AddStaffCard = ({ showModal }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className="flex justify-between items-center py-2 px-4 max-w-[600px] border border-dashed border-[#CCCCCC] rounded cursor-pointer sm:border-none sm:relative"
      onClick={showModal}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center gap-2 sm:hidden">
        <Avatar variant="unidentified" size="small" />
        <ParagraphText variant="sm">ADD STAFF</ParagraphText>
      </div>
      <RoundActionButton
        dataTestId="add-new-staff-btn"
        className={`transition-all duration-300 ${
          isHovered && '!bg-[#FF5000]'
        } sm:!bg-[#FF5000] sm:absolute sm:-top-[50px] right-[5px]`}
      />
    </div>
  )
}

export const AddStaffCardWithModal = ({ className }) => {
  const CardWithModal = withModal(AddStaffCard, AddStaffModal)

  return (
    <div className={className}>
      <CardWithModal disableBackdropClick />
    </div>
  )
}
