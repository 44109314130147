import { useState } from 'react'
import { ParagraphText } from '@thryvlabs/maverick'
import { Card, ShowMoreButton } from '../../message-compose/compose-header/ccSection'

export const BccOrRecipientsSection = ({ type, emailRecipient }) => {
  const [showMore, setShowMore] = useState(false)
  let carbonCopyType = ''

  const initCarbonCopyType = () => {
    switch (type) {
      case 'cc':
        carbonCopyType = 'CC'
        break
      case 'bcc':
        carbonCopyType = 'BCC'
        break
      case 'cc/bcc':
        carbonCopyType = 'CC/BCC'
        break
      default:
        carbonCopyType = 'Invalid type'
        break
    }
  }

  initCarbonCopyType()

  const firstItem = emailRecipient?.slice(0, 1)[0] //grabbing the first item of the array to display if the list is too long
  return (
    <div>
      <div className="flex gap-2 mb-2.5">
        <div>
          <ParagraphText
            className="flex items-center h-9"
            variant="sm"
            color="thryv-night"
          >
            {carbonCopyType}:
          </ParagraphText>
        </div>

        <ul className="flex flex-wrap gap-[8px_10px] items-center">
          {showMore ? (
            emailRecipient?.map((recipient, idx) => (
              <li key={idx} className="list-none">
                <Card receiver={recipient} />
              </li>
            ))
          ) : (
            <Card receiver={firstItem} />
          )}
          {emailRecipient?.length > 1 ? (
            <ShowMoreButton
              onClick={() => setShowMore(!showMore)}
              number={emailRecipient?.length - 1}
              showMore={showMore}
            />
          ) : null}
        </ul>
      </div>
    </div>
  )
}
