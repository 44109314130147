import { CommandCenterIcon } from '../../../../command-center-icon'
import checkIsRoundedSquareIcon from '../utils/checkIsRoundedSquareIcon'
import getChannelIconProps from '../../../../channels-icon/utils/get-channel-icon-prop'
import { IncomingMessageContext } from '../../../../../context/message-context'
import { useContext } from 'react'

export const MessageSocialIcon = ({ variant }) => {
  const socialIcon = getChannelIconProps(variant)
  const verifyIcon =
    socialIcon.variant === 'ttl' ||
    socialIcon.variant === 'calls' ||
    socialIcon.variant === 'facebookMessenger' ||
    socialIcon.variant === 'instagram' ||
    socialIcon.variant === 'webchat'
  const incomingMessage = useContext(IncomingMessageContext)
  const isRoundedSquareIcon = checkIsRoundedSquareIcon(variant)

  if (verifyIcon && incomingMessage) {
    return (
      <div
        className={`flex justify-center items-center w-6 h-6 p-0.5 rounded bg-thryv-white-50 shadow-[0_2px_4px_0px_#00000014]`}
      >
        <CommandCenterIcon
          type={socialIcon.type}
          variant={socialIcon.variant}
          color={socialIcon.color}
          width={isRoundedSquareIcon ? 18 : 24}
          height={isRoundedSquareIcon ? 18 : 24}
        />
      </div>
    )
  } else if (!verifyIcon && incomingMessage) {
    return (
      <div className="h-6 w-6 ">
        <CommandCenterIcon type="solid" variant="imap" />
      </div>
    )
  } else {
    return (
      <div
        className={`flex justify-center items-center w-6 h-6 p-0.5 rounded bg-thryv-white-50 shadow-[0_2px_4px_0px_#00000014]
     `}
      >
        <CommandCenterIcon
          type={socialIcon.type}
          variant={socialIcon.variant}
          color={socialIcon.color}
          width={isRoundedSquareIcon ? 18 : 24}
          height={isRoundedSquareIcon ? 18 : 24}
        />
      </div>
    )
  }
}
