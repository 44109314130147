import { Icon, ParagraphText } from '@thryvlabs/maverick'

const CircleCheckIcon = ({ className, isActive }) => (
  <Icon
    className={className}
    type={isActive ? 'solid' : 'regular'}
    variant="circleCheck"
    height="12"
    width="12"
    fill={isActive ? '#16A085' : '#CCCCCC'}
  />
)

const VerificationListItem = ({ children, isActive }) => {
  return (
    <li className="flex col-span-2 items-center">
      <CircleCheckIcon isActive={isActive} />
      <ParagraphText variant="sm" className="ml-2" color="thryv-steel">
        {children}
      </ParagraphText>
    </li>
  )
}

/**
 *
 *
 * ***** COMPONENT GUIDE *****
 * https://www.notion.so/thryvlabs/RequirementsGroup-418f161ca2b64d7eaa2cf327c0f32a06
 *
 *
 */

export const RequirementsGroup = ({ className, requirementsList }) => {
  return (
    <ul className={`${className}`}>
      {requirementsList.map((requirement, i) => (
        <VerificationListItem key={i} isActive={requirement.isValid}>
          {requirement.text}
        </VerificationListItem>
      ))}
    </ul>
  )
}
