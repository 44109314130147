import styled from 'styled-components'

export const SummaryBox = styled.div`
  background-color: #f8f9fb;
  width: 300px;
  height: 488px;
  font-size: 14px;

  margin-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 28px;
  gap: 4px;


  & .header {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    height: 24px;
    color: #4d4d4d;
  }

  & .order-details {
    margin-top: 5px;
    
    width: 100%;
    font-size: 14px;
    color: #808080;
  }
  @media (max-width: 640px) {
  margin: 30px 0px;
  height: unset;
  }
`

export const OrderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`

export const OrderTotal = styled.div`
  display: flex;
  color: #000;
  font-weight: bold;
  justify-content: space-between;
  margin-top: 14px;
`

export const TotalLine = styled.div`
  width: 100%;
  margin-top: 08px;
  border-bottom: 1px solid #808080;
`
export const PromoSection = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${'' /* align-items: flex-end; */}
  justify-content: flex-end;
`
