import { gql } from '@apollo/client'

export const DELETE_LABEL_FROM_MESSAGE = gql`
  mutation delete_label_from_message_mutation($PK1: ID!, $SK1: Long!) {
    deleteMessageLabel(pk1: $PK1, sk1: $SK1)
  }
`
export const ADD_LABEL_TO_MESSAGE = gql`
  mutation add_label_to_message_mutation(
    $channel_id: String!
    $thread_id: String!
    $threadpk: String
    $body: String!
    $message_sk1: Long!
  ) {
    createMessageLabel(
      input: {
        channel_id: $channel_id
        thread_id: $thread_id
        threadpk: $threadpk
        body: $body
        message_sk1: $message_sk1
      }
    ) {
      pk1
      sk1
      body
      chanpk
      item_type
      labelsk
      threadpk
      ts
    }
  }
`

export const CREATE_LABEL_ADD_TO_MESSAGE = gql`
  mutation create_label_add_to_message(
    $location_id: String!
    $label_name: String!
    $status: String!
    $channel_id: String!
    $thread_id: String!
    $threadpk: String
    $message_sk1: Long!
  ) {
    createLabelOptionForLocation(
      input: { location_id: $location_id, label_name: $label_name, status: $status }
    ) {
      LabelID
      LabelName
      LocationID
    }

    createMessageLabel(
      input: {
        channel_id: $channel_id
        thread_id: $thread_id
        threadpk: $threadpk
        body: $label_name
        message_sk1: $message_sk1
      }
    ) {
      pk1
      sk1
      body
      chanpk
      item_type
      labelsk
      threadpk
      ts
    }
  }
`
