/*
----- SQL Database reference: command_center_qa -----
----- Meaning of integers in Role column in Users table in SQL ----- 

1. admin
2. staff
3. Owner

*/

export const convertRoleIntegerToText = (inputRoleInteger) => {
  switch (parseInt(inputRoleInteger)) {
    case 1:
      return 'admin'
    case 2:
      return 'staff'
    case 3:
      return 'owner'
    default:
      return 'invalid role provided'
  }
}

export const convertRoleTextToInteger = (inputRoleText) => {
  switch (inputRoleText) {
    case 'admin':
      return 1
    case 'staff':
      return 2
    case 'owner':
      return 3
    default:
      return 'invalid role provided'
  }
}
