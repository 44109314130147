import * as yup from 'yup'

const formSchema = (countryISO) => {
  let max = 0
  let min = 0
  const usernameErrorMessage = 'No Special Characters Allowed'

  if (countryISO === 'US' || countryISO === 'CA') {
    min = 10
    max = 11
  }
  if (countryISO === 'AU') {
    min = 9
    max = 12
  }
  if (countryISO === 'NZ') {
    min = 8
    max = 10
  }

  return yup.object({
    firstName: yup
      .string()
      .required('First Name is required')
      .max(15)
      .trim()
      .typeError(usernameErrorMessage),
    lastName: yup
      .string()
      .required('Last Name is required')
      .max(15)
      .trim()
      .typeError(usernameErrorMessage),
    email: yup.string().email(),
    phone: yup
      .string()
      .max(max)
      .min(min, `Please enter a valid ${countryISO} phone number`)
      .matches(/^[\d\s]*$/, 'No special characters allowed')
      .typeError(
        `Please enter a valid ${countryISO} phone number without any special characters`,
      ),
  })
}

export default formSchema
