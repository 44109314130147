import { useState } from 'react'
import { MaskedInput } from '@thryvlabs/maverick'
import { useController } from 'react-hook-form'

export const PhoneInput = ({
  className,
  control,
  errors,
  phoneNumber,
  pattern = '(###) ###-####',
  disabled,
}) => {
  const { field } = useController({ name: 'phone', control })
  const [value, setValue] = useState(phoneNumber)
  const handleChange = (e) => {
    setValue(e.target.value)
    field.onChange(e.target.value)
  }

  return (
    <div className={`h-[27px] text-start ${className}`}>
      <MaskedInput
        errors={errors}
        className="w-full"
        type="tel"
        customPlaceholder="Phone Number"
        name="phone"
        variant="default"
        value={value}
        onChange={handleChange}
        mask={pattern}
        disabled={disabled}
      />
    </div>
  )
}
