import { Button, ParagraphText } from '@thryvlabs/maverick'
import { useEffect, useMemo } from 'react'
import BusinessInfo, {
  getFormNames as getBusinessInfoFormNames,
} from './steps/BusinessInfo'
import BusinessDetails, {
  formNames as businessDetailsFormNames,
} from './steps/BusinessAddress'
import ContactDetails, {
  getFormNames as getContactDetailsFormNames,
} from './steps/BusinessDetails'
import TaxIdNumber, { getFormNames as getTaxIdFormNames } from './steps/TaxIDNumber'
import CampaignType, {
  formNames as campaignTypeFormNames,
} from './steps/CampaignType'
import CTAImage, { getFormNames as getCtaFormNames } from './steps/CTAImage'
import { useForm } from 'react-hook-form'
import formSchema from './data/formSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_BRAND_CALL,
  UPDATE_BRAND_CALL,
  UPDATE_CAMPAIGN_CALL,
} from '../../../../../graphql/mutations/10dlc-mutations'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { setBrand, setPhone } from '../../slices/phoneRegistrationSlice'
import { GET_BRAND_CAMPAIGN_BY_CHANNEL_ID, GET_STAFF } from '../../../../../graphql'
import usePhoneChannel from '../../../../../hooks/use-phone-channel'
import usePhoneChannelInfo from '../../../../inbox/hooks/usePhoneChannelInfo'
import {
  setCardDataConnectError,
  setCardDataConnectWarning,
} from '../../slices/connectChannelsSlice'
import useCardData from '../../../../inbox/hooks/useCardData'
import { useFlags } from 'launchdarkly-react-client-sdk'
const stepTitles = {
  1: 'Business Info',
  2: 'Business Address',
  3: 'Business Details',
  4: 'Tax ID Number (EIN Number)',
  5: 'Campaign Type & Description',
  6: 'CTA Image',
}

const stepComponents = {
  1: BusinessInfo,
  2: BusinessDetails,
  3: ContactDetails,
  4: TaxIdNumber,
  5: CampaignType,
  6: CTAImage,
}

const SmsStepsHeader = ({
  step,
  setStep,
  handleShowPendingModal,
  handleShowSuccessModal,
  setSubmitErrorMessage,
  setShowSubmitErrorNotif,
  handleCloseModal,
  isMobile,
}) => {
  const StepComponent = useMemo(() => stepComponents[step], [step])
  const dispatch = useDispatch()
  const countryCodeState = useSelector((state) => state.countryCode.countryCode)
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userPhoneNumber = singleStaffData?.staff[0]?.Phone
  const brand = useSelector((state) => state.phoneRegistration.brand)
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const [phoneChannel] = usePhoneChannel()
  const { getPhoneCard } = useCardData()
  const [, phoneCardIndex] = getPhoneCard()
  usePhoneChannelInfo()
  const brand_id = useSelector((state) => state.phoneRegistration?.brand_id)
  const campaign_id = useSelector(
    (state) => state.phoneRegistration?.brand?.campaign_id,
  )
  const resubmission =
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'TERMINATED'

  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  const errorNotifHandler = (error) => {
    try {
      const errorMessage = JSON.parse(error)
      if (Array.isArray(errorMessage)) {
        if (errorMessage[0].field === 'reference_id') {
          setSubmitErrorMessage(
            'Submission failed because of a similar business name, address, email, or phone number was already used in another submission. Please edit the submission and try again.',
          )
        } else {
          setSubmitErrorMessage(
            `There was an issue with your ${errorMessage[0].field} - ${errorMessage[0].description}. Please fix this issue and try again.`,
          )
        }
      } else if (errorMessage === 'Brand already exists') {
        setSubmitErrorMessage(
          'Registration has already been submitted. If this is an error, please contact support via email (support@thryv.com) or phone (1-844-998-4798).',
        )
      } else {
        setSubmitErrorMessage(
          'There was an issue with registration. Please contact support via email (support@thryv.com) or phone (1-844-998-4798).',
        )
      }
      setShowSubmitErrorNotif(true)
    } catch (error) {
      setSubmitErrorMessage(
        'There was an issue with registration. Please contact support via email (support@thryv.com) or phone (1-844-998-4798).',
      )
      setShowSubmitErrorNotif(true)
    }
  }

  const [createBrand, { loading: createBrandLoading }] = useMutation(
    CREATE_BRAND_CALL,
    {
      onCompleted: (data) => {
        dispatch(setBrand(data.createBrand))
      },
      onError: (error) => {
        errorNotifHandler(error)
      },
    },
  )

  const [updateBrand, { loading: updateBrandLoading }] = useMutation(
    UPDATE_BRAND_CALL,
    {
      onCompleted: (data) => {
        dispatch(setBrand(data.updateBrand))
      },
      onError: (error) => {
        errorNotifHandler(error)
      },
    },
  )

  const [updateCampaign, { loading: updateCampaignLoading }] = useMutation(
    UPDATE_CAMPAIGN_CALL,
    {
      refetchQueries: [
        GET_BRAND_CAMPAIGN_BY_CHANNEL_ID,
        'QueryBrandCampaignByChannelID',
      ],
    },
  )

  const loadingCalls =
    createBrandLoading || updateBrandLoading || updateCampaignLoading

  const formatUserPhone = (userPhoneNumber) => {
    if (userPhoneNumber?.length === 12) {
      return userPhoneNumber?.slice(2)
    } else if (userPhoneNumber?.length === 11) {
      return userPhoneNumber?.slice(1)
    } else if (userPhoneNumber?.length === 10) {
      return userPhoneNumber
    }

    return ''
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      businessType: brand?.entity_type || 'SOLE_PROPRIETOR',
      businessName: brand?.business_name,
      firstName: brand?.owner_first_name,
      lastName: brand?.owner_last_name,
      dba: brand?.dba,
      ein: brand?.ein,
      verticalType: brand?.vertical,
      stockSymbol: brand?.stock_symbol,
      stockExchange: brand?.stock_exchange,
      address: brand?.street,
      city: brand?.city,
      state: brand?.state,
      postal: brand?.postal_code,
      businessEmail: brand?.email || user.email,
      countryCode: brand?.phone[0],
      phoneNumber: brand?.phone.substring(1) || formatUserPhone(userPhoneNumber),
      businessPrivacyPolicy: brand?.privacy_policy_url,
      businessTC: brand?.tc_url,
      supportContact: brand?.support_contact || '',
      supportType: brand?.support_type,
      campaignType: brand?.consent_method,
      campaignDescription: brand?.consent_details,
      ctaImage: brand?.cta_image_url,
    },
    resolver: yupResolver(formSchema),
  })

  const businessType = watch('businessType')
  const noEin = watch('noEin')
  const campaignType = watch('campaignType')
  const ctaImage = watch('ctaImage')

  const stepFormNames = useMemo(
    () => ({
      1: getBusinessInfoFormNames(businessType),
      2: businessDetailsFormNames,
      3: getContactDetailsFormNames(businessType),
      4: getTaxIdFormNames(businessType, noEin),
      5: campaignTypeFormNames,
      6: getCtaFormNames(campaignType),
    }),
    [businessType, noEin, campaignType],
  )

  const handleBackClick = () => {
    setStep(step - 1)
    if (step === 6) {
      setShowSubmitErrorNotif(false)
    }
  }

  const handleNextClick = async () => {
    const triggerResult = await trigger(stepFormNames[step])
    if (triggerResult) {
      setStep(step + 1)
    }
  }

  async function checkImgUrl(url) {
    if (campaignType === 'TEXT_TO_JOIN' && ctaImage === '') {
      return true
    }

    try {
      const response = await fetch(url, { method: 'HEAD' })
      const buffer = await response.blob()

      return buffer.type.startsWith('image/')
    } catch (e) {
      return false
    }
  }

  const onSubmit = async (data) => {
    const imgCheck = await checkImgUrl(data.ctaImage)

    if (!imgCheck) {
      setSubmitErrorMessage(
        "The link you provided is not an image URL. Please provide a valid image URL and press 'Done' to finish.",
      )
      setShowSubmitErrorNotif(true)
      return
    }

    const phone = `${data.countryCode[1]}${data.phoneNumber}`
    const variables = {
      variables: {
        channel_id: phoneChannel.ChannelID,
        business_name: data.businessName,
        entity_type: data.businessType,
        dba: data.dba,
        vertical: data.verticalType,
        stock_symbol: data?.stockSymbol,
        stock_exchange: data?.stockExchange,
        owner_first_name: data.firstName,
        owner_last_name: data.lastName,
        phone,
        email: data.businessEmail,
        street: `${data.address}${
          data?.optionalAddress && ` ${data?.optionalAddress}`
        }`,
        state: data.state,
        city: data.city,
        postal_code: data.postal,
        ein: data?.ein,
        privacy_policy_url: data?.businessPrivacyPolicy,
        tc_url: data?.businessTC,
        support_contact: data.supportContact,
        support_type: data.supportType,
        consent_method: data.campaignType,
        consent_details: data.campaignDescription,
        cta_image_url: data.ctaImage,
        brand_id,
        campaign_id,
      },
    }

    dispatch(setPhone(phone))

    let brandResponse = null

    if (brandStatus === 'UNVERIFIED') {
      brandResponse = await updateBrand(variables)
    } else if (
      campaignStatus === 'UPDATES_REQUIRED' ||
      campaignStatus === 'TERMINATED'
    ) {
      brandResponse = await updateCampaign(variables)
    } else {
      brandResponse = await createBrand(variables)
    }

    const brandIdResponse =
      brandResponse.data?.createBrand?.brand_id ||
      brandResponse.data?.updateBrand?.brand_id ||
      brandResponse.data?.updateCampaign?.brand_id

    if (brandIdResponse && resubmission) {
      dispatch(
        setCardDataConnectError({
          channelClickedID: phoneCardIndex,
          connectError: false,
        }),
      )
      dispatch(
        setCardDataConnectWarning({
          channelClickedID: phoneCardIndex,
          connectWarning: isActive ? true : false,
        }),
      )
      dispatch(setBrand(brandResponse.data))
      handleCloseModal()
    } else if (brandIdResponse) {
      data.businessType === 'SOLE_PROPRIETOR'
        ? handleShowPendingModal()
        : handleShowSuccessModal()
    } else {
      errorNotifHandler(brandResponse.errors?.message)
    }
  }

  const watchFields = watch(stepFormNames[step])

  const handleDisabled = () => {
    for (let i = 0; i < watchFields.length; i++) {
      if (!watchFields[i]) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    setValue('countryCode', countryCodeState)
  }, [countryCodeState])

  useEffect(() => {
    if (businessType !== 'PUBLIC_PROFIT') {
      setValue('stockSymbol', undefined)
      setValue('stockExchange', undefined)
    }
    if (businessType === 'SOLE_PROPRIETOR') {
      setValue('businessPrivacyPolicy', undefined)
      setValue('businessTC', undefined)
    }
  }, [businessType])

  return (
    <>
      <div className="text-left mt-5 w-full">
        <ParagraphText
          variant="reg"
          className="mt-6 text-sm font-semibold leading-[20px]"
        >
          Step {step} of 6 - {stepTitles[step]}
        </ParagraphText>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full"
      >
        <div>
          <StepComponent
            register={register}
            setStep={setStep}
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            businessType={businessType}
            noEin={noEin}
            getValues={getValues}
            isMobile={isMobile}
          />
        </div>
        <div className="grow" />
        {loadingCalls && (
          <div className="flex pl-20 mb-[20px]">
            <span className="w-[38px] h-[38px] border-4 border-[#E3E6E8] border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative left-1/3 top-5" />
          </div>
        )}
        <div className="mb-10 sm:my-5">
          {!loadingCalls && step <= 6 && step > 1 && (
            <Button
              variant="text"
              level={2}
              textVariant="light"
              onClick={handleBackClick}
              className="mr-5 text-xs"
            >
              Back
            </Button>
          )}
          {step < 6 && step > 0 && (
            <Button
              variant="primary"
              disabled={handleDisabled()}
              type="button"
              onClick={handleNextClick}
            >
              Next
            </Button>
          )}
          {!loadingCalls && step === 6 && (
            <Button variant="primary" disabled={handleDisabled()} type="submit">
              Done
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default SmsStepsHeader
