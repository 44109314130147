import { gql } from '@apollo/client'

export const SET_CHANNEL_NICKNAME = gql`
  mutation set_channel_nickname($channel_id: String!, $nickname: String!) {
    channel: setChannelNickname(channel_id: $channel_id, nickname: $nickname) {
      AccessType
      ChannelID
      AccountUserValue
      CCID
      ExternalAccountID
      Provider
      Status
      CC_UserID
      VendorID
      syncState
      Nickname
    }
  }
`
