import { useLazyQuery, useSubscription } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  SUBSCRIPTION_FOR_MESSAGE_CREATED,
  GET_MESSAGES_BY_PHONE_CHANNEL,
} from '../../graphql'
import { FETCH_THREADS } from '../../graphql'

import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../redux-toolkit/slices/notifications/notifications-slice'
import { setCalls, setVoicemails } from '../calls/slices/callSlice'

import { Buffer } from 'buffer'
window.Buffer = Buffer

export function CallsSubscriptionListener() {
  const dispatch = useDispatch()

  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)
  const { data: subscriptionAlert } = useSubscription(
    SUBSCRIPTION_FOR_MESSAGE_CREATED,
  )

  const [getUnreadThreads, { data: unreadThreadsData }] = useLazyQuery(
    FETCH_THREADS,
    {
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      variables: {
        folder: 'unread',
      },
      onCompleted: () => {
        const threads = unreadThreadsData?.data?.queryThreads?.items?.filter(
          (x) => x?.messages?.items?.length > 0,
        )
        const pinned = unreadThreadsData?.data?.pinned?.items?.filter(
          (x) => x?.messages?.items?.length > 0 && x?.read === false,
        )
        dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
      },
    },
  )

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        dispatch(setCalls(recentCallsData.queryMessagesByPhoneChannel))
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        dispatch(setVoicemails(recentVoicemailsData.queryMessagesByPhoneChannel))
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  useEffect(() => {
    if (subscriptionAlert) {
      getUnreadThreads()
      getRecentVoicemails({
        variables: {
          chanpk: phoneChannelID,
          item_type: 'VOICEMAIL',
        },
      })

      getRecentCalls({
        variables: {
          chanpk: phoneChannelID,
          item_type: 'CALL',
        },
      })
    }
  }, [subscriptionAlert, phoneChannelID])

  return <></>
}
