import {
  RegularClockIcon,
  BangCircleIcon,
} from '../../command-center-icon/icons/icons'
import { useSelector } from 'react-redux'
import { GET_CALLS_MINUTES } from '../../../graphql'
import { useQuery } from '@apollo/client'
import { useRedux } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { setMinutesRemaining } from '../../../redux-toolkit'
import { secondsToMinutesConverter } from '../add-minutes/SecondsToMinutesConverter'
import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'

const MinutesCounter = ({ setOpenAddMoreMinutes }) => {
  const dispatch = useDispatch()
  const { tier } = useSelector((state) => state.calls)
  const [{ minutesRemaining }] = useRedux('meetings')
  const { user } = useAuth0()
  const { role } = useSelector((state) => state.user)
  const userRole = role || user?.cc_role

  /* Users with Fremium or Plus accounts display "UPGRADE"
       Users with Pro acccounts display "ADD MORE"
    */

  const [isMounted, setIsMounted] = useState(true)

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  useQuery(GET_CALLS_MINUTES, {
    onCompleted: (data) => {
      if (isMounted) {
        dispatch(
          setMinutesRemaining(
            secondsToMinutesConverter(data.queryCallsMinutesByCCID.SecondsRemaining),
          ),
        )
      }
    },
  })

  const actionText = tier === 'pro' ? 'ADD MORE' : 'UPGRADE'
  /* Users with more than 20 minutes remaining get a green indicator and clock icon
        Users with fewer than 20 minutes but more than 0 get a yellow indicator and warning icon
        Users with 0 minutes remaining get a red indicator and  warning icon
    */
  let backgroundClass = 'bg-[#16A085]'
  if (minutesRemaining <= 0) {
    backgroundClass = 'bg-[#DF2A2A]'
  } else if (minutesRemaining > 0 && minutesRemaining <= 20) {
    backgroundClass = 'bg-[#FAC02B]'
  }

  return (
    <div
      data-testid="minutes-counter"
      className="flex font-['Open_Sans'] text-[12px] h-[64px] items-center justify-center bg-white border-[2px] border-solid border-[#ECEEF1] rounded-[6px] px-[16px]"
    >
      <div
        data-testid="minutes-remaining-icon"
        className={`${backgroundClass} flex w-[36px] h-[36px] rounded-full`}
      >
        {minutesRemaining > 20 ? <RegularClockIcon /> : <BangCircleIcon />}
      </div>
      <div className="flex text-black px-3.5 grow justify-center items-center">
        <span
          data-testid="minutes-remaining"
          className="flex font-bold items-center"
        >
          {minutesRemaining}&nbsp;
        </span>
        <span className="flex whitespace-nowrap overflow-hidden text-overflow-ellipsis">
          mins remaining
        </span>
      </div>
      {userRole !== 2 && (
        <button
          onClick={() => setOpenAddMoreMinutes(true)}
          data-testid="add-more-minutes"
          className="flex hover:bg-thryv-orange active:bg-[#FF8E26] transition-all duration-200 ease-in-out text-[#4e99fe] text-thryv-orange hover:text-white w-[110px] h-[35px] font-semibold font-[14px] border-[2px] border-thryv-orange rounded-full justify-center items-center"
        >
          <span className="py-[20px]">{actionText}</span>
        </button>
      )}
    </div>
  )
}

export default MinutesCounter
