import { Icon } from '@thryvlabs/maverick'

export const ClearButton = ({ className, onClick, isVisible }) => {
  return (
    <button
      data-testid="clear-search"
      type="button"
      className={`rounded-full opacity-100 pointer-events-auto duration-300 transition-opacity
        ${!isVisible && '!opacity-0 !pointer-events-none'} 
        ${className}`}
      onClick={onClick}
    >
      <Icon type="solid" variant="circleX" color="#3E494A" width="16" height="16" />
    </button>
  )
}
