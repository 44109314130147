import { createSelector } from 'reselect'

const selectConnectChannels = ({ connectChannels }) => connectChannels

export const connectChannelsSelector = createSelector(
  selectConnectChannels,
  ({
    activeChannels2,
    cardData,
    channelDisconnected,
    channelInfo,
    channelIsConnected,
    connectedChannelInfo,
    connectionLoading,
    disableBackdropClick,
    maxChannelsAllowed,
    maxChannelsReached,
    modalContent,
    showChannelDisconnectedNotification,
    showFirstChannelModal,
    showModal,
  }) => ({
    activeChannels2,
    cardData,
    channelDisconnected,
    channelInfo,
    channelIsConnected,
    connectedChannelInfo,
    connectionLoading,
    disableBackdropClick,
    maxChannelsAllowed,
    maxChannelsReached,
    modalContent,
    showChannelDisconnectedNotification,
    showFirstChannelModal,
    showModal,
  }),
)
