import { Button, Header, ParagraphText } from '@thryvlabs/maverick'
import { WelcomeCover } from '../../command-center-icon/icons'
import { CallsVideoCover } from '../../../assets'
import { useRef, useState } from 'react'

const buttonStyle =
  'h-[44px] w-[167px] py-auto px-auto rounded-[22px] bg-thryv-orange-500 text-white leading-[14px] text-[15px] font-medium md:h-[32px] md:w-[138px] md:rounded-[4px]'

const ActivationView = ({ openConnectPhoneModal }) => {
  const [playVideo, setPlayVideo] = useState(false)
  const videoRef = useRef(null)

  const handlePlayVideo = () => {
    if (videoRef.current?.paused || playVideo === false) {
      setPlayVideo(true)
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen()
      } else if (videoRef.current.mozRequestFullScreen) {
        /* Firefox */
        videoRef.current.mozRequestFullScreen()
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        videoRef.current.webkitRequestFullscreen()
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE/Edge */
        videoRef.current.msRequestFullscreen()
      }
      videoRef.current.play()
    } else {
      setPlayVideo(false)
      videoRef.current.pause()
    }
  }

  const onEnded = () => {
    setPlayVideo(false)
  }

  return (
    <div
      id="contact-calls"
      className="flex flex-row flex-wrap justify-around content-start relative font-bold h-[735px]"
    >
      <div
        className={`md:w-fit w-[470px] relative z-10  flex flex-col mt-[42px] mx-10 md:mx-[10px]`}
      >
        <Header fontWeight="semibold" variant="h1" className="normal-case">
          Welcome to <span className="text-thryv-orange-500">Calls!</span>
        </Header>
        <ParagraphText
          variant="lg"
          color="thryv-steel"
          className="my-5 text-left font-semibold leading-[22px]"
        >
          Our new features include calling, voicemail and customizing your calling
          experience by connecting with Inbox.
        </ParagraphText>
        <Button
          variant="text"
          level={1}
          className={`${buttonStyle} sm:w-[175px] mt-[25px]`}
          onClick={openConnectPhoneModal}
        >
          Get Your Number
        </Button>{' '}
      </div>
      <div className="pt-4">
        <video
          ref={videoRef}
          width="377"
          height="282"
          controls
          onEnded={onEnded}
          className={playVideo ? 'sm:w-[300px]' : 'sm:w-[300px] hidden'}
        >
          <source src={CallsVideoCover} type="video/mp4" />
        </video>

        {playVideo ? null : <WelcomeCover onClick={() => handlePlayVideo()} />}
      </div>
    </div>
  )
}

export default ActivationView
