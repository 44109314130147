import { ModalWrapper, ParagraphText } from '@thryvlabs/maverick'
import { useAuth0 } from '@auth0/auth0-react'
import { getFirstAndLastName } from '../../../../../top-navigation-bar/utils/get-first-and-last-name'
import FirstChannelAdded from '../../../first-channel-added-modal/FirstChannelAdded'
import { useEffect } from 'react'
import { GET_BRAND_CAMPAIGN_BY_CHANNEL_ID } from '../../../../../../graphql'
import { useLazyQuery } from '@apollo/client'
import usePhoneChannel from '../../../../../../hooks/use-phone-channel'
import { useDispatch } from 'react-redux'
import { setBrand } from '../../../slices/phoneRegistrationSlice'

export const Success = ({ handleCloseModal }) => {
  const { user } = useAuth0()
  const [firstName] = getFirstAndLastName(user)
  const [getBrandCampaign] = useLazyQuery(GET_BRAND_CAMPAIGN_BY_CHANNEL_ID)
  const [phoneChannel] = usePhoneChannel()
  const dispatch = useDispatch()

  // SAVE: Potentially will use in future for SOLE_PROPRIETOR
  // const [
  //   createCampaign,
  //   {
  //     data: createCampaignData,
  //     loading: createCampaignLoading,
  //     error: createCampaignError,
  //   },
  // ] = useMutation(CREATE_CAMPAIGN_CALL, {
  //   onCompleted: () => {
  //     getBrandCampaign({
  //       variables: { channel_id: phoneChannel.ChannelID },
  //       onCompleted: (data) => {
  //         dispatch(setBrand(data))
  //       }
  //     })
  //   }
  // })

  // useEffect(() => {
  //   createCampaign({ variables: { brand_id: brandId }})
  // }, [])

  useEffect(() => {
    getBrandCampaign({
      variables: { channel_id: phoneChannel.ChannelID },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dispatch(setBrand(data))
      },
    })
  }, [])

  return (
    <>
      <div className="w-[436px] h-[370px] p-[5px]">
        <div className="text-left">
          <p className="mb-5 text-[22px] font-montserrat font-semibold leading-[30px]">
            We successfully connected your new phone number!
          </p>
          <ParagraphText variant="reg" className="text-sm leading-[20px]">
            Congrats {firstName}! You can start calling right away. Due to{' '}
            <a
              href="https://learn.thryv.com/hc/en-us/signin?return_to=https%3A%2F%2Flearn.thryv.com%2Fhc%2Fen-us%2Farticles%2F17089148474253-Why-Do-I-Need-To-Register-My-Number-With-TCR-"
              target="_blank"
              className="text-[#057AFF]"
              rel="noreferrer"
            >
              mobile carrier regulation
            </a>
            , you need to register your number before you can send text (SMS)
            messages.
          </ParagraphText>
        </div>
        <FirstChannelAdded handleCloseModal={handleCloseModal} />
      </div>
    </>
  )
}

const SuccessModal = ({ showModal, handleCloseModal }) => {
  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseModal}
      disableBackdropClick={false}
    >
      <Success />
    </ModalWrapper>
  )
}

export default SuccessModal
