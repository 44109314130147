export const TextButton = ({ children, onClick, className }) => {
  return (
    <button
      type="button"
      className={`text-xs text-thryv-sapphire-500 leading-[18px] ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
