import { ContactsListEmptyState } from '@icons'
import Contact from './contact'
import { setPhoneValue } from '../slices/callSlice'
import {
  searchContactByPhone,
  searchContactByNameOrEmail,
} from '../../../redux-toolkit'
import { useDispatch } from 'react-redux'
import { useRedux } from '../../../hooks'
import { Button } from '@thryvlabs/maverick'

const ContactsList = ({
  contactsList,
  connection,
  setMobileContactSideSheetOpen,
  setContactInfoView,
  setShowKeypad,
}) => {
  const [
    {
      contacts: { contactsList: fullContactList },
    },
  ] = useRedux('contacts')
  const showSeeAll = fullContactList.length !== contactsList.length
  const dispatch = useDispatch()
  const renderContactsList = connection ? (
    <div
      style={{ height: 'calc(100vh - 375px)' }}
      className="overflow-y-auto no-scrollbar border-t border-[#F1F1F5]"
    >
      {contactsList?.length
        ? contactsList?.map((contact, index) => (
            <Contact
              key={index}
              contact={{ ...contact }}
              setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
              setContactInfoView={setContactInfoView}
            />
          ))
        : null}
      {showSeeAll ? (
        <div className="font-montserrat text-sm text-[#0000EE] cursor-pointer text-center pt-[20px]">
          <Button
            variant="text"
            level={1}
            type="button"
            onClick={() => {
              dispatch(setPhoneValue(''))
              dispatch(searchContactByPhone(''))
              dispatch(searchContactByNameOrEmail(''))
              setShowKeypad(false)
            }}
          >
            Show All
          </Button>
        </div>
      ) : null}
      {contactsList.length === 1 &&
      contactsList[0].username === 'Thryv Support Center' ? (
        <div className="font-montserrat text-sm text-[#808080] text-center pt-[100px]">
          Your contacts will appear here.
        </div>
      ) : null}
    </div>
  ) : null

  const renderContactsListEmptyState =
    !contactsList?.length && !connection ? (
      <div className="w-full h-[60px] flex gap-3 py-2 px-4 items-center justify-start mb-2 bg-[#ECEEF1]">
        <ContactsListEmptyState />
        <span className="font-open-sans text-sm font-normal text-[#231f20]">
          High Thryv!
        </span>
      </div>
    ) : null

  return (
    <>
      {renderContactsList}
      {renderContactsListEmptyState}
    </>
  )
}

export default ContactsList
