import { Button, ParagraphText } from '@thryvlabs/maverick'

const RegisterInitial = ({ setStep, isMobile }) => {
  return (
    <div>
      <div className="w-full">
        <p className="mt-10 mb-5 px-8 font-montserrat text-[22px] font-semibold leading-[30px]">
          Why do I need to register my phone number?
        </p>
        <ParagraphText
          variant="reg"
          className="mb-5 text-sm font-montserrat leading-[20px]"
        >
          <b>
            In an effort to eliminate spam,{' '}
            <a
              href="https://api.support.vonage.com/hc/en-us/articles/7995696869788"
              target="_blank"
              className="text-[#057AFF]"
              rel="noreferrer"
            >
              The Campaign Registry (TCR)
            </a>{' '}
            and mobile carriers (like T-Mobile, AT&T, etc.) require businesses to
            register prior to sending any SMS traffic. Thryv has no control over
            this.
          </b>
        </ParagraphText>
        <ParagraphText
          variant="reg"
          className="mb-5 px-4 text-sm font-montserrat leading-[20px]"
        >
          If your business isn’t registered, all outgoing SMS messages will be
          blocked by the mobile carriers.
        </ParagraphText>
      </div>
      <Button
        variant="primary"
        className={isMobile ? 'mb-16' : 'mt-20'}
        onClick={() => setStep(1)}
      >
        Register Now
      </Button>
    </div>
  )
}

export default RegisterInitial
