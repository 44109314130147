import { useQuery } from '@apollo/client'
import { Button, Header } from '@thryvlabs/maverick'
import { ParagraphText as Text } from '@thryvlabs/maverick'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TrashIcon } from '../../../components/command-center-icon/icons'
import RecordVoiceModal from '../../../components/calls/recent-and-voicemail/recent-and-voicemail-content/record-voicemail/recordVoicemailModal/recordVoiceModal'
import DeleteVoicemailModal from '../../../components/calls/recent-and-voicemail/recent-and-voicemail-content/delete-voicemail/DeleteVoicemailModal'
import CustomModal from '../../../components/common/CustomModal/Modal'
import { GET_VOICEMAIL_AUDIO } from '../../../graphql/queries/calls-queries'
import { Notification } from '../../../components/common/notification'
import { setVoicemailMetaData } from '../../../redux-toolkit/slices/calls'
import { secondsToTime } from '../../../utils/voicemail'
import { useMediaQuery } from 'react-responsive'

export const VoicemailSettings = () => {
  const dispatch = useDispatch()
  const [openRecordNew, setOpenRecordNew] = useState(false)
  const [deleteVoicemailOpen, setDeleteVoicemailOpen] = useState(false)
  const [voicemailDeleted, setVoicemailDeleted] = useState(false)
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)
  const voicemailMetaData = useSelector((state) => state.contacts.voicemailMetaData)

  const isMobile = useMediaQuery({ maxWidth: 640 })

  const { data } = useQuery(GET_VOICEMAIL_AUDIO, {
    variables: { phone_number: phoneNumber },
    onCompleted: () => {
      const seconds = data?.queryVoicemailGreetingMetaData?.length
      const name = data?.queryVoicemailGreetingMetaData?.name
      const metaData = {
        length: secondsToTime(seconds),
        name: name,
      }
      dispatch(setVoicemailMetaData(metaData))
    },
  })

  useEffect(() => {
    voicemailDeleted &&
      setTimeout(() => {
        setVoicemailDeleted(false)
      }, 5000)
  }, [voicemailDeleted])

  return (
    <div className="relative px-[24px] grid grid-row mt-[1%] h-[114px] sm:h-[150px] bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A]">
      <div className="flex flex-row pt-[24px] h-[118px] justify-between ">
        <div>
          <Header fontWeight="semibold" variant="h6">
            Voicemail
          </Header>
          {voicemailMetaData && (
            <div className="flex gap-6 sm:gap-2">
              <Text
                variant="reg"
                className={`pt-[21px] truncate max-w-[300px] sm:max-w-[100px] ${
                  voicemailMetaData.name !== 'default' && '!text-[#16A085]'
                } !font-semibold`}
              >
                {voicemailMetaData.name === 'default'
                  ? 'Default voicemail greeting'
                  : voicemailMetaData.name}
              </Text>
              <Text
                variant="reg"
                className="pt-[21px] !text-[#16A085] !font-semibold"
              >
                {voicemailMetaData.length !== '00:00' && voicemailMetaData.length}
              </Text>
            </div>
          )}
        </div>

        <div className="flex gap-4 items-center pt-5">
          {voicemailMetaData.length !== '00:00' && (
            <button onClick={() => setDeleteVoicemailOpen(true)}>
              <TrashIcon className="cursor-pointer" />
            </button>
          )}

          <Button
            variant="secondary"
            className="flex px-2"
            onClick={() => setOpenRecordNew(true)}
          >
            <span className="my-auto font-open-sans text-[#4D4D4D] font-semibold text-[13px] sm:text-[10px] leading-[14px]">
              {voicemailMetaData.length === '00:00'
                ? 'Record a new voicemail'
                : 'Change my voicemail greeting'}
            </span>
          </Button>
          {voicemailDeleted && (
            <Notification
              variant="success"
              iconType="solid"
              className="z-[1000] absolute bottom-0 transform -translate-x-1/2 left-[50%] w-full"
              textToRender={
                'Your previous voicemail has been deleted and the default greeting has been restored.'
              }
            />
          )}
        </div>
      </div>

      <CustomModal
        isOpen={openRecordNew}
        showTitle={true}
        close={() => setOpenRecordNew(false)}
        heading="Record a new greeting"
        width={isMobile ? '325px' : '616px'}
        hasCrossIcon={true}
      >
        <RecordVoiceModal setOpenRecordNew={setOpenRecordNew} />
      </CustomModal>
      <CustomModal
        isOpen={deleteVoicemailOpen}
        close={() => setDeleteVoicemailOpen(false)}
        width={isMobile ? '325px' : '500px'}
        hasCrossIcon={true}
      >
        <DeleteVoicemailModal
          setDeleteVoicemailOpen={setDeleteVoicemailOpen}
          setVoicemailDeleted={setVoicemailDeleted}
        />
      </CustomModal>
    </div>
  )
}
