import RecentAndVoicemail from './recent-and-voicemail-content/RecentAndVoicemailMain'

export const Voicemails = ({ setContactInfoView }) => {
  return (
    <div id="contacts-calls" className="h-full overflow-y-scroll scroll-smooth">
      <RecentAndVoicemail
        tabSelected="voicemail"
        setContactInfoView={setContactInfoView}
      />
    </div>
  )
}

export const Recents = ({ contactInfoView, setContactInfoView }) => {
  return (
    <div
      id="contacts-calls"
      className="h-auto overflow-y-scroll no-scrollbar scroll-smooth"
    >
      <RecentAndVoicemail
        tabSelected="recent"
        contactInfoView={contactInfoView}
        setContactInfoView={setContactInfoView}
      />
    </div>
  )
}
