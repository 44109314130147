import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'
import { createFolders } from './utils/createFolders'
import { UPDATE_THREAD_READ_TIME } from '../../../../../../graphql/queries/inbox-queries'
import {
  UPDATE_THREAD_FIELD,
  REMOVE_THREAD_FIELD,
} from '../../../../../../graphql/mutations/inbox-thread-mutations'
import { useInboxMenuConversations } from '../../../hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import {
  setShowInboxNotification,
  setSelectedThread,
  setSelectedThreadCompleted,
  setSelectedThreadSpam,
  setSelectedThreadTrash,
  setUnreadThreadIds,
} from '../../../../slices/inboxThreadsSlice'
import { FETCH_THREADS } from '../../../../../../graphql/queries/inbox-queries'
import { GET_MESSAGES_BY_PHONE_CHANNEL } from '../../../../../../graphql'
import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../../../../../redux-toolkit/slices/notifications/notifications-slice'

// Components
import RoundedActionButton from '../../../../common/rounded-action-button'
import { MoveFolderButtonWithMenu } from './move-folder-button-with-menu'

export const ActionButtonsMenu = ({
  show,
  folderName,
  isPinned,
  onMenuToggle,
  hideHoverButtons,
  threadId,
  startTime,
  endTime,
  time,
  isViewed,
}) => {
  const dispatch = useDispatch()
  const {
    selectedThread,
    mostRecentThread,
    inboxThreadSk1s,
    inboxPinnedThreadSk1s,
  } = useSelector((state) => state.inboxThreads)
  const [folders, setFolders] = useState([])
  const [trashClicked, setTrashClicked] = useState(false)
  const [moveFolderClicked, setMoveFolderClicked] = useState(false)
  const { inboxFilter } = useSelector((state) => state.inboxThreads)
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const threads = [...inboxThreadSk1s, ...inboxPinnedThreadSk1s]
  const sk1 = [threads.filter((x) => x.id === threadId)[0]?.sk1]

  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,

    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  const fetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  const [getUnreadThreads, { data: unreadThreadsData }] = useLazyQuery(
    FETCH_THREADS,
    {
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      variables: {
        folder: 'unread',
      },
      onCompleted: () => {
        const threads = unreadThreadsData?.data?.queryThreads?.items?.filter(
          (x) => x?.messages?.items?.length > 0,
        )
        const pinned = unreadThreadsData?.data?.pinned?.items?.filter(
          (x) => x?.messages?.items?.length > 0 && x?.read === false,
        )
        dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
      },
    },
  )

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
      setTrashClicked(false)
      setMoveFolderClicked(false)
      const unreadThreadIds = threads
        .filter((t) => !t?.read)
        .map((t) => t?.thread_id)
      dispatch(setUnreadThreadIds(unreadThreadIds))
    },
  })

  const [updateReadTime] = useMutation(UPDATE_THREAD_READ_TIME, {})

  const [updateToUnread] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: sk1,
      field: 'read',
    },
    onCompleted: () => {
      getThreads()
      getUnreadThreads()
      fetchVoicemailsAndCalls()
    },
  })

  const [updateToRead] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: sk1,
      field: 'read',
    },
    onCompleted: () => {
      getThreads()
      getUnreadThreads()
      fetchVoicemailsAndCalls()
    },
  })

  const [addThreadToSpam, { loading: spamLoading }] = useMutation(
    UPDATE_THREAD_FIELD,
    {
      variables: {
        sk1s: sk1,
        field: 'spamsk',
      },
      onCompleted: () => {
        dispatch(setSelectedThreadSpam(true))
        getThreads()
      },
    },
  )

  const [addThreadToCompleted, { loading: completedLoading }] = useMutation(
    UPDATE_THREAD_FIELD,
    {
      variables: {
        sk1s: sk1,
        field: 'completedsk',
      },
      onCompleted: () => {
        getThreads()
        dispatch(setSelectedThreadCompleted(true))
        if (selectedThread.id === mostRecentThread[0].id) {
          dispatch(setSelectedThread(mostRecentThread[1]))
        } else {
          dispatch(setSelectedThread(mostRecentThread[0]))
        }
      },
    },
  )

  const [removeThreadFromSpam, { loading: removeSpamLoading }] = useMutation(
    REMOVE_THREAD_FIELD,
    {
      variables: {
        sk1s: sk1,
        field: 'spamsk',
      },
      onCompleted: () => {
        dispatch(setSelectedThreadSpam(false))
        getThreads()
      },
    },
  )

  const [removeThreadFromTrash, { loading: removeTrashLoading }] = useMutation(
    REMOVE_THREAD_FIELD,
    {
      variables: {
        sk1s: sk1,
        field: 'trashsk',
      },
      onCompleted: () => {
        dispatch(setSelectedThreadTrash(false))
        getThreads()
        if (threadId === selectedThread.id) {
          dispatch(setSelectedThread({ id: '', name: '' }))
        }
      },
    },
  )

  const [removeThreadFromCompleted, { loading: removeCompletedLoading }] =
    useMutation(REMOVE_THREAD_FIELD, {
      variables: {
        sk1s: sk1,
        field: 'completedsk',
      },
      onCompleted: () => {
        dispatch(setSelectedThreadCompleted(false))
        getThreads()
      },
    })

  const [deletePinThread] = useMutation(REMOVE_THREAD_FIELD, {
    variables: {
      sk1s: sk1,
      field: 'pinsk',
    },
    onCompleted: () => {
      getThreads()
    },
  })

  const [createPinThread] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: sk1,
      field: 'pinsk',
    },
    onCompleted: () => {
      getThreads()
    },
  })

  const [addThreadToTrash, { loading: trashLoading }] = useMutation(
    UPDATE_THREAD_FIELD,
    {
      variables: {
        sk1s: sk1,
        field: 'trashsk',
      },
      onCompleted: () => {
        dispatch(setSelectedThreadTrash(true))
        getThreads()
        dispatch(setShowInboxNotification(true))
        if (threadId === selectedThread.id) {
          dispatch(setSelectedThread({ id: '', name: '' }))
        }
      },
    },
  )

  const init = () => {
    const newFolders = createFolders(
      folderName,
      threadId,
      startTime,
      endTime,
      updateReadTime,
      addThreadToCompleted,
      addThreadToSpam,
      getThreads,
      time,
      inboxFilter,
      removeThreadFromSpam,
      removeThreadFromTrash,
      isViewed,
      removeThreadFromCompleted,
      setSelectedThreadCompleted,
      dispatch,
      updateToUnread,
      updateToRead,
    )
    setFolders(newFolders)
  }

  useEffect(() => {
    init()
  }, [isViewed, inboxFilter])

  useEffect(() => {
    if (
      !spamLoading &&
      !completedLoading &&
      !removeSpamLoading &&
      !removeSpamLoading &&
      !removeCompletedLoading &&
      !removeTrashLoading
    ) {
      setMoveFolderClicked(false)
    } else {
      setMoveFolderClicked(true)
    }
  }, [
    spamLoading,
    completedLoading,
    removeSpamLoading,
    removeTrashLoading,
    removeCompletedLoading,
  ])

  useEffect(() => {
    if (!trashLoading) {
      setTrashClicked(false)
    } else {
      setTrashClicked(true)
    }
  }, [trashLoading])

  const handleThreadPinDelete = (e) => {
    e.stopPropagation()
    deletePinThread()
  }

  const handleThreadPinCreate = (e) => {
    e.stopPropagation()
    createPinThread()
  }

  const handleThreadTrash = (e) => {
    e.stopPropagation()
    addThreadToTrash()
  }

  return (
    <>
      {show && (
        <div className={`flex ml-2.5 opacity opacity-100`}>
          {inboxFilter !== 'All' && (
            <>
              {moveFolderClicked ? (
                <div className="flex justify-center">
                  <span className="w-[16px] h-[16px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative top-2" />
                </div>
              ) : (
                <MoveFolderButtonWithMenu
                  data={folders}
                  onMenuToggle={(isVisible) => onMenuToggle(isVisible)}
                  hideHoverButtons={hideHoverButtons}
                />
              )}
            </>
          )}
          {inboxFilter !== 'Trash' && (
            <>
              {trashClicked ? (
                <div className="flex justify-center">
                  <span className="w-[16px] h-[16px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin relative top-2" />
                </div>
              ) : (
                <RoundedActionButton
                  dataTestId="conversation-delete"
                  className="w-[30px] h-[30px]"
                  icon={{
                    variant: 'altTrash',
                    type: 'regular',
                    width: '16',
                    height: '16',
                  }}
                  isMavIcon
                  tooltipTitle="Trash"
                  onClick={handleThreadTrash}
                />
              )}
            </>
          )}

          {inboxFilter !== 'Trash' && inboxFilter !== 'Spam' && (
            <RoundedActionButton
              dataTestId="conversation-pin"
              className="w-[30px] h-[30px]"
              icon={{
                variant: 'thumbtack',
                type: isPinned ? 'solid' : 'regular',
                color: isPinned && '#FF5000',
                hoverColor: isPinned && '#FF5000',
                width: '14',
                height: '16',
              }}
              isCCIcon
              tooltipTitle={isPinned ? 'Unpin' : 'Pin'}
              onClick={isPinned ? handleThreadPinDelete : handleThreadPinCreate}
            />
          )}
        </div>
      )}
    </>
  )
}
