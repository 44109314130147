export const updateStaffInDatabase = async (
  formData,
  staffData,
  singleStaffData,
  emailWatcher,
  countryISO,
  countryCode,
  staffMemberData,
  user,
  setStaffUpdatedName,
  setIsDuplicate,
  setUpdatedStaffData,
  updateStaff,
  getRoleIntegerEquivalent,
) => {
  setStaffUpdatedName({
    firstName: formData.firstName,
    lastName: formData.lastName,
  })
  const duplicateEmail = staffData.staff.some(
    (staff) =>
      staff.Email === emailWatcher &&
      staff.CC_UserID !== singleStaffData.staff[0].CC_UserID,
  )

  const duplicateNumber = staffData.staff.filter(
    (staff) =>
      staff.Status !== '4' &&
      staff.Phone === countryCode + formData.phone &&
      staff.CC_UserID !== singleStaffData.staff[0].CC_UserID,
  )

  if (duplicateNumber.length) {
    setIsDuplicate({ number: true, email: false })
  } else if (duplicateEmail) {
    setIsDuplicate({ number: false, email: true })
  }

  if (duplicateNumber.length === 0 && !duplicateEmail) {
    await updateStaff({
      variables: {
        CC_UserID: staffMemberData.cc_userId,
        LocationID: user.businessId,
        FirstName: formData.firstName,
        LastName: formData.lastName,
        Email: formData.email,
        Phone: countryCode + formData.phone,
        // Status: active ? STAFF_STATUS.active : STAFF_STATUS.inactive, // don't need to pass in an active or inactive status when updating staff details
        Role: getRoleIntegerEquivalent(formData.role.value),
        Type: 'cinnamon roll', // FIXME: Figure out what Type refers to here. A placeholder is needed for this to work.
        CountryCode: countryISO,
      },
    })
  }
  setUpdatedStaffData({
    CC_UserID: staffMemberData.cc_userId,
    LocationID: user.businessId,
    FirstName: formData.firstName,
    LastName: formData.lastName,
    Email: formData.email,
    Phone:
      formData.phone === undefined
        ? staffMemberData.phone
        : countryCode + formData.phone,
    Role: getRoleIntegerEquivalent(formData.role.value),
    Type: 'cinnamon roll',
    CountryCode: formData.countryCode,
  })
}

export const updateChannelPermissionsInDatabase = async (
  newStaffPermissions,
  singleStaffData,
  updateChannelPermissionsBulk,
  setUpdatedStaffPermissionsData,
  formData,
  setStaffUpdatedName,
) => {
  const updatedNewStaffPermissions = newStaffPermissions.map((x) => ({
    ...x,
    UserID: singleStaffData.staff[0].CC_UserID,
  }))

  if (!newStaffPermissions.length) return

  try {
    await updateChannelPermissionsBulk({
      variables: { input: updatedNewStaffPermissions },
    })
    setUpdatedStaffPermissionsData(updatedNewStaffPermissions)
    setStaffUpdatedName({
      firstName: formData.firstName,
      lastName: formData.lastName,
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error updating channel permissions:', err)
  }
}

export const hasStaffDataChanged = (newData, currentData) => {
  return (
    newData.firstName !== currentData.firstName ||
    newData.lastName !== currentData.lastName ||
    newData.phone !== currentData.phone ||
    newData.email !== currentData.email ||
    newData.role !== currentData.role ||
    newData.cc_userId !== currentData.cc_userId
  )
}
