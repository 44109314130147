import { Icon } from '@thryvlabs/maverick'
import { ParagraphText } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons'

const CaretUpIcon = ({ onClick, className }) => {
  return (
    <FontAwesomeIcon
      className={`${className} transition-all duration-300`}
      icon={faCaretUp}
      size={'lg'}
      onClick={onClick}
    />
  )
}

const CarotToggle = ({ isActive, onClick }) => {
  return (
    <button
      type="button"
      className="w-[15px] ml-2"
      aria-label="Show Personal Information"
    >
      <CaretUpIcon onClick={onClick} className={!isActive && 'rotate-180'} />
    </button>
  )
}

const RegularEditFormButton = ({ onClick, children }) => {
  return (
    <button
      data-testid="edit-personal"
      type="button"
      className="flex"
      onClick={onClick}
      aria-label="Edit Personal Information"
    >
      <Icon type="regular" variant="pen" width="18" height="18" color="#057AFF" />
      <ParagraphText
        variant="reg"
        className="ml-1 font-semibold text-[#057AFF]"
        color="thryv-utility-link-info"
      >
        {children}
      </ParagraphText>
    </button>
  )
}

export const EditFormButton = ({
  show,
  isMobile,
  isAccordianOpen,
  onClick,
  onAccordionClick,
}) => {
  return (
    <>
      <div
        className={`flex opacity-100 transition-all duration-300 ${
          !show && 'pointer-events-none !opacity-0'
        }`}
      >
        <RegularEditFormButton onClick={onClick}>Edit</RegularEditFormButton>

        {isMobile && (
          <CarotToggle isActive={isAccordianOpen} onClick={onAccordionClick} />
        )}
      </div>
    </>
  )
}
