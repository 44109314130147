import { ParagraphText } from '@thryvlabs/maverick'
import {
  SecurityStaffSectionPopover,
  SecurityStaffSectionTitle,
} from '../../components'
import { ResetPasswordButtonWithModal } from './reset-password-button-with-modal'

export const SecuritySection = ({ className }) => {
  return (
    <SecurityStaffSectionPopover className={`${className}`}>
      <SecurityStaffSectionTitle className="mb-2">
        Security
      </SecurityStaffSectionTitle>

      <ParagraphText
        variant="reg"
        color="thryv-steel"
        className="!font-medium leading-[22px] !font-[Montserrat] mb-4"
      >
        Receive an email to change your password.
      </ParagraphText>

      <ResetPasswordButtonWithModal />
    </SecurityStaffSectionPopover>
  )
}
