import styled from 'styled-components'

export const StyledDiv = styled.div`
    
    border-radius: ${props => props.isDragging ? '4px':'0px'};
    background-color: white;
    margin-left: 3px;
    padding: 4px 0px;
    border-bottom: 1px solid #F0F0F0;
    @media (max-width: 767px) {
        border-bottom: none;
    }
    box-shadow: ${props => (props.isDragging ? '0px 1px 4px rgba(0, 0, 0, 0.25);': '')}
    `
    
