import VoicemailDefaultScreen from './VoicemailDefaultScreen'
import RecentsDefaultScreen from './RecentsDefaultScreen'
import { useAuth0 } from '@auth0/auth0-react'

const DefaultScreen = ({ tabSelected, isMobile }) => {
  const { user } = useAuth0()
  const [firstname] = user?.name.split('.') || ''
  return (
    <>
      {tabSelected === 'voicemail' ? (
        <VoicemailDefaultScreen username={firstname} isMobile={isMobile} />
      ) : (
        <RecentsDefaultScreen username={firstname} isMobile={isMobile} />
      )}
    </>
  )
}

export default DefaultScreen
