import { useState, useEffect } from 'react'
import { Button, Icon, Input } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../command-center-icon'
import GetAreaCode from './GetAreaCode'
import NoAvailAreaCode from './NoAvailAreaCode'
import { useProvisionNumber } from '../../../../../../vonage/useProvisionNumber'
import { LoadingSpinner } from '../../../../loading-spinner'

const InitialScreen = ({ inputField, setInputField, handleProceedClick }) => {
  const [isRetrying, setIsRetrying] = useState(false)
  const [number, provisionNumber, isProvisioning] = useProvisionNumber()

  const handleOnChange = (event) => {
    setInputField(event.target.value)
  }

  useEffect(() => {
    if (number) {
      if (number === 'provision failed') {
        setIsRetrying(true)
      } else {
        setIsRetrying(false)
        handleProceedClick()
      }
    }
    return () => {
      setIsRetrying(false)
    }
  }, [isProvisioning, number])

  const HandleProvisionNumber = async () => {
    await provisionNumber(inputField)
  }

  return (
    <>
      <div className="flex w-full min-h-[116px] bg-[#F8F9FB] rounded place-items-center justify-center">
        <CommandCenterIcon
          type="solid"
          variant="thryvCCLogoOrange"
          width="52px"
          height="52px"
        />
        <Icon
          type="regular"
          variant="exchange"
          width="20px"
          height="20px"
          className="ml-[40px] mr-[40px]"
        />
        <Icon
          type="solid"
          variant="circlePhone"
          fill="#3D5199"
          width="52px"
          height="52px"
        />
      </div>
      <div className="w-[412px] sm:w-[290px] x-sm:w-[250px] xx-sm:w-[200px]">
        {isRetrying ? <NoAvailAreaCode /> : <GetAreaCode />}
      </div>
      <div className="flex flex-col">
        <div className="flex mt-[32px] justify-center">
          <Input
            type="tel"
            withLabel
            name="areaCode"
            placeholder="Enter Area Code"
            labelType="floating"
            variant="default"
            className="w-[220px] xx-sm:w-[200px]"
            value={inputField}
            onChange={handleOnChange}
            maxLength="3" // maxLength for each country {US/CA:3, NZ:1, Aus:2}
          />
        </div>
      </div>
      <div className="grow" />
      {isProvisioning ? (
        <LoadingSpinner className="mb-16" />
      ) : (
        <Button
          variant="primary"
          className="mb-16"
          onClick={HandleProvisionNumber}
          disabled={!inputField.length}
        >
          {isRetrying ? 'Try Again' : 'Proceed'}
        </Button>
      )}
    </>
  )
}

export default InitialScreen
