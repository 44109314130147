import { ParagraphText } from '@thryvlabs/maverick'

const NoAvailAreaCode = () => {
  return (
    <>
      <ParagraphText
        variant="lg"
        className="mt-10 mb-5 text-[22px] font-semibold leading-[30px]"
      >
        Whoops!
      </ParagraphText>
      <ParagraphText variant="reg" className="mb-5 text-sm leading-[20px]">
        Looks like there aren’t any available phone numbers with your area code.
        Please enter a different area code below and we’ll check availability.
      </ParagraphText>
    </>
  )
}

export default NoAvailAreaCode
