import { useState, useRef, useEffect } from 'react'
import { Icon, ParagraphText, Button } from '@thryvlabs/maverick'
import { UnderlineBorder } from './compose-header'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCcEmailRecipients,
  setCcInputValue,
} from '../../../slices/messageComposeSlice'
import { TransitionContainer } from '../../../../common/transition-container'

export const CCSection = ({ setShowCC }) => {
  const { message, replying } = useSelector((s) => s.messageCompose)
  const [CcInput, setCcInput] = useState({ email: '', name: '' })

  // const ccEmailValue = ccInputValue.email

  const [invalidInput, setInvalidInput] = useState(false)
  const [showMore, setShowMore] = useState(false)

  const ccRef = useRef(null)
  const inputRef = useRef(null)
  const timerRef = useRef(null)

  const dispatch = useDispatch()
  const [ccToData, setCcToData] = useState(message.emailRecipients.cc)

  const submitInputWithClick = () => {
    const validEmail = validateEmail(CcInput.email)
    if (validEmail) {
      dispatch(setCcEmailRecipients([...ccToData, CcInput]))
      const spanInput = document.getElementById('email')
      spanInput.textContent = '' //sets the input to empty
      setInvalidInput(false)
      setCcInput({ ...CcInput, email: '' })
    } else {
      setInvalidInput(true)
      timerRef.current = setTimeout(() => {
        setInvalidInput(false)
      }, 5000)
    }
  }

  useClickOutside(() => {
    //need to make sure this IF is first so it doesnt default to the other if and runs this one
    if (CcInput.email !== '') {
      submitInputWithClick()
      return false
    }
    if (ccToData.length !== 0) {
      return false
    } else {
      setShowCC(false)
    }
  }, ccRef)

  const firstItem = ccToData?.slice(0, 1)

  useEffect(() => {
    if (replying) {
      setCcToData(message.emailRecipients.cc)
    } else {
      return
    }
  }, [message.emailRecipients, replying])

  useEffect(() => {
    setCcToData(message.emailRecipients.cc)
  }, [message.emailRecipients])

  useEffect(() => {
    if (inputRef !== null) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleAddCC = (evt) => {
    setInvalidInput(false) //setting the invalidInput state to false when the user starts typing
    dispatch(setCcInputValue(evt.target.textContent)) //using dispatch to set the value in redux to persist it even if the user closes the tab
    setCcInput({ ...CcInput, [evt.target.id]: evt.target.textContent })
  }

  const handleDelete = (receiverEmail) => {
    const filteredList = ccToData?.filter((cc) => cc !== receiverEmail) // passing filteredList to slice
    setCcToData(filteredList)
    dispatch(setCcEmailRecipients(filteredList))
  }

  const handleShowMoreClick = () => {
    setShowMore(!showMore)
    inputRef.current.focus()
  }

  //validates email and return a true or false value
  function validateEmail(email) {
    let re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  function handleSubmitKey(e) {
    if (
      e.key.toLowerCase() === 'enter' ||
      e.key.toLowerCase() === ' ' || //to run this when space is clicked since ccs shouldnt have spaces
      e.code.toLowerCase() === 'space'
    ) {
      e.preventDefault()
      const validEmail = validateEmail(e.target.textContent)
      if (validEmail) {
        dispatch(setCcEmailRecipients([...ccToData, CcInput]))
        e.target.textContent = '' //sets the input to empty
        setInvalidInput(false)
        setCcInput({ ...CcInput, email: '' })
      } else {
        setInvalidInput(true)
        timerRef.current = setTimeout(() => {
          setInvalidInput(false)
        }, 5000)
      }
    }
  }

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current)
  }, [])

  return (
    <div
      onClick={() => {
        inputRef.current.focus()
      }}
      className="flex flex-col w-0 min-w-full max-w-full"
      ref={ccRef}
    >
      <div id="compose-header-left" className="flex w-full justify-between">
        <div id="cc-section" className="flex">
          <ParagraphText variant="reg" color="thryv-steel" className="pr-2 pt-2">
            Cc:
          </ParagraphText>
          <div
            className={`flex flex-wrap items-center ${
              showMore ? 'gap-2' : ''
            } gap-y-4`}
          >
            {showMore ? (
              <>
                {ccToData?.map((receiver, idx) => {
                  return (
                    <Card
                      receiver={receiver}
                      key={idx}
                      handleDelete={handleDelete}
                    />
                  )
                })}
              </>
            ) : ccToData?.length >= 1 ? ( //checking that there's something to render inside of cctoData object if there's none then just return null
              <Card receiver={firstItem[0]} handleDelete={handleDelete} />
            ) : null}
            <form className="flex flex-col items-center relative z-50">
              <span
                name="email"
                id="email"
                className={`${
                  invalidInput && 'border border-[#FDDF95]'
                } block min-w-[25px] whitespace-nowrap focus:outline-none p-1.5 pr-0 rounded-sm focus:cursor-text max-w-[300px] overflow-x-scroll no-scrollbar text-left text-sm font-snow`}
                ref={inputRef}
                role="textbox"
                // innerText={'previous value'}
                contentEditable
                onInput={(evt) => handleAddCC(evt)}
                onKeyDown={(e) => handleSubmitKey(e)}
                autoFocus
              ></span>
              {/* <input ref={inputRef} /> */}
              <TransitionContainer
                width={'270px'}
                show={invalidInput}
                transformOrigin={'bottom right'}
                positioning="absolute"
                className="border bg-[#FFF9EA] border-[#FDDF95] top-[35px] text-sm flex items-center justify-around py-2"
              >
                <Icon
                  variant="triangleExclamation"
                  type="solid"
                  width={20}
                  color="#FAC02B"
                />
                Email is not in the correct format
              </TransitionContainer>
            </form>
            {ccToData?.length > 1 ? (
              <ShowMoreButton
                number={ccToData?.length - 1}
                onClick={() => handleShowMoreClick()}
                showMore={showMore}
              />
            ) : null}
          </div>
        </div>
        <Button
          variant="text"
          level={1}
          className="z-10 w-[5%]"
          onClick={() => setShowCC(false)}
        >
          Hide
        </Button>
      </div>
      <UnderlineBorder className="w-full mb-2" />
    </div>
  )
}

export const Card = ({ receiver, handleDelete }) => {
  return (
    <div className="rounded bg-thryv-white-50 border border-thryv-gray-light-400 w-fit h-[36px] py-2 gap-2 px-2 text-center flex items-center justify-evenly">
      {/* removing picture for now because is not adding any value to the user */}
      {/* <img src={receiver.profileImg} width={24} /> */}
      <ParagraphText
        variant="reg"
        color="thryv-black-500"
        className="w-fit font-snow"
      >
        {receiver?.email}
      </ParagraphText>
      {handleDelete && (
        <button
          type="button"
          onClick={() => handleDelete(receiver)}
          className="z-20"
        >
          <Icon variant="x" type="regular" width="11" height="11" fill="#4D4D4D" />
        </button>
      )}
    </div>
  )
}

export const ShowMoreButton = ({ onClick, number, showMore, className }) => {
  return (
    <button
      className={`${className} flex bg-thryv-white-300 hover:bg-thryv-gray-medium-100 items-center rounded py-3 px-2 h-6 transition duration-200 ease-in-out`}
      onClick={onClick}
    >
      <ParagraphText
        variant="sm"
        color="thryv-cornflower-500"
        className="font-semibold leading-snug"
      >
        {showMore ? 'Collapse' : `+${number} more`}
      </ParagraphText>
    </button>
  )
}
