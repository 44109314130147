import { SectionTitle } from '../common'
import { SearchContactCard } from './search-contact-card'
import formatContactData from '../../../../../hooks/formatContactData'
export const TopContactsSection = ({
  show,
  contacts,
  textToEmphasize,
  onContactRowClick,
}) => {
  return (
    <>
      {show ? (
        <section>
          <SectionTitle>Top contacts</SectionTitle>
          <ul>
            {contacts.map((contact, i) => {
              const fullName = contact.full_name
              const firstName = fullName ? fullName.split(' ')[0] : ''
              const lastName = fullName ? fullName.split(' ')[1] : ''
              const email = contact.contactsk2
              const threadId = contact.contactsk1
              const phoneNum = formatContactData(contact.contactsk3)

              return (
                <li key={i}>
                  <SearchContactCard
                    onClick={() =>
                      onContactRowClick({
                        fullName: fullName,
                        email: email,
                        threadId: threadId,
                      })
                    }
                    firstName={firstName}
                    lastName={lastName}
                    email={email || phoneNum}
                    imageUrl={''}
                    textToEmphasize={textToEmphasize}
                  />
                </li>
              )
            })}
          </ul>
        </section>
      ) : null}
    </>
  )
}
