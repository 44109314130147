import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { Icon, ParagraphText as Text, Button, Modal } from '@thryvlabs/maverick'
import Input from './Input'
import { PhoneInput } from '../../../../common/add-staff-modal/inputs/PhoneInput'
import { EditContactErrorToast } from './EditContactErrorToast'
import { UPDATE_CONTACT, FETCH_MESSAGES_BY_THREAD_ID } from '@graphql'
import { setSelectedThread } from '../../../../inbox/slices/inboxThreadsSlice'
import UpdateContactModalContent from '../../../../right-panel/user-profile-and-activity/update-contact-modal-content'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import { DELETE_MESSAGES, DELETE_MESSAGES_WITH_MERGE } from '../../../../../graphql'
import { getUpdateContactSchema } from '../../../../../schema/contact-schema'
import { LoadingSpinner } from '../../../../common/loading-spinner'
import styled from 'styled-components'

const ERROR_MSG_STYLES =
  'flex text-center justify-center w-[310px] pl-[10px] text-[12px] text-[red] font-open-sans normal-case'
import { setUpdatedContactMergeNotification } from '../../../../inbox/slices/inboxSlice'

const StyledPhoneInput = styled(PhoneInput)`
  div div {
    background-color: white;
  }
  input[type='tel'] {
    border-bottom: 0px;
    font-family: 'Open Sans';
    font-size: 0.875rem
    width: 235px;
    margin-left: 1rem;
    color: #808080;
  }
`

const EditContactForm = ({
  contactName,
  refetch,
  editingContact,
  setContactInfoView,
  handleClickOnBack,
  contact,
  getThreads,
}) => {
  const dispatch = useDispatch()
  const [disableButton, setDisableButton] = useState(true)
  const { username } = contact
  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const body =
    typeof editingContact.body === 'string'
      ? JSON.parse(editingContact.body)?.body
        ? JSON.parse(editingContact.body).body
        : JSON.parse(editingContact.body)
      : null
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  const isFirstRender = useRef(true)

  const firstName = body?.given_name ? body?.given_name : ''
  const lastName = body?.surname ? body?.surname : ''
  const phone = editingContact.contactsk3 ? editingContact.contactsk3 : ''
  const email = editingContact.contactsk2 ? editingContact.contactsk2 : ''

  const inputInfo =
    !lastName.length && contactName?.length
      ? {
          firstName: contactName.split(' ')[0],
          lastName: contactName.split(' ')[1],
          email,
        }
      : { firstName, lastName, phone, email }

  const parsedContactName =
    contactName?.includes('@') && contactName?.includes('.com')
      ? ''
      : contactName.split(' ')[0]
  const parsedLastName = !contactName?.includes('@')
    ? contactName?.split(' ').slice(1).join(' ')
    : ''

  const firstNameOrEmailOrPhone = parsedContactName.includes('known')
    ? ''
    : parsedContactName
  const [, setContactHasMessages] = useState(false)
  const [updateContactError, setUpdateContactError] = useState(null)
  const [updateContactFailed, setUpdateContactFailed] = useState(false)
  const [maskPattern, setMaskPattern] = useState('')
  const [showDeleteMessagesWarning, setShowDeleteMessagesWarning] = useState(false)
  const [
    showDeleteMessagesWarningAndMergeContact,
    setShowDeleteMessagesWarningAndMergeContact,
  ] = useState(false)
  const [isDeletingMessage, setIsDeletingMessage] = useState(false)
  const [mergeContact, setMergeContact] = useState({
    body: '',
    contactsk1: '',
    contactsk2: '',
    contactsk3: '',
    pk1: '',
    sk1: '',
  })
  const [preUpdatePhone, setPreUpdatePhone] = useState('')
  const [preUpdateName, setPreUpdateName] = useState('')

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else if (
      showDeleteMessagesWarning ||
      updateContactError ||
      updateContactFailed
    ) {
      return
    } else {
      handleClickOnBack()
    }
  }, [editingContact])

  const phoneErrorMessage = `Please enter a valid ${countryCode} phone number without any special characters`

  const USER_FRIENDLY_ERRORS = {
    ERR001: 'Contact changes not saved. Please try again.',
    ERR002: 'Error with your contact record. Please try again.',
    ERR003: 'Unable to update. Please update email or phone, not both.',
    ERR004:
      'Invalid entry. Please enter a valid phone or email address and try again.',
    ERR005: 'Contact changes not saved. Please try again.',
    ERR006:
      'Uh-oh, The contact details you are trying to edit, already belongs to another Contact with both email and phone assigned.',
    ERR007:
      'Uh-oh, looks like the contact details you entered is already associated with another Contact. Please try a different phone number.',
    ERR008:
      'Uh-oh, looks like the contact details you entered is already associated with another Contact. Please try a different email.',
    ERR009:
      'Changing this phone or email will result in the removal of messages from your conversation.',
    ERR010: 'Contact changes not saved. Please try again.',
    ERR011: 'Contact changes not saved. Please try again.',
    ERR012: 'Contact changes not saved. Please try again.',
    ERR013: 'Contact changes not saved. Please try again.',
    ERR014: 'Contact changes not saved. Please try again.',
    ERR015: 'Contact changes not saved. Please try again.',
    ERR016:
      'You are trying to combine two contacts. Your CRM has important details that would be lost if this contact is merged. Please update your CRM contact record.',
    ERR017: 'Invalid entry. Please enter a valid email address and try again.',
    ERR018: 'Contact changes not saved. Please try again.',
  }

  const {
    register,
    handleSubmit,
    setError,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getUpdateContactSchema(countryCode)),
    defaultValues: {
      firstName: firstNameOrEmailOrPhone,
      lastName: parsedLastName,
      phone,
      email,
    },
  })

  const thread_id = editingContact?.contactsk1

  const { data: contactMessages, refetch: refetchMessages } = useQuery(
    FETCH_MESSAGES_BY_THREAD_ID,
    {
      variables: {
        threadid: thread_id,
      },
    },
  )

  useEffect(
    () => {
      if (contactMessages?.items?.length) {
        setContactHasMessages(true)
      }
    },
    [contactMessages?.items?.length],
    setContactHasMessages,
  )

  useEffect(() => {
    setMask(countryCode)
  }, [countryCode])

  const [updateContact, { loading }] = useMutation(UPDATE_CONTACT, {
    errorPolicy: 'all',
    refetchQueries: () => [
      {
        query: FETCH_CONTACT_BY_THREAD,
        variables: { threadid: thread_id },
      },
    ],
  })

  const [deleteMessages] = useMutation(DELETE_MESSAGES, {
    onCompleted: () => {
      handleClickOnBack()
    },
    onError: () => {
      setUpdateContactFailed(true)
    },
  })

  const [deleteMessagesWithMerge] = useMutation(DELETE_MESSAGES_WITH_MERGE, {
    onCompleted: () => {
      dispatch(setUpdatedContactMergeNotification(editingContact?.contactsk1))
      handleClickOnBack()
    },
    onError: () => {
      setUpdateContactFailed(true)
    },
    refetchQueries: () => [
      {
        query: FETCH_CONTACT_BY_THREAD,
        variables: { threadid: thread_id },
      },
    ],
  })

  useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: {
      threadid: thread_id,
    },
    onSuccess: () => {
      setUpdateContactFailed(true)
    },
  })

  const addCountryCodeToPhone = (phoneNumber) => {
    let phone_without_country_code_regex
    let phone_with_country_code_regex

    if (countryCode === 'US' || countryCode === 'CA') {
      phone_without_country_code_regex = /^[\d{10}\s]*$/
      phone_with_country_code_regex = /^[\d{11}\s]*$/
      if (
        phoneNumber?.length === 10 &&
        phone_without_country_code_regex.test(phoneNumber)
      ) {
        return `1${phoneNumber}`
      } else if (
        phoneNumber?.length === 11 &&
        phone_with_country_code_regex.test(phoneNumber)
      ) {
        return phoneNumber
      } else if (phoneNumber?.length === 0) {
        return phoneNumber
      } else {
        return 'error'
      }
    }

    if (countryCode === 'AU') {
      phone_without_country_code_regex = /^[\d{9,10}\s]*$/
      phone_with_country_code_regex = /^[\d{11,12}\s]*$/
      if (
        phoneNumber.length === 9 ||
        (phoneNumber.length === 10 &&
          phoneNumber[0] === '0' &&
          phone_without_country_code_regex.test(phoneNumber))
      ) {
        return `61${phoneNumber}`
      } else if (
        (phoneNumber.length === 11 || phoneNumber.length === 12) &&
        phone_with_country_code_regex.test(phoneNumber) &&
        phoneNumber[0] === '6' &&
        phoneNumber[1] === '1'
      ) {
        return phoneNumber
      } else if (phoneNumber.length === 0) {
        return phoneNumber
      } else {
        return 'error'
      }
    }

    if (countryCode === 'NZ') {
      phone_without_country_code_regex = /^[\d{8,10}\s]*$/
      phone_with_country_code_regex = /^[\d{9,10}\s]*$/
      if (
        phoneNumber.length === 8 ||
        (phoneNumber.length === 10 &&
          phoneNumber[0] === '0' &&
          phone_without_country_code_regex.test(phoneNumber))
      ) {
        return `64${phoneNumber}`
      } else if (
        (phoneNumber.length === 9 || phoneNumber.length === 10) &&
        phone_with_country_code_regex.test(phoneNumber) &&
        phoneNumber[0] === '6' &&
        phoneNumber[1] === '4'
      ) {
        return phoneNumber
      } else if (phoneNumber.length === 0) {
        return phoneNumber
      } else {
        return 'error'
      }
    }
  }

  const inputFirstName = watch('firstName')
  const inputLastName = watch('lastName')
  const inputPhone = watch('phone')
  const inputEmail = watch('email')

  useEffect(() => {
    const isFirstNameChanged = inputFirstName !== firstName
    const isLastNameChanged = inputLastName !== lastName
    const phoneExists = inputPhone !== undefined
    const isPhoneChanged = phoneExists
      ? addCountryCodeToPhone(inputPhone) !== phone
      : false

    const isEmailChanged = inputEmail !== email

    if (
      isFirstNameChanged ||
      isLastNameChanged ||
      isPhoneChanged ||
      isEmailChanged
    ) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [inputFirstName, inputLastName, inputPhone, inputEmail])

  const onSubmit = async (data) => {
    // check to see if it's clearing a field that prior had info in it
    const isClearingData = (contactDetail, newData) => {
      return contactDetail && !newData?.trim()
    }

    if (isClearingData(editingContact.contactsk2, data.email)) {
      // prevent submission and then show error

      setUpdateContactError({
        message: 'You cannot remove email from a contact',
        toastType: 'failure',
      })
      return
    }

    if (isClearingData(editingContact.contactsk3, data.phone)) {
      // prevent submission and then show error

      setUpdateContactError({
        message: 'You cannot remove phone number from a contact',
        toastType: 'failure',
      })
      return
    }

    const updatedContact = {}
    const contactsk2 = data?.email?.toLowerCase() || ''
    const contactsk3 = data?.phone
      ? addCountryCodeToPhone(data?.phone) === 'error'
        ? setError('phone', {
            type: 'manual',
            message: '',
          })
        : addCountryCodeToPhone(data?.phone)
      : ''
    const sk1 = editingContact?.sk1 || ''
    updatedContact.given_name = `${data?.firstName}` || ''
    updatedContact.surname = data?.lastName || ''
    updatedContact.emails = [{ email: email, type: '' }]
    updatedContact.phone_numbers = [{ number: phone, type: '' }]
    setPreUpdatePhone(contactsk3)
    setPreUpdateName(updatedContact.given_name + ' ' + updatedContact.surname)
    // Clear error from state before calling the updateContact mutation.
    setUpdateContactError(null)

    if (data?.phone && addCountryCodeToPhone(data?.phone) === 'error') {
      return
    }

    // Can only update EITHER contactsk2 OR contactsk3 at a time
    try {
      const res = await updateContact({
        variables: {
          body: JSON.stringify(updatedContact),
          contactsk2: contactsk2,
          contactsk3: contactsk3,
          sk1,
        },
      })

      // If the update contact mutation failed
      if (res?.errors?.length) {
        const errorMsg = res?.errors[0]?.message || ''
        const errCode = res?.errors[0]?.errorType || ''
        const newMergeContact = res?.data?.update_contact?.mergeContact

        // Handling showing the error toast on the UI.
        setUpdateContactError({
          message: USER_FRIENDLY_ERRORS[errCode],
          toastType: 'failure',
        })

        // Handling deleteMessages
        if (errCode === 'ERR009') {
          if (newMergeContact) {
            // If getting back ERR009 and mergeContact need to run deleteMessages with mergeContact
            setShowDeleteMessagesWarningAndMergeContact(true)
            setMergeContact({
              body: newMergeContact.body,
              contactsk1: newMergeContact.contactsk1,
              contactsk2: newMergeContact.contactsk2,
              contactsk3: newMergeContact.contactsk3,
              pk1: newMergeContact.pk1,
              sk1: newMergeContact.sk1,
            })
          } else {
            // If getting back ERR009 without mergeContact need to run deleteMessages without mergeContact
            setShowDeleteMessagesWarning(true)
          }
        }

        if (errorMsg.length) {
          setUpdateContactFailed(true)
        } else {
          setUpdateContactFailed(false)
        }
      } else {
        await getThreads()
        handleClickOnBack()
        if (!window.location.href.includes('/calls')) {
          dispatch(
            setSelectedThread({
              id: selectedThreadId,
              name: res?.data?.update_contact?.body.length
                ? JSON.parse(res?.data?.update_contact?.body).given_name
                  ? JSON.parse(res?.data?.update_contact?.body).given_name +
                    ' ' +
                    JSON.parse(res?.data?.update_contact?.body).surname
                  : res?.data?.update_contact?.contactsk2
                    ? res?.data?.update_contact?.contactsk2
                    : res?.data?.update_contact?.contactsk3
                      ? res?.data?.update_contact?.contactsk3
                      : 'unknown'
                : 'unknown',
            }),
          )
        }
      }
    } catch (err) {
      setUpdateContactFailed(true)
    }
  }

  const handleDeleteMessages = async () => {
    setIsDeletingMessage(true)
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessages({
      variables: {
        VendorID: preUpdatePhone === phone ? 1 : 2,
        thread_id: editingContact?.contactsk1,
      },
    })
    // after contact is updated & messages are deleted, refetch threads & contact
    await getThreads()
    // use setTimeout to refetchContact because BE needs some time to fully update contacts and delete messages
    setTimeout(() => {
      refetch()
      dispatch(setSelectedThread({ id: '', name: '' }))
      dispatch(setSelectedThread({ id: selectedThreadId, name: preUpdateName }))
      refetchMessages()
      setIsDeletingMessage(false)
    }, 800)
    handleClickOnBack()
  }

  const handleDeleteMessagesAndMergeContacts = async () => {
    setIsDeletingMessage(true)
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessagesWithMerge({
      variables: {
        VendorID: preUpdatePhone === phone ? 1 : 2,
        thread_id: editingContact?.contactsk1,
        body: mergeContact.body,
        contactsk1: mergeContact.contactsk1,
        contactsk2: mergeContact.contactsk2,
        contactsk3: mergeContact.contactsk3,
        pk1: mergeContact.pk1,
        sk1: mergeContact.sk1,
      },
    })
    setIsDeletingMessage(false)
    handleClickOnBack()
  }

  const ChevronRightIcon = () => (
    <Icon
      type="solid"
      variant="chevronRight"
      height="11.43"
      width="6.94"
      color="#CCCCCC"
    />
  )

  const renderErrorToast = updateContactError ? (
    <EditContactErrorToast
      {...updateContactError}
      setContactInfoView={setContactInfoView}
    />
  ) : null

  const setMask = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          phone[1] === '4' ||
          phone[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }

      case 'NZ':
        return setMaskPattern('(##) ###-####')
    }
    return '(###) ###-####'
  }

  const trimPhone = (phone) => {
    return phone.substring(1)
  }

  const getChangeType = () => {
    if (email !== getValues('email')) {
      return 'email'
    } else {
      return 'phone'
    }
  }

  const getChangeData = () => {
    if (email !== getValues('email')) {
      return email
    } else {
      return phone
    }
  }
  return (
    <>
      {renderErrorToast}
      {showDeleteMessagesWarning && (
        <Modal
          variant="default"
          title="Update Contact"
          footer
          footerCancel
          action
          modalClassNames="p-[32px]"
          actionClose
          btnActionText="Confirm"
          btnAction={handleDeleteMessages}
          openOnLoad
        >
          <UpdateContactModalContent
            changeType={getChangeType()}
            data={getChangeData()}
            contactName={username}
          />
        </Modal>
      )}
      {showDeleteMessagesWarningAndMergeContact && (
        <Modal
          variant="default"
          title="Update Contact"
          footer
          footerCancel
          action
          modalClassNames="p-[32px]"
          actionClose
          btnActionText="Confirm"
          btnAction={handleDeleteMessagesAndMergeContacts}
          openOnLoad
        >
          <UpdateContactModalContent
            changeType={getChangeType()}
            data={getChangeData()}
            contactName={username}
          />
        </Modal>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`${
            setContactInfoView ? 'w-[310px]' : 'w-[275px] mx-auto'
          } mt-6 h-[92px] items-center justify-between  flex flex-col rounded-[5px] font-normal`}
        >
          <Input
            register={register}
            name="firstName"
            placeholder="First Name"
            setContactInfoView={setContactInfoView}
          />
          <hr
            className={`${
              setContactInfoView ? 'w-[278px]' : 'w-[245px]'
            } border-box border border-thryv-cloud`}
          />
          <Input
            register={register} //don't need a last name
            name="lastName"
            placeholder="Last Name"
            setContactInfoView={setContactInfoView}
          />
        </div>
        {errors.firstName || errors.lastName ? (
          <div className={`${ERROR_MSG_STYLES} pl-[14px] pt-[4px] text-[10px]`}>
            {errors.firstName?.message || errors.lastName?.message}
          </div>
        ) : null}
        <div className="flex flex-col justify-start">
          <div className="mt-6 flex flex-row items-center font-normal">
            <Text className="w-[55px]" variant="reg" color="thryv-steel">
              Mobile
            </Text>
            <ChevronRightIcon width="5" />
            <StyledPhoneInput
              className={`'w-[235px] focus:outline-none font-['Open_Sans'] text-sm text-thryv-steel !focus:border-b-0`}
              phoneNumber={trimPhone(body?.phone_numbers[0]?.number || phone)}
              errors={errors}
              control={control}
              pattern={maskPattern}
              name="phone"
              title={inputInfo.phone}
              register={register}
            />
          </div>
          <hr
            className={`${
              setContactInfoView ? 'w-[310px]' : 'w-[275px] mx-auto'
            } mt-[10px]  border-box border border-thryv-cloud`}
          />
          {errors.phone ? (
            <div className={ERROR_MSG_STYLES}>{phoneErrorMessage}</div>
          ) : null}
          <div className="mt-6 flex flex-row items-center font-normal">
            <Text className="w-[55px]" variant="reg" color="thryv-steel">
              Email
            </Text>
            <ChevronRightIcon />
            <Input
              name="email"
              register={register}
              placeholder="Email"
              title={inputInfo.email}
              setContactInfoView={setContactInfoView}
              // disabled={!enableEmailInput}
            />
          </div>
          <hr
            className={`${
              setContactInfoView ? 'w-[310px]' : 'w-[275px] mx-auto'
            } mt-[10px]  border-box border border-thryv-cloud`}
          />
          {errors.email ? (
            <div className={ERROR_MSG_STYLES}>{errors.email?.message}</div>
          ) : null}
        </div>
        <div
          className={`${
            setContactInfoView || 'mr-3'
          } mt-7 flex flex-row justify-end`}
        >
          {loading || isDeletingMessage ? <LoadingSpinner /> : null}
          {!loading && !isDeletingMessage ? (
            <Button variant="text" level={1} type="submit" disabled={disableButton}>
              Save Changes
            </Button>
          ) : null}
        </div>
      </form>
      {updateContactFailed && (
        <div className={ERROR_MSG_STYLES}>Contact cannot be updated</div>
      )}
    </>
  )
}

export default EditContactForm
