import { useRef } from 'react'
import { withModal } from '@thryvlabs/maverick'
import ResetPasswordModalComponent from '../../../../../../../../../../components/common/reset-password-modal'

/*
###########################
##     EXAMPLE USAGE     ##
###########################

  ----- Needed in code in order to toggle reset password Modal -----

  <ResetPasswordModal />

  Use the above modal in your code
*/

export const useResetPasswordModal = () => {
  const toggleResetPasswordModalRef = useRef(null)

  const HiddenButton = ({ showModal }) => {
    toggleResetPasswordModalRef.current = showModal
    return <div className="hidden">Hidden Reset Password Modal Button</div>
  }

  const ResetPasswordModal = withModal(HiddenButton, ResetPasswordModalComponent)

  return {
    ResetPasswordModal: ResetPasswordModal,
    toggleResetPasswordModalRef: toggleResetPasswordModalRef,
  }
}
