import { TransitionContainer } from '../../../../common/transition-container'
import { UserCard } from './user-card'

export const UsersDropdown = ({
  className,
  users,
  show,
  setSelectedUser,
  searchTerm,
}) => {
  return (
    <>
      {users.length > 0 && show ? (
        <div className={className}>
          <TransitionContainer show={show} className="p-2 w-[500px]">
            <ul>
              {users.map((user, i) => {
                const { email, name, phoneNumber, imgUrl, isThryvSupport } = user

                return (
                  <li
                    key={i}
                    onMouseDown={() => {
                      setSelectedUser(user)
                    }}
                  >
                    <UserCard
                      isThryvSupport={isThryvSupport}
                      imageUrl={imgUrl || ''}
                      name={name}
                      email={email}
                      phoneNumber={phoneNumber}
                      searchTerm={searchTerm}
                    />
                  </li>
                )
              })}
            </ul>
          </TransitionContainer>
        </div>
      ) : null}
    </>
  )
}
