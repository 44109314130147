import { Button, Header, RegularX } from '@thryvlabs/maverick'
import { HeaderContainer } from './ModalHeader.style'

const ModalHeader = ({ close }) => (
  <HeaderContainer>
    <Header variant="h4" className="text-[22px]" fontWeight="semibold">
      Pricing & Plans
    </Header>
    <Button variant="text" level={2}>
      <RegularX
        type="regular"
        variant="x"
        height="18"
        fill="#A3A5A7"
        onClick={() => close()}
      />
    </Button>
  </HeaderContainer>
)

export default ModalHeader
