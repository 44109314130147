import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { store } from '@redux-toolkit/store'
import { Auth0ProviderWithRedirectCallback } from './auth0-provider-with-redirect-callback'

const { VITE_AUTH0_AUDIENCE, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_DOMAIN } = import.meta
  .env

function AppProviders({ children }) {
  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        audience={VITE_AUTH0_AUDIENCE}
        clientId={VITE_AUTH0_CLIENT_ID}
        domain={VITE_AUTH0_DOMAIN}
      >
        <Provider store={store}>
          <HelmetProvider>{children}</HelmetProvider>
        </Provider>
      </Auth0ProviderWithRedirectCallback>
    </Router>
  )
}

export { AppProviders }
