import { useState } from 'react'
import { Icon } from '@thryvlabs/maverick'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserUnlock, faTowerBroadcast } from '@fortawesome/pro-regular-svg-icons'
import { useDispatch } from 'react-redux'
import {
  setChanelInfo,
  setModalContent,
  setShowModal,
} from '../slices/connectChannelsSlice'
import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../../../graphql/queries'
import { useAuth0 } from '@auth0/auth0-react'

const FirstChannelAdded = ({ handleCloseModal }) => {
  const [hover1, setHover1] = useState(false)
  const [hover2, setHover2] = useState(false)
  const [hover3, setHover3] = useState(false)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { user } = useAuth0()

  const [getChannelsByUser, { called, data }] = useLazyQuery(
    GET_CONNECTED_CHANNELS_BY_USER,
    {
      onCompleted: () => {},
    },
  )
  useEffect(() => {
    if (!called) {
      getChannelsByUser({
        variables: {
          loc_id: user.businessId,
          user_id: user.cc_uid,
        },
      })
    } else {
      const activeChannels =
        data?.queryConnectedChannelsByUser?.filter(
          (channel) => channel.Status === '1' && channel.Provider !== 'CC',
        ) || []
      dispatch(setChanelInfo(activeChannels))
    }
  }, [called, data])

  return (
    <div className="flex flex-col pt-[16px]">
      <button className="focus:outline-none">
        <div
          className="min-w-[250px] py-3 px-4 rounded-md mb-3 flex flex-row border-[1px] border-[#E3E6E8] hover:border-[1px] hover:border-[#5378FC] justify-between"
          style={{ height: '56px', backgroundColor: '#F8F9FB' }}
          onMouseEnter={() => setHover1(true)}
          onMouseLeave={() => setHover1(false)}
          onClick={() => {
            navigate('/inbox')
            handleCloseModal()
          }}
        >
          <div className="flex flex-row justify-between items-center w-full ">
            <div className="flex flex-row">
              <div className="pr-[10px]">
                <Icon
                  type="regular"
                  variant="envelope"
                  width="24"
                  height="18"
                  color="#5378FC"
                />
              </div>
              <p className="text-sm">Go to your inbox</p>
            </div>
            <Icon
              type="regular"
              variant="chevronRight"
              width="8.7"
              height="16.84"
              color={hover1 ? '#5378FC' : '#CCCCCC'}
            />
          </div>
        </div>
      </button>
      <button className="focus:outline-none">
        <div
          className="min-w-[250px] py-3 px-4 rounded-md mb-3 flex flex-row border-[1px] border-[#E3E6E8] hover:border-[1px] hover:border-[#5378FC] justify-between"
          style={{ height: '56px', backgroundColor: '#F8F9FB' }}
          onMouseEnter={() => setHover2(true)}
          onMouseLeave={() => setHover2(false)}
          onClick={() => {
            dispatch(setModalContent(2))
            dispatch(setShowModal(true))
            handleCloseModal()
          }}
        >
          <div className="flex flex-row justify-between items-center w-full ">
            <div className="flex flex-row">
              <div className="pr-[10px]">
                <FontAwesomeIcon
                  className="flex"
                  size="lg"
                  icon={faUserUnlock}
                  color="#5378FC"
                />
              </div>
              <p className="text-sm">Grant access to your channel</p>
            </div>
            <Icon
              type="regular"
              variant="chevronRight"
              width="8.7"
              height="16.84"
              color={hover2 ? '#5378FC' : '#CCCCCC'}
            />
          </div>
        </div>
      </button>
      <button className="focus:outline-none">
        <div
          className="min-w-[250px] py-3 px-4 rounded-md mb-3 flex flex-row border-[1px] border-[#E3E6E8] hover:border-[1px] hover:border-[#5378FC] justify-between"
          style={{ height: '56px', backgroundColor: '#F8F9FB' }}
          onMouseEnter={() => setHover3(true)}
          onMouseLeave={() => setHover3(false)}
          onClick={() => {
            dispatch(setShowModal(true))
            dispatch(setModalContent(1))
            handleCloseModal()
          }}
        >
          <div className="flex flex-row justify-between items-center w-full ">
            <div className="flex flex-row">
              <div className="pr-[10px]">
                <FontAwesomeIcon
                  className="flex"
                  size="lg"
                  icon={faTowerBroadcast}
                  color="#5378FC"
                />
              </div>
              <p className="text-sm">Connect more channels</p>
            </div>
            <Icon
              type="regular"
              variant="chevronRight"
              width="8.7"
              height="16.84"
              color={hover3 ? '#5378FC' : '#CCCCCC'}
            />
          </div>
        </div>
      </button>
    </div>
  )
}

export default FirstChannelAdded
