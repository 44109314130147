import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../graphql'
import { useAuth0 } from '@auth0/auth0-react'

export const useActiveChannels = () => {
  const { user } = useAuth0()

  const { data: channelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: user.businessId, user_id: user.cc_uid },
  })

  const connectedChannels = channelsData
    ? channelsData.queryConnectedChannelsByUser?.filter((channel) => {
        const { Provider, Status, AccessType } = channel
        const isCcChannel = Provider === 'CC'
        const isActiveChannel = Status === '1'
        const userCanAccess = AccessType !== 0

        return !isCcChannel && isActiveChannel && userCanAccess
      })
    : []

  return connectedChannels
}
