import { createSlice } from '@reduxjs/toolkit'

export const countryCodeSlice = createSlice({
  name: 'countryCode',
  initialState: {
    country: '',
    countryCode: '',
    countryIso2: '',
  },
  reducers: {
    setCountry(state, action) {
      state.country = action.payload
    },

    setCountryCode(state, action) {
      state.countryCode = action.payload
    },

    setCountryIso2(state, action) {
      state.countryIso2 = action.payload
    },
  },
})

export const { setCountry, setCountryCode, setCountryIso2 } =
  countryCodeSlice.actions

export default countryCodeSlice.reducer
