import { useEffect, useState } from 'react'

let staffSelectedSort = JSON.parse(localStorage.getItem('staff-selected-sort'))
if (!staffSelectedSort) {
  staffSelectedSort = {
    name: 'name',
    type: 'asc',
  }

  localStorage.setItem('staff-selected-sort', JSON.stringify(staffSelectedSort))
}

export const useStaffSort = (defaultStaff) => {
  const [staff, setStaff] = useState([])
  const [selectedSort, setSelectedSort] = useState(staffSelectedSort)

  const updateSelectedSort = (name, type) => {
    localStorage.setItem('staff-selected-sort', JSON.stringify({ name, type }))
    setSelectedSort({ name, type })
  }

  const sortStaff = {
    byLastName: {
      asc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.LastName < b.LastName) return -1
          if (a.LastName > b.LastName) return 1
          return 0
        })

        setStaff(staffCopy)
      },

      desc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.LastName > b.LastName) return -1
          if (a.LastName < b.LastName) return 1
          return 0
        })

        setStaff(staffCopy)
      },
    },

    byFirstName: {
      asc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.FirstName < b.FirstName) return -1
          if (a.FirstName > b.FirstName) return 1
          return 0
        })

        setStaff(staffCopy)
      },

      desc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.FirstName > b.FirstName) return -1
          if (a.FirstName < b.FirstName) return 1
          return 0
        })

        setStaff(staffCopy)
      },
    },

    byChannels: {
      asc: () => {
        const staffCopy = defaultStaff.slice()
        staffCopy.sort(function (a, b) {
          if (a.permittedChannels.length < b.permittedChannels.length) return -1
          if (a.permittedChannels.length > b.permittedChannels.length) return 1
          return 0
        })
        setStaff(staffCopy)
      },

      desc: () => {
        const staffCopy = defaultStaff.slice()
        staffCopy.sort(function (a, b) {
          if (a.permittedChannels.length > b.permittedChannels.length) return -1
          if (a.permittedChannels.length < b.permittedChannels.length) return 1
          return 0
        })
        setStaff(staffCopy)
      },
    },

    byEmail: {
      asc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.Email < b.Email) return -1
          if (a.Email > b.Email) return 1
          return 0
        })

        setStaff(staffCopy)
      },

      desc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.Email > b.Email) return -1
          if (a.Email < b.Email) return 1
          return 0
        })

        setStaff(staffCopy)
      },
    },

    // FIXME: Test to make sure this sorts integer values or strings with integers
    byPhone: {
      asc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.Phone < b.Phone) return -1
          if (a.Phone > b.Phone) return 1
          return 0
        })

        setStaff(staffCopy)
      },

      desc: () => {
        const staffCopy = defaultStaff.slice()

        staffCopy.sort(function (a, b) {
          if (a.Phone > b.Phone) return -1
          if (a.Phone < b.Phone) return 1
          return 0
        })

        setStaff(staffCopy)
      },
    },

    // FIXME: Remove values: 6, 5, test. Once they are out of the database.
    // 6, 5, and test are only in here because they're in the database as roles
    byRole: {
      // Owner: 3 | Admin: 1 | Staff: 2
      ownerFirst: () => {
        const staffCopy = defaultStaff.slice()
        const order = ['3', '1', '2', '6', '5', '4']
        staffCopy.sort((a, b) => order.indexOf(a?.Role) - order.indexOf(b?.Role))
        setStaff(staffCopy)
      },

      // Admin: 1 | Staff: 2 | Owner: 3
      adminFirst: () => {
        const staffCopy = defaultStaff.slice()
        const order = ['1', '2', '3', '6', '5', '4']
        staffCopy.sort((a, b) => order.indexOf(a?.Role) - order.indexOf(b?.Role))
        setStaff(staffCopy)
      },

      //  Staff: 2 | Owner: 3  | Admin: 1
      staffFirst: () => {
        const staffCopy = defaultStaff.slice()
        const order = ['2', '3', '1', '6', '5', '4']
        staffCopy.sort((a, b) => order.indexOf(a?.Role) - order.indexOf(b?.Role))
        setStaff(staffCopy)
      },
    },

    // FIXME: Remove all values that are not 3 and 1 once they are removed from the db
    // FIXME: Test after things status is toggable
    byStatus: {
      // Active: 1 | Inactive: 3
      activeFirst: () => {
        const staffCopy = defaultStaff.slice()
        const order = ['1', '3', '6', '5', '4', '2', 'test2', 'test', '']
        staffCopy.sort((a, b) => order.indexOf(a.Status) - order.indexOf(b.Status))
        setStaff(staffCopy)
      },

      // Inactive: 3 | Active: 1
      inactiveFirst: () => {
        const staffCopy = defaultStaff.slice()
        const order = ['3', '6', '5', '4', '2', 'test2', 'test', '', '1']
        staffCopy.sort((a, b) => order.indexOf(a.Status) - order.indexOf(b.Status))
        setStaff(staffCopy)
      },
    },
  }

  const toggleSort = (name) => {
    switch (name) {
      case 'name':
        if (selectedSort.name !== 'name') {
          updateSelectedSort('name', 'asc')
          sortStaff.byFirstName.asc()
        } else if (selectedSort.type === 'asc') {
          updateSelectedSort('name', 'desc')
          sortStaff.byFirstName.desc()
        } else if (selectedSort.type === 'desc') {
          updateSelectedSort('name', 'asc')
          sortStaff.byFirstName.asc()
        }
        break

      case 'channels':
        if (selectedSort.name !== 'channels') {
          updateSelectedSort('channels', 'asc')
          sortStaff.byChannels.asc()
        } else if (selectedSort.type === 'asc') {
          updateSelectedSort('channels', 'desc')
          sortStaff.byChannels.desc()
        } else if (selectedSort.type === 'desc') {
          updateSelectedSort('channels', 'asc')
          sortStaff.byChannels.asc()
        }
        break

      case 'email':
        if (selectedSort.name !== 'email') {
          updateSelectedSort('email', 'asc')
          sortStaff.byEmail.asc()
        } else if (selectedSort.type === 'asc') {
          updateSelectedSort('email', 'desc')
          sortStaff.byEmail.desc()
        } else if (selectedSort.type === 'desc') {
          updateSelectedSort('email', 'asc')
          sortStaff.byEmail.asc()
        }
        break

      case 'phone':
        if (selectedSort.name !== 'phone') {
          updateSelectedSort('phone', 'asc')
          sortStaff.byPhone.asc()
        } else if (selectedSort.type === 'asc') {
          updateSelectedSort('phone', 'desc')
          sortStaff.byPhone.desc()
        } else if (selectedSort.type === 'desc') {
          updateSelectedSort('phone', 'asc')
          sortStaff.byPhone.asc()
        }
        break

      case 'role':
        if (selectedSort.name !== 'role') {
          updateSelectedSort('role', 'owner-first')
          sortStaff.byRole.ownerFirst()
        } else if (selectedSort.type === 'owner-first') {
          updateSelectedSort('role', 'admin-first')
          sortStaff.byRole.adminFirst()
        } else if (selectedSort.type === 'admin-first') {
          updateSelectedSort('role', 'staff-first')
          sortStaff.byRole.staffFirst()
        } else if (selectedSort.type === 'staff-first') {
          updateSelectedSort('role', 'owner-first')
          sortStaff.byRole.ownerFirst()
        }
        break

      case 'status':
        if (selectedSort.name !== 'status') {
          updateSelectedSort('status', 'active-first')
          sortStaff.byStatus.activeFirst()
        } else if (selectedSort.type === 'active-first') {
          updateSelectedSort('status', 'inactive-first')
          sortStaff.byStatus.inactiveFirst()
        } else if (selectedSort.type === 'inactive-first') {
          updateSelectedSort('status', 'active-first')
          sortStaff.byStatus.activeFirst()
        }
        break
    }
  }

  const initializeSort = (name) => {
    switch (name) {
      case 'name':
        if (selectedSort.type === 'asc') sortStaff.byFirstName.asc()
        if (selectedSort.type === 'desc') sortStaff.byFirstName.desc()
        break

      case 'channels':
        if (selectedSort.type === 'asc') sortStaff.byChannels.asc()
        if (selectedSort.type === 'desc') sortStaff.byChannels.desc()
        break

      case 'email':
        if (selectedSort.type === 'asc') sortStaff.byEmail.asc()
        if (selectedSort.type === 'desc') sortStaff.byEmail.desc()
        break

      case 'phone':
        if (selectedSort.type === 'asc') sortStaff.byPhone.asc()
        if (selectedSort.type === 'desc') sortStaff.byPhone.desc()
        break

      case 'role':
        if (selectedSort.type === 'owner-first') sortStaff.byRole.ownerFirst()
        if (selectedSort.type === 'admin-first') sortStaff.byRole.adminFirst()
        if (selectedSort.type === 'staff-first') sortStaff.byRole.staffFirst()
        break
      case 'status':
        if (selectedSort.type === 'active-first') sortStaff.byStatus.activeFirst()
        if (selectedSort.type === 'inactive-first')
          sortStaff.byStatus.inactiveFirst()

        break
    }
  }

  useEffect(() => {
    initializeSort(staffSelectedSort.name)
  }, [defaultStaff])

  return { staff, toggleSort, selectedSort }
}
