import { Transition } from '@headlessui/react'
import { useEffect, useState } from 'react'

export const AnimatedNotificationContainer = ({ show, children }) => {
  const [showNotif, setShowNotif] = useState(false)

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowNotif(true)
      }, 10)
    } else {
      setShowNotif(false)
    }
  }, [show])

  return (
    <Transition
      show={showNotif}
      className="rounded-[10px]"
      enter="transition ease-in duration-300 relative"
      enterFrom="transform opacity-0"
      enterTo="transform opacity-100"
      leave="transition ease-out duration-200"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
      unmount={false}
      style={{
        transformOrigin: 'center',
      }}
    >
      {children}
    </Transition>
  )
}
