import { CommandCenterIcon } from '../command-center-icon'
import getChannelIconProps from './utils/get-channel-icon-prop'

const ChannelsIcon = ({ width, height, variant, ...rest }) => {
  const { variant: iconVariant, type: iconType } = getChannelIconProps(variant)
  return (
    <CommandCenterIcon
      variant={iconVariant}
      type={iconType}
      height={height}
      width={width}
      {...rest}
      data-testid="channelsIcon"
    />
  )
}

export default ChannelsIcon
