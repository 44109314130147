import { gql } from '@apollo/client'

export const CREATE_LABEL_OPTIONS_BY_LOCATION = gql`
  mutation create_label_options_by_location_mutation(
    $location_id: String!
    $label_name: String!
    $status: String!
  ) {
    createLabelOptionForLocation(
      input: { location_id: $location_id, label_name: $label_name, status: $status }
    ) {
      LabelID
      LabelName
      LocationID
    }
  }
`
export const UPDATE_LABEL_OPTIONS_BY_LOCATION = gql`
  mutation update_label_options_by_location_mutation(
    $label_id: ID!
    $label_name: String!
  ) {
    updateLabelOptionForLocation(
      input: { label_name: $label_name, label_id: $label_id }
    )
  }
`

export const DELETE_LABEL_OPTIONS_BY_LOCATION = gql`
  mutation delete_label_options_by_location_mutation($label_id: ID!) {
    deleteLabelOptionForLocation(input: { label_id: $label_id })
  }
`
