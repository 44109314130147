import styled, { keyframes, css } from 'styled-components'

const animationCalculation = ({ animationCalculation }) => {
  const animation = keyframes`
      from { 
        transform: translateY(${animationCalculation}); 
        pointer-events: none;
      }
      to { transform: translateY(${'0%'}); }
    `

  return css`
    ${animation} .3s;
  `
}

export const AnimatedDiv = styled.div`
  animation: ${(props) => animationCalculation(props)};
`
