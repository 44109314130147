import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useClickOutside } from '../../../../hooks/use-click-outside'
import { NavTab } from './nav-tab'

export const NavigationMobile = ({ linksArray }) => {
  const menuRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const { pathname } = useLocation()
  const currentPath = linksArray.find((link) => link.path === pathname)
  const activeStyle =
    'rounded-md w-[80vw] mx-3 text-left text-black px-2 bg-thryv-cloud'

  useClickOutside(() => {
    setShowMenu(false)
  }, menuRef)

  return (
    <>
      <div
        className="flex justify-between px-3 items-center text-thryv-night min-w-md:hidden h-[56px] w-full bg-barely-gray"
        onClick={() => {
          setShowMenu(!showMenu)
        }}
      >
        <p>{currentPath?.title}</p>
        <FontAwesomeIcon
          className="fill-thryv-night text-thryv-night p-[10px]"
          size="lg"
          icon={faCaretDown}
        />
      </div>
      {showMenu && (
        <div
          ref={menuRef}
          className="min-w-md:hidden absolute z-50 bg-white text-thryv-steel font-semibold w-[90vw] my-[6px]"
        >
          <div className="flex flex-col shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-[4px] py-[5px] ml-[6px]">
            {linksArray.map((link) => {
              return (
                <NavTab
                  key={link.title}
                  path={link.path}
                  title={link.title}
                  active={pathname === link.path}
                  activeStyle={activeStyle}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
