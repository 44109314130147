import { useState, useRef, useEffect } from 'react'
import DOMPurify from 'dompurify'
import { ActionButtonsMenu } from './action-buttons-menu'
import formatContactData from '../../../hooks/formatContactData'

// Components
import { ConversationHeader } from './conversation-header'
import { ParagraphText } from '@thryvlabs/maverick'
import { ConversationRowWrapper } from './conversation-row-wrapper'
import { ConversationRowProfileAvatar } from './profile-avatar/conversation-row-profile-avatar'
import { PinIcon } from './components/pin-icon'
import { Checkbox } from '@thryvlabs/maverick'
import { useSelector, useDispatch } from 'react-redux'
import {
  setBulkSelectedThreadIds,
  setBulkSelectAllBtnChecked,
  setBulkSelectedThreadSk1s,
} from '../../../slices/inboxThreadsSlice'

export const ConversationRow = ({
  className,
  message,
  user,
  isViewed,
  isPinned,
  isSelected,
  hasDraft,
  folderName,
  threadId,
  threadCreatedTime,
  email,
  threadName,
  startTime,
  endTime,
  time,
  onClick,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { text, timestamp } = message
  const [givenName, setGivenName] = useState('')
  const [surname, setSurname] = useState('')
  const {
    bulkEditBtnClicked,
    bulkSelectedThreadIds,
    inboxThreadIds,
    inboxPinnedThreadIds,
  } = useSelector((state) => state.inboxThreads)

  useEffect(() => {
    const fullName = threadName?.split(' ')
    if (fullName?.length < 2) {
      setGivenName(fullName?.[0]?.toUpperCase())
    } else {
      setGivenName(fullName?.[0]?.toUpperCase())
      setSurname(fullName?.[1]?.toUpperCase())
    }
  }, [threadName])

  const conversationRef = useRef(null)
  const [rowInUse, setRowInUse] = useState(false)
  const [showHoverButtons, setShowHoverButtons] = useState(false)

  const updateShowHoverButtons = () => {
    if (!rowInUse) setShowHoverButtons(false)
  }
  const hasHTML = text.startsWith('<')
  const isThryvSupport =
    message?.title?.toLowerCase() === 'support@thryv.com' ||
    message?.title?.toLowerCase() === 'support'

  const renderMessageDescriptionOnSuccess = threadName?.length ? (
    <div className="grow max-w-full w-0 h-[30px] flex items-center">
      {!hasHTML ? (
        <ParagraphText className="truncate" variant="reg" color="thryv-steel">
          {text}
        </ParagraphText>
      ) : (
        <ParagraphText
          className="truncate"
          variant="reg"
          color="thryv-steel"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      )}
    </div>
  ) : null

  useEffect(() => {
    if (bulkEditBtnClicked === false) {
      dispatch(setBulkSelectAllBtnChecked(false))
    }
  }, [bulkEditBtnClicked])

  const handleChange = () => {
    dispatch(setBulkSelectedThreadIds(threadId))
    dispatch(setBulkSelectedThreadSk1s({ id: threadId, sk1: email.sk1 }))
  }

  useEffect(() => {
    if (
      bulkSelectedThreadIds.length <
      inboxThreadIds.length + inboxPinnedThreadIds.length
    ) {
      dispatch(setBulkSelectAllBtnChecked(false))
    } else {
      dispatch(setBulkSelectAllBtnChecked(true))
    }
  }, [bulkSelectedThreadIds])

  return (
    <ConversationRowWrapper
      className={className}
      id={`conversation-row-${threadId}`}
      variant={
        (!isPinned && threadName && isSelected && 'selected') ||
        (isPinned && threadName && 'pinned')
      }
      onMouseEnter={() => setShowHoverButtons(true)}
      onMouseLeave={updateShowHoverButtons}
      ref={conversationRef}
      onClick={onClick}
      {...rest}
    >
      <td className="flex items-center justify-center gap-3">
        {bulkEditBtnClicked ? (
          <span data-testid="bulk-action-checkbox">
            <Checkbox
              hasLabel
              name="Thread Checkbox"
              isChecked={bulkSelectedThreadIds.includes(threadId)}
              onChange={handleChange}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </span>
        ) : null}

        <ConversationRowProfileAvatar
          variant={
            (isThryvSupport && 'thryv-support') || (user && 'user-with-image')
          }
          user={user}
          firstName={givenName}
          lastName={surname}
          isViewed={isViewed}
        />
      </td>

      <div className="w-full ml-2">
        {threadName?.length ? (
          <ConversationHeader
            title={
              threadName?.toLowerCase() === 'support@thryv.com' ||
              threadName?.toLowerCase() === 'support'
                ? 'Thryv Support Center'
                : !/\d{10}/.test(threadName)
                  ? threadName
                  : formatContactData(threadName)
            }
            timestamp={timestamp}
            isViewed={isViewed}
            variant={hasDraft && 'draft'}
          />
        ) : null}

        <div className="flex">
          {renderMessageDescriptionOnSuccess}

          {isPinned && !showHoverButtons && threadName && <PinIcon />}

          {threadName && (
            <ActionButtonsMenu
              show={showHoverButtons}
              email={email}
              folderName={folderName}
              isPinned={isPinned}
              onMenuToggle={(menuIsOpen) => setRowInUse(menuIsOpen)}
              hideHoverButtons={() => setShowHoverButtons(false)}
              threadId={threadId}
              threadCreatedTime={threadCreatedTime}
              startTime={startTime}
              endTime={endTime}
              time={time}
              isViewed={isViewed}
            />
          )}
        </div>
      </div>
    </ConversationRowWrapper>
  )
}
