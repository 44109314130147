import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setModalContent,
  setShowModal,
  setDisableBackdropClick,
} from '../common/modals/slices/connectChannelsSlice'

// Components
import PaymentFailModal from '../common/modals/payment-fail-modal/PaymentFailModal'
import ManageChannelButton from './ManageChannelButton'
import { TooltipWithCenteredArrow } from '../common/tooltip-with-centered-arrow'
import ManageChannelsModal from '../common/modals/manage-channels-modal/ManageChannelsModal'
import { withModal, Button } from '@thryvlabs/maverick'
import { useAuth0 } from '@auth0/auth0-react'
import formatContactData from '../inbox/hooks/formatContactData'

const ManageChannelButtonWithModal = withModal(
  ManageChannelButton,
  ManageChannelsModal,
)

const AddChannelPlusButton = ({ onClick }) => (
  <TooltipWithCenteredArrow
    variant="top"
    title="Add channel"
    disableVisibleOnClick={true}
    width="max-content"
  >
    <ManageChannelButton variant="secondary" buttonType="outlined" onClick={onClick}>
      +
    </ManageChannelButton>
  </TooltipWithCenteredArrow>
)

const UpgradePlusButton = ({ onClick }) => (
  <TooltipWithCenteredArrow
    variant="top"
    title="Add channel"
    disableVisibleOnClick={true}
    width="max-content"
  >
    <ManageChannelButton variant="secondary" buttonType="outlined" onClick={onClick}>
      +
    </ManageChannelButton>
  </TooltipWithCenteredArrow>
)

const UpgradeButton = ({ onClick }) => (
  <Button
    data-testid="upgrade-button"
    className="h-11 w-[127px] rounded-[22px] border-2 text-[15px] bg-white"
    variant="primary"
    buttonType="outlined"
    onClick={onClick}
  >
    UPGRADE
  </Button>
)

const ChannelActivationBar = () => {
  const [failModal, setFailModal] = useState(false)
  const { channelInfo } = useSelector((state) => state.connectChannels)
  const { maxChannelsAllowed, maxChannelsAllowedLoading } = useSelector(
    (state) => state.connectChannels,
  )
  const { role } = useSelector((state) => state.user)
  const TOTAL_ACTIVE_CHANNELS = channelInfo ? channelInfo?.length : 0
  const dispatch = useDispatch()

  const { user } = useAuth0()
  const userRole = role || user.cc_role

  const openUpgradePlanModal = () => {
    dispatch(setModalContent(4))
    dispatch(setDisableBackdropClick(true))
    dispatch(setShowModal(true))
  }

  const openManageModal = () => {
    dispatch(setModalContent(2))
    dispatch(setShowModal(true))
  }

  const openConnectModal = () => {
    dispatch(setModalContent(1))
    dispatch(setShowModal(true))
  }

  const plusButtonsToShow =
    maxChannelsAllowed === 'Unlimited'
      ? 1
      : maxChannelsAllowed - TOTAL_ACTIVE_CHANNELS

  const PlusIcons =
    plusButtonsToShow >= 0 ? Array.from(Array(plusButtonsToShow).keys()) : []

  return (
    <>
      <div className="flex h-[70px] w-full relative bg-[#f8f9fb]">
        <div className="w-full flex gap-1 items-center flex-col justify-center">
          {maxChannelsAllowedLoading || maxChannelsAllowed === 'Unlimited' ? (
            <p className="font-open-sans text-[14px] font-semibold leading-[18px]">
              My Channels
            </p>
          ) : (
            <p className="font-open-sans text-[14px] font-semibold leading-[18px]">{`My Channels (${TOTAL_ACTIVE_CHANNELS}/${maxChannelsAllowed})`}</p>
          )}

          <div className="flex space-x-[15px]">
            {channelInfo?.map((channel, index) => (
              <TooltipWithCenteredArrow
                key={index}
                variant="top"
                title={
                  channel?.Nickname
                    ? channel.Nickname.replace(/''/g, "'")
                    : channel.Provider === 'video'
                      ? 'Video Channel'
                      : formatContactData(channel.AccountUserValue)
                }
                className="z-10"
                disableVisibleOnClick={true}
                width="max-content"
              >
                <ManageChannelButtonWithModal
                  parentProps={{
                    onClick: () => openManageModal(),
                    iconName: channel.Provider,
                    iconType: 'solid',
                    channel: channel,
                    progress: channel.syncState,
                    showSpinner:
                      channel.syncState !== '0' && channel.VendorID === '1',
                  }}
                  maxNumOfChannels={maxChannelsAllowed}
                />
              </TooltipWithCenteredArrow>
            ))}

            {PlusIcons &&
              PlusIcons.length > 0 &&
              PlusIcons.map((number, i) => {
                return <AddChannelPlusButton key={i} onClick={openConnectModal} />
              })}

            {PlusIcons.length === 0 &&
              parseInt(maxChannelsAllowed) === TOTAL_ACTIVE_CHANNELS && (
                <UpgradePlusButton onClick={openUpgradePlanModal} />
              )}
          </div>
        </div>
        <div className="flex items-center absolute h-[70px] right-[30px]">
          {userRole !== 2 && <UpgradeButton onClick={openUpgradePlanModal} />}
        </div>
        <PaymentFailModal showModal={failModal} setShowModal={setFailModal} />
      </div>
    </>
  )
}

export default ChannelActivationBar
