import { forwardRef, useEffect, useState } from 'react'
import { CommandCenterAvatar } from '../../../../../components/common/command-center-avatar/command-center-avatar'
import { Input, MaskedInput } from '@thryvlabs/maverick'
import { Controller } from 'react-hook-form'
import { useCountryData } from '../../../../../hooks/use-country-data'
// import CustomModal from '../../../../../components/common/CustomModal/Modal'
// import { AddImage } from '../../AddImage'

const DISABLED_OPACITY = 'opacity-50'

// THE ADD AVATAR MODAL IS WITHIN THIS COMPONENT
export const FormAvatarInput = ({ firstName, lastName, disabled, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [imageUrl, setImageUrl] = useState('')

  return (
    <div className="sm:w-full w-[9.19%]">
      <button
        className={`transition-all duration-300 ${
          disabled && `${DISABLED_OPACITY} pointer-events-none`
        } cursor-pointer`}
        aria-label="Click to upload new profile image"
        onClick={() => setIsOpen(!isOpen)}
      >
        <CommandCenterAvatar
          variant="name"
          size={isMobile ? 'large' : 'default'}
          widthAndHeight={isMobile ? 80 : 48}
          firstName={firstName}
          lastName={lastName}
        />
      </button>

      {/* <div className="sm:col-span-8 col-span-1">
                  {!imageUrl && (
                    <div>
                      {!showAddAvatar ? (
                        <Avatar
                          onMouseOver={() => {
                            if (isEdit) {
                              setShowAddAvatar(true)
                            }
                          }}
                          className={'sm:ml-[2%]'}
                          variant="name"
                          size="default"
                          name={{
                            firstName: user.name.split(' ')[0],
                            lastName: user.name.split(' ')[1],
                          }}
                        />
                      ) : (
                        <Avatar
                          onMouseOut={() => setShowAddAvatar(false)}
                          className={'sm:ml-[2%] ml-[15%] bg-[#606377]'}
                          variant="icon"
                          size="default"
                        >
                          <FontAwesomeIcon
                            onMouseOut={() => setShowAddAvatar(false)}
                            onClick={() => setIsOpen(true)}
                            className="text-[2rem] text-[#ffffff]"
                            icon={faCamera}
                            size={'lg'}
                          />
                        </Avatar>
                      )}
                    </div>
                  )}

                  {imageUrl && (
                    <div>
                      {!showAddAvatar ? (
                        <Avatar
                          onMouseOver={() => {
                            if (isEdit) {
                              setShowAddAvatar(true)
                            }
                          }}
                          className={'sm:ml-[2%] ml-[15%] overflow-hidden'}
                          variant="icon"
                          size="default"
                        >
                          <img src={imageUrl} />
                        </Avatar>
                      ) : (
                        <Avatar
                          onMouseOut={() => setShowAddAvatar(false)}
                          className={'sm:ml-[2%] ml-[15%] bg-[#606377]'}
                          variant="icon"
                          size="default"
                        >
                          <FontAwesomeIcon
                            onMouseOut={() => setShowAddAvatar(false)}
                            onClick={() => setIsOpen(true)}
                            className="text-[2rem] text-[#ffffff]"
                            icon={faCamera}
                            size={'lg'}
                          />
                        </Avatar>
                      )}
                    </div>
                  )}
                </div> */}

      {/* <div className="sm:col-span-8 col-span-1">
                  <div className="relative sm:mt-5 w-full mt-[14px] ml-[30px] sm:ml-0">
                    <Avatar
                      onMouseOver={() => {
                        if (isEdit) {
                          setShowAddAvatar(true)
                        }
                      }}
                      className={'sm:ml-[2%] sm:hidden pr-1]'}
                      variant="name"
                      size="default"
                      name={{
                        firstName: personalInfo.fname,
                        lastName: personalInfo.lname,
                      }}
                    />
                  </div>
                </div> */}

      {/* ADD AVATAR MODAL */}
      {/* <CustomModal
        isModalForm={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={() => setIsOpen(false)}
        width={'60%'}
      >
        <AddImage setImageUrl={setImageUrl} handleClose={() => setIsOpen(false)} />
      </CustomModal> */}
    </div>
  )
}

export const FormEmailInput = ({ control, disabled }) => {
  return (
    <div className="sm:w-full transition-all duration-300 pt-[14px] w-[57.05%]">
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Input
            disabled={disabled}
            className="w-full"
            type="email"
            placeholder="Email"
            name="email"
            id="email"
            {...field}
            withLabel
            labelType="static"
            variant="default"
          />
        )}
      />
    </div>
  )
}

export const FormPhoneNumberInput = ({
  control,
  errors,
  disabled,
  getValues,
  phoneNumber,
}) => {
  const countryData = useCountryData()
  const [maskPattern, setMaskPattern] = useState('')

  const setMask = (countryData) => {
    switch (countryData.countryISO) {
      case 'US':
        return setMaskPattern('(###) ###-####')
      case 'CA':
        return setMaskPattern('(###) ###-####')
      case 'AU':
        if (
          getValues('phoneNumber')[1] === '4' ||
          phoneNumber[1] === '4' ||
          getValues('phoneNumber')[1] === '5' ||
          phoneNumber[1] === '5'
          // checks if mobile numbber and formats accordingly
        ) {
          return setMaskPattern('#### ### ###')
        } else {
          return setMaskPattern('(##) #### ####')
        }

      // case 'NZ':
      //   return setMaskPattern('(##) ###-####')
    }
    return '(###) ###-####'
  }

  useEffect(() => {
    setMask(countryData)
  }, [countryData])

  const MaskedInputWithRef = forwardRef(({ ...rest }, ref) => {
    return (
      <MaskedInput
        ref={ref}
        disabled={disabled}
        className="w-full"
        type="tel"
        mask={maskPattern}
        placeholder="Phone Number"
        name="phoneNumber"
        id="phoneNumber"
        withLabel
        labelType="static"
        variant="default"
        errors={errors}
        {...rest}
      />
    )
  })

  return (
    <>
      {/* Padding Added to include label height in component */}
      <div className="pt-[14px] w-[22.05%] sm:w-full transition-all duration-300">
        {countryData.countryISO === 'NZ' ? (
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { ...rest } }) => (
              <Input
                disabled={disabled}
                className="w-full transition-all duration-300"
                type="tel"
                placeholder="Phone Number"
                id="phoneNumber"
                customPlaceholder="Phone Number"
                {...rest}
                withLabel
                labelType="static"
                variant="default"
                errors={errors}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { ...rest } }) => <MaskedInputWithRef {...rest} />}
          />
        )}
      </div>
    </>
  )
}

export const FormFirstNameInput = ({ control, disabled, register, errors }) => {
  return (
    <>
      {/* Padding Added to include label height in component */}
      <div className="pt-[14px] sm:h-auto h-[40px] transition-all duration-300 w-[28.73%] sm:w-full">
        <Controller
          control={control}
          rules={{ required: 'First Name is required' }}
          name="fname"
          render={({ field }) => (
            <Input
              disabled={disabled}
              className="w-full"
              type="text"
              placeholder="First Name"
              {...field}
              name="fname"
              id="fname"
              withLabel
              labelType="static"
              variant="default"
              register={register}
              errors={errors}
            />
          )}
        />
      </div>
    </>
  )
}

export const FormLastNameInput = ({ control, disabled, register, errors }) => {
  return (
    <>
      {/* Padding Added to include label height in component */}
      <div className="pt-[14px] sm:h-auto h-[40px] transition-all duration-300 w-[28.73%] sm:w-full">
        <Controller
          control={control}
          rules={{ required: 'Last Name is required' }}
          name="lname"
          render={({ field }) => (
            <Input
              disabled={disabled}
              className="w-full"
              type="text"
              placeholder="Last Name"
              {...field}
              name="lname"
              id="lname"
              withLabel
              labelType="static"
              variant="default"
              register={register}
              errors={errors}
            />
          )}
        />
      </div>
    </>
  )
}

export const FormRoleInput = ({ control, disabled }) => {
  return (
    <>
      {/* Padding Added to include label height in component */}
      <div className="pt-[14px] transition-all duration-300 w-[19.15%] sm:w-full">
        <Controller
          control={control}
          name="role"
          render={({ field }) => (
            <Input
              disabled={disabled}
              labelType="static"
              className="w-full bg-white"
              type="text"
              placeholder="Role"
              {...field}
              name="role"
              id="role"
              withLabel
              variant="default"
            />
          )}
        />
      </div>
    </>
  )
}

export const FormCountryCodeInput = ({ disabled }) => {
  const countryData = useCountryData()

  return (
    <div className="pt-[14px] w-[14.70%] sm:w-full transition-all duration-300">
      <div className="relative w-full">
        <Input
          type="tel"
          customPlaceholder={countryData.emojiAndCountryCode}
          name="countryCode"
          withLabel
          labelType="static"
          variant="default"
          disabled={disabled}
          className="w-full"
        />
        <label
          htmlFor="countryCode"
          className='pointer-events-none absolute left-0 -top-[16px] capitalize
                font-open-sans text-label-text peer-placeholder-shown:text-thryv-steel peer-focus:text-label-text
                peer-focus:placeholder-transparent peer-focus:placeholder-opacity-0 peer-focus:placeholder-white" text-[#898989]'
        >
          Country Code
        </label>
      </div>
    </div>
  )
}
