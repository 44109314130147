import { Icon, Input, MaskedInput, Select, Tooltip } from '@thryvlabs/maverick'
import { Controller } from 'react-hook-form'
import { getTypeObj } from '../utils/getTypeObj'
import useDetectCountryCode from '../../../../../../hooks/use-detect-country-code'
import { LoadingDots } from '../../../../loading-dots'

export const formNames = [
  'businessEmail',
  'countryCode',
  'phoneNumber',
  'supportType',
  'supportContact',
]

export const getFormNames = (businessType) => {
  if (businessType !== 'SOLE_PROPRIETOR') {
    return [...formNames, 'businessPrivacyPolicy', 'businessTC']
  }

  return formNames
}

const supportTypes = [
  { name: 'Phone', value: 'phone' },
  { name: 'Email', value: 'email' },
  { name: 'URL', value: 'url' },
]

const ContactDetails = ({
  register,
  control,
  errors,
  getValues,
  setValue,
  isMobile,
}) => {
  const busType = getValues('businessType')
  const supType = getValues('supportType')
  const detectedCountry = useDetectCountryCode()

  const toolTipMessage = () => {
    const message = {
      description: (
        <span>
          Please ensure that your business privacy policy URL is updated to
          incorporate{' '}
          <a
            href="https://api.support.vonage.com/hc/en-us/articles/6730614610588#01GVZXF1Z3WQEAFCY18R73127E"
            target="_blank"
            className="text-[#FF5000]"
            rel="noreferrer"
          >
            the following 10 DLC campaign messages
          </a>
          .
        </span>
      ),
    }
    return message
  }

  const toolTipProp = toolTipMessage()

  return (
    <>
      <div className="flex flex-col">
        <div className="mt-[30px]">
          <Input
            type="email"
            withLabel
            name="businessEmail"
            placeholder="Business Email"
            labelType="floating"
            variant="default"
            register={register}
            className="w-full"
            errors={errors}
          />
        </div>
        <div className="flex mt-[30px]">
          <div className="w-[150px] mt-[-7px]">
            {detectedCountry.length > 0 ? (
              <Input
                value={detectedCountry}
                tabindex="-1"
                disabled
                className="w-full h-full pt-[5px]"
                type="tel"
                placeholder="Country Code"
                withLabel
                labelType="floating"
                variant="default"
              />
            ) : (
              <div className="h-[26px] w-[124px]">
                <LoadingDots className={'p-3'} />
              </div>
            )}
          </div>
          <div className="w-full pl-2 ml-4 relative sm:mt-[-4px]">
            <Controller
              control={control}
              name="phoneNumber"
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, ...rest } }) => (
                <MaskedInput
                  type="tel"
                  withLabel
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Phone Number"
                  labelType="floating"
                  variant="default"
                  mask="(###) ###-####"
                  className="w-full"
                  errors={errors}
                  {...rest}
                />
              )}
            />
          </div>
        </div>
        <div className="flex mt-[30px] sm:flex-col">
          <div className="w-[150px] mt-[-7px] sm:w-full">
            <Controller
              control={control}
              name="supportType"
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, onChange, ...rest } }) => (
                <Select
                  className="[&>div>div:last-child>ul]:h-auto w-[110px] sm:w-full"
                  options={supportTypes}
                  selectLabel="Support Type"
                  withLabel
                  selectedOption={getTypeObj(getValues('supportType'), supportTypes)}
                  onChange={(option) => setValue('supportType', option.value)}
                  {...rest}
                />
              )}
            />
          </div>
          <div className="w-full pl-2 ml-4 relative sm:pl-0 sm:ml-0 sm:mt-6">
            <Controller
              control={control}
              name="supportContact"
              // eslint-disable-next-line no-unused-vars
              render={({ field: { ref, ...rest } }) => (
                <>
                  {supType === 'phone' ? (
                    <MaskedInput
                      type="tel"
                      withLabel
                      name="supportContact"
                      id="supportContact"
                      placeholder="Support Contact"
                      labelType="floating"
                      variant="default"
                      mask="(###) ###-####"
                      className="w-full"
                      errors={errors}
                      {...rest}
                    />
                  ) : (
                    <Input
                      type="text"
                      withLabel
                      name="supportContact"
                      placeholder="Support Contact"
                      labelType="floating"
                      variant="default"
                      register={register}
                      className="w-full"
                      errors={errors}
                      {...rest}
                    />
                  )}
                </>
              )}
            />
          </div>
        </div>
        {busType !== 'SOLE_PROPRIETOR' && (
          <>
            <div className="flex w-full mt-[30px]">
              <div className="grow">
                <Input
                  type="url"
                  withLabel
                  name="businessPrivacyPolicy"
                  placeholder="Business Privacy Policy URL"
                  labelType="floating"
                  variant="default"
                  register={register}
                  className="w-full"
                  errors={errors}
                />
              </div>
              <div className="mt-1">
                <Tooltip
                  variant={isMobile ? 'left' : 'top'}
                  positioning={
                    isMobile
                      ? { offsetX: '15px', offsetY: '-8px' }
                      : { offsetY: '-12px' }
                  }
                  {...toolTipProp}
                >
                  <button type="button">
                    <Icon
                      type="regular"
                      variant="infoCircle"
                      height="18"
                      fill="#057AFF"
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="flex w-full mt-[32px]">
              <div className="grow">
                <Input
                  type="url"
                  withLabel
                  name="businessTC"
                  placeholder="Business Terms & Conditions URL"
                  labelType="floating"
                  variant="default"
                  register={register}
                  className="w-full"
                  errors={errors}
                />
              </div>
              <div className="mt-1">
                <Tooltip
                  variant={isMobile ? 'left' : 'top'}
                  positioning={
                    isMobile
                      ? { offsetX: '15px', offsetY: '-8px' }
                      : { offsetY: '-12px' }
                  }
                  {...toolTipProp}
                >
                  <button type="button">
                    <Icon
                      type="regular"
                      variant="infoCircle"
                      height="18"
                      fill="#057AFF"
                    />
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ContactDetails
