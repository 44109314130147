import placeholderImg from '../../../../../../assets/placeholder-profile-image.svg'
import { Avatar } from '@thryvlabs/maverick'
import avatarColorPicker from '../../../../../../utils/avatarColorPicker'
import { ThryvGuyIcon } from '../../../../../command-center-icon/icons'

export const ProfileImage = ({
  className,
  src,
  alt,
  variant = '',
  isThryvSupport = false,
}) => {
  const Border = ({ isHidden }) => (
    <div
      className={`absolute h-12 w-12 rounded-full border-thryv-orange-500 border-[1.8px] border-solid ${
        isHidden && 'hidden'
      }`}
    ></div>
  )

  const RoundedImage = () => {
    return (
      <div className="h-10 w-10 rounded-full overflow-hidden">
        <img
          className="w-full h-full object-cover"
          src={src || placeholderImg}
          alt={alt || ''}
        />
      </div>
    )
  }

  const ThryvSupportImage = () => {
    return (
      <Avatar variant="icon" style={{ height: 40, width: 40 }}>
        <ThryvGuyIcon fill="white" stroke="#ECEEF1" />
      </Avatar>
    )
  }

  return (
    <div className={`relative flex justify-center items-center ${className || ''}`}>
      <Border isHidden={variant !== 'active' && true} />
      {isThryvSupport ? <ThryvSupportImage /> : <RoundedImage />}
    </div>
  )
}

export const PlaceholderImage = ({
  className,
  variant = '',
  firstName,
  lastName,
}) => {
  const Border = ({ isHidden }) => (
    <div
      className={`absolute h-12 w-12 rounded-full border-thryv-orange-500 border-[1.8px] border-solid ${
        isHidden && 'hidden'
      }`}
    ></div>
  )

  const RoundedImage = ({ firstName, lastName }) => {
    if (!firstName) {
      return (
        <div className="h-10 w-10 rounded-full overflow-hidden">
          <Avatar variant="unidentified" className="w-full h-full object-cover" />
        </div>
      )
    }

    return (
      <div className="h-10 w-10 rounded-full overflow-hidden">
        <Avatar
          variant="name"
          name={{
            firstName: firstName,
            lastName: lastName,
          }}
          backgroundColor={avatarColorPicker(lastName?.at(0))}
          staticBackground
          className="w-full h-full object-cover"
        />
      </div>
    )
  }

  return (
    <div className={`relative flex justify-center items-center ${className || ''}`}>
      <Border isHidden={variant !== 'active' && true} />
      <RoundedImage firstName={firstName} lastName={lastName} />
    </div>
  )
}
