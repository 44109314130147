import './reset-password-sent-modal.css'

// Components
import { Icon, ParagraphText } from '@thryvlabs/maverick'

const CloseModalButton = ({ onClick, height, width }) => (
  <button onClick={onClick}>
    <Icon
      variant="x"
      type="regular"
      width={width || '12'}
      height={height || '12'}
      color="#A3A5A7"
    />
  </button>
)

export const ResetPasswordSentModal = ({ close, firstName }) => {
  return (
    <div className="reset-pass-sent-modal text-start">
      <div className="flex justify-between items-center mb-4">
        <h4 className="font-semibold font-[Montserrat] text-[16px] leading-[24px] text-thryv-black-500">
          Reset Password
        </h4>
        <CloseModalButton onClick={close} />
      </div>

      <ParagraphText variant="reg" className="mb-10">
        We’ve sent an email to {firstName} to reset their password.
      </ParagraphText>
    </div>
  )
}
