import { createSlice } from '@reduxjs/toolkit'

export const businessInfoSlice = createSlice({
  name: 'businessInfo',
  initialState: {
    logo: '',
    location: {
      name: '',
      category: '',
      address: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      countryCode: '',
      phone: '',
      email: '',
      website: '',
    },
  },
  reducers: {
    setBusinessName(state, action) {
      state.businessName = action.payload
    },
    setBusinessLocation(state, action) {
      state.location = { ...state.location, ...action.payload }
    },
    setBusinessLogoPreview(state, action) {
      state.logo = action.payload
    },
  },
})

export const {
  setBusinessName,
  setBusinessLocation,
  setBusinessHasLocation,
  setBusinessHasName,
  setBusinessLogoPreview,
} = businessInfoSlice.actions

export default businessInfoSlice.reducer
