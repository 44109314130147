import queryString from 'query-string'
const { VITE_AZURE_CLIENT_ID, VITE_AZURE_AUTH_REDIRECT_URL } = import.meta.env

export const useOffice365Auth = () => {
  const stringifiedPayload = queryString.stringify({
    client_id: VITE_AZURE_CLIENT_ID,
    redirect_uri: VITE_AZURE_AUTH_REDIRECT_URL,
    scope: [
      'email',
      'openid',
      'profile',
      'User.Read',
      'offline_access',
      'EAS.AccessAsUser.All',
      'EWS.AccessAsUser.All',
    ].join(' '),
    response_type: 'code',
    prompt: 'login',
  })

  const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${stringifiedPayload}`

  return authUrl
}
