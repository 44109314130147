import { gql } from '@apollo/client'

export const GET_PERSONAL_INFO = gql`
  query get_personal_info($CC_UserID: String!) {
    staff: queryStaff(input: { CC_UserID: { eq: $CC_UserID } }) {
      CC_UserID
      CCID
      FirstName
      LastName
      Phone
      CountryCode
      Email
      Role
    }
  }
`
