import { convertRoleIntegerToText } from '../../../../../../../../utils/convert-role-integer-to-text'

const createUpdatedListItem = (
  key,
  className,
  businessId,
  commandCenterId,
  member,
  masterUser,
  connectedChannels,
) => {
  const channelProviders = member.permittedChannels ? member.permittedChannels : []

  const memberPermittedChannelProviders = channelProviders
    .map((channel) => {
      const channelId = channel.ChannelID
      const matchingChannelProvider = []
      connectedChannels?.forEach((connectedChannel) => {
        if (connectedChannel.ChannelID === channelId) {
          matchingChannelProvider.push(connectedChannel.Provider)
        }
      })
      return matchingChannelProvider
    })
    .flat()

  const listItem = {
    key,
    className,
    commandCenterId,
    user: {
      id: member.CC_UserID,
      locationId: businessId,
      firstName: member.FirstName,
      lastName: member.LastName,
      email: member.Email,
      mobile_number: member.Phone,
      role: convertRoleIntegerToText(member?.Role),
    },
    activeChannels: memberPermittedChannelProviders,
    statusIsActive: member.Status === '1' && true,
    masterUser: masterUser,
  }

  return listItem
}

export const createStaffListForPagination = ({
  className,
  businessId,
  commandCenterId,
  staffList,
  masterUser,
  connectedChannels,
}) => {
  const staffListForPagination = staffList.map((member, i) => {
    const staffMemberWithNewProps = createUpdatedListItem(
      i,
      className,
      businessId,
      commandCenterId,
      member,
      masterUser,
      connectedChannels,
    )
    return staffMemberWithNewProps
  })

  return staffListForPagination
}
