const getSocialIconProps = (variant) => {
  switch (variant) {
    case 'gmail':
      return { variant: 'gmail', type: 'solid', width: '16', height: '16' }
    case 'outlook':
      return { variant: 'outlook', type: 'solid', width: '20', height: '18' }
    case 'office365':
      return { variant: 'outlook', type: 'solid', width: '20', height: '18' }
    case 'instagram':
      return { variant: 'instagram', type: 'solid', width: '18', height: '18' }
    case 'yahoo':
      return { variant: 'yahoo', type: 'solid', width: '16', height: '16' }
    case 'facebook':
      return {
        variant: 'facebookMessenger',
        type: 'solid',
        width: '18',
        height: '18',
      }
    default:
      return {
        variant: variant,
        type: 'solid',
        width: '18',
        height: '18',
      }
  }
}

export default getSocialIconProps
