
const Marketplace = () => {
  const { VITE_ENV } = import.meta.env
  const marketplaceUrl = VITE_ENV === "prod" ? "https://marketplace.thryv.com/" : "https://appmarket.sandbox.thryv.com/";
  
  return (
    <div>
      <iframe style={{height: "calc(100vh - 66.18px"}} width="100%" src={marketplaceUrl} />
    </div>
  )
}

export default Marketplace
