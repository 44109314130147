import { forwardRef } from 'react'
import { Transition } from '@headlessui/react'

export const TransitionContainer = forwardRef(
  (
    {
      className,
      children,
      show,
      positioning,
      width,
      transformOrigin,
      style,
      as,
      unmount,
      ...rest
    },
    ref,
  ) => {
    return (
      <Transition
        className={`${className} !z-10 bg-[white] rounded shadow-thryv focus:outline-none`}
        show={show}
        as={as || 'div'}
        enter="transition ease-in duration-300 relative z-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-out duration-200"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        ref={ref}
        style={{
          width: width ? width : '100%',
          position: positioning || 'absolute',
          transformOrigin: transformOrigin || 'top left',
          ...style,
        }}
        unmount={typeof unmount === 'boolean' ? unmount : true}
        {...rest}
      >
        {children}
      </Transition>
    )
  },
)
