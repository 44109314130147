import { gql } from '@apollo/client'

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation create_notification_mutation(
    $PK1: ID!
    $SK1: Long!
    $agent_id: String!
    $REMINDSK: Long!
  ) {
    setReminder(pk1: $PK1, sk1: $SK1, agent_id: $agent_id, remindsk: $REMINDSK) {
      pk1
      sk1
      remind_agent
      remindsk
    }
  }
`

export const DELETE_NOTIFICATION_MUTATION = gql`
  mutation delete_notification_mutation($PK1: ID!, $SK1: Long!) {
    deleteReminder(pk1: $PK1, sk1: $SK1) {
      pk1
      sk1
      remind_agent
      remindsk
    }
  }
`
