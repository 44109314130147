// import { useEffect, useState } from 'react'
// import { Modal } from '@thryvlabs/maverick'
// import UpdateContactModalContent from '../update-contact-modal-content'
import RecentCalls from '../../../calls/contacts/recentcall-section/RecentCalls'
import { RemoveContactDetail } from '../../../command-center-icon/icons'
import formatContactData from '../../../inbox/hooks/formatContactData'
const TYPE_STYLES = `font-open-sans font-normal text-sm text-[#808080] mt-1.5`
const SMALL_HEADER_STYLES = `font-semibold text-sm text-[#231F20] font-montserrat`

const RightPanelUserProfile = ({
  contact,
  clearPhone,
  clearEmail,
  handleEditEmail,
  handleEditPhone,
}) => {
  const { phone, email } = contact

  const recentCallData = {
    threadpk: contact.threadpk,
  }
  const phoneNumber = formatContactData(phone) || ''
  const renderDetailsEmptyState = <p className={TYPE_STYLES}>--</p>

  const renderPhoneForContact = phoneNumber?.length ? (
    <p className={TYPE_STYLES}>{phoneNumber}</p>
  ) : (
    renderDetailsEmptyState
  )

  const renderEmailForContact = email?.length ? (
    <p className={`${TYPE_STYLES} mt-1 truncate`}>{email}</p>
  ) : (
    renderDetailsEmptyState
  )

  // const renderPhoneEditWarningModal =
  //   clearPhone && phoneNumber?.length ? (
  //     <Modal
  //       variant="default"
  //       title="Update Contact"
  //       footer
  //       footerCancel
  //       action
  //       modalClassNames="p-[32px]"
  //       btnActionText="Confirm"
  //       btnAction={handleEditPhone}
  //       altBtn={<RemoveContactDetail />}
  //     >
  //       <UpdateContactModalContent
  //         changeType="phone"
  //         data={phone}
  //         contactName={username}
  //       />
  //     </Modal>
  //   ) : null

  // const renderEmailEditWarningModal = showDeleteMessagesWarning ? (
  //   <Modal
  //     variant="default"
  //     title="Update Contact"
  //     footer
  //     footerCancel
  //     action
  //     modalClassNames="p-[32px]"
  //     actionClose
  //     btnActionText="Confirm"
  //     btnAction={handleEditEmail}
  //     altBtn={<RemoveContactDetail />}
  //   >
  //     <UpdateContactModalContent
  //       changeType="email"
  //       data={email}
  //       contactName={username}
  //     />
  //   </Modal>
  // ) : null

  const renderEmailEditButton =
    // clearEmail && !email?.length ? (
    clearEmail ? (
      <button onClick={handleEditEmail} data-testid="edit-email-btn">
        <RemoveContactDetail />
      </button>
    ) : null

  const renderPhoneEditButton =
    // clearPhone && !phoneNumber?.length ? (
    clearPhone ? (
      <button onClick={handleEditPhone} data-testid="edit-phone-btn">
        <RemoveContactDetail />
      </button>
    ) : null

  const renderContactDetails = contact ? (
    <div
      className={`grid grid-flow-row gap-8 w-full border-b-[1px] border-[#E3E6E8] pt-1`}
    >
      <div className="w-full">
        <p className={SMALL_HEADER_STYLES}>Phone</p>
        <div className="w-full flex justify-between items-center">
          <span>{renderPhoneForContact}</span>
          {renderPhoneEditButton}
        </div>
      </div>
      <div className="w-full pb-6 truncate">
        <p className={SMALL_HEADER_STYLES}>Email</p>
        <div className="w-full flex justify-between items-center">
          <span>{renderEmailForContact}</span>
          {renderEmailEditButton}
        </div>
      </div>
    </div>
  ) : null

  const renderRecentCalls = contact ? (
    <div className="mt-[-30px]">
      <RecentCalls selectedContact={recentCallData} />
    </div>
  ) : null

  return (
    <>
      {renderContactDetails}
      {renderRecentCalls}
    </>
  )
}

export default RightPanelUserProfile
