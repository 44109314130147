import './reset-password-modal.css'
import newPasswordValidation from './utils/new-password-validation'
import { useForm } from 'react-hook-form'
import formSchema from './data/formSchema'

// Components
import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'
import { PasswordInput } from './components/password-input'
import { yupResolver } from '@hookform/resolvers/yup'

const CloseModalButton = ({ onClick, height, width }) => (
  <button onClick={onClick}>
    <Icon
      variant="x"
      type="regular"
      width={width || '18'}
      height={height || '18'}
      color="#A3A5A7"
    />
  </button>
)

const ResetPasswordModal = ({ close }) => {
  const formDefaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  const reactHookForm = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(formSchema),
  })
  const { register, formState, handleSubmit, watch } = reactHookForm
  const { errors, isSubmitSuccessful } = formState

  // const { getAccessTokenSilently, user } = useAuth0()
  // const [accessToken, setAccessToken] = useState(null)

  const watchNewPassword = watch('newPassword')
  const watchConfirmPassword = watch('confirmPassword')

  const validations = newPasswordValidation(watchNewPassword)

  const passwordRequirements = [
    {
      text: 'Min. 8 characters',
      isValid: validations.isLengthValid,
    },
    {
      text: 'Min. 1 upper-case letter',
      isValid: validations.hasUpperCase,
    },
    {
      text: 'Min. 1 lower-case letter',
      isValid: validations.hasLowerCase,
    },
    {
      text: 'Min. 1 number',
      isValid: validations.hasNumber,
    },
    {
      text: 'A special character',
      isValid: validations.hasSpecialChar,
    },
  ]

  const passwordsAreEqualAndValid = (password1, password2) => {
    if (password1 !== password2) return false

    const validationsAreTrue = () => {
      return Object.values(passwordValidations).every(
        (validation) => validation === true,
      )
    }

    const passwordValidations = newPasswordValidation(watchNewPassword)
    if (!validationsAreTrue()) return false

    return true
  }

  // FIXME: Not sure why this is here, but I'm leaving the code until it's figured out.
  // const getAccessToken = useCallback(async () => {
  //   if (!accessToken) {
  //     const token = await getAccessTokenSilently()
  //     setAccessToken(token)
  //   }
  // }, [accessToken, getAccessTokenSilently])

  // useEffect(() => {
  //   getAccessToken()
  // }, [getAccessToken])

  const submitNewPassword = async () => {
    // FIXME: Show error prompts instead of returning if the password isn't valid?
    // FIXME: Make sure to check that the current password is ALSO valid!!!

    try {
      // const data = await resetPassword(authClaims, pwdGroup)
      // const result = data.data.data.resetPassword.result
      // if (result === 'success') {
      //   console.log('Reset password successfully!')
      //   close()
      // }
    } catch (err) {
      // FIXME: based on error message, see if it's password don't match or server closed
    }
  }

  return (
    <>
      {isSubmitSuccessful ? (
        <>
          {/* #####                     ##### */}
          {/* ##### RESET SUCCESS MODAL ##### */}
          {/* #####                     ##### */}
          <div className="reset-password-success-modal">
            <div className="flex justify-between items-center mb-4">
              <h6 className="font-semibold text-base leading-6 font-[Montserrat]">
                Reset Password
              </h6>
              <CloseModalButton onClick={close} width={12} height={12} />
            </div>

            <ParagraphText
              variant="reg"
              color="thryv-black-500"
              className="text-start"
            >
              Your password has been reset.
            </ParagraphText>
          </div>
        </>
      ) : (
        <>
          {/* #####                  ##### */}
          {/* ##### RESET FORM MODAL ##### */}
          {/* #####                  ##### */}
          <div className="reset-password-modal">
            <div className="flex justify-between mb-[20px]">
              <h4 className="leading-[30px] text-thryv-black-500 text-[22px] font-[Montserrat] font-semibold">
                Reset Password
              </h4>

              <CloseModalButton onClick={close} />
            </div>

            <form onSubmit={handleSubmit(submitNewPassword)}>
              <div className="mb-[70px]">
                {/* Current Password */}
                <PasswordInput
                  variant="default"
                  className="mb-[38px]"
                  labelText="Enter your current password"
                  inputName="currentPassword"
                  register={register}
                  errors={errors}
                />

                {/* New Password */}
                <PasswordInput
                  variant="requirements"
                  className="mb-[38px]"
                  labelText="Enter new password"
                  inputName="newPassword"
                  requirementsList={passwordRequirements}
                  register={register}
                />

                {/* Confirm Password */}
                <PasswordInput
                  labelText="Confirm new password"
                  variant="default"
                  inputName={'confirmPassword'}
                  passwordIsValid={passwordsAreEqualAndValid(
                    watchNewPassword,
                    watchConfirmPassword,
                  )}
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex justify-end">
                <Button variant="primary" type="submit">
                  Reset
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  )
}

export default ResetPasswordModal
