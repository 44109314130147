import { Avatar, ParagraphText } from '@thryvlabs/maverick'
import {
  AvatarPlaceHolder,
  ThryvGuyIcon,
} from '../../../../../command-center-icon/icons'
import avatarColorPicker from '../../../../../../utils/avatarColorPicker'

export const MessageHeader = ({ className, imageUrl, name }) => {
  const nameWithoutExtraSpace = name?.replace(/\s+/g, ' ').trim()
  const cleanName = nameWithoutExtraSpace?.split(' ')
  const nameLength = cleanName?.length

  if (
    name === 'Thryv Support' ||
    name?.toLowerCase() === 'support@thryv.com' ||
    name?.toLowerCase() === 'support'
  ) {
    return (
      <div className={className}>
        <div className="flex gap-2 items-center">
          <Avatar variant="icon" className="!w-6 !h-6">
            <ThryvGuyIcon fill="white" stroke="#ECEEF1" />
          </Avatar>
          <ParagraphText variant="reg" color="#231F20">
            Thryv Support Center
          </ParagraphText>
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <div className="flex gap-2 items-center">
        {name === 'Unknown Contact' ? (
          <AvatarPlaceHolder className="!w-6 !h-6" />
        ) : (
          <Avatar
            variant={imageUrl ? 'image' : 'name'}
            imageUrl={imageUrl}
            className="!w-6 !h-6"
            staticBackground
            backgroundColor={avatarColorPicker(
              nameLength === 3 ? cleanName?.[2]?.[0] : cleanName?.[1]?.[0],
            )}
            name={{
              firstName: cleanName?.[0] || '',
              lastName: nameLength === 3 ? cleanName?.[2] : cleanName?.[1] || '',
            }}
            size="small"
          />
        )}
        <ParagraphText variant="reg" color="#231F20">
          {name}
        </ParagraphText>
      </div>
    </div>
  )
}
