import { forwardRef } from 'react'
import { CommandCenterIcon } from '../../../../../../../command-center-icon'
import { ParagraphText, Icon } from '@thryvlabs/maverick'

/* ----- mav = maverick ----- */
/* ----- cc = command center ---- */
export const MenuButton = forwardRef(
  (
    {
      children,
      mavIconVariant,
      ccIconVariant,
      onClick,
      showArrow,
      noIcon,
      height,
      width,
    },
    buttonRef,
  ) => {
    const ButtonIcon = () => {
      if (mavIconVariant) {
        return (
          <Icon
            variant={mavIconVariant}
            type="regular"
            color="#808080"
            width={width || '14'}
            height={height || '14'}
          />
        )
      }

      if (ccIconVariant) {
        return (
          <CommandCenterIcon
            variant={ccIconVariant}
            color="#808080"
            type="regular"
            width={width || '14'}
            height={height || '14'}
          />
        )
      }
    }

    return (
      <button
        data-testid={children}
        type="button"
        className="flex items-center justify-between p-2 rounded transition-all duration-300 hover:bg-thryv-gray-light-200 w-full"
        onClick={onClick}
        ref={buttonRef}
      >
        <div className="flex items-center gap-2">
          {noIcon ? null : <ButtonIcon />}
          <ParagraphText color="#231F20" variant="reg">
            {children}
          </ParagraphText>
        </div>
        {showArrow && (
          <Icon
            variant="caretRight"
            type="solid"
            height="8"
            width="5"
            color="#4D4D4D"
          />
        )}
      </button>
    )
  },
)
