/* eslint-disable no-useless-escape */
const findYouTubeVideoId = (text) => {
  const urlRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/gi
  const matches = text.match(urlRegex)
  if (matches) {
    const firstMatch = matches[0]
    const videoIdMatch = firstMatch.match(
      /(?:youtube\.com.*(?:\\?|&)v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    )
    return videoIdMatch ? videoIdMatch[1] : null
  }
  return null
}

export default findYouTubeVideoId
