import 'react-quill/dist/quill.snow.css'
import { useSelector, useDispatch, batch } from 'react-redux'
import './quill-styles.css'
import { Quill } from 'react-quill'
import { useEffect } from 'react'
import {
  setCurrHyperlinkInformation,
  setDisplayHyperlinkTooltip,
} from '../../../slices/messageComposeSlice'

export const quillEditorFormats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'align',
  'list',
  'bullet',
  'indent',
  'link',
  'customlink',
]

export const quillEditorModules = {
  toolbar: { container: '#toolbar' },
}

export const QuillToolbar = () => {
  const dispatch = useDispatch()
  let overwriteLink = false
  useEffect(() => {
    if (!overwriteLink) {
      const Inline = Quill.import('blots/inline')
      class CustomLinkBlot extends Inline {
        static create(url) {
          let node = super.create()
          node.setAttribute('href', url)
          node.setAttribute('target', '_blank')
          node.setAttribute('title', node.textContent)
          node.classList.add('inline-hyperlink')
          node.addEventListener('click', () => {
            const currHyperLinkInformation = {
              offSetTop: node.offsetTop,
              offSetLeft: node.offsetLeft,
              text: node.textContent,
              url: node.href,
            }
            batch(() => {
              dispatch(setDisplayHyperlinkTooltip(true))
              dispatch(setCurrHyperlinkInformation(currHyperLinkInformation))
            })
          })
          return node
        }

        static formats(domNode) {
          return domNode.getAttribute('href') || true
        }

        format(name, value) {
          if (name === 'link' && value) {
            this.domNode.setAttribute('href', value)
          } else {
            super.format(name, value)
          }
        }

        formats() {
          let formats = super.formats()
          formats['link'] = CustomLinkBlot.formats(this.domNode)
          return formats
        }
      }
      CustomLinkBlot.blotName = 'customlink'
      CustomLinkBlot.tagName = 'A'

      Quill.register(CustomLinkBlot)
      overwriteLink = true
    }
  }, [])
  const { showFormattingToolbar } = useSelector((s) => s.messageCompose)

  return (
    <>
      <div
        className={
          showFormattingToolbar
            ? 'flex sm:absolute sm:bottom-[70px] sm:right-2 z-50'
            : '!hidden'
        }
      >
        <div id="toolbar">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
          <span className="h-[28px] w-[2px] bg-[#F3F3F3] mx-1" />
          <select className="ql-align">
            <option></option>
            <option value="center"></option>
            <option value="right"></option>
          </select>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
          <span className="h-[28px] w-[2px] bg-[#F3F3F3] mx-1" />
          <button className="ql-clean"></button>
        </div>
      </div>
    </>
  )
}
