import moment from 'moment/moment'

// make the dates look from today up until the end of last month
export const getInboxDatePairs = ({ from, to, selection }) => {
  const date = {
    getYesterday: () => {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
      return [startDate, endDate]
    },

    getLastWeek: () => {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
      return [startDate, endDate]
    },

    getLastMonth: () => {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment().subtract(31, 'days').format('YYYY-MM-DD')
      return [startDate, endDate]
    },

    getLastYear: () => {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment().subtract(365, 'days').format('YYYY-MM-DD')
      return [startDate, endDate]
    },

    getCustom: () => {
      let startDate
      let endDate

      if (from && to) {
        startDate = from.format('YYYY-MM-DD')
        endDate = to.format('YYYY-MM-DD')
      } else {
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
      }

      return [startDate, endDate]
    },
  }

  const getStartAndEndDatePair = (selection) => {
    switch (selection) {
      case 'Any':
        return ['', '']
      case 'Yesterday':
        return date.getYesterday()
      case 'Past week':
        return date.getLastWeek()
      case 'Past month':
        return date.getLastMonth()
      case 'Past year':
        return date.getLastYear()
      case 'Custom':
        return date.getCustom()
      default:
        break
    }
  }

  return getStartAndEndDatePair(selection)
}
