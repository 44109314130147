import { Avatar } from '@thryvlabs/maverick'
import styled from 'styled-components'
import avatarColorPicker from '../../../../utils/avatarColorPicker'
const StyledAvatar = styled(Avatar)`
  height: 40px;
  width: 40px;
`
const CallAvatar = ({ call, firstName, lastName }) => {
  if (call?.image) {
    return <StyledAvatar imageUrl={call?.image} variant="image" size="default" />
  } else if (firstName && isNaN(firstName)) {
    return (
      <StyledAvatar
        variant="name"
        name={{ firstName, lastName }}
        size="default"
        staticBackground
        backgroundColor={avatarColorPicker(lastName[0])}
      />
    )
  } else {
    return <StyledAvatar variant="unidentified" size="default" />
  }
}

export default CallAvatar
