import { useState } from 'react'
import { Button, ParagraphText } from '@thryvlabs/maverick'
import ManageTypeCard from './ManageTypeCard'

const ConnectRequest = ({ setStep, setModalType }) => {
  const [selectedCard, setSelectedCard] = useState()

  const handleModalSelection = () => {
    if (selectedCard === 'Thryv Website') {
      setModalType('Almost Done')
    } else if (selectedCard === 'External Website') {
      setModalType('Almost Done External')
    }
  }

  return (
    <div className="bg-white">
      <ParagraphText
        className="px-[40.5px] text-[16px] text-[#000] font-semibold pt-[50px] pb-[30px] sm:pt-[15px] sm:pb-[20px]"
        variant="reg"
      >
        Please select whether the website is managed by Thryv or Externally
      </ParagraphText>

      <ParagraphText className="w-[85%] m-[auto]" variant="reg">
        Thryv Websites are managed by Thryv, while external websites are not. Please
        choose the right option below.
      </ParagraphText>

      <ManageTypeCard
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        managedBy="Thryv Website"
        description="When you proceed with the Thryv website option, Thryv specialist team will add Web Chat to your website within 24 hours."
        icon="faHandshake"
      />

      <ManageTypeCard
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        managedBy="External Website"
        description="When you proceed with the external website option, we will send you an email within 24 hours with the script to inject into your website. "
        icon="faWindow"
      />

      <ParagraphText
        className="mt-[0.5rem] mb-[1rem] text-[#5378FC] cursor-pointer"
        variant="reg"
        color="#057AFF"
      >
        <a target="_blank" href="https://learn.thryv.com" rel="noreferrer">
          Need Help?
        </a>
      </ParagraphText>

      <span
        className="text-[#808080] text-[13px]  mr-[0.5rem] cursor-pointer"
        onClick={() => {
          setStep(1)
        }}
      >
        BACK
      </span>

      <Button
        onClick={handleModalSelection}
        variant="primary"
        className="h-[32px]  mr-[2%] mb-[1rem]"
        disabled={!selectedCard}
      >
        Confirm
      </Button>
    </div>
  )
}

export default ConnectRequest
