import { Icon } from '@thryvlabs/maverick'
import { scrollToMsgInThread } from '../../../inbox/global-search-section/sections/utils/scrollToMsgInThread'
import { TooltipWithCenteredArrow } from '../../../common/tooltip-with-centered-arrow/tooltip-with-centered-arrow'

// Adds blue circle hover effect to photo icon buttons
const PhotoIconContainer = ({ children }) => {
  return (
    <div className="grid place-content-center transition ease-in-out hover:bg-[#057AFF] rounded-full w-[30px] h-[30px] [&>div]:whitespace-nowrap">
      {children}
    </div>
  )
}

const SharedPhotoButtons = ({
  handleClickImage,
  handlePhotoAttachmentDownload,
  attachment,
  index,
}) => {
  return (
    <>
      <PhotoIconContainer>
        <TooltipWithCenteredArrow variant="top" title="Preview">
          <Icon
            data-testid="view-photo"
            variant="eye"
            type="regular"
            height="18px"
            width="18px"
            color="#FFFFFF"
            className="cursor-pointer"
            onClick={() => handleClickImage(attachment, index)}
          />
        </TooltipWithCenteredArrow>
      </PhotoIconContainer>
      <PhotoIconContainer>
        <TooltipWithCenteredArrow variant="top" title="Go to file">
          <Icon
            data-testid="import-photo"
            variant="fileImport"
            type="regular"
            height="18px"
            width="18px"
            color="#FFFFFF"
            className="cursor-pointer"
            onClick={() => scrollToMsgInThread(attachment.filesk)}
          />
        </TooltipWithCenteredArrow>
      </PhotoIconContainer>
      <PhotoIconContainer>
        <TooltipWithCenteredArrow variant="top" title="Download">
          <Icon
            data-testid="download-photo"
            variant="download"
            type="regular"
            height="18px"
            width="18px"
            color="#FFFFFF"
            className="cursor-pointer"
            onClick={() => {
              handlePhotoAttachmentDownload(attachment, index)
            }}
          />
        </TooltipWithCenteredArrow>
      </PhotoIconContainer>
    </>
  )
}

export default SharedPhotoButtons
