const avatarColorPicker = (letter = "A") => {
	letter = letter.toLocaleLowerCase()
    if (/[a-c]/.test(letter)) {
		// return lavender color for A thru e
		return '#C0B1D2';
	} else if (/[d-f]/.test(letter)) {
		// return orange color for P thru z
		return '#F19B6F';
	} else if (/[g-i]/.test(letter)) {
		// return gray color for F thru o
		return '#B2B9D3';
	} else if (/[j-l]/.test(letter)) {
		// return dark blue color for P thru z
		return '#4761C3';
	} else if (/[m-o]/.test(letter)) {
		// return brown color for P thru z
		return '#C37847';
	} else if (/[p-r]/.test(letter)) {
		// return lime green color for P thru z
		return '#48C246';
	} else if (/[s-u]/.test(letter)) {
		// return peach color for P thru z
		return '#F2C9CE';
	} else if (/[v-x]/.test(letter)) {
		// return  purple color for P thru z
		return '#A846C3';
	} else if (/[y-z]/.test(letter)) {
		// return rust color for P thru z
		return '#C35546';
	} else {
		//return gold color
		return '#C3A947';
	}
}

export default avatarColorPicker