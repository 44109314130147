import { Modal } from '@thryvlabs/maverick'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  setShowFormattingToolbar,
  setCurrHyperlinkInformation,
} from '../../../slices/messageComposeSlice'
import { HyperlinkModalContent } from './modal-content'
import { HyperlinkModalOpenButton } from './hyperlink-modal-openbutton'
import './hyperlink-modal.css'
import {
  getEditorSelection,
  insertHyperlink,
  getLinkFromSelection,
} from '../quill-editor/quill-editor-helpers'

export const HyperlinkModal = ({
  quillEditorRef,
  disableButtons,
  currHyperlinkInformation = '',
}) => {
  const [displayText, setDisplayText] = useState('')
  const [originalTextLength, setOriginalTextLength] = useState(0)
  const [selectionIndex, setSelectionIndex] = useState(null)
  const [link, setLink] = useState('')
  const [isEditingLink, setIsEditingLink] = useState(false)
  const [showLinkError, setShowLinkError] = useState(false)
  const [showPreviewLink, setShowPreviewLink] = useState(false)
  const [finishedTyping, setFinishedTyping] = useState(false)
  const { showFormattingToolbar } = useSelector((s) => s.messageCompose)
  const { editorView } = useSelector((s) => s.messageCompose)

  const dispatch = useDispatch()

  // Show invalid link error if link is bad and user has typed more than two characters
  useEffect(() => {
    if (finishedTyping) {
      if (isValidUrl(link)) {
        setShowPreviewLink(true)
        setShowLinkError(false)
      } else {
        setShowPreviewLink(false)
        if (link.length > 2) {
          setShowLinkError(true)
        } else {
          setShowLinkError(false)
        }
      }
    }
  }, [link, finishedTyping])

  // Check if typing to show link validation error only if user is done typing
  useEffect(() => {
    setFinishedTyping(false)
    const typingTimeout = setTimeout(() => {
      setFinishedTyping(true)
    }, 500)
    return () => clearTimeout(typingTimeout)
  }, [link])

  const handleClick = () => {
    if (showFormattingToolbar) {
      dispatch(setShowFormattingToolbar(false))
    }
    const selection = getEditorSelection(quillEditorRef)
    let linkText
    if (currHyperlinkInformation) {
      // if currHyperlinkInformation contains info, it means we open the modal from the tooltip
      linkText = currHyperlinkInformation.url
    } else {
      // else use quill methods
      const selectionFormat = quillEditorRef.current
        .getEditor()
        .getFormat(quillEditorRef.current.getEditor().getSelection())
      linkText = selectionFormat.link ? selectionFormat.link : undefined
    }
    if (linkText) {
      // EDITING LINK
      setIsEditingLink(true)
      const link = getLinkFromSelection(quillEditorRef, selection, linkText)
      setDisplayText(link?.tagText)
      setLink(linkText)
      setOriginalTextLength(link?.length)
      setSelectionIndex(link?.startIndex)
    } else {
      setIsEditingLink(false)
      setDisplayText(selection.text)
      setOriginalTextLength(selection.text?.length)
      setSelectionIndex(selection.index)
    }
  }

  const handleSubmitHyperlink = () => {
    // Add a default protocol if none is present
    let formattedLink
    if (!/^https?:\/\//i.test(link)) {
      formattedLink = 'http://' + link
    }
    if (currHyperlinkInformation) {
      // if currHyperlinkInformation contains info, it means we open the modal from the tooltip.
      // hence, we need to update the current hyperlink information as well
      dispatch(
        setCurrHyperlinkInformation({
          ...currHyperlinkInformation,
          text: displayText,
          url: formattedLink,
        }),
      )
    }
    insertHyperlink(
      quillEditorRef,
      displayText,
      originalTextLength,
      selectionIndex,
      formattedLink,
    )
    // eslint-disable-next-line no-undef
    if (editorView === 'closed') dispatch(setEditorView('open'))
    setIsEditingLink(false)
    resetModalState()
  }

  const resetModalState = () => {
    setSelectionIndex(null)
    setDisplayText('')
    setOriginalTextLength(0)
    setLink('')
  }

  return (
    <Modal
      modalTestId="insert-link-btn"
      id="hyperlink-modal"
      variant="default"
      title={isEditingLink ? 'Edit Link' : 'Insert Link'}
      footer
      action
      btnActionText="Insert"
      btnAction={() => {
        handleSubmitHyperlink(displayText, originalTextLength, selectionIndex, link)
        resetModalState()
      }}
      btnActionDisabled={
        currHyperlinkInformation
          ? false
          : displayText?.length === 0 || link.length < 2
      }
      disableModal={disableButtons}
      footerCancel
      actionClose
      onClose={() => resetModalState()}
      altBtn={
        <HyperlinkModalOpenButton
          disableButtons={disableButtons}
          handleClick={handleClick}
          fromTooltip={currHyperlinkInformation ? true : false}
        />
      }
    >
      <HyperlinkModalContent
        displayText={displayText}
        setDisplayText={setDisplayText}
        link={link}
        setLink={setLink}
        showPreviewLink={showPreviewLink}
        showLinkError={showLinkError}
      />
    </Modal>
  )
}

function isValidUrl(str) {
  // Add a default protocol if none is present
  if (!/^https?:\/\//i.test(str)) {
    str = 'http://' + str
  }
  let url

  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  // check if the URL has a valid hostname with at least one dot (.)
  const hostnameParts = url.hostname.split('.')
  if (hostnameParts.length < 2 || !hostnameParts[hostnameParts.length - 1]) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}
