import { forwardRef } from 'react'
import { Icon, ParagraphText, Button } from '@thryvlabs/maverick'

const validateType = (variant) => {
  if (
    variant !== 'success' &&
    variant !== 'error' &&
    variant !== 'warning' &&
    variant !== 'reminder'
  ) {
    // eslint-disable-next-line no-console
    console.error(`Type "${variant}" is not valid.`)
  } else return true
}
export const Notification = forwardRef(
  (
    {
      textToRender,
      iconType,
      variant,
      close,
      className,
      styles,
      iconVariant,
      textSize,
      button,
      buttonText,
      buttonOnclick,
    },
    ref,
  ) => {
    // types available = 'success' | 'error' | 'warning'
    validateType(variant)
    let iconProps = {
      variant: '',
      color: '',
    }
    let bannerStyles = ''
    switch (variant) {
      case 'success':
        iconProps.color = '#16A085'
        iconProps.variant = 'circleCheck'
        bannerStyles = 'bg-[#E8F6F3] border-[#8ACFC2]'
        break
      case 'error':
        iconProps.color = 'red'
        iconProps.variant = 'circleX'
        bannerStyles = 'bg-[#FCEAEA] border-[#EF9595]'
        break
      case 'warning':
        iconProps.color = '#FAC02B'
        iconProps.variant = 'triangleExclamation'
        bannerStyles = 'bg-[#FFF9EA] border-[#FDDF95]'
        break
      case 'reminder':
        iconProps.color = '#057AFF'
        iconProps.variant = 'infoCircle'
        bannerStyles = 'bg-[#E6F2FF] border-[#82BDFF]'
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Variant "${variant}" is not valid.`)
    }

    const wrapper = ` ${className} flex flex-row justify-between items-start border rounded ${bannerStyles} p-[8px] text-[14px]`

    return (
      <div className={wrapper} ref={ref} style={styles}>
        <div className="flex gap-2 items-center">
          <span className=" pt-[.16rem]">
            <Icon
              variant={iconVariant ? iconVariant : iconProps.variant}
              type={iconType}
              color={iconProps.color}
              height="16"
            />
          </span>

          <ParagraphText variant={textSize || 'reg'}>{textToRender}</ParagraphText>

          {button && (
            <Button
              variant="primary"
              onClick={buttonOnclick}
              className="h-[30px] ml-2"
            >
              {buttonText}
            </Button>
          )}
        </div>
        {close && (
          <span className="pt-[.3rem]">
            <Icon
              variant="x"
              type="regular"
              height="10"
              color="#808080"
              onClick={close}
              className="cursor-pointer"
            />
          </span>
        )}
      </div>
    )
  },
)
