import moment from 'moment'

export const calculateDifferenceToMilliseconds = (duration) => {
    const localDateTime = moment() // gets the current date and time in the local timezone
    const currentUtcInMilliseconds = localDateTime.utc().valueOf() // converts local date and time to UTC milliseconds

    let millisecondsToAdd
    if (duration === 'in 30 minutes') {
      millisecondsToAdd = 30 * 60 * 1000
    } else if (duration === 'in 1 hour') {
      millisecondsToAdd = 60 * 60 * 1000
    } else if (duration === 'in 3 hours') {
      millisecondsToAdd = 3 * 60 * 60 * 1000
    } else if (duration === 'in 24 hours') {
      millisecondsToAdd = 24 * 60 * 60 * 1000
    } 

    const updatedUtcMilliseconds = currentUtcInMilliseconds + millisecondsToAdd
    const differenceInMilliseconds =
      updatedUtcMilliseconds - currentUtcInMilliseconds
    return differenceInMilliseconds
  }