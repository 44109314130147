import ASCClient, { ApiEndpoint } from '@amityco/js-sdk'

const apiKey = import.meta.env.VITE_TEAMCHAT_AMITY_API_KEY
const amityRegion = ApiEndpoint.US

export const AmityClientService = (function () {
  let instance

  function createInstance() {
    return ASCClient.create({ apiKey, apiEndpoint: amityRegion })
  }

  return {
    getClient: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    },
  }
})()
