import { CircleXGrayBackgroundIcon } from '../../../command-center-icon/icons'
import { Button, Header, ParagraphText, Icon } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { setModalContent } from '../slices/connectChannelsSlice'
const ErrorModal = ({ handleClose }) => {
  const dispatch = useDispatch()
  return (
    <div className="w-[500px] sm:w-[300px] x-sm:w-[280px] max-w-[500px] p-[32px]">
      <div className="w-full flex justify-end">
        <Icon
          type="regular"
          variant="x"
          height="18"
          fill="#A3A5A7"
          className="cursor-pointer"
          onClick={() => handleClose()}
        />
      </div>
      <div className="flex justify-center mb-7">
        <CircleXGrayBackgroundIcon />
      </div>
      <div className="flex justify-center">
        <Header className="mb-7" fontWeight="semibold" variant="h4">
          Something went wrong...
        </Header>
      </div>

      <ParagraphText variant="reg" className="mb-9">
        Your Facebook account connection has failed. Please try again.
      </ParagraphText>
      <div className="flex justify-center">
        <Button
          type="submit"
          className=""
          variant="primary"
          style={{ fontSize: '14px' }}
          onClick={() => dispatch(setModalContent(11))}
        >
          Try Again
        </Button>
      </div>
    </div>
  )
}

export default ErrorModal
