import { createSlice } from '@reduxjs/toolkit'

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptionUuid: '',
  },
  reducers: {
    setSubscriptionUuid(state, action) {
      state.subscriptionUuid = action.payload
    },
  },
})

export const { setSubscriptionUuid } = subscriptionSlice.actions

export default subscriptionSlice.reducer
