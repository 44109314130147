import { Checkbox } from '@thryvlabs/maverick'
import { Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import { faArrowsUpDownLeftRight } from '@fortawesome/pro-light-svg-icons'
import { AnimatedDiv } from './EditMenuItem.styles'
const EditMenuItem = ({
  idx,
  id,
  data,
  title,
  handleChange,
  editedId,
  visible,
  checkedItems,
  shouldAnimate,
  unCheckedItems,
  previousSectionOrder,
  outOfRangeNum,
}) => {
  function calculateSlideDown() {
    if (editedId === id) {
      const orderOfEditedId = previousSectionOrder.indexOf(editedId) + 1
      const unitsToSlideDown = (checkedItems.length + 1 - orderOfEditedId) * -1
      return unitsToSlideDown.toString() + '00%'
    }
  }
  function calculateSlideUp() {
    if (editedId === id) {
      const previousCheckedLength =
        previousSectionOrder.length - unCheckedItems.length - 1
      const unitsToSlideUp = previousSectionOrder
        .slice(previousCheckedLength)
        .indexOf(editedId)
      return unitsToSlideUp.toString() + '00%'
    }
  }

  let animationCalculation = visible ? calculateSlideUp() : calculateSlideDown()

  if (outOfRangeNum > 0 && editedId === id) {
    //If the animationCalculation was the "same" it will now have a decimal,force the animation to be negligibly more so the animation will run
    if (outOfRangeNum % 1 !== 0) {
      animationCalculation = Math.floor(outOfRangeNum).toString() + '00.1%'
    } else {
      animationCalculation = outOfRangeNum.toString() + '00.2%'
    }
  }
  return (
    <Draggable draggableId={id} key={id} index={idx} isDragDisabled={!data.visible}>
      {(provided, snapshot) => {
        let dragging = snapshot.isDragging
        return (
          <AnimatedDiv
            data-testid={`div-${title}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            animationCalculation={animationCalculation}
            className={`  ${editedId === id && shouldAnimate ? 'z-10' : ''} ${
              dragging ? 'bg-[#ECEEF1]' : 'bg-white'
            } flex relative gap-[8px] font-primary font-semibold text-[12px] p-[6px] w-[191px] h-[28px] rounded-[4px] hover:bg-[#ECEEF1]`}
          >
            <Checkbox
              data-testid={`checkbox-${title}`}
              key={idx}
              hasLabel
              name="HTML"
              isChecked={data.visible}
              onChange={() => handleChange(idx)}
            />
            <span>{title === 'Social Content' ? 'Social' : title}</span>
            <div {...provided.dragHandleProps}>
              <FontAwesomeIcon
                icon={
                  snapshot.isDragging ? faArrowsUpDownLeftRight : faGripDotsVertical
                }
                size={'lg'}
                className="h-[18px] absolute left-[170px] group-hover:visible hover:cursor-grab"
              />
            </div>
          </AnimatedDiv>
        )
      }}
    </Draggable>
  )
}

export default EditMenuItem
