import { Input } from '@thryvlabs/maverick'

export const EmailInput = ({ className, register, errors }) => {
  return (
    <div className={`h-[27px] text-start ${className}`}>
      <Input
        register={register}
        errors={errors}
        className="w-full"
        type="email"
        customPlaceholder="Email"
        name="email"
        labelType="static"
        variant="default"
      />
    </div>
  )
}
