import { gql } from '@apollo/client'

/* ----- QUERIES ALL STAFF PRETAINING TO A PARTICULAR COMMAND CENTER ----- */
/* ----- NOTE: Use an empty array in "variables" to query all staff ----- */
export const GET_STAFF = gql`
  query get_staff($input: StaffQuery = {}) {
    staff: queryStaff(input: $input) {
      CC_UserID
      CCID
      FirstName
      LastName
      Phone
      Email
      Role
      Status
      CountryCode
      permittedChannels {
        ChannelID
        AccessType
      }
    }
  }
`

export const HAS_AVAILABLE_SEATS_FOR_STAFF = gql`
  query has_available_seats_for_staff {
    seats: queryHasAvailableSeats {
      hasAvailableSeats
    }
  }
`
