import { Header } from '@thryvlabs/maverick'
import RightPanelSharedFiles from './RightPanelSharedFiles'
import { Oval } from 'react-loader-spinner'
import { getFormattedFileSize } from './utils'

const SharedFilesPreview = ({
  setShowAllFiles,
  loading,
  sortedAttachmentData,
  attachmentFiles,
  handleFileAttachmentDownload,
}) => {
  return (
    <div className="grid grid-flow-row min-h-[100px] md:h-full md:w-[390px]">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Oval color="##FF5000" height={55} width={55} />
        </div>
      ) : (
        <>
          {sortedAttachmentData.length > 0 && (
            <>
              <div className="grid grid-cols-2 gap-4 w-[310px] pb-2">
                <Header fontWeight="semibold" variant="h7">
                  Shared Files
                </Header>
                <button
                  className="ml-[85px] self-start mt-[3px]"
                  onClick={() => setShowAllFiles(true)}
                >
                  <p className="text-[#057AFF] text-sm" fontWeight="semibold">
                    See All
                  </p>
                </button>
              </div>

              <div className="grid grid-flow-row">
                {attachmentFiles.length > 0 ? (
                  attachmentFiles
                    .slice(0, 6) // Displaying first 6 attachments
                    .map((attachment, index) => {
                      const parsedData = JSON.parse(attachment.body)
                      return (
                        <div key={index} className="attachment-container">
                          <RightPanelSharedFiles
                            fileName={parsedData.filename}
                            fileSize={getFormattedFileSize(parsedData.size)}
                            type={parsedData.filename.slice(
                              parsedData.filename?.lastIndexOf('.') + 1,
                            )}
                            fileSk={attachment.filesk}
                            handleFileAttachmentDownload={() =>
                              handleFileAttachmentDownload(attachment)
                            }
                          />
                        </div>
                      )
                    })
                ) : (
                  <p className="self-start font-open-sans font-normal text-sm text-[#808080] -mt-6">
                    Your shared files will appear here.
                  </p>
                )}
              </div>
            </>
          )}
          {sortedAttachmentData.length === 0 && (
            <p className="text-thryv-gray-medium-500 self-start mt-5">
              Your sent and received attachments will appear here.
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default SharedFilesPreview
