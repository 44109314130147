import { useEffect, useState } from 'react'
import UpcomingImprovementsModal from './UpcomingImprovementsModal'
import { useChannelsWithReauthStatus } from '../../../../hooks/use-channels-with-reauth-status'

const useReauthModals = () => {
  const [showUpcomingImprovModal, setShowUpcomingImprovModal] = useState(false)
  const [showPendingReauthModal, setShowPendingReauthModal] = useState(false)

  const reauthChannels = useChannelsWithReauthStatus()

  useEffect(() => {
    if (reauthChannels.length > 0) {
      setShowUpcomingImprovModal(true)
    }
  }, [reauthChannels.length])

  const upcomingImprovementsModal = (
    <UpcomingImprovementsModal
      showUpcomingImprovModal={showUpcomingImprovModal}
      setShowUpcomingImprovModal={setShowUpcomingImprovModal}
      showPendingReauthModal={showPendingReauthModal}
      setShowPendingReauthModal={setShowPendingReauthModal}
    />
  )

  return { upcomingImprovementsModal }
}

export default useReauthModals
