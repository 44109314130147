import moment from 'moment/moment'

export const now = moment.utc(moment()).local().valueOf()

export const midnight = moment
  .utc(moment().startOf('day').valueOf())
  .local()
  .valueOf()

export const yesterdayMidnightTime = moment
  .utc(moment().subtract(1, 'days').startOf('day').valueOf())
  .local()
  .valueOf()

export const startOfLastWeekMidnightTime = moment
  .utc(moment().subtract(1, 'weeks').startOf('day').valueOf())
  .local()
  .valueOf()

export const startOfLastMonthMidnightTime = moment
  .utc(moment().subtract(1, 'months').startOf('day').valueOf())
  .local()
  .valueOf()

export const startOfLastYearMidnightTime = moment
  .utc(moment().subtract(1, 'years').startOf('day').valueOf())
  .local()
  .valueOf()
