import { useDispatch } from 'react-redux'
import { setChanelInfo } from '../../common/modals/slices/connectChannelsSlice'
import usePhoneChannel from '../../../hooks/use-phone-channel'
import { useLazyQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'

const usePhoneChannelInfo = () => {
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const [getChannelsByUser] = useLazyQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
  })

  const [phoneChannel] = usePhoneChannel()

  const initActiveChannels = useCallback(async () => {
    try {
      const newChannelsData = await getChannelsByUser()
      const activeChannels =
        newChannelsData.data?.queryConnectedChannelsByUser?.filter(
          (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
        )

      //  had to add the conditional because it would try to set it before the data comes back, which caused it to make multiple calls to set the redux slice
      if (activeChannels) {
        dispatch(setChanelInfo(activeChannels))
        // selecting the channel is being handled in the channels button now
        // dispatch(setSelectedChannel(activeChannels[0]))
      }
    } catch (err) {
      //
    }
  }, [getChannelsByUser, dispatch, setChanelInfo])

  useEffect(() => {
    if (!phoneChannel) {
      initActiveChannels()
    }
  }, [phoneChannel])
}

export default usePhoneChannelInfo
