import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { ContactInfoView, ContactsListView } from '@contacts'
import ActivationScreen from '../../components/calls/activation-screen/ActivationScreen'
import {
  CallsNavigation,
  CallsNavigationMobile,
} from '@calls/recent-and-voicemail/navigation/CallsNavigation'
import {
  Voicemails,
  Recents,
} from '@calls/recent-and-voicemail/voicemail-recent-container'
import { useRedux } from '@hooks'
import {
  loadAppoloContactsState,
  selectContactsData,
  selectContactOnPageLoad,
} from '@redux-toolkit'
import { FETCH_CONTACT_WITH_PHONE, GET_MESSAGES_BY_PHONE_CHANNEL } from '@graphql'
import './calls.css'
import { useMediaQuery } from 'react-responsive'
import MobileDialpad from '../../components/calls/mobile-dialpad/mobile-dialpad'
import CustomModal from '../../components/common/CustomModal/Modal'
import VideoCallLinkModal from '../../components/calls/video-call-link-modal/VideoCallLinkModal'
import {
  setOpenVideoCallLinkModal,
  setCalls,
  setVoicemails,
} from '../../components/calls/slices/callSlice'
import AddMoreMinutes from '../../components/calls/add-minutes/AddMoreMinutes'
import SuccessModal from '../../components/common/modals/pause-sucess-modal/PauseSuccess'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useConnectPhoneModal } from '../../components/common/modals/phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
import { getThryvSupportNumber } from '../../utils/get-thryv-support-number'

const CALLS_URLS = ['/calls', '/calls/recents', '/calls/voicemail']

const Calls = () => {
  const [dispatch, useSelector] = useRedux()
  const [sortOrder, setSortOrder] = useState('asc')
  const [contactInfoView, setContactInfoView] = useState('default')
  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)
  const {
    contacts: { connection, contactsList, phoneNumber },
  } = useSelector(selectContactsData)
  const { inboxThreadSk1s } = useSelector((state) => state.inboxThreads)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(
    () => {
      if (!CALLS_URLS.includes(location.pathname)) {
        navigate(CALLS_URLS[0])
      }
    },
    CALLS_URLS,
    location.pathname,
    navigate,
  )

  const {
    data: contactsData,
    loading: contactsLoading,
    refetch: refetchContacts,
  } = useQuery(FETCH_CONTACT_WITH_PHONE)

  const [openConnectPhoneModal, , ConnectPhoneModal] = useConnectPhoneModal()
  const { user } = useAuth0()
  const { data: connectedChannelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: user.businessId, user_id: user.cc_uid },
  })

  // eslint-disable-next-line no-unused-vars
  const phoneChannel = connectedChannelsData?.queryConnectedChannelsByUser.find(
    (channel) => channel?.Provider === 'phone',
  )
  // const phoneChId = phoneChannel?.ChannelID
  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      onCompleted: () => {
        dispatch(setCalls(recentCallsData.queryMessagesByPhoneChannel))
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      onCompleted: () => {
        dispatch(setVoicemails(recentVoicemailsData.queryMessagesByPhoneChannel))
      },
    },
  )

  const { mobileKeypadOpen } = useSelector((state) => state.calls)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const openVideoCallLinkModal = useSelector(
    (state) => state.calls.openVideoCallLinkModal,
  )
  const [openAddMoreMinutes, setOpenAddMoreMinutes] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const [{ prepopulatedNumber, prepopulatedNumberClicked }] = useRedux('calls')

  const handleClose = function () {
    setOpenAddMoreMinutes(false)
  }

  useEffect(() => {
    if (!contactsLoading) {
      // FIXME when we need to create a thryv contact in db
      // Hard-coded thryv support contact now

      const contacts = []

      contactsData.queryContactsWithPhoneNumber.map((contact) => {
        if (contact.body.length > 0) {
          const body = JSON.parse(contact?.body)
          const username = body?.given_name + ' ' + body?.surname
          if (
            username.length > 1 &&
            !username.includes('undefined') &&
            !/\d{10}/.test(username) &&
            body?.phone_numbers?.at(0)?.number !== '' &&
            contact.contactsk2 !== 'support@thryv.com'
          ) {
            contacts.push({
              ...contact,
              threadpk: contact.contactsk1,
              username: body?.given_name + ' ' + body?.surname,
              phone: body?.phone_numbers?.at(0)?.number,
              email: body?.emails?.at(0)?.email,
            })
          }
        }
      })
      if (sortOrder === 'asc') {
        contacts.sort((a, b) => a.username.localeCompare(b.username))
      } else if (sortOrder === 'desc') {
        contacts.sort((a, b) => b.username.localeCompare(a.username))
      }

      contacts.unshift({
        username: 'Thryv Support Center',
        phone: getThryvSupportNumber(countryISO),
        email: 'support@thryv.com',
      })

      const loadedState = {
        connection: connection || false,
        contactsList: contacts || [],
        filteredContacts: contacts || [],
        phoneNumber: phoneNumber || '',
      }

      if (prepopulatedNumberClicked) {
        const formattedNumber = prepopulatedNumber.replace(/\D/g, '')
        loadedState.filteredContacts = contacts.filter(
          (x) => x?.contactsk3 === formattedNumber,
        )
      }

      dispatch(loadAppoloContactsState(loadedState))
    }
  }, [contactsLoading, dispatch, contactsData, sortOrder])

  // if user successfully update an contact, refetch contactsData
  useEffect(() => {
    if (!contactsLoading) {
      refetchContacts()
    }
  }, [inboxThreadSk1s, contactsLoading])

  const refetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      fetchPolicy: 'no-cache',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      fetchPolicy: 'no-cache',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  useEffect(() => {
    if (phoneChannelID) {
      refetchVoicemailsAndCalls()
    }
  }, [phoneChannelID])

  useEffect(() => {
    if (contactsList?.length) {
      dispatch(selectContactOnPageLoad())
    }
  }, [contactsList?.length])

  const renderWelcomeScreen = !connection ? (
    <ActivationScreen openConnectPhoneModal={openConnectPhoneModal} />
  ) : null

  const renderContactsListView = !isMobile ? (
    <ContactsListView
      setOpenAddMoreMinutes={setOpenAddMoreMinutes}
      sortedOrder={sortOrder}
      setSortedOrder={setSortOrder}
      contactInfoView={contactInfoView}
      setContactInfoView={setContactInfoView}
    />
  ) : null

  const renderCallsHistory = connection ? (
    <Routes>
      <Route
        path="/"
        element={
          <Recents
            contactInfoView={contactInfoView}
            setContactInfoView={setContactInfoView}
          />
        }
      />
      <Route
        path="/recents"
        element={
          <Recents
            contactInfoView={contactInfoView}
            setContactInfoView={setContactInfoView}
          />
        }
      />
      <Route
        path="/voicemail"
        element={
          <Voicemails
            contactInfoView={contactInfoView}
            setContactInfoView={setContactInfoView}
          />
        }
      />
      <Route
        path="/contacts"
        element={
          isMobile ? (
            <ContactsListView
              contactInfoView={contactInfoView}
              setContactInfoView={setContactInfoView}
            />
          ) : (
            <Navigate replace to={'/calls'} />
          )
        }
      />
    </Routes>
  ) : null

  return (
    <div className="h-full flex md:flex-col items-start justify-start gap-0">
      {mobileKeypadOpen && isMobile ? (
        <MobileDialpad />
      ) : (
        <>
          {connection && <CallsNavigationMobile />}
          <div className="h-full w-[350px] md:hidden relative">
            {renderContactsListView}
          </div>
          <div className="h-full w-full flex m-0 bg-white">
            <div id="contact-calls-and-profile-wrapper">
              {connection && (
                <div id="calls-navigation">
                  <CallsNavigation
                    setOpenAddMoreMinutes={setOpenAddMoreMinutes}
                    connection={connection}
                    isMobile={isMobile}
                  />
                </div>
              )}
              {renderCallsHistory}
              {renderWelcomeScreen}
              <div id="contact-sidesheet" className="relative md:hidden">
                <ContactInfoView
                  connection={connection}
                  refetchContacts={refetchContacts}
                  setContactInfoView={setContactInfoView}
                  contactInfoView={contactInfoView}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <CustomModal
        isModalForm={true}
        isOpen={openVideoCallLinkModal}
        setIsOpen={setOpenVideoCallLinkModal}
        handleClose={() => {
          dispatch(setOpenVideoCallLinkModal(false))
        }}
        width={isMobile ? '325px' : '500px'}
      >
        <VideoCallLinkModal />
      </CustomModal>

      <CustomModal
        isModalForm={true}
        showTitle={true}
        heading="Add More Minutes"
        hasCrossIcon={true}
        isOpen={openAddMoreMinutes}
        setIsOpen={setOpenAddMoreMinutes}
        close={handleClose}
        width={isMobile ? '94%' : '1024px'}
      >
        <AddMoreMinutes
          setOpenAddMoreMinutes={setOpenAddMoreMinutes}
          setOpenSuccessModal={setOpenSuccessModal}
          handleClose={handleClose}
        />
      </CustomModal>

      <CustomModal
        isModalForm={true}
        showTitle={false}
        hasCrossIcon={false}
        isOpen={openSuccessModal}
        setIsOpen={setOpenSuccessModal}
        close={setOpenSuccessModal}
        width={isMobile ? '94%' : '500px'}
      >
        <SuccessModal
          modalType="Success"
          modalHeaderMessage="Congratulations, payment completed"
          modalBodyMessage="Monthly minutes have been added to your Plan. Need more? Visit Settings or upgrade your plan!"
          setOpenSuccessModal={setOpenSuccessModal}
        />
      </CustomModal>

      {ConnectPhoneModal}
    </div>
  )
}

export default Calls
