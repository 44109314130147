import { useDispatch } from 'react-redux'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import { setEditorView } from '../../../slices/messageComposeSlice'

export const ActionButton = ({
  dataTestID,
  title,
  disabled = false,
  isActive = false,
  onBtnClick = () => {},
  btnClassName = '',
  children,
}) => {
  const IconStyles = `w-[28px] h-[28px] rounded-full p-4 flex items-center justify-center bg-none`

  const dispatch = useDispatch()

  return (
    <>
      {disabled ? (
        <button
          data-testid={dataTestID}
          type="button"
          className={`${IconStyles} ${
            isActive
              ? 'bg-thryv-gray-light-200'
              : !disabled
                ? 'hover:bg-thryv-gray-light-200'
                : 'hover:cursor-default'
          } ${disabled && 'opacity-30'} ${btnClassName}`}
          onClick={() => {
            if (disabled) {
              dispatch(setEditorView('open'))
            } else {
              onBtnClick
            }
          }}
        >
          <div>{children}</div>
        </button>
      ) : (
        <TooltipWithCenteredArrow
          variant="top"
          title={title}
          className="!max-w-[500px] !min-w-0"
          positioning={{ offsetX: '0px', offsetY: '3px' }}
          disableVisibleOnClick={true}
          width={'max-content'}
        >
          <button
            data-testid={dataTestID}
            disabled={disabled}
            type="button"
            className={`${IconStyles} ${
              isActive
                ? 'bg-thryv-gray-light-200'
                : !disabled
                  ? 'hover:bg-thryv-gray-light-200'
                  : ''
            } ${disabled && 'opacity-30'} ${btnClassName}`}
            onClick={onBtnClick}
          >
            <div>{children}</div>
          </button>
        </TooltipWithCenteredArrow>
      )}
    </>
  )
}
