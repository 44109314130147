/* eslint-disable no-console */

import { Button, Header, Input, MaskedInput } from '@thryvlabs/maverick'
import { Select } from '@thryvlabs/maverick'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Checkbox } from '@thryvlabs/maverick'
import { useMutation } from '@apollo/client'
import { SET_UPDATE_RECURLY_BILLING_INFO } from '../../../../graphql'
import { getRecurlyErrorMessage } from '../../../../utils/recurly-parse-error'
import {
  getCountryStates,
  getStateObj,
} from '../../../../components/top-navigation-bar/StateProvinceSelectDropdown'
import { useSelector } from 'react-redux'
import { LoadingSpinner } from '../../../../components/common/loading-spinner'
import { useAuth0 } from '@auth0/auth0-react'
import { postalCodeValidator } from '../../../../utils/postalCodeValidator'

const EditPaymentMethod = ({ handleClose, paymentMethod, onComplete }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [
    updateBillingInfo,
    { loading: loadingNewBillingInfo, error: billingInfoError },
  ] = useMutation(SET_UPDATE_RECURLY_BILLING_INFO)
  const [error, setError] = useState(null)
  const countryCode = useSelector((state) => state.countryCode.countryIso2)
  const [states] = useState(
    getCountryStates(useSelector((state) => state.countryCode.countryIso2)),
  )
  const { user } = useAuth0()

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cardNumber: '',
      expiresOn: '',
      cvv: '',
      fname: paymentMethod.BILLING_FIRST_NAME,
      lname: paymentMethod.BILLING_LAST_NAME,
      street1: paymentMethod.BILLING_ADDRESS1,
      street2: paymentMethod.BILLING_ADDRESS2,
      city: paymentMethod.BILLING_CITY,
      state: getStateObj(paymentMethod.BILLING_STATE, getCountryStates(countryCode)),
      postalCode: paymentMethod.BILLING_POSTAL_CODE,
    },
  })

  const billingInfoId = paymentMethod.BILLING_INFO_ID

  const customValidationPincode = (value) => {
    if (postalCodeValidator(countryCode, value) === 'valid') {
      return undefined
    } else {
      return postalCodeValidator(countryCode, value)
    }
  }

  useEffect(() => {
    if (billingInfoError) {
      let billingError
      try {
        billingError = JSON.parse(billingInfoError.message)
      } catch (err) {
        console.error('Error parsing billingInfoError.message', err)
      }
      setError(
        billingError.params?.transactionError?.message ||
          'Error adding payment method',
      )
    }
  }, [billingInfoError])

  useEffect(() => {
    setIsChecked(paymentMethod.PRIMARY_PAYMENT_METHOD)
  }, [billingInfoId])

  const handleOnChange = () => setIsChecked((prev) => !prev)

  const submitEditPaymentMethod = (data) => {
    setLoading(true)
    const client = window.recurly
    client.configure({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
      parent: false,
      cors: true,
    })

    client.token(
      {
        first_name: data.fname,
        last_name: data.lname,
        number: data.cardNumber.trim(),
        year: `20${Number(data.expiresOn.substring(2, 4))}`,
        month: Number(data.expiresOn.substring(0, 2)),
        cvv: data.cvv,
        address1: data.street1,
        address2: data.street2,
        country: user.country,
        city: data.city,
        state: data.state.value,
        postal_code: data.postalCode,
      },
      (err, token) => {
        if (token) {
          try {
            if (token) {
              const cc_id = user.cc_id
              updateBillingInfo({
                variables: {
                  CCID: cc_id,
                  token: token.id,
                  billingInfoId,
                  isPrimary: isChecked,
                },
              })
                .then(() => {
                  setLoading(false)
                  onComplete()
                })
                .catch(() => setError('There is an error with you Credit Card.'))
            } else {
              console.log('recurly error:', err)
            }
          } catch (error) {
            console.log('Service error:', error)
          }
        }
        if (err) {
          setError(getRecurlyErrorMessage(err))
        }
        setLoading(false)
      },
    )
  }

  return (
    <div className=" bg-[#FFFFFF] ">
      <form
        onSubmit={handleSubmit(submitEditPaymentMethod)}
        onFocus={() => setError(null)}
      >
        <Header fontWeight="bold" variant="h5" className="mt-5 mb-3 text-sm">
          Edit Credit Card Info*
        </Header>

        <div className="grid grid-cols-6 gap-3">
          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{
                  required: 'Card Number is required',
                  minLength: {
                    value: 15,
                    message: 'Card Number must be 15-16 digits',
                  },
                  maxLength: {
                    value: 16,
                    message: 'Card Number must be 15-16 digits',
                  },
                }}
                name="cardNumber"
                render={({ field }) => (
                  <MaskedInput
                    onChange={(e) => {
                      setValue('cardNumber', e.target.value)
                    }}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="cardNumber"
                    name="cardNumber"
                    withLabel
                    placeholder="Card Number"
                    labelType="floating"
                    variant="default"
                    mask="#### #### #### ####"
                    maxLength={20}
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.cardNumber?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'Expiry date is required' }}
                name="expiresOn"
                render={({ field }) => (
                  <MaskedInput
                    onChange={(e) => setValue('expiresOn', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="expiresOn"
                    name="expiresOn"
                    withLabel
                    placeholder="Expires On (MM/YY)"
                    labelType="floating"
                    variant="default"
                    mask="##/##"
                    maxLength={5}
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.expiresOn?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'CVV is required' }}
                name="cvv"
                render={({ field }) => (
                  <MaskedInput
                    onChange={(e) => setValue('cvv', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="cvv"
                    name="cvv"
                    withLabel
                    placeholder="CVV"
                    labelType="floating"
                    variant="default"
                    maxLength={4}
                    mask="####"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">{errors.cvv?.message}</p>
            </>
          </div>
        </div>
        <Header fontWeight="bold" variant="h5" className="mt-5 mb-3 text-sm">
          Billing Address
        </Header>
        <div className="grid grid-cols-6 gap-3">
          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'First Name is required' }}
                name="fname"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('fname', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="fname"
                    name="fname"
                    placeholder="First Name"
                    withLabel
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.fname?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'Last Name is required' }}
                name="lname"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('lname', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="lname"
                    name="lname"
                    withLabel
                    placeholder="Last Name"
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.lname?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'Email is required' }}
                name="street1"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('street1', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="street1"
                    name="street1"
                    withLabel
                    labelType="floating"
                    placeholder="Street Address"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.street1?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                name="street2"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('street2', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="street2"
                    name="street2"
                    withLabel
                    labelType="floating"
                    placeholder="Street Address 2 (optional)"
                    variant="default"
                  />
                )}
              />
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'City is required' }}
                name="city"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('city', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="city"
                    name="city"
                    placeholder="City"
                    withLabel
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.city?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-4 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'State is required' }}
                name="state"
                render={({ field: { ref, ...rest } }) => (
                  <Select
                    options={states}
                    id="state"
                    name="state"
                    width="full"
                    ref={ref}
                    selectLabel="State/Region"
                    labelType="floating"
                    selectedOption={getValues('state')}
                    setSelectedOption={(e) => setValue('state', e)}
                    {...rest}
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.state?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{
                  required: 'Postal Code is required',
                  validate: customValidationPincode,
                }}
                name="postalCode"
                render={({ field }) =>
                  countryCode === 'CA' ? (
                    <Input
                      onChange={(e) => setValue('postalCode', e.target.value)}
                      className="w-[100%] mt-[7px]"
                      type="tel"
                      {...field}
                      id="postalCode"
                      name="postalCode"
                      placeholder="Postal Code"
                      pattern="^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\- ]?\d[ABCEGHJ-NPRSTV-Z]\d$"
                      withLabel
                      labelType="floating"
                      variant="default"
                    />
                  ) : (
                    <MaskedInput
                      onChange={(e) => setValue('postalCode', e.target.value)}
                      className="w-[100%] mt-[7px]"
                      type="tel"
                      {...field}
                      id="postalCode"
                      name="postalCode"
                      placeholder="Postal Code"
                      withLabel
                      labelType="floating"
                      variant="default"
                      mask="#####"
                    />
                  )
                }
              />
              <p className="text-notification-red text-xs ">
                {errors.postalCode?.message}
              </p>
            </>
          </div>
        </div>

        <div className=" flex items-center py-[24px]">
          <Checkbox
            hasLabel
            name="HTML"
            isChecked={isChecked}
            onChange={handleOnChange}
            label="Make this my default credit card"
          />
        </div>

        {error && <div className="text-[#8B0000] text-[13px] h-[40px]">{error}</div>}
        <div className="flex w-full mt-5 mb-5 justify-end items-center gap-4 sm:w-[286px] sm:justify-center sm:mt-[50px] sm:mb-[20px]">
          <Button onClick={handleClose} variant="text" level={2} textVariant="light">
            Cancel
          </Button>

          {loading || loadingNewBillingInfo ? (
            <LoadingSpinner widthAndHeight={32} />
          ) : (
            <Button variant="primary" type="submit" disabled={loadingNewBillingInfo}>
              Save
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default EditPaymentMethod
