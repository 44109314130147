import {
  Button,
  Icon,
  Input,
  ModalTitle,
  ModalWrapper,
  ParagraphText,
} from '@thryvlabs/maverick'
import { PenPaperIcon } from '../../../command-center-icon/icons'
import { useForm } from 'react-hook-form'
import { UPDATE_LOCATION_NAME_CALL } from '../../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { setBusinessLocation } from '../../../top-navigation-bar/slices/businessInfoSlice'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import formSchema from './data/formSchema'
import { useDispatch } from 'react-redux'
import {
  closeToast,
  openToast,
} from '../../../inbox/slices/inboxToastNotificationSlice'

const RegisterBusiness = ({ handleCloseModal }) => {
  const {
    user: { businessId },
  } = useAuth0()
  const dispatch = useDispatch()
  const [updateLocationName] = useMutation(UPDATE_LOCATION_NAME_CALL, {
    onCompleted: (data) => {
      dispatch(setBusinessLocation({ name: data?.updateLocationName?.Name }))
      dispatch(
        openToast({
          iconType: 'solid',
          close: () => {
            dispatch(closeToast())
          },
          variant: 'success',
          textToRender:
            'Great news! Your business name has been successfully registered. You can now start texting your contacts!',
        }),
      )
    },
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(formSchema) })

  const onSubmit = async (data) => {
    updateLocationName({
      variables: {
        location_id: businessId,
        business_name: data.businessName,
      },
    })
    handleCloseModal()
  }

  return (
    <>
      <div className="w-[656px]">
        <div className="flex justify-between items-center gap-8 w-full">
          <div className="flex gap-4 items-center">
            <ModalTitle variant="primary">Register Your Business Name</ModalTitle>
          </div>
          <Button className="" variant="text" level={2}>
            <Icon
              type="regular"
              variant="x"
              height="18"
              fill="#A3A5A7"
              onClick={handleCloseModal}
            />
          </Button>
        </div>
        <ParagraphText
          variant="lg"
          className="my-5 text-left font-semibold leading-[22px]"
        >
          You're moments away from better conversations.
        </ParagraphText>
        <div className="flex flex-col items-center w-[496px] h-[582px] border-[2px] rounded border-thryv-gray-light-400 mx-auto mt-[20px] mb-[50px] px-[20px]">
          <div className="flex w-[492px] mt-12 place-items-center justify-center">
            <div className="absolute z-0 h-[100px] w-[100px] bg-[#EBEDF0] rounded-full" />
            <PenPaperIcon className="z-10 ml-3" />
          </div>
          <p className="mt-10 mb-5 px-8 font-montserrat text-[22px] font-semibold leading-[30px]">
            Let’s start using SMS in the Thryv{' '}
            <span className="text-[#FF5000]">Command Center Inbox</span>!
          </p>
          <ParagraphText
            variant="reg"
            className="mb-5 px-4 text-sm font-montserrat leading-[20px]"
          >
            In order to start using the SMS service included in the phone channel for
            your area, you need to register a business name to send with text as per{' '}
            <a
              href="https://www.acma.gov.au/avoid-sending-spam"
              target="_blank"
              className="text-[#057AFF]"
              rel="noreferrer"
            >
              policy
            </a>
            . You can easily update your business name later in the Business
            Information settings page.
          </ParagraphText>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full">
            <div className="flex flex-col">
              <div className="flex mt-[32px] justify-center">
                <Input
                  type="text"
                  withLabel
                  name="businessName"
                  placeholder="Enter Your Business Name"
                  labelType="floating"
                  variant="default"
                  className="w-[220px]"
                  errors={errors}
                  register={register}
                />
              </div>
            </div>
            <div className="grow" />
            <Button
              variant="primary"
              className="w-[133px] mb-16 ml-[45px]"
              type="submit"
            >
              Register
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

export const RegisterBusinessModal = ({ showModal, handleCloseModal }) => {
  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseModal}
      disableBackdropClick={false}
    >
      <RegisterBusiness handleCloseModal={handleCloseModal} />
    </ModalWrapper>
  )
}
