import {
  Button,
  Icon,
  ModalWrapper,
  ParagraphText,
  Header,
} from '@thryvlabs/maverick'
import InitialScreen from './steps/InitialScreen'
import { useMemo, useState } from 'react'
import { RegisterPhoneWrapper } from '../register-phone-modal/RegisterPhoneModal'
import AssignedPhone from './steps/AssignedPhone'
import { useMediaQuery } from 'react-responsive'
import {
  setChannelIsConnected,
  setShowWelcomeChannelModal,
} from '../../slices/connectChannelsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { sha1 } from 'js-sha1'
import usePhoneChannel from '../../../../../hooks/use-phone-channel'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
const ConnectPhone = ({
  handleCloseModal,
  handleShowRegisterModal,
  inputField,
  setInputField,
}) => {
  const [step, setStep] = useState(0)
  const dispatch = useDispatch()
  const { user } = useAuth0()

  const isMobile = useMediaQuery({ maxWidth: 640 })
  const isXSmallMobile = useMediaQuery({ maxWidth: 375 })
  // const StepComponent = useMemo(() => stepComponents[step], [step])
  const { showWelcomeChannelModal } = useSelector((state) => state.connectChannels)
  const navigate = useNavigate()

  const clearAreaCodeInput = () => {
    //clear previous input field
    setInputField('')
  }

  const handleProceedClick = () => {
    setStep(1)
    clearAreaCodeInput()
  }

  const handleCloseFunctions = () => {
    handleCloseModal()
    dispatch(setChannelIsConnected(true))
    setStep(0)
    clearAreaCodeInput()
  }

  const handleCloseConnectPhoneModal = () => {
    // invoke impact conversion function when channel is connected
    if (window.ire && !Array.isArray(window.ire)) {
      const { cc_id, email } = user
      if (email && cc_id) {
        window.ire(
          'trackConversion',
          43455,
          {
            orderId: 'IR_AN_64', // passing this value will create a UUID in impact
            customerId: cc_id,
            customerEmail: sha1(email),
          },
          {
            verifySiteDefinitionMatch: true,
          },
        )
      }
    }

    if (showWelcomeChannelModal) {
      handleCloseFunctions()
      dispatch(setShowWelcomeChannelModal(false))
      navigate('/calls')
      return
    }

    handleCloseFunctions()
  }
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)

  const [phoneChannel] = usePhoneChannel()
  const { toggleTenDlc } = useFlags()
  const isActive =
    toggleTenDlc ||
    countryISO === 'AU' ||
    countryISO === 'CA' ||
    countryISO === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  return (
    <div className="w-full px-4 sm:px-1 xx-sm:px-0">
      <div className="flex justify-between items-center gap-8 w-full">
        <div className="flex gap-4 items-center">
          <Header
            fontWeight="semibold"
            variant={isXSmallMobile ? 'h6' : isMobile ? 'h4' : 'h2'}
          >
            {isActive ? 'Connect Calls & SMS' : 'Connect Calls'}
          </Header>
        </div>
        <Button className="" variant="text" level={2}>
          <Icon
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={handleCloseFunctions}
          />
        </Button>
      </div>
      <ParagraphText
        variant={isMobile ? 'sm' : 'lg'}
        className="my-5 text-left font-semibold leading-[22px]"
      >
        Build strong relationships with real-time, direct, and personal
        communication.
      </ParagraphText>
      <div className="flex flex-col items-center sm:w-full w-[496px] h-[582px] border-[2px] rounded border-thryv-gray-light-400 mx-auto mt-[20px] mb-[50px]">
        {step === 0 ? (
          <InitialScreen
            setStep={setStep}
            handleShowRegisterModal={handleShowRegisterModal}
            inputField={inputField}
            setInputField={setInputField}
            handleProceedClick={handleProceedClick}
          />
        ) : (
          <AssignedPhone
            handleShowRegisterModal={handleShowRegisterModal}
            handleCloseConnectPhoneModal={handleCloseConnectPhoneModal}
          />
        )}
      </div>
    </div>
  )
}

export const ConnectPhoneModal = ({
  showModal,
  setShowModal,
  disableBackdropClick,
}) => {
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [isOTPScreen, setIsOTPScreen] = useState(false)
  const [inputField, setInputField] = useState('')

  const clearAreaCodeInput = () => {
    //clear previous input field
    setInputField('')
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setShowRegisterModal(false)
  }

  const handleShowRegisterModal = () => {
    setShowRegisterModal(true)
    clearAreaCodeInput()
  }

  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseModal}
      disableBackdropClick={disableBackdropClick ?? false}
      {...(isOTPScreen && { className: 'transform-none' })}
    >
      {showRegisterModal ? (
        <RegisterPhoneWrapper
          handleCloseModal={handleCloseModal}
          setIsOTPScreen={setIsOTPScreen}
        />
      ) : (
        <ConnectPhone
          handleShowRegisterModal={handleShowRegisterModal}
          handleCloseModal={handleCloseModal}
          inputField={inputField}
          setInputField={setInputField}
        />
      )}
    </ModalWrapper>
  )
}

export const useConnectPhoneModal = () => {
  const [showModal, setShowModal] = useState(false)
  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  const Modal = useMemo(
    () => <ConnectPhoneModal showModal={showModal} setShowModal={setShowModal} />,
    [showModal, setShowModal],
  )

  return [openModal, closeModal, Modal]
}
