import { Badge, Button } from '@thryvlabs/maverick'
import InfoBanner from '../../../helper-components/InfoBanner'
import { CommandCenterIcon } from '../../../../command-center-icon/command-center-icon'
import { RadioContainer } from './OrderReview.styles'
import { UPGRADE_FLOW_STEPS } from '../constants'
import {
  ButtonAlt,
  ButtonContainer,
  ContentContainer,
  FlexEnd,
  FlexStart,
  LargeRadioContainer,
  LeftSide,
  RightSide,
} from '../UpgradePlanModal.styles'
import StepsContainer from '../steps-container/StepsContainer'
import OrderSummaryBox from '../order-summary-box/OrderSummaryBox'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_CC_PLANS_INFO, SET_SUBSCRIPTION_CHANGE } from '../../../../../graphql'
import { useDispatch } from 'react-redux'
import { changeCurrentPlan, setPaymentProcessed } from '../slices/planInfoSlice'
import {
  setDisableBackdropClick,
  setModalContent,
} from '../../slices/connectChannelsSlice'
import { GET_PACKAGE_INFO } from '../../../../../graphql'
import { setMaxChannelsAllowed } from '../../slices/connectChannelsSlice'
import { LoadingSpinner } from '../../../loading-spinner'
import { useMediaQuery } from 'react-responsive'

export default function OrderReview({
  setStep,
  selectedPlan,
  accountInfo,
  currentStep,
  moveToPreviousStep,
  setIsNewPaymentMethod,
  seats,
}) {
  const dispatch = useDispatch()
  const [canSubmit, setCanSubmit] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const {
    data: packageInfoData,
    error: packageInfoError,
    refetch: refetchPackageInfo,
  } = useQuery(GET_PACKAGE_INFO, {
    skip: !isUpdated,
    onCompleted: () => {
      dispatch(
        setMaxChannelsAllowed(packageInfoData.queryPackageInfoByCCID.InboxChannels),
      )
      dispatch(setPaymentProcessed(true))
      dispatch(changeCurrentPlan(selectedPlan))
      dispatch(setDisableBackdropClick(false))
      dispatch(setModalContent(1))
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.log('PACKAGE INFO ERROR:', packageInfoError)
    },
  })

  const [sendSubscriptionChange, { loading: loadingChange }] = useMutation(
    SET_SUBSCRIPTION_CHANGE,
    {
      refetchQueries: [GET_CC_PLANS_INFO],
      onCompleted: () => {
        setIsUpdated(true)
        refetchPackageInfo()
      },
    },
  )

  const { billingInfo } = accountInfo

  const paymentMethod = billingInfo?.paymentMethod || null
  const isAbleToConfirm = () => billingInfo?.id && canSubmit

  const SubmitPayment = () => {
    const cc_id = localStorage.getItem('cc_id')
    sendSubscriptionChange({
      variables: {
        CCID: cc_id,
        planCode: selectedPlan?.code,
      },
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log({ err })
      setErrorMessage('Unable to change subscriptions')
    })
  }

  return (
    <>
      <StepsContainer step={'review'} />
      <ContentContainer>
        <LeftSide>
          <LargeRadioContainer>
            <div className="text-left mt-[15px] mb-[5px] text-[14px] text-[#808080] font-[400]">
              Your Plan
            </div>
            <RadioContainer>
              <div className="flex flex-row justify-between gap-1 items-center">
                <CommandCenterIcon
                  type="solid"
                  variant="thryvCCLogoOrange"
                  width={40}
                  height={40}
                />{' '}
                <div className="flex gap-[10px] sm:gap-0 sm:flex-wrap sm:w-[150px]">
                  <span className="text-[#7A94F6] mt-[5px] ml-[10px] sm:mt-0">
                    {selectedPlan?.header}
                  </span>
                  <span className="mt-[5px] sm:text-[12px] sm:pl-2 sm:mt-0">
                    {`($${selectedPlan?.price}/user, per month)`}
                  </span>
                </div>
              </div>
              <Button
                variant="text"
                onClick={() => {
                  setIsNewPaymentMethod(false)
                  setStep(UPGRADE_FLOW_STEPS.PLAN_SELECTION)
                }}
                level={1}
              >
                Edit
              </Button>
            </RadioContainer>
          </LargeRadioContainer>
          <LargeRadioContainer>
            <div className="flex flex-row mt-[25px] mb-[5px] text-[14px] text-[#808080] justify-between">
              <div>Your Payment Method</div>
              <Button
                variant="text"
                onClick={() => {
                  setIsNewPaymentMethod(true)
                  setStep(UPGRADE_FLOW_STEPS.PAYMENT_FROM)
                }}
                level={1}
              >
                + Add New
              </Button>
            </div>
            {paymentMethod && (
              <RadioContainer>
                <div className="flex flex-row justify-between items-center">
                  <CommandCenterIcon
                    width={40}
                    height={30}
                    type="solid"
                    variant={
                      paymentMethod.cardType === 'Visa' ? 'visa' : 'masterCard'
                    }
                  />
                  <div className="ml-[10px] sm:text-[12px] sm:ml-0">{`${paymentMethod.cardType} ending in ${paymentMethod.lastFour}`}</div>
                </div>
                <div className="flex flex-row justify-between w-[120px] items-center sm:w-[90px]">
                  <Badge variant="positive" badgeText="Active" />{' '}
                  <Button
                    variant="text"
                    onClick={() => {
                      setIsNewPaymentMethod(false)
                      setStep(UPGRADE_FLOW_STEPS.PAYMENT_FROM)
                    }}
                    level={1}
                  >
                    Edit
                  </Button>
                </div>
              </RadioContainer>
            )}
          </LargeRadioContainer>
          <InfoBanner
            className="sm:w-[300px]"
            text={
              <span>
                Once you complete your order, you will receive a confirmation email
                with a receipt. If you have any questions, please contact Thryv at{' '}
                <a href="support@thryv.com">support@thryv.com </a> or call at +1
                844-339-6334.
              </span>
            }
          />
          {isMobile ? (
            <OrderSummaryBox
              selectedPlan={selectedPlan}
              canApplyPromoCode={currentStep === UPGRADE_FLOW_STEPS.ORDER_REVIEW}
              accountInfo={accountInfo}
              setCanSubmit={setCanSubmit}
              seats={seats}
              setErrorMessage={setErrorMessage}
            />
          ) : null}
        </LeftSide>
        {!isMobile ? (
          <RightSide>
            <OrderSummaryBox
              selectedPlan={selectedPlan}
              canApplyPromoCode={currentStep === UPGRADE_FLOW_STEPS.ORDER_REVIEW}
              accountInfo={accountInfo}
              setCanSubmit={setCanSubmit}
              seats={seats}
              setErrorMessage={setErrorMessage}
            />
          </RightSide>
        ) : null}
      </ContentContainer>
      <div className="flex text-left">
        {errorMessage ? (
          <p className="!text-[13px] !text-[#B22222]">{errorMessage}</p>
        ) : null}
      </div>
      <ButtonContainer>
        <FlexStart>
          <ButtonAlt onClick={moveToPreviousStep} variant="primary" hover>
            BACK
          </ButtonAlt>
        </FlexStart>
        <FlexEnd>
          {loadingChange ? (
            <LoadingSpinner widthAndHeight={36} />
          ) : (
            <>
              <Button
                variant="primary"
                disabled={!isAbleToConfirm()}
                onClick={SubmitPayment}
              >
                {currentStep === UPGRADE_FLOW_STEPS.ORDER_REVIEW
                  ? 'Confirm'
                  : 'Review'}
              </Button>
            </>
          )}
        </FlexEnd>
      </ButtonContainer>
    </>
  )
}
