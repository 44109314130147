
import Labels from './Labels'

export const ConversationsTab = () => {
  return (
    <div>
      <div className="sm:col-span-12 md:col-span-6 p-6 min-h-[348px] bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] sm:w-[95%] sm:m-auto">
        <Labels />
      </div>
    </div>
  )
}
