import { Icon } from '@thryvlabs/maverick'
import { useMutation } from '@apollo/client'
import { DELETE_LABEL_FROM_MESSAGE } from '../../../../graphql'
import { FETCH_MESSAGES_BY_THREAD_ID } from '../../../../graphql'

export const LabelOnMessage = ({ label }) => {
  const [deleteLabelFromMessage] = useMutation(DELETE_LABEL_FROM_MESSAGE, {
    variables: {
      PK1: label.pk1,
      SK1: label.sk1,
    },
    onError: () => {},
    refetchQueries: [FETCH_MESSAGES_BY_THREAD_ID],
  })

  function extractDisplayName(inputString) {
    const pairs = inputString.slice(1, -1).split(',')

    let displayNameValue = ''

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=')

      const trimmedKey = key.trim()
      const trimmedValue = value.trim()

      if (trimmedKey === 'displayName') {
        displayNameValue = trimmedValue
      }
    })

    return displayNameValue
  }

  const displayName =
    label?.body.startsWith('{') && label?.body.endsWith('}')
      ? extractDisplayName(label?.body)
      : label?.body

  return (
    <>
      {label !== undefined ? (
        <div className="flex space-x-1 items-center justify-center h-6 w-max rounded-full bg-thryv-gray-medium-500 px-2 mb-1 mr-[5px]">
          <span className="text-xs text-white">{displayName}</span>
          <Icon
            variant="circleX"
            type="solid"
            color="white"
            height="12"
            width="12"
            className="cursor-pointer"
            onClick={() => deleteLabelFromMessage()}
          />
        </div>
      ) : null}
    </>
  )
}
