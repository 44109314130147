import { useRef } from 'react'
import RoundedActionButton from '../../../../../common/rounded-action-button'
import { useClickOutside } from '../../../../../../../hooks/use-click-outside'
import { TriDotMenu } from './tri-dot-menu'

export const TriDotButtonWithMenu = ({
  showMenu,
  setVisibleMenu,
  onClick,
  buttonHandlers,
}) => {
  const tridotButtonRef = useRef(null)
  const menuRef = useRef(null)

  const onTridotButtonClick = () => {
    setVisibleMenu(showMenu === true ? '' : 'triDot')
    onClick && onClick()
  }

  useClickOutside((e) => {
    const tridotButtonClicked = e.composedPath().includes(tridotButtonRef.current)
    if (!tridotButtonClicked) setVisibleMenu(false)
  }, menuRef)

  return (
    <>
      <RoundedActionButton
        className="!w-[30px] !h-[30px]"
        type="regular"
        icon={{
          variant: 'horizontalEllipsis',
          type: 'regular',
          width: '16',
          height: '4',
          color: '#92929D',
          hoverColor: '#92929D',
        }}
        active={showMenu}
        onClick={onTridotButtonClick}
        isCCIcon
        ref={tridotButtonRef}
      />

      <TriDotMenu
        show={showMenu}
        className="top-[calc(100%+2px)] right-0"
        buttonHandlers={buttonHandlers}
        ref={menuRef}
        onButtonClick={() => setVisibleMenu('')}
      />
    </>
  )
}
