import styled from 'styled-components'

export const ManageChannelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ChannelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

export const ConnectedChannelInfo = styled.div`
  padding: 1.5rem 1rem;
  max-height: 108px;
  position: relative;
  display: flex;
  align-items: start;
  z-index: auto;

  @media (max-width: 640px) {
    width: 276px;
  }
`

export const SharedPermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const SharedPermissionModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-property: visibility, opacity, translate;
  transition: ease-in-out 200ms;
  margin-bottom: 6px;
  z-index: 999;
`
