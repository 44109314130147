/* eslint-disable no-useless-escape */
import { useState, useRef, useEffect } from 'react'
import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'
import { UnderlineBorder } from './compose-header'
import { Card, ShowMoreButton } from './ccSection'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { useSelector, useDispatch } from 'react-redux'
import { setBccEmailRecipients } from '../../../slices/messageComposeSlice'
import { TransitionContainer } from '../../../../common/transition-container'

export const BccSection = ({ setShowBCC }) => {
  const { message, replying } = useSelector((s) => s.messageCompose)

  const [bccInput, setBccInput] = useState({ name: '', email: '' })
  const [bccRecipients, setBccRecipients] = useState(message.emailRecipients.bcc)
  const [showMore, setShowMore] = useState(false)
  const [invalidInput, setInvalidInput] = useState(false)

  const bccRef = useRef(null)
  const inputRef = useRef(null)
  const timerRef = useRef(null)

  const dispatch = useDispatch()

  const submitInputWithClick = () => {
    const validEmail = validateEmail(bccInput.email)
    if (validEmail) {
      dispatch(setBccEmailRecipients([...bccRecipients, bccInput]))
      const spanInput = document.getElementById('email')
      spanInput.textContent = '' //sets the input to empty
      setInvalidInput(false)
      setBccInput({ ...bccInput, email: '' })
    } else {
      setInvalidInput(true)
      timerRef.current = setTimeout(() => {
        setInvalidInput(false)
      }, 5000)
    }
  }
  useEffect(() => {
    if (replying) {
      setBccRecipients(message.emailRecipients.bcc)
    }
  }, [message.emailRecipients, replying])

  useEffect(() => {
    setBccRecipients(message.emailRecipients.bcc)
    //refetches data and sets state when emailrecipients changes
  }, [message.emailRecipients])

  useEffect(() => {
    if (inputRef !== null) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const firstItem = bccRecipients?.slice(0, 1)

  const handleAddBcc = (e) => {
    setInvalidInput(false)
    setBccInput({ ...bccInput, [e.target.id]: e.target.textContent })
  }

  const handleDelete = (recipientEmail) => {
    const filteredList = bccRecipients?.filter((bcc) => bcc !== recipientEmail)
    setBccRecipients(filteredList)
    dispatch(setBccEmailRecipients(filteredList))
  }

  useClickOutside(() => {
    if (bccInput.email !== '') {
      submitInputWithClick()
      return
    }
    if (bccRecipients.length !== 0) return
    else {
      setShowBCC(false)
    }
  }, bccRef)

  const handleShowMoreClick = () => {
    //this is so that the input is focused after the user clicks the see more button
    setShowMore(!showMore)
    inputRef.current.focus()
  }

  function validateEmail(email) {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  function handleSubmitKey(e) {
    if (
      e.key.toLowerCase() === 'enter' ||
      e.key.toLowerCase() === ' ' || //to run this when space is clicked since bccs shouldnt have spaces
      e.code.toLowerCase() === 'space'
    ) {
      e.preventDefault()
      const validEmail = validateEmail(e.target.textContent)
      if (validEmail) {
        dispatch(setBccEmailRecipients([...bccRecipients, bccInput]))
        e.target.textContent = '' //sets the input to empty
        setInvalidInput(false)
        setBccInput({ ...bccInput, email: '' })
      } else {
        setInvalidInput(true)
        timerRef.current = setTimeout(() => {
          setInvalidInput(false)
        }, 5000)
      }
    }
  }

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current)
  }, [])

  return (
    <div
      className="w-full flex flex-col pt-1"
      ref={bccRef}
      onClick={() => inputRef.current.focus()}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ParagraphText variant="reg" color="thryv-steel" className="pr-2 pb-1.5">
            Bcc:
          </ParagraphText>
          <div
            className={`flex flex-wrap items-center ${
              showMore ? 'gap-2' : ''
            }  gap-y-4 pb-1`}
          >
            {showMore ? (
              bccRecipients?.map((recipient, idx) => {
                return (
                  <Card receiver={recipient} key={idx} handleDelete={handleDelete} />
                )
              })
            ) : bccRecipients?.length >= 1 ? (
              <Card receiver={firstItem[0]} handleDelete={handleDelete} />
            ) : null}
            <form className="flex flex-col items-center relative">
              <span
                name="email"
                id="email"
                className={`${
                  invalidInput && 'border border-[#FDDF95]'
                } block min-w-[25px] whitespace-nowrap focus:outline-none p-1.5 rounded-sm focus:cursor-text max-w-[300px] overflow-x-scroll no-scrollbar text-left text-sm font-snow`}
                ref={inputRef}
                role="textbox"
                contentEditable
                onInput={(evt) => handleAddBcc(evt)}
                onKeyDown={(e) => handleSubmitKey(e)}
                autoFocus
              ></span>
              <TransitionContainer
                width={'270px'}
                show={invalidInput}
                transformOrigin={'bottom right'}
                positioning="absolute"
                className="border bg-[#FFF9EA] pointer-events-none border-[#FDDF95] top-[35px] text-sm flex items-center justify-around p-2"
              >
                <Icon
                  variant="triangleExclamation"
                  type="solid"
                  width={20}
                  color="#FAC02B"
                />
                Email is not in the correct format
              </TransitionContainer>
            </form>

            {bccRecipients?.length > 1 ? (
              <ShowMoreButton
                onClick={() => handleShowMoreClick()}
                showMore={showMore}
                number={bccRecipients?.length - 1}
              />
            ) : null}
          </div>
        </div>
        <Button
          variant="text"
          level={1}
          className="z-10 w-[5%]"
          onClick={() => setShowBCC(false)}
        >
          Hide
        </Button>
      </div>
      <UnderlineBorder className="w-full mb-2" />
    </div>
  )
}
