import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { faGear } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardTitle } from '@thryvlabs/maverick'
import { GeneralTab } from './MyAccount/MyAccount'
import { ConversationsTab } from './Conversations/ConversationsTab'
import { SecurityStaffTab } from './SecurityStaff/SecurityStaffTab'
import { CallsTab } from './Calls/CallsTab'
import { CommandCenterNavigation } from '../../components/common/tab-navigation'
import { Notifications } from './Notifications/Notifications'
import { useMediaQuery } from 'react-responsive'
import { MobileNavigation } from './MobileNavigation/MobileNavigation'
import { useEffect } from 'react'

const SETTINGS_URLS = [
  '/settings/my-account/personal-information',
  '/settings/my-account/business-form',
  '/settings/my-account/product-services',
  '/settings/my-account/payment-methods',
  '/settings/my-account/invoice-coupons',
  '/settings/security-staff',
  '/settings/notifications',
  '/settings/inbox',
  '/settings/calls',
]

export const Settings = () => {
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!SETTINGS_URLS.includes(location.pathname)) {
      navigate(SETTINGS_URLS[0])
    }
  }, [SETTINGS_URLS, location.pathname, navigate])

  const myAccountSubTab = [
    {
      label: 'Personal Information',
      path: '/settings/my-account/personal-information',
    },
    { label: 'Business Information', path: '/settings/my-account/business-form' },
    { label: 'Subscription', path: '/settings/my-account/product-services' },
    { label: 'Payment Methods', path: '/settings/my-account/payment-methods' },
    { label: 'Payment History', path: '/settings/my-account/invoice-coupons' },
  ]

  const staffMyAccountSubTab = [
    {
      label: 'Personal Information',
      path: '/settings/my-account/personal-information',
    },
    { label: 'Business Information', path: '/settings/my-account/business-form' },
  ]

  const settingsLinksArray = [
    { title: 'My Account', path: '/settings/my-account/personal-information/' },
    { title: 'Security + Staff', path: '/settings/security-staff' },
    { title: 'Notifications', path: '/settings/notifications' },
    { title: 'Inbox', path: '/settings/inbox' },
    // { title: 'TeamChat', path: '/settings/team-chat' },
    { title: 'Calls', path: '/settings/calls' },
  ]

  return (
    <>
      {!isMobile && (
        <CardTitle
          className="text-[18px] font-bold flex items-center h-[62px] bg-[#fff]"
          variant="primary"
        >
          <FontAwesomeIcon
            icon={faGear}
            size={'lg'}
            className={'pl-0 h-[16px] w-[17px] mr-1 ml-[1rem]'}
          />
          Settings
        </CardTitle>
      )}

      <div className="sm:p-[0px] p-[1rem]">
        {!isMobile && (
          <div className="overscroll-none mb-6">
            <CommandCenterNavigation linksArray={settingsLinksArray} />
          </div>
        )}

        {isMobile && (
          <MobileNavigation
            settingsLinksArray={settingsLinksArray}
            myAccountSubTab={myAccountSubTab}
            staffMyAccountSubTab={staffMyAccountSubTab}
          />
        )}

        <Routes>
          <Route path="/my-account/*" element={<GeneralTab />} />
          <Route path="/inbox" element={<ConversationsTab />} />
          <Route path="/security-staff" element={<SecurityStaffTab />} />
          <Route path="/notifications" element={<Notifications />} />
          {/* <Route path="/team-chat" element={<div>Team Chat</div>} /> */}
          <Route
            path="/calls"
            element={
              <div>
                <CallsTab />
              </div>
            }
          />
          <Route path="/" element={<GeneralTab />} />
        </Routes>
      </div>
    </>
  )
}
