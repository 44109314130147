import { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  setEditorContents,
  setSubmitMessageFlag,
} from '../../../slices/messageComposeSlice'
import { quillEditorFormats, quillEditorModules } from './quill-toolbar'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'

export const QuillEditor = forwardRef(function QuillEditor(props, quillEditorRef) {
  const dispatch = useDispatch()
  const { editorView, editorHTML, editorText } = useSelector((s) => s.messageCompose)
  const handleKeyDown = (e) => {
    if ((e.key === 'Enter' && e.ctrlKey) || (e.key === 'Enter' && e.metaKey)) {
      dispatch(setSubmitMessageFlag(true))
    }
  }
  return (
    <ReactQuill
      ref={quillEditorRef}
      theme="snow"
      modules={quillEditorModules}
      formats={quillEditorFormats}
      value={editorHTML}
      onChange={(html) => {
        const text = quillEditorRef.current.getEditor().getText().replace(/\n/g, '')
        dispatch(setEditorContents({ text, html }))
        // If selection exists and is at the beginning of the editor, but we know editor has text,
        // set selection to the end of the editor text.
        if (
          quillEditorRef.current.getEditor().getSelection() !== null &&
          quillEditorRef.current.getEditor().getSelection().index === 0 &&
          editorText.length !== 0
        ) {
          quillEditorRef.current.getEditor().setSelection(editorText.length, 0)
        }
      }}
      onKeyDown={handleKeyDown}
      placeholder={"What's on your mind?"}
      className={editorView === 'expanded' ? 'ql-editor-expanded' : ''}
    />
  )
})
