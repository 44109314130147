import { useState } from 'react'
import { ParagraphText, Input, Icon } from '@thryvlabs/maverick'
import { RequirementsGroup } from '../../../requirements-group'

const PasswordVisibilityButton = ({ className, onClick, isActive }) => {
  return (
    <button className={className} type="button">
      <Icon
        onClick={onClick}
        type="regular"
        variant="eye"
        height="10.67"
        width="18.55"
        color={isActive ? '#057AFF' : '#4D4D4D'}
      />
    </button>
  )
}

const CircleCheckIcon = ({ className, isVisible }) => (
  <Icon
    className={`${className} opacity-0 ${
      isVisible && '!opacity-100'
    } transition-all duration-300`}
    type="solid"
    variant="circleCheck"
    height="16"
    width="16"
    fill="#16A085"
  />
)

export const PasswordInput = ({
  className,
  labelText,
  variant,
  requirementsList = [],
  inputName,
  passwordIsValid,
  register,
  errors,
}) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)

  const togglePasswordIsVisible = (isVisible) => {
    if (isVisible !== undefined) setPasswordIsVisible(isVisible)
    else setPasswordIsVisible(!passwordIsVisible)
  }

  return (
    <div className={`${className} text-start`}>
      <ParagraphText variant="sm" color="thryv-steel">
        {labelText}
      </ParagraphText>

      <div className={className}>
        <div className="relative h-[28px] w-[487px]">
          <Input
            style={{ paddingRight: variant === 'requirements' ? '40px' : '' }}
            register={register}
            errors={errors}
            type={passwordIsVisible ? 'text' : 'password'}
            variant="default"
            name={inputName}
            customPlaceholder=" "
            labelType="static"
            className="w-full"
            autoComplete="off"
          />
          {variant === 'requirements' && (
            <PasswordVisibilityButton
              isActive={passwordIsVisible}
              className="absolute right-3 bottom-[10px]"
              onClick={() => togglePasswordIsVisible()}
            />
          )}

          {variant === 'default' && (
            <CircleCheckIcon
              className="absolute right-3 bottom-[10px]"
              isVisible={passwordIsValid}
            />
          )}
        </div>

        {requirementsList.length ? (
          <RequirementsGroup
            className="mt-[6px]"
            requirementsList={requirementsList}
          />
        ) : null}
      </div>
    </div>
  )
}
