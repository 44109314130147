import { useState, useEffect, useRef } from 'react'
import { TransitionContainer } from '../../../../../../../common/transition-container'
import { Icon, ParagraphText } from '@thryvlabs/maverick'
import { useClickOutside } from '../../../../../../../../hooks'

export const SelectionButtonWithMenu = ({
  dataTestID,
  className,
  children,
  content,
  onStateChange,
  isOpen,
  ...rest
}) => {
  const [visible, setVisible] = useState(false)
  const [active, setActive] = useState(false)
  const buttonContainerRef = useRef(null)

  const SCDropDownStyle = `
  pb-[7px] w-full flex justify-between items-center 
  text-btn-text text-thryv-black-400 font-open-sans font-normal 
  border-b transition-all ${
    active ? 'border-thryv-orange-500' : 'border-thryv-gray-light-500'
  }`

  const toggleVisible = () => {
    setVisible(!visible)
    setActive(!active)
  }

  const updateVisible = (isVisible) => {
    setVisible(isVisible)
    setActive(isVisible)
  }

  useClickOutside(() => {
    updateVisible(false)
  }, buttonContainerRef)

  useEffect(() => {
    if (typeof isOpen === 'boolean') updateVisible(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (onStateChange) onStateChange(visible ? 'dropdown open' : 'dropdown closed')
  }, [visible])

  return (
    <div
      className={`relative text-right ${className && className}`}
      ref={buttonContainerRef}
      {...rest}
    >
      <button
        data-testid={dataTestID}
        type="button"
        className={SCDropDownStyle}
        onClick={() => toggleVisible()}
      >
        <div className="grow w-0 text-start">
          <ParagraphText
            variant="reg"
            className="truncate leading-[17px] md:text-base md:leading-[22px]"
          >
            {children}
          </ParagraphText>
        </div>

        <Icon
          type="solid"
          variant="caretDown"
          className={`ml-2 mr-1 duration-300 ${visible ? 'rotate-180' : ''}`}
          width="10"
          height="6"
        />
      </button>

      <TransitionContainer
        show={visible}
        transformOrigin={'top'}
        className="mt-1"
        unmount={false}
      >
        {content}
      </TransitionContainer>
    </div>
  )
}
