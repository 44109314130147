import { createContactsListForPagination } from '../utils/createContactsListForPagination'

// Components
import { ContactRow } from './contact-row'
import { PaginatedList } from '@thryvlabs/maverick'
import StyledPaginatedListWrapper from '../common/paginated-list-wrapper'
import { NoResultsFoundSection } from '../../no-results-found-section'
// An alternative to loading dots
import { LoadingSpinner } from '../../../../common/loading-spinner'

const PaginatedContacts = ({ list }) => (
  <StyledPaginatedListWrapper>
    <PaginatedList list={list}>
      <ContactRow />
    </PaginatedList>
  </StyledPaginatedListWrapper>
)

const LoadingDotsInContainer = () => (
  <div className="relative h-full w-full">
    <LoadingSpinner />
  </div>
)

const ContactsSection = ({
  searchTerm,
  contactsList,
  onContactClick,
  contactsLoading,
}) => {
  const showNoResultsFound = !contactsList.length && !contactsLoading

  const paginationList = createContactsListForPagination({
    list: contactsList,
    searchTerm,
    onContactClick,
  })

  return (
    <>
      {contactsList.length ? <PaginatedContacts list={paginationList} /> : null}
      {showNoResultsFound ? <NoResultsFoundSection /> : null}
      {contactsLoading ? <LoadingDotsInContainer /> : null}
    </>
  )
}

export default ContactsSection
