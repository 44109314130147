// import { useState } from 'react'
import { Button, RegularX } from '@thryvlabs/maverick'
// import sadGuyThryv from '../../../../assets/ThryvGuy_sad.png'
// import { useMutation } from '@apollo/client'
// import { CANCEL_SUBSCRIPTION } from '../../../../graphql/mutations/cancel-subscription-mutation'
// import { useSelector } from 'react-redux'
// import { useAuth0 } from '@auth0/auth0-react'
// import moment from 'moment'
// import { CANCEL_CC_SUBSCRIPTION } from '../../../../graphql/mutations/recurly-mutations'
// import axios from 'axios'
// import { useVonageClient } from '../../../../vonage/useVonageClient'
import { ThryvExclamationIcon } from '../../../command-center-icon/icons'
export const CancelPlanModal = ({
  handleClose,
  // subscription,
  // latestPayment
}) => {
  // const { user, getAccessTokenSilently, logout } = useAuth0()
  // const [finalStep, setfinalStep] = useState(false)
  // const { VITE_NYLAS_HOST } = import.meta.env
  // const [vonageClient] = useVonageClient()

  // async function delSession() {
  //   // eslint-disable-next-line no-async-promise-executor
  //   return new Promise(async (resolve) => {
  //     try {
  //       const results = await axios.delete(
  //         'https://api.nexmo.com/v1/sessions/' + vonageClient.session_id,
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Authorization: 'Bearer ' + vonageClient.jwt_sub,
  //           },
  //         },
  //       )
  //       resolve(results.data)
  //       return
  //     } catch (err) {
  //       //
  //     }
  //   })
  // }

  // const deregisterKnock = async () => {
  //   const androidToken = localStorage.getItem('FCM_TOKEN')
  //   const iosToken = localStorage.getItem('APNS_TOKEN')
  //   const mobileToken = androidToken || iosToken
  //   const accessToken = await getAccessTokenSilently()
  //   let deviceType = ''

  //   if (androidToken) {
  //     deviceType = 'android'
  //   }

  //   if (iosToken) {
  //     deviceType = 'ios'
  //   }

  //   if (mobileToken && accessToken) {
  //     const options = {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //     axios.post(
  //       `${VITE_NYLAS_HOST}/remove_knock_user`,
  //       {
  //         token: mobileToken,
  //         user_id: user.cc_uid,
  //         device: deviceType,
  //       },
  //       options,
  //     )
  //   }
  // }

  // const [cancelSubscription, { error, loading }] = useMutation(
  //   CANCEL_CC_SUBSCRIPTION,
  //   {
  //     variables: {
  //       CCID: user.cc_id,
  //     },
  //     onCompleted: () => {
  //       handleClose()
  //       delSession()
  //       deregisterKnock()
  //       logout({ logoutParams: { returnTo: window.location.origin } })
  //     },
  //     onError: () => {
  //       // eslint-disable-next-line no-console
  //       console.error('Error in cancel subscription mutation:', error)
  //     },
  //   },
  // )

  // const nextPaymentDate = latestPayment[1].paymentDate

  // const renderPlanName = (plan) => {
  //   if (
  //     plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Plus' ||
  //     plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Pro'
  //   ) {
  //     return plan?.subscription?.SUBSCRIPTION_PLAN_NAME.substring(14)
  //   } else if (
  //     plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Basic'
  //   ) {
  //     return 'Free Forever'
  //   } else {
  //     return plan?.subscription?.SUBSCRIPTION_PLAN_NAME
  //   }
  // }

  // const renderFinalCancelButton = loading ? (
  //   <span
  //     className={`border-4 border-[#F8F9FB] border-b-thryv-orange-300 rounded-[50%] inline-block box-border animate-spin w-[32px] h-[32px] mr-10`}
  //   ></span>
  // ) : (
  //   <Button variant="primary" onClick={cancelSubscription}>
  //     Done
  //   </Button>
  // )
  // return (
  //   <div
  //     data-testid="cancel-modal"
  //     className="flex flex-col rounded-sm p-5 justify-between items-start w-[475px] h-[464px] md:w-[298px] "
  //   >
  //     <Button
  //       data-testid="close-x-btn"
  //       className="fixed right-[6%] top-[12%] md:top-[5%] md:right-[3%] mr-[0.5rem]"
  //       variant="text"
  //       level={2}
  //     >
  //       <RegularX
  //         type="regular"
  //         variant="x"
  //         height="18"
  //         fill="#A3A5A7"
  //         onClick={handleClose}
  //       />
  //     </Button>
  //     {!finalStep ? (
  //       <>
  //         <div className="flex flex-col w-[436px] md:w-[298px] h-[144px] items-start gap-[24px] self-stretch">
  //           <div className="flex-[1_0_0] text-[#231F20] text-[22px] md:pt-3 font-['Montserrat'] font-semibold w-fit">
  //             <h4>Confirm Your Command Center Cancellation</h4>
  //           </div>
  //           <div className="w-[411px] self-stretch sm:w-full text-[14px] font-normal font-['Open_Sans'] leading-5">
  //             <p>
  //               You are requesting to cancel your{' '}
  //               {renderPlanName(subscription) === 'Free Forever' ? 'Free ' : ''}Thryv
  //               Command Center plan. By clicking Cancel Plan, you will no longer be
  //               able to login and your Thryv Command Center account data will be
  //               deleted.
  //             </p>
  //           </div>
  //         </div>
  //         {/* <div className="flex flex-col w-[436px] h-[108px] px-4 py-4 items-start gap-[8px] border rounded-[4px] border-solid border-[#E3E6E8] bg-[#F8F9FB]">
  //           <div className="flex flex-col w-[389px] h-[108px] items-start text-[14px] leading-9">
  //             <p>Only 3 connected channels will be available in Inbox.</p>
  //             <p>Only 5 users will be able to access Inbox.</p>
  //             <p>All TeamChat history will be deleted after 30 days.</p>
  //           </div>
  //         </div> */}
  //       </>
  //     ) : (
  //       <div className="mt-[36px] ml-[30px] md:w-[298px] md:-ml-3 flex flex-col w-[380px] h-[286px] justify-start items-center gap-4 ">
  //         <img className="w-[96px] h-[120px]" src={sadGuyThryv} />
  //         <div className="flex w-[379px] h-[150px] items-center sm:w-full mx-[auto]">
  //           <div
  //             className={`${
  //               error ? 'hidden' : 'flex flex-col justify-center items-center'
  //             }`}
  //           >
  //             <div className="w-[308px] text-[22px]  font-semibold font-['Montserrat'] leading-[30px] break-words mt-[16px] mb-[40px]">
  //               <p>We're sorry to see you go!</p>
  //             </div>
  //             <div className="flex flex-col gap-[12] md:-ml-3 font-[400] font-['Open_Sans'] leading-[20px] text-[14px]">
  //               {renderPlanName(subscription) == 'Free Forever' ||
  //               !nextPaymentDate ? (
  //                 <p className="text-center">
  //                   Your Command Center{' '}
  //                   <span className="text-[#057AFF] font-semibold">
  //                     {renderPlanName(subscription)}
  //                   </span>{' '}
  //                   subscription has been scheduled to cancel. Please allow{' '}
  //                   <b>24-48 hours</b> for the cancellation to be processed.{' '}
  //                 </p>
  //               ) : moment(nextPaymentDate).format('MMMM Do, YYYY') ===
  //                 'Invalid date' ? (
  //                 <p className="text-center">
  //                   Your Command Center{' '}
  //                   <span className="text-[#057AFF] font-semibold">
  //                     {renderPlanName(subscription)}
  //                   </span>{' '}
  //                   plan has been scheduled to cancel. After that date, you will no
  //                   longer be able to login or access any data in your Command Center
  //                   Account.
  //                 </p>
  //               ) : (
  //                 <p className="text-center">
  //                   Your Command Center{' '}
  //                   <span className="text-[#057AFF] font-semibold">
  //                     {renderPlanName(subscription)}
  //                   </span>{' '}
  //                   plan has been scheduled to cancel, effective{' '}
  //                   <b>{moment(nextPaymentDate).format('MMMM Do, YYYY')}.</b> After
  //                   that date, you will no longer be able to login or access any data
  //                   in your Command Center Account.
  //                 </p>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //     {error ? (
  //       <p>
  //         There seems to have been an error submitting your request, please try
  //         again.
  //       </p>
  //     ) : null}

  //     {!finalStep ? (
  //       <div className="flex self-stretch mt-12 md:w-[280px] text-sm justify-end gap-[16px]">
  //         <Button
  //           data-testid="close-btn"
  //           variant="text"
  //           level={1}
  //           textVariant="light"
  //           onClick={handleClose}
  //         >
  //           Close
  //         </Button>
  //         <Button
  //           data-testid="cancel-btn"
  //           className="ml-2"
  //           variant="primary"
  //           onClick={() => setfinalStep(true)}
  //         >
  //           Cancel Plan
  //         </Button>
  //       </div>
  //     ) : (
  //       <div
  //         className={`flex self-stretch ${
  //           loading ? 'gap-6' : 'gap-2'
  //         } justify-center items-center gap-[16px]`}
  //       >
  //         {renderFinalCancelButton}
  //       </div>
  //     )}
  //   </div>
  // )
  return (
    <div
      data-testid="cancel-modal"
      className="flex flex-col rounded p-8 justify-center items-center h-[464px] md:w-[298px] "
    >
      <div className="flex gap-8 self-stretch">
        <Button
          data-testid="close-x-btn"
          className="fixed right-[5%] top-[8%] md:top-[5%] md:right-[3%] mr-[0.5rem]"
          variant="text"
          level={2}
          onClick={handleClose}
        >
          <RegularX type="regular" variant="x" height="18" fill="#A3A5A7" />
        </Button>
      </div>
      <div className="flex flex-col w-[380px] items-center gap-6 text-center">
        <div className="">
          <ThryvExclamationIcon />
        </div>
        <div className="flex flex-col w-[380px] gap-6 items-center">
          <div className="w-[380px] font-montserrat text-[22px] font-semibold leading-[30px]">
            Work in progress: <br /> Cancel Plan
          </div>
          <div className="w-[342px] font-open-sans text-[14px] leading-5">
            <p>
              At this time, “Cancel Plan” is a work in progress.
              <br />
              We'll notify you via email and send you a notification
              <br />
              as soon as it's ready. We apologize for any
              <br /> inconvenience and appreciate your patience.
            </p>
            <br />
            <p>
              In the meantime, please contact{' '}
              <span className="text-[#057AFF]">support@thryv.com </span> to cancel
              your Command Center plan. Thank you for understanding!
            </p>
          </div>
        </div>
        <div className="flex items-end justify-center self-stretch">
          <div className="flex px-[24px] py-[9px] justify-center items-center gap-2 bg-[#FF5000] rounded">
            <Button
              data-testid="close-btn"
              variant="text"
              level={1}
              onClick={handleClose}
              className="font-open-sans text-[#FFF] font-semibold text-[13px]"
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
