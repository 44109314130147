import { useSelector, useDispatch } from 'react-redux'
import { setSelectedMatter } from '../../../slices/messageContentSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleX } from '@fortawesome/pro-solid-svg-icons'

export const MattersPill = () => {
  const { selectedMatter } = useSelector((state) => state.messageContent)
  const dispatch = useDispatch()

  return (
    selectedMatter !== 'All' && (
      <div className="flex items-center text-open-sans text-xs text-thryv-cornflower border-thryv-cornflower w-fit border-[1px] rounded-full py-[1px] px-[6px]">
        <span className='px-[2px] pr-[6px]'>{selectedMatter}</span>
        <FontAwesomeIcon onClick={() => {dispatch(setSelectedMatter('All'))}} className="hover:cursor-pointer flex text-thryv-night" icon={faCircleX} size="sm"/>
      </div>
    )
  )
}
