import { gql } from '@apollo/client'

export const GET_DRAFT_DATA_BY_AGENT_ID = gql`
  query get_draft_data_by_Agent_id($agentid: String!) {
    queryMessageDrafts(agent_id: $agentid) {
      items {
        pk1
        sk1
        chanpk
        draftpk
        labelsk
        msgpk
        pinpk
        threadpk
        acct_id
        channels
        conv_id
        body
        email_from {
          email
          name
        }
        email_subject
        email_snippet
        email_to {
          email
          name
        }
        email_cc {
          email
          name
        }
        email_bcc {
          email
          name
        }
        item_type
        link
      }
    }
  }
`
export const GET_PINNED_MESSAGES = gql`
  query get_pinned_messages($threadid: String!) {
    queryMessagesByPinned(thread_id: $threadid) {
      threadpk
      msgpk
      agent_id
      body
      pinpk
      chanpk
      pk1
      email_subject
      email_snippet
      pk1
      sk1
      item_type
      number_from
      number_to
      dm_to
      dm_from
      email_from {
        name
        email
      }
      email_to {
        name
        email
      }
      chan_type
      ts
    }
  }
`

export const GET_S3_UPLOAD_LINK = gql`
  query get_s3_upload_link(
    $content_type: String!
    $threadid: String!
    $filename: String!
  ) {
    getSafeS3UploadURL(
      input: {
        thread_id: $threadid
        content_type: $content_type
        filename: $filename
      }
    ) {
      sk1
      uploadUrl
    }
  }
`

export const GET_S3_DOWNLOAD_LINK = gql`
  query get_s3_download_link($filename: String!) {
    getSafeS3DownloadURL(s3_object_path: $filename)
  }
`

export const FETCH_RECENT_MESSAGES = gql`
  query fetch_recent_messages {
    queryThreadsByLatest(limit: 6) {
      thread_id
      thread_name
      last_read_time
      last_message_time
      last_agent_msg_time
      messages(limit: 1) {
        items {
          SK1
          data
          attachments(limit: 1) {
            items {
              data
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`

export const FETCH_MESSAGE_BY_ID = gql`
  query fetch_message_by_id($pk1: ID!, $sk1: Long!) {
    queryMessageByID(pk1: $pk1, sk1: $sk1) {
      com_ctr_id
      threadpk
      conv_id
      acct_id
      msgpk
      agent_id
      agentpk
      body
      chanpk
      chan_type
      draftpk
      pinpk
      labelsk
      filesk
      link
      vcita_conv_id
      email_subject
      email_snippet
      number_from
      number_to
      is_read
      dm_from
      dm_to
      status
      item_type
      remindsk
      remind_agent
      ts
      is_suppressed
    }
  }
`

export const FETCH_MESSAGES_BY_THREAD_ID = gql`
  query fetch_messages_by_thread_id($threadid: String!, $first: Int) {
    queryMessagesByThread(threadpk: $threadid, first: $first) {
      nextToken
      items {
        agent_id
        pk1
        sk1
        chanpk
        chan_type
        is_suppressed
        draftpk
        dm_from
        number_from
        number_to
        filesk
        status
        labelsk
        msgpk
        pinpk
        threadpk
        acct_id
        channels
        conv_id
        body
        remindsk
        email_snippet
        email_subject
        email_cc {
          name
          email
        }
        email_bcc {
          name
          email
        }
        email_from {
          name
          email
        }
        email_to {
          name
          email
        }
        item_type
        labels {
          body
          sk1
          pk1
        }
        attachments {
          items {
            body
            sk1
          }
          nextToken
        }
        link
      }
      draft {
        agent_id
        pk1
        sk1
        chanpk
        chan_type
        draftpk
        filesk
        labelsk
        msgpk
        pinpk
        threadpk
        acct_id
        channels
        conv_id
        body
        remindsk
        email_snippet
        email_subject
        email_cc {
          name
          email
        }
        email_bcc {
          name
          email
        }
        email_from {
          name
          email
        }
        email_to {
          name
          email
        }
        item_type
        labels {
          body
          sk1
          pk1
        }
        attachments {
          items {
            body
            sk1
            pk1
          }
          nextToken
        }
        link
      }
    }
  }
`

export const FETCH_MESSAGES_WITH_TIME_FILTER = gql`
  query fetch_messages_with_time_filter(
    $threadid: String!
    $time_filter: TableSK1SortInput
  ) {
    queryMyInboxDataByMessagesByThread(
      THREADPK: $threadid
      time_filter: $time_filter
    ) {
      items {
        PK1
        SK1
        CHANPK
        DRAFTPK
        FILESK1
        LABELSK1
        MSGPK
        PINPK
        THREADPK
        acct_id
        channels
        conv_id
        data
        email_from
        email_snippet
        email_to
        item_type
        label
        link
      }
    }
  }
`

export const FETCH_MESSAGE_BY_PRIMARY_KEY = gql`
  query fetch_message_by_primary_key($PK1: ID!, $SK1: Int!) {
    getMyInboxData(PK1: $PK1, SK1: $SK1) {
      PK1
      SK1
      CHANPK
      DRAFTPK
      FILESK1
      LABELSK1
      MSGPK
      PINPK
      THREADPK
      acct_id
      channels
      conv_id
      data
      email_from
      email_snippet
      email_to
      item_type
      label
      link
    }
  }
`

export const FETCH_MESSAGE_BY_NYLAS_ID = gql`
  query fetch_message_by_nylas_id($id: String!) {
    queryMyInboxDataByMessagesByID(MSGPK: $id) {
      items {
        acct_id
        CHANPK
        conv_id
        data
        email_from
        email_snippet
        email_to
      }
    }
  }
`

export const FETCH_DRAFTS_FOR_AGENTS = gql`
  query fetch_drafts_for_agents($agentid: String!) {
    queryMyInboxDataByDrafts(DRAFTPK: $agentid) {
      items {
        PK1
        SK1
        CHANPK
        DRAFTPK
        FILESK1
        LABELSK1
        MSGPK
        PINPK
        THREADPK
        acct_id
        channels
        conv_id
        data
        email_from
        email_snippet
        email_to
        item_type
        label
        link
      }
    }
  }
`

export const FETCH_LABELS_FOR_THREAD = gql`
  query fetch_labels_for_thread($threadid: String!) {
    queryMyInboxDataByLabels(THREADPK: $threadid) {
      items {
        PK1
        SK1
        THREADPK
        CHANPK
        LABELSK1
        label
      }
    }
  }
`

export const FETCH_ATTATCHMENTS_BY_THREAD_ID = gql`
  query fetch_attachments_by_thread_id($threadid: String!) {
    queryInboxAttachmentsByThread(threadpk: $threadid, limit: 100) {
      items {
        pk1
        body
        sk1
        filesk
      }
      nextToken
    }
  }
`

export const FETCH_ATTATCHMENTS_WITH_TIME_RANGE = gql`
  query fetch_attachments_with_time_range(
    $threadid: String!
    $time_filter: TableSK1SortInput
  ) {
    queryInboxAttachmentsByThread(THREADPK: $threadid, time_filter: $time_filter) {
      items {
        PK1
        data
        SK1
      }
    }
  }
`

export const FETCH_THREADS = gql`
  query fetch_threads(
    $sort: String
    $folder: String
    $order: String
    $limit: Int
    $nextToken: String
  ) {
    queryThreads(
      sort: $sort
      limit: $limit
      folder: $folder
      order: $order
      nextToken: $nextToken
    ) {
      items {
        thread_id
        thread_name
        sk1
        read
        last_message_time
        completedsk
        spamsk
        trashsk
        pinsk
        messages(limit: 1) {
          items {
            sk1
            agent_id
            preview
            item_type
            email_snippet
            pk1
            ts
            chan_type
          }
          draft {
            sk1
            body
            email_snippet
            pk1
          }
          nextToken
        }
      }
      nextToken
    }
    pinned: queryThreads(sort: $sort, limit: 100, folder: "pinned", order: $order) {
      items {
        thread_id
        thread_name
        sk1
        read
        last_message_time
        completedsk
        spamsk
        trashsk
        pinsk
        messages(limit: 1) {
          items {
            sk1
            agent_id
            preview
            item_type
            email_snippet
            pk1
            ts
            chan_type
          }
          draft {
            sk1
            body
            email_snippet
            pk1
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const UPDATE_THREAD_READ_TIME = gql`
  mutation update_thread_read_time($thread_ids: [ID!], $read_time: Long!) {
    updateThreadReadTime(thread_ids: $thread_ids, read_time: $read_time)
  }
`

export const GET_THUMBNAIL = gql`
  query get_thumbnail($filename: String!) {
    getThumbnail(s3_object_path: $filename)
  }
`

export const GET_ALL_THUMBNAIL = gql`
  query get_all_thumbnails($files: [String!]!) {
    queryAttachments(files: $files, type: "thumbnail")
  }
`

export const GET_USER_PREFERENCES = gql`
  query get_user_preferences($userId: String!) {
    queryUserPreferences(userId: $userId) {
      Platform
      Values
    }
  }
`
