import { forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  FETCH_THREADS,
  REMOVE_THREAD_FIELD,
  UPDATE_THREAD_FIELD,
} from '../../../../../../../../graphql'
import { useInboxMenuConversations } from '../../../../../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { useMediaQuery } from 'react-responsive'
import { toggleAttachmentsModal } from './modalSlice'
import {
  setSelectedThreadTrash,
  setSelectedThread,
  setShowInboxNotification,
} from '../../../../../../slices/inboxThreadsSlice'
// Components
import { TransitionContainer } from '../../../../../../../common/transition-container'
import { MenuButton } from '../menu-button'
import { ModalWrapper } from '@thryvlabs/maverick'
import { useNavigate } from 'react-router-dom'
import MobileSharedMedia from '../../../../../../../right-panel/mobile/MobileSharedMedia'

export const TriDotMenu = forwardRef(
  ({ show, className, onButtonClick }, menuRef) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [addThreadToSpam] = useMutation(UPDATE_THREAD_FIELD)
    const [removeThreadFromSpam] = useMutation(REMOVE_THREAD_FIELD)

    const [addThreadToTrash] = useMutation(UPDATE_THREAD_FIELD)
    const [removeThreadFromTrash] = useMutation(REMOVE_THREAD_FIELD)

    const {
      selectedThreadTrash,
      selectedThreadSpam,
      inboxThreadSk1s,
      inboxPinnedThreadSk1s,
    } = useSelector((state) => state.inboxThreads)
    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )

    const threads = [...inboxThreadSk1s, ...inboxPinnedThreadSk1s]
    const sk1 = [threads.filter((x) => x.id === selectedThreadId)[0]?.sk1]
    const { createVariables, initMessageThreads, initPinnedMessageThreads } =
      useInboxMenuConversations()

    const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      variables: createVariables(),
      onCompleted: () => {
        const threads = threadsData?.queryThreads.items
        initMessageThreads(threads)
        const pinned = threadsData?.pinned?.items
        initPinnedMessageThreads(pinned)
      },
    })

    const onSpamClick = async () => {
      const THREAD_IS_SPAM = selectedThreadSpam

      const MUTATION_OBJECT = {
        variables: { sk1s: sk1, field: 'spamsk' },
        onCompleted: () => {
          getThreads()
        },
      }

      if (THREAD_IS_SPAM) removeThreadFromSpam(MUTATION_OBJECT)
      else addThreadToSpam(MUTATION_OBJECT)

      onButtonClick()
    }

    const onTrashClick = async () => {
      const THREAD_IS_TRASH = selectedThreadTrash

      const MUTATION_OBJECT = {
        variables: { sk1s: sk1, field: 'trashsk' },
        onCompleted: () => {
          dispatch(setSelectedThreadTrash(true))
          getThreads()
          dispatch(setShowInboxNotification(true))
          dispatch(setSelectedThread({ id: '', name: '' }))
        },
      }

      if (THREAD_IS_TRASH) removeThreadFromTrash(MUTATION_OBJECT)
      else addThreadToTrash(MUTATION_OBJECT)

      onButtonClick()
    }

    const handleOpenModal = () => {
      dispatch(toggleAttachmentsModal())
    }

    const handleCloseModal = () => {
      dispatch(toggleAttachmentsModal(false))
    }

    const isModalOpen = useSelector(
      (state) => state.modal?.isAttachmentsModalOpen || false,
    )

    const isMobile = useMediaQuery({ maxWidth: 835 })

    return (
      <TransitionContainer
        show={show}
        className={`${className} flex flex-col p-2 !w-[200px]`}
        as="ul"
        transformOrigin="top right"
        unmount={false}
        ref={menuRef}
      >
        {/* Temporarily removing: https://app.asana.com/0/0/1204162713457349/f */}
        {/* <MenuButton mavIconVariant="bell">Notifications</MenuButton> */}

        {/* <ExportButtonWithMenu
          onPdfClick={buttonHandlers.onPdfClick}
          onCsvClick={buttonHandlers.onCsvClick}
        >
          Export
        </ExportButtonWithMenu> */}

        {/* Not present in design: https://tinyurl.com/3t57fpz6 */}
        {/* <MenuButton ccIconVariant="paperClip">Attachment history</MenuButton> */}

        <MenuButton mavIconVariant="circleExclamation" onClick={onSpamClick}>
          {selectedThreadSpam ? 'Remove from spam' : 'Mark as spam'}
        </MenuButton>

        <MenuButton mavIconVariant="altTrash" onClick={onTrashClick}>
          {selectedThreadTrash ? 'Remove from trash' : 'Move to trash'}
        </MenuButton>
        {isMobile && (
          <>
            <MenuButton ccIconVariant="paperClip" onClick={handleOpenModal}>
              {selectedThreadTrash ? 'Attachments' : 'Attachments'}
            </MenuButton>
            <MenuButton
              mavIconVariant="user"
              onClick={() => navigate({ pathname: '/inbox/contact' })}
            >
              {selectedThreadTrash ? 'Contact' : 'Contact'}
            </MenuButton>
          </>
        )}
        <ModalWrapper
          show={isModalOpen}
          close={() => {
            if (!isMobile) {
              handleCloseModal()
            }
          }}
        >
          <MobileSharedMedia handleCloseModal={handleCloseModal} />
        </ModalWrapper>
      </TransitionContainer>
    )
  },
)
