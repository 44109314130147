import { getRecentSearchesFromCookies } from '../../../utils'

// Components
import { TransitionContainer } from '../../../../../../common/transition-container'
import { Button } from '@thryvlabs/maverick'
import {
  TopContactsSection,
  RecentSearchesSection,
  // SuggestedSearchesSection,
} from '../../../sections/search-section'

const HorizontalLine = ({ show }) => (
  <>{show ? <hr className="my-2 mx-4 text-thryv-gray-light-400" /> : null}</>
)

// FIXME: Suggested searches temporarily commented out
export const ContactsAndSearchesMenu = ({
  className,
  show,
  topContactsOptions,
  textToEmphasize,
  onSearchAllClick,
  onRecentSearchClick,
  onContactRowClick,
}) => {
  const recentSearches = getRecentSearchesFromCookies()
  // const suggestedSearches = [
  //   'prefer bluish gray',
  //   'look kind of fluish',
  //   'prefer bluish gray',
  // ]

  const showRecentSearches = !textToEmphasize.length && recentSearches.length
  // const showSuggestedSearches = suggestedSearches.length && textToEmphasize.length
  const showTopContacts = topContactsOptions.length
  const showMainContainer = showRecentSearches || showTopContacts

  return (
    <TransitionContainer
      className={`
        ${className} 
        border border-thryv-gray-light-400 
        ${!showMainContainer && '!opacity-0'}
       `}
      show={show}
      transformOrigin={'top center'}
    >
      <div className={`py-4 ${!showMainContainer && '!py-0'}`}>
        <TopContactsSection
          show={showTopContacts}
          contacts={topContactsOptions}
          textToEmphasize={textToEmphasize}
          onContactRowClick={onContactRowClick}
        />

        <HorizontalLine
          show={
            showRecentSearches && showTopContacts
            // || (showSuggestedSearches && showTopContacts)
          }
        />

        <RecentSearchesSection
          show={showRecentSearches}
          recentSearches={recentSearches}
          onRowClick={onRecentSearchClick}
        />

        {/* <SuggestedSearchesSection
          show={textToEmphasize.length}
          suggestedSearches={suggestedSearches}
        /> */}

        <HorizontalLine show={textToEmphasize.length && showTopContacts} />

        {textToEmphasize ? (
          <Button
            variant="text"
            level={1}
            className="px-6"
            onClick={onSearchAllClick}
          >
            Search All
          </Button>
        ) : null}
      </div>
    </TransitionContainer>
  )
}
