import { useAuth0 } from '@auth0/auth0-react';

const ThryvLeads = () => {
  const { user } = useAuth0();
  const thryvId = user.businessId;
  let thryvLeadsUrl = `${import.meta.env.VITE_THRYV_LEADS_IFRAME_URL}?thryvId=${thryvId}`
  if (import.meta.env.VITE_ENV !== 'prod') thryvLeadsUrl += "&isInternalUser=true"
  return (
    <div>
      <iframe
        style={{ height: 'calc(100vh - 66.18px' }}
        width="100%"
        src={thryvLeadsUrl}
      />
    </div>
  )
}
export default ThryvLeads