export const STAFF_PERMISSIONS = {
  'Read / Write': 2,
  'Read Only': 1,
  'No Access': 0,
}

export const STAFF_PERMISSIONS_INT = {
  2: 'Read / Write',
  1: 'Read Only',
  0: 'No Access',
}
