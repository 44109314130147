import { Routes, Route } from 'react-router'
import { LoginRedirectApp } from './login-redirect-app'
import SignUp from '../sign-up/SignUp'
import EmailVerified from './email-verified'
import { RequestDeletion } from '../../modules/settings/RequestDeletion'

export function UnauthenticatedApp() {
  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/email-verified" element={<EmailVerified />} />
      <Route path="/request-deletion" element={<RequestDeletion />} />
      <Route path="*" element={<LoginRedirectApp />} />
    </Routes>
  )
}
