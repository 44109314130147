import { useRef } from 'react'
import { withModal } from '@thryvlabs/maverick'
import { ResetPasswordSentModal as ResetPasswordSentModalComponent } from './reset-password-sent-modal'

/*
###########################
##     EXAMPLE USAGE     ##
###########################

  ----- Needed in code in order to toggle reset password sent Modal -----

  const {
    toggleResetPasswordSentModalRef,
    ResetPasswordSentModal,
  } = useResetPasswordSentModal()

  ...

  <ResetPasswordSentModal
    modalProps={{
      firstName: firstName,
    }}
  />

  Use the above modal in your code
*/

export const useResetPasswordSentModal = () => {
  const toggleResetPasswordSentModalRef = useRef(null)

  const HiddenButton = ({ showModal }) => {
    toggleResetPasswordSentModalRef.current = showModal
    return <div className="hidden">Hidden Reset Password Sent Modal Button</div>
  }

  const ResetPasswordSentModal = withModal(
    HiddenButton,
    ResetPasswordSentModalComponent,
  )

  return {
    ResetPasswordSentModal: ResetPasswordSentModal,
    toggleResetPasswordSentModalRef: toggleResetPasswordSentModalRef,
  }
}
