import './thumbtack-button-with-modal.css'
import { ParagraphText, Button, Icon, ModalWrapper } from '@thryvlabs/maverick'
import RoundedActionButton from '../../../../../../../common/rounded-action-button'
import { DELETE_MESSAGE_PIN } from '../../../../../../../../../graphql/mutations/inbox-message-mutations'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const Modal = ({
  emailCard,
  message,
  refetchPins,
  messageContentRef,
  refetchMessages,
  openModal,
  setOpenModal,
  setShowDropdown,
}) => {
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  const [delete_message_pin] = useMutation(DELETE_MESSAGE_PIN, {
    onCompleted: () => {
      setOpenModal(false)
    },
    refetchQueries: [],
  })

  const recordScrollPosition = () => {
    if (messageContentRef?.current) {
      const scrollTop = messageContentRef?.current.scrollTop
      sessionStorage.setItem('scrollPosition', scrollTop)
    }
  }

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition')
    if (scrollPosition && messageContentRef?.current) {
      messageContentRef.current.scrollTop = parseInt(scrollPosition)
    }
  }

  const handleRemovePin = () => {
    recordScrollPosition()

    delete_message_pin({
      variables: {
        channelid: message.pk1?.split('#')[1],
        threadid: selectedThreadId,
        sk1: message.sk1,
      },
    }).then(() => {
      refetchPins({ threadid: selectedThreadId })
      refetchMessages()
      handleScrollPosition()
      setShowDropdown(false)
    })
  }

  return (
    <ModalWrapper show={openModal} close={() => setOpenModal(false)}>
      <div className="text-left">
        <div className="flex justify-between mb-5">
          <h2 className="font-[Montserrat] font-semibold text-[22px] ">
            Remove pinned message
          </h2>
          <button type="button" onClick={() => setOpenModal(false)}>
            <Icon
              type="regular"
              variant="x"
              color="#A3A5A7"
              height="18px"
              width="18px"
            />
          </button>
        </div>

        <ParagraphText variant="reg" className="mb-4">
          Are you sure you want to remove this pinned item?
        </ParagraphText>

        <div className="mb-6">{emailCard}</div>
        <div className="flex justify-end">
          <Button
            variant="text"
            level={2}
            textVariant="light"
            className="px-4"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleRemovePin()
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

const OtherButton = ({ setOpenModal }) => {
  return (
    <RoundedActionButton
      className="!w-[28px] !h-[28px]"
      icon={{
        variant: 'altThumbtack',
        type: 'solid',
        width: '12',
        height: '16',
        color: '#5378FC',
        hoverColor: '#5378FC',
      }}
      onClick={() => {
        setOpenModal(true)
      }}
      isCCIcon
      tooltipTitle="Unpin"
    />
  )
}

export const ThumbtackButtonWithModal = ({
  emailCard,
  message,
  refetchPins,
  messageContentRef,
  refetchMessages,
  setShowDropdown,
}) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <OtherButton setOpenModal={setOpenModal} />

      <Modal
        openModal={openModal}
        emailCard={emailCard}
        setOpenModal={setOpenModal}
        setShowDropdown={setShowDropdown}
        refetchPins={refetchPins}
        messageContentRef={messageContentRef}
        refetchMessages={refetchMessages}
        message={message}
      />
    </>
  )
}
