import { ModalWrapper } from '@thryvlabs/maverick'
import FreemiumStaffUpdate from './FreemiumStaffUpdate'
import PaidStaffUpdateModal from './PaidStaffUpdate'
import { useEffect, useState } from 'react'

const StaffSeatChangeModal = ({ planName, loading }) => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    let seenStaffUpdateModal = sessionStorage.getItem('seenStaffUpdateModal')
    setShowModal(!seenStaffUpdateModal)
  }, [])

  const handleCloseStaffChangeModal = () => {
    sessionStorage.setItem('seenStaffUpdateModal', true)
    setShowModal(false)
  }

  if (loading) {
    return null
  }

  return (
    <ModalWrapper
      show={showModal}
      close={handleCloseStaffChangeModal}
      disableBackdropClick={false}
    >
      {planName === 'Command Center Basic' ? (
        <FreemiumStaffUpdate
          handleCloseStaffChangeModal={handleCloseStaffChangeModal}
        />
      ) : (
        <PaidStaffUpdateModal
          handleCloseStaffChangeModal={handleCloseStaffChangeModal}
        />
      )}
    </ModalWrapper>
  )
}

export default StaffSeatChangeModal
