/* eslint-disable no-console */
import { parsePhoneNumber } from 'libphonenumber-js'
import phoneFormatter from '../../../utils/phoneFormatter'

const formatContactData = (data) => {
  if (/^\+?\d+$/.test(data)) {
    const ausNoCountryCodeRegex = /^\d{9,10}$/
    const auCountryCodeRegex = /^\d{11,12}$/
    if (
      data.length === 9 ||
      (data.length === 10 && data[0] === '0' && ausNoCountryCodeRegex.test(data))
    ) {
      try {
        return '+61 ' + parsePhoneNumber(data).formatNational()
      } catch (error) {
        return parsePhoneNumber('+61 ' + data).formatNational()
      }
    } else if (
      (data.length === 11 || data.length === 12) &&
      auCountryCodeRegex.test(data) &&
      data[0] === '6' &&
      data[1] === '1'
    ) {
      try {
        return '+61 ' + parsePhoneNumber('+' + data).formatNational()
      } catch (error) {
        console.error(error)
        return data
      }
    }

    if (phoneFormatter(data).includes('undefined')) {
      if (data.includes('+') && data[1] === '1') {
        try {
          return '+1 ' + parsePhoneNumber(data).formatNational()
        } catch (error) {
          console.error(error)
          return data
        }
      }
      if (data.includes('+')) {
        try {
          return parsePhoneNumber(data).formatInternational()
        } catch (error) {
          console.error(data)
          return data
        }
      } else {
        try {
          return parsePhoneNumber('+' + data).formatInternational()
        } catch (error) {
          console.error(data)
          return data
        }
      }
    } else {
      try {
        return phoneFormatter(data)
      } catch (error) {
        console.error(error)
        return data
      }
    }
  } else {
    return data
  }
}

export default formatContactData
