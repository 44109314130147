export const getTypeObj = (type, optionTypes) => {
  if (!type) {
    return { name: '', value: '' }
  }
  if (typeof type !== 'object') {
    return optionTypes?.find((option) => option.value === type)
  }

  return type
}
