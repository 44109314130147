import { scrollToSelectedThread } from './scrollToSelectedThread'

const createUpdatedListItem = ({
  contact,
  searchTerm,
  className,
  onContactClick,
}) => {
  const { full_name, contactsk1, contactsk2, contactsk3 } = contact

  const threadTitle =
    (contact.full_name && contact.full_name) ||
    (contactsk2 && contactsk2) ||
    (contactsk3 && contactsk3)

  const newContact = {
    className: className,
    user: {
      name: full_name || '',
      email: contactsk2 || '',
      contact,
      iconUrl: '',
      phoneNumber: contactsk3 || '',
    },
    textToEmphasize: searchTerm,
    onCardClick: () => {
      scrollToSelectedThread(contactsk1)

      onContactClick({
        threadId: contactsk1,
        threadTitle: threadTitle,
        openRightPanel: true,
      })
    },
  }

  return newContact
}

export const createContactsListForPagination = ({
  list,
  searchTerm,
  className = '',
  onContactClick,
}) => {
  const updatedList = list.map((contact) =>
    createUpdatedListItem({ contact, searchTerm, className, onContactClick }),
  )

  return updatedList
}
