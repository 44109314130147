import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'
import { AuthLoader } from './auth-loader'

export function LoginRedirectApp() {
  const { loginWithRedirect } = useAuth0()

  const login = useCallback(async () => {
    const { search, pathname } = window.location
    const returnTo = `${pathname}${search}`
    await loginWithRedirect({ appState: { returnTo } })
  }, [loginWithRedirect])

  useEffect(() => {
    login()
  }, [login])

  return <AuthLoader />
}
