import styled from 'styled-components'
import { Button } from '@thryvlabs/maverick'

export const PlanCardBox = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: 28px;
  border-radius: 15px;
  border: 2px solid #4761c3;
  width: 310px;
  justify-content: space-between;
  margin-right: 18px;
  padding-bottom: 40px;
  height: 550px;

  @media screen and (max-width: 500px) {
    width: 240px;
    height: 432px;

    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`

export const ButtonPlanCard = styled(Button)`
  width: 144px;
  height: 44px;
  border-radius: 22px;

  color: ${(props) => (props.active ? '#fff' : '#fff')};
  border: 1px solid ${(props) => props.color || '#000'};
  background-color: ${(props) => (props.active ? '#4761C3' : props.color)};

  &:hover {
    background-color: ${(props) => (props.active ? props.color : '#fff')};
    color: ${(props) => (props.active ? '#fff' : '#000')};
  }

  @media screen and (min-width: 360px) and (max-width: 640px) {
    width: 100px;
    height: 35px;
    font-size: 11px;
  }
`
