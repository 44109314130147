import { useState } from 'react'
import { useSelector } from 'react-redux'

// Components
import { LabelTagWithDropdown, PinTagWithDropdown } from './tags'
import { ContactComposeSubheader } from './contact-compose-subheader'

export const MessageSubheader = ({
  pinnedMessages,
  refetchPins,
  refetchMessages,
  messageContentRef,
  labels,
  onLabelChange,
  labelsClear,
  onClearClick,
  labelsSelected,
}) => {
  const [visibleTagDropdown, setVisibleTagDropdown] = useState('')
  const { startNewMessage } = useSelector((state) => state.inbox)
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  const handlePinDropdown = (isVisible) => {
    if (isVisible === false) setVisibleTagDropdown('')
    else setVisibleTagDropdown('pin')
  }

  const handleLabelDropdown = (isVisible) => {
    if (isVisible === false) setVisibleTagDropdown('')
    else setVisibleTagDropdown('label')
  }

  return (
    <div className="relative px-[30px] py-2.5 border-t border-b border-[#F1F1F5] border-solid flex flex-row space-x-4 bg-[white] h-[49px] items-center">
      {/* ----- SUBHEADER FOR MESSAGE COMPOSING ----- */}
      {startNewMessage ? <ContactComposeSubheader /> : null}

      {/* ----- SUBHEADER FOR WHEN MESSAGES ARE IN VIEW ----- */}
      {!startNewMessage && (pinnedMessages?.length > 0 || labels.length > 0) ? (
        <div className="flex gap-4">
          {selectedThreadId && pinnedMessages?.length ? (
            <div>
              <PinTagWithDropdown
                messages={pinnedMessages}
                refetchPins={refetchPins}
                messageContentRef={messageContentRef}
                refetchMessages={refetchMessages}
                showDropdown={visibleTagDropdown === 'pin'}
                setShowDropdown={handlePinDropdown}
              >
                {pinnedMessages.length} Pinned
              </PinTagWithDropdown>
            </div>
          ) : null}

          {selectedThreadId && labels?.length ? (
            <div>
              <LabelTagWithDropdown
                id="label-message-button"
                showDropdown={visibleTagDropdown === 'label'}
                setShowDropdown={handleLabelDropdown}
                labels={labels}
                onLabelChange={onLabelChange}
                onClearClick={onClearClick}
                labelsClear={labelsClear}
                labelsSelected={labelsSelected}
              >
                {labels.length} Labels
              </LabelTagWithDropdown>
            </div>
          ) : null}
        </div>
      ) : null}

      {/* Commenting out matters feature for now */}
      {/* {hasBusinessCenter && !startNewMessage ? <MattersDropdown /> : null} */}
    </div>
  )
}
