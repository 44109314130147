/* eslint-disable no-unused-vars */
import useAvatarComponent from '../../../hooks/useAvatarComponent'
import formatContactData from '../../inbox/hooks/formatContactData'

const ITEM_TO_REMOVE_STYLES = `font-open-sans text-sm text-[#808080]`

const UpdateContactModalContent = ({
  changeType,
  data,
  contactName,
  contactPicture = '',
}) => {
  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'small',
    picture_url: contactPicture,
    contactName:
      !contactName?.includes('undefined') || !contactName?.includes('unknown')
        ? contactName
        : null,
  })

  const renderData = changeType === 'email' ? data : formatContactData(data)

  const renderEmailToRemove =
    changeType === 'email' ? (
      <div className="flex gap-4 items-center justify-start">
        <span className={ITEM_TO_REMOVE_STYLES}>Email to remove: </span>
        <span className="text-[#4D4D4D] font-open-sans text-sm">{renderData}</span>
      </div>
    ) : null

  const renderPhoneToRemove =
    changeType === 'phone' ? (
      <div className="flex gap-4 items-center justify-start">
        <span className={ITEM_TO_REMOVE_STYLES}>Number to remove: </span>
        <span className="text-[#4D4D4D] font-open-sans text-sm">{renderData}</span>
      </div>
    ) : null
  return (
    <div className="w-full">
      <div className="w-full flex p-2 items-start bg-[#FFF9EA] border border-[#FDDF95] rounded mb-[44px]">
        <span>
          <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2970_10801)">
              <path
                d="M7.99982 3C8.44357 3 8.85295 3.23437 9.07795 3.61875L15.8279 15.1187C16.0561 15.5062 16.0561 15.9844 15.8342 16.3719C15.6123 16.7594 15.1967 17 14.7498 17H1.24982C0.802948 17 0.387323 16.7594 0.165448 16.3719C-0.0564269 15.9844 -0.0533019 15.5031 0.171698 15.1187L6.9217 3.61875C7.1467 3.23437 7.55607 3 7.99982 3ZM7.99982 7C7.5842 7 7.24982 7.33437 7.24982 7.75V11.25C7.24982 11.6656 7.5842 12 7.99982 12C8.41545 12 8.74982 11.6656 8.74982 11.25V7.75C8.74982 7.33437 8.41545 7 7.99982 7ZM8.99982 14C8.99982 13.4469 8.55295 13 7.99982 13C7.4467 13 6.99982 13.4469 6.99982 14C6.99982 14.5531 7.4467 15 7.99982 15C8.55295 15 8.99982 14.5531 8.99982 14Z"
                fill="#FAC02B"
              />
            </g>
            <defs>
              <clipPath id="clip0_2970_10801">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 2)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
        <div className="font-open-sans text-thryv-black-700 font-normal text-sm ml-4">
          Removing this contact's info will result in the loss of all messages in
          this conversation with {renderData}.
        </div>
      </div>
      <div className="mb-14">
        <div className="w-full gap-3 flex items-center mb-4">
          <span className="w-[30px]">{/* <RenderUserProfile /> */}</span>
          <span className="font-open-sans text-base text-thryv-black-500">
            {contactName}
          </span>
        </div>
        {renderEmailToRemove}
        {renderPhoneToRemove}
      </div>
    </div>
  )
}

export default UpdateContactModalContent
