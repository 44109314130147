import { useAuth0 } from '@auth0/auth0-react'
import { Icon, Avatar } from '@thryvlabs/maverick'
import { StyledToggleOn, StyledToggleOff } from './TopNavProfileDropdown.styles'
import avatarColorPicker from '../../utils/avatarColorPicker'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PREFERENCES } from '../../graphql/mutations/user-preferences-mutation'
import { setDoNotDisturbPreferences } from '../../redux-toolkit/slices/user-preferences/user-preferences'
import axios from 'axios'

const TopNavProfileDropdown = ({
  isOnline,
  handleToggleOnlineStatus,
  setDropdownActive,
}) => {
  const { logout, user, getAccessTokenSilently } = useAuth0()
  const { fname, lname, email } = useSelector((state) => state.user)
  const preferenceData = useSelector((state) => state.userPreferences)
  const { doNotDisturb } = useSelector(
    (state) => state.userPreferences.notificationPreferences,
  )
  const dispatch = useDispatch()
  const isMounted = useRef(false)
  const { VITE_NYLAS_HOST } = import.meta.env
  const { vonageClient } = useSelector((state) => state.calls)

  async function delSession() {
    try {
      await axios.delete(
        'https://api.nexmo.com/v1/sessions/' + vonageClient?.session_id,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + vonageClient?.jwt_sub,
          },
        },
      )
      return
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Fetch error:', err)
    }
  }

  const deregisterKnock = async () => {
    const androidToken = localStorage.getItem('FCM_TOKEN')
    const iosToken = localStorage.getItem('APNS_TOKEN')
    const mobileToken = androidToken || iosToken
    const accessToken = await getAccessTokenSilently()
    let deviceType = ''

    if (androidToken) {
      deviceType = 'android'
    }

    if (iosToken) {
      deviceType = 'ios'
    }

    if (mobileToken && accessToken) {
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      try {
        return axios.post(
          `${VITE_NYLAS_HOST}/remove_knock_user`,
          {
            token: mobileToken,
            user_id: user.cc_uid,
            device: deviceType,
          },
          options,
        )
      } catch (err) {
        //
      }
    }
  }

  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES)

  const renderImageAvatar = !user?.picture?.includes('auth0') ? (
    <Avatar
      variant="image"
      size="default"
      imageUrl={user.picture}
      className="h-[70px] w-[70px] sm:hidden"
    />
  ) : null

  const renderNamedAvatar = user?.picture?.includes('auth0') ? (
    <Avatar
      variant="name"
      size="default"
      staticBackground
      backgroundColor={avatarColorPicker(lname)}
      name={{
        firstName: fname?.toUpperCase() || '',
        lastName: lname?.toUpperCase() || '',
      }}
      className="h-[70px] w-[70px] sm:hidden"
    />
  ) : null

  useEffect(() => {
    if (isMounted.current) {
      const updatedConfig = {
        navigationPreferences: preferenceData.navigationPreferences,
        navigationCollapsed: preferenceData.navigationCollapsed,
        notificationPreferences: {
          doNotDisturb: doNotDisturb,
          desktop: preferenceData.notificationPreferences.desktop,
          mobile: preferenceData.notificationPreferences.mobile,
          selectedConversationMobile:
            preferenceData.notificationPreferences.selectedConversationMobile,
          selectedConversationDesktop:
            preferenceData.notificationPreferences.selectedConversationDesktop,
          selectedTeamChatDesktop:
            preferenceData.notificationPreferences.selectedTeamChatDesktop,
          selectedTeamChatMobile:
            preferenceData.notificationPreferences.selectedTeamChatMobile,
        },
      }
      updateUserPreferences({
        variables: {
          userId: user.email,
          preferences: JSON.stringify({
            time_stamp: Date.now(),
            data: updatedConfig,
          }),
        },
      })
    }
    isMounted.current = true
  }, [doNotDisturb])

  const renderuserEmail = email ? (
    <h6 className="text-[12px] font-semibold leading-[18px] font-primary text-[#808080] truncate sm:pb-[8px]">
      {email}
    </h6>
  ) : null

  const renderMockDataEmail = !user ? (
    <h6 className="text-[12px] font-semibold leading-[18px] font-primary text-[#808080] truncate sm:pb-[8px]">
      Unknown
    </h6>
  ) : null

  return (
    <>
      <div className=" bg-[#FFFFFF] rounded-[4px] drop-shadow pt-[16px] px-[16px] pb-[8px] absolute top-[63px] md:top-[47px] right-[-24px] md:right-[-14px] z-50 sm:min-w-[216px] max-w-[365px]">
        <div className="flex gap-[12px] ">
          {renderImageAvatar}
          {renderNamedAvatar}
          <div className="gap-[5px] sm:gap-[2px] flex flex-col max-w-[251px] h-fit sm:h-auto">
            <h4 className="font-semibold leading-[22px] font-primary truncate">
              {fname && lname ? fname + ' ' + lname : 'Unknown'}
            </h4>
            {renderuserEmail}
            {renderMockDataEmail}
            <div className=" sm:px-[16px] hidden sm:flex sm:border-t-[1px]  border-[#F0F0F0]"></div>
          </div>
        </div>
        <div className="flex flex-col gap-[9px] sm:py-2 pt-[15px] pl-[10px]">
          {isOnline ? (
            <div className="flex gap-[8px] sm:pt-[10px]">
              <StyledToggleOn
                data-testid="status-toggle"
                toggleSize="small"
                onChange={handleToggleOnlineStatus}
                toggled={isOnline}
              />
              <h6 className="text-[12px] sm:text-[14px] font-semibold leading-[18px] text-[#808080] font-primary">
                Online
              </h6>
            </div>
          ) : (
            <div className="flex gap-[8px] sm:pt-[10px]">
              <StyledToggleOff
                data-testid="status-toggle"
                toggleSize="small"
                onChange={handleToggleOnlineStatus}
                toggled={isOnline}
              />
              <h6 className="text-[12px] sm:text-[14px] font-semibold leading-[18px] text-[#808080] font-primary">
                Offline
              </h6>
            </div>
          )}

          {doNotDisturb ? (
            <div className="flex gap-[8px] sm:pt-[10px]">
              <StyledToggleOn
                data-testid="dnd-toggle"
                toggleSize="small"
                onChange={() => {
                  dispatch(setDoNotDisturbPreferences(!doNotDisturb))
                }}
                toggled={doNotDisturb}
              />
              <h6 className="text-[12px] sm:text-[14px] font-semibold leading-[18px] text-[#808080] font-primary">
                Do not disturb
              </h6>
            </div>
          ) : (
            <div className="flex gap-[8px] sm:pt-[10px]">
              <StyledToggleOff
                data-testid="dnd-toggle"
                toggleSize="small"
                onChange={async () => {
                  dispatch(setDoNotDisturbPreferences(!doNotDisturb))
                  await delSession()
                }}
                toggled={doNotDisturb}
              />
              <h6 className="text-[12px] sm:text-[14px] font-semibold leading-[18px] text-[#808080] font-primary">
                Do not disturb
              </h6>
            </div>
          )}
        </div>
        <div className="px-[16px] h-[1px] bg-[#F0F0F0] mt-[16px] sm:hidden"></div>
        <div className="hidden sm:flex sm:px-[16px] sm:border-b-[1px] border-[#F0F0F0]"></div>
        <div className="flex flex-col pt-[5px]">
          <Link
            to="/settings/my-account/personal-information"
            onClick={() => {
              setDropdownActive(false)
            }}
            className="flex gap-[8px] pl-[8px] rounded-[4px] h-[30px] sm:h-[38px] items-center text-[#808080] hover:bg-[#eceef1] hover:cursor-pointer hover:text-[#4D4D4D]"
          >
            <Icon
              type="regular"
              variant="user"
              color="#808080"
              height="16px"
              width="16px"
            />
            <h6 className="text-[12px] sm:text-[14px] font-semibold leading-[18px] font-primary">
              My Account
            </h6>
          </Link>
          <div
            onClick={async () => {
              localStorage.removeItem('inbox-selected-thread')
              await delSession()
              await deregisterKnock()
              logout({ logoutParams: { returnTo: window.location.origin } })
            }}
            className="flex gap-[8px] pl-[8px] rounded-[4px] h-[30px] sm:h-[38px] items-center text-[#808080] hover:bg-[#eceef1] hover:cursor-pointer hover:text-[#4D4D4D]"
          >
            <Icon
              type="regular"
              variant="signOut"
              color="#808080"
              height="12px"
              width="16px"
            />
            <h6 className="text-[12px] sm:text-[14px] font-semibold leading-[18px]  font-primary">
              Sign Out
            </h6>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopNavProfileDropdown
