import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
// import { ButtonsData } from '../../../welcome-screen/buttonsData'
const { VITE_AUTH0_REDIRECT_URI, VITE_NYLAS_HOST } = import.meta.env
import { useFacebookAuth } from '../../../../hooks/use-facebook-auth'
import { useOffice365Auth } from '../../../../hooks/use-office365-auth'

export const connectChannelsSlice = createSlice({
  name: 'connectChannels',
  initialState: {
    connectionLoading: false,
    modalContent: 1,
    showModal: false,
    showFirstChannelModal: false,
    disableBackdropClick: false,
    showWelcomeChannelModal: false,
    cardData: [],
    channelIsConnected: false,
    channelInfo: [], //push active channels in here
    maxChannelsReached: false,
    channelDisconnecting: '',
    channelDisconnected: [],
    showChannelDisconnectedNotification: false,
    showChannelDisconnectingNotification: false,
    maxChannelsAllowed: '',
    connectedChannelInfo: '',
    maxChannelsAllowedLoading: true,
    channelConnectedToAnotherCC: false,
    connectionError: false,
    meta400Error: '',
    metaUserData: {},
    selectedCardData: {
      access_token: '',
      name: '',
      email: '',
      selectedPageUserAccessToken: '',
    },
  },
  reducers: {
    setLoading(state, action) {
      state.connectionLoading = action.payload
    },
    setMaxChannelsAllowed(state, action) {
      state.maxChannelsAllowed = action.payload
    },
    setMaxChannelsAllowedLoading(state, action) {
      state.maxChannelsAllowedLoading = action.payload
    },
    setMaxChannelsReached(state, action) {
      state.maxChannelsReached = action.payload
    },
    setChanelInfo(state, action) {
      //this sets the channels connected across the app
      state.channelInfo = action.payload
    },
    setChannelDisconnecting(state, action) {
      state.channelDisconnecting = action.payload
      state.showChannelDisconnectingNotification =
        action.payload !== undefined || action.payload !== null ? true : false
    },
    setChannelDisconnected(state, action) {
      state.channelDisconnected = action.payload
      state.showChannelDisconnectedNotification =
        action.payload?.length > 0 ? true : false
    },
    handleHideDisconnectedChannelsNotification(state, action) {
      state.showChannelDisconnectedNotification = action.payload
    },
    handleHideDisconnectingChannelNotification(state, action) {
      state.showChannelDisconnectingNotification = action.payload
    },
    setModalContent(state, action) {
      state.modalContent = action.payload
    },
    setShowFirstChannelModal(state, action) {
      state.showFirstChannelModal = action.payload
    },
    setShowWelcomeChannelModal(state, action) {
      state.showWelcomeChannelModal = action.payload
    },
    setShowModal(state, action) {
      state.showModal = action.payload
    },
    setChannelIsConnected(state, action) {
      state.channelIsConnected = action.payload
    },
    setConnectedChannelInfo(state, action) {
      state.connectedChannelInfo = action.payload
    },
    setChannelConnectedToAnotherCC(state, action) {
      state.channelConnectedToAnotherCC = action.payload
    },
    setConnectionError(state, action) {
      state.connectionError = action.payload
    },
    setMeta400Error(state, action) {
      state.meta400Error = action.payload
    },
    setNoWebsiteToConnectError(state, action) {
      state.noWebsiteToConnectError = action.payload
    },
    setCardData(state, action) {
      /*
      action.payload consists of the id of the channel and the number of times that channel is connected
      function to determine that is inside the channels modal component line 50
      This is to render the proper number on the blue banner that appears at the top of the connected channel
      */
      const channelClickedID = action.payload.channelId
      // state.cardData[channelClickedID].isConnected = true
      if (state.cardData[channelClickedID]) {
        state.cardData[channelClickedID].isConnected = action.payload.showCard
        state.cardData[channelClickedID].channelsConnected =
          action.payload.channelsConnected
      }
    },
    setInitialCardData(state, action) {
      state.cardData = action.payload
    },

    handleConnectChannel(state, action) {
      const {
        clickedID: channelClicked,
        token: accessToken,
        toggleNylasV3,
        ccuid,
      } = action.payload
      localStorage.setItem('channelClicked', channelClicked) //setting this for the message to show after the channel is connected
      const isMobile = localStorage.getItem('IS_MOBILE')
      if (state.channelInfo?.length >= 100) {
        //max channels reached
        state.maxChannelsReached = true
      } else if (
        (state.cardData[channelClicked].iconName === 'microsoft365' &&
          toggleNylasV3 === true) ||
        state.cardData[channelClicked].channelProvider === 'nylas'
      ) {
        //NYLAS CHANNELS CONNECTION PROCESS
        // eslint-disable-next-line no-console
        console.log('nylas v3 is: ', toggleNylasV3)
        let provider = `${state.cardData[channelClicked].iconName}`
        if (toggleNylasV3 == true) {
          if (state.cardData[channelClicked].iconName == 'gmail') {
            provider = 'google'
          } else if (
            state.cardData[channelClicked].iconName == 'outlook' ||
            state.cardData[channelClicked].iconName == 'microsoft365' ||
            state.cardData[channelClicked].iconName == 'hotmail'
          ) {
            provider = 'microsoft'
          }
        }
        axios({
          url: `${VITE_NYLAS_HOST}/send_hosted_auth_sync_url?`,
          method: 'get',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            redirectURI: isMobile
              ? 'com.thryv.thryvcommandcenter://oauth/callback'
              : `${VITE_AUTH0_REDIRECT_URI}/oauth/callback`,
            provider,
            ccuid,
          },
        })
          .then((res) => {
            const aTag = document.createElement('a')
            aTag.setAttribute('href', res.data.Oauth_url_redirect)
            aTag.click()
          })
          .catch(() => {})
      } else if (state.cardData[channelClicked].ButtonText === 'IMAP') {
        state.modalContent = 6
      } else if (state.cardData[channelClicked].iconName === 'facebookMessenger') {
        state.sunshineChannelClick = 'facebook'
        localStorage.setItem('sunshineapp', 'facebook')
        window.location.replace(useFacebookAuth('facebook'))
      } else if (state.cardData[channelClicked].iconName === 'instagram') {
        state.sunshineChannelClick = 'instagram'
        localStorage.setItem('sunshineapp', 'instagram')
        window.location.replace(useFacebookAuth('instagram'))
      } else if (state.cardData[channelClicked].iconName === 'microsoft365') {
        window.location.replace(useOffice365Auth())
      } else if (
        state.cardData[channelClicked].ButtonText === 'Calls & SMS' ||
        state.cardData[channelClicked].ButtonText === 'Calls'
      ) {
        state.modalContent = 7
      } else if (state.cardData[channelClicked].iconName === 'video') {
        state.modalContent = 8
      } else if (state.cardData[channelClicked].iconName === 'webchat') {
        state.modalContent = 9
      } else {
        alert('no connection process for this channel yet')
        //logic to connect to other channels
      }
    },
    setDisableBackdropClick(state, action) {
      state.disableBackdropClick = action.payload
    },
    setCardDataConnectError(state, action) {
      const channelClickedID = action.payload.channelClickedID
      state.cardData[channelClickedID].connectError = action.payload.connectError
    },
    setCardDataConnectWarning(state, action) {
      const channelClickedID = action.payload.channelClickedID
      state.cardData[channelClickedID].connectWarning = action.payload.connectWarning
    },
    disconnectChannel(state, action) {
      const index = action.payload
      if (index > -1) {
        state.channelInfo.splice(index, 1)
      }
    },
    setSelectedCardData(state, action) {
      state.selectedCardData = action.payload
    },
    setMetaUserData(state, action) {
      state.metaUserData = action.payload
    },
  },
})
export const {
  setLoading,
  setModalContent,
  setChannelConnectedToAnotherCC,
  setMaxChannelsReached,
  setConnectionError,
  setMeta400Error,
  setShowFirstChannelModal,
  setChanelInfo,
  setChannelDisconnected,
  setChannelDisconnecting,
  handleHideDisconnectedChannelsNotification,
  handleHideDisconnectingChannelNotification,
  setChannelIsConnected,
  setShowWelcomeChannelModal,
  setShowModal,
  setCardData,
  handleConnectChannel,
  setDisableBackdropClick,
  setCardDataConnectError,
  setCardDataConnectWarning,
  disconnectChannel,
  setMaxChannelsAllowed,
  setConnectedChannelInfo,
  setNoWebsiteToConnectError,
  setInitialCardData,
  setSelectedCardData,
  setMetaUserData,
  setMaxChannelsAllowedLoading,
} = connectChannelsSlice.actions

export default connectChannelsSlice.reducer
