import queryString from 'query-string'
const { VITE_FACEBOOK_CLIENT_ID, VITE_FACEBOOK_AUTH_REDIRECT_URI } = import.meta.env

export const useFacebookAuth = (type, reauth) => {
  const facebookScopes = [
    'public_profile',
    'pages_show_list',
    'pages_manage_metadata',
    'pages_messaging',
    'email',
    'pages_read_engagement',
  ]

  const instagramScopes = [
    'public_profile',
    'pages_show_list',
    'pages_manage_metadata',
    'instagram_basic',
    'email',
    'pages_messaging',
    'instagram_manage_messages',
  ]

  if (reauth) facebookScopes.push('business_management')

  const scope = type == 'facebook' ? facebookScopes : instagramScopes

  const stringifiedPayload = queryString.stringify({
    client_id: VITE_FACEBOOK_CLIENT_ID,
    redirect_uri: VITE_FACEBOOK_AUTH_REDIRECT_URI,
    scope: scope.join(','),
    response_type: 'code',
    auth_type: 'rerequest',
  })

  const authUrl = `https://www.facebook.com/v18.0/dialog/oauth?${stringifiedPayload}`

  return authUrl
}
