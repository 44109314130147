import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const useDisplayCenter = (option) => {
  const [displayCenter, setDisplayCenter] = useState(true)

  const { user } = useAuth0()

  useEffect(() => {
    if (!user.businessId) setDisplayCenter(false)
    else setDisplayCenter(true)
  }, [user.businessId])

  const hideNavBarSection = () => {
    if (!displayCenter) {
      switch (option.title) {
        case 'ThryvPay':
          return true
        default:
          return false
      }
    } else return false
  }

  return { displayCenter, hideNavBarSection }
}

export default useDisplayCenter
