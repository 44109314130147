import { ausStates } from '../../utils/aus-states'
import { canProvinces } from '../../utils/can-provinces'
import { nzProvinces } from '../../utils/nz-provinces'
import { usStates } from '../../utils/us-states'

export const getCountryStates = (countryISO) => {
  if (countryISO === 'US') {
    return usStates
  } else if (countryISO === 'CA') {
    return canProvinces
  } else if (countryISO === 'AU') {
    return ausStates
  } else if (countryISO === 'NZ') {
    return nzProvinces
  }
}

export const getStateObj = (state, countryStates) => {
  if (!state) {
    return { name: '', value: '' }
  }
  if (typeof state !== 'object') {
    return countryStates?.find((countryState) => countryState.value === state)
  }

  return state
}

export const getStateValue = (state) => {
  if (typeof state !== 'string') {
    return state?.value
  }

  return state
}
