import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_STAFF } from '../graphql'

const INTEGER_TO_ROLE = {
  1: 'admin',
  2: 'staff',
  3: 'owner',
}

export const useStaffUser = () => {
  const { user: auth0User } = useAuth0()

  const [loggedInUser, setLoggedInUser] = useState({})

  const { data: userAsStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: auth0User.cc_uid },
      },
    },
  })

  useEffect(() => {
    if (userAsStaffData?.staff?.length) {
      const user = userAsStaffData.staff[0]
      setLoggedInUser(user)
    }
  }, [userAsStaffData])

  return {
    user: loggedInUser,
    role: INTEGER_TO_ROLE[loggedInUser?.Role || auth0User.cc_role],
  }
}
