export async function getAudioDuration(audioBlob) {
  try {
    // Create an audio element to analyze the duration
    const audio = new Audio(URL.createObjectURL(audioBlob))

    // Wait for the audio element to load the metadata
    await new Promise((resolve) => {
      audio.addEventListener('loadedmetadata', () => {
        resolve()
      })
    })

    // Get the duration in seconds
    const durationInSeconds = audio.duration

    return Math.floor(durationInSeconds)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting audio duration:', error)
    return null
  }
}

export async function blobToAudioBuffer(blob) {
  // Create an AudioContext
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()

  // Read the Blob data and convert it to an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer()

  return new Promise((resolve, reject) => {
    // Decode the ArrayBuffer into an AudioBuffer
    audioContext.decodeAudioData(
      arrayBuffer,
      (audioBuffer) => {
        resolve(audioBuffer)
      },
      (error) => {
        reject(error)
      },
    )
  })
}

export function secondsToTime(seconds) {
  // Calculate minutes and remaining seconds
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  // Format minutes and remaining seconds as strings with leading zeros if needed
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  // Return the time in the format "00:02"
  return `${formattedMinutes}:${formattedSeconds}`
}
