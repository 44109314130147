import { useState, useEffect, useRef } from 'react'
import moment from 'moment/moment'
import { getInboxDatePairs } from './utils/get-inbox-date-pairs'
import DATE_OPTIONS from './data/date-options.json'

// Components
import { FilterItem, SelectionButtonWithMenu } from '../../common'
import { DatePicker, withModal } from '@thryvlabs/maverick'
import { CustomDateModal } from './custom-date-modal'

const useCustomDateModal = () => {
  const toggleCustomDateModalRef = useRef(null)

  const InitToggleCustomDateModal = ({ showModal }) => {
    toggleCustomDateModalRef.current = showModal
    return <></>
  }

  const HiddenCustomDateModal = withModal(InitToggleCustomDateModal, CustomDateModal)

  return [toggleCustomDateModalRef, HiddenCustomDateModal]
}

const ItemButton = ({ children, onClick }) => {
  const hoverAnimClass = 'hover:bg-thryv-gray-light-200 duration-300 transition-all'

  return (
    <button
      type="button"
      className={`block w-full text-start hover:bg-thryv-gray-light-200 py-1 md:py-[9px] px-2 rounded font-['Open_Sans'] text-sm text-thryv-black-500 ${hoverAnimClass}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const DateOption = ({ type, defaultDate, altDatePicker }) => {
  const [toggleCustomDateModalRef, HiddenCustomDateModal] = useCustomDateModal()

  const [label, setLabel] = useState(type || 'Any')
  const [isOpen, setIsOpen] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const [from, setFrom] = useState(
    defaultDate[0] ? moment(defaultDate[0]) : moment(),
  )
  const [to, setTo] = useState(defaultDate[1] ? moment(defaultDate[1]) : moment())
  const [dates, setDates] = useState(defaultDate || ['', ''])

  const updateDatesAndLabel = (labelName) => {
    setLabel(labelName)
    const datePair = getInboxDatePairs({ from, to, selection: labelName })
    setDates(datePair)
  }

  useEffect(() => {
    label === 'Custom' ? setShowDatePicker(true) : setShowDatePicker(false)
    setIsOpen(false)
  }, [label])

  return (
    <FilterItem title="Date">
      <input
        name="date"
        value={JSON.stringify({
          type: label,
          dates: dates,
        })}
        className="hidden"
        readOnly
      />
      <SelectionButtonWithMenu
        dataTestID="date-filter"
        className="!w-full"
        onStateChange={(status) => status === 'dropdown open' && setIsOpen(true)}
        isOpen={isOpen}
        content={
          <div className="p-2">
            {DATE_OPTIONS.map((dateOption, i) => (
              <ItemButton key={i} onClick={() => updateDatesAndLabel(dateOption)}>
                {dateOption}
              </ItemButton>
            ))}
            {/* Hiding custom date filter until refactor is complete */}
            {/* <hr className="my-2 text-thryv-gray-light-400" />

            <ItemButton onClick={() => updateDatesAndLabel('Custom')}>
              Custom
            </ItemButton> */}
          </div>
        }
      >
        {label}
      </SelectionButtonWithMenu>
      {showDatePicker && (
        <>
          <HiddenCustomDateModal
            modalProps={{
              onClose: (datePair) => {
                setFrom(datePair[0])
                setTo(datePair[1])
              },
              newFrom: from,
              newTo: to,
            }}
          />

          <div
            onClick={() => altDatePicker && toggleCustomDateModalRef.current()}
            className="cursor-pointer"
          >
            <DatePicker
              className={`-mt-3 inbox-menu-filter-date-picker 
                ${altDatePicker && 'pointer-events-none'}
                ${!altDatePicker && 'w-[301px]'}`}
              variant="range"
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              setDates={() => updateDatesAndLabel('Custom')}
            />
          </div>
        </>
      )}
    </FilterItem>
  )
}
