import { ConnectionStatus } from '@amityco/js-sdk'
import axios from 'axios'
import { AmityClientService } from './amityClientService'

const baseUrl = import.meta.env.VITE_TEAMCHAT_API_URL
export const client = AmityClientService.getClient()
export let currentUserId = null

export const connectClient = async (userId) => {
  const amityConnection = await axios
    .get(`${baseUrl}/api/teamchat/amityAuthentication/${userId}`)
    .then((res) => {
      const authToken = res.data
      return new Promise((resolve) => {
        client.on('connectionStatusChanged', ({ newValue }) => {
          if (newValue === ConnectionStatus.Connected) {
            currentUserId = userId
            resolve()
          }
        })
        client.registerSession({ userId, authToken })
      })
    })
  return amityConnection
}
