import { ParagraphText } from '@thryvlabs/maverick'
import { Icon } from '@thryvlabs/maverick'
import { TransitionContainer } from '../../../../common/transition-container'
import { useEffect, useRef, useState, forwardRef } from 'react'
import { useSelector } from 'react-redux'

export const LatestMessagesButton = forwardRef(
  ({ lastMessage, className, ...rest }, messageContentRef) => {
    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )
    const [show, setShow] = useState(false)

    const buttonRef = useRef(null)

    const jumpToBottom = () => {
      messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight
      setShow(false)
      sessionStorage.removeItem('scrollPosition')
    }

    const handleScroll = () => {
      let scrollTop = messageContentRef.current?.scrollTop
      let scrollHeight = messageContentRef.current?.scrollHeight

      let clientHeight = messageContentRef.current?.clientHeight

      let distanceFromBottom = scrollHeight - (scrollTop + clientHeight)

      let messageHeight = document.getElementById(lastMessage?.sk1)?.offsetHeight

      if (distanceFromBottom > messageHeight) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    useEffect(() => {
      messageContentRef.current?.addEventListener('scroll', handleScroll)
      return () => {
        messageContentRef.current?.removeEventListener('scroll', handleScroll)
      }
    }, [messageContentRef, selectedThreadId, lastMessage])

    return (
      <TransitionContainer
        show={show}
        className={`${className} rounded-2xl !scale-100`}
        {...rest}
        width={158.73}
        unmount={false}
        transformOrigin="center"
      >
        <button
          ref={buttonRef}
          className="flex gap-[9px] items-center bg-thryv-cornflower-500 rounded-2xl h-[32px] px-4"
          onClick={jumpToBottom}
        >
          <Icon type="regular" variant="chevronDown" color="#FFFFFF" width={14} />

          <ParagraphText
            variant="reg"
            color="thryv-white-50"
            className="!text-[13px] !leading-[14px] !font-semibold !tracking-[0.065px]"
          >
            Latest Messages
          </ParagraphText>
        </button>
      </TransitionContainer>
    )
  },
)
