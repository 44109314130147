import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useInboxNotifications = () => {
  const notifications = useSelector((state) => state.inboxNotifications)
  const reduxNotifs = Object.values(useSelector((state) => state.inboxNotifications))

  const [inboxNotifications, setInboxNotifications] = useState([])
  const [activeNotifNames, setActiveNotifNames] = useState([])

  const activeNotifs = reduxNotifs.filter((notif) => notif.show)
  const inactiveNotifs = reduxNotifs.filter((notif) => !notif.show)

  const updateActiveNotifNames = () => {
    let activeNotifNamesCopy = activeNotifNames.slice()

    reduxNotifs.forEach((reduxNotif) => {
      if (!activeNotifNames.includes(reduxNotif.name) && reduxNotif.show) {
        activeNotifNamesCopy.push(reduxNotif.name)
      } else if (activeNotifNames.includes(reduxNotif.name) && !reduxNotif.show) {
        activeNotifNamesCopy = activeNotifNamesCopy.filter(
          (notifName) => notifName !== reduxNotif.name,
        )
      }
    })

    setActiveNotifNames(activeNotifNamesCopy)
  }

  useEffect(() => {
    updateActiveNotifNames()
  }, [notifications])

  const updateNotifications = {
    activeOnly: () => {
      const inboxNotifsCopy = []

      activeNotifNames.forEach((notifName) => {
        const notifToAdd = notifications[notifName]
        inboxNotifsCopy.push(notifToAdd)
      })

      setInboxNotifications(inboxNotifsCopy)
    },

    existingOnly: () => {
      const inboxNotifsCopy = inboxNotifications.slice()

      inboxNotifications.forEach((notif, i) => {
        if (!activeNotifNames.includes(notif.name)) {
          const notifClone = { ...inboxNotifsCopy[i] }
          notifClone.show = false
          inboxNotifsCopy[i] = notifClone
        }
      })

      setInboxNotifications(inboxNotifsCopy)
    },
  }

  const updateInboxNotifications = () => {
    updateNotifications.existingOnly()
    updateNotifications.activeOnly()
  }

  useEffect(() => {
    updateInboxNotifications()
  }, [activeNotifNames])

  return {
    inboxNotifications: inboxNotifications,
    numOfActiveNotifs: activeNotifs.length,
    numOfInactiveNotifs: inactiveNotifs.length,
  }
}
