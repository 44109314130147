import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const NavButton = ({ onClick, children, active }) => (
  <button onClick={onClick} className="px-6 pb-3 pt-4">
    <h4
      className={`text-sm font-semibold font-[Montserrat] text-thryv-steel ${
        active && '!text-thryv-black-500'
      }`}
    >
      {children}
    </h4>
  </button>
)

const NavTab = ({ title, active, onClick }) => {
  const renderUnderline = () => (
    <div className="absolute bg-thryv-orange-500 h-0.5 w-full bottom-0"></div>
  )

  return (
    <div className="relative">
      <NavButton onClick={onClick} active={active}>
        {title}
      </NavButton>
      {active && renderUnderline()}
    </div>
  )
}

export const DesktopNavigation = ({
  className,
  linksArray,
  selected,
  setSelectedOption,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <nav>
      <ul className={`${className} flex gap-2 border-b-[1px] border-[#F1F1F5]`}>
        {/* Must use absolute path for `url` in order for tab active styling to work */}
        {linksArray.map((link, i) => {
          const isActive =
            pathname === link.url || (selected !== undefined && selected === i)

          const handleTabClick = () => {
            link.onClick && link.onClick()
            setSelectedOption && setSelectedOption(i)
            if (link.url !== undefined) navigate(link.url)
          }

          return (
            <li key={i}>
              <NavTab
                title={link.title}
                active={isActive}
                onClick={handleTabClick}
              />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
