import { useState } from 'react'
import { NavMenu } from './nav-menu'
import { ParagraphText, Icon } from '@thryvlabs/maverick'
import { useSelector } from 'react-redux'

export const NavButtonWithMenu = ({ disableMenu }) => {
  const [showMenu, setShowMenu] = useState(false)
  const { inboxFilter } = useSelector((state) => state.inboxThreads)

  return (
    <div className="relative">
      <button
        className={`relative flex items-center gap-2 
        ${disableMenu && 'pointer-events-none'}`}
        onClick={() => setShowMenu(!showMenu)}
      >
        <ParagraphText
          variant="lg"
          color="[#4D4D4D]"
          className="!font-[Montserrat] font-semibold text-lg"
        >
          {inboxFilter === 'Inbox' ? 'Inbox' : inboxFilter}
        </ParagraphText>
        {!disableMenu ? (
          <Icon
            className={`${showMenu && '-rotate-180'} duration-300`}
            type="solid"
            variant="caretDown"
            color="#4D4D4D"
            height="4.5"
            width="8"
          />
        ) : null}
      </button>
      <NavMenu show={showMenu} setShow={setShowMenu} />
    </div>
  )
}
