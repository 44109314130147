import CustomModal from '../common/CustomModal/Modal'
import { useMediaQuery } from 'react-responsive'
import { Button, ParagraphText } from '@thryvlabs/maverick'
const ErrorFallback = () => {
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const handleRefresh = () => {
    window.location.reload()
    localStorage.clear()
  }
  const ErrorComponent = () => {
    return (
      <div className="flex flex-col ">
        <ParagraphText className="text-center py-11" variant="lg">
          Uh-oh, looks like there was an issue. Please refresh the page to continue.
        </ParagraphText>
        <div className="flex justify-end">
          <Button variant="primary" onClick={handleRefresh}>
            Refresh
          </Button>
        </div>
      </div>
    )
  }

  return (
    <CustomModal
      isModalForm={true}
      hasCrossIcon={false}
      isOpen={true}
      width={isMobile ? '300px' : '500px'}
    >
      <ErrorComponent />
    </CustomModal>
  )
}

export default ErrorFallback
