import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'

// Reference-1: https://en.wikipedia.org/wiki/National_conventions_for_writing_telephone_numbers#:~:text=New%20Zealand,-Almost%20all%20New&text=Recently%20with%208%20digit%20mobile,BBB%2DBBBB%20for%20mobile%20numbers.
// Reference-2: (Vonage API) https://developer.vonage.com/en/voice/voice-api/concepts/numbers
// Reference-3: (Country Codes) https://www.nationsonline.org/oneworld/country_code_list.htm

/**
 * Current Validator Support List
 * Australia    +61 (4xx)   xxx-xxx
 * New Zealand  +64 (xx)    xxx-xxxx
 * US           +1  (xxx)   xxx-xxxx
 * Canada       +1  (xxx)   xxx-xxxx
 */

const validCountries = ['US', 'AU', 'CA', 'NZ']

const isPossibleCCPhoneNumber = (number) => {
  return validCountries.some((country) => isValidPhoneNumber(number, country))
}

const commandCenterPhoneValidator = (phoneNum = '', countryCode) => {
  // IMPORTANT: This is the shape of the value returned from `parsePhoneNumber(phoneNum, country)` below.
  const unknownPhone = {
    country: undefined, // "AU"
    countryCallingCode: undefined, // "61"
    nationalNumber: undefined, // "755380499"
    number: '', // "+61755380499"
    isValid: () => false,
  }

  if (
    typeof phoneNum !== 'string' ||
    !isPossibleCCPhoneNumber(phoneNum) ||
    countryCode === undefined
  ) {
    return unknownPhone
  }

  if (countryCode === 'AU') {
    return parsePhoneNumber(phoneNum, 'AU')
  }

  if (countryCode === 'US') {
    return parsePhoneNumber(phoneNum, 'US')
  }

  if (countryCode === 'CA') {
    return parsePhoneNumber(phoneNum, 'CA')
  }

  if (countryCode === 'NZ') {
    return parsePhoneNumber(phoneNum, 'NZ')
  }

  return unknownPhone
}

export default commandCenterPhoneValidator
