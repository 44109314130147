import { CommandCenterIcon } from '../../../../../../command-center-icon'

export const FilterButton = ({ className, onClick, active }) => {
  return (
    <button
      data-testid="filter-button"
      className={`p-1.5 rounded-full ${active && 'bg-[#F8F9FB]'} ${className}`}
      type="button"
      onClick={onClick}
    >
      <CommandCenterIcon type="solid" variant="filter" fill="#808080" width="20" />
    </button>
  )
}
