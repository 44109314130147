// let eg = {
//   isLengthValid: false,
//   hasUpperCase: false,
//   hasLowerCase: false,
//   hasNumber: false,
//   hasSpecialChar: false,
// }

const newPasswordValidation = (newPassword) => {
  let isLengthValid = newPassword.length >= 8
  let hasUpperCase = /[A-Z]/.test(newPassword)
  let hasLowerCase = /[a-z]/.test(newPassword)
  let hasNumber = /\d/.test(newPassword)
  let hasSpecialChar = /[!@#$%^&*]/.test(newPassword)
  // let resultObj = {
  //   isLengthValid,
  //   hasUpperCase,
  //   hasLowerCase,
  //   hasNumber,
  //   hasSpecialChar,
  // }
  return { isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar }
}

export default newPasswordValidation
