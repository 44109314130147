import { gql } from '@apollo/client'

export const GET_LOCATION_BY_ID = gql`
  query get_location_by_id($location_id: String!) {
    queryLocationByID(location_id: $location_id) {
      LocationID
      CCID
      Name
      Email
      Phone
      Address
      City
      Zip
      State
      CountryCode
      Timezone
      AddressHidden
      Website
      CreditCardOnFile
      ACHOnFile
      Status
      AddressType
      ExtDirectoryCode
      VToken
      CreatedAt
      UpdatedAt
      BusinessCenterLegacy
      BusinessCenter
      MarketingCenter
      Signatures
      WebsiteBuilder
    }
  }
`
