import { useMediaQuery } from 'react-responsive'

// Components
import ExpandableBoxWrapper from '../../../../expandable-box-wrapper'
import { UserPermissionsDesktopTable } from './user-permissions-desktop-table/user-permissions-desktop-table'
import { UserPermissionsMobileTable } from './user-permissions-mobile-table.jsx/user-permissions-mobile-table'

export const UserPermissionsTable = ({ className, channel, staffMembers, show }) => {
  const isMobile = useMediaQuery({ maxWidth: 640 })

  return (
    <ExpandableBoxWrapper className={`${className} `} expanded={show}>
      <div className="mt-0.5"></div>
      <div className="bg-thryv-white-50">
        {!isMobile && (
          <UserPermissionsDesktopTable
            staffMembers={staffMembers}
            channel={channel}
          />
        )}

        {isMobile && <UserPermissionsMobileTable staffMembers={staffMembers} />}
      </div>

      <div className="mt-4"></div>
    </ExpandableBoxWrapper>
  )
}
