import { Input } from '@thryvlabs/maverick'

export const FirstNameInput = ({ className, register, errors, disabled }) => {
  return (
    <div className={`h-[27px] text-start ${className}`}>
      <Input
        register={register}
        className="w-full sm:max-w-[280px]"
        type="text"
        name="firstName"
        customPlaceholder="First Name"
        labelType="static"
        variant="default"
        errors={errors}
        disabled={disabled}
      />
    </div>
  )
}

export const LastNameInput = ({ className, register, errors, disabled }) => {
  return (
    <div className={`h-[27px] text-start ${className}`}>
      <Input
        register={register}
        errors={errors}
        className="w-full sm:max-w-[280px]"
        type="text"
        name="lastName"
        customPlaceholder="Last Name"
        labelType="static"
        variant="default"
        disabled={disabled}
      />
    </div>
  )
}
