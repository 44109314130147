import { useRef } from 'react'
import { withModal } from '@thryvlabs/maverick'
import { DeleteModal as DeleteModalComponent } from './delete-modal'

/*
###########################
##     EXAMPLE USAGE     ##
###########################

  ----- Needed in code in order to toggle Delete Modal -----

   const {
    DeleteModal
    toggleDeleteModalRef
  } = useDeleteModal()

  ...

  <DeleteModal
    modalProps={{
      userToDelete: {
        id: id,
      },
    }}
  />

  Use the above modal in your code
*/

export const useDeleteModal = () => {
  const toggleDeleteModalRef = useRef(null)

  const HiddenButton = ({ showModal }) => {
    toggleDeleteModalRef.current = showModal
    return <div className="hidden">Hidden Delete Modal Button</div>
  }

  const DeleteModal = withModal(HiddenButton, DeleteModalComponent)

  return {
    DeleteModal: DeleteModal,
    toggleDeleteModalRef: toggleDeleteModalRef,
  }
}
