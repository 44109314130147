import { Input, Button, Icon } from '@thryvlabs/maverick'
import { Formik } from 'formik'
import * as yup from 'yup'

export const AddOtherApps = ({ onSuccess, handleClose }) => {
  const validationSchema = yup.object({
    nickname: yup.string().required('Nickname is Required'),
    link: yup
      .string()
      .url(
        'A valid link beginning with "https://" and ending with a domain such as ".com" is required',
      ),
  })

  return (
    <>
      <Formik
        validationOnChange={true}
        initialValues={{ nickname: '', link: 'https://' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSuccess(values)
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Icon
                className="w-[18px] h-[18px] text-[#A3A5A7] cursor-pointer absolute top-[25px] right-[30px]"
                type="regular"
                variant="x"
                height="18"
                fill="#A3A5A7"
                onClick={handleClose}
              />
              <div className="flex flex-col gap-8 pt-[40px]">
                <Input
                  id="nickname"
                  name="nickname"
                  withLabel
                  labelType="floating"
                  placeholder="Nickname"
                  onChange={handleChange}
                  className="w-[100%]"
                  type="text"
                  value={values.nickname}
                  variant="default"
                  errors="Error"
                />
                {errors.nickname && touched.nickname && (
                  <p className="text-[#E13C3B] text-xs">{errors.nickname}</p>
                )}

                <Input
                  id="link-field"
                  name="link"
                  withLabel
                  labelType="floating"
                  className="w-[100%]"
                  type="text"
                  placeholder="Link"
                  value={values.link}
                  onChange={handleChange}
                  variant="default"
                />
                {errors.link && touched.link && (
                  <p className="text-[#E13C3B] text-xs">{errors.link}</p>
                )}
              </div>

              <div
                className="mt-12 text-sm flex justify-end"
                style={{ marginTop: '4rem' }}
              >
                <Button
                  className="text-sm"
                  onClick={handleClose}
                  variant="text"
                  level={1}
                  textVariant="light"
                  style={{ fontSize: '14px', marginRight: '16px' }}
                >
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="ml-2 text-sm"
                  variant="primary"
                  style={{ fontSize: '14px' }}
                >
                  Save
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
