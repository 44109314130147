import { useRef, useState } from 'react'
import {
  PageWrapper,
  TopContent,
  BottomContent,
  ChannelsContainer,
  ChannelLinksContainer,
  RoundedBtn,
} from './WelcomeScreen.styles'
import { Header, ParagraphText } from '@thryvlabs/maverick'
import { ChannelsModalComponent } from '../common/modals/channels-modal-component/ChannelsModalComponent'
import { ButtonsData } from './buttonsData'
import BottomModalButton from './BottomModalButton'
import { useSelector, useDispatch } from 'react-redux'
import {
  setMaxChannelsAllowed,
  setModalContent,
  setShowModal,
  setShowWelcomeChannelModal,
} from '../common/modals/slices/connectChannelsSlice'
import { InboxCover } from '../command-center-icon/icons'
import video from '../../assets/welcome-inbox.mp4'
import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER, GET_PACKAGE_INFO } from '../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import {
  setNoChannelsConnected,
  setSupportChannelId,
} from '../inbox/slices/welcomeSlice'
import { useWelcomeMessage } from '../inbox/hooks/useWelcomeMessage'
import { AuthLoader } from '../authentication/auth-loader'
import { useMediaQuery } from 'react-responsive'

const WelcomeScreen = () => {
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const dispatch = useDispatch()
  const [playVideo, setPlayVideo] = useState(false)
  const { user } = useAuth0()
  const videoRef = useRef(null)
  const buttonsData = ButtonsData()
  const navigate = useNavigate()

  const handlePlayVideo = () => {
    if (videoRef.current?.paused) {
      setPlayVideo(true)
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen()
      } else if (videoRef.current.mozRequestFullScreen) {
        /* Firefox */
        videoRef.current.mozRequestFullScreen()
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        videoRef.current.webkitRequestFullscreen()
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE/Edge */
        videoRef.current.msRequestFullscreen()
      }
      videoRef.current.play()
    } else {
      setPlayVideo(false)
      videoRef.current.pause()
    }
  }

  const ConnectButton = () => {
    const handleOpenModal = () => {
      dispatch(setShowModal(true))
      dispatch(setModalContent(1))
    }
    return (
      <RoundedBtn
        variant="primary"
        onClick={() => handleOpenModal()}
        className="text-sm px-[1.5rem] py-2"
      >
        CONNECT CHANNELS
      </RoundedBtn>
    )
  }
  const { showModal } = useSelector((state) => state.connectChannels)

  const onEnded = () => {
    setPlayVideo(false)
  }

  const { data: packageInfoData, error: packageInfoError } = useQuery(
    GET_PACKAGE_INFO,
    {
      onCompleted: () => {
        dispatch(
          setMaxChannelsAllowed(
            packageInfoData.queryPackageInfoByCCID.InboxChannels,
          ),
        )
      },
      onError: () => {
        // eslint-disable-next-line no-console
        console.log('PACKAGE INFO ERROR:', packageInfoError)
      },
    },
  )

  const { data: channelData, loading } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: user.businessId, user_id: user.cc_uid },
    onCompleted: () => {
      const hiddenSupportChannel = channelData?.queryConnectedChannelsByUser
        ?.filter((channel) => channel.Provider === 'CC')
        ?.at(0)
      dispatch(setSupportChannelId(hiddenSupportChannel?.ChannelID))

      const activeChannels = channelData?.queryConnectedChannelsByUser?.filter(
        (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
      )

      const videoChannelConnected = activeChannels?.filter(
        (channel) => channel.Provider === 'video',
      )?.length

      const callsChannels = activeChannels?.filter(
        (channel) => channel.VendorID === '2',
      )?.length

      if (activeChannels.length === 0) {
        dispatch(setNoChannelsConnected(true))
        localStorage.setItem('noConnectedChannels', true)
        dispatch(setModalContent(1))
        dispatch(setShowWelcomeChannelModal(true))
        dispatch(setShowModal(true))
        return
      }

      if (
        activeChannels.length === 1 &&
        localStorage.getItem('noConnectedChannels') === 'true'
      ) {
        // Channel was JUST connected; open first channel modal!
        //logic to open first channel modal has changed to open for every channel connection
        navigate('/inbox')
        localStorage.setItem('noConnectedChannels', false)
        return
      }

      if (videoChannelConnected && activeChannels.length === 1) {
        navigate('/inbox')
        return
      }
      if (callsChannels.length > 0) {
        localStorage.setItem('noConnectedChannels', false)
        navigate('/calls')
      }

      if (activeChannels.length > 0) {
        localStorage.setItem('noConnectedChannels', false)
        navigate('/inbox')
      }
    },
  })

  useWelcomeMessage()

  //using loader for when the logic and query are running
  if (loading) return <AuthLoader />

  if (isMobile && showModal) {
    return (
      <PageWrapper>
        <ChannelsModalComponent renderAsPage />
      </PageWrapper>
    )
  }

  return (
    <>
      <PageWrapper>
        <TopContent>
          <div
            id="contact-calls"
            className="flex flex-wrap justify-evenly gap-y-20 relative font-bold ml-[3px]"
          >
            <div className="relative self-center m-10 z-10 w-[417px] sm:w-[300px] mt-[42px] md:ml-[10px]">
              <Header fontWeight="semibold" variant="h1" className="normal-case">
                Welcome to <br />
                Command Center <span className="text-thryv-orange-500">Inbox!</span>
              </Header>
              <ParagraphText
                variant="lg"
                color="thryv-steel"
                className="my-5 text-left font-semibold leading-[22px]"
              >
                Never miss another communication from your team or your customers. No
                matter the channel, your communications can be handled all in one
                place.
              </ParagraphText>
              <ConnectButton />
              {showModal && <ChannelsModalComponent />}
            </div>
            <div className="pb-[15px] w-[488px] sm:w-[300px] h-[387px] flex justify-center items-center flex-col relative">
              <video
                ref={videoRef}
                width="377"
                height="282"
                controls
                onEnded={onEnded}
                className={playVideo ? 'sm:w-[300px]' : 'sm:w-[300px] hidden'}
              >
                <source src={video} type="video/mp4" />
              </video>

              {playVideo ? null : <InboxCover onClick={() => handlePlayVideo()} />}

              <div className=" absolute bottom-4">
                <ParagraphText
                  variant="reg"
                  color="thryv-steel"
                  className="my-4 text-center leading-[20px]"
                >
                  Connect with your prospects in your inbox <br />
                  <span className="font-semibold text-thryv-orange-500">
                    no matter where they're coming from.
                  </span>
                </ParagraphText>
              </div>
            </div>
          </div>
        </TopContent>
        <BottomContent>
          <ChannelsContainer className="x-sm:mt-[250px]">
            <div className="w-[880px] mx-auto mb-6 pl-10 sm:pl-0 sm:w-full sm:flex sm:justify-center">
              <ParagraphText
                variant={screen.width < 560 ? 'lg' : 'reg'}
                color="thryv-black"
                className="text-left font-semibold text-xl leading-[28px] x-sm:text-lg"
              >
                Our list of channels also include:
              </ParagraphText>
            </div>
            <ChannelLinksContainer>
              {buttonsData.map((button) => {
                return (
                  <BottomModalButton
                    key={button.iconName}
                    IconName={button.iconName}
                    ButtonText={button.ButtonText}
                    IconType={button.iconType}
                  />
                )
              })}
            </ChannelLinksContainer>
          </ChannelsContainer>
        </BottomContent>
      </PageWrapper>
    </>
  )
}

export default WelcomeScreen
