import { useState } from 'react'
import { ParagraphText } from '@thryvlabs/maverick'
import { ConversationAvatar } from '../../../../../conversation-avatar'
import { ThumbtackButtonWithModal } from './thumbtack-button-with-modal'
import { MessageCardTemplate } from '../message-card-template'
import { ExpandableTextbox } from './expandable-text-box'
import { TextButton } from './text-button'
import RoundedActionButton from '../../../../../../common/rounded-action-button'
import moment from 'moment'
import ChannelsIcon from '../../../../../../../channels-icon/channels-icon'
import phoneFormatter from '../../../../../../../../utils/phoneFormatter'
import { LoadingSpinner } from '../../../../../../../common/loading-spinner'

export const MessageCard = ({
  imageUrl,
  content,
  setShowDropdown,
  message,
  isVoicemail,
  isSMS,
  refetchPins,
  messageContentRef,
  refetchMessages,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [loading, setLoading] = useState(false)
  const hoverAnimClass = ' hover:border-[#FF5000] duration-300 transition-all'

  const loadMsg = () => {
    const messageFound = document.getElementById(message.sk1)
    if (messageFound) {
      setLoading(false)
      //need the space before the actual className so it doesnt remove the classnames on the element
      messageFound.className += ` message-selected`
      messageFound.scrollIntoView({ behavior: 'smooth', block: 'center' })
      setShowDropdown(false)
      setScroll(false)
    } else {
      document
        .getElementById('message-container')
        .scroll({ top: 0, behavior: 'smooth' })
    }
  }

  const scrollToMsg = () => {
    setLoading(true)
    setScroll(true)
    loadMsg()
  }

  if (scroll) {
    setTimeout(() => {
      loadMsg()
    }, 200)
  }

  if (isVoicemail) {
    const voicemailTranscript = content.substring(
      content.indexOf('=') + 1,
      content.indexOf('link=') - 2,
    )
    voicemailTranscript
      ? (content = `Transcript:  ${voicemailTranscript}`)
      : (content = 'Transcript not available')
  }

  const sunshineChannel =
    message?.chan_type === 'facebook' || message?.chan_type === 'instagram'

  return (
    <article
      className={`border border-solid border-thryv-gray-medium-500 rounded-xl p-2.5 ${hoverAnimClass}`}
    >
      <div className="flex justify-between mb-2">
        <div className="flex gap-1.5 items-center">
          <ConversationAvatar variant="image" imgUrl={imageUrl} customSize={20} />
          <ParagraphText variant="sm" color="thryv-black-500">
            {isVoicemail || isSMS
              ? phoneFormatter(message.number_from)
              : sunshineChannel
                ? message?.dm_from
                : message?.email_from?.name}
          </ParagraphText>
        </div>

        <div>
          <ChannelsIcon variant={message.chan_type} width={22} height={22} />
        </div>
      </div>

      <div className="mb-3 min-h-[58px]">
        <div className="flex justify-between items-start">
          <ParagraphText
            className="font-semibold mb-1 line-clamp-1"
            variant="sm"
            color="thryv-night"
          >
            {isVoicemail ? 'Voicemail' : isSMS ? null : message?.email_subject}
          </ParagraphText>
          {expanded && (
            <TextButton onClick={() => setExpanded(!expanded)}>Collapse</TextButton>
          )}
        </div>

        <ExpandableTextbox
          isOpen={expanded}
          setIsOpen={() => setExpanded(!expanded)}
        >
          {content}
        </ExpandableTextbox>
      </div>

      <div className="flex justify-between items-center">
        <ParagraphText variant="sm" color="thryv-gray-medium-500">
          {moment(message.ts).local().format('dddd, MMMM Do')}
        </ParagraphText>
        <div className="flex">
          {!loading ? (
            <RoundedActionButton
              className="w-[28px] h-[28px]"
              icon={{
                type: 'regular',
                variant: 'fileImport',
                width: '16',
                height: '16',
                color: '#808080',
                hoverColor: '#057AFF',
              }}
              onClick={() => scrollToMsg()}
              tooltipTitle="Go to message"
              isMavIcon
            />
          ) : (
            <LoadingSpinner widthAndHeight="24px" />
          )}
          <ThumbtackButtonWithModal
            message={message}
            refetchPins={refetchPins}
            messageContentRef={messageContentRef}
            refetchMessages={refetchMessages}
            setShowDropdown={setShowDropdown}
            emailCard={
              <MessageCardTemplate
                className="pointer-events-none"
                imageUrl={imageUrl}
                name={
                  isVoicemail || isSMS
                    ? message?.number_from
                    : sunshineChannel
                      ? message?.dm_from
                      : message?.email_from?.name
                }
                message_channel={message.chan_type}
                subject={isVoicemail ? 'Voicemail' : message?.email_subject}
                content={content}
                timestamp={moment(message.ts).local().format('dddd, MMMM Do')}
                focus
                showPinnedButton
              />
            }
          />
        </div>
      </div>
    </article>
  )
}
