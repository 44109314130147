import { CC_RECURLY_PLAN_CODES } from '../../../../utils/recurly-constants'
import { getTiersData } from './constants'

export const syncCCPlanPrices = (data, activePlanCode, toggleStaffSeatUpdates) =>
  getTiersData({ toggleStaffSeatUpdates }).map((tier) => ({
    ...tier,
    // If user has freePlus account, tier will still be "free"
    isCurrent:
      tier.code === activePlanCode ||
      (tier.name === 'free' && activePlanCode === CC_RECURLY_PLAN_CODES.freePlus),
    selected: false,
    price: data.find((d) => d.code === tier.code).price,
  }))

export const createToken = async (data) => {
  return new Promise((resolve) => {
    const client = window.recurly
    client.configure({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
      parent: false,
      cors: true,
    })
    client.token(
      {
        first_name: data.fname,
        last_name: data.lname,
        number: data.cardNumber,
        year: `20${Number(data.expiresOn.substring(2, 4))}`,
        month: Number(data.expiresOn.substring(0, 2)),
        cvv: data.cvv,
        address1: data.street1,
        address2: data.street2,
        country: data.countryCode,
        city: data.city,
        state: data.state.value,
        postal_code: data.postalCode,
        primary_payment_method: data.primaryPaymentMethod,
      },
      (err, token) => {
        resolve({ err, token })
      },
    )
  })
}
