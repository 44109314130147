import ColoredFileIcon from '../../../common/colored-file-icon/colored-file-icon'
import { Icon } from '@thryvlabs/maverick'
import { scrollToMsgInThread } from '../../../inbox/global-search-section/sections/utils/scrollToMsgInThread'
import { TooltipWithCenteredArrow } from '../../../common/tooltip-with-centered-arrow/tooltip-with-centered-arrow'

const RightPanelSharedFiles = ({
  fileName,
  type,
  fileUrl,
  handleFileAttachmentDownload,
  fileSk,
  fileSize,
  isMobile,
}) => {
  const truncateFileName = (name, maxLength = 15) => {
    const parts = name.split('.')
    const extension = parts.pop()
    const truncatedName = parts.join('.')

    if (truncatedName.length > maxLength) {
      const front = truncatedName.substring(0, maxLength / 2)
      const back = truncatedName.substring(truncatedName.length - maxLength / 2)
      return `${front}....${back}.${extension}`
    }

    return name
  }

  return (
    <div className="hover:bg-[#FF50000D] rounded-lg cursor-default px-3 py-2">
      <>
        <div className={`grid grid-flow-col justify-between`}>
          <div className={`flex items-center`}>
            <ColoredFileIcon
              variant={fileName}
              text={type}
              height={'37px'}
              width={'30px'}
            />
            <div className={`grid grid-flow-row pl-[20px]`}>
              <p className="font-normal text-[#323232] text-sm font-['Open Sans']">
                {truncateFileName(fileName, isMobile ? 20 : 15)}{' '}
                {/* Using the truncate function here */}
              </p>
              <p className="font-open-sans text-sm text-[#969696] font-normal sm:grid sm:justify-items-start">
                {fileSize === 'NaN gb' ? '–' : fileSize}
              </p>
            </div>
          </div>
          {!isMobile && (
            <div className="flex items-center [&>div]:whitespace-nowrap">
              <TooltipWithCenteredArrow
                variant="top"
                title="Go to file"
                disableVisibleOnClick
              >
                <Icon
                  data-testid="import-photo"
                  variant="fileImport"
                  type="regular"
                  height="16px"
                  width="15px"
                  color="#808080"
                  className="cursor-pointer"
                  onClick={() => scrollToMsgInThread(fileSk)}
                />
              </TooltipWithCenteredArrow>
              <TooltipWithCenteredArrow
                variant="top"
                title="Download"
                positioning={{ offsetX: '5px' }}
                disableVisibleOnClick
              >
                <Icon
                  data-testid="download-attachment"
                  variant="download"
                  type="regular"
                  height="16px"
                  width="14px"
                  color="#808080"
                  className="z-40 ml-4 cursor-pointer"
                  onClick={() => handleFileAttachmentDownload(fileUrl)}
                />
              </TooltipWithCenteredArrow>
            </div>
          )}
        </div>
      </>
    </div>
  )
}

export default RightPanelSharedFiles
