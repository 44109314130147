import { scrollToMsgInThread } from '../../global-search-section/sections/utils/scrollToMsgInThread'
import { toggleAttachmentsModal } from '../message-header/header-menu/buttons/tri-dot-button-with-menu/tri-dot-menu/modalSlice'

export const fslightboxCustomButtons = ({
  attachmentPreviewFilename,
  attachmentPreviewFileSK,
  setLightboxToggle,
  dispatch,
  isMobile,
}) => {
  return [
    {
      viewBox: '0 0 22 24',
      d: 'M17 24H5C2.51469 24 0.5 21.9853 0.5 19.5V16.125C0.5 15.5016 1.00391 15 1.625 15C2.24656 15 2.75 15.5039 2.75 16.125V19.5C2.75 20.7375 3.7625 21.75 5 21.75H17C18.2375 21.75 19.25 20.7375 19.25 19.5V16.125C19.25 15.5039 19.7539 15 20.375 15C20.9966 15 21.5 15.5039 21.5 16.125V19.5C21.5 21.9844 19.4844 24 17 24ZM11.7687 16.1953L18.1437 10.1953C18.5979 9.76903 18.6181 9.05719 18.1921 8.60578C17.7673 8.15316 17.0554 8.13141 16.6025 8.55745L12.125 12.7687V1.125C12.125 0.503906 11.6234 0 11 0C10.3766 0 9.875 0.503906 9.875 1.125V12.7687L5.39375 8.55469C4.94281 8.12813 4.23078 8.15156 3.80609 8.60625C3.60125 8.82188 3.5 9.09844 3.5 9.375C3.5 9.67383 3.61864 9.97266 3.85447 10.1939L10.2295 16.1939C10.6625 16.6031 11.3375 16.6031 11.7687 16.1953Z',
      width: '16px',
      height: '16px',
      title: 'Download',
      onClick: async function (instance) {
        var URL = instance.props.sources[instance.stageIndexes.current]

        await fetch(URL)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = attachmentPreviewFilename
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
            return blob
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('An error occurred while downloading the file:', error)
          })
      },
    },
    {
      viewBox: '0 0 24 24',
      d: 'M23.3358 4.59281L19.4034 0.660938C18.9816 0.239063 18.4097 0 17.8148 0H8.24953C7.00781 0.0046875 6 1.0125 6 2.25422V12.3783H0.5625C0.251719 12.3783 0 12.63 0 12.9408V14.0658C0 14.3766 0.251719 14.6283 0.5625 14.6283H13.5V16.9195C13.5 17.4216 14.108 17.6719 14.4619 17.3156L17.8261 13.9261C18.0581 13.6922 18.0581 13.3148 17.8261 13.0809L14.4619 9.69141C14.108 9.33516 13.5 9.58547 13.5 10.0875V12.3787H8.24953V2.25422H15.7481V7.12828C15.7481 7.75172 16.2497 8.25281 16.8731 8.25281H21.75V21.7505H8.24953V16.8783H6V21.7505C6 22.9922 7.00781 24 8.24953 24H21.7467C22.9889 24 24 22.9922 24 21.7505V6.18609C24 5.59125 23.7577 5.01469 23.3358 4.59281ZM17.9977 6.00328V2.43703L21.5644 6.00328H17.9977Z',
      width: '16px',
      height: '16px',
      title: 'Go to file',
      onClick: () => {
        setLightboxToggle(0)
        scrollToMsgInThread(attachmentPreviewFileSK)(
          isMobile && dispatch(toggleAttachmentsModal(false)),
        )
      },
    },
  ]
}
