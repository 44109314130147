import { useState, useEffect } from 'react'
import { Icon } from '@thryvlabs/maverick'
import EditContactForm from './edit-contact-form/EditContactForm'
import useAvatarComponent from '../../../../hooks/useAvatarComponent'

const EditContactPanel = ({
  contactName = '',
  handleEditEmail,
  handleEditPhone,
  enableEmailInput,
  enablePhoneInput,
  setContactInfoView,
  setIsEditPanelOpen,
  setEnablePhoneInput,
  setEnableEmailInput,
  setClearEmail,
  setClearPhone,
  contact,
  refetch,
  isInbox = false,
  refetchContacts,
  editingCall,
  isEditing,
  setIsEditing,
  isMobile,
  setShowMobileContact,
  getThreads,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [editingContact, setEditingContact] = useState({
    id: '',
    username: '',
    phone: '',
    email: '',
  })

  const [contactDetails] = useState(
    contact ? JSON.parse(contact?.queryContactsByThread?.items[0]?.body) : null,
  )

  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'default',
    picture_url: contactDetails?.picture_url,
    contactName: contactName.toUpperCase(),
  })

  useEffect(() => {
    setEditingContact(contact)
  }, [])

  const handleClickOnBack = () => {
    setContactInfoView('default')
    setEnableEmailInput && setEnableEmailInput(false)
    setEnablePhoneInput && setEnablePhoneInput(false)
    setIsEditPanelOpen && setIsEditPanelOpen(false)
    setClearEmail && setClearEmail(false)
    setClearPhone && setClearPhone(false)
    setShowMobileContact && setShowMobileContact(true)
    refetch()
  }

  const ChevronLeftIcon = ({ isEditing }) => (
    <Icon
      type="solid"
      variant="chevronLeft"
      height="16.84"
      width="8.7"
      color={isEditing ? '#5378F7' : '#4D4D4D'}
    />
  )

  return (
    <>
      {!contact ? (
        // Loading
        <div
          className={
            isInbox
              ? 'flex flex-col items-center w-full h-full bg-white absolute'
              : `flex flex-col items-center ${
                  setContactInfoView
                    ? 'w-[350px] border-l border-[#F1F1F5]'
                    : 'w-[275px]'
                } h-full bg-white absolute `
          }
        />
      ) : (
        // Loading completed
        <div
          className={
            isInbox
              ? isMobile
                ? 'flex flex-col items-center w-full absolute p-5'
                : 'flex flex-col items-center w-full h-full bg-white absolute'
              : `flex flex-col items-center ${
                  setContactInfoView
                    ? 'w-[350px] border-l border-[#F1F1F5]'
                    : 'w-[275px]'
                } h-full bg-white absolute `
          }
        >
          {/* Back Button */}
          <div
            onClick={handleClickOnBack}
            className={
              isInbox
                ? isMobile
                  ? 'cursor-pointer mr-auto flex flex-row items-center'
                  : 'cursor-pointer left-[0px] top-[5px] absolute flex flex-row items-center'
                : `${
                    setContactInfoView ? 'left-[27px] top-[25px]' : 'left-[0px]'
                  } absolute cursor-pointer flex flex-row items-center`
            }
          >
            <ChevronLeftIcon isEditing={isEditing} />
            <button
              className="ml-3 text-sm font-montserrat font-semibold"
              onClick={handleClickOnBack}
              data-testid="contact-card-back-btn"
            >
              Back
            </button>
          </div>

          {/* Heading */}
          <div
            className={
              isInbox
                ? isMobile
                  ? 'm-auto flex flex-col items-center'
                  : 'mt-[47px] mr-[50px] flex flex-col items-center md:ml-auto'
                : `${
                    setContactInfoView ? 'mt-[72px]' : 'mt-[47px]'
                  } flex flex-col items-center md:ml-auto`
            }
          >
            <RenderUserProfile />
            <span className="mt-4 font-montserrat font-semibold text-base">
              {contactName}
            </span>
            <EditContactForm
              contact={contact}
              contactName={contactName?.trim()}
              handleEditPhone={handleEditPhone}
              handleEditEmail={handleEditEmail}
              editingContact={contact?.queryContactsByThread?.items[0]}
              setContactInfoView={setContactInfoView}
              setIsEditPanelOpen={setIsEditPanelOpen}
              setIsEditing={setIsEditing}
              handleClickOnBack={handleClickOnBack}
              enableEmailInput={enableEmailInput}
              enablePhoneInput={enablePhoneInput}
              refetchContacts={refetchContacts}
              refetch={refetch}
              editingCall={editingCall}
              getThreads={getThreads}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default EditContactPanel
