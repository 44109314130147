import './reset-password-modal.css'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Icon, ParagraphText } from '@thryvlabs/maverick'

const CloseModalButton = ({ onClick, height, width }) => (
  <button onClick={onClick}>
    <Icon
      variant="x"
      type="regular"
      width={width || '18'}
      height={height || '18'}
      color="#A3A5A7"
    />
  </button>
)

const ResetPasswordEmailModal = ({ close }) => {
  const { VITE_AUTH0_CLIENT_ID, VITE_AUTH0_DOMAIN } = import.meta.env
  const { user, logout } = useAuth0()
  const [message, setMessage] = useState('')

  const handleResetPassword = async () => {
    try {
      const response = await fetch(
        `https://${VITE_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            client_id: VITE_AUTH0_CLIENT_ID,
            email: user.email,
            connection: 'Username-Password-Authentication',
          }),
        },
      )

      if (response.ok) {
        setMessage(
          'Password reset email has been sent. Logging out of Command Center.',
        )
        setTimeout(() => {
          logout({ returnTo: window.location.origin })
        }, 3000)
      } else {
        setMessage('Failed to send password reset email.')
      }
    } catch (error) {
      setMessage('An error occurred.')
    }
  }

  return (
    <div className="reset-password-modal sm:max-w-[300px]">
      <div className="flex justify-between mb-[20px]">
        <h4 className="leading-[30px] text-thryv-black-500 text-[22px] font-[Montserrat] font-semibold">
          Reset Password
        </h4>
        <CloseModalButton onClick={close} width={12} height={12} />
      </div>
      {message ? (
        <>
          <ParagraphText
            variant="reg"
            color="thryv-black-500"
            className="text-start mb-[20px]"
          >
            {message}
          </ParagraphText>
          <Button variant="secondary" type="click" onClick={close}>
            Close
          </Button>
        </>
      ) : (
        <>
          <ParagraphText
            variant="reg"
            color="thryv-black-500"
            className="text-start mb-[20px]"
          >
            Click the button below and we will send a link to your email address to
            reset your password.
          </ParagraphText>
          <Button variant="primary" type="click" onClick={handleResetPassword}>
            Send Email
          </Button>
        </>
      )}
    </div>
  )
}

export default ResetPasswordEmailModal
