import { useDispatch } from 'react-redux'
import './filter-modal.css'
import { useSelector } from 'react-redux'
import { useActiveChannels } from '../../../../../../../hooks/use-active-channels'
import {
  setInboxAdvancedFilters,
  setInboxFilter,
} from '../../../../../slices/inboxThreadsSlice'

// Components
import { Button, Header, ModalWrapper, Icon } from '@thryvlabs/maverick'
import { FilterItemGroup } from '../../../sections/filter-section/common/filter-item-group'
import {
  // LabelOption,
  ChannelOption,
  DateOption,
  // AttachmentOption,
  SearchInOption,
} from '../../../sections/filter-section/options'

export const FilterModal = ({ show, close }) => {
  const dispatch = useDispatch()
  const { inboxAdvancedFilters } = useSelector((state) => state.inboxThreads)

  const activeChannels = useActiveChannels()
  const channelProviders = activeChannels.map((channel) => channel.Provider)

  const getFormData = () => {
    // for (const pair of formData.entries()) {
    //   const key = pair[0]
    //   const val = pair[1]
    //   if (key === 'search in') formDataObject[key] = val
    //   if (key === 'date') formDataObject[key] = JSON.parse(val)
    //   if (key === 'channel') formDataObject[key] = JSON.parse(val)
    //   if (key === 'label') formDataObject[key] = JSON.parse(val)
    // }
    // return formDataObject
  }

  const startSearch = (e) => {
    e.preventDefault()
    const formData = getFormData(e.target)

    const refactoredFormData = {
      folder: formData['search in'],
      channels: formData.channel,
      labels: formData.label,
      date: formData.date,
    }

    dispatch(setInboxAdvancedFilters(refactoredFormData))
    dispatch(setInboxFilter(refactoredFormData.folder))
    close()
  }

  return (
    <ModalWrapper show={show} disableBackdropClick={true}>
      <div className="inbox-menu-search-filter-modal">
        <div className="flex justify-end mb-2">
          <button type="button" onClick={close}>
            <Icon
              variant="x"
              type="regular"
              color="#A3A5A7"
              width="18"
              height="18"
            />
          </button>
        </div>
        <Header fontWeight="semibold" variant="h6" className="mb-11">
          Advanced Search
        </Header>
        <form onSubmit={(e) => startSearch(e)}>
          <FilterItemGroup className="gap-[53px] text-left mb-14">
            <SearchInOption defaultFilter={inboxAdvancedFilters.folder} />
            {/* REVISITING LABELS. */}
            {/* <LabelOption initialLabels={labels} /> */}
            <ChannelOption
              defaultChannels={channelProviders}
              defaultSelected={inboxAdvancedFilters.channels}
            />
            <DateOption
              altDatePicker={true}
              type={inboxAdvancedFilters.date.type}
              defaultDate={inboxAdvancedFilters.date.dates}
            />
            {/* REVISITING ATTACHMENTS POST-LAUNCH */}
            {/* <AttachmentOption /> */}
          </FilterItemGroup>

          <Button variant="primary" type="submit">
            Search
          </Button>
        </form>
      </div>
    </ModalWrapper>
  )
}
