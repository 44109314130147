import { useCallback, useEffect, useState } from 'react'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { getMainDefinition } from '@apollo/client/utilities'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

const {
  VITE_BASE_API_URL: uri,
  VITE_GRAPHQL_TOKEN = 'da2-tyw2wo7sord2rkx4u6dbbzq4qm',
} = import.meta.env

const httpLink = new HttpLink({
  uri: (operation) => `${uri}?${operation.operationName}`,
})
const cache = new InMemoryCache()
const region = 'us-east-1'

const authMiddleware = (accessToken, apiKey) =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`,
        'x-api-key': apiKey,
      },
    })

    return forward(operation)
  })

export const useAuthenticatedApolloClient = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState(null)

  const getAccessToken = useCallback(async () => {
    if (!accessToken) {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
    }
  }, [accessToken, getAccessTokenSilently])

  useEffect(() => {
    getAccessToken()
  }, [getAccessToken])

  const wsLink = ApolloLink.from([
    createSubscriptionHandshakeLink(
      {
        url: uri,
        region,
        auth: {
          type: 'AWS_LAMBDA',
          token: `Bearer ${accessToken}`,
        },
      },
      httpLink,
    ),
  ])

  // const apiKey = user?.cc_api_key
  const apiKey = VITE_GRAPHQL_TOKEN

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    authMiddleware(accessToken, apiKey).concat(httpLink),
  )

  const client = new ApolloClient({ link: splitLink, cache })

  return { client }
}
