import { gql } from '@apollo/client'

export const GET_RECURLY_ACCOUNT_INFO = gql`
  query get_recurly_account_info($CCID: String!) {
    queryRecurlyAccountInfo(CCID: $CCID) {
      code
      firstName
      lastName
      state
      activePlanCode
      billingInfo {
        id
        accountId
        primaryPaymentMethod
        paymentMethod {
          cardType
          firstSix
          lastFour
          expMonth
          expYear
        }
        address {
          street1
          street2
          city
          region
          postalCode
        }
      }
    }
  }
`

export const GET_RECURLY_SUBSCRIPTION_TAX = gql`
  query get_recurly_subscription_tax(
    $CCID: String!
    $planCode: String!
    $billingInfoId: String!
    $NumberofSeats: String!
  ) {
    queryRecurlySubscriptionTaxes(
      CCID: $CCID
      planCode: $planCode
      billingInfoId: $billingInfoId
      NumberofSeats: $NumberofSeats
    ) {
      amount
      type
      rate
      region
    }
  }
`

export const GET_RECURLY_PLAN_TYPE = gql`
query get_recurly_account_info($CCID: String!) {
  queryRecurlyAccountInfo(CCID: $CCID) {
    activePlanCode
  }
}
`
