import { useRef, useState, forwardRef } from 'react'
import { QuillToolbar } from './quill-editor/quill-toolbar'
import { QuillEditor } from './quill-editor/quill-editor'
import { useDropzone } from 'react-dropzone'
import { useEffect } from 'react'
import { Dropzone } from './attachments/dropzone'
import { UploadedFiles } from './attachments/uploaded-files'
import 'react-awesome-lightbox/build/style.css'
import 'react-quill/dist/quill.snow.css'
import './quill-editor/quill-styles.css'
import './emojis/emoji-menu-styles.css'
import { useSelector, useDispatch } from 'react-redux'
import {
  setBccEmailRecipients,
  setCcEmailRecipients,
  setIsEditorFocused,
  setEditorView,
  setSubjectText,
  handleClickAttachment,
  handleShowAttachmentPreview,
  handleCloseLightbox,
  setFileType,
} from '../../slices/messageComposeSlice'
import { useMutation, useApolloClient, useLazyQuery } from '@apollo/client'
import { GET_S3_UPLOAD_LINK } from '../../../../graphql/queries/inbox-queries'
import { CREATE_DRAFT_MUTATION } from '../../../../graphql/mutations/inbox-draft-mutations'
import {
  setEditorContents,
  setAttachmentFilesToDelete,
} from '../../slices/messageComposeSlice'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import {
  handleReloadMessage,
  setDraftPk1,
  setError,
  setDraftChanPk,
  setConfirmAttachmentData,
} from '../../slices/inboxSlice'
import { DELETE_MESSAGE } from '../../../../graphql'
import FiletypeError from './FiletypeError.png'
import { DELETE_ATTACHMENT } from '../../../../graphql/mutations/delete-attachment-mutation'
import { CONFIRM_ATTACHMENT } from '../../../../graphql/mutations/inbox-draft-mutations'
import {
  allowedApplicationTypes,
  allowedImgTypes,
  allowedTextTypes,
  allowedVideoTypes,
} from './attachments/allowed-fileTypes'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { FETCH_THREADS } from '../../../../graphql/queries/inbox-queries'
import { useInboxMenuConversations } from '../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import FsLightbox from 'fslightbox-react'
import { useMediaQuery } from 'react-responsive'

// Components
import { ComposeHeader } from './compose-header/compose-header'
import { ChannelsButton } from './channels-button/channels-button'
import { RightActionButtons } from './right-action-buttons/right-action-buttons'
import { LeftActionButtons } from './left-action-buttons/action-buttons'
import { Button, ParagraphText, Icon } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../command-center-icon'
import { HyperlinkCustomTooltip } from './hyperlinks/hyperlink-custom-tooltip'
import { fslightboxCustomButtons } from '../utils/fslightboxCustomButtons'

export const MessageCompose = forwardRef(
  (
    {
      className,
      messages,
      limitAttachmentError,
      setLimitAttachmentError,
      draftMessageData,
      setDraftMessageData,
      refetchMessages,
    },
    ref,
  ) => {
    const {
      editorText,
      editorHTML,
      editorView,
      attachmentPreviewUrl,
      showAttachmentPreview,
      selectedChannel,
      replying,
      message,
      subjectText,
      fileTypeToRender,
      sendButtonClicked,
      filenameToRender,
      attachmentFilesToDelete,
    } = useSelector((s) => s.messageCompose)

    const { recipient, draftPk1, isLoading, draftChanPk } = useSelector(
      (state) => state.inbox,
    )
    const createMessageError = useSelector((state) => state.inbox.error.status)

    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )
    const emailCC = useSelector(
      (state) => state.messageCompose.message.emailRecipients.cc,
    )
    const emailBCC = useSelector(
      (state) => state.messageCompose.message.emailRecipients.bcc,
    )
    const isViewingWelcomeMessage = useSelector(
      (state) => state.welcome.isViewingWelcomeMessage,
    )

    const {
      displayHyperlinkTooltip,
      attachmentPreviewFilename,
      attachmentPreviewFileSK,
    } = useSelector((state) => state.messageCompose)

    const isMobile = useMediaQuery({ maxWidth: 835 })

    const dispatch = useDispatch()
    const { body, email_subject, email_cc, email_bcc, attachments } =
      draftMessageData || {}

    const { user } = useAuth0()
    const isMounted = useRef(false)
    const getAllEmailCcs = () => {
      return emailCC?.map((e) => {
        return { name: e.name, email: e.email }
      })
    }
    const getAllEmailBcs = () => {
      return emailBCC?.map((e) => {
        return { name: e.name, email: e.email }
      })
    }

    const [uploadedFiles, setUploadedFiles] = useState([])
    // const [attachmentFilesToDelete, setAttachmentFilesToDelete] = useState([])
    const [draftMutationBody, setDraftMutationBody] = useState(null)
    const { channelDisconnected, showChannelDisconnectedNotification } = useSelector(
      (state) => state.connectChannels,
    )

    const phoneComposeRef = useRef(null)

    const { createVariables, initMessageThreads, initPinnedMessageThreads } =
      useInboxMenuConversations()

    const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      variables: createVariables(),
      onCompleted: () => {
        const threads = threadsData?.queryThreads.items
        initMessageThreads(threads)
        const pinned = threadsData?.pinned?.items
        initPinnedMessageThreads(pinned)
      },
    })

    const [create_draft] = useMutation(CREATE_DRAFT_MUTATION, {
      variables: {
        body: editorHTML,
        email_snippet: editorText,
        email_subject: subjectText,
        thread_id: selectedThreadId,
        channel_id: selectedChannel?.ChannelID,
        email_to: { name: '', email: recipient.email },
        email_cc: getAllEmailCcs(),
        email_bcc: getAllEmailBcs(),
        email_from: {
          name: selectedChannel?.AccountUserValue,
          email: selectedChannel?.AccountUserValue,
        },
      },
      onCompleted: (data) => {
        if (!isLoading || !sendButtonClicked) {
          dispatch(setDraftPk1(data.createDraftMessage.pk1))
          dispatch(setDraftChanPk(data.createDraftMessage.chanpk))
          setDraftMutationBody(data.createDraftMessage.body)
          getThreads()
        }
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.log('data error create draft:', error)
      },
    })

    const [deleteMessage] = useMutation(DELETE_MESSAGE, {
      variables: {
        pk1: draftPk1,
        sk1: 9999999999999,
        channel_id: draftChanPk,
        thread_id: selectedThreadId,
      },
      onCompleted: () => {
        getThreads()
      },
    })

    //changing the accept files for text based on this: https://react-dropzone.js.org/#browser-limitations
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone(
      {
        noClick: true,
        accept: {
          'image/jpeg': allowedImgTypes,
          'video/quicktime': allowedVideoTypes,
          'application/pdf': allowedApplicationTypes,
          'text/*': allowedTextTypes,
        },
        autoProcessQueue: true,
        parallelUploads: 1,
      },
    )

    useEffect(() => {
      const whitespaceAndIndentRegex =
        /^<p class="ql-indent-[1-8]">(?:<br>|\s*)<\/p>$/
      const onlyWhitespaceRegex = /^\s*$/
      if (isLoading) return
      if (
        (selectedChannel &&
          !sendButtonClicked &&
          editorHTML !== undefined &&
          editorHTML !== '<p><br></p>' &&
          editorHTML !== '' &&
          editorHTML !== '<p> </p>' &&
          !whitespaceAndIndentRegex.test(editorHTML?.trim()) &&
          !editorHTML?.trim().match(/^<p>\s*<\/p>$/)) ||
        uploadedFiles.length > 0 ||
        (subjectText !== '' && !onlyWhitespaceRegex.test(subjectText)) ||
        getAllEmailCcs()?.length > 0 ||
        getAllEmailBcs()?.length > 0
      ) {
        const draftTimeOut = setTimeout(() => {
          create_draft()
        }, 1000)
        return () => {
          clearTimeout(draftTimeOut)
        }
      }

      if (
        body !== undefined ||
        (draftMutationBody !== null &&
          (whitespaceAndIndentRegex.test(editorHTML?.trim()) ||
            editorHTML?.trim().match(/^<p>\s*<\/p>$/) ||
            editorHTML === '<p><br></p>' ||
            editorHTML === '' ||
            editorHTML === '<p> </p>') &&
          editorText === '' &&
          (subjectText === '' || onlyWhitespaceRegex.test(subjectText)) &&
          getAllEmailCcs()?.length === 0 &&
          getAllEmailBcs()?.length === 0 &&
          !sendButtonClicked &&
          uploadedFiles.length === 0)
      ) {
        const draftTimeOut = setTimeout(() => {
          deleteMessage()
        }, 500)
        return () => {
          clearTimeout(draftTimeOut)
        }
      }
      if (createMessageError && editorText !== '') {
        deleteMessage()
      }
    }, [
      body,
      editorHTML,
      editorText,
      subjectText,
      emailCC,
      emailBCC,
      sendButtonClicked,
      acceptedFiles,
      uploadedFiles,
      isLoading,
    ])

    useEffect(() => {
      if (editorView === 'open' && createMessageError) {
        deleteMessage()
        dispatch(setError({ status: false, message: '' }))
      }
    }, [editorView])

    useEffect(() => {
      dispatch(
        setEditorContents({
          text: '',
          html: body,
        }),
      )
      isMounted.current = false
      setUploadedFiles([])
      dispatch(setEditorView('closed'))
      dispatch(setSubjectText(''))
      dispatch(setCcEmailRecipients([]))
      dispatch(setBccEmailRecipients([]))
      setAttachmentLimit(0)
      setLimitAttachmentError(false)
      setDraftMutationBody(null)
    }, [selectedThreadId])

    useEffect(() => {
      if (
        quillEditorRef.current &&
        !quillEditorRef.current.editor.hasFocus() &&
        !sendButtonClicked
      ) {
        // editorHTML, not editorText dictates editor content. HTML will update, then update editorText field when quill onChange runs.
        dispatch(
          setEditorContents({
            text: editorText,
            html: body || '',
          }),
        )
        dispatch(setSubjectText(email_subject || ''))
        dispatch(setCcEmailRecipients(email_cc || []))
        dispatch(setBccEmailRecipients(email_bcc || []))
        setUploadedFiles(attachments?.items || [])
        dispatch(setAttachmentFilesToDelete(attachments?.items || []))
      }
      if (
        (editorView === 'closed' && body) ||
        email_subject ||
        email_cc ||
        email_bcc ||
        attachments?.items
      ) {
        dispatch(setEditorView('open'))
        dispatch(handleReloadMessage(false))
      }
      if (body && sendButtonClicked) {
        dispatch(setEditorView('closed'))
      }
    }, [body, sendButtonClicked])

    const sunshineChannels = ['facebook', 'instagram', 'webchat']

    const limitedOptions =
      selectedChannel?.Provider === 'phone' ||
      selectedChannel?.Provider === 'facebook' ||
      selectedChannel?.Provider === 'instagram' ||
      selectedChannel?.Provider === 'webchat'

    const [showLastMessage, setShowLastMessage] = useState(false)

    const quillEditorRef = useRef(null)

    // handle editor focus
    useEffect(() => {
      if (editorView !== 'closed') {
        quillEditorRef.current.editor.focus()
      }
      if (editorView !== 'closed' && quillEditorRef.current) {
        const quillEditor = quillEditorRef.current.getEditor()
        quillEditor.setSelection(quillEditor.getLength(), 0)
        // quillEditor.focus()
      }
      quillEditorRef.current.editor.on('selection-change', (range) => {
        if (range) {
          // If range is not null, editor is focused
          dispatch(setIsEditorFocused(true))
        } else {
          // If range is null, editor is not focused
          dispatch(setIsEditorFocused(false))
        }
      })
    }, [editorView, quillEditorRef.current])

    const [loadingState, setLoadingState] = useState(false)

    const uploadAttachmentToS3 = async (uploadUrl, fileToUpload) => {
      //handles the uploading of the attachment and deletes the uploading animation
      let options = {
        headers: { 'Content-Type': fileToUpload?.type },
      }
      await axios
        .put(uploadUrl.uploadUrl, fileToUpload, options)
        .then(() => {
          setLoadingState(false)
          uploadedFiles.map(() => {
            // let NewUploadedFile = delete file.uploading
          })
          confirmAttachment({
            variables: {
              thread_id: selectedThreadId,
              content_type: fileToUpload?.type,
              filename: fileToUpload?.name,
              sk1: uploadUrl.sk1,
            },
          })
        })
        .catch(() => {
          setLoadingState(false)
          //stop loading state and show user that there wasnt upload error
        })
    }

    const [attachmentsLimit, setAttachmentLimit] = useState(0) //to keep track of the number for limit attachments
    // const [limitAttachmentError, setLimitAttachmentError] = useState(false) //to show error messages and change values depending if this is true or false

    const checkAttachmentLimit = async (size, operation) => {
      if (operation === 'subtract') {
        setAttachmentLimit((a) => a - size)
        const totalLimit = attachmentsLimit - size
        return totalLimit
      } else {
        setAttachmentLimit((a) => a + size)
        const totalLimit = attachmentsLimit + size
        return totalLimit
      }
    }
    const client = useApolloClient()

    const handleUploadAttachments = async (fileToUpload) => {
      const currentLimit = await checkAttachmentLimit(fileToUpload.size)
      const formattedLimit = currentLimit / 1000 ** 2
      const HARDLIMIT = 20

      if (formattedLimit.toFixed(1) >= HARDLIMIT) {
        setLimitAttachmentError(true)
        setLoadingState(false)
        setUploadedFiles((previousArr) => previousArr.slice(0, -1))
        checkAttachmentLimit(fileToUpload.size, 'subtract')
      } else {
        const urlResponse = await client.query({
          query: GET_S3_UPLOAD_LINK,
          fetchPolicy: 'network-only',
          variables: {
            content_type: fileToUpload?.type,
            filename: fileToUpload?.name,
            threadid: selectedThreadId,
          },
        })
        setLimitAttachmentError(false)
        try {
          uploadAttachmentToS3(urlResponse.data.getSafeS3UploadURL, fileToUpload)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error when getting link?', error)
        }
      }
    }

    useEffect(() => {
      if (acceptedFiles?.length > 0) {
        const existingFilePaths = uploadedFiles?.map((file) => file.path)
        setUploadedFiles([
          ...uploadedFiles,
          ...acceptedFiles
            .map((file) =>
              Object.assign(file, {
                previewUrl: URL.createObjectURL(file),
                uploading: true,
              }),
            )
            .filter((file) => !existingFilePaths.includes(file.path)),
        ])
      }
      if (acceptedFiles?.length > 0) {
        setLoadingState(true)
        for (let i = 0; i < acceptedFiles?.length; i++) {
          handleUploadAttachments(acceptedFiles[i])
        }
      }
    }, [acceptedFiles])

    const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
      onCompleted: () => {},
    })

    const [confirmAttachment, { error: confirmAttachmentError }] = useMutation(
      CONFIRM_ATTACHMENT,
      {
        onCompleted: (data) => {
          dispatch(
            setAttachmentFilesToDelete([
              ...attachmentFilesToDelete,
              data?.confirmAttachment,
            ]),
          )

          const body = data.confirmAttachment.body
          const pk1 = data.confirmAttachment.pk1
          const sk1 = data.confirmAttachment.sk1
          dispatch(setConfirmAttachmentData({ body: body, pk1: pk1, sk1: sk1 }))
        },
        onError: () => {
          // eslint-disable-next-line no-console
          console.log('HERE data confirm attachment error:', confirmAttachmentError)
        },
      },
    )

    const handleRemoveAttachment = async (file, index) => {
      if (file?.pk1) {
        const placeholderFileToRemove = attachmentFilesToDelete?.filter(
          (x) => x.sk1 !== file.sk1,
        )
        await deleteAttachment({
          variables: {
            pk1: file?.pk1,
            sk1: file?.sk1,
          },
        })
        dispatch(setAttachmentFilesToDelete(placeholderFileToRemove))
      } else {
        const fileToRemove = attachmentFilesToDelete?.filter(
          (x) => JSON.parse(x?.body)?.filename === file?.path,
        )
        const placeholderFileToRemove = attachmentFilesToDelete?.filter(
          (x) => JSON.parse(x?.body)?.filename !== file?.path,
        )
        await deleteAttachment({
          variables: {
            pk1: fileToRemove[0]?.pk1,
            sk1: fileToRemove[0]?.sk1,
          },
        })
        dispatch(setAttachmentFilesToDelete(placeholderFileToRemove))
      }

      const removeAttachment = uploadedFiles?.filter((files, i) => i !== index)
      setUploadedFiles(removeAttachment)
      try {
        const currentLimit = await checkAttachmentLimit(
          uploadedFiles[index].size,
          'subtract',
        )
        const formattedLimit = currentLimit / 1000 ** 2
        const HARDLIMIT = 20
        if (formattedLimit.toFixed(1) < HARDLIMIT) {
          setLimitAttachmentError(false)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('HERE error in catch:', error)
      }
    }

    // const closeNotification = () => {
    //   setInactiveChannel({ ...inactiveChannel, active: false })
    // }

    const [lightboxToggle, setLightboxToggle] = useState(0)

    const handleOpenFile = (fileType, file_id) => {
      dispatch(setFileType(fileType))
      return `${user.cc_id}/DRAFT/${selectedThreadId}/${user.cc_uid}/${file_id}`
    }

    useEffect(() => {
      setLightboxToggle((prev) => prev + 1)
    }, [attachmentPreviewUrl])

    const PDFComponent = () => {
      return (
        <object
          width="1200"
          height="900"
          data={attachmentPreviewUrl}
          type="application/pdf"
        >
          {' '}
        </object>
      )
    }

    // This handles the logic to download attachments and show the error message for attachments that are not working
    useEffect(() => {
      if (showAttachmentPreview && fileTypeToRender?.includes('image')) {
        const image = new Image()
        image.src = attachmentPreviewUrl
        image.onError = () => {
          dispatch(handleClickAttachment({ url: FiletypeError })) //changing image
        }
      }
      if (
        (fileTypeToRender !== 'application/pdf' &&
          fileTypeToRender?.includes('application/')) ||
        fileTypeToRender?.includes('plain/') ||
        fileTypeToRender?.includes('text/')
      ) {
        dispatch(handleShowAttachmentPreview(false))
        axios
          .get(attachmentPreviewUrl, {
            responseType: 'blob',
          })
          .then((res) => {
            saveAs(res.data, filenameToRender)
            dispatch(handleCloseLightbox(false))
          })
      }
    }, [attachmentPreviewUrl, fileTypeToRender])

    const lastMessage = messages[messages?.length - 1] || {}

    const removeHTML = (input) => {
      return input.replace(/<[^>]*>/g, '')
    }
    const sanitizedHTML = editorHTML ? removeHTML(editorHTML) : ''

    const handleScroll = () => {
      sessionStorage.removeItem('scrollPosition')
      setTimeout(() => {
        ref.current.scrollTop = ref.current.scrollHeight
      }, 10)
    }
    const [showCloseButton, setShowCloseButton] = useState(false)
    useEffect(() => {
      const div = phoneComposeRef.current
      if (div) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === div) {
              const height = entry.contentRect.height

              if (height >= 100) {
                setShowCloseButton(true)
              } else {
                setShowCloseButton(false)
              }
            }
          }
        })

        resizeObserver.observe(div)

        return () => {
          resizeObserver.disconnect()
        }
      }
    }, [])

    return (
      <div
        {...getRootProps({})}
        id="lightbox-root"
        // The fslightbox-toolbar-button styling is for hiding the additional buttons that come with FsLightbox Pro
        className={
          isViewingWelcomeMessage
            ? 'hidden'
            : '[&_.fslightbox-toolbar-button:nth-child(3)]:hidden [&_.fslightbox-toolbar-button:nth-child(4)]:hidden [&_.fslightbox-toolbar-button:nth-child(5)]:hidden [&_.fslightbox-toolbar-button:nth-child(6)]:hidden'
        }
      >
        {/* LIGHTBOX */}

        {showAttachmentPreview && !limitedOptions && (
          <FsLightbox
            customToolbarButtons={fslightboxCustomButtons({
              attachmentPreviewFilename,
              attachmentPreviewFileSK,
              setLightboxToggle,
              dispatch,
              isMobile,
            })}
            onClose={() => {
              dispatch(handleShowAttachmentPreview(false))
              dispatch(handleCloseLightbox(false))
            }}
            toggler={lightboxToggle}
            key={attachmentPreviewUrl}
            sources={[
              fileTypeToRender === 'application/pdf' ? (
                <div>
                  <PDFComponent />
                </div>
              ) : (
                attachmentPreviewUrl
              ),
            ]}
          />
        )}

        <div
          className={`${className} min-h-[76px] px-4 flex flex-col sm:w-screen ${
            uploadedFiles?.length >= 1 ? 'gap-y-10' : ''
          } bg-white rounded border ${
            editorView !== 'expanded' ? ' max-h-96' : 'max-h-[34rem]'
          } ${
            isDragActive
              ? 'border-dashed border-[#CECFCF]'
              : 'border-solid border-[#CCCCCC]'
          }  min-h-[72px] px-4 `}
        >
          {/* DROPZONE */}

          {isDragActive && !limitedOptions ? (
            <Dropzone getRootProps={getRootProps} />
          ) : null}

          {/* PHONE CHANNEL EDITOR */}

          <div
            className={`${
              selectedChannel?.Provider === 'phone' ? 'block' : 'hidden '
            } absolute bottom-4 flex gap-4 items-center`}
          >
            <div>
              <ChannelsButton
                channelType={messages[0]?.chan_type}
                lastMessage={lastMessage}
                draftMessageData={draftMessageData}
              />
            </div>

            <ParagraphText
              variant="reg"
              color="thryv-steel"
              onClick={() => {
                dispatch(setEditorView('open'))
                quillEditorRef.current?.editor.focus()
              }}
            >
              {editorView === 'closed' && isLoading ? (
                <ParagraphText variant="reg" color="thryv-steel">
                  Type your message here...
                </ParagraphText>
              ) : (
                editorView === 'closed' && (
                  <ParagraphText variant="reg" color="thryv-steel">
                    {sanitizedHTML.trim().length > 0
                      ? `${sanitizedHTML.substring(0, 80)}${
                          sanitizedHTML.length > 80 ? '...' : ''
                        }`
                      : 'Type your message here...'}
                  </ParagraphText>
                )
              )}
            </ParagraphText>
          </div>

          {selectedChannel?.Provider === 'phone' ? (
            <div className="absolute right-4 z-10">
              <RightActionButtons
                setDraftData={setDraftMessageData}
                loadingAttachments={loadingState}
                quillEditorRef={quillEditorRef}
                setUploadedFiles={setUploadedFiles}
                setLimitAttachmentError={setLimitAttachmentError}
                setAttachmentLimit={setAttachmentLimit}
                uploadedFiles={uploadedFiles}
                refetchMessages={refetchMessages}
                setDraftMutationBody={setDraftMutationBody}
                ref={ref}
              />
            </div>
          ) : null}

          {showCloseButton && selectedChannel?.Provider === 'phone' && (
            <button
              type="button"
              onClick={() => dispatch(setEditorView('closed'))}
              className={` z-10 absolute right-6 top-4`}
              tabIndex={-1}
            >
              <Icon
                variant="x"
                type="regular"
                width="12"
                height="12"
                fill="#808080"
              />
            </button>
          )}

          {/* OPEN EDITOR */}

          <div
            ref={phoneComposeRef}
            className={`${editorView === 'closed' ? 'hidden' : ''} 
            ${
              selectedChannel?.Provider === 'phone'
                ? ' h-full max-h-[168px] relative w-[95%] left-10 ml-5 overflow-hidden'
                : ''
            }
            ${editorView !== 'expanded' ? 'h-[200px]' : 'max-h-[350px]'} ${
              limitedOptions ? 'pt-0 max-h-[174px]' : 'min-h-[230px]'
            } ${
              channelDisconnected?.length > 0 && showChannelDisconnectedNotification
                ? 'pointer-events-none cursor-not-allowed'
                : ''
            }`}
          >
            {selectedChannel?.Provider !== 'phone' && (
              <ComposeHeader
                draftMessageData={body}
                selectedChannel={selectedChannel}
                limitedOptions={limitedOptions}
              />
            )}

            <div
              onClick={() => quillEditorRef.current?.editor.focus()}
              className={`${selectedChannel?.Provider === 'phone' ? 'mt-6' : ''}`}
            >
              <QuillEditor ref={quillEditorRef} />
              {!limitedOptions && (
                <UploadedFiles
                  loadingState={loadingState}
                  uploadedFiles={uploadedFiles}
                  handleRemoveAttachment={handleRemoveAttachment}
                  openLocalFiles={handleOpenFile}
                />
              )}
              {displayHyperlinkTooltip && (
                <HyperlinkCustomTooltip ref={quillEditorRef} />
              )}
            </div>
          </div>

          {/* Show last message Button when Replying to message */}
          {!limitedOptions ? (
            replying && editorView !== 'closed' ? (
              <div className="flex flex-col items-start gap-1 w-full">
                <Button
                  variant="text"
                  level={1}
                  onClick={() => setShowLastMessage(!showLastMessage)}
                >
                  {showLastMessage ? 'Close' : 'Show last message'}
                </Button>
                {showLastMessage ? (
                  <>
                    <ParagraphText variant="sm">
                      {moment(message.sk1).local().format('dddd, MMMM Do')}, at{' '}
                      {message.timestamp}
                    </ParagraphText>
                    <div className="flex items-center w-[85%] ml-1 gap-1">
                      <CommandCenterIcon
                        variant="verticalLine"
                        type="regular"
                        height={32}
                        width={5}
                      />
                      <blockquote>
                        <ParagraphText variant="sm">{message.text}</ParagraphText>
                      </blockquote>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null
          ) : null}

          {/* CHANNELS */}

          <div
            className={`${
              selectedChannel?.Provider === 'phone' ? 'hidden' : ''
            } flex items-center justify-between w-full gap-4 py-3`}
          >
            <div className="flex items-center justify-between gap-12">
              <ChannelsButton
                channelType={messages[0]?.chan_type}
                lastMessage={lastMessage}
              />
              <QuillToolbar />
            </div>

            <div
              id="response-bar-left"
              className="flex items-center gap-4 grow"
              onClick={() => {
                dispatch(setEditorView('open'))
                handleScroll()
              }}
            >
              {editorView === 'closed' && isLoading ? (
                <ParagraphText variant="reg" color="thryv-steel">
                  Type your message here...
                </ParagraphText>
              ) : (
                editorView === 'closed' && (
                  <ParagraphText variant="reg" color="thryv-steel">
                    {sanitizedHTML.trim().length > 0
                      ? `${sanitizedHTML.substring(0, 80)}${
                          sanitizedHTML.length > 80 ? '...' : ''
                        }`
                      : 'Type your message here...'}
                  </ParagraphText>
                )
              )}
            </div>

            {/* ACTION BUTTONS */}

            <div
              id="response-bar-right"
              className="relative flex items-center gap-4 justify-evenly"
            >
              {(editorView === 'open' &&
                !sunshineChannels.includes(selectedChannel?.Provider)) ||
              editorView === 'expanded' ? (
                <LeftActionButtons
                  disableButtons={limitedOptions || editorView === 'closed'}
                  getInputProps={getInputProps} // enables file uploads to be handled by dropzone
                  quillEditorRef={quillEditorRef}
                  limitAttachmentError={limitAttachmentError}
                />
              ) : null}
              {selectedChannel?.Provider !== 'phone' ? (
                <RightActionButtons
                  setDraftData={setDraftMessageData}
                  loadingAttachments={loadingState}
                  quillEditorRef={quillEditorRef}
                  setUploadedFiles={setUploadedFiles}
                  setLimitAttachmentError={setLimitAttachmentError}
                  setAttachmentLimit={setAttachmentLimit}
                  uploadedFiles={uploadedFiles}
                  refetchMessages={refetchMessages}
                  ref={ref}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  },
)
