import WelcomeScreen from '../components/welcome-screen/WelcomeScreen'
import { DynamicPageTitle as Title } from '@title'

const WelcomePageWrapper = () => {
  return (
    <div
      id="cc-welcome-screen"
      className="h-full w-full flex justify-center items-center"
    >
      <Title pageTitle="Command Center | Welcome" />
      <WelcomeScreen />
    </div>
  )
}

export default WelcomePageWrapper
