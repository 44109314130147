import { useState, useEffect } from 'react'
import SharedPhotosPreview from './shared-photos/SharedPhotosPreview'
import SeeAllSharedFiles from './shared-files/SeeAllSharedFiles'
import SeeAllSharedPhotos from './shared-photos/SeeAllSharedPhotos'
import SharedFilesPreview from './shared-files/SharedFilesPreview'
import { SideSheet } from '@thryvlabs/maverick'
import useSharedMediaProps from './shared-photos/useSharedMediaProps'

// Convert epoch to readable date format
export const getEpochToFormattedDate = (epochTimestamp) => {
  const date = new Date(epochTimestamp)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${month}.${day}.${year}`
}

const SharedMedia = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [showAllPhotos, setShowAllPhotos] = useState(false)
  const [showAllFiles, setShowAllFiles] = useState(false)
  const [tab, setTab] = useState('files')
  const [isOpen, setIsOpen] = useState(false)

  const { allSharedFilesProps, mediaProps } = useSharedMediaProps()

  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsOpen(true)
    }
  }, [])

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div>
      {showAllPhotos ? (
        <SeeAllSharedPhotos
          setShowAllPhotos={setShowAllPhotos}
          attachmentData={mediaProps.attachmentData}
        />
      ) : showAllFiles ? (
        <SeeAllSharedFiles
          setShowAllFiles={setShowAllFiles}
          sortedAttachmentData={mediaProps.sortedAttachmentData}
        />
      ) : (
        <div className="relative">
          <>
            <SharedPhotosPreview
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              setShowAllPhotos={setShowAllPhotos}
              attachmentData={mediaProps.attachmentData}
            />
            <SharedFilesPreview
              setShowAllFiles={setShowAllFiles}
              attachmentData={mediaProps.attachmentData}
              loading={mediaProps.loading}
              sortedAttachmentData={mediaProps.sortedAttachmentData}
              attachmentFiles={allSharedFilesProps.attachmentFiles}
              handleFileAttachmentDownload={
                allSharedFilesProps.handleFileAttachmentDownload
              }
            />
          </>
        </div>
      )}
      <SideSheet
        isOpen={isOpen}
        onClose={handleClose}
        positioning={{ top: '10px' }}
        size="mobile"
        className="rounded-t-xl w-full min-w-md:hidden"
      >
        <div>
          {tab === 'photos' ? (
            <SeeAllSharedPhotos
              setShowAllPhotos={setShowAllPhotos}
              setTab={setTab}
            />
          ) : tab === 'files' ? (
            <SeeAllSharedFiles setTab={setTab} setShowAllFiles={setShowAllFiles} />
          ) : (
            <></>
          )}
        </div>
      </SideSheet>
    </div>
  )
}

export default SharedMedia
