import styled from 'styled-components'
import { Header, Icon, ParagraphText, Button } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../command-center-icon'

export const PageWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
`
export const TopContent = styled.div`
  width: 100%;
  background-color: rgb(243 244 246);
  @media (max-width: 640px) {
    background-color: white;
  }
`
export const BottomContent = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
`
/* Rectangle 990 */
export const GreyContainer = styled.div`
  position: relative;
  width: 80%;
  background: rgba(217, 217, 217, 0.36);
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    width: 100%;
    border-radius: 0;
    height: 55%;
  }
`
export const IconContainer = styled.div`
  margin-left: -1rem;
  @media (max-width: 640px) {
    height: 20%;
    width: 80%;
    margin-left: -1.2rem;
  }
`
export const ThryvLogo = styled(Icon)`
  margin-left: 1.5rem;
  width: 100%;
  height: auto;
  transform: scale(2);
  @media (max-width: 640px) {
    width: fit-content;
    height: 90%;
    transform: scale(1.5);
  }
`
export const VideoContainer = styled.div`
  height: fit-content;
  position: absolute;
  top: 50;
  right: 0;
  transform: translateX(-10%);
  border-radius: 12px;
  @media (max-width: 640px) {
    position: inherit;
    transform: none;
    width: 90%;
    margin-left: 6%;
    height: 50%;
  }
`

export const VideoPlayer = styled.video`
  width: 500px;
  border-radius: 12px;
  border: 2px solid #ff5000;
  @media (max-width: 640px) {
    width: 100%;
  }
`

export const DarkerText = styled.span`
  font-weight: 600;
  line-height: 22px;
  color: #000000;
`
export const ColoredHeader = styled(Header)`
  color: #4d4d4d;
  text-transform: inherit;
  width: 838px;
  @media (max-width: 640px) {
    width: 600px;
  }
`
export const ChannelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    margin-left: 0;
  }
`
export const RoundedBtn = styled(Button)`
  border-radius: 56px !important;
  @media (max-width: 640px) {
    font-size: large;
    padding: 1rem;
  }
`
export const ChannelLinksContainer = styled.div`
  margin-top: 1%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: auto auto auto auto auto;
  gap: 45px;
  max-width: 900px;
  @media (max-width: 640px) {
    width: unset;
  }
`

export const BottomBtn = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 140px;
  font-size: 10px;
  text-transform: unset !important;
  @media (max-width: 640px) {
    margin: 0 auto;
  }
`

export const LogoContainer = styled.div`
  height: 60px;
  width: 60px;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fb;
  border-radius: 20px;
 cursor-pointer;
  @media (max-width: 640px) {
   
  }
`

export const CommandCenterIconResponsive = styled(CommandCenterIcon)`
  width: 28px;
  height: 28px;
  @media (max-width: 640px) {
    width: 45px;
    height: 45px;
  }
`
export const ParagraphTextStyled = styled(ParagraphText)`
  width: 115px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: 640px) {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
  }
`
export const ParagraphTextStyledSmaller = styled.div`
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 600;
`
