/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react'
import { connectClient } from '../../Amity/amityClient'
import { useAuth0 } from '@auth0/auth0-react'
import {
  ChannelRepository,
  ChannelFilter,
  ChannelType,
  ChannelSortingMethod,
} from '@amityco/js-sdk'
import { setTeamchatNotifications } from '../../../redux-toolkit/slices/notifications/notifications-slice'
import { useDispatch } from 'react-redux'

export const useTeamchatNotifications = (inTeamchat = false) => {
  const collection = useRef(null)
  const dispatch = useDispatch()
  const [connected, setConnected] = useState(false)
  const [refetchFromAmity, setRefetchFromAmity] = useState(false)

  const { user } = useAuth0()
  const thryv_staff_id = user?.staffUID

  const TEAMCHAT_IFRAME_ORIGINS = [
    'https://teamchat.thryv.com',
    'https://qa-teamchat.staging.thryv.com',
    'https://main.d39y9btfsmzvsf.amplifyapp.com',
  ]
  if (import.meta.env.VITE_ENV === 'development')
    TEAMCHAT_IFRAME_ORIGINS.push('http://localhost:3000', 'http://localhost:3001')

  // Register Session with Amity
  useEffect(() => {
    const connectToAmity = async () => {
      try {
        await connectClient(thryv_staff_id)
        setConnected(true)
      } catch (err) {
        console.log('Error connecting to Amity: ', err)
      }
    }
    if (inTeamchat && !connected && user?.staffUID) {
      connectToAmity()
    }
  }, [inTeamchat, connected])

  // Fetch Unreads and Add to Left Nav Pill
  useEffect(() => {
    let teamchatIframe = document.getElementById('teamchat-iframe')
    const isTeamchatIframeOpen = teamchatIframe !== null
    setRefetchFromAmity(false)
    if (connected) {
      collection.current = ChannelRepository.queryChannels({
        types: [
          ChannelType.Standard,
          ChannelType.Community,
          ChannelType.Live,
          ChannelType.Conversation,
        ],
        filter: ChannelFilter.Member,
        excludeTags: ['deleted', 'archived'],
        sortBy: ChannelSortingMethod.FirstCreated,
      })

      // NOTE: The 'dataUpdated' dispatch updates TC unreads when TC iframe is not open,
      // whereas the second dispatch updates unreads more accurately when TC iframe is open
      collection.current.on('dataUpdated', (models) => {
        if (!isTeamchatIframeOpen) {
          const unreadCount = models.reduce((acc, model) => {
            return acc + model.unreadCount
          }, 0)
          dispatch(setTeamchatNotifications(unreadCount))
        }
      })

      if (collection.current?.models.length > 0) {
        if (isTeamchatIframeOpen) {
          dispatch(
            setTeamchatNotifications(
              collection.current.models.reduce((acc, model) => {
                return acc + model.unreadCount
              }, 0),
            ),
          )
        }
      }

      return () => collection.current.dispose()
    }
  }, [connected, refetchFromAmity])

  // If TeamChat is open, listen for updates from iframe in case SDK doesn't catch them or is slow
  useEffect(() => {
    const handleMessage = (event) => {
      if (
        TEAMCHAT_IFRAME_ORIGINS.includes(event?.origin) &&
        typeof event.data?.unreadCount === 'number'
      ) {
        console.log('Postmessage: unreads from TC: ', event.data.unreadCount)
        dispatch(setTeamchatNotifications(event.data.unreadCount))
        setRefetchFromAmity(true)
      }
    }
    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [dispatch])
}
