import { useRef, useState, useEffect } from 'react'
import { Header, ParagraphText, Icon, Button, Input } from '@thryvlabs/maverick'
import { ThryvCCOrangeLogoIcon } from '../../../command-center-icon/icons'
import { ThryvCommandCenterIcon } from '../../../command-center-icon/icons'
import { CommandCenterIcon } from '../../../command-center-icon'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { setModalContent } from '../slices/connectChannelsSlice'
import { useClickOutside } from '../../../../hooks/use-click-outside'
import { setShowModal } from '../slices/connectChannelsSlice'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingSpinner } from '../../loading-spinner'

const ImapModal = ({ clickedID }) => {
  const { VITE_AUTH0_REDIRECT_URI, VITE_NYLAS_HOST } = import.meta.env
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const isXSmallMobile = useMediaQuery({ maxWidth: 280 })
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState()
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const cardData = useSelector((state) => state.connectChannels.cardData)
  const imapRef = useRef(null)
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      email: '',
    },
  })
  const email = watch('email')

  const ValidateForm = () => {
    return (
      <>
        {!loading ? (
          <Button variant="primary" type="submit" disabled={email === ''}>
            Log In
          </Button>
        ) : (
          <LoadingSpinner className={'ml-10'} />
        )}
      </>
    )
  }

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()

        setToken(accessToken)
      } catch (err) {
        //
      }
    }

    getToken()
  }, [getAccessTokenSilently])

  useClickOutside((e) => {
    const imapRefClicked = e.composedPath().includes(imapRef.current)
    if (imapRefClicked) return
    else {
      dispatch(setModalContent(1))
      dispatch(setShowModal(false))
    }
  }, imapRef)

  return (
    <div
      className=" px-10 pb-[50px] w-full flex flex-col items-center"
      ref={imapRef}
    >
      <div className="py-[30px] sm:pt-[10px] sm:pb-[15px] flex w-full justify-center relative">
        <ThryvCommandCenterIcon width={isMobile ? 150 : 199} />
        <Icon
          type="regular"
          variant="x"
          height="18"
          fill="#A3A5A7"
          className="absolute -right-5 top-9 sm:top-4 sm:-right-8 xx-sm:-right-10 md:right-0 cursor-pointer"
          onClick={() => dispatch(setModalContent(1))}
        />
      </div>

      <div className="w-[496px] sm:w-[300px] xx-sm:w-[220px] border border-[#E3E6E8]">
        <div className="flex bg-[#F8F9FB] py-[32px] sm:py-[22px] items-center gap-[32px] justify-center">
          <ThryvCCOrangeLogoIcon
            width={isMobile ? 42 : 52}
            height={isMobile ? 42 : 52}
          />
          <Icon type="regular" variant="exchange" />
          <CommandCenterIcon
            variant="imap"
            type="solid"
            width={isMobile ? 42 : 52}
            height={isMobile ? 42 : 52}
          />
        </div>
        <div className="bg-white">
          <Header
            fontWeight="semibold"
            variant={isXSmallMobile ? 'h8' : isMobile ? 'h7' : 'h4'}
            className="px-[40.5px] pt-[50px] pb-[30px] sm:pt-[15px] sm:pb-[20px] xx-sm:px-0"
          >
            Log in to use your {cardData[clickedID]?.ButtonText} account with Thryv{' '}
            <span className="text-[#FF5000]">Command Center Inbox.</span>
          </Header>
          <ParagraphText
            variant="reg"
            className="px-[69px] pb-[54px] sm:pb-[30px] sm:px-7 xx-sm:px-4"
          >
            We would like to integrate with your email account.
          </ParagraphText>
          <form
            onSubmit={handleSubmit(() => {
              setLoading(true)
              axios({
                url: `${VITE_NYLAS_HOST}/send_hosted_auth_sync_url?`,
                method: 'get',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                params: {
                  redirectURI: `${VITE_AUTH0_REDIRECT_URI}/oauth/callback`,
                  email: email,
                },
              })
                .then((res) => {
                  const aTag = document.createElement('a')
                  aTag.setAttribute('href', res.data.Oauth_url_redirect)
                  aTag.click()
                  setLoading(false)
                })
                .catch(() => {
                  setLoading(false)
                })
            })}
          >
            <div className=" px-[98px] pb-[44px] sm:px-0 sm:flex sm:justify-center sm:w-full">
              <Controller
                control={control}
                rules={{
                  required: 'Please enter a valid email',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                }}
                name="email"
                render={({ field }) => (
                  <Input
                    className="w-[300px] sm:w-[250px] xx-sm:w-[200px]"
                    onChange={(e) => setValue('email', e.target.value)}
                    type="email"
                    placeholder="Email"
                    withLabel
                    labelType="floating"
                    variant="default"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="flex gap-[16px] px-[170px] pb-[60px] sm:w-full sm:px-0 sm:flex sm:justify-center sm:pb-[30px]">
              <Button
                variant="text"
                level={2}
                textVariant="light"
                onClick={() => dispatch(setModalContent(1))}
              >
                Cancel
              </Button>
              <ValidateForm />
            </div>
          </form>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default ImapModal
