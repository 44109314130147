import { createSlice } from '@reduxjs/toolkit'

export const inboxToastNotificationSlice = createSlice({
  name: 'inboxToastNotification',
  initialState: {
    showToast: false,
    toastContent: null,
  },
  reducers: {
    setShowToast(state, action) {
      state.showToast = action.payload
    },
    setToastContent(state, action) {
      state.toastContent = action.payload
    },
    openToast(state, action) {
      state.showToast = true
      state.toastContent = action.payload
    },
    closeToast(state) {
      state.showToast = false
    },
  },
})

export const { setShowToast, setToastContent, openToast, closeToast } =
  inboxToastNotificationSlice.actions

export default inboxToastNotificationSlice.reducer
