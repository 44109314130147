import { Header, ParagraphText, Button, Icon } from '@thryvlabs/maverick'
import { FETCH_THREADS, UPDATE_THREAD_FIELD } from '../../../../graphql'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { useInboxMenuConversations } from '../hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import {
  setBulkEditBtnClicked,
  setBulkSelectedThreadIds,
  setShowInboxNotification,
  setBulkSelectedThreadSk1s,
} from '../../slices/inboxThreadsSlice'
const RemoveConversationModal = ({ setOpenRemoveConversationModal }) => {
  const dispatch = useDispatch()
  const { bulkSelectedThreadSk1s } = useSelector((state) => state.inboxThreads)
  const sk1s = bulkSelectedThreadSk1s.map((x) => x.sk1)

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const [addThreadToTrash] = useMutation(UPDATE_THREAD_FIELD, {
    variables: {
      sk1s: sk1s,
      field: 'trashsk',
    },
    onCompleted: () => {
      getThreads()
      dispatch(setBulkEditBtnClicked(false))
      dispatch(setBulkSelectedThreadIds([]))
      dispatch(setBulkSelectedThreadSk1s([]))
      dispatch(setShowInboxNotification(true))
    },
  })

  const handleClick = () => {
    addThreadToTrash()
    setOpenRemoveConversationModal(false)
  }

  return (
    <div className=" flex flex-col gap-[40px] sm:w-[300px] sm:gap-[10px] sm:py-0 ">
      <div className="flex w-full items-center">
        <div className="flex justify-start w-2/3 sm:w-full sm:items-center sm:mb-6">
          <Header variant="h4" fontWeight="semibold">
            Remove Conversation
          </Header>
        </div>
        <div className="flex w-1/3 justify-end sm:absolute sm:top-[25px] sm:right-[20px]">
          <Icon
            className="cursor-pointer"
            type="regular"
            variant="x"
            height="18"
            fill="#A3A5A7"
            onClick={() => {
              setOpenRemoveConversationModal(false)
            }}
          />
        </div>
      </div>

      <div className="w-full sm:w-[300px]">
        <ParagraphText variant="reg">
          Please confirm you want to move these conversations to the trash.
        </ParagraphText>
      </div>
      <div className="flex w-full justify-end items-center gap-4 mt-[50px] sm:pr-4">
        <Button
          type="button"
          onClick={() => {
            setOpenRemoveConversationModal(false)
          }}
          variant="text"
          level={2}
          textVariant="light"
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" onClick={handleClick}>
          Trash
        </Button>
      </div>
    </div>
  )
}

export default RemoveConversationModal
