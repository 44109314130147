export const getCountryCurrency = (countryCode) => {
  switch (countryCode) {
    case 'US':
      return 'USD'
    case 'CA':
      return 'CAD'
    case 'AU':
      return 'AUD'
    case 'NZ':
      return 'NZD'
  }
  return 'USD'
}
