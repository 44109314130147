import { useEffect, useState } from 'react'
import { useResize } from './hooks/use-resize'
import { useWindowDimensions } from '../../../../hooks'

const ResizeButton = ({ onMouseDown }) => (
  <button
    className="absolute w-2 h-full -right-0.5 cursor-ew-resize"
    type="button"
    onMouseDown={onMouseDown}
    tabIndex="-1"
  ></button>
)

const ResizeContainer = ({ children, className, disableResize }) => {
  const [resizeHandler, size] = useResize(324)
  const windowDimensions = useWindowDimensions()
  const isSmallScreen = windowDimensions.width < 1600
  const handleMaxWidth = isSmallScreen ? 515 : 650

  return (
    <div
      className={`relative ${className}`}
      style={
        disableResize
          ? { width: '100%' }
          : { width: size.x, minWidth: 324, maxWidth: handleMaxWidth }
      }
    >
      {!disableResize ? <ResizeButton onMouseDown={resizeHandler} /> : null}
      {children}
    </div>
  )
}

export const InboxMenuResizeWrapper = ({ children }) => {
  const [disableResize, setDisableResize] = useState(false)
  const windowDimensions = useWindowDimensions()
  const desktopBreakpointEntered = windowDimensions.width > 834

  useEffect(() => {
    if (desktopBreakpointEntered && disableResize) {
      setDisableResize(false)
    }

    if (!desktopBreakpointEntered && !disableResize) {
      setDisableResize(true)
    }
  }, [windowDimensions])

  return (
    <ResizeContainer
      className={`flex flex-col border-r-[1px] border-solid border-[#F1F1F5] bg-white h-0 min-h-full`}
      disableResize={disableResize}
    >
      {children}
    </ResizeContainer>
  )
}
