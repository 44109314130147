import {
  ErrorBannerContainer,
  SuccessText,
  SuccessClose,
} from '../modals/connect-channels-modal/ModalComponent.styles'
import { SolidCircleExclamation, Button, RegularX } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { setMaxChannelsReached } from '../modals/slices/connectChannelsSlice'

const ErrorBanner = () => {
  const dispatch = useDispatch()
  return (
    <ErrorBannerContainer>
      <div className="mt-1">
        <SolidCircleExclamation width="16" height="16" fill="#FAC02B" />
      </div>
      <SuccessText variant="reg" color="thryv-black-500">
        You have connected all of your free channels, please upgrade to{' '}
        <Button variant="text" level={1}>
          Command Center Pro
        </Button>
        to add more channels.
      </SuccessText>
      <SuccessClose
        variant="text"
        level={2}
        onClick={() => dispatch(setMaxChannelsReached(false))}
      >
        <RegularX width="12" height="12" fill="#808080" />
      </SuccessClose>
    </ErrorBannerContainer>
  )
}

export default ErrorBanner
